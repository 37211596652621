import { useQuery } from "@tanstack/react-query";
import getDepRes from "../api/getDepRes";

const useGetDepRes = (prj_cd, qst_cd, sel_cd, stDate, edDate) => {
  return useQuery(["dep"], () => getDepRes(prj_cd, qst_cd, sel_cd, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDepRes;
