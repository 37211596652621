import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import Da05Chart from "../../components/mobile/chart/Da05Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da05Table from "../../components/mobile/table/Da05Table";
// import { ageCntDict, ageDict, workCntDict, workDict } from "../../dict/mobile/dict";
import useGetDa05Res from "../../hooks/useGetDa05Res";
import { useComnDict, useMenuState, usePeriodState, usePrjValState, useSelectQstState, useUnitState } from "../../store/mobile/store";

const DA05 = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);
  const ageCntDict = useComnDict((state) => state.ageCntDict);
  const workCntDict = useComnDict((state) => state.workCntDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const prjVal = usePrjValState((state) => state.prjVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const { refetch, data } = useGetDa05Res(prjVal.value, selectQstState.svy_OJT, startDate, endDate);

  const initData = data?.data[0];
  // table
  // categories
  let selNm = [];
  let categories = [];
  let uniqueCategories = [];

  let chartDb = initData?.slice(1, 5);
  let chartProps = [];

  const [rendering, setRendering] = useState(false);

  if (selectQstState.svy_OJT === "MEM") {
    chartDb?.forEach((el, idx) => {
      let temp = [];
      selNm.push(el.sel_NM);
      if (unitState === "rate") {
        workDict.forEach((data, idx2) => {
          if (el[data.sel_cd]) {
            categories.push(data.sel_nm);
            temp.push(el[data.sel_cd]);
          } else {
            temp.push(null);
          }
        });
      } else {
        workCntDict.forEach((data, idx2) => {
          if (el[data.sel_cd]) {
            categories.push(data.sel_nm);
            temp.push(el[data.sel_cd]);
          } else {
            temp.push(null);
          }
        });
      }
      // if (temp.reduce((a, b) => a + b)) {
      chartProps.push(temp);
      // }
    });

    let categorySet = new Set(categories);
    uniqueCategories = [...categorySet];
  } else if (
    selectQstState.svy_OJT === "VST" ||
    selectQstState.svy_OJT === "LRES" ||
    selectQstState.svy_OJT === "CS" ||
    selectQstState.svy_OJT === "RS"
  ) {
    chartDb?.forEach((el, idx) => {
      let temp = [];
      selNm.push(el.sel_NM);
      if (unitState === "rate") {
        ageDict.forEach((data, idx2) => {
          if (el[data.sel_cd]) {
            categories.push(data.sel_nm);
            temp.push(el[data.sel_cd]);
          } else {
            temp.push(null);
          }
        });
      } else {
        ageCntDict.forEach((data, idx2) => {
          if (el[data.sel_cd]) {
            categories.push(data.sel_nm);
            temp.push(el[data.sel_cd]);
          } else {
            temp.push(null);
          }
        });
      }
      // if (temp.reduce((a, b) => a + b)) {
      chartProps.push(temp);
      // }
    });

    let categorySet = new Set(categories);
    uniqueCategories = [...categorySet];
  }

  let chartAllProps = [selNm, uniqueCategories, chartProps];

  //table
  let tableSelNm = [];
  let tableProps = [];
  if (selectQstState.svy_OJT === "MEM") {
    if (unitState === "rate") {
      workDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data) => {
          if (idx === 0) {
            tableSelNm.push(data.sel_NM);
          }
          temp.push(data[el.sel_cd]);
        });

        tableProps.push(temp);
      });
    } else {
      workCntDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data) => {
          if (idx === 0) {
            tableSelNm.push(data.sel_NM);
          }
          temp.push(data[el.sel_cd]);
        });

        tableProps.push(temp);
      });
    }
  } else if (
    selectQstState.svy_OJT === "VST" ||
    selectQstState.svy_OJT === "LRES" ||
    selectQstState.svy_OJT === "CS" ||
    selectQstState.svy_OJT === "RS"
  ) {
    if (unitState === "rate") {
      ageDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data) => {
          if (idx === 0) {
            tableSelNm.push(data.sel_NM);
          }
          temp.push(data[el.sel_cd]);
        });

        tableProps.push(temp);
      });
    } else {
      ageCntDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data) => {
          if (idx === 0) {
            tableSelNm.push(data.sel_NM);
          }
          temp.push(data[el.sel_cd]);
        });

        tableProps.push(temp);
      });
    }
  }

  let tableAllProps = [tableSelNm, tableProps];

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 300);
  }, [selectQstState, startDate, endDate]);

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {selectQstState.keyword}
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>
          {rendering ? (
            <>
              <Da05Chart data={chartAllProps} />
              <Da05Table data={tableAllProps} />
            </>
          ) : null}
        </div>

        <TabBar />
      </div>
    </>
  );
};

export default DA05;
