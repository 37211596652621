import axios from "axios";

const deleteAdminUserArea = (data) => {
  return axios.delete("/adminUser/deleteAdminUserArea", {
    data: data,
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
  });
};

export default deleteAdminUserArea;
