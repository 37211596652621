import { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Filter from "../../components/mobile/analysis/Filter";
import Header from "../../components/mobile/common/Header";
import NavBar from "../../components/mobile/common/NavBar";
import PrjModal from "../../components/mobile/PrjModal";
import QstListModal from "../../components/mobile/QstListModal";
import {
  useActivePrjModalState,
  useMenuListModalControlState,
  useMenuState,
  useQstSvyOjtState,
  useUserDataState,
  useUserIdState,
} from "../../store/mobile/store";
import Analysis from "./Analysis";
import Crss from "./Crss";
import DA01 from "./DA01";
import DA02 from "./DA02";
import DA03 from "./DA03";
import DA04 from "./DA04";
import DA05 from "./DA05";
import DA06 from "./DA06";
import DA07 from "./DA07";
import DA08 from "./DA08";
import DA09 from "./DA09";
import DA10 from "./DA10";
import DA11 from "./DA11";
import DA12 from "./DA12";
import Defualt from "./Default";
import Dep from "./Dep";
import Essay from "./Essay";
import GptDataComp from "./GptDataComp";
import MenuList from "./MenuList";

const Common = () => {
  const params = useParams();
  const paramsVal = Object.values(params)[0];

  // console.log("params:" + params.get("*"));
  const qstSvjOjt = useQstSvyOjtState((state) => state.qstSvyOjtState);

  const userId = useUserIdState((state) => state.userId);
  const resetUserId = useUserIdState((state) => state.resetUserId);
  const userData = useUserDataState((state) => state.userData);
  const navigate = useNavigate();

  const prjModalState = useActivePrjModalState((state) => state.prjModalState);

  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);
  const setQstListModalState = useMenuListModalControlState((state) => state.setMenuListModalControl);

  // select menu state
  const setMenuState = useMenuState((state) => state.setMenuVal);

  const handleClickBg = () => {
    setQstListModalState(false);
    if (paramsVal === "analysis") {
      setMenuState("");
    } else {
      setMenuState(qstSvjOjt);
    }
  };

  useEffect(() => {
    if (userData.length === 0) {
      navigate("/");
    }

    return () => {
      console.log("check");
      resetUserId();
    };
  }, []);

  return (
    // 모달 활성화 시 overflow-hidden으로 스크롤 방지
    <>
      {userData ? (
        <div
          id="con"
          className={
            prjModalState
              ? "fixed flex h-full w-screen flex-col items-center justify-start overflow-hidden "
              : "fixed flex h-full w-screen flex-col items-center justify-start overflow-auto"
          }
        >
          <div className="sticky top-0 z-50 flex h-fit w-full flex-col items-center justify-center">
            {/* header */}
            <Header />
            {/* NavBar */}
            <NavBar />
          </div>

          {/* Filter */}
          {/* 초기 데이터 받아오기 용 (화면에 보이지 않지만 랜더링은 되어있음)*/}
          <div className="absolute -top-72 h-fit w-full ">
            <Filter />
          </div>

          {qstListModalState ? (
            <div
              onClick={handleClickBg}
              className="fixed top-0 z-50 flex h-full w-screen flex-col items-center justify-start bg-spinnerBackColor"
            ></div>
          ) : null}
          <QstListModal />

          {prjModalState ? <PrjModal /> : null}
          {/* commom page에서의 라우팅 */}
          <Routes>
            <Route key="/analysis" path="/analysis" element={<Analysis />} />
            <Route key="/menuList" path="/menuList" element={<MenuList />} />
            <Route key="/default" path="/default" element={<Defualt />} />
            <Route key="/DA01" path="/DA01" element={<DA01 />} />
            <Route key="/DA02" path="/DA02" element={<DA02 />} />
            <Route key="/DA03" path="/DA03" element={<DA03 />} />
            <Route key="/DA04" path="/DA04" element={<DA04 />} />
            <Route key="/DA05" path="/DA05" element={<DA05 />} />
            <Route key="/DA06" path="/DA06" element={<DA06 />} />
            <Route key="/DA07" path="/DA07" element={<DA07 />} />
            <Route key="/DA08" path="/DA08" element={<DA08 />} />
            <Route key="/DA09" path="/DA09" element={<DA09 />} />
            <Route key="/DA10" path="/DA10" element={<DA10 />} />
            <Route key="/DA11" path="/DA11" element={<DA11 />} />
            <Route key="/DA12" path="/DA12" element={<DA12 />} />
            <Route key="/Crss" path="/Crss" element={<Crss />} />
            <Route key="/Essay" path="/Essay" element={<Essay />} />
            <Route key="/Dep" path="/Dep" element={<Dep />} />
            <Route key="/GptFetch" path="/GptFetch" element={<GptDataComp />} />
          </Routes>
        </div>
      ) : null}
    </>
  );
};

export default Common;
