import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import { ageDict, workDict } from "../../../dict/mobile/dict";
import useWindowSize from "../../../hooks/useWindowSize";
import { useChartDataState, useComnDict, useMenuState, useSelListState } from "../../../store/mobile/store";

const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"];

const Da09Chart = () => {
  const width = useWindowSize();
  const menuState = useMenuState((state) => state.menuVal);
  const selListState = useSelListState((state) => state.selListState);

  const chartData = useChartDataState((state) => state.chartData);

  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  // chart data 추출 (사례수, 그룹별 전체 데이터 제외)
  let chartBaseData = selListState === "전체" ? chartData.slice(1, chartData.length).slice(0, chartData.length - 3) : chartData;

  let seriesArr = [];
  let categories = [];
  let dataNmList = menuState === "MEM" ? workDict.map((el) => el.sel_nm) : ageDict.map((el) => el.sel_nm);

  let minVal = 0;
  let maxVal = 0;

  // 직종별 데이터
  if (menuState === "MEM") {
    workDict.forEach((el, idx) => {
      let temp = [];
      // 비율
      chartBaseData.forEach((data, idx2) => {
        temp.push(data[el.sel_cd]);

        // 최솟값, 최댓값 구하기
        if (data[el.sel_cd] > maxVal) {
          maxVal = data[el.sel_cd];
        } else if (data[el.sel_cd] < minVal) {
          minVal = data[el.sel_cd];
        }

        // categories data 추출
        if (idx === 0) {
          if (data.grp_NM === "전체") {
            categories.push(`${data.grp_NM}`);
          } else {
            categories.push(`${data.grp_NM} (${data.sel_NM})`);
          }
        }
      });

      seriesArr.push(temp);
    });
  }
  // 연령별 데이터

  let finalSeries = seriesArr.map((el, idx) => {
    return {
      name: dataNmList[idx],
      data: el,
      color: colors[idx % 10],
    };
  });

  const options = {
    chart: {
      type: "bar",
      style: {},
      width: width !== 0 ? width * 0.95 : null,
      height: categories.length > 7 ? 2000 : categories.length > 5 ? 1800 : categories.length > 3 ? 900 : categories.length > 1 ? 600 : 400,
      spacingBottom: 50,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
      style: {
        fontSize: 16,
        color: "#383838",
      },
    },
    xAxis: {
      marginTop: 10,
      tickLength: 3,
      lineWidth: 0.2,
      lineColor: "#383838",
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        y: 4,
      },
      gridLineWidth: 0,
      categories: categories,
    },
    yAxis: {
      min: maxVal === 0 ? -100 : minVal, // data의 min 값
      max: minVal === 0 ? 100 : maxVal, // data의 max 값
      tickInterval: 10,

      title: {
        text: null,
      },
      visible: false,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    series: finalSeries,
    plotOptions: {
      series: {
        pointWidth: 14,
        groupPadding: 0.07,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        const legendValue = series.name;

        return `<b style="font-size : 9px;">${this.x} </br> </b> <span style="color: ${color};">&#9679;</span> ${legendValue} : <b>${this.y}</b>`;
      },
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: true,
      reversed: false,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
  };

  return <>{width ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}</>;
};

export default Da09Chart;
