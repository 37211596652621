import { BsCheckLg } from "react-icons/bs";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import useScroll from "../../../../hooks/Admin/AdminQst/useScroll";
import useGetAdminProjectList from "../../../../hooks/Admin/Common/useGetAdminProjectList";
import useDeleteTarget from "../../../../hooks/useDeleteTarget";
import useGetAdminExcp from "../../../../hooks/useGetAdminExcp";
import useGetAdminTargetSelNm from "../../../../hooks/useGetAdminTargetSelNm";
import usePostTarget from "../../../../hooks/usePostTarget";
import useStore from "../../../../store/pc/store";
import { makeTableData } from "../../../../util/pc/makeTableData";
import Loading from "../../Loading";
import AdminQstNotFoundAnimation from "./AdminQstNotFoundAnimation";

const AdminQstTargetComp = () => {
  const { PrjCd, AnalysisType } = useStore();
  const { titleDiv, checkDiv, caresel, handleClickNextBtn, handleClickPrevBtn, handleEnterMouse, handleLeaveMouse } = useScroll(640, 80);

  let tempNm = "";

  checkDiv.current = [];

  //ANCHOR - API 요청 영역
  const { data: selNmAndQst, isLoading: isSelNmLoading } = useGetAdminTargetSelNm(PrjCd);
  const { data: excp, isLoading: isExcpLoading } = useGetAdminExcp(PrjCd);
  const { data: adminProjectList, isLoading: adminProjectListLoading } = useGetAdminProjectList(PrjCd);
  const { mutate: postMutate } = usePostTarget();
  const { mutate: deleteMutate } = useDeleteTarget();

  //ANCHOR - API DATA 가공 영역
  if (isSelNmLoading || isExcpLoading || adminProjectListLoading) return <Loading />;
  const { qst, sel_NM } = selNmAndQst.data;
  const vst_sel_nm = sel_NM.filter((item) => !(item.dvs_CD === "VST" && item.comn_CD !== "I" && item.comn_CD !== "O"));
  const excpData = excp.data.filter((item) => !(item.svy_OJT === "VST" && item.io_DVS !== "I" && item.io_DVS !== "O"));
  const tableData = makeTableData(qst, vst_sel_nm, excpData, AnalysisType, adminProjectList);

  const handleClickCheck = (checked, comn_CD, qst_CD, dvs_CD, dtl_COMN_NM, dtl_COMN_CD, AnalysisType, svy_OJT, dvs_NM) => {
    let body;
    if (AnalysisType === "csscan") {
      let svyOjt = dvs_CD;
      let selNM = "";
      let Value = "";

      if (dvs_CD === "MEM" && dtl_COMN_NM === null) {
        selNM = "OCTY";
      } else if (dvs_CD === "MEM" && dtl_COMN_NM !== null) {
        selNM = "OCTY_DTL";
      } else if (dvs_CD === "VST") {
        selNM = svy_OJT + "_IO_DVS";
      } else if (dvs_CD === "LRES") {
        selNM = "LRES";
      }

      if (dtl_COMN_CD !== null && dtl_COMN_CD !== "") {
        Value = dtl_COMN_CD;
      } else {
        Value = comn_CD;
      }

      if (dvs_NM === "내원고객(입원외래)") {
        svyOjt = svy_OJT;
      }

      body = {
        prj_CD: PrjCd,
        qst_CD: qst_CD,
        svy_OJT: svyOjt, // 여기 CSSCAN 모드면 RS,CS로 들어가야함
        sel_NM: selNM,
        value: Value,
      };
    } else {
      let svyOjt = dvs_CD;
      let selNM = "";
      let Value = "";

      if (dvs_CD === "MEM" && dtl_COMN_NM === null) {
        selNM = "OCTY";
      } else if (dvs_CD === "MEM" && dtl_COMN_NM !== null) {
        selNM = "OCTY_DTL";
      } else if (dvs_CD === "VST") {
        selNM = "IO_DVS";
      } else if (dvs_CD === "LRES") {
        selNM = "LRES";
      }

      if (dtl_COMN_CD !== null && dtl_COMN_CD !== "") {
        Value = dtl_COMN_CD;
      } else {
        Value = comn_CD;
      }

      body = {
        prj_CD: PrjCd,
        qst_CD: qst_CD,
        svy_OJT: svyOjt, // 여기 CSSCAN 모드면 RS,CS로 들어가야함
        sel_NM: selNM,
        value: Value,
      };
    }

    if (checked) {
      postMutate(body);
    } else {
      deleteMutate(body);
    }
  };

  return (
    <>
      <div className="mt-5 flex h-[90px] w-fit flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
        {/* 헤더 영역 (순서,문항,해당없음)*/}
        <div className="box-border flex h-full w-[50px] flex-col items-center justify-center border-l border-white">순서</div>
        <div className="box-border flex h-full w-[290px] flex-col items-center justify-center border-l border-white">문항</div>

        {/* 헤더 영역 (내원고객 세부과)*/}
        <div
          onMouseEnter={handleEnterMouse}
          onMouseLeave={handleLeaveMouse}
          className="hide-scrollbar relative flex h-full w-[640px] flex-row items-center justify-start overflow-hidden border-l border-white"
        >
          {caresel ? (
            <>
              <button
                type="button"
                onClick={handleClickPrevBtn}
                className="absolute left-3 z-50 flex h-[40px] w-[40px] flex-col items-center justify-center rounded-md bg-selectTextColor/50 p-3 text-white hover:bg-selectTextColor/30"
              >
                <MdNavigateBefore className="text-[30px]" />
              </button>
              <button
                type="button"
                onClick={handleClickNextBtn}
                className="absolute right-3 z-50 flex h-[40px] w-[40px] flex-col items-center justify-center rounded-md  bg-selectTextColor/50 p-3 text-white hover:bg-selectTextColor/30"
              >
                <MdNavigateNext className="text-[30px]" />
              </button>
            </>
          ) : null}
          <div ref={titleDiv} className="flex h-full w-fit translate-x-0 flex-row items-center justify-start duration-300 ease-in-out">
            {/*csscan일때 메뉴 (내원고객,구성원 순) */}
            {AnalysisType === "csscan" && (
              <>
                {/* 내원고객 */}
                {!(adminProjectList.includes("MEM") && adminProjectList.length === 1) && (
                  <div className="flex h-full w-fit flex-col items-start justify-start">
                    <div className="flex h-[30px] w-full flex-col items-center justify-center border-b border-r border-white ">내원고객</div>
                    <div className="flex h-[60px] w-full flex-row items-center justify-start">
                      {["입원", "외래"].map((el, idx) => {
                        return (
                          <div key={idx} className="flex h-full w-full flex-col items-center justify-start ">
                            <div className="flex h-[30px] w-full flex-col items-center justify-center border-r border-white ">{el}</div>
                            <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                              {adminProjectList
                                .filter((item) => item !== "MEM")
                                .map((item, index) => (
                                  <div
                                    key={index}
                                    className=" flex h-full w-fit min-w-[80px]  flex-col items-center justify-center whitespace-nowrap border-r border-white "
                                  >
                                    {item === "CS" ? "고객만족" : "환자경험"}
                                  </div>
                                ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* 구성원 */}
                {adminProjectList.includes("MEM") && (
                  <div className="flex h-full w-fit flex-col items-start justify-start">
                    <div className="flex h-[30px] w-full flex-col items-center justify-center border-b border-r border-white ">구성원</div>
                    <div className="flex h-[60px] w-full flex-row items-center justify-start">
                      {vst_sel_nm
                        .filter((el) => el.dvs_CD === "MEM")
                        .map((el, idx) => {
                          if (el.dtl_COMN_NM) {
                            if (tempNm !== el.comn_NM) {
                              tempNm = el.comn_NM;

                              return (
                                <div key={idx} className="flex h-full w-full flex-col items-center justify-start">
                                  <div className="flex h-[30px] w-full flex-col items-center justify-center border-r border-white ">{el.comn_NM}</div>

                                  <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                                    {vst_sel_nm
                                      .filter((el2) => el2.dvs_CD === "MEM" && el.comn_CD === el2.comn_CD)
                                      .map((element, idx) => {
                                        return (
                                          <div
                                            key={idx}
                                            className=" flex h-full w-[80px] flex-col items-center justify-center whitespace-nowrap border-r border-white "
                                          >
                                            {element.dtl_COMN_NM}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              );
                            }
                          } else {
                            return (
                              <div key={idx} className="flex h-full w-full flex-col items-center justify-start">
                                <div className="flex h-[30px] w-full flex-col items-center justify-center border-r border-white ">{el.comn_NM}</div>

                                <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                                  <div className=" flex h-full w-[80px] flex-col items-center justify-center whitespace-nowrap border-r border-white ">
                                    -
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                )}
              </>
            )}
            {/*scan일때 메뉴 (구성원,내원고객,지역주민 순) */}
            {AnalysisType !== "csscan" && (
              <>
                {/* 구성원 */}
                <div className="flex h-full w-fit flex-col items-start justify-start">
                  <div className="flex h-[30px] w-full flex-col items-center justify-center border-b border-r border-white ">구성원</div>
                  <div className="flex h-[60px] w-full flex-row items-center justify-start">
                    {vst_sel_nm
                      .filter((el) => el.dvs_CD === "MEM")
                      .map((el, idx) => {
                        if (el.dtl_COMN_NM) {
                          if (tempNm !== el.comn_NM) {
                            tempNm = el.comn_NM;

                            return (
                              <div key={idx} className="flex h-full w-full flex-col items-center justify-start">
                                <div className="flex h-[30px] w-full flex-col items-center justify-center border-r border-white ">{el.comn_NM}</div>

                                <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                                  {vst_sel_nm
                                    .filter((el2) => el2.dvs_CD === "MEM" && el.comn_CD === el2.comn_CD)
                                    .map((element) => {
                                      return (
                                        <div className=" flex h-full w-[80px] flex-col items-center justify-center whitespace-nowrap border-r border-white ">
                                          {element.dtl_COMN_NM}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return (
                            <div key={idx} className="flex h-full w-full flex-col items-center justify-start">
                              <div className="flex h-[30px] w-full flex-col items-center justify-center border-r border-white ">{el.comn_NM}</div>

                              <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                                <div className=" flex h-full w-[80px] flex-col items-center justify-center whitespace-nowrap border-r border-white ">
                                  -
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
                {/* 내원고객 */}
                <div className="flex h-full w-fit flex-col items-start justify-start">
                  <div className="flex h-[30px] w-full flex-col items-center justify-center border-b border-r border-white ">내원고객</div>
                  <div className="flex h-[60px] w-full flex-row items-center justify-start">
                    {["입원", "외래"].map((el, idx) => {
                      return (
                        <div key={idx} className="flex h-full w-full flex-col items-center justify-start ">
                          <div className="flex h-[30px] w-full flex-col items-center justify-center border-r border-white ">{el}</div>
                          <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                            <div className=" flex h-full w-fit min-w-[80px] flex-col items-center justify-center whitespace-nowrap border-r border-white ">
                              -
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* 지역주민 */}
                <div className="flex h-full w-[80px]  flex-col items-center justify-center whitespace-nowrap ">지역주민</div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* 데이터 영역*/}
      {tableData.length === 0 && <AdminQstNotFoundAnimation />}

      {tableData.map((item, index) => (
        <div
          key={index}
          className="flex h-[80px] w-fit flex-row items-center justify-start border-b border-FilterInActiveColor/40 text-[16px] font-normal text-selectTextColor"
        >
          <div className="flex h-full w-[50px] flex-col items-end justify-start p-7 leading-snug ">{index + 1}</div>
          <textarea
            spellCheck="false"
            readOnly
            value={item.qst}
            className="hide-scrollbar flex h-full w-[290px] cursor-default resize-none flex-col items-start justify-start border-x border-FilterInActiveColor/40 p-7 leading-snug outline-none"
          ></textarea>

          <div className="hide-scrollbar flex h-full w-[640px] flex-col items-start justify-start overflow-hidden">
            <div
              ref={(element) => (checkDiv.current[index] = element)}
              className="flex h-full w-fit translate-x-0 flex-row items-start justify-start border-r border-FilterInActiveColor/40  duration-300 ease-in-out "
            >
              {item.listData.map((item2, index2) => (
                <div
                  onClick={() =>
                    handleClickCheck(
                      item2.checked,
                      item2.comn_CD,
                      item.qst_CD,
                      item2.dvs_CD,
                      item2.dtl_COMN_NM,
                      item2.dtl_COMN_CD,
                      AnalysisType,
                      item2.svy_OJT,
                      item2.dvs_NM
                    )
                  }
                  key={index2}
                  className={`flex h-full min-w-[80px] flex-col items-center justify-center ${
                    index2 === 0 ? "border-0" : " border-l "
                  } border-FilterInActiveColor/40 hover:bg-FilterInActiveColor/20`}
                >
                  {item2.checked ? (
                    <BsCheckLg className="text-[24px] font-bold text-orangeColor" />
                  ) : (
                    <BsCheckLg className="text-[24px] font-bold text-gray-200" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AdminQstTargetComp;
