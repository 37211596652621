import { useQuery } from "@tanstack/react-query";
import getAdminUserEtc from "../api/getAdminUserEtc";

const useGetAdminUserEtc = (prj_cd, etc_num) => {
  return useQuery(["adminUserEtc"], () => getAdminUserEtc(prj_cd, etc_num), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserEtc;
