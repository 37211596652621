import { useEffect, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { MdRefresh } from "react-icons/md";
import useGetGptRes from "../../../hooks/useGetGptRes";
import { useMenuState, usePeriodState, usePrjValState, useSelectQstState, useSummerizeRes, useUserIdState } from "../../../store/mobile/store";
import GptFetchModal from "./GptFetchModal";

const ChatGptComp = (props) => {
  const [spreadArea, setSpreadArea] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const summerizedData = useSummerizeRes((state) => state.summerizedData);
  const menuState = useMenuState((state) => state.menuVal);
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const prjVal = usePrjValState((state) => state.prjVal);
  const userInfo = useUserIdState((state) => state.userId);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const handleClickShowBtn = () => {
    setSpreadArea(!spreadArea);
  };

  const extractTime = (time) => {
    let currDate = new Date(time);

    let utc = currDate.getTime() + currDate.getTimezoneOffset() * 60 * 1000;

    // DB 시간 차이
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    const kr_curr = new Date(utc + KR_TIME_DIFF);

    let year = kr_curr.getFullYear();
    let month = Number(kr_curr.getMonth()) + 1 > 9 ? Number(kr_curr.getMonth()) + 1 : `0${Number(kr_curr.getMonth()) + 1}`;
    let date = kr_curr.getDate() > 9 ? kr_curr.getDate() : `0${kr_curr.getDate()}`;

    let hour = kr_curr.getHours() > 9 ? kr_curr.getHours() : `0${kr_curr.getHours()}`;
    let minute = kr_curr.getMinutes() > 9 ? kr_curr.getMinutes() : `0${kr_curr.getMinutes()}`;
    let sec = kr_curr.getSeconds() > 9 ? kr_curr.getSeconds() : `0${kr_curr.getSeconds()}`;

    return `${year}.${month}.${date} ${hour}:${minute}:${sec}`;
  };

  // ADMIN ID
  const ADMIN = ["JJLIM", "GMNA", "SHPARK", "BSKIM"];
  // const ADMIN = ["JJLIM2", "GMNA2", "SHPARK2", "BSKIM2"];

  const { isFetched, isLoading, isRefetching, isSuccess, data, refetch } = useGetGptRes(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.svy_OJT,
    (menuState === "MEM" && props.filterOpt.length === 7) ||
      ((menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") && props.filterOpt.length === 8)
      ? ["-"]
      : props.filterOpt,
    startDate ? `${startDate}-${endDate?.substring(0, 10)}` : "-"
  );

  const handleClickBg = (e) => {
    // setActiveModal(false);
  };

  const handleClickRefresh = () => {
    setActiveModal(true);
  };

  useEffect(() => {
    refetch();
  }, [props.filterOpt, startDate, endDate]);

  return (
    <>
      {/* gptModal */}
      {activeModal ? (
        <div onClick={handleClickBg} className="fixed top-0 z-50 flex h-full w-screen flex-col items-center justify-center bg-spinnerBackColor">
          <GptFetchModal data={props?.data.substring(0, 450000)} setModal={setActiveModal} filterOpt={props?.filterOpt} qst={props?.qst} />
        </div>
      ) : null}

      {/* content */}
      <div className="relative flex h-fit w-[95%] flex-col items-start justify-start rounded-lg border  border-FilterInActiveColor bg-white p-4 font-SDGothic  duration-300 ease-in-out">
        <div className="flex h-fit w-full flex-row items-center justify-start text-[14px] font-bold text-selectTextColor/90">
          <img
            src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/chatGptIcon.png"
            alt="chatGptIcon"
            className="mr-2  h-auto w-[16px]"
          />
          <div className="flex h-fit w-fit flex-row items-center justify-center">
            {(props.selectOptArr.length === 7 && menuState === "MEM") ||
            (props.selectOptArr.length === 8 && (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS"))
              ? "ChatGPT로 전체 답변을 요약한 글입니다"
              : "ChatGPT로 선택한 조건의 답변을 요약한 글입니다"}

            {/* {summerizedData?.data?.gpt_RSLT && !ADMIN.includes(userInfo) ? null : ( */}
            {ADMIN.includes(userInfo.toUpperCase()) ? (
              <div
                onClick={handleClickRefresh}
                className="absolute right-3 top-3 flex h-fit w-fit flex-col items-center justify-center rounded-md border border-FilterActiveColor/50 p-1"
              >
                <MdRefresh className=" text-[12px] text-FilterActiveColor/50" />
              </div>
            ) : summerizedData?.data?.prog_YN === "Y" ? (
              <div
                onClick={handleClickRefresh}
                className="absolute right-3 top-3 flex h-fit w-fit flex-col items-center justify-center rounded-md border border-FilterActiveColor/50 p-1"
              >
                <MdRefresh className=" text-[12px] text-FilterActiveColor/50" />
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={
            spreadArea
              ? `mt-3 flex max-h-fit w-full flex-col items-start justify-start overflow-hidden  text-ellipsis text-[14px] font-light leading-snug text-selectTextColor/90 transition-all duration-300 ease-in-out`
              : `mt-3 flex max-h-[75px] w-full flex-col items-start justify-start overflow-hidden  text-ellipsis text-[14px] font-light leading-snug text-selectTextColor/90 transition-all duration-300 ease-in-out`
          }
        >
          {summerizedData?.data?.gpt_RSLT
            ? summerizedData?.data?.gpt_RSLT
            : "요약정보가 존재하지 않습니다. 설문이 진행중인 경우 우측 상단의 버튼을 눌러 GPT 요약정보를 요청하세요"}
          {summerizedData?.data?.gpt_RSLT && spreadArea ? (
            <div className=" mt-3 flex h-fit w-full flex-col items-center justify-center rounded-lg bg-FilterInActiveColor/30 p-3 font-SDGothic text-[12px] font-normal text-selectTextColor/50">
              {`최종 업데이트 : ${extractTime(summerizedData?.data?.inpt_DTM)}`}
            </div>
          ) : null}
        </div>

        {!summerizedData?.data?.gpt_RSLT ? null : spreadArea ? (
          <div
            onClick={handleClickShowBtn}
            className="mt-2 flex h-fit w-full flex-row items-center justify-end bg-gradient-to-l from-white from-60% text-[14px] font-bold text-blue1Color"
          >
            접기
            <HiChevronUp className="text-[22px]" />
          </div>
        ) : (
          <div
            onClick={handleClickShowBtn}
            className="absolute bottom-2 right-3 flex h-fit w-1/3 flex-row items-center justify-end bg-gradient-to-l from-white from-60% text-[14px] font-bold text-blue1Color"
          >
            더보기
            <HiChevronDown className="text-[22px]" />
          </div>
        )}
      </div>
    </>
  );
};

export default ChatGptComp;
