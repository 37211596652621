import axios from "axios";

const getEssayQstInfo = (prj_cd, qst_cd, svy_ojt) => {
  return axios.get(`/pcEssay/basicQstInfo?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getEssayQstInfo;
