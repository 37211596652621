import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SyncLoader } from "react-spinners";
import DepChart from "../../components/mobile/chart/DepChart";
import TabBar from "../../components/mobile/common/TabBar";
import Da08SelListModal from "../../components/mobile/Da08SelListModal";
import DepTable from "../../components/mobile/table/DepTable";
import useGetDepRes from "../../hooks/useGetDepRes";
import {
  useDa08SelListState,
  useMenuListModalControlState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useSelListModalState,
  useUnitState,
} from "../../store/mobile/store";

const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"];

const Dep = () => {
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const prjVal = usePrjValState((state) => state.prjVal);
  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);
  const globalSelCd = useDa08SelListState((state) => state.da08SelListState);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const selListModalState = useSelListModalState((state) => state.selListModalState);
  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  const handleClickSelList = () => {
    setSelListModalState(!selListModalState);
  };

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  console.log(globalSelCd);

  const { refetch, data, isLoading } = useGetDepRes(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.deep_ANA_CD === "DA06" || selectQstState.deep_ANA_CD === "DA08" || selectQstState.deep_ANA_CD === "DA09" ? globalSelCd : null,
    startDate,
    endDate
  );

  const selData = data?.data[0];
  const initData = data?.data[1];

  console.log(selData);

  const depDict = [
    { sel_cd: "tot", sel_nm: "전체" },
    { sel_cd: "mem", sel_nm: "구성원" },
    { sel_cd: "vst", sel_nm: "내원고객" },
    { sel_cd: "lres", sel_nm: "지역주민" },
  ];

  const depCntDict = [
    { sel_cd: "tot_CNT", sel_nm: "전체" },
    { sel_cd: "mem_CNT", sel_nm: "구성원" },
    { sel_cd: "vst_CNT", sel_nm: "내원고객" },
    { sel_cd: "lres_CNT", sel_nm: "지역주민" },
  ];

  /** chartData 추출 */
  let categoriesArr = [];

  initData?.forEach((el, idx) => {
    depDict.forEach((word, idx2) => {
      if (el[word.sel_cd]) {
        categoriesArr.push(word.sel_nm);
      }
    });
  });

  let categoriesSet = new Set(categoriesArr);
  let uniqueChartCategories = [...categoriesSet];

  let chartArr = [];
  let sel_nm = [];

  if (selectQstState.deep_ANA_CD === "DA04" || selectQstState.deep_ANA_CD === "DA06" || selectQstState.deep_ANA_CD === "DA09") {
    initData?.slice(1, initData?.length)?.forEach((el, idx) => {
      let temp = [];
      if (el.sel_NM === "NPS") {
        sel_nm.push(selectQstState.deep_ANA_CD === "DA08" ? `${el.sel_NM}위` : el.sel_NM);

        if (unitState === "rate") {
          // 비율
          depDict.forEach((word, idx2) => {
            if (el[word.sel_cd]) {
              temp.push(el[word.sel_cd]);
            } else {
              temp.push(null);
            }
          });
        } else {
          // 카운트
          depCntDict.forEach((word, idx2) => {
            if (el[word.sel_cd]) {
              temp.push(el[word.sel_cd]);
            } else {
              temp.push(null);
            }
          });
        }
        chartArr.push(temp);
      }
    });
  } else {
    initData?.slice(1, initData?.length)?.forEach((el, idx) => {
      let temp = [];
      sel_nm.push(selectQstState.deep_ANA_CD === "DA08" ? `${el.sel_NM}위` : el.sel_NM);

      if (unitState === "rate") {
        // 비율
        depDict.forEach((word, idx2) => {
          if (el[word.sel_cd]) {
            temp.push(el[word.sel_cd]);
          } else {
            temp.push(null);
          }
        });
      } else {
        // 카운트
        depCntDict.forEach((word, idx2) => {
          if (el[word.sel_cd]) {
            temp.push(el[word.sel_cd]);
          } else {
            temp.push(null);
          }
        });
      }

      chartArr.push(temp);
    });
  }

  /** tableData 추출 */
  let tableCategory = [];
  let tableData = [];

  // 비율
  if (unitState === "rate") {
    depDict.forEach((word, idx2) => {
      let temp = [word.sel_nm];

      initData?.forEach((el, idx) => {
        if (idx2 === 0) {
          tableCategory.push(selectQstState.deep_ANA_CD === "DA08" && idx != 0 ? `${el.sel_NM}위` : el.sel_NM);
        }

        temp.push(el[word.sel_cd]);
      });

      tableData.push(temp);
    });
  } else {
    depCntDict.forEach((word, idx2) => {
      let temp = [word.sel_nm];

      initData?.forEach((el, idx) => {
        if (idx2 === 0) {
          tableCategory.push(selectQstState.deep_ANA_CD === "DA08" && idx != 0 ? `${el.sel_NM}위` : el.sel_NM);
        }

        temp.push(el[word.sel_cd]);
      });

      tableData.push(temp);
    });
  }

  // 카운트

  const [rendering, setRendering] = useState(false);

  useEffect(() => {
    refetch();

    if (selectQstState.deep_ANA_CD === "DA04" || selectQstState.deep_ANA_CD === "DA06" || selectQstState.deep_ANA_CD === "DA09") {
      setUnitState("rate");
    }

    setTimeout(() => {
      setRendering(true);
    }, 700);

    return () => {
      setRendering(false);
      setSelListModalState(false);
    };
  }, [selectQstState, globalSelCd, startDate, endDate]);

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="relative flex h-fit w-full flex-row items-center justify-between border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <div className="flex h-fit w-fit flex-row items-center justify-center">
            <BsDot className="text-orangeColor" />
            {selectQstState.keyword}
          </div>

          {selectQstState.deep_ANA_CD === "DA06" || selectQstState.deep_ANA_CD === "DA08" || selectQstState.deep_ANA_CD === "DA09" ? (
            <>
              {selListModalState ? (
                <>
                  <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                    <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                      {selData?.filter((el) => el.sel_CD === globalSelCd)[0].sel_NM_M.length > 5
                        ? `${selData?.filter((el) => el.sel_CD === globalSelCd)[0].sel_NM_M.slice(0, 5)}...`
                        : selData?.filter((el) => el.sel_CD === globalSelCd)[0].sel_NM_M}
                    </div>
                    <IoIosArrowUp className="mr-2 text-[20px] text-orangeColor" />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                    <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                      {selData?.filter((el) => el.sel_CD === globalSelCd)[0].sel_NM_M.length > 5
                        ? `${selData?.filter((el) => el.sel_CD === globalSelCd)[0].sel_NM_M.slice(0, 5)}...`
                        : selData?.filter((el) => el.sel_CD === globalSelCd)[0].sel_NM_M}
                    </div>
                    <IoIosArrowDown className="mr-2 text-[20px] text-orangeColor" />
                  </div>
                </>
              )}

              <div
                className={
                  selListModalState || qstListModalState
                    ? `absolute right-3 top-[46px] z-10 h-[500px] w-[80%] overflow-hidden font-semibold transition-z-index ease-out`
                    : `absolute right-3 top-[46px] -z-30 h-[500px] w-[80%] overflow-hidden font-semibold transition-z-index duration-1000 ease-out`
                }
              >
                <Da08SelListModal selList={selData} />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            {selectQstState.deep_ANA_CD === "DA04" || selectQstState.deep_ANA_CD === "DA06" || selectQstState.deep_ANA_CD === "DA09" ? (
              <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
                {/* (단위: %) */}
                <div className="flex h-fit w-fit flex-row items-center justify-center py-2">
                  <label className="ml-2 flex h-fit w-fit flex-col items-center justify-center">(단위: %)</label>
                </div>
              </div>
            ) : (
              <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
                {/* (단위: %) */}
                <div className="flex h-fit w-fit flex-row items-center justify-center">
                  <input
                    id="rate"
                    type="radio"
                    name="radio_unit"
                    value="rate"
                    className="accent-orangeColor"
                    checked={unitState === "rate" ? true : false}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                    비중(%)
                  </label>
                  <input
                    id="count"
                    type="radio"
                    name="radio_unit"
                    value="count"
                    className="ml-3 border accent-orangeColor"
                    checked={unitState === "count" ? true : false}
                    onChange={handleChangeRadio}
                  />
                  <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                    인원(명)
                  </label>
                </div>
              </div>
            )}
          </div>
          {rendering && !qstListModalState && !isLoading ? (
            <>
              <DepChart categories={uniqueChartCategories} seriesDb={chartArr} sel_nm={sel_nm} deep_ANA_CD={selectQstState.deep_ANA_CD} />
              <DepTable categories={tableCategory} data={tableData} />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default Dep;
