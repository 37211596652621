import { useQuery } from "@tanstack/react-query";
import getEssayQstInfo from "../api/getEssayQstInfo";

const useGetEssayQstInfo = (prj_cd, qst_cd, svy_ojt) => {
  return useQuery(["essayQstInfo"], () => getEssayQstInfo(prj_cd, qst_cd, svy_ojt), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetEssayQstInfo;
