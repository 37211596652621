import axios from "axios";

const getDa06Res = (prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate) => {
  return axios.get(`/resultM/da06/chart?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}&sel_cd=${sel_cd}&stDate=${stDate}&edDate=${edDate}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getDa06Res;
