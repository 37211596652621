import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartCRSS from "../../components/pc/BarColumnChartCRSS";
import BarColumnChartDA04 from "../../components/pc/BarColumnChartDA04";
import BarColumnChartDA06 from "../../components/pc/BarColumnChartDA06";
import CRSSTable from "../../components/pc/CRSSTable";
import DA04Table from "../../components/pc/DA04Table";
import DA06Table from "../../components/pc/DA06Table";
import Loading from "../../components/pc/Loading";
import PieChartCrss from "../../components/pc/PieChartCrss";
import { Radio } from "../../components/pc/Radio";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useCrssSelList } from "../../hooks/useCrssSelList";
import { useGraphCRSSAgeGrp } from "../../hooks/useGraphCRSSAgeGrp";
import { useGraphCRSSArea } from "../../hooks/useGraphCRSSArea";
import { useGraphCRSSAssign } from "../../hooks/useGraphCRSSAssign";
import { useGraphCRSSDoc } from "../../hooks/useGraphCRSSDoc";
import { useGraphCRSSEtc1 } from "../../hooks/useGraphCRSSEtc1";
import { useGraphCRSSEtc2 } from "../../hooks/useGraphCRSSEtc2";
import { useGraphCRSSEtc3 } from "../../hooks/useGraphCRSSEtc3";
import { useGraphCRSSMainDept } from "../../hooks/useGraphCRSSMainDept";
import { useGraphCRSSNormal } from "../../hooks/useGraphCRSSNormal";
import { useGraphCRSSOcty } from "../../hooks/useGraphCRSSOcty";
import { useGraphCRSSSex } from "../../hooks/useGraphCRSSSex";
import { useGraphCRSSStandard } from "../../hooks/useGraphCRSSStandard";
import { useGraphCRSSStandardAccu } from "../../hooks/useGraphCRSSStandardAccu";
import { useGraphCRSSWrkYyGrp } from "../../hooks/useGraphCRSSWrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useTableCRSSAgeGrp } from "../../hooks/useTableCRSSAgeGrp";
import { useTableCRSSAssign } from "../../hooks/useTableCRSSAssign";
import { useTableCRSSDoc } from "../../hooks/useTableCRSSDoc";
import { useTableCRSSOcty } from "../../hooks/useTableCRSSOcty";
import { useTableCRSSSex } from "../../hooks/useTableCRSSSex";
import { useTableCRSSWrkYyGrp } from "../../hooks/useTableCRSSWrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const CrssPage = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [KeywordDeepAnaCd, setKeywordDeepAnaCd] = useState("");
  const [QstCd, setQstCd] = useState(Pick.crss_qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);
  const [SecondCheck, setSecondCheck] = useState([]);
  const [RadioDA07DA11Check, setRadioDA07DA11Check] = useState("1");

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useCrssSelList(
    PrjCd,
    SvyOjt,
    Pick.crss_qst_cd,
    Pick.crss_deep_ana_cd
  );

  const Da07Data = [
    { value: "1", label: "1순위" },
    { value: "2", label: "2순위" },
    { value: "+", label: "1+2순위" },
    { value: "*", label: "1*2+2*1순위" },
  ];

  const changeDA07DA11 = (e) => {
    setRadioDA07DA11Check(e.target.value);
  };

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    if ((Sel !== null && Sel !== undefined) || (Sel.length === 1 && Sel[0] !== undefined)) {
      var vTemp = Sel.slice().map((data) => data.value);
      setSecondCheck([...vTemp]);
    }
  }, [Sel]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vKeywordDeepQstCd = "";
    var vTempQstCd = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.crss_qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.crss_qst_cd)[0].keyword;
      vTempQstCd = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst_CD;
      vKeywordDeepQstCd = QstListData.filter((x) => x.qst_CD === vTempQstCd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN; // 수정 포인트 Pick.crss_qst_cd => Pick.qst_cd
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setKeywordDeepAnaCd(vKeywordDeepQstCd);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(SecondCheck);
  }, [SecondCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeSecondCheck = (text) => {
    setSecondCheck(text);
  };

  const { isGraphCRSSStandardLoading, isGraphCRSSStandardError, GraphCRSSStandardData, isGraphCRSSStandardSuccess, GraphCRSSStandardRefetch } =
    useGraphCRSSStandard(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "OCTY",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const {
    isGraphCRSSStandardAccuLoading,
    isGraphCRSSStandardAccuError,
    GraphCRSSStandardAccuData,
    isGraphCRSSStandardAccuSuccess,
    GraphCRSSStandardAccuRefetch,
  } = useGraphCRSSStandardAccu(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSNormalLoading, isGraphCRSSNormalError, GraphCRSSNormalData, isGraphCRSSNormalSuccess, GraphCRSSNormalRefetch } =
    useGraphCRSSNormal(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "OCTY",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isGraphCRSSAssignLoading, isGraphCRSSAssignError, GraphCRSSAssignData, isGraphCRSSAssignSuccess, GraphCRSSAssignRefetch } =
    useGraphCRSSAssign(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "ASSIGN_YN",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isGraphCRSSDocLoading, isGraphCRSSDocError, GraphCRSSDocData, isGraphCRSSDocSuccess, GraphCRSSDocRefetch } = useGraphCRSSDoc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSOctyLoading, isGraphCRSSOctyError, GraphCRSSOctyData, isGraphCRSSOctySuccess, GraphCRSSOctyRefetch } = useGraphCRSSOcty(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSWrkYyGrpLoading, isGraphCRSSWrkYyGrpError, GraphCRSSWrkYyGrpData, isGraphCRSSWrkYyGrpSuccess, GraphCRSSWrkYyGrpRefetch } =
    useGraphCRSSWrkYyGrp(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "WRK_YY_GRP",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isGraphCRSSAgeGrpLoading, isGraphCRSSAgeGrpError, GraphCRSSAgeGrpData, isGraphCRSSAgeGrpSuccess, GraphCRSSAgeGrpRefetch } =
    useGraphCRSSAgeGrp(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "AGE_GRP",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isGraphCRSSSexLoading, isGraphCRSSSexError, GraphCRSSSexData, isGraphCRSSSexSuccess, GraphCRSSSexRefetch } = useGraphCRSSSex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSAreaLoading, isGraphCRSSAreaError, GraphCRSSAreaData, isGraphCRSSAreaSuccess, GraphCRSSAreaRefetch } = useGraphCRSSArea(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AREA_CD",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSMainDeptLoading, isGraphCRSSMainDeptError, GraphCRSSMainDeptData, isGraphCRSSMainDeptSuccess, GraphCRSSMainDeptRefetch } =
    useGraphCRSSMainDept(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "MAIN_DEPT_CD",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isGraphCRSSEtc1Loading, isGraphCRSSEtc1Error, GraphCRSSEtc1Data, isGraphCRSSEtc1Success, GraphCRSSEtc1Refetch } = useGraphCRSSEtc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSEtc2Loading, isGraphCRSSEtc2Error, GraphCRSSEtc2Data, isGraphCRSSEtc2Success, GraphCRSSEtc2Refetch } = useGraphCRSSEtc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isGraphCRSSEtc3Loading, isGraphCRSSEtc3Error, GraphCRSSEtc3Data, isGraphCRSSEtc3Success, GraphCRSSEtc3Refetch } = useGraphCRSSEtc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isTableCRSSAgeGrpLoading, isTableCRSSAgeGrpError, TableCRSSAgeGrpData, isTableCRSSAgeGrpSuccess, TableCRSSAgeGrpRefetch } =
    useTableCRSSAgeGrp(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "AGE_GRP",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isTableCRSSAssignLoading, isTableCRSSAssignError, TableCRSSAssignData, isTableCRSSAssignSuccess, TableCRSSAssignRefetch } =
    useTableCRSSAssign(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "ASSIGN_YN",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isTableCRSWrkYyGrpLoading, isTableCRSSWrkYyGrpError, TableCRSSWrkYyGrpData, isTableCRSSWrkYyGrpSuccess, TableCRSSWrkYyGrpRefetch } =
    useTableCRSSWrkYyGrp(
      PrjCd,
      SvyOjt,
      QstCd,
      "",
      "",
      "WRK_YY_GRP",
      SortType,
      "",
      SecondCheck.toString(),
      startDate,
      endDate,
      Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
      Pick.crss_deep_ana_cd
    );

  const { isTableCRSSDocLoading, isTableCRSSDocError, TableCRSSDocData, isTableCRSSDocSuccess, TableCRSSDocRefetch } = useTableCRSSDoc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isTableCRSSOctyLoading, isTableCRSSOctyError, TableCRSSOctyData, isTableCRSSOctySuccess, TableCRSSOctyRefetch } = useTableCRSSOcty(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  const { isTableCRSSSexLoading, isTableCRSSSexError, TableCRSSSexData, isTableCRSSSexSuccess, TableCRSSSexRefetch } = useTableCRSSSex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    "",
    SecondCheck.toString(),
    startDate,
    endDate,
    Pick.deep_ana_cd ? Pick.deep_ana_cd : "",
    Pick.crss_deep_ana_cd
  );

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      PrjDispCntRefetch();
      SelListRefetch();
      QstListRefetch();
    }
    if (SecondCheck.length > 0 && SecondCheck[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        GraphCRSSStandardRefetch();
        GraphCRSSStandardAccuRefetch();
        GraphCRSSNormalRefetch();
        GraphCRSSAssignRefetch();
        GraphCRSSDocRefetch();
        GraphCRSSOctyRefetch();
        GraphCRSSWrkYyGrpRefetch();
        GraphCRSSAgeGrpRefetch();
        GraphCRSSSexRefetch();
        GraphCRSSAreaRefetch();
        GraphCRSSMainDeptRefetch();
        GraphCRSSEtc1Refetch();
        GraphCRSSEtc2Refetch();
        GraphCRSSEtc3Refetch();
      }

      if (RadioTypeCheck === "AgeGrp") {
        GraphCRSSAgeGrpRefetch();
        TableCRSSAgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphCRSSSexRefetch();
        TableCRSSSexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphCRSSAssignRefetch();
        TableCRSSAssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphCRSSDocRefetch();
        TableCRSSDocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphCRSSOctyRefetch();
        TableCRSSOctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphCRSSWrkYyGrpRefetch();
        TableCRSSWrkYyGrpRefetch();
      } else if (RadioTypeCheck === "Area") {
        GraphCRSSAreaRefetch();
      }
    }
  }, [PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, SecondCheck, startDate, endDate]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"CRSS"}
          keyword={Keyword}
          keywordDeepAnaCd={KeywordDeepAnaCd}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={changeSecondCheck}
          selectedFirstCheck={""}
          selectedSecondCheck={SecondCheck}
        />
      </div>
      {KeywordDeepAnaCd && (
        <div className="float-left mb-[1.0rem] h-[3.0rem] w-full text-[2.0rem] font-bold">
          <div className="float-left ml-[2.0rem] h-full w-[2.0rem] bg-reply_png bg-[length:100%_100%] bg-center bg-no-repeat"></div>
          <div className="float-left h-full w-[calc(100%-4.0rem)] leading-[3.2rem]">{KeywordDeepAnaCd}</div>
        </div>
      )}
      {/* DA07용 option component */}
      {Pick.deep_ana_cd === "DA07" && (
        <div className="float-left mb-10 h-full w-full">
          <div className="float-left h-[0.2rem] w-full bg-orange2Color"></div>
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioSel"}
                id={`Radio${Pick.deep_ana_cd}`}
                data={Da07Data}
                changed={changeDA07DA11}
                value={RadioDA07DA11Check}
                onClick={(e) => {
                  changeDA07DA11(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="float-left h-[0.2rem] w-full bg-tableBorderColor"></div>
        </div>
      )}

      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (i === 0) {
              if (CntYn === "Y") {
                return (
                  <div key={`normal_${i}`} className="float-left w-full">
                    <div className="float-left w-[calc(50%-1.0rem)] border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]">
                      <PieChartCrss
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={"N"}
                        type={"pie"}
                        title={SvyOjtNm + " 전체 응답"}
                        qst={Qst}
                        first_check={""}
                        second_check={SecondCheck}
                        Gdata={GraphCRSSStandardData}
                      />
                    </div>
                    <div className="float-left ml-[2.0rem] w-[calc(50%-1.0rem)] border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]">
                      <BarColumnChartCRSS
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={"Y"}
                        type={"column"}
                        title={SvyOjtNm + " 표준 비교"}
                        qst={Qst}
                        name={"standard_accu"}
                        first_check={""}
                        second_check={SecondCheck}
                        Gdata={GraphCRSSStandardAccuData}
                      />
                    </div>
                  </div>
                );
              } else if (Pick.deep_ana_cd === "DA04" || Pick.deep_ana_cd === "DA06") {
                return null;
              } else {
                return (
                  <div
                    key={`normal_${i}`}
                    className="float-left w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]"
                  >
                    <div className="float-left w-full">
                      {Pick.deep_ana_cd === "DA07" ? (
                        <PieChartCrss
                          hsp_nm={HspNm}
                          prj_cd={PrjCd}
                          svy_ojt={SvyOjt}
                          qst_cd={Pick.qst_cd}
                          sel_cd={""}
                          string_value={""}
                          where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                          sort_type={SortType}
                          data_num={RadioNumCheck}
                          data_type={RadioTypeCheck}
                          stack={"N"}
                          type={"pie"}
                          title={SvyOjtNm + " 전체 응답"}
                          qst={Qst}
                          first_check={""}
                          second_check={SecondCheck}
                          Gdata={GraphCRSSStandardData}
                          da07_check={RadioDA07DA11Check}
                        />
                      ) : (
                        <PieChartCrss
                          hsp_nm={HspNm}
                          prj_cd={PrjCd}
                          svy_ojt={SvyOjt}
                          qst_cd={Pick.qst_cd}
                          sel_cd={""}
                          string_value={""}
                          where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                          sort_type={SortType}
                          data_num={RadioNumCheck}
                          data_type={RadioTypeCheck}
                          stack={"N"}
                          type={"pie"}
                          title={SvyOjtNm + " 전체 응답"}
                          qst={Qst}
                          first_check={""}
                          second_check={SecondCheck}
                          Gdata={GraphCRSSStandardData}
                          // da07_check={RadioDA07DA11Check}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            } else {
              if (data.value !== "All") {
                var vTitle = "";
                var vName = "";
                var vStack = "N";
                var vWhere = "";
                var vType = "bar";
                var vData;

                if (SvyOjt === "MEM") {
                  vWhere = "OCTY";
                } else {
                  vWhere = "AGE_GRP";
                }

                if (data.value === "Normal") {
                  vTitle = SvyOjtNm + " 항목별 비교";
                } else if (data.value === "Assign") {
                  vTitle = "보직여부 응답률";
                } else if (data.value === "Doc") {
                  vTitle = "의사직, 구성원 응답률";
                } else if (data.value === "Octy") {
                  vTitle = "직종별 응답률";
                } else if (data.value === "WrkYyGrp") {
                  vTitle = "연차별 응답률";
                } else if (data.value === "Sex") {
                  vTitle = "성별 응답률";
                } else if (data.value === "AgeGrp") {
                  vTitle = "연령대별 응답률";
                } else if (data.value === "MainDept") {
                  vTitle = "방문진료과별 응답률";
                } else if (data.value === "Area") {
                  vTitle = "주거지역별 응답률";
                } else if (data.value === "Etc1") {
                  vTitle =
                    (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                    "별 응답률";
                } else if (data.value === "Etc2") {
                  vTitle =
                    (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                    "별 응답률";
                } else if (data.value === "Etc3") {
                  vTitle =
                    (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                    "별 응답률";
                }

                if (data.value === "Normal") {
                  vName = "basic";
                  vData = GraphCRSSNormalData;
                } else if (data.value === "Assign") {
                  vName = "assign";
                  vData = GraphCRSSAssignData;
                } else if (data.value === "Doc") {
                  vName = "doc";
                  vData = GraphCRSSDocData;
                } else if (data.value === "Octy") {
                  vName = "octy";
                  vData = GraphCRSSOctyData;
                } else if (data.value === "WrkYyGrp") {
                  vName = "wrk";
                  vWhere = "WRK_YY_GRP";
                  vData = GraphCRSSWrkYyGrpData;
                } else if (data.value === "Sex") {
                  vName = "sex";
                  vWhere = "SEX";
                  vData = GraphCRSSSexData;
                } else if (data.value === "AgeGrp") {
                  vName = "age";
                  vWhere = "AGE_GRP";
                  vData = GraphCRSSAgeGrpData;
                } else if (data.value === "MainDept") {
                  vName = "mainDept";
                  vWhere = "MAIN_DEPT_CD";
                  vType = "bar";
                  vData = GraphCRSSMainDeptData;
                } else if (data.value === "Area") {
                  vName = "area";
                  vWhere = "AREA_CD";
                  vType = "bar";
                  vData = GraphCRSSAreaData;
                } else if (data.value === "Etc1") {
                  vName = "etc1";
                  vWhere = "ETC_CD1";
                  vData = GraphCRSSEtc1Data;
                } else if (data.value === "Etc2") {
                  vName = "etc2";
                  vWhere = "ETC_CD2";
                  vData = GraphCRSSEtc2Data;
                } else if (data.value === "Etc3") {
                  vName = "etc3";
                  vWhere = "ETC_CD3";
                  vData = GraphCRSSEtc3Data;
                }

                return (
                  <div
                    key={`normal_${i}`}
                    className={`float-left ${
                      i === 0 ? "" : "mt-[2.0rem]"
                    } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                  >
                    {/* 유형별 분기 */}
                    {/* NPS(일반) */}
                    {Pick.deep_ana_cd === "DA04" && (
                      <BarColumnChartDA04
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        Gdata={vData}
                      />
                    )}

                    {/* 다중NPS(일반) */}
                    {Pick.deep_ana_cd === "DA06" && (
                      <BarColumnChartDA06
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={"All"}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        Gdata={vData}
                      />
                    )}

                    {/* Da07 */}
                    {Pick.deep_ana_cd === "DA07" && (
                      <BarColumnChartCRSS
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        first_check={""}
                        second_check={SecondCheck}
                        Gdata={vData}
                        da07_check={RadioDA07DA11Check}
                      />
                    )}

                    {Pick.deep_ana_cd !== "DA06" && Pick.deep_ana_cd !== "DA04" && Pick.deep_ana_cd !== "DA07" && (
                      <BarColumnChartCRSS
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        first_check={""}
                        second_check={SecondCheck}
                        Gdata={vData}
                        // da07_check={RadioDA07DA11Check}
                      />
                    )}
                  </div>
                );
              }
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTableData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Area") {
              vTitle = "주거지역별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphCRSSNormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphCRSSAssignData;
              vTableData = TableCRSSAssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphCRSSDocData;
              vTableData = TableCRSSDocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphCRSSOctyData;
              vTableData = TableCRSSOctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphCRSSWrkYyGrpData;
              vTableData = TableCRSSWrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphCRSSSexData;
              vTableData = TableCRSSSexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphCRSSAgeGrpData;
              vTableData = TableCRSSAgeGrpData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "MAIN_DEPT_CD";
              vType = "bar";
              vData = GraphCRSSMainDeptData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AREA_CD";
              vType = "bar";
              vData = GraphCRSSAreaData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphCRSSEtc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphCRSSEtc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphCRSSEtc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {/* 유형별 분기 */}
                  {/* NPS(일반) */}

                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    Pick.deep_ana_cd === "DA04" && (
                      <DA04Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTableData}
                      />
                    )}

                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    Pick.deep_ana_cd === "DA06" && (
                      <DA06Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTableData}
                      />
                    )}

                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    Pick.deep_ana_cd !== "DA06" &&
                    Pick.deep_ana_cd !== "DA04" &&
                    Pick.deep_ana_cd !== "DA07" && (
                      <CRSSTable
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        keywordDeepAnaCd={KeywordDeepAnaCd}
                        first_check={""}
                        second_check={SecondCheck}
                        Tdata={vTableData}
                      />
                    )}

                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    Pick.deep_ana_cd === "DA07" && (
                      <CRSSTable
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        keywordDeepAnaCd={KeywordDeepAnaCd}
                        first_check={""}
                        second_check={SecondCheck}
                        Tdata={vTableData}
                        da07_check={RadioDA07DA11Check}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  {Pick.deep_ana_cd === "DA04" && (
                    <BarColumnChartDA04
                      hsp_nm={HspNm}
                      prj_cd={PrjCd}
                      svy_ojt={SvyOjt}
                      qst_cd={Pick.qst_cd}
                      sel_cd={""}
                      string_value={""}
                      where={vWhere}
                      sort_type={SortType}
                      data_num={RadioNumCheck}
                      data_type={RadioTypeCheck}
                      stack={vStack}
                      type={vType}
                      title={vTitle}
                      qst={Qst}
                      name={vName}
                      Gdata={vData}
                    />
                  )}

                  {Pick.deep_ana_cd === "DA06" && (
                    <BarColumnChartDA06
                      hsp_nm={HspNm}
                      prj_cd={PrjCd}
                      svy_ojt={SvyOjt}
                      qst_cd={Pick.qst_cd}
                      sel_cd={"All"}
                      string_value={""}
                      where={vWhere}
                      sort_type={SortType}
                      data_num={RadioNumCheck}
                      data_type={RadioTypeCheck}
                      stack={vStack}
                      type={vType}
                      title={vTitle}
                      qst={Qst}
                      name={vName}
                      Gdata={vData}
                    />
                  )}

                  {/* Da07 */}
                  {Pick.deep_ana_cd === "DA07" && (
                    <BarColumnChartCRSS
                      hsp_nm={HspNm}
                      prj_cd={PrjCd}
                      svy_ojt={SvyOjt}
                      qst_cd={Pick.qst_cd}
                      sel_cd={""}
                      string_value={""}
                      where={vWhere}
                      sort_type={SortType}
                      data_num={RadioNumCheck}
                      data_type={RadioTypeCheck}
                      stack={vStack}
                      type={vType}
                      title={vTitle}
                      qst={Qst}
                      name={vName}
                      first_check={""}
                      second_check={SecondCheck}
                      Gdata={vData}
                      da07_check={RadioDA07DA11Check}
                    />
                  )}

                  {Pick.deep_ana_cd !== "DA06" && Pick.deep_ana_cd !== "DA04" && Pick.deep_ana_cd !== "DA07" && (
                    <BarColumnChartCRSS
                      hsp_nm={HspNm}
                      prj_cd={PrjCd}
                      svy_ojt={SvyOjt}
                      qst_cd={Pick.qst_cd}
                      sel_cd={""}
                      string_value={""}
                      where={vWhere}
                      sort_type={SortType}
                      data_num={RadioNumCheck}
                      data_type={RadioTypeCheck}
                      stack={vStack}
                      type={vType}
                      title={vTitle}
                      qst={Qst}
                      name={vName}
                      first_check={""}
                      second_check={SecondCheck}
                      Gdata={vData}
                      // da07_check={RadioDA07DA11Check}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default CrssPage;
