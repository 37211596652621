import exceljs from "exceljs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useGraphBasic } from "../../hooks/useGraphBasic";
import { useGraphDA01Normal } from "../../hooks/useGraphDA01Normal";
import { useGraphDA03Normal } from "../../hooks/useGraphDA03Normal";
import { useGraphDA04Normal } from "../../hooks/useGraphDA04Normal";
import { useGraphDA05Normal } from "../../hooks/useGraphDA05Normal";
import { useGraphDA06Normal } from "../../hooks/useGraphDA06Normal";
import { useGraphDA07Normal } from "../../hooks/useGraphDA07Normal";
import { useGraphDA08Normal } from "../../hooks/useGraphDA08Normal";
import { useGraphDA09Normal } from "../../hooks/useGraphDA09Normal";
import { useGraphDA10Normal } from "../../hooks/useGraphDA10Normal";
import { useGraphDA11Normal } from "../../hooks/useGraphDA11Normal";
import { useGraphDA12Normal } from "../../hooks/useGraphDA12Normal";
import { useSelList } from "../../hooks/useSelList";
const html2canvas = require("html2canvas");

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const Summary = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  deep_ana_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  title,
  qst,
  menu_cate,
  qst_hsp_sel_cd,
  startDate,
  endDate,
}) => {
  const [FirstCheck, setFirstCheck] = useState([]);
  const [SecondCheck, setSecondCheck] = useState([]);
  const [DA08Check, setDA08Check] = useState("");
  const [DA08CheckNm, setDA08CheckNm] = useState("");
  const [OptionCnt, setOptionCnt] = useState(0);
  Highcharts.setOptions({
    colors: ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"],
  });

  const chart = useRef();

  const convertToCSV = () => {
    const currChart = chart.current.chart;
    const csvData = currChart.getCSV();

    return csvData;
  };

  const downloadChart = (type) => {
    if (chart && chart.current && chart.current.chart) {
      if (type === "jpeg") {
        chart.current.chart.exportChart({ type: "image/jpeg" });
      } else if (type === "png") {
        chart.current.chart.exportChart({ type: "image/png" });
      } else if (type === "pdf") {
        chart.current.chart.exportChart({
          type: "application/pdf",
        });
      } else {
        // 엑셀 워크북 생성
        let workbook = new exceljs.Workbook();
        // 시트 생성
        const imageSheet = workbook.addWorksheet("DataSheet");

        // 텍스트 데이터 csv로 변환
        const csvData = convertToCSV();
        // 불필요한 특수문자 제거 (")
        let filteredCsvData = csvData.replace(/\"/gi, "");

        // excelJs에 맞는 형식으로 전환
        let excelData = [];

        // 개행태그를 기준으로 split
        let dataArr = filteredCsvData.split("\n");

        // ,단위로 split하여 배열로 만든 후, 덩어리를 excelData에 push
        for (let i = 0; i < dataArr.length; i++) {
          let temp = dataArr[i].split(",");

          excelData.push(temp);
        }

        // 시트에 추가
        imageSheet.addRows(excelData);

        // 차트 이미지 작업을 위한 ref 속성 가져오기
        const chartContainer = chart.current.container.current;

        let promise = [];

        // 차트이미지를 스크린샷 찍어 이미지를 시트에 추가
        promise.push(
          html2canvas(chartContainer).then((c) => {
            let image = c.toDataURL();
            const imageId2 = workbook.addImage({
              base64: image,
              extension: "png",
            });

            imageSheet.addImage(imageId2, {
              tl: { col: 0, row: excelData.length + 3 },
              br: { col: chartContainer.offsetWidth / 100, row: excelData.length + 12 + chartContainer.offsetHeight / 100 },
            });
          })
        );

        // 파일 다운로드
        Promise.all(promise).then(() => {
          workbook.xlsx.writeBuffer().then((b) => {
            let a = new Blob([b]);
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement("a");
            elem.href = url;
            elem.download = `${qst}.xlsx`;
            document.body.appendChild(elem);
            elem.style = "display: none";
            elem.click();
            elem.remove();
          });
        });

        return workbook;
      }
    }
  };

  const initialOptions = {
    title: { text: "" },
    exporting: {
      scale: 2,
      enabled: false,
      filename: qst + "_" + title,
      chartOptions: {
        chart: {
          spacing: [30, 10, 30, 50],
        },
        xAxis: {
          labels: {
            style: {
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              fontSize: "12px",
            },
          },
        },
        yAxis: {
          labels: {
            style: {
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              fontSize: "12px",
            },
          },
        },
        legend: {
          itemStyle: {
            fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            fontSize: "12px",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontSize: "12px",
              },
            },
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: { fontSize: "1.4rem", textShadow: false, textOutLine: false },
          format: "{y}",
          border: null,
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: "",
        pointWidth: 30,
      },
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: "",
        pointWidth: 30,
      },
    },
    credits: { enabled: false },
    tooltip: {
      style: {
        fontSize: "1.4rem",
      },
    },
    xAxis: {},
    yAxis: {},
    legend: {
      enabled: false,
      itemStyle: {
        fontSize: "1.4rem",
      },
    },
    series: [], // 데이터가 처음엔 비어았다.
  };

  const [options, setOptions] = useState({});

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(prj_cd, svy_ojt, qst_cd);

  useEffect(() => {
    if (SelListData) {
      var vTemp = SelListData.slice().map((data) => data.value);

      var vTemp2 = qst_hsp_sel_cd ? qst_hsp_sel_cd : "";
      var vTemp3 =
        vTemp2 && SelListData.filter((x) => x.sel_CD === vTemp2) && SelListData.filter((x) => x.sel_CD === vTemp2).length > 0
          ? SelListData.filter((x) => x.sel_CD === vTemp2)[0].sel_NM
          : "";
      setFirstCheck([...vTemp]);
      setSecondCheck([...vTemp]);
      setDA08Check(vTemp[0]);
      setDA08CheckNm(vTemp3);
    }
  }, [SelListData, isSelListSuccess, prj_cd, svy_ojt, qst_cd, qst_hsp_sel_cd]);

  const { isGraphBasicLoading, isGraphBasicError, GraphBasicData, isGraphBasicSuccess, GraphBasicRefetch } = useGraphBasic(
    prj_cd,
    svy_ojt,
    qst_cd,
    "",
    "",
    svy_ojt === "MEM" ? "OCTY" : "AGE_GRP",
    sort_type,
    startDate,
    endDate
  );

  const { isGraphDA01NormalLoading, isGraphDA01NormalError, GraphDA01NormalData, isGraphDA01NormalSuccess, GraphDA01NormalRefetch } =
    useGraphDA01Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA03NormalLoading, isGraphDA03NormalError, GraphDA03NormalData, isGraphDA03NormalSuccess, GraphDA03NormalRefetch } =
    useGraphDA03Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA04NormalLoading, isGraphDA04NormalError, GraphDA04NormalData, isGraphDA04NormalSuccess, GraphDA04NormalRefetch } =
    useGraphDA04Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA05NormalLoading, isGraphDA05NormalError, GraphDA05NormalData, isGraphDA05NormalSuccess, GraphDA05NormalRefetch } =
    useGraphDA05Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA06NormalLoading, isGraphDA06NormalError, GraphDA06NormalData, isGraphDA06NormalSuccess, GraphDA06NormalRefetch } =
    useGraphDA06Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA07NormalLoading, isGraphDA07NormalError, GraphDA07NormalData, isGraphDA07NormalSuccess, GraphDA07NormalRefetch } =
    useGraphDA07Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA08NormalLoading, isGraphDA08NormalError, GraphDA08NormalData, isGraphDA08NormalSuccess, GraphDA08NormalRefetch } =
    useGraphDA08Normal(prj_cd, svy_ojt, qst_cd, "S001", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA09NormalLoading, isGraphDA09NormalError, GraphDA09NormalData, isGraphDA09NormalSuccess, GraphDA09NormalRefetch } =
    useGraphDA09Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA10NormalLoading, isGraphDA10NormalError, GraphDA10NormalData, isGraphDA10NormalSuccess, GraphDA10NormalRefetch } =
    useGraphDA10Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA11NormalLoading, isGraphDA11NormalError, GraphDA11NormalData, isGraphDA11NormalSuccess, GraphDA11NormalRefetch } =
    useGraphDA11Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  const { isGraphDA12NormalLoading, isGraphDA12NormalError, GraphDA12NormalData, isGraphDA12NormalSuccess, GraphDA12NormalRefetch } =
    useGraphDA12Normal(prj_cd, svy_ojt, qst_cd, "", "", "OCTY", sort_type, startDate, endDate);

  useEffect(() => {
    // if (deep_ana_cd === "DA04") {
    //   SelListRefetch();
    //   // console.log("DA04");
    //   GraphDA04NormalRefetch();
    // }

    if (deep_ana_cd === "") {
      // console.log("default");
      SelListRefetch();

      GraphBasicRefetch();
    } else if (deep_ana_cd === "DA01") {
      // console.log("DA01");
      SelListRefetch();

      GraphDA01NormalRefetch();
    } else if (deep_ana_cd === "DA03") {
      SelListRefetch();
      // console.log("DA03");
      GraphDA03NormalRefetch();
    } else if (deep_ana_cd === "DA04") {
      SelListRefetch();
      // console.log("DA04");
      GraphDA04NormalRefetch();
    } else if (deep_ana_cd === "DA05") {
      SelListRefetch();
      // console.log("DA05");
      GraphDA05NormalRefetch();
    } else if (deep_ana_cd === "DA06") {
      SelListRefetch();
      // console.log("DA06");
      GraphDA06NormalRefetch();
    } else if (deep_ana_cd === "DA07") {
      SelListRefetch();
      // console.log("DA07");
      GraphDA07NormalRefetch();
    } else if (deep_ana_cd === "DA08") {
      // console.log("DA08");
      SelListRefetch();
      GraphDA08NormalRefetch();
    } else if (deep_ana_cd === "DA09") {
      SelListRefetch();
      // console.log("DA09");
      SelListRefetch();
      GraphDA09NormalRefetch();
    } else if (deep_ana_cd === "DA10") {
      SelListRefetch();
      // console.log("DA10");
      GraphDA10NormalRefetch();
    } else if (deep_ana_cd === "DA11") {
      SelListRefetch();
      // console.log("DA11");
      GraphDA11NormalRefetch();
    } else if (deep_ana_cd === "DA12") {
      SelListRefetch();
      // console.log("DA12");
      GraphDA12NormalRefetch();
    }
  }, [qst_cd, startDate, endDate]);

  useEffect(() => {
    if (!deep_ana_cd || menu_cate !== "심화문항") {
      if (GraphBasicData !== null && GraphBasicData !== undefined && GraphBasicData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];

        GraphBasicData.map((data, i) => {
          tempSeriesData.push({
            name: data.sel_NM,
            y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
            dataLabels: {
              shadow: false,
              fontSize: "1.4rem",
            },
            color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
          });
          tempCategoriesData.push(data.sel_NM ? data.sel_NM : "");
          // tempCategoriesData.push(data.sel_NM);
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        if (
          GraphBasicData.filter(
            (x) =>
              x.b_RATE !== "" &&
              x.b_RATE !== null &&
              x.b_RATE !== "0.0" &&
              x.b_RATE !== "0" &&
              x.w_RATE !== "" &&
              x.w_RATE !== null &&
              x.w_RATE !== "0.0" &&
              x.w_RATE !== "0"
          ).length > 0
        ) {
          GraphBasicData.map((data, i) => {
            tempSeriesDataBest.push({
              name: data.sel_NM,
              y: data_num === "1" ? (data.b_RATE ? parseFloat(data.b_RATE) : 0) : data.b_RATE_CNT ? parseFloat(data.b_RATE_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontSize: "1.4rem",
              },
            });
          });

          tempSeries.push({
            name: "Best",
            data: tempSeriesDataBest,
          });

          GraphBasicData.map((data, i) => {
            tempSeriesDataWorst.push({
              name: data.sel_NM,
              y: data_num === "1" ? (data.w_RATE ? parseFloat(data.w_RATE) : 0) : data.w_RATE_CNT ? parseFloat(data.w_RATE_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontSize: "1.4rem",
              },
            });
          });

          tempSeries.push({
            name: "Worst",
            data: tempSeriesDataWorst,
          });
        }

        let Bcount = tempSeriesDataBest.length > 0 ? 1 : 0;
        let Wcount = tempSeriesDataWorst.length > 0 ? 1 : 0;

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth:
                    600 / (tempCategoriesData.length * (tempSeries.length + Bcount + Wcount)) > 50
                      ? 50
                      : 600 / (tempCategoriesData.length * (tempSeries.length + Bcount + Wcount)),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: (tempSeriesData.length + tempSeriesDataBest.length + tempSeriesDataWorst.length) / tempCategoriesData.length > 1 ? true : false,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth:
                600 / (tempCategoriesData.length * (tempSeries.length + Bcount + Wcount)) > 50
                  ? 50
                  : 600 / (tempCategoriesData.length * (tempSeries.length + Bcount + Wcount)),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth:
                600 / (tempCategoriesData.length * (tempSeries.length + Bcount + Wcount)) > 50
                  ? 50
                  : 600 / (tempCategoriesData.length * (tempSeries.length + Bcount + Wcount)),
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA01") {
      if (GraphDA01NormalData !== null && GraphDA01NormalData !== undefined && GraphDA01NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA01NormalData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
          tempCategoriesData.map((dd, i) => {
            tempCategoriesAssignData.push({
              name: dd,
              categories: ["상승이유", "하락이유"],
            });
          });
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];
          GraphDA01NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: "상승이유",
                y: data_num === "1" ? (data.tot ? parseFloat(data.tot) : 0) : data.tot_CNT ? parseFloat(data.tot_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
              tempSeriesData.push({
                name: "하락이유",
                y: data_num === "1" ? (data.tot2 ? parseFloat(data.tot2) : 0) : data.tot2_CNT ? parseFloat(data.tot2_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 30,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 250 / (tempSeries.length * tempCategoriesData.length) > 40 ? 40 : 250 / (tempSeries.length * tempCategoriesData.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 250 / (tempSeries.length * tempCategoriesData.length) > 40 ? 40 : 250 / (tempSeries.length * tempCategoriesData.length),
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA03") {
      if (GraphDA03NormalData !== null && GraphDA03NormalData !== undefined && GraphDA03NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA03NormalData.map((data, i) => {
          tempCategoriesData.push("전체");
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
          tempCategoriesData.map((dd, i) => {
            tempCategoriesAssignData.push({
              name: dd,
              categories: ["본인<br>직종", "다른<br>직종"],
            });
          });
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];
          GraphDA03NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: "본인<br>직종",
                y: data_num === "1" ? (data.self ? parseFloat(data.self) : 0) : data.self_CNT ? parseFloat(data.self_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: "",
              });
              tempSeriesData.push({
                name: "다른<br>직종",
                y: data_num === "1" ? (data.nonself ? parseFloat(data.nonself) : 0) : data.nonself_CNT ? parseFloat(data.nonself_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: "#ecb758",
              });
            });

          tempSeries.push({
            name: hsp_nm,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 100 / tempCategoriesData.length > 50 ? 50 : 100 / tempCategoriesData.length,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA04") {
      if (GraphDA04NormalData !== null && GraphDA04NormalData !== undefined && GraphDA04NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA04NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA04NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "bar",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA05") {
      if (GraphDA05NormalData !== null && GraphDA05NormalData !== undefined && GraphDA05NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA05NormalData.map((dd, ii) => {
          tempCategoriesData.push(dd.assign);
        });
        GraphDA05NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA05NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 500 / tempCategoriesData.length >= 50 ? 50 : 500 / tempCategoriesData.length > 50,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA06") {
      if (GraphDA06NormalData !== null && GraphDA06NormalData !== undefined && GraphDA06NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA06NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData?.map((dd, ii) => {
          GraphDA06NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "bar",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA07") {
      if (GraphDA07NormalData !== null && GraphDA07NormalData !== undefined && GraphDA07NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA07NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA07NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 30,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA08") {
      if (GraphDA08NormalData !== null && GraphDA08NormalData !== undefined && GraphDA08NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA08NormalData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.answ_CD + "위").length === 0) {
            tempCategoriesData.push(data.answ_CD + "위");
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.sel_NM);
          }
        });

        tempSeriesData = [];
        GraphDA08NormalData
          //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
          .map((data, i) => {
            tempSeriesData.push({
              name: data.answ_CD + "위",
              y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontSize: "1.4rem",
              },
            });
          });

        tempSeries.push({
          name: DA08CheckNm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 500 / tempCategoriesData.length >= 50 ? 50 : 500 / tempCategoriesData.length > 50,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
              },
              stacking: "",
              // pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50,
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA09") {
      if (GraphDA09NormalData !== null && GraphDA09NormalData !== undefined && GraphDA09NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA09NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA09NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" || dd === "전체-전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",

                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",

                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA10") {
      if (GraphDA10NormalData !== null && GraphDA10NormalData !== undefined && GraphDA10NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA10NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA10NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 500 / tempCategoriesData.length >= 50 ? 50 : 500 / tempCategoriesData.length > 50,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",

                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA11") {
      if (GraphDA11NormalData !== null && GraphDA11NormalData !== undefined && GraphDA11NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA11NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA11NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 500 / tempCategoriesData.length >= 50 ? 50 : 500 / tempCategoriesData.length > 50,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (deep_ana_cd === "DA12") {
      if (GraphDA12NormalData !== null && GraphDA12NormalData !== undefined && GraphDA12NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA12NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA12NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: "column",
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 15, 30, 15],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: "",
                  pointWidth: 500 / tempCategoriesData.length >= 50 ? 50 : 500 / tempCategoriesData.length > 50,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 600 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length > 50 ? 50 : 500 / tempCategoriesData.length,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 40,
            },
          },
        });
      }
    }
  }, [
    GraphBasicData,
    GraphDA01NormalData,
    GraphDA03NormalData,
    GraphDA04NormalData,
    GraphDA05NormalData,
    GraphDA06NormalData,
    GraphDA07NormalData,
    GraphDA08NormalData,
    GraphDA09NormalData,
    GraphDA10NormalData,
    GraphDA11NormalData,
    GraphDA12NormalData,
    prj_cd,
    svy_ojt,
    qst_cd,
    deep_ana_cd,
    sort_type,
    data_num,
    sel_cd,
    title,
    qst,
    menu_cate,
    FirstCheck,
    SecondCheck,
    DA08Check,
  ]);

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      setOptionCnt(options.xAxis.categories.length);
    }
  }, [options]);

  return (
    <div className={`float-left ${OptionCnt > 4 ? "w-full" : "w-[50%]"} bg-white p-[1.0rem]`}>
      <div className="float-left h-full w-full border-[0.1rem] border-tableBorderColor">
        <div
          className={`float-left h-[3.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor bg-white pl-[1.0rem] pr-[1.0rem] ${
            title !== null && title !== undefined && title !== "" && qst !== null && qst !== undefined && qst !== "" ? "" : "hidden"
          }`}
        >
          <div className="float-left mt-[1.0rem] h-[1.0rem] w-[1.0rem] bg-orangeColor"></div>
          <div className="float-left ml-[1.0rem] h-full w-[calc(100%-17.0rem)] overflow-hidden text-ellipsis break-all text-[1.8rem] font-bold leading-[3.0rem]">
            {title}
          </div>
          <div className="float-left h-full w-[15.0rem] leading-[6.0rem]">
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_png bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("png");
              }}
            ></div>
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_pdf bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("pdf");
              }}
            ></div>
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_jpeg bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("jpeg");
              }}
            ></div>
            <div
              className="float-right mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_excel bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("excel");
              }}
            ></div>
          </div>
        </div>
        <div className="float-left h-[8.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor p-[1.0rem] text-[1.6rem] leading-[2.0rem]">
          {qst}
        </div>
        <div className="float-left w-full">
          {/* {`1. ${Object.keys(options.xAxis).length}`}
          {`2. ${options.xAxis.categories}`} */}
          {/* {Object.keys(options.xAxis).length > 0 && ( */}
          {Object.keys(options).length > 0 ? (
            <HighchartsReact
              key={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + sort_type + "_" + data_num + "_" + deep_ana_cd}`}
              id={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + sort_type + "_" + data_num + "_" + deep_ana_cd}`}
              ref={chart}
              highcharts={Highcharts}
              options={options}
              allowChartUpdate={true}
            />
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center p-20">
              <ClipLoader color="#1686FF" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
