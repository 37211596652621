import { useEffect } from "react";
import {
  useRankSelListNm,
  useRankSelListState,
  useSelectQstState,
  useSelListModalState,
} from "../../store/mobile/store";

const RankSelListModal = (props) => {
  const selListModalState = useSelListModalState(
    (state) => state.selListModalState
  );
  const setSelListModalState = useSelListModalState(
    (state) => state.setSelListModalState
  );
  const setSelNm = useRankSelListNm((state) => state.setRankSelNmState);
  const rankSelList = useRankSelListState((state) => state.rankSelListState);
  const selectQstState = useSelectQstState((state) => state.selectQstState);

  const setRankSelList = useRankSelListState(
    (state) => state.setRankSelListState
  );

  const handleClickCheckedList = (sel_cd) => {
    setSelListModalState(false);
  };

  const handleClickList = (sel_cd, sel_nm) => {
    setSelNm(sel_nm);
    setRankSelList(sel_cd);
    setSelListModalState(false);
  };

  useEffect(() => {
    props?.data.forEach((el, idx) => {
      if (el.selCd === rankSelList) {
        setSelNm(el.selNm);
      }
    });
  }, [props]);

  useEffect(() => {
    return () => {
      setSelListModalState(false);
      setRankSelList("A");
    };
  }, [selectQstState]);

  return (
    <div
      className={
        selListModalState
          ? `absolute -top-[0px] right-0 z-10  flex h-fit w-fit  flex-col items-center justify-start rounded-sm border  border-t-0 border-tableBorderColor/50 bg-white py-3 font-SDGothic text-[14px] text-selectTextColor/80 drop-shadow-lg duration-500 ease-in-out`
          : `absolute -top-[500px] right-0 z-10   flex h-fit w-fit  flex-col items-center justify-start rounded-sm border border-tableBorderColor/50 bg-white py-3 font-SDGothic text-[14px] text-selectTextColor/80 drop-shadow-lg duration-500 ease-in-out`
      }
    >
      {props?.data?.map((el, idx) => {
        if (rankSelList === el.selCd) {
          return (
            <div
              key={idx}
              onClick={() => handleClickCheckedList(el.selCd, el.selNm)}
              className="flex h-fit w-full flex-col items-start justify-center rounded-sm bg-tableHeaderColor px-7 py-4 text-orangeColor"
            >
              {el.selNm}
            </div>
          );
        } else {
          return (
            <div
              key={idx}
              onClick={() => handleClickList(el.selCd, el.selNm)}
              className="flex h-fit w-full flex-col items-start justify-center rounded-sm px-7 py-4"
            >
              {el.selNm}
            </div>
          );
        }
      })}
    </div>
  );
};

export default RankSelListModal;
