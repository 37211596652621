import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
// import { ageDict, workDict } from "../../../dict/mobile/dict";
import useWindowSize from "../../../hooks/useWindowSize";
import { useChartDataState, useComnDict, useMenuState, useSelListState } from "../../../store/mobile/store";

const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"];

const Da06Chart = (props) => {
  const width = useWindowSize();

  const selListState = useSelListState((state) => state.selListState);

  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  let selList =
    selListState !== "전체"
      ? [props.selList?.filter((el) => el.sel_CD === selListState)[0].sel_NM]
      : props.selList
      ? ["전체", ...props?.selList.map((el) => el.sel_NM)]
      : [];

  const menuState = useMenuState((state) => state.menuVal);
  const da06ChartDataState = useChartDataState((state) => state.chartData);

  let da06ChartData = props?.data === null ? da06ChartDataState : props?.data;

  const [initData, setInitData] = useState([]);

  useEffect(() => {
    if (selListState === "전체") {
      setInitData(da06ChartData?.slice(1, da06ChartData.length));
    } else {
      setInitData(da06ChartData);
    }
  }, [da06ChartData]);

  let seriesData = [];
  let minVal = 0;
  let maxVal = 0;

  if (menuState === "MEM") {
    workDict.forEach((el, idx) => {
      let temp = [];
      initData.forEach((data) => {
        temp.push(data[el.sel_cd]);

        // 최솟값, 최댓값 구하기
        if (data[el.sel_cd] > maxVal) {
          maxVal = data[el.sel_cd];
        } else if (data[el.sel_cd] < minVal) {
          minVal = data[el.sel_cd];
        }
      });
      seriesData.push({
        name: el.sel_nm,
        data: temp,
        color: colors[idx],
      });
    });
  } else if (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") {
    ageDict.forEach((el, idx) => {
      let temp = [];
      initData.forEach((data) => {
        temp.push(data[el.sel_cd]);

        // 최솟값, 최댓값 구하기
        if (data[el.sel_cd] > maxVal) {
          maxVal = data[el.sel_cd];
        } else if (data[el.sel_cd] < minVal) {
          minVal = data[el.sel_cd];
        }
      });
      seriesData.push({
        name: el.sel_nm,
        data: temp,
        color: colors[idx],
      });
    });
  }

  const options = {
    chart: {
      type: "bar",
      style: {},
      width: width !== 0 ? width * 0.95 : null,
      //   height: 350,
      height: selList.length > 7 ? 2000 : selList.length > 5 ? 1800 : selList.length > 3 ? 900 : selList.length > 1 ? 600 : 400,
      spacingBottom: 50,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
      style: {
        fontSize: 16,
        color: "#383838",
      },
    },
    xAxis: {
      marginTop: 10,
      tickLength: 3,
      lineWidth: 0.2,
      lineColor: "#383838",
      title: {
        text: null,
      },
      labels: {
        // useHTML: true,
        // formatter: function () {
        //   return `<span style="border: none;">${this.value}</span>`;
        // },
        style: {
          fontSize: 14,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        y: 4,
      },
      gridLineWidth: 0,
      categories: selList,
    },
    yAxis: {
      min: maxVal === 0 ? -100 : minVal, // data의 min 값
      max: minVal === 0 ? 100 : maxVal, // data의 max 값
      tickInterval: 10,

      title: {
        text: null,
      },
      visible: false,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    series: seriesData,
    plotOptions: {
      series: {
        pointWidth: 14,
        groupPadding: 0.09,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        const legendValue = series.name;

        return `<b style="font-size : 9px;">${this.x} </br> </b> <span style="color: ${color};">&#9679;</span> ${legendValue} : <b>${this.y}</b>`;
      },
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: true,
      reversed: false,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
  };
  return <>{width ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}</>;
};

export default Da06Chart;
