import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loading from "../../components/pc/Loading";
import useStore from "../../store/pc/store";
import AdminProject from "./AdminProject";
import AdminQst from "./AdminQst";
import AdminQstStandard from "./AdminQstStandard";
import AdminSentense from "./AdminSentense";
import AdminUser from "./AdminUser";

const AdminProjectPage = () => {
  const [loading, setLoading] = useState(false);
  const LoginId = useStore((state) => state.LoginId);

  const navigate = useNavigate();

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div>{loading ? <Loading /> : null}</div>
      <Routes>
        <Route key="/project" path="/project" element={<AdminProject />} />
        <Route key="/user/*" path="/user/*" element={<AdminUser />} />
        <Route key="/qst/standard" path="/qst/standard" element={<AdminQstStandard />} />
        <Route key="/qst" path="/qst" element={<AdminQst />} />
        <Route key="/sentense" path="/sentense" element={<AdminSentense />} />
      </Routes>
    </>
  );
};

export default AdminProjectPage;
