import { useQuery } from "@tanstack/react-query";

import getAdminStandardExist from "../../../api/Admin/Standard/getAdminStandardExist";

const useGetAdminStandardExist = (prj_cd) => {
  const fallback = [];

  const { data = fallback, isLoading } = useQuery({
    queryKey: ["adminStandardExist", prj_cd],
    queryFn: () => getAdminStandardExist(prj_cd),
    onSuccess: () => {},
    staleTime: 0,
    gcTime: 0,
  });

  return { data, isLoading };
};

export default useGetAdminStandardExist;
