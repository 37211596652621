import { useMutation, useQueryClient } from "@tanstack/react-query";
import updateAdminIntroGuide from "../api/updateAdminIntroGuide";

const useUpdateAdminIntroGuide = () => {
  const queryClient = useQueryClient();

  return useMutation(updateAdminIntroGuide, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminGuide"]);
      alert("수정되었습니다");
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useUpdateAdminIntroGuide;
