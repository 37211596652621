import { useQuery } from "@tanstack/react-query";
import getAIChartRes from "../api/getAIChartRes";
import { useAiExtractionDataState, useMergedStrState } from "../store/mobile/store";
import usePostExtractKeyword from "./usePostExtractKeyword";

const useGetAIChartRes = (prj_cd, qst_cd, svy_ojt, filterOpt, date) => {
  const mergedStr = useMergedStrState((state) => state.mergedStr);
  const { mutate } = usePostExtractKeyword();
  // const { mutate } = usePostKeywordGpt();
  const setAiExtractionData = useAiExtractionDataState((state) => state.setAiExtractionData);

  return useQuery(["aiChart"], () => getAIChartRes(prj_cd, qst_cd, svy_ojt, filterOpt, date), {
    enabled: false,
    onSuccess: (res) => {
      if (res.data.length === 0) {
        // ** 기존 로직 (Eden AI)*/
        const body = {
          providers: "ibm",
          text: mergedStr,
          language: "ko",
        };

        mutate(body);
      } else {
        let data = res.data.map((el, idx) => {
          return {
            name: el.keyword,
            weight: el.importance,
            color: el.color,
          };
        });

        setAiExtractionData(data);
      }
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAIChartRes;
