import { useRef, useState } from "react";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea";

const AdminProChatComp = ({ prj_cd, content, user_id, inpt_dtm, status }) => {
  const [value, setValue] = useState("테스트문구입니다");
  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef, content);

  const [modal, setModal] = useState(false);

  return (
    <div
      className={
        status === "D"
          ? `relative mt-5 flex h-fit w-[80%] flex-col  items-start justify-start rounded-b-xl rounded-r-xl border border-FilterInActiveColor bg-FilterInActiveColor/80 p-5 text-[16px] font-bold text-white`
          : `relative mt-5 flex h-fit w-[80%] flex-col  items-start justify-start rounded-b-xl rounded-r-xl border border-FilterInActiveColor bg-white p-5 text-[16px] font-bold text-selectTextColor/80`
      }
    >
      <div className="flex h-fit w-fit flex-col items-center justify-center p-3">{prj_cd}</div>
      <textarea
        readOnly
        spellCheck="false"
        ref={textAreaRef}
        value={content}
        className={`hide-scrollbar flex h-fit w-[90%] resize-none flex-col items-start justify-start bg-transparent p-3 leading-snug outline-none`}
      ></textarea>
      <div className="flex h-fit w-full flex-col items-end justify-end p-3 pb-0">{`${user_id} / ${inpt_dtm}`}</div>
    </div>
  );
};

export default AdminProChatComp;
