import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import DefaultChart from "../../components/mobile/chart/DefaultChart";
import TabBar from "../../components/mobile/common/TabBar";
import DefaultTable from "../../components/mobile/table/DefaultTable";
import useGetDefaultRes from "../../hooks/useGetDefaultRes";
import { useMenuState, usePeriodState, usePrjValState, useSelectQstState, useUnitState } from "../../store/mobile/store";

const Defualt = () => {
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const prjVal = usePrjValState((state) => state.prjVal);

  const menuState = useMenuState((state) => state.menuVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);

  const [rendering, setRendering] = useState(false);

  const { data, refetch, isLoading } = useGetDefaultRes(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.svy_OJT,
    selectQstState.sort_TYPE,
    startDate,
    endDate
  );

  // 최초 데이터
  let initData = data?.data;
  // chart 컴포넌트로 보낼 props 데이터 (객체)
  let propsData = {};
  // table 컴포넌트로 보낼 props 데이터 (배열)
  let tableData = [];

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  // xaxis.categories
  // 카테고리 (전체, 의사직, 간호직 ...)
  let selNmData = initData?.map((el) => el.sel_NM);
  const selNmSet = new Set(selNmData);
  const uniqueSelNmData = [...selNmSet]; // props : categories

  // 차트에 props로 전달될 categories
  const categories = [];

  // assignData
  // 보기, 문항...
  let assignData = initData?.map((el) => el.assign);
  const assignSet = new Set(assignData);
  const uniqueAssignData = [...assignSet];
  // console.log(uniqueAssignData);

  uniqueAssignData.forEach((el) => (propsData[el] = []));

  // chart props를 생성하는 알고리즘
  uniqueSelNmData?.forEach((el, idx) => {
    // 각 카테고리별 필터링한 데이터 추출
    let filteredData = initData?.filter((data) => data.sel_NM === el);

    // 연령대 별, 직종 별 데이터의 값이 모두 null 값인지 판별 (차트에서 걸러주기 위한 작업)
    let tempRes = filteredData.filter((el) => +el.cnt > 0);

    // tempRes에 값이 있을 경우 (데이터가 모두 null이 아닌 경우)
    if (tempRes.length > 0) {
      // categories에 sel_NM 삽입
      categories.push(tempRes[0].sel_NM);
    }

    // 각 카테고리별 테이블 배열 생성
    let tempArr = [];
    // 카테고리 이름 배열 삽입
    tempArr.push(filteredData[0].sel_NM);

    let sum = filteredData.map((el) => +el.cnt).reduce((a, b) => a + b);
    tempArr.push(sum);

    // 보기, 문항을 돌면서 해당 값이 없으면 null, 있으면 rate 값 propsData에 push
    uniqueAssignData.forEach((el) => {
      let res = filteredData.filter((data) => data.assign === el);

      if (+res[0].rate > 0) {
        // chart data
        if (tempRes.length > 0) {
          {
            unitState === "rate" ? propsData[el].push(parseFloat(res[0].rate)) : propsData[el].push(parseFloat(res[0].cnt));
          }
        }

        // table data
        {
          unitState === "rate" ? tempArr.push(parseFloat(res[0].rate)) : tempArr.push(parseFloat(res[0].cnt));
        }
      } else {
        // chart data
        if (tempRes.length > 0) {
          propsData[el].push(null);
        }
        // table data
        tempArr.push(0);
      }
    });
    tableData.push(tempArr);
  });

  useEffect(() => {
    refetch();
    // setRendering(false);

    setTimeout(() => {
      setRendering(true);
    }, 300);
  }, [selectQstState, startDate, endDate]);

  return (
    <>
      {!rendering || isLoading ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}

      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {selectQstState.keyword}
        </div>
        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="border border-l-rose-600 text-lime-600 accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>
          {!isLoading ? (
            <>
              <DefaultChart data={propsData} assignData={uniqueAssignData} categories={categories} />
              <DefaultTable assignData={uniqueAssignData} data={tableData} />
            </>
          ) : null}
        </div>
        {/* {`문항 : ${selectQstState.keyword} / deep_ANA_CD : ${selectQstState.deep_ANA_CD} / qst_cd : ${selectQstState.qst_CD} / sort_type : ${selectQstState.sort_TYPE}`} */}
        <TabBar />
      </div>
    </>
  );
};

export default Defualt;
