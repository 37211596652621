import { HiPlus } from "react-icons/hi";
import { useMenuState } from "../../../store/mobile/store";

const InactiveFilterBtn = (props) => {
  const menuState = useMenuState((state) => state.menuVal);

  const handleClickBtn = () => {
    if (props?.opt !== "0") {
      let list = [...props.list];
      if (props.name === "전체") {
        if (menuState === "MEM") {
          props.setList(["01", "02", "03", "04", "05", "06", "07"]);
        } else {
          props.setList(["10", "20", "30", "40", "50", "60", "70", "80"]);
        }
      } else {
        if (menuState === "MEM") {
          if (list.length === 7) {
            props.setList([props.code]);
          } else {
            list.push(props.code);
            props.setList(list);
          }
        } else {
          if (list.length === 8) {
            props.setList([props.code]);
          } else {
            list.push(props.code);
            props.setList(list);
          }
        }
      }
    } else {
      props?.setAlert(true);

      setTimeout(() => {
        props?.setAlert(false);
      }, 1500);
    }
  };

  return (
    <div
      onClick={handleClickBtn}
      className="mr-5 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] border-[1.5px] border-FilterInActiveColor bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/60"
    >
      <HiPlus className="mr-2 text-[18px] text-selectTextColor/60" />
      <div className="flex h-fit w-fit flex-col items-center justify-center">
        {props.name}
      </div>
    </div>
  );
};

export default InactiveFilterBtn;
