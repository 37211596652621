const menuDict = [
  {
    id: 1,
    value: "MEM",
    label: "구성원",
  },
  {
    id: 2,
    value: "VST",
    label: "내원고객",
  },
  {
    id: 3,
    value: "LRES",
    label: "지역주민",
  },
  {
    id: 4,
    value: "DEP",
    label: "대상간",
  },
];

const menuCSDict = [
  {
    id: 1,
    value: "MEM",
    label: "구성원",
  },
  {
    id: 2,
    value: "CS",
    label: "고객만족",
  },
  {
    id: 3,
    value: "RS",
    label: "환자경험",
  },
];

const amtDict = [
  {
    id: 1,
    value: "1",
    label: "비중(%)",
  },
  {
    id: 2,
    value: "2",
    label: "인원(명)",
  },
];

export { menuDict, menuCSDict, amtDict };
