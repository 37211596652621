import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA02 from "../../components/pc/BarColumnChartDA02";
import DA02Table from "../../components/pc/DA02Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA02AgeGrp } from "../../hooks/useGraphDA02AgeGrp";
import { useGraphDA02Area } from "../../hooks/useGraphDA02Area";
import { useGraphDA02Assign } from "../../hooks/useGraphDA02Assign";
import { useGraphDA02Doc } from "../../hooks/useGraphDA02Doc";
import { useGraphDA02Etc1 } from "../../hooks/useGraphDA02Etc1";
import { useGraphDA02Etc2 } from "../../hooks/useGraphDA02Etc2";
import { useGraphDA02Etc3 } from "../../hooks/useGraphDA02Etc3";
import { useGraphDA02MainDept } from "../../hooks/useGraphDA02MainDept";
import { useGraphDA02Normal } from "../../hooks/useGraphDA02Normal";
import { useGraphDA02Octy } from "../../hooks/useGraphDA02Octy";
import { useGraphDA02Sex } from "../../hooks/useGraphDA02Sex";
import { useGraphDA02WrkYyGrp } from "../../hooks/useGraphDA02WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA02AgeGrp } from "../../hooks/useTableDA02AgeGrp";
import { useTableDA02Assign } from "../../hooks/useTableDA02Assign";
import { useTableDA02Doc } from "../../hooks/useTableDA02Doc";
import { useTableDA02Octy } from "../../hooks/useTableDA02Octy";
import { useTableDA02Sex } from "../../hooks/useTableDA02Sex";
import { useTableDA02WrkYyGrp } from "../../hooks/useTableDA02WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA02Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [KeywordDeepAnaCd, setKeywordDeepAnaCd] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);
  const [FirstCheck, setFirstCheck] = useState([]);
  const [SecondCheck, setSecondCheck] = useState([]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    if ((Sel !== null && Sel !== undefined) || (Sel.length === 1 && Sel[0] !== undefined)) {
      var vTemp = Sel.slice().map((data) => data.value);
      setFirstCheck([...vTemp]);
      setSecondCheck([...vTemp]);
    }
  }, [Sel]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vKeywordDeepQstCd = "";
    var vTempQstCd = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vTempQstCd = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].deep_QST_CD;
      vKeywordDeepQstCd = QstListData.filter((x) => x.qst_CD === vTempQstCd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setKeywordDeepAnaCd(vKeywordDeepQstCd);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(FirstCheck);
  }, [FirstCheck]);

  useEffect(() => {
    //console.log(SecondCheck);
  }, [SecondCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeFirstCheck = (text) => {
    setFirstCheck(text);
  };

  const changeSecondCheck = (text) => {
    setSecondCheck(text);
  };

  const { isGraphDA02NormalLoading, isGraphDA02NormalError, GraphDA02NormalData, isGraphDA02NormalSuccess, GraphDA02NormalRefetch } =
    useGraphDA02Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isGraphDA02AssignLoading, isGraphDA02AssignError, GraphDA02AssignData, isGraphDA02AssignSuccess, GraphDA02AssignRefetch } =
    useGraphDA02Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isGraphDA02DocLoading, isGraphDA02DocError, GraphDA02DocData, isGraphDA02DocSuccess, GraphDA02DocRefetch } = useGraphDA02Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isGraphDA02OctyLoading, isGraphDA02OctyError, GraphDA02OctyData, isGraphDA02OctySuccess, GraphDA02OctyRefetch } = useGraphDA02Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isGraphDA02WrkYyGrpLoading, isGraphDA02WrkYyGrpError, GraphDA02WrkYyGrpData, isGraphDA02WrkYyGrpSuccess, GraphDA02WrkYyGrpRefetch } =
    useGraphDA02WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isGraphDA02AgeGrpLoading, isGraphDA02AgeGrpError, GraphDA02AgeGrpData, isGraphDA02AgeGrpSuccess, GraphDA02AgeGrpRefetch } =
    useGraphDA02AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isGraphDA02SexLoading, isGraphDA02SexError, GraphDA02SexData, isGraphDA02SexSuccess, GraphDA02SexRefetch } = useGraphDA02Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isGraphDA02AreaLoading, isGraphDA02AreaError, GraphDA02AreaData, isGraphDA02AreaSuccess, GraphDA02AreaRefetch } = useGraphDA02Area(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AREA_CD",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isGraphDA02MainDeptLoading, isGraphDA02MainDeptError, GraphDA02MainDeptData, isGraphDA02MainDeptSuccess, GraphDA02MainDeptRefetch } =
    useGraphDA02MainDept(PrjCd, SvyOjt, QstCd, "", "", "MAIN_DEPT_CD", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isGraphDA02Etc1Loading, isGraphDA02Etc1Error, GraphDA02Etc1Data, isGraphDA02Etc1Success, GraphDA02Etc1Refetch } = useGraphDA02Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isGraphDA02Etc2Loading, isGraphDA02Etc2Error, GraphDA02Etc2Data, isGraphDA02Etc2Success, GraphDA02Etc2Refetch } = useGraphDA02Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isGraphDA02Etc3Loading, isGraphDA02Etc3Error, GraphDA02Etc3Data, isGraphDA02Etc3Success, GraphDA02Etc3Refetch } = useGraphDA02Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isTableDA02AgeGrpLoading, isTableDA02AgeGrpError, TableDA02AgeGrpData, isTableDA02AgeGrpSuccess, TableDA02AgeGrpRefetch } =
    useTableDA02AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isTableDA02AssignLoading, isTableDA02AssignError, TableDA02AssignData, isTableDA02AssignSuccess, TableDA02AssignRefetch } =
    useTableDA02Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  const { isTableDA02DocLoading, isTableDA02DocError, TableDA02DocData, isTableDA02DocSuccess, TableDA02DocRefetch } = useTableDA02Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isTableDA02OctyLoading, isTableDA02OctyError, TableDA02OctyData, isTableDA02OctySuccess, TableDA02OctyRefetch } = useTableDA02Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isTableDA02SexLoading, isTableDA02SexError, TableDA02SexData, isTableDA02SexSuccess, TableDA02SexRefetch } = useTableDA02Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    FirstCheck.toString(),
    SecondCheck.toString(),
    startDate,
    endDate
  );

  const { isTableDA02WrkYyGrpLoading, isTableDA02WrkYyGrpError, TableDA02WrkYyGrpData, isTableDA02WrkYyGrpSuccess, TableDA02WrkYyGrpRefetch } =
    useTableDA02WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, FirstCheck.toString(), SecondCheck.toString(), startDate, endDate);

  useEffect(() => {
    // if (FirstCheck.length > 0 && SecondCheck.length > 0) {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (FirstCheck.length > 0 && SecondCheck.length > 0 && FirstCheck[0] !== undefined && SecondCheck[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA02NormalRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA02AssignRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA02DocRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA02OctyRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA02WrkYyGrpRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA02AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA02SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA02AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA02MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA02Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA02Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA02Etc3Refetch();
          }
        }
      }

      if (RadioTypeCheck === "AgeGrp") {
        GraphDA02AgeGrpRefetch();
        TableDA02AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA02SexRefetch();
        TableDA02SexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA02AssignRefetch();
        TableDA02AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphDA02DocRefetch();
        TableDA02DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA02OctyRefetch();
        TableDA02OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphDA02WrkYyGrpRefetch();
        TableDA02WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA02MainDeptRefetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, FirstCheck, SecondCheck, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA02NormalSuccess || GraphDA02NormalData === null || GraphDA02NormalData === undefined || GraphDA02NormalData.length === 0) {
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02NormalData,
  //   isGraphDA02NormalSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02AssignSuccess || GraphDA02AssignData === null || GraphDA02AssignData === undefined || GraphDA02AssignData.length === 0) {
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();

  //   }
  // }, [
  //   GraphDA02AssignData,
  //   isGraphDA02AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02DocSuccess || GraphDA02DocData === null || GraphDA02DocData === undefined || GraphDA02DocData.length === 0) {
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02DocData,
  //   isGraphDA02DocSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02OctySuccess || GraphDA02OctyData === null || GraphDA02OctyData === undefined || GraphDA02OctyData.length === 0) {
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02OctyData,
  //   isGraphDA02OctySuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02WrkYyGrpSuccess || GraphDA02WrkYyGrpData === null || GraphDA02WrkYyGrpData === undefined || GraphDA02WrkYyGrpData.length === 0) {
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02WrkYyGrpData,
  //   isGraphDA02WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02AgeGrpSuccess || GraphDA02AgeGrpData === null || GraphDA02AgeGrpData === undefined || GraphDA02AgeGrpData.length === 0) {
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02AgeGrpData,
  //   isGraphDA02AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02SexSuccess || GraphDA02SexData === null || GraphDA02SexData === undefined || GraphDA02SexData.length === 0) {
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02SexData,
  //   isGraphDA02SexSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02AreaSuccess || GraphDA02AreaData === null || GraphDA02AreaData === undefined || GraphDA02AreaData.length === 0) {
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02AreaData,
  //   isGraphDA02AreaSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02MainDeptSuccess || GraphDA02MainDeptData === null || GraphDA02MainDeptData === undefined || GraphDA02MainDeptData.length === 0) {
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02MainDeptData,
  //   isGraphDA02MainDeptSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02Etc1Success || GraphDA02Etc1Data === null || GraphDA02Etc1Data === undefined || GraphDA02Etc1Data.length === 0) {
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02Etc1Data,
  //   isGraphDA02Etc1Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02Etc2Success || GraphDA02Etc2Data === null || GraphDA02Etc2Data === undefined || GraphDA02Etc2Data.length === 0) {
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02Etc2Data,
  //   isGraphDA02Etc2Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA02Etc3Success || GraphDA02Etc3Data === null || GraphDA02Etc3Data === undefined || GraphDA02Etc3Data.length === 0) {
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   GraphDA02Etc3Data,
  //   isGraphDA02Etc3Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA02AgeGrpSuccess || TableDA02AgeGrpData === null || TableDA02AgeGrpData === undefined || TableDA02AgeGrpData.length === 0) {
  //     TableDA02AgeGrpRefetch();
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   TableDA02AgeGrpData,
  //   isTableDA02AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA02AssignSuccess || TableDA02AssignData === null || TableDA02AssignData === undefined || TableDA02AssignData.length === 0) {
  //     TableDA02AssignRefetch();
  //     TableDA02AgeGrpRefetch();
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   TableDA02AssignData,
  //   isTableDA02AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA02DocSuccess || TableDA02DocData === null || TableDA02DocData === undefined || TableDA02DocData.length === 0) {
  //     TableDA02DocRefetch();
  //     TableDA02AssignRefetch();
  //     TableDA02AgeGrpRefetch();
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   TableDA02DocData,
  //   isTableDA02DocSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA02OctySuccess || TableDA02OctyData === null || TableDA02OctyData === undefined || TableDA02OctyData.length === 0) {
  //     TableDA02OctyRefetch();
  //     TableDA02DocRefetch();
  //     TableDA02AssignRefetch();
  //     TableDA02AgeGrpRefetch();
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   TableDA02OctyData,
  //   isTableDA02OctySuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA02SexSuccess || TableDA02SexData === null || TableDA02SexData === undefined || TableDA02SexData.length === 0) {
  //     TableDA02SexRefetch();
  //     TableDA02OctyRefetch();
  //     TableDA02DocRefetch();
  //     TableDA02AssignRefetch();
  //     TableDA02AgeGrpRefetch();
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   TableDA02SexData,
  //   isTableDA02SexSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA02WrkYyGrpSuccess || TableDA02WrkYyGrpData === null || TableDA02WrkYyGrpData === undefined || TableDA02WrkYyGrpData.length === 0) {
  //     TableDA02WrkYyGrpRefetch();
  //     TableDA02SexRefetch();
  //     TableDA02OctyRefetch();
  //     TableDA02DocRefetch();
  //     TableDA02AssignRefetch();
  //     TableDA02AgeGrpRefetch();
  //     GraphDA02Etc3Refetch();
  //     GraphDA02Etc2Refetch();
  //     GraphDA02Etc1Refetch();
  //     GraphDA02MainDeptRefetch();
  //     GraphDA02AreaRefetch();
  //     GraphDA02SexRefetch();
  //     GraphDA02AgeGrpRefetch();
  //     GraphDA02WrkYyGrpRefetch();
  //     GraphDA02OctyRefetch();
  //     GraphDA02DocRefetch();
  //     GraphDA02AssignRefetch();
  //     GraphDA02NormalRefetch();
  //   }
  // }, [
  //   TableDA02WrkYyGrpData,
  //   isTableDA02WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   FirstCheck,
  //   SecondCheck,
  //   startDate,
  //   endDate,
  // ]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA02"}
          keyword={Keyword}
          keywordDeepAnaCd={KeywordDeepAnaCd}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={changeFirstCheck}
          changeSecondCheck={changeSecondCheck}
          selectedFirstCheck={FirstCheck}
          selectedSecondCheck={SecondCheck}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vType = "column";
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vType = "column";
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vType = "column";
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vType = "column";
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vTitle = "연령대별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Area") {
                vTitle = "주거지역별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA02NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA02AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA02DocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA02OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA02WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA02SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA02AgeGrpData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "MAIN_DEPT_CD";
                vType = "bar";
                vData = GraphDA02MainDeptData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AREA_CD";
                vType = "bar";
                vData = GraphDA02AreaData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA02Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA02Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA02Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA02
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    first_check={FirstCheck}
                    second_check={SecondCheck}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vType = "column";
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vType = "column";
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vType = "column";
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vType = "column";
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vTitle = "연령대별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Area") {
              vTitle = "주거지역별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA02NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA02AssignData;
              vTData = TableDA02AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA02DocData;
              vTData = TableDA02DocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA02OctyData;
              vTData = TableDA02OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA02WrkYyGrpData;
              vTData = TableDA02WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA02SexData;
              vTData = TableDA02SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA02AgeGrpData;
              vTData = TableDA02AgeGrpData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "MAIN_DEPT_CD";
              vType = "bar";
              vData = GraphDA02MainDeptData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AREA_CD";
              vType = "bar";
              vData = GraphDA02AreaData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA02Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA02Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA02Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" && (
                      <DA02Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        keywordDeepAnaCd={KeywordDeepAnaCd}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA02
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    first_check={FirstCheck}
                    second_check={SecondCheck}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA02Page;
