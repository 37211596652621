import axios from "axios";

const getDa10Res = (prj_cd, qst_cd, svy_ojt, stDate, edDate) => {
  return axios.get(`/resultM/da10/chart?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}&stDate=${stDate}&edDate=${edDate}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getDa10Res;
