import { useQuery } from "@tanstack/react-query";
import getAdminFileHistory from "../api/getAdminFileHistory";

const useGetAdminFileHistory = (prjCd) => {
  return useQuery(["fileHistory"], () => getAdminFileHistory(prjCd), {
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminFileHistory;
