import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useWindowSize from "../../../hooks/useWindowSize";
import { useUnitState } from "../../../store/mobile/store";

const Da03Chart = (props) => {
  const width = useWindowSize();
  const unitState = useUnitState((state) => state.unitState);

  const options = {
    chart: {
      type: "bar",
      style: {},
      width: width !== 0 ? width * 0.95 : null,
      height: 480,
      //   height:
      //     props.categories.length > 7
      //       ? 400
      //       : props.categories.length > 5
      //       ? 350
      //       : props.categories.length > 3
      //       ? 250
      //       : props.categories.length > 1
      //       ? 200
      //       : 150,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
      style: {
        fontSize: 16,
        color: "#383838",
      },
    },
    xAxis: {
      marginTop: 10,
      tickLength: 0,
      lineWidth: 0.2,
      lineColor: "#383838",
      tickWidth: 0,
      title: {
        text: null,
      },
      labels: {
        // useHTML: true,
        // formatter: function () {
        //   return `<span style="border: none;">${this.value}</span>`;
        // },
        style: {
          fontSize: 14,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        y: 4,
      },
      gridLineWidth: 0,
      categories: props.categories,
    },
    yAxis: {
      min: 0,
      max: unitState === "rate" ? 100 : null,
      tickInterval: 10,

      title: {
        text: null,
      },
      visible: false,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    series: [
      {
        name: "본인직종",
        data: unitState === "rate" ? props.rateDb[0] : props.cntDb[0],
        color: "#DF895F",
      },
      {
        name: "다른직종",
        data: unitState === "rate" ? props.rateDb[1] : props.cntDb[1],
        color: "#ECB758",
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        const legendValue = series.name;

        return `<b style="font-size : 9px;">${this.x} </br> </b> <span style="color: ${color};">&#9679;</span> ${legendValue} : <b>${this.y}</b>`;
      },
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: true,
      reversed: false,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
  };
  return (
    <>
      {width ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : null}
    </>
  );
};

export default Da03Chart;
