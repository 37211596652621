import { useQuery } from "@tanstack/react-query";
import getAdminProjectList from "../../../api/Admin/Common/getAdminProjectList";

const useGetAdminProjectList = (prj_cd) => {
  const fallback = [];

  const { data = fallback, isLoading } = useQuery({
    queryKey: ["adminProjectList", prj_cd],
    queryFn: () => getAdminProjectList(prj_cd),
    onSuccess: () => {},
    staleTime: 0,
    gcTime: 0,
  });

  return { data, isLoading };
};

export default useGetAdminProjectList;
