/**
 * yyyy-MM-dd hh:mm:ss 형식의 문자열을 yyyyMMdd 형식으로 변환합니다.
 * @param {*} yyyy-MM-dd hh:mm:ss
 * @returns yyyyMMdd
 */
export function formatDateToyyyyMMdd(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  // getMonth()는 0부터 시작하므로 1을 더해줍니다. 또한, 결과가 한 자리수일 경우 앞에 0을 붙여줍니다.
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}${month}${day}`;
}
/**
 *
 * @param {*} dateString
 * @returns
 */
export function formatDateTo_yyyy_MM_dd(dateString) {
  const year = dateString.getFullYear();
  const month = ("0" + (dateString.getMonth() + 1)).slice(-2); // getMonth()는 0부터 시작하므로 1을 더해줍니다.
  const day = ("0" + dateString.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

/**
 * trendPeriod에 따라 endDate 기준으로 시작일 구하기
 * @param {*} trendPeriod
 * @param {*} endDate
 * @returns
 */
export const getStartDateForTrendSummarySql = (trendPeriod, endDate) => {
  const date = new Date(endDate);
  switch (trendPeriod) {
    case "month":
      date.setFullYear(date.getFullYear() - 1); // 1년 전으로 설정
      date.setMonth(date.getMonth() + 1); // 월을 1달 더함
      date.setDate(1); // 일자를 1일로 설정
      break;
    case "year":
      date.setFullYear(date.getFullYear() - 4);
      date.setMonth(0); // 1월로 설정
      date.setDate(1); // 1일로 설정
      break;
    case "quarter":
      const currentMonth = date.getMonth() + 1;
      if (currentMonth >= 0 && currentMonth < 3) {
        // 1분기
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(2); // 2분기로 설정
      } else if (currentMonth >= 3 && currentMonth < 6) {
        // 2분기
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(5); // 3분기로 설정
      } else if (currentMonth >= 6 && currentMonth < 9) {
        // 3분기
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(8); // 4분기로 설정
      } else {
        // 4분기
        date.setMonth(0); // 1분기로 설정
      }
      date.setDate(1); // 1일로 설정
      break;
    default:
      console.log("");
  }
  return formatDateTo_yyyy_MM_dd(date);
};

/**
 * 기간만 추출
 * @param {*} dataList
 * @returns
 */
// export function exportSelList(dataList) {
//   return dataList?.map((data) => {
//     return data.period;
//   });
// }
export async function exportSelList(dataList) {
  if (!dataList) return [];

  const periods = await Promise.all(
    dataList.map(async (data) => {
      return data.period;
    })
  );

  return periods;
}

/**
 * sel list와 매칭해 값 있는 것만 추출
 * @param {*} dataList
 * @param {*} selList
 * @returns
 */
export async function exportGraphDataMatchPeriodNPS(dataList, selList) {
  if (!dataList || !selList) return [];
  if (dataList.length === 0) {
    return [0, 0, 0, 0, 0, 0];
  }

  await Promise.all(
    selList.map(async (selItem) => {
      dataList.forEach((dataItem) => {
        if (dataItem.period === selItem.period) {
          selItem.value = dataItem.value;
        }
      });
    })
  );

  const rst = [];
  selList.forEach((el, idx) => rst.push({ x: idx, y: parseFloat(el.value) }));

  return rst;
}

export async function GraphDataMatchPeriodUsingExp(dataList, selList) {
  if (!dataList || !selList) return [];

  if (dataList.length === 0) {
    return [{ data: [0, 0, 0, 0, 0, 0] }, { data: [0, 0, 0, 0, 0, 0] }];
  }

  // Step 2: Group data by 'assign' and collect 'rate' values
  const groupedData = await new Promise((resolve) => {
    const result = dataList.reduce((acc, curr) => {
      const { period, assign, value } = curr;
      if (!acc[assign]) {
        acc[assign] = { name: assign, datas: [] };
      }
      acc[assign].datas.push({ period: period, value: value });
      return acc;
    }, {});
    resolve(result);
  });

  // Convert grouped data to array
  const resultData = Object.values(groupedData);

  resultData.forEach((rstItems) => {
    let val = [];
    selList.forEach((selITem, idx) => {
      let isPush = false;
      rstItems.datas.forEach((dataItem) => {
        if (selITem.period === dataItem.period) {
          val.push({ x: idx, y: parseFloat(dataItem.value) });
          isPush = true;
        }
      });
      if (!isPush) {
        val.push({ x: idx, y: 0 });
      }
    });
    rstItems.data = val;
    delete rstItems.datas;
    // rstItems.color = rstItems.name === "주차장" ? "#63ABCB" : "#D977A0";
  });

  return resultData;
}

export async function GraphDataMatchPeriodVisitWay(dataList, selList) {
  if (!dataList || !selList) return [];
  if (dataList.length === 0) {
    return [0, 0, 0, 0, 0, 0];
  }

  await Promise.all(
    selList.map(async (selItem) => {
      selItem.tmp = "";
      dataList.forEach((dataItem) => {
        if (dataItem.period === selItem.period) {
          selItem.value = dataItem.value;
          selItem.tmp = dataItem.assign;
        }
      });
    })
  );

  const rst = [];
  selList.forEach((el, idx) => rst.push({ x: idx, y: parseFloat(el.value), name: el.tmp }));

  return rst;
}
