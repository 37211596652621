import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { SyncLoader } from "react-spinners";
import { useEssayListState } from "../../../store/mobile/store";
import SpeechBubble from "./SpeechBubble";

const EssayAnswerComp = (props) => {
  const listData = useEssayListState((state) => state.listData);

  const num = listData[0] ? listData[0]?.cnt : 0;

  const [tempWord, setTempWord] = useState("");

  const [rendering, setRendering] = useState(false);

  const handleChangeSearchInput = (e) => {
    setTempWord(e.target.value);
  };

  const handleClickSearchIcon = (e) => {
    props.setPage(0);

    if (tempWord) {
      props.setSearchWord(tempWord);
    } else {
      props.setSearchWord("");
    }

    setRendering(true);

    setTimeout(() => {
      setRendering(false);
    }, 500);
  };

  const handlePressEnter = (e) => {
    if (e.keyCode === 13) {
      props.setPage(0);

      if (tempWord) {
        props.setSearchWord(tempWord);
      } else {
        props.setSearchWord("");
      }

      setRendering(true);

      setTimeout(() => {
        setRendering(false);
      }, 500);
    }
  };

  return (
    <div className="mt-8 flex h-fit w-[95%] flex-col items-center justify-center">
      <div className="flex h-fit w-full flex-row items-center justify-between font-SDGothic">
        {/* title */}
        <div className="flex h-fit w-[35%] flex-row items-center justify-start">
          <img
            src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
            alt="menu_dot"
            className="mr-3 w-[6px]"
          />
          <div className="flex h-fit w-fit flex-row items-end justify-end text-[16px] text-selectTextColor">
            <div className="h-fit w-fit font-bold">답변</div>
            <div className="ml-1 h-fit w-fit font-light text-selectTextColor/80">({num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</div>
          </div>
        </div>
        {/* search Area */}
        <div className="flex h-fit w-[60%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor p-2">
          <input
            type="text"
            placeholder="검색할 키워드를 입력하세요"
            value={tempWord}
            onChange={handleChangeSearchInput}
            onKeyUp={handlePressEnter}
            className="h-fit w-[90%]  font-SDGothic text-[14px] text-selectTextColor outline-none"
          />
          <FiSearch onClick={handleClickSearchIcon} className="text-[16px] text-orangeColor" />
        </div>
      </div>

      {rendering ? (
        <div className="mb-16 mt-16 flex h-fit w-full flex-col items-center justify-center p-3">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}

      {/* data list */}
      {listData?.map((el, idx) => {
        return <SpeechBubble key={idx} index={idx} data={el} />;
      })}
    </div>
  );
};

export default EssayAnswerComp;
