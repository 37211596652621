import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA11 from "../../components/pc/BarColumnChartDA11";
import DA11Table from "../../components/pc/DA11Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA11AgeGrp } from "../../hooks/useGraphDA11AgeGrp";
import { useGraphDA11Area } from "../../hooks/useGraphDA11Area";
import { useGraphDA11Assign } from "../../hooks/useGraphDA11Assign";
import { useGraphDA11Doc } from "../../hooks/useGraphDA11Doc";
import { useGraphDA11Etc1 } from "../../hooks/useGraphDA11Etc1";
import { useGraphDA11Etc2 } from "../../hooks/useGraphDA11Etc2";
import { useGraphDA11Etc3 } from "../../hooks/useGraphDA11Etc3";
import { useGraphDA11MainDept } from "../../hooks/useGraphDA11MainDept";
import { useGraphDA11Normal } from "../../hooks/useGraphDA11Normal";
import { useGraphDA11Octy } from "../../hooks/useGraphDA11Octy";
import { useGraphDA11Sex } from "../../hooks/useGraphDA11Sex";
import { useGraphDA11WrkYyGrp } from "../../hooks/useGraphDA11WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA11AgeGrp } from "../../hooks/useTableDA11AgeGrp";
import { useTableDA11Assign } from "../../hooks/useTableDA11Assign";
import { useTableDA11Doc } from "../../hooks/useTableDA11Doc";
import { useTableDA11Octy } from "../../hooks/useTableDA11Octy";
import { useTableDA11Sex } from "../../hooks/useTableDA11Sex";
import { useTableDA11WrkYyGrp } from "../../hooks/useTableDA11WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA11Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [RadioDA07DA11Check, setRadioDA07DA11Check] = useState("1");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeDA07DA11 = (text) => {
    setRadioDA07DA11Check(text);
  };

  const { isGraphDA11NormalLoading, isGraphDA11NormalError, GraphDA11NormalData, isGraphDA11NormalSuccess, GraphDA11NormalRefetch } =
    useGraphDA11Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA11AssignLoading, isGraphDA11AssignError, GraphDA11AssignData, isGraphDA11AssignSuccess, GraphDA11AssignRefetch } =
    useGraphDA11Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA11DocLoading, isGraphDA11DocError, GraphDA11DocData, isGraphDA11DocSuccess, GraphDA11DocRefetch } = useGraphDA11Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA11OctyLoading, isGraphDA11OctyError, GraphDA11OctyData, isGraphDA11OctySuccess, GraphDA11OctyRefetch } = useGraphDA11Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA11WrkYyGrpLoading, isGraphDA11WrkYyGrpError, GraphDA11WrkYyGrpData, isGraphDA11WrkYyGrpSuccess, GraphDA11WrkYyGrpRefetch } =
    useGraphDA11WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA11AgeGrpLoading, isGraphDA11AgeGrpError, GraphDA11AgeGrpData, isGraphDA11AgeGrpSuccess, GraphDA11AgeGrpRefetch } =
    useGraphDA11AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA11SexLoading, isGraphDA11SexError, GraphDA11SexData, isGraphDA11SexSuccess, GraphDA11SexRefetch } = useGraphDA11Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA11AreaLoading, isGraphDA11AreaError, GraphDA11AreaData, isGraphDA11AreaSuccess, GraphDA11AreaRefetch } = useGraphDA11Area(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA11MainDeptLoading, isGraphDA11MainDeptError, GraphDA11MainDeptData, isGraphDA11MainDeptSuccess, GraphDA11MainDeptRefetch } =
    useGraphDA11MainDept(PrjCd, SvyOjt, QstCd, "", "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA11Etc1Loading, isGraphDA11Etc1Error, GraphDA11Etc1Data, isGraphDA11Etc1Success, GraphDA11Etc1Refetch } = useGraphDA11Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA11Etc2Loading, isGraphDA11Etc2Error, GraphDA11Etc2Data, isGraphDA11Etc2Success, GraphDA11Etc2Refetch } = useGraphDA11Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA11Etc3Loading, isGraphDA11Etc3Error, GraphDA11Etc3Data, isGraphDA11Etc3Success, GraphDA11Etc3Refetch } = useGraphDA11Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA11AgeGrpLoading, isTableDA11AgeGrpError, TableDA11AgeGrpData, isTableDA11AgeGrpSuccess, TableDA11AgeGrpRefetch } =
    useTableDA11AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA11AssignLoading, isTableDA11AssignError, TableDA11AssignData, isTableDA11AssignSuccess, TableDA11AssignRefetch } =
    useTableDA11Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA11DocLoading, isTableDA11DocError, TableDA11DocData, isTableDA11DocSuccess, TableDA11DocRefetch } = useTableDA11Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA11OctyLoading, isTableDA11OctyError, TableDA11OctyData, isTableDA11OctySuccess, TableDA11OctyRefetch } = useTableDA11Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA11SexLoading, isTableDA11SexError, TableDA11SexData, isTableDA11SexSuccess, TableDA11SexRefetch } = useTableDA11Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA11WrkYyGrpLoading, isTableDA11WrkYyGrpError, TableDA11WrkYyGrpData, isTableDA11WrkYyGrpSuccess, TableDA11WrkYyGrpRefetch } =
    useTableDA11WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioDA07DA11Check.length > 0 && RadioDA07DA11Check[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA11NormalRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA11AssignRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA11DocRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA11OctyRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA11WrkYyGrpRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA11AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA11SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA11AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA11MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA11Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA11Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA11Etc3Refetch();
          }
        }
      }

      if (RadioTypeCheck === "AgeGrp") {
        GraphDA11AgeGrpRefetch();
        TableDA11AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA11SexRefetch();
        TableDA11SexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA11AssignRefetch();
        TableDA11AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphDA11DocRefetch();
        TableDA11DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA11OctyRefetch();
        TableDA11OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphDA11WrkYyGrpRefetch();
        TableDA11WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA11MainDeptRefetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, RadioDA07DA11Check, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA11NormalSuccess || GraphDA11NormalData === null || GraphDA11NormalData === undefined || GraphDA11NormalData.length === 0) {
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11NormalData,
  //   isGraphDA11NormalSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11AssignSuccess || GraphDA11AssignData === null || GraphDA11AssignData === undefined || GraphDA11AssignData.length === 0) {
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();

  //   }
  // }, [
  //   GraphDA11AssignData,
  //   isGraphDA11AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11DocSuccess || GraphDA11DocData === null || GraphDA11DocData === undefined || GraphDA11DocData.length === 0) {
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11DocData,
  //   isGraphDA11DocSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11OctySuccess || GraphDA11OctyData === null || GraphDA11OctyData === undefined || GraphDA11OctyData.length === 0) {
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11OctyData,
  //   isGraphDA11OctySuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11WrkYyGrpSuccess || GraphDA11WrkYyGrpData === null || GraphDA11WrkYyGrpData === undefined || GraphDA11WrkYyGrpData.length === 0) {
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11WrkYyGrpData,
  //   isGraphDA11WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11AgeGrpSuccess || GraphDA11AgeGrpData === null || GraphDA11AgeGrpData === undefined || GraphDA11AgeGrpData.length === 0) {
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11AgeGrpData,
  //   isGraphDA11AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11SexSuccess || GraphDA11SexData === null || GraphDA11SexData === undefined || GraphDA11SexData.length === 0) {
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11SexData,
  //   isGraphDA11SexSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11AreaSuccess || GraphDA11AreaData === null || GraphDA11AreaData === undefined || GraphDA11AreaData.length === 0) {
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11AreaData,
  //   isGraphDA11AreaSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11MainDeptSuccess || GraphDA11MainDeptData === null || GraphDA11MainDeptData === undefined || GraphDA11MainDeptData.length === 0) {
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11MainDeptData,
  //   isGraphDA11MainDeptSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11Etc1Success || GraphDA11Etc1Data === null || GraphDA11Etc1Data === undefined || GraphDA11Etc1Data.length === 0) {
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11Etc1Data,
  //   isGraphDA11Etc1Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11Etc2Success || GraphDA11Etc2Data === null || GraphDA11Etc2Data === undefined || GraphDA11Etc2Data.length === 0) {
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11Etc2Data,
  //   isGraphDA11Etc2Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA11Etc3Success || GraphDA11Etc3Data === null || GraphDA11Etc3Data === undefined || GraphDA11Etc3Data.length === 0) {
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   GraphDA11Etc3Data,
  //   isGraphDA11Etc3Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA11AgeGrpSuccess || TableDA11AgeGrpData === null || TableDA11AgeGrpData === undefined || TableDA11AgeGrpData.length === 0) {
  //     TableDA11AgeGrpRefetch();
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   TableDA11AgeGrpData,
  //   isTableDA11AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA11AssignSuccess || TableDA11AssignData === null || TableDA11AssignData === undefined || TableDA11AssignData.length === 0) {
  //     TableDA11AssignRefetch();
  //     TableDA11AgeGrpRefetch();
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   TableDA11AssignData,
  //   isTableDA11AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA11DocSuccess || TableDA11DocData === null || TableDA11DocData === undefined || TableDA11DocData.length === 0) {
  //     TableDA11DocRefetch();
  //     TableDA11AssignRefetch();
  //     TableDA11AgeGrpRefetch();
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   TableDA11DocData,
  //   isTableDA11DocSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA11OctySuccess || TableDA11OctyData === null || TableDA11OctyData === undefined || TableDA11OctyData.length === 0) {
  //     TableDA11OctyRefetch();
  //     TableDA11DocRefetch();
  //     TableDA11AssignRefetch();
  //     TableDA11AgeGrpRefetch();
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   TableDA11OctyData,
  //   isTableDA11OctySuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA11SexSuccess || TableDA11SexData === null || TableDA11SexData === undefined || TableDA11SexData.length === 0) {
  //     TableDA11SexRefetch();
  //     TableDA11OctyRefetch();
  //     TableDA11DocRefetch();
  //     TableDA11AssignRefetch();
  //     TableDA11AgeGrpRefetch();
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   TableDA11SexData,
  //   isTableDA11SexSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA11WrkYyGrpSuccess || TableDA11WrkYyGrpData === null || TableDA11WrkYyGrpData === undefined || TableDA11WrkYyGrpData.length === 0) {
  //     TableDA11WrkYyGrpRefetch();
  //     TableDA11SexRefetch();
  //     TableDA11OctyRefetch();
  //     TableDA11DocRefetch();
  //     TableDA11AssignRefetch();
  //     TableDA11AgeGrpRefetch();
  //     GraphDA11Etc3Refetch();
  //     GraphDA11Etc2Refetch();
  //     GraphDA11Etc1Refetch();
  //     GraphDA11MainDeptRefetch();
  //     GraphDA11AreaRefetch();
  //     GraphDA11SexRefetch();
  //     GraphDA11AgeGrpRefetch();
  //     GraphDA11WrkYyGrpRefetch();
  //     GraphDA11OctyRefetch();
  //     GraphDA11DocRefetch();
  //     GraphDA11AssignRefetch();
  //     GraphDA11NormalRefetch();
  //   }
  // }, [
  //   TableDA11WrkYyGrpData,
  //   isTableDA11WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA11"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={changeDA07DA11}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={RadioDA07DA11Check}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA11NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA11AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA11DocData;
              } else if (data.value === "Octy") {
                vType = "column";
                vStack = "Y";
                vName = "octy";
                vData = GraphDA11OctyData;
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vStack = "Y";
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA11WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA11SexData;
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vStack = "Y";
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA11AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA11AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA11MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA11Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA11Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA11Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA11
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    selectedDA07DA11={RadioDA07DA11Check}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA11NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA11AssignData;
              vTData = TableDA11AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA11DocData;
              vTData = TableDA11DocData;
            } else if (data.value === "Octy") {
              vType = "column";
              vStack = "Y";
              vName = "octy";
              vData = GraphDA11OctyData;
              vTData = TableDA11OctyData;
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vStack = "Y";
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA11WrkYyGrpData;
              vTData = TableDA11WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA11SexData;
              vTData = TableDA11SexData;
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vStack = "Y";
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA11AgeGrpData;
              vTData = TableDA11AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA11AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA11MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA11Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA11Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA11Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA11Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        RadioDA07DA11Check={RadioDA07DA11Check}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA11
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    selectedDA07DA11={RadioDA07DA11Check}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA11Page;
