import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminDisp from "../api/postAdminDisp";
const usePostAdminDisp = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminDisp, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserDisp"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminDisp;
