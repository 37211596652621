import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA03 from "../../components/pc/BarColumnChartDA03";
import DA03Table from "../../components/pc/DA03Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA03AgeGrp } from "../../hooks/useGraphDA03AgeGrp";
import { useGraphDA03Assign } from "../../hooks/useGraphDA03Assign";
import { useGraphDA03Etc1 } from "../../hooks/useGraphDA03Etc1";
import { useGraphDA03Etc2 } from "../../hooks/useGraphDA03Etc2";
import { useGraphDA03Etc3 } from "../../hooks/useGraphDA03Etc3";
import { useGraphDA03Normal } from "../../hooks/useGraphDA03Normal";
import { useGraphDA03Octy } from "../../hooks/useGraphDA03Octy";
import { useGraphDA03Sex } from "../../hooks/useGraphDA03Sex";
import { useGraphDA03WrkYyGrp } from "../../hooks/useGraphDA03WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA03AgeGrp } from "../../hooks/useTableDA03AgeGrp";
import { useTableDA03Assign } from "../../hooks/useTableDA03Assign";
import { useTableDA03Octy } from "../../hooks/useTableDA03Octy";
import { useTableDA03Sex } from "../../hooks/useTableDA03Sex";
import { useTableDA03WrkYyGrp } from "../../hooks/useTableDA03WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA03Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const { isGraphDA03NormalLoading, isGraphDA03NormalError, GraphDA03NormalData, isGraphDA03NormalSuccess, GraphDA03NormalRefetch } =
    useGraphDA03Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA03AssignLoading, isGraphDA03AssignError, GraphDA03AssignData, isGraphDA03AssignSuccess, GraphDA03AssignRefetch } =
    useGraphDA03Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA03OctyLoading, isGraphDA03OctyError, GraphDA03OctyData, isGraphDA03OctySuccess, GraphDA03OctyRefetch } = useGraphDA03Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA03WrkYyGrpLoading, isGraphDA03WrkYyGrpError, GraphDA03WrkYyGrpData, isGraphDA03WrkYyGrpSuccess, GraphDA03WrkYyGrpRefetch } =
    useGraphDA03WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA03AgeGrpLoading, isGraphDA03AgeGrpError, GraphDA03AgeGrpData, isGraphDA03AgeGrpSuccess, GraphDA03AgeGrpRefetch } =
    useGraphDA03AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA03SexLoading, isGraphDA03SexError, GraphDA03SexData, isGraphDA03SexSuccess, GraphDA03SexRefetch } = useGraphDA03Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA03Etc1Loading, isGraphDA03Etc1Error, GraphDA03Etc1Data, isGraphDA03Etc1Success, GraphDA03Etc1Refetch } = useGraphDA03Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA03Etc2Loading, isGraphDA03Etc2Error, GraphDA03Etc2Data, isGraphDA03Etc2Success, GraphDA03Etc2Refetch } = useGraphDA03Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA03Etc3Loading, isGraphDA03Etc3Error, GraphDA03Etc3Data, isGraphDA03Etc3Success, GraphDA03Etc3Refetch } = useGraphDA03Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA03AgeGrpLoading, isTableDA03AgeGrpError, TableDA03AgeGrpData, isTableDA03AgeGrpSuccess, TableDA03AgeGrpRefetch } =
    useTableDA03AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA03AssignLoading, isTableDA03AssignError, TableDA03AssignData, isTableDA03AssignSuccess, TableDA03AssignRefetch } =
    useTableDA03Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA03OctyLoading, isTableDA03OctyError, TableDA03OctyData, isTableDA03OctySuccess, TableDA03OctyRefetch } = useTableDA03Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA03SexLoading, isTableDA03SexError, TableDA03SexData, isTableDA03SexSuccess, TableDA03SexRefetch } = useTableDA03Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA03WrkYyGrpLoading, isTableDA03WrkYyGrpError, TableDA03WrkYyGrpData, isTableDA03WrkYyGrpSuccess, TableDA03WrkYyGrpRefetch } =
    useTableDA03WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length > 0) {
        let graphList = GraphSet.map((el) => el.value);

        if (graphList.includes("Normal")) {
          GraphDA03NormalRefetch();
        }

        if (graphList.includes("Assign")) {
          GraphDA03AssignRefetch();
        }

        if (graphList.includes("Octy")) {
          GraphDA03OctyRefetch();
        }

        if (graphList.includes("WrkYyGrp")) {
          GraphDA03WrkYyGrpRefetch();
        }

        if (graphList.includes("AgeGrp")) {
          GraphDA03AgeGrpRefetch();
        }

        if (graphList.includes("Sex")) {
          GraphDA03SexRefetch();
        }

        if (graphList.includes("Etc1")) {
          GraphDA03Etc1Refetch();
        }

        if (graphList.includes("Etc2")) {
          GraphDA03Etc2Refetch();
        }

        if (graphList.includes("Etc3")) {
          GraphDA03Etc3Refetch();
        }
      }

      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioTypeCheck === "AgeGrp") {
      GraphDA03AgeGrpRefetch();
      TableDA03AgeGrpRefetch();
    } else if (RadioTypeCheck === "Sex") {
      GraphDA03SexRefetch();
      TableDA03SexRefetch();
    } else if (RadioTypeCheck === "Assign") {
      GraphDA03AssignRefetch();
      TableDA03AssignRefetch();
    } else if (RadioTypeCheck === "Doc") {
    } else if (RadioTypeCheck === "Octy") {
      GraphDA03OctyRefetch();
      TableDA03OctyRefetch();
    } else if (RadioTypeCheck === "WrkYyGrp") {
      GraphDA03WrkYyGrpRefetch();
      TableDA03WrkYyGrpRefetch();
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA03NormalSuccess || GraphDA03NormalData === null || GraphDA03NormalData === undefined || GraphDA03NormalData.length === 0) {
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03NormalData, isGraphDA03NormalSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03AssignSuccess || GraphDA03AssignData === null || GraphDA03AssignData === undefined || GraphDA03AssignData.length === 0) {
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();

  //   }
  // }, [GraphDA03AssignData, isGraphDA03AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03OctySuccess || GraphDA03OctyData === null || GraphDA03OctyData === undefined || GraphDA03OctyData.length === 0) {
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03OctyData, isGraphDA03OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03WrkYyGrpSuccess || GraphDA03WrkYyGrpData === null || GraphDA03WrkYyGrpData === undefined || GraphDA03WrkYyGrpData.length === 0) {
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03WrkYyGrpData, isGraphDA03WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03AgeGrpSuccess || GraphDA03AgeGrpData === null || GraphDA03AgeGrpData === undefined || GraphDA03AgeGrpData.length === 0) {
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03AgeGrpData, isGraphDA03AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03SexSuccess || GraphDA03SexData === null || GraphDA03SexData === undefined || GraphDA03SexData.length === 0) {
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03SexData, isGraphDA03SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03Etc1Success || GraphDA03Etc1Data === null || GraphDA03Etc1Data === undefined || GraphDA03Etc1Data.length === 0) {
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03Etc1Data, isGraphDA03Etc1Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03Etc2Success || GraphDA03Etc2Data === null || GraphDA03Etc2Data === undefined || GraphDA03Etc2Data.length === 0) {
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03Etc2Data, isGraphDA03Etc2Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA03Etc3Success || GraphDA03Etc3Data === null || GraphDA03Etc3Data === undefined || GraphDA03Etc3Data.length === 0) {
  //     GraphDA03Etc3Refetch();
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [GraphDA03Etc3Data, isGraphDA03Etc3Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA03AgeGrpSuccess || TableDA03AgeGrpData === null || TableDA03AgeGrpData === undefined || TableDA03AgeGrpData.length === 0) {
  //     TableDA03AgeGrpRefetch();
  //     GraphDA03Etc3Refetch();
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [TableDA03AgeGrpData, isTableDA03AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA03AssignSuccess || TableDA03AssignData === null || TableDA03AssignData === undefined || TableDA03AssignData.length === 0) {
  //     TableDA03AssignRefetch();
  //     TableDA03AgeGrpRefetch();
  //     GraphDA03Etc3Refetch();
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [TableDA03AssignData, isTableDA03AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA03OctySuccess || TableDA03OctyData === null || TableDA03OctyData === undefined || TableDA03OctyData.length === 0) {
  //     TableDA03OctyRefetch();
  //     TableDA03AssignRefetch();
  //     TableDA03AgeGrpRefetch();
  //     GraphDA03Etc3Refetch();
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [TableDA03OctyData, isTableDA03OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA03SexSuccess || TableDA03SexData === null || TableDA03SexData === undefined || TableDA03SexData.length === 0) {
  //     TableDA03SexRefetch();
  //     TableDA03OctyRefetch();
  //     TableDA03AssignRefetch();
  //     TableDA03AgeGrpRefetch();
  //     GraphDA03Etc3Refetch();
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [TableDA03SexData, isTableDA03SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA03WrkYyGrpSuccess || TableDA03WrkYyGrpData === null || TableDA03WrkYyGrpData === undefined || TableDA03WrkYyGrpData.length === 0) {
  //     TableDA03WrkYyGrpRefetch();
  //     TableDA03SexRefetch();
  //     TableDA03OctyRefetch();
  //     TableDA03AssignRefetch();
  //     TableDA03AgeGrpRefetch();
  //     GraphDA03Etc3Refetch();
  //     GraphDA03Etc2Refetch();
  //     GraphDA03Etc1Refetch();
  //     GraphDA03SexRefetch();
  //     GraphDA03AgeGrpRefetch();
  //     GraphDA03WrkYyGrpRefetch();
  //     GraphDA03OctyRefetch();
  //     GraphDA03AssignRefetch();
  //     GraphDA03NormalRefetch();
  //   }
  // }, [TableDA03WrkYyGrpData, isTableDA03WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA03"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vType = "column";
                vTitle = "보직여부 응답률";
              } else if (data.value === "Octy") {
                vType = "column";
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vType = "column";
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vTitle = "연령대별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA03NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA03AssignData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA03OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA03WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA03SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA03AgeGrpData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA03Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA03Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA03Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem] ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  }`}
                >
                  <BarColumnChartDA03
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vType = "column";
              vTitle = "보직여부 응답률";
            } else if (data.value === "Octy") {
              vType = "column";
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vType = "column";
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vTitle = "연령대별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA03NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA03AssignData;
              vTData = TableDA03AssignData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA03OctyData;
              vTData = TableDA03OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA03WrkYyGrpData;
              vTData = TableDA03WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA03SexData;
              vTData = TableDA03SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA03AgeGrpData;
              vTData = TableDA03AgeGrpData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA03Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA03Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA03Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA03Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA03
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA03Page;
