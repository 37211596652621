import axios from "axios";

const getAdminUserDetail = (prj_cd, user_id) => {
  return axios.get(`/adminUser/getAdminUserDetail?prj_cd=${prj_cd}&user_id=${user_id}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminUserDetail;
