import { useQuery } from "@tanstack/react-query";
import getCrssDa04Res from "../api/getCrssDa04Res";

const useGetCrssDa04Res = (prj_cd, qst_cd, svy_ojt, crss_qst_cd, filter_opt, stDate, edDate, deep_ana_cd, crss_deep_ana_cd, sort_type) => {
  return useQuery(
    ["crssDa04"],
    () => getCrssDa04Res(prj_cd, qst_cd, svy_ojt, crss_qst_cd, filter_opt, stDate, edDate, deep_ana_cd, crss_deep_ana_cd, sort_type),
    {
      enabled: false,
      onSuccess: (res) => {},
      onError: (err) => {
        console.log(err);
      },
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetCrssDa04Res;
