import axios from "axios";

const postExtractKeyword = (body) => {
  return axios.post("/v2/text/keyword_extraction", body, {
    baseURL: process.env.REACT_APP_EDENAI_API_ADDRESS,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_EDENAI_API_KEY}`,
    },
  });
};

export default postExtractKeyword;
