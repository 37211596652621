import { useQuery } from "@tanstack/react-query";
import getAdminUserDisp from "../api/getAdminUserDisp";

const useGetAdminUserDisp = (prj_cd) => {
  return useQuery(["adminUserDisp"], () => getAdminUserDisp(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserDisp;
