import { useEffect, useState } from "react";

export const Check = (props) => {
  const { data, changed, id, value } = props;
  const [Check, setCheck] = useState([{}]);

  useEffect(() => {
    if (data !== null && data !== undefined && data.length !== 0) {
      var vCheck = [{}];
      data.map((dd, i) => {
        vCheck.push({ value: dd.value, label: dd.label });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setCheck(vCheck);
    }
  }, [data]);

  useEffect(() => {}, [Check]);

  useEffect(() => {}, [value]);

  return (
    <div key={`check_${id}`} className="min-h-[1.4rem]">
      {Check.map((dd, i) => {
        return (
          <div key={`check_${id}_${i}`} className="float-left h-full  pr-[0.8rem] leading-[1.4rem]">
            <div className="float-left">
              <input
                id={id + "_" + dd.value}
                name={`${id}`}
                onChange={changed}
                value={dd.value}
                type="checkbox"
                className="h-[1.4rem] w-[1.4rem] pt-[0.2rem] accent-orange2Color"
                checked={value.indexOf(dd.value) !== -1 ? true : false}
              />
            </div>
            <div className="float-left leading-[1.4rem]">
              <label htmlFor={id + "_" + dd.value} className="ml-[0.4rem] mt-[-0.4rem] h-[full] text-[1.4rem]">
                {dd.label}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};
