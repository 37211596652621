import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useWindowSize from "../../../hooks/useWindowSize";

const Da04Chart = (props) => {
  const width = useWindowSize();

  const data = props?.data;

  const rateDb = [...data[2]];

  rateDb?.sort((a, b) => b.y - a.y);

  const options = {
    chart: {
      type: "bar",
      style: {},
      width: width !== 0 ? width * 0.95 : null,
      height:
        props.data[0].length > 7
          ? 350
          : props.data[0].length > 5
          ? 300
          : props.data[0].length > 3
          ? 250
          : props.data[0].length > 1
          ? 200
          : 150,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
      style: {
        fontSize: 16,
        color: "#383838",
      },
    },
    xAxis: {
      marginTop: 10,
      tickLength: 0,
      lineWidth: 0.2,
      lineColor: "#383838",
      tickWidth: 0,
      title: {
        text: null,
      },
      labels: {
        // useHTML: true,
        // formatter: function () {
        //   return `<span style="border: none;">${this.value}</span>`;
        // },
        style: {
          fontSize: 14,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        y: 4,
      },
      gridLineWidth: 0,
      categories: data[0],
    },
    yAxis: {
      min: rateDb ? rateDb[rateDb.length - 1]?.y - 10 : null, // data의 min 값
      max: rateDb
        ? rateDb[0]?.y === 100
          ? rateDb[0]?.y
          : rateDb[0]?.y + 20
        : null, // data의 max 값
      tickInterval: 10,

      title: {
        text: null,
      },
      visible: false,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    series: [
      {
        name: "NPS",
        data: data[2],
        color: "#DF895F",
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        const legendValue = series.name;

        return `<b style="font-size : 9px;">${this.x} </br> </b> <span style="color: ${color};">&#9679;</span> ${legendValue} : <b>${this.y}</b>`;
      },
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: false,
      reversed: false,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
  };

  return (
    <>
      {width ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : null}
    </>
  );
};

export default Da04Chart;
