const TopBtn = () => {
  const containerElement = document.getElementById("con");

  const handleClickTopBtn = (e) => {
    containerElement.scrollTop = 0;
    e.preventDefault();
  };

  return (
    <div
      onClick={handleClickTopBtn}
      className={`fixed bottom-24 right-4 z-50 flex h-fit w-fit flex-col items-center justify-center rounded-lg bg-black/40 px-5 py-8 font-SDGothic text-[16px] font-bold text-white duration-300 ease-in-out`}
    >
      Top
    </div>
  );
};

export default TopBtn;
