import { HiOutlineX } from "react-icons/hi";
import { useActivePrjModalState } from "../../store/mobile/store";
import Filter from "./analysis/Filter";

const PrjModal = () => {
  // prj Modal active control state
  const setPrjModalState = useActivePrjModalState((state) => state.setPrjModal);

  const handleClickBackground = (e) => {
    setPrjModalState(false);
  };

  const handleClickModalContent = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClickCancel = () => {
    setPrjModalState(false);
  };

  return (
    <div onClick={handleClickBackground} className="fixed top-0 z-50 flex h-full w-screen flex-col items-center justify-start bg-spinnerBackColor">
      {/* modal content */}
      <div
        onClick={handleClickModalContent}
        className="relative mt-16 flex h-fit min-h-[230px] w-[90%] flex-col items-center justify-start rounded-2xl border border-modalBorderColor bg-gradient-to-t from-white to-headerEndColor"
      >
        <HiOutlineX onClick={handleClickCancel} className="absolute right-3 top-3 text-4xl text-textColor" />
        <Filter />
      </div>
    </div>
  );
};

export default PrjModal;
