import { useMutation, useQueryClient } from "@tanstack/react-query";
import deleteAdminUserArea from "../api/deleteAdminUserArea";

const useDeleteAdminUserArea = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAdminUserArea, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserArea"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useDeleteAdminUserArea;
