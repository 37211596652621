// import { ageDict, workDict } from "../../../dict/mobile/dict";
import { useComnDict, useMenuState, useUnitState } from "../../../store/mobile/store";

const Da01Table = (props) => {
  const menuState = useMenuState((state) => state.menuVal);
  const unitState = useUnitState((state) => state.unitState);

  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  let categories = menuState === "MEM" ? workDict.map((el) => el.sel_nm) : ageDict.map((el) => el.sel_nm);

  return (
    <div className="flex h-fit w-full flex-row items-start justify-start overflow-auto border-l border-qstTitleBorder">
      {/* 구분 */}
      <div className="sticky left-0 flex h-full w-fit flex-col items-center justify-start">
        <div className="flex h-fit w-fit min-w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
          {/* {menuState === "MEM" ? `직종` : `연령대`} */}
          구분
        </div>
        {/* sticky된 영역 */}
        <div className="flex h-full w-full flex-row items-start justify-start whitespace-pre">
          {/* 왼쪽 첫번째 컬럼 */}
          <div className="flex h-full w-fit flex-col items-center justify-center border-b border-r border-qstTitleBorder bg-white px-5 py-10 font-SDGothic text-[14px] text-selectTextColor">
            <div className="h-fit w-fit">위상</div>
            <div className="mt-2 h-fit w-fit">상승</div>
          </div>
          {/* 왼쪽 두번째 컬럼 */}
          <div className="flex h-full w-full flex-col items-start justify-start  font-SDGothic text-[14px] text-selectTextColor">
            <div className="h-fit w-full border-b border-r border-qstTitleBorder bg-white p-3 ">사례수</div>
            {/* data에 따라 변경되는 영역 */}
            {props?.incGrp.map((el, idx) => {
              return (
                <div key={idx} className="flex h-full w-fit min-w-full flex-row items-start justify-start">
                  {/* grp_nm */}
                  <div
                    className={
                      props?.incSel[idx].length > 1
                        ? idx % 2
                          ? `flex h-full w-[50px] flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-white p-3`
                          : `flex h-full w-[50px] flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-tableBodyGray p-3`
                        : idx % 2
                        ? `flex h-full w-full flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-white p-3`
                        : `flex h-full w-full flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-tableBodyGray p-3`
                    }
                  >
                    {el !== "" ? el.slice(0, 2) : "-"}
                  </div>
                  {/* grp_nm에 대한 sel_nm이 2개 이상이면 랜더링 / 아니면 null */}

                  {/* grp_nm에 대한 sel_nm이 1개인 경우 grp_nm과 sel_nm이 같다면 null로 처리하도록 변경 */}
                  {props?.incSel[idx].length > 1 && el !== props?.incSel[idx][0] ? (
                    <div className="flex h-full w-full flex-col items-start justify-start border-r border-qstTitleBorder">
                      {/* sel_nm에 대한 map */}
                      {props?.incSel[idx].map((sel, idx2) => {
                        return (
                          <div
                            key={idx2}
                            className={
                              (idx + idx2) % 2
                                ? `h-fit w-full border-b border-qstTitleBorder bg-white p-3`
                                : `h-fit w-full border-b border-qstTitleBorder bg-tableBodyGray p-3`
                            }
                          >
                            {sel}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        {/* sticky된 영역 */}
        <div className="flex h-full w-full flex-row items-start justify-start whitespace-pre">
          {/* 왼쪽 첫번째 컬럼 */}
          <div className="flex h-full w-fit flex-col items-center justify-center border-b border-r border-qstTitleBorder bg-white px-5 py-10 font-SDGothic text-[14px] text-selectTextColor">
            <div className="h-fit w-fit">위상</div>
            <div className="mt-2 h-fit w-fit">하락</div>
          </div>
          {/* 왼쪽 두번째 컬럼 */}
          <div className="flex h-full w-full flex-col items-start justify-start  font-SDGothic text-[14px] text-selectTextColor">
            <div className="h-fit w-full border-b border-r border-qstTitleBorder bg-white p-3 ">사례수</div>
            {/* data에 따라 변경되는 영역 */}
            {props?.decGrp.map((el, idx) => {
              return (
                <div key={idx} className="flex h-full w-fit min-w-full flex-row items-start justify-start">
                  {/* grp_nm */}
                  <div
                    className={
                      props?.decSel[idx].length > 1
                        ? idx % 2
                          ? `flex h-full w-[50px] flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-white p-3`
                          : `flex h-full w-[50px] flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-tableBodyGray p-3`
                        : idx % 2
                        ? `flex h-full w-full flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-white p-3`
                        : `flex h-full w-full flex-col items-center justify-center border-b border-r border-qstTitleBorder  bg-tableBodyGray p-3`
                    }
                  >
                    {el !== "" ? el.slice(0, 2) : "-"}
                  </div>
                  {/* grp_nm에 대한 sel_nm이 2개 이상이면 랜더링 / 아니면 null */}
                  {props?.incSel[idx].length > 1 && el !== props?.incSel[idx][0] ? (
                    <div className="flex h-full w-full flex-col items-start justify-start border-r border-qstTitleBorder">
                      {/* sel_nm에 대한 map */}
                      {props?.decSel[idx].map((sel, idx2) => {
                        return (
                          <div
                            key={idx2}
                            className={
                              (idx + idx2) % 2
                                ? `h-fit w-full border-b border-qstTitleBorder bg-white p-3`
                                : `h-fit w-full border-b border-qstTitleBorder bg-tableBodyGray p-3`
                            }
                          >
                            {sel}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex h-full w-fit flex-col items-start justify-start">
        <div className="flex h-full w-full flex-row">
          {/* 데이터 영역 (위상상승) */}
          {props?.data.map((el, idx) => {
            return (
              <div key={idx} className="flex h-full w-fit flex-col items-center justify-start">
                <div className="flex h-fit w-[80px] flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
                  {categories[idx]}
                </div>
                {el.map((data, idx2) => {
                  return (
                    <div
                      key={idx2}
                      className={
                        idx2 % 2
                          ? `flex h-fit w-full flex-col items-end justify-center border-b border-r border-qstTitleBorder bg-tableBodyGray p-3 font-SDGothic text-[14px] text-selectTextColor`
                          : `flex h-fit w-full flex-col items-end justify-center border-b border-r border-qstTitleBorder bg-white p-3 font-SDGothic text-[14px] text-selectTextColor`
                      }
                    >
                      {idx2 === 0 && data
                        ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                        : unitState === "rate" && data
                        ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`
                        : unitState === "count" && data
                        ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                        : `0`}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="flex h-full w-full flex-row">
          {/* 데이터 영역 (위상하락) */}
          {props?.decData.map((el, idx) => {
            return (
              <div key={idx} className="flex h-full w-fit flex-col items-center justify-start">
                {el.map((data, idx2) => {
                  return (
                    <div
                      key={idx2}
                      className={
                        idx2 % 2
                          ? `flex h-fit w-[80px] flex-col items-end justify-center border-b border-r border-qstTitleBorder bg-tableBodyGray p-3 font-SDGothic text-[14px] text-selectTextColor`
                          : `flex h-fit w-[80px] flex-col items-end justify-center border-b border-r border-qstTitleBorder bg-white p-3 font-SDGothic text-[14px] text-selectTextColor`
                      }
                    >
                      {idx2 === 0 && data
                        ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                        : unitState === "rate" && data
                        ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`
                        : unitState === "count" && data
                        ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                        : "0"}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Da01Table;
