import axios from "axios";

const getAdminDetailSelList = (prj_cd, qst_cd, sel_cd) => {
  return axios.get(`/admin/selectOptList?prj_cd=${prj_cd}&qst_cd=${qst_cd}&sel_cd=${sel_cd}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminDetailSelList;
