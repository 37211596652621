import { useEffect, useState } from "react";
import Select from "react-select";
import useGetAdminDetailSelList from "../../../hooks/useGetAdminDetailSelList";
import usePatchDetailSel from "../../../hooks/usePatchDetailSel";
import useStore from "../../../store/pc/store";

const AdminOptDetailModalComp = (props) => {
  const [order, setOrder] = useState("");
  const [selTyp, setSelTyp] = useState("");
  const [nextQst, setNextQst] = useState("");
  const [selNmM, setSelNmM] = useState("");
  const [selNm, setSelNm] = useState("");
  const [selNmSvy, setSelNmSvy] = useState("");
  const [status, setStatus] = useState(false);
  const PrjCd = useStore((state) => state.PrjCd);

  const { data } = useGetAdminDetailSelList(PrjCd, props.qstCd, props.selCd);

  const { mutate, isSuccess } = usePatchDetailSel();

  const handleChangeOrder = (e) => {
    setOrder(e.targer.value);
  };

  const handleChangeSelTyp = (e) => {
    setSelTyp(e);
  };

  const handleChangeNextQst = (e) => {
    setNextQst(e);
  };

  const handleChangeSelNmM = (e) => {
    setSelNmM(e.target.value);
  };

  const handleChangeSelNm = (e) => {
    setSelNm(e.target.value);
  };

  const handleChangeSelNmSvy = (e) => {
    setSelNmSvy(e.target.value);
  };

  const handleClickSubmit = () => {
    let body = {
      prj_CD: PrjCd,
      qst_CD: props.qstCd,
      sel_CD: props.selCd,
      order: order,
      origin_ORDER: data?.data.sel_INFO[0].seq,
      sel_TYP: selTyp.value,
      next_QST: nextQst.value,
      sel_NM_M: selNmM,
      sel_NM: selNm,
      sel_NM_SVY: selNmSvy,
      svy_OJT: props.projectType,
    };

    // console.log("보기수정body", body);
    mutate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      setStatus(true);

      setTimeout(() => {
        setStatus(false);
      }, 1000);
    }
  }, [isSuccess]);

  useEffect(() => {
    setOrder(data?.data.sel_INFO[0].seq);
    setSelTyp(data?.data.sel_TYP.filter((el) => el.value === data?.data.sel_INFO[0].sel_TYP)[0]);
    setNextQst(data?.data.qst.filter((el) => el.value === data?.data.sel_INFO[0].next_QST)[0]);
    setSelNmM(data?.data.sel_INFO[0].sel_NM_M);
    setSelNm(data?.data.sel_INFO[0].sel_NM);
    setSelNmSvy(data?.data.sel_INFO[0].sel_NM_SVY);
  }, [data]);

  console.log(data, "너의데이터");
  return (
    <div className="relative mb-20 flex h-full w-full flex-col items-center justify-start">
      {status ? (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-100 duration-300 ease-out">
          저장되었습니다
        </div>
      ) : (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-0 duration-300 ease-out">
          저장되었습니다
        </div>
      )}
      {/* title */}
      <div className="mt-10 flex h-fit w-full flex-row items-center justify-start py-5 font-SDGothic text-[24px] font-extrabold text-selectTextColor/80">
        보기 상세정보
      </div>
      {data && (
        <div className="mt-10 flex h-fit w-full flex-col items-center justify-start rounded-xl bg-FilterInActiveColor/20 px-10 py-20 font-SDGothic font-bold">
          {/* 첫번째 row */}
          <div className="flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-[20%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">순서</div>
              <input type="text" className="h-fit w-[80px] text-right text-selectTextColor outline-none" value={order} onChange={handleChangeOrder} />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-[30%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">보기타입</div>
              <Select
                options={data?.data.sel_TYP}
                isSearchable={false}
                value={selTyp}
                onChange={handleChangeSelTyp}
                className="w-[160px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-[50%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">분기문항</div>
              <Select
                options={data?.data.qst}
                isSearchable={false}
                value={nextQst}
                onChange={handleChangeNextQst}
                className="w-[450px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>
          {/* 첫번째 row */}
          <div className="mt-10 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-[20%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">모바일분석 보기명</div>
              <input
                type="text"
                className="h-fit w-[80px] text-right text-selectTextColor outline-none"
                value={selNmM}
                onChange={handleChangeSelNmM}
              />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-[30%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">PC분석 보기명</div>
              <input type="text" className="h-fit w-[80px] text-right text-selectTextColor outline-none" value={selNm} onChange={handleChangeSelNm} />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-[50%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">설문보기</div>
              <input
                type="text"
                className="h-fit w-[100px] text-right text-selectTextColor outline-none"
                value={selNmSvy}
                onChange={handleChangeSelNmSvy}
              />
            </div>
          </div>
        </div>
      )}

      {/* save btn */}
      <button
        type="button"
        onClick={handleClickSubmit}
        className="mt-20 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
      >
        저장
      </button>
    </div>
  );
};

export default AdminOptDetailModalComp;
