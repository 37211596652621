import { useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { AiFillFileExcel } from "react-icons/ai";
import { CgDanger } from "react-icons/cg";
import { ImFolderUpload } from "react-icons/im";
import SyncLoader from "react-spinners/SyncLoader";
import usePostAdminFileUpload from "../../../hooks/usePostAdminFileUpload";
import usePostUploadUserFile from "../../../hooks/usePostUploadUserFile";
import useStore from "../../../store/pc/store";

const AdminQstUploadModalComp = (props) => {
  const [file, setFile] = useState(null);
  const PrjCd = useStore((state) => state.PrjCd);
  const HspCd = useStore((state) => state.HspCd);

  const PrjCdArr = useStore((state) => state.PrjCdArr);

  const inputRef = useRef();
  const uploadBoxRef = useRef();

  const { mutate, error, isLoading, isSuccess, isError } = usePostAdminFileUpload();
  const { mutate: userMutate, error: userError, isLoading: userIsLoading, isSuccess: userIsSuccess, isError: userIsError } = usePostUploadUserFile();
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClickSubmit = () => {
    if (
      window.confirm(
        `선택하신 프로젝트가 "${PrjCdArr.filter((el) => el.value === PrjCd)[0].label}" 이(가) 맞습니까?\n확인 시 기존 데이터는 삭제됩니다!`
      )
    ) {
      if (file) {
        if (props.fileDvs === "Q") {
          const formData = new FormData();

          formData.append("prj_cd", PrjCd);
          formData.append("file", file);

          mutate(formData);
        } else if (props.fileDvs === "U") {
          const formData = new FormData();

          formData.append("prj_cd", PrjCd);
          formData.append("file", file);
          formData.append("hsp_cd", HspCd);

          userMutate(formData);
        }
      }
    }
  };

  const handleClickUploadBox = () => {
    inputRef.current.click();
  };

  const handleClickSuccess = () => {
    props.setModal(false);
    document.body.style.overflow = "unset";
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-start  p-20 ">
      {/* upload title */}
      <div className="flex h-fit w-full flex-row items-center justify-start py-5 font-SDGothic text-[24px] font-extrabold text-selectTextColor/80">
        Upload
      </div>
      {/* upload area */}
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClickUploadBox}
        className="relative mt-6 flex h-fit w-full flex-col items-center justify-center rounded-xl border-2 border-dotted border-FilterInActiveColor bg-[#5F7493]/5 py-20 font-bold text-[#5F7493]"
        ref={uploadBoxRef}
      >
        {isLoading || userIsLoading ? (
          <div className="absolute flex h-full w-full flex-col items-center justify-center rounded-md bg-white/30">
            <SyncLoader color="#0070E9" />
          </div>
        ) : null}

        {isError || userIsError ? (
          <div className="absolute flex h-full w-full flex-col items-center justify-center rounded-md bg-black/60 text-[20px] text-orangeColor">
            업로드 실패!
          </div>
        ) : null}

        <input type="file" name="excel" onChange={handleChangeFile} ref={inputRef} className="invisible" />
        {file ? (
          <div className="flex h-fit w-fit flex-col items-center justify-center text-[#556A51]">
            <AiFillFileExcel className="mb-8 text-[80px] " />
            {file.name}
          </div>
        ) : (
          <div className="flex h-fit w-fit flex-col items-center justify-center">
            <ImFolderUpload className="mb-8 text-[60px]  text-[#5F7493]" />
            파일 업로드
          </div>
        )}
      </div>
      <div className="mt-4 flex h-fit w-full flex-row items-center justify-start font-SDGothic text-[16px] font-bold text-orangeColor">
        <CgDanger className="mb-1 mr-3 text-[16px]" />
        복호화가 된 파일만 업로드 가능합니다
      </div>
      {isError || userIsError ? (
        <>
          <div className="mt-8 flex h-fit w-full flex-row items-center justify-start py-5 font-SDGothic text-[20px] font-extrabold text-selectTextColor/80">
            에러 로그
          </div>
          {/* error log area */}
          <div className="mt-6 flex h-[300px] w-full flex-col items-start justify-start rounded-xl border-2 border-dotted border-FilterInActiveColor bg-[#5F7493]/5 p-5 font-bold text-[#5F7493]">
            <Scrollbars
              renderTrackVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    backgroundColor: "transparent",
                    right: "2px",
                    bottom: "2px",
                    top: "2px",
                    borderRadius: "3px",
                    width: "5px",
                  }}
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    width: "7px",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#6C6C6C",
                    opacity: "60%",
                  }}
                />
              )}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={300}
            >
              <div className="flex h-fit w-full flex-row items-center justify-center">
                <div className="flex h-fit w-1/6 flex-col items-center justify-center p-5">셀 번호</div>
                <div className="flex h-fit w-5/6 flex-col items-center justify-center border-l border-FilterInActiveColor/40 p-5">내 용</div>
              </div>
              {error?.response.data.map((el, idx) => {
                return (
                  <div className="flex h-fit w-full flex-row items-center justify-center border-t border-FilterInActiveColor/40 text-selectTextColor/80">
                    <div className="flex h-fit w-1/6 flex-col items-center justify-center p-7">{el.pos}</div>
                    <div className="flex h-fit w-5/6 flex-col items-start justify-center border-l border-FilterInActiveColor/40 p-7">{el.msg}</div>
                  </div>
                );
              })}
              {userError?.response.data.map((el, idx) => {
                return (
                  <div className="flex h-fit w-full flex-row items-center justify-center border-t border-FilterInActiveColor/40 text-selectTextColor/80">
                    <div className="flex h-fit w-1/6 flex-col items-center justify-center p-7">{el.pos}</div>
                    <div className="flex h-fit w-5/6 flex-col items-start justify-center border-l border-FilterInActiveColor/40 p-7">{el.msg}</div>
                  </div>
                );
              })}
            </Scrollbars>
          </div>
        </>
      ) : null}

      {/* confirm btn */}
      {isSuccess || userIsSuccess ? (
        <button
          type="button"
          onClick={handleClickSuccess}
          className="mt-20 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-orangeColor px-20 py-8  text-[18px] text-white hover:bg-orangeColor/70"
        >
          성공
        </button>
      ) : (
        <button
          type="button"
          onClick={handleClickSubmit}
          className="mt-20 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-20 py-8  text-[18px] text-white hover:bg-loginBlueColor/70"
        >
          등록
        </button>
      )}
    </div>
  );
};

export default AdminQstUploadModalComp;
