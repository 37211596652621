import axios from "axios";

const getPrjPrd = (prjVal) => {
  return axios.get(`AnalysisMobile/prj_prd?prj_cd=${prjVal}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getPrjPrd;
