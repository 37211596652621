import axios from "axios";

const getAdminStandardQstSelRes = async (AnalysisType) => {
  const response = await axios.get(`/admin/qststand?ana_tp=${AnalysisType}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });

  const responseData = response.data;

  return responseData;
};

export default getAdminStandardQstSelRes;
