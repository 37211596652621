import { useEffect } from "react";

const useAutosizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    textAreaRef.current.style.height = "0px";
    const scrollHeight = textAreaRef.current.scrollHeight;

    textAreaRef.current.style.height = scrollHeight + "px";
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
