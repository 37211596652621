import { useEffect } from "react";

const DEPTable = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  data_type,
  stack,
  type,
  title,
  qst,
  name,
  keyword,
  Tdata,
}) => {
  useEffect(() => {}, [Tdata, prj_cd, svy_ojt, qst_cd, name, data_num, data_type, sel_cd]);

  return (
    <div key={`table_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}`} className="float-left w-full">
      {name === "dep" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-66.6rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[11.1rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[11.1rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구성원
            </div>
            <div className="flex h-[3.0rem] w-[11.1rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구성원(의사직)
            </div>
            <div className="flex h-[3.0rem] w-[11.1rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구성원(일반직)
            </div>
            <div className="flex h-[3.0rem] w-[11.1rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              내원고객
            </div>
            <div className="flex h-[3.0rem] w-[11.1rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              지역주민
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`Normal_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                  <div className="float-left flex h-[3.0rem] w-[calc(100%-66.6rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                    {data.sel_NM}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[11.1rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {(data_num === "1"
                      ? data.tot
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                          : data.tot.toLocaleString()
                        : 0
                      : data.tot_CNT
                      ? data.sel_NM === "사례수"
                        ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                        : data.tot_CNT.toLocaleString()
                      : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[11.1rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {(data_num === "1"
                      ? data.mem
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.mem)).toString().toLocaleString()
                          : data.mem.toLocaleString()
                        : 0
                      : data.mem_CNT
                      ? data.sel_NM === "사례수"
                        ? Math.floor(parseFloat(data.mem_CNT)).toString().toLocaleString()
                        : data.mem_CNT.toLocaleString()
                      : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[11.1rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {(data_num === "1"
                      ? data.doc
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc)).toString().toLocaleString()
                          : data.doc.toLocaleString()
                        : 0
                      : data.doc_CNT
                      ? data.sel_NM === "사례수"
                        ? Math.floor(parseFloat(data.doc_CNT)).toString().toLocaleString()
                        : data.doc_CNT.toLocaleString()
                      : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[11.1rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {(data_num === "1"
                      ? data.member
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.member)).toString().toLocaleString()
                          : data.member.toLocaleString()
                        : 0
                      : data.member_CNT
                      ? data.sel_NM === "사례수"
                        ? Math.floor(parseFloat(data.member_CNT)).toString().toLocaleString()
                        : data.member_CNT.toLocaleString()
                      : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[11.1rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {(data_num === "1"
                      ? data.vst
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.vst)).toString().toLocaleString()
                          : data.vst.toLocaleString()
                        : 0
                      : data.vst_CNT
                      ? data.sel_NM === "사례수"
                        ? Math.floor(parseFloat(data.vst_CNT)).toString().toLocaleString()
                        : data.vst_CNT.toLocaleString()
                      : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[11.1rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {(data_num === "1"
                      ? data.lres
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.lres)).toString().toLocaleString()
                          : data.lres.toLocaleString()
                        : 0
                      : data.lres_CNT
                      ? data.sel_NM === "사례수"
                        ? Math.floor(parseFloat(data.lres_CNT)).toString().toLocaleString()
                        : data.lres_CNT.toLocaleString()
                      : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default DEPTable;
