import axios from "axios";

const postGpt = (body) => {
  return axios.post("/v1/chat/completions", body, {
    baseURL: process.env.REACT_APP_GPT_API_ADDRESS,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_GPT_API_KEY}`,
      "content-Type": `application/json`,
    },
  });
};

export default postGpt;
