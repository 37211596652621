import axios from "axios";

const getAdminUserEtcTitle = (prj_cd, etc_num) => {
  return axios.get(`/adminUser/getAdminUserEtcTitle?prj_cd=${prj_cd}&etc_num=${etc_num}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminUserEtcTitle;
