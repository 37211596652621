import { useEffect } from "react";
import { useNavigate } from "react-router";
// import { menuDict } from "../../../dict/mobile/dict";
import useGetMenuList from "../../../hooks/useGetMenuList";
import {
  useComnDict,
  useMenuList,
  useMenuListModalControlState,
  useMenuState,
  usePreventRequestFilterState,
  usePrjValState,
  useQstListState,
  useSelListModalState,
} from "../../../store/mobile/store";

const NavBar = () => {
  const navigate = useNavigate();

  const menuDict = useComnDict((state) => state.menuDict);

  // selList Modal control
  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  // 현재 선택된 메뉴 id
  const menuVal = useMenuState((state) => state.menuVal);
  // 메뉴 id 설정함수
  const setMenuVal = useMenuState((state) => state.setMenuVal);

  // prjVal global state
  const prjVal = usePrjValState((state) => state.prjVal);

  // hspVal global state
  // const hspVal = useHspValState((state) => state.hspVal);

  // menuList global state
  const menuList = useMenuList((state) => state.menuList);

  // preventReqFilterState
  const preventReqFilterState = usePreventRequestFilterState((state) => state.preventReqFilterState);

  // QstListState
  const qstList = useQstListState((state) => state.qstList);

  // QstListModalState
  const setQstListModalState = useMenuListModalControlState((state) => state.setMenuListModalControl);

  // const setPreventReqFilterState = usePreventRequestFilterState(
  //   (state) => state.setPreventReqFilterState
  // );

  // prj Modal active control state
  // const prjModalState = useActivePrjModalState((state) => state.prjModalState);
  // const setPrjModalState = useActivePrjModalState((state) => state.setPrjModal);

  // menuList api
  const { refetch } = useGetMenuList(prjVal.value);
  // const { isLoading, isFetched, isSuccess, data } = useQuery(["mobileQst"]);

  // menu onclick handling function
  const handleClickMenu = (menu) => {
    setMenuVal(menu);
    // navigate("/m/common/menuList");
    setQstListModalState(true);
    setSelListModalState(false);
  };

  // prj Info onclick handling function
  // const handleClickPrjTitle = () => {
  //   setPrjModalState(!prjModalState);
  //   setPreventReqFilterState(true);
  // };

  useEffect(() => {
    // prjVal이 존재할 경우만
    if (prjVal.value && !preventReqFilterState) {
      refetch();
    }
  }, [prjVal]);

  return (
    <div className="flex h-fit w-full flex-row items-center justify-center bg-blue2Color">
      {/* project info
      <div
        onClick={handleClickPrjTitle}
        className=" mt-3 flex h-fit w-full cursor-pointer flex-col items-center justify-center p-2 font-SDGothic text-2xl font-bold text-white "
      >
        {`${hspVal.label} - ${prjVal.label}`}
      </div> */}
      {/* count info */}

      {/* 10000이 넘으면 flex-col */}
      <div
        className={
          qstList.length === 0
            ? "flex h-full w-[24%] flex-col items-center justify-center bg-blue1Color font-SDGothic text-[12px] text-white"
            : qstList[0].cnt < 10000 || qstList[0].tot_CNT < 10000
            ? `flex h-full w-[24%] flex-row items-center justify-center bg-blue1Color font-SDGothic text-[12px] text-white`
            : `flex h-full w-[24%] flex-col items-center justify-center bg-blue1Color font-SDGothic text-[12px] text-white`
        }
      >
        {menuVal === "MEM" ? (
          <>
            {/* 10000아래면 mr-2 */}
            <div className={qstList[0]?.cnt < 10000 || qstList[0]?.tot_CNT < 10000 ? `mr-2 h-fit w-fit` : "h-fit w-fit"}>
              {qstList[0]?.cnt === null ? `0` : qstList[0]?.cnt}
            </div>
            <div className="h-fit w-fit">{`/ ${qstList[0]?.tot_CNT}명`}</div>
          </>
        ) : menuVal === "VST" || menuVal === "LRES" || menuVal === "CS" || menuVal === "RS" ? (
          <>
            <div className="h-fit w-fit">{qstList[0]?.tot_CNT ? `${qstList[0]?.tot_CNT}명` : `0명`}</div>
          </>
        ) : (
          <div className="h-fit w-fit text-white/50">완료수/배포수</div>
        )}
      </div>
      <div className="flex h-fit min-h-[40px] w-[76%] flex-row items-center justify-start p-3">
        {/* menuList */}
        <div className="flex h-fit w-full flex-row items-center justify-between rounded-xl ">
          {/* ../dict/mobile/dict.js 참고 */}
          {menuDict.map((menu) => {
            // 사용가능 메뉴가 아닌 경우
            if (!menuList.includes(menu.value)) {
              return (
                <div
                  key={menu.id}
                  className="mx-1 flex h-fit w-1/3 flex-col items-center justify-center rounded-xl bg-blue1Color px-1 py-4 font-SDGothic text-[14px] text-white/30"
                >
                  {menu.name}
                </div>
              );
            }
            // 선택된 메뉴인 경우
            else if (menu.value === menuVal) {
              return (
                <div
                  key={menu.id}
                  onClick={() => handleClickMenu(menu.value)}
                  className="mx-1 flex h-fit w-1/3 flex-col items-center justify-center rounded-xl bg-blue1Color bg-gradient-to-r from-checkedBtnStartColor to-chekcedBtnEndColor px-1 py-4 font-SDGothic text-[14px] font-bold text-white"
                >
                  {menu.name}
                </div>
              );
            }
            // 선택되지 않은 메뉴인 경우
            else {
              return (
                <div
                  onClick={() => handleClickMenu(menu.value)}
                  key={menu.id}
                  className="mx-1 flex h-fit w-1/3  cursor-pointer flex-col items-center justify-center rounded-xl bg-blue1Color px-1 py-4 font-SDGothic text-[14px] text-white"
                >
                  {menu.name}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;

{
  /* checkedBtn */
}
{
  /* <div className=" flex h-fit w-1/4 flex-col items-center justify-center rounded-md bg-gradient-to-r from-checkedBtnStartColor to-chekcedBtnEndColor p-2  font-SDGothic text-xl text-white"> */
}

{
  /* unCheckedBtn */
}
{
  /* <div className="flex h-fit w-1/4 flex-col items-center justify-center rounded-md p-2 font-SDGothic text-xl text-white"> */
}

{
  /* disabled Btn */
}
{
  /* <div className="flex h-fit w-1/4 flex-col items-center justify-center rounded-md p-2 font-SDGothic text-xl text-white/30"> */
}
