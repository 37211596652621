import { HiEllipsisVertical } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useActivePrjModalState, useMenuState, usePrjValState, useSelListModalState, useServiceModeState } from "../../../store/mobile/store";

const Header = () => {
  const navigate = useNavigate();

  // prjVal global state
  const prjVal = usePrjValState((state) => state.prjVal);
  const setPrjModal = useActivePrjModalState((state) => state.setPrjModal);

  // select menu state
  const setMenuState = useMenuState((state) => state.setMenuVal);

  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  // service Mode
  const serviceMode = useServiceModeState((state) => state.serviceMode);

  // onClick on Logo handing function
  const handleClickLogo = () => {
    navigate("/m/common/analysis");
    setMenuState("");
  };

  const handleClickFilter = () => {
    setPrjModal(true);
    setSelListModalState(false);
  };

  return (
    <div className="flex h-fit w-full flex-row items-center justify-start bg-gradient-to-t from-white to-headerEndColor">
      {/* logo */}
      <div
        className={
          serviceMode === "scan"
            ? `border-gradient-to-t flex h-fit w-[25%] flex-col items-center justify-center border-r from-bdGradientStColor to-tableBorderColor px-3 py-4`
            : `border-gradient-to-t flex h-fit w-[25%] flex-col items-center justify-center border-r from-bdGradientStColor to-tableBorderColor py-4 pl-2 pr-1`
        }
      >
        {serviceMode === "scan" ? (
          <img
            src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/scan_logo.png"
            alt="scan_logo"
            className="h-auto w-full"
            onClick={handleClickLogo}
          />
        ) : (
          <img
            src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/csscan_logo.png"
            alt="csscan_logo"
            className="h-auto w-full"
            onClick={handleClickLogo}
          />
        )}
      </div>
      {/* prj_nm */}
      <div
        onClick={handleClickFilter}
        className="flex h-fit w-[65%] flex-col items-start justify-center px-4 py-5 font-SDGothic text-[14px] font-bold text-textColor fold:text-2xl"
      >
        {prjVal.label}
      </div>
      {/* btn */}
      <div
        onClick={handleClickFilter}
        className="flex h-fit w-[15%] flex-row items-center justify-start font-SDGothic text-[14px] font-semibold text-blue3Color fold:text-2xl"
      >
        <HiEllipsisVertical />
        변경
      </div>
    </div>
  );
};

export default Header;
