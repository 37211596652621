import { useQuery } from "@tanstack/react-query";
import getAdminUserDetail from "../api/getAdminUserDetail";

const useGetAdminUserDetail = (prj_cd, user_id) => {
  return useQuery(["adminUserDetail"], () => getAdminUserDetail(prj_cd, user_id), {
    enabled: false,
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserDetail;
