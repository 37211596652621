import { useQuery } from "@tanstack/react-query";
import getComnDict from "../api/getComnDict";
import getCsComnDict from "../api/getCsComnDict";
import { useComnDict, useServiceModeState } from "../store/mobile/store";

const useGetComnDict = () => {
  const setMenuDict = useComnDict((state) => state.setMenuDict);
  const setWorkDict = useComnDict((state) => state.setWorkDict);
  const setWorkRateDict = useComnDict((state) => state.setWorkRateDict);
  const setAgeDict = useComnDict((state) => state.setAgeDict);
  const setAgeCntDict = useComnDict((state) => state.setAgeCntDict);
  const setAgeRateDict = useComnDict((state) => state.setAgeRateDict);
  const setWorkCntDict = useComnDict((state) => state.setWorkCntDict);
  const setWorkCodeDict = useComnDict((state) => state.setWorkCodeDict);
  const setAgeCodeDict = useComnDict((state) => state.setAgeCodeDict);
  const serviceMode = useServiceModeState((state) => state.serviceMode);

  return useQuery(["comnDict"], serviceMode === "scan" ? () => getComnDict() : () => getCsComnDict(), {
    enabled: false,
    onSuccess: (res) => {
      // menuDict
      let menuTempArr = res.data.menu_DICT.map((el, idx) => {
        return {
          id: el.id,
          value: el.comn_CD,
          name: el.comn_NM,
        };
      });

      setMenuDict(menuTempArr);

      // workDict
      let workTempArr = res.data.work_DICT.map((el, idx) => {
        return {
          sel_cd:
            el.comn_NM === "전체"
              ? "tot"
              : el.comn_NM === "의사직"
              ? "doc"
              : el.comn_NM === "간호직"
              ? "nr"
              : el.comn_NM === "보건직"
              ? "hlth"
              : el.comn_NM === "약무직"
              ? "pham"
              : el.comn_NM === "사무직"
              ? "office"
              : el.comn_NM === "기술직"
              ? "tech"
              : el.comn_NM === "기타"
              ? "etc"
              : "-",
          sel_nm: el.comn_NM,
        };
      });

      setWorkDict(workTempArr);

      // workRateDict
      let workRateTempArr = res.data.work_DICT.map((el, idx) => {
        return {
          sel_cd:
            el.comn_NM === "전체"
              ? "tot_RATE"
              : el.comn_NM === "의사직"
              ? "doc_RATE"
              : el.comn_NM === "간호직"
              ? "nr_RATE"
              : el.comn_NM === "보건직"
              ? "hlth_RATE"
              : el.comn_NM === "약무직"
              ? "pham_RATE"
              : el.comn_NM === "사무직"
              ? "office_RATE"
              : el.comn_NM === "기술직"
              ? "tech_RATE"
              : el.comn_NM === "기타"
              ? "etc_RATE"
              : "-",
          sel_nm: el.comn_NM,
        };
      });

      setWorkRateDict(workRateTempArr);

      // workCntDict
      let workCntTempArr = res.data.work_DICT.map((el, idx) => {
        return {
          sel_cd:
            el.comn_NM === "전체"
              ? "tot_CNT"
              : el.comn_NM === "의사직"
              ? "doc_CNT"
              : el.comn_NM === "간호직"
              ? "nr_CNT"
              : el.comn_NM === "보건직"
              ? "hlth_CNT"
              : el.comn_NM === "약무직"
              ? "pham_CNT"
              : el.comn_NM === "사무직"
              ? "office_CNT"
              : el.comn_NM === "기술직"
              ? "tech_CNT"
              : el.comn_NM === "기타"
              ? "etc_CNT"
              : "-",
          sel_nm: el.comn_NM,
        };
      });

      setWorkCntDict(workCntTempArr);

      // ageDict
      let ageTempArr = res.data.age_DICT.map((el, idx) => {
        return {
          sel_cd:
            el.comn_NM === "전체"
              ? "tot"
              : el.comn_NM === "10대 이하"
              ? "a10"
              : el.comn_NM === "20대"
              ? "a20"
              : el.comn_NM === "30대"
              ? "a30"
              : el.comn_NM === "40대"
              ? "a40"
              : el.comn_NM === "50대"
              ? "a50"
              : el.comn_NM === "60대"
              ? "a60"
              : el.comn_NM === "70대"
              ? "a70"
              : el.comn_NM === "80대 이상"
              ? "a80"
              : "-",
          sel_nm: el.comn_NM,
        };
      });

      setAgeDict(ageTempArr);

      // ageCntDict
      let ageCntTempArr = res.data.age_DICT.map((el, idx) => {
        return {
          sel_cd:
            el.comn_NM === "전체"
              ? "tot_CNT"
              : el.comn_NM === "10대 이하"
              ? "a10_CNT"
              : el.comn_NM === "20대"
              ? "a20_CNT"
              : el.comn_NM === "30대"
              ? "a30_CNT"
              : el.comn_NM === "40대"
              ? "a40_CNT"
              : el.comn_NM === "50대"
              ? "a50_CNT"
              : el.comn_NM === "60대"
              ? "a60_CNT"
              : el.comn_NM === "70대"
              ? "a70_CNT"
              : el.comn_NM === "80대 이상"
              ? "a80_CNT"
              : "-",
          sel_nm: el.comn_NM,
        };
      });

      setAgeCntDict(ageCntTempArr);

      // ageRateDict
      let ageRateTempArr = res.data.age_DICT.map((el, idx) => {
        return {
          sel_cd:
            el.comn_NM === "전체"
              ? "tot_RATE"
              : el.comn_NM === "10대 이하"
              ? "a10_RATE"
              : el.comn_NM === "20대"
              ? "a20_RATE"
              : el.comn_NM === "30대"
              ? "a30_RATE"
              : el.comn_NM === "40대"
              ? "a40_RATE"
              : el.comn_NM === "50대"
              ? "a50_RATE"
              : el.comn_NM === "60대"
              ? "a60_RATE"
              : el.comn_NM === "70대"
              ? "a70_RATE"
              : el.comn_NM === "80대 이상"
              ? "a80_RATE"
              : "-",
          sel_nm: el.comn_NM,
        };
      });

      setAgeRateDict(ageRateTempArr);

      // workCodeDict
      let wordCodeTempArr = res.data.work_DICT
        .filter((db, dbIdx) => dbIdx !== 0)
        .map((el, idx) => {
          return {
            sel_code: el.comn_CD,
            sel_nm: el.comn_NM,
          };
        });

      setWorkCodeDict(wordCodeTempArr);

      // ageCodeDict
      let ageCodeTempArr = res.data.age_DICT
        .filter((db, dbIdx) => dbIdx !== 0)
        .map((el, idx) => {
          return {
            sel_code: el.comn_CD,
            sel_nm: el.comn_NM,
          };
        });

      setAgeCodeDict(ageCodeTempArr);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useGetComnDict;
