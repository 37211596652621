import { useMutation, useQueryClient } from "@tanstack/react-query";
import patchAdminUserArea from "../api/patchAdminUserArea";

const usePatchAdminUserArea = () => {
  const queryClient = useQueryClient();

  return useMutation(patchAdminUserArea, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserArea"]);
    },
    onError: (err) => {
      console.log(err);
      alert("이미 부여된 코드입니다!");
    },
  });
};

export default usePatchAdminUserArea;
