import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import Da10Chart from "../../components/mobile/chart/Da10Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da10Table from "../../components/mobile/table/Da10Table";
import useGetDa10Res from "../../hooks/useGetDa10Res";
import {
  useComnDict,
  useMenuListModalControlState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useUnitState,
} from "../../store/mobile/store";

const DA10 = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);
  const ageCntDict = useComnDict((state) => state.ageCntDict);
  const workCntDict = useComnDict((state) => state.workCntDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const prjVal = usePrjValState((state) => state.prjVal);
  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  const { refetch, data } = useGetDa10Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, startDate, endDate);

  const [rendering, setRendering] = useState(false);

  const initData = data?.data[0];

  let chartData = [];

  // 사례수
  let caseCnt = [];

  // categories
  let categories = [];

  // chart data 추출
  // 직종별 데이터
  if (menuState === "MEM") {
    // 비율
    if (unitState === "rate") {
      workDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data, idx2) => {
          if (idx === 0) {
            categories.push(data.sel_NM);
            caseCnt.push(data.case_CNT);
          }

          temp.push(data[el.sel_cd]);
        });

        chartData.push(temp);
      });
    }
    // 카운트
    else {
      workCntDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data, idx2) => {
          if (idx === 0) {
            categories.push(data.sel_NM);
            caseCnt.push(data.case_CNT);
          }

          temp.push(data[el.sel_cd]);
        });

        chartData.push(temp);
      });
    }
  }
  // 연령별 데이터
  else if (menuState === "VST" || menuState === "LRES" || selectQstState.svy_OJT === "CS" || selectQstState.svy_OJT === "RS") {
    // 비율
    if (unitState === "rate") {
      ageDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data, idx2) => {
          if (idx === 0) {
            categories.push(data.sel_NM);
            caseCnt.push(data.case_CNT);
          }

          temp.push(data[el.sel_cd]);
        });

        chartData.push(temp);
      });
    }
    // 카운트
    else {
      ageCntDict.forEach((el, idx) => {
        let temp = [];
        initData?.forEach((data, idx2) => {
          if (idx === 0) {
            categories.push(data.sel_NM);
            caseCnt.push(data.case_CNT);
          }

          temp.push(data[el.sel_cd]);
        });

        chartData.push(temp);
      });
    }
  }

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 500);

    return () => {
      setRendering(false);
    };
  }, [selectQstState, startDate, endDate]);

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {selectQstState.keyword}
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>
          {rendering && !qstListModalState && chartData ? (
            <>
              <Da10Chart data={chartData} categories={categories} />
              <Da10Table categories={categories} caseCnt={caseCnt} qstInfo={selectQstState} data={chartData} />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default DA10;
