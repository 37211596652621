import axios from "axios";

const getAdminUserEtc = (prj_cd, etc_num) => {
  return axios.get(`/adminUser/getAdminUserEtc?prj_cd=${prj_cd}&etc_num=${etc_num}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminUserEtc;
