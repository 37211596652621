import { getSvyOjtList } from "./getSvyOjtList";

export const makeStandardQstDynamicData = (getStandardQstData) => {
  const svyOjtList = getSvyOjtList(getStandardQstData);
  const SvyOjtFirstIndexList = svyOjtList.map((item) => item[0]);
  return getStandardQstData.map((item) => {
    const newItem = { ...item };

    if (SvyOjtFirstIndexList.includes("MEM")) {
      newItem.custom_member_YN = item.member_YN;
    }
    if (SvyOjtFirstIndexList.includes("VST")) {
      newItem.custom_vist_YN = item.vist_YN;
    }
    if (SvyOjtFirstIndexList.includes("LRES")) {
      newItem.custom_loc_RES_YN = item.loc_RES_YN;
    }
    if (SvyOjtFirstIndexList.includes("CS")) {
      newItem.custom_cs_YN = item.cs_YN;
    }
    if (SvyOjtFirstIndexList.includes("RS")) {
      newItem.custom_rs_YN = item.rs_YN;
    }

    // Return the modified object with additional custom keys
    return newItem;
  });
};

// {
//  "keyword": "경영환경에 대한 전망",
//   "sort_TYPE": "M",
//   "multi_COUNT": null,
//   "dvs_CD": "001",
//   "nps_ST_WD": null,
//   "obj_ANA_YN": "Y",
//   "vist_YN": "N",
//   "ess_YN": "Y",
//   "loc_RES_YN": "N",
//   "cs_YN": "N",
//   "rs_YN": "N",
//   "member_YN": "Y",
//   "inpt_DTM": "2024-07-25 14:19:40"
// }
