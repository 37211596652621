import { useMutation, useQueryClient } from "@tanstack/react-query";
import patchAdminUserEtc from "../api/patchAdminUserEtc";

const usePatchAdminUserEtc = () => {
  const queryClient = useQueryClient();

  return useMutation(patchAdminUserEtc, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserEtc"]);
    },
    onError: (err) => {
      console.log(err);
      alert("이미 부여된 코드입니다!");
    },
  });
};

export default usePatchAdminUserEtc;
