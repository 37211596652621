import { SyncLoader } from "react-spinners";

const override = {
  display: "flex",
  margin: "0 auto",
  textAlign: "center",
  position: "absolute",
  top: "calc(50% - 19px)",
  left: "50%",
};

const Loading = ({ loading }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 h-full w-full overflow-hidden bg-spinnerBackColor">
      <SyncLoader color="#1686FF" loading={loading} cssOverride={override} />
    </div>
  );
};

export default Loading;
