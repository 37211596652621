// import { ageDict, workDict } from "../../../dict/mobile/dict";
import { useComnDict, useMenuState, useUnitState } from "../../../store/mobile/store";
const Da05Table = (props) => {
  const unitState = useUnitState((state) => state.unitState);
  const menuState = useMenuState((state) => state.menuVal);

  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  return (
    <div className="flex h-fit w-full flex-row items-start justify-start overflow-auto border-l border-qstTitleBorder">
      {/* 구분 */}
      <div className="sticky left-0 flex h-fit w-fit  flex-col items-center justify-start">
        <div className="flex h-fit w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
          {/* {menuState === "MEM" ? `직종` : `연령대`} */}
          구분
        </div>
        {props?.data[0].map((el, idx) => {
          return (
            <div
              key={idx}
              className={
                idx % 2 === 0
                  ? `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  : `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
              }
            >
              {el}
            </div>
          );
        })}
      </div>
      {props?.data[1].map((el, idx) => {
        return (
          <div key={idx} className=" h-fit w-fit items-center justify-start">
            <div className="flex h-fit w-fit  min-w-full  flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-8 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
              {menuState === "MEM"
                ? workDict[idx].sel_nm
                : menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS"
                ? ageDict[idx].sel_nm
                : null}
            </div>
            {el.map((data, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    idx % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4  font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {idx === 0
                    ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                    : unitState === "rate"
                    ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`
                    : `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Da05Table;
