import { useEffect } from "react";
import Checkbox from "../../components/pc/admin/Checkbox";
import useGetAdminProjectList from "../../hooks/Admin/Common/useGetAdminProjectList";
import useGetAdminUserDisp from "../../hooks/useGetAdminUserDisp";
import usePostAdminDisp from "../../hooks/usePostAdminDisp";
import useStore from "../../store/pc/store";

const dict = [
  {
    value: "DISP_DEPT_YN",
    key: "disp_DEPT_YN",
    label: "부서 조사 여부",
  },
  {
    value: "DISP_OCTY_YN",
    key: "disp_OCTY_YN",
    label: "직종 조사 여부",
  },
  {
    value: "DISP_WRK_YY_GRP_YN",
    key: "disp_WRK_YY_GRP_YN",
    label: "연차그룹 조사 여부",
  },
  {
    value: "DISP_ASSIGN_YN",
    key: "disp_ASSIGN_YN",
    label: "보직여부 조사 여부",
  },
  {
    value: "DISP_AGE_GRP_YN",
    key: "disp_AGE_GRP_YN",
    label: "연령대 조사 여부",
  },
  {
    value: "DISP_SEX_YN",
    key: "disp_SEX_YN",
    label: "성별 조사 여부",
  },
  {
    value: "DISP_CENTER_YN",
    key: "disp_CENTER_YN",
    label: "센터 조사 여부",
  },
  {
    value: "DISP_IO_YN",
    key: "disp_IO_YN",
    label: "입원, 외래 조사 여부",
  },
  {
    value: "DISP_AREA_YN",
    key: "disp_AREA_YN",
    label: "지역 조사 여부",
  },
  {
    value: "DISP_MAIN_DEPT_YN",
    key: "disp_MAIN_DEPT_YN",
    label: "방문진료과 조사 여부",
  },
  {
    value: "DISP_ETC1_YN",
    key: "disp_ETC1_YN",
    label: "기타 1 조사 여부",
  },
  {
    value: "DISP_ETC2_YN",
    key: "disp_ETC2_YN",
    label: "기타 2 조사 여부",
  },
  {
    value: "DISP_ETC3_YN",
    key: "disp_ETC3_YN",
    label: "기타 3 조사 여부",
  },
];

const AdminUserDisp = () => {
  const PrjCd = useStore((state) => state.PrjCd);
  const { data: adminProjectList, isLoading: adminProjectListLoading } = useGetAdminProjectList(PrjCd);

  const { data, refetch } = useGetAdminUserDisp(PrjCd);

  const { mutate } = usePostAdminDisp();

  const handleChangeCheckbox = (svy_ojt, comn_cd, value) => {
    const body = {
      prj_CD: PrjCd,
      svy_OJT: svy_ojt,
      comn_CD: comn_cd,
      value: value === "N" ? "Y" : "N",
    };

    mutate(body);
  };

  useEffect(() => {
    refetch();
  }, [PrjCd]);

  console.log(data?.data, "data?.data");

  return (
    <div className="h-full min-h-[2000px] w-full flex-col items-center justify-start p-3 font-SDGothic text-[16px] font-bold text-selectTextColor/80">
      {/* subTitle */}
      <div className="flex h-fit w-full flex-col items-start justify-start py-3">
        <div className="mt-10 h-fit w-fit text-[18px] font-bold">사전정보 조사 여부</div>
        <div className="mt-6 h-fit w-fit text-[14px] font-bold text-selectTextColor/50">
          설문을 시작하기 전, 설문자에게 요청되는 사전정보를 설정해주세요
        </div>
      </div>

      {/* data */}
      {data?.data
        .filter((db) => adminProjectList.includes(db.svy_OJT))
        .map((db, dbIdx) => {
          return (
            <>
              <div key={dbIdx} className="mt-16 flex h-fit w-full flex-row items-start justify-start">
                <img
                  src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
                  alt="menu_dot"
                  className="mb-1 mr-5 w-[8px]"
                />
                <div className="h-fit w-fit text-[18px] font-bold">{db.svy_NM}</div>
              </div>

              <div key={`a${dbIdx}`} className="mt-10 flex h-fit  w-full flex-wrap items-start justify-start leading-[40px]">
                {dict.map((el, idx) => {
                  return (
                    <Checkbox
                      key={idx}
                      disabled={false}
                      checked={db[el.key] === "N" ? false : true}
                      onChange={() => handleChangeCheckbox(db.svy_OJT, el.value, db[el.key])}
                    >
                      {el.label}
                    </Checkbox>
                  );
                })}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default AdminUserDisp;
