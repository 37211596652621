import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChart from "../../components/pc/BarColumnChart";
import CSTrendChartPeriod from "../../components/pc/CSTrendChartPeriod";
import Loading from "../../components/pc/Loading";
import NormalTable from "../../components/pc/NormalTable";
import PieChart from "../../components/pc/PieChart";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphAgeArea } from "../../hooks/useGraphAgeArea";
import { useGraphAgeGrp } from "../../hooks/useGraphAgeGrp";
import { useGraphAgeMainDept } from "../../hooks/useGraphAgeMainDept";
import { useGraphAgeSex } from "../../hooks/useGraphAgeSex";
import { useGraphArea } from "../../hooks/useGraphArea";
import { useGraphAssign } from "../../hooks/useGraphAssign";
import { useGraphBasic } from "../../hooks/useGraphBasic";
import { useGraphDoc } from "../../hooks/useGraphDoc";
import { useGraphEtc1 } from "../../hooks/useGraphEtc1";
import { useGraphEtc2 } from "../../hooks/useGraphEtc2";
import { useGraphEtc3 } from "../../hooks/useGraphEtc3";
import { useGraphMainDept } from "../../hooks/useGraphMainDept";
import { useGraphOcty } from "../../hooks/useGraphOcty";
import { useGraphSex } from "../../hooks/useGraphSex";
import { useGraphStandard } from "../../hooks/useGraphStandard";
import { useGraphStandardAccu } from "../../hooks/useGraphStandardAccu";
import { useGraphWrkYyGrp } from "../../hooks/useGraphWrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableAgeGrp } from "../../hooks/useTableAgeGrp";
import { useTableAssign } from "../../hooks/useTableAssign";
import { useTableDoc } from "../../hooks/useTableDoc";
import { useTableOcty } from "../../hooks/useTableOcty";
import { useTableSex } from "../../hooks/useTableSex";
import { useTableWrkYyGrp } from "../../hooks/useTableWrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const NormalPage = () => {
  const analysisType = useStore((state) => state.AnalysisType);
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const HspCd = useStore((state) => state.HspCd);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [Sel, setSel] = useState([{}]);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.age_GRP_CNT !== "0" && dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "AgeGrpArea", label: "연령별 주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }
        if (dd.age_GRP_CNT !== "0" && dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "AgeGrpMainDept", label: "연령별 방문진료과" });
        }
        if (dd.age_GRP_CNT !== "0" && dd.sex_CNT !== "0") {
          vRadio.push({ value: "AgeGrpSex", label: "연령별 성별" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null) {
  //     QstListRefetch();
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData === undefined || SelListData === null || SelListData.length === 0) {
      SelListRefetch();
    } else {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
      setQst(vQst);
      setKeyword(vKeyword);
      setCntYn(vCntYn);
      setQstCd(Pick.qst_cd);
    }
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const { isGraphStandardAccuLoading, isGraphStandardAccuError, GraphStandardAccuData, isGraphStandardAccuSuccess, GraphStandardAccuRefetch } =
    useGraphStandardAccu(PrjCd, SvyOjt, QstCd, "", "", SvyOjt === "MEM" ? "OCTY" : "AGE_GRP", SortType, startDate, endDate);

  const { isGraphStandardLoading, isGraphStandardError, GraphStandardData, isGraphStandardSuccess, GraphStandardRefetch } = useGraphStandard(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphBasicLoading, isGraphBasicError, GraphBasicData, isGraphBasicSuccess, GraphBasicRefetch } = useGraphBasic(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphAssignLoading, isGraphAssignError, GraphAssignData, isGraphAssignSuccess, GraphAssignRefetch } = useGraphAssign(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDocLoading, isGraphDocError, GraphDocData, isGraphDocSuccess, GraphDocRefetch } = useGraphDoc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphAgeGrpLoading, isGraphAgeGrpError, GraphAgeGrpData, isGraphAgeGrpSuccess, GraphAgeGrpRefetch } = useGraphAgeGrp(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphSexLoading, isGraphSexError, GraphSexData, isGraphSexSuccess, GraphSexRefetch } = useGraphSex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphOctyLoading, isGraphOctyError, GraphOctyData, isGraphOctySuccess, GraphOctyRefetch } = useGraphOcty(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphWrkYyGrpLoading, isGraphWrkYyGrpError, GraphWrkYyGrpData, isGraphWrkYyGrpSuccess, GraphWrkYyGrpRefetch } = useGraphWrkYyGrp(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "WRK_YY_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphMainDeptLoading, isGraphMainDeptError, GraphMainDeptData, isGraphMainDeptSuccess, GraphMainDeptRefetch } = useGraphMainDept(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphAgeMainDeptLoading, isGraphAgeMainDeptError, GraphAgeMainDeptData, isGraphAgeMainDeptSuccess, GraphAgeMainDeptRefetch } =
    useGraphAgeMainDept(PrjCd, SvyOjt, QstCd, "", "", SvyOjt === "MEM" ? "OCTY" : "AGE_GRP", SortType, startDate, endDate);

  const { isGraphAgeSexLoading, isGraphAgeSexError, GraphAgeSexData, isGraphAgeSexSuccess, GraphAgeSexRefetch } = useGraphAgeSex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphAreaLoading, isGraphAreaError, GraphAreaData, isGraphAreaSuccess, GraphAreaRefetch } = useGraphArea(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphAgeAreaLoading, isGraphAgeAreaError, GraphAgeAreaData, isGraphAgeAreaSuccess, GraphAgeAreaRefetch } = useGraphAgeArea(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphEtc1Loading, isGraphEtc1Error, GraphEtc1Data, isGraphEtc1Success, GraphEtc1Refetch } = useGraphEtc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphEtc2Loading, isGraphEtc2Error, GraphEtc2Data, isGraphEtc2Success, GraphEtc2Refetch } = useGraphEtc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isGraphEtc3Loading, isGraphEtc3Error, GraphEtc3Data, isGraphEtc3Success, GraphEtc3Refetch } = useGraphEtc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isTableAgeGrpLoading, isTableAgeGrpError, TableAgeGrpData, isTableAgeGrpSuccess, TableAgeGrpRefetch } = useTableAgeGrp(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isTableAssignLoading, isTableAssignError, TableAssignData, isTableAssignSuccess, TableAssignRefetch } = useTableAssign(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isTableDocLoading, isTableDocError, TableDocData, isTableDocSuccess, TableDocRefetch } = useTableDoc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isTableOctyLoading, isTableOctyError, TableOctyData, isTableOctySuccess, TableOctyRefetch } = useTableOcty(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isTableSexLoading, isTableSexError, TableSexData, isTableSexSuccess, TableSexRefetch } = useTableSex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  const { isTableWrkYyGrpLoading, isTableWrkYyGrpError, TableWrkYyGrpData, isTableWrkYyGrpSuccess, TableWrkYyGrpRefetch } = useTableWrkYyGrp(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    SvyOjt === "MEM" ? "OCTY" : "AGE_GRP",
    SortType,
    startDate,
    endDate
  );

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length > 0) {
        let graphList = GraphSet.map((el) => el.value);

        if (graphList.includes("Octy")) {
          GraphOctyRefetch();
          // console.log("octy");
        }

        if (graphList.includes("Doc")) {
          GraphDocRefetch();
          // console.log("doc");
        }

        if (graphList.includes("AgeGrp")) {
          GraphAgeGrpRefetch();
          // console.log("ageGrp");
        }

        if (graphList.includes("Assign")) {
          GraphAssignRefetch();
          // console.log("assign");
        }

        if (graphList.includes("WrkYyGrp")) {
          GraphWrkYyGrpRefetch();
          // console.log("wrkYyGrp");
        }

        if (graphList.includes("Sex")) {
          GraphSexRefetch();
          // console.log("sex");
        }

        if (graphList.includes("AgeGrpSex")) {
          GraphAgeSexRefetch();
          // console.log("ageGrpSex");
        }

        if (graphList.includes("MainDept")) {
          GraphMainDeptRefetch();
          // console.log("mainDept");
        }

        if (graphList.includes("Area")) {
          GraphAreaRefetch();
          // console.log("area");
        }

        if (graphList.includes("AgeArea")) {
          GraphAgeAreaRefetch();
          // console.log("ageArea");
        }

        if (graphList.includes("AgeGrpMainDept")) {
          GraphAgeMainDeptRefetch();
          // console.log("ageMainDept");
        }

        if (graphList.includes("AgeGrpArea")) {
          GraphAgeAreaRefetch();
          // console.log("ageMainDept");
        }

        if (graphList.includes("Etc1")) {
          GraphEtc1Refetch();
          // console.log("etc1");
        }

        if (graphList.includes("Etc2")) {
          GraphEtc2Refetch();
          // console.log("etc2");
        }

        if (graphList.includes("Etc3")) {
          GraphEtc3Refetch();
          // console.log("etc3");
        }

        GraphStandardAccuRefetch();
        GraphStandardRefetch();
        GraphBasicRefetch();
      }

      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioTypeCheck === "AgeGrp") {
      GraphAgeGrpRefetch();
      GraphStandardAccuRefetch();
      TableAgeGrpRefetch();
    } else if (RadioTypeCheck === "Sex") {
      GraphSexRefetch();
      GraphStandardAccuRefetch();
      TableSexRefetch();
    } else if (RadioTypeCheck === "Assign") {
      GraphAssignRefetch();
      GraphStandardAccuRefetch();
      TableAssignRefetch();
    } else if (RadioTypeCheck === "Area") {
      GraphAreaRefetch();
      GraphStandardAccuRefetch();
      // TableAssignRefetch();
    } else if (RadioTypeCheck === "Etc1") {
      GraphEtc1Refetch();
      GraphStandardAccuRefetch();
      // TableAssignRefetch();
    } else if (RadioTypeCheck === "Doc") {
      GraphDocRefetch();
      GraphStandardAccuRefetch();
      TableDocRefetch();
    } else if (RadioTypeCheck === "Octy") {
      GraphOctyRefetch();
      GraphStandardAccuRefetch();
      TableOctyRefetch();
    } else if (RadioTypeCheck === "WrkYyGrp") {
      GraphWrkYyGrpRefetch();
      GraphStandardAccuRefetch();
      TableWrkYyGrpRefetch();
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, startDate, endDate, GraphSet]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={""}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {analysisType === "csscan" ? (
            <CSTrendChartPeriod
              hsp_nm={HspNm}
              prj_cd={PrjCd}
              svy_ojt={SvyOjt}
              qst_cd={Pick.qst_cd}
              sel_cd={""}
              string_value={""}
              sort_type={SortType}
              data_num={RadioNumCheck}
              data_type={RadioTypeCheck}
              qst={Qst}
              endDate={endDate}
              deep_ana_cd={""}
            />
          ) : (
            <></>
          )}
          {GraphSet.map((data, i) => {
            if (i === 0) {
              if (CntYn === "Y") {
                return (
                  <div key={`normal_${i}`} className="float-left w-full">
                    <div className="float-left w-[calc(50%-1.0rem)] border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]">
                      <PieChart
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={"N"}
                        type={"pie"}
                        title={SvyOjtNm + " 전체 응답"}
                        qst={Qst}
                        Gdata={GraphStandardData}
                      />
                    </div>
                    <div className="float-left ml-[2.0rem] w-[calc(50%-1.0rem)] border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]">
                      <BarColumnChart
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={"Y"}
                        type={"column"}
                        title={SvyOjtNm + " 표준 비교"}
                        qst={Qst}
                        name={"standard_accu"}
                        Gdata={GraphStandardAccuData}
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={`normal_${i}`}
                    className="float-left w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]"
                  >
                    <div className="float-left w-full">
                      <PieChart
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={"N"}
                        type={"pie"}
                        title={SvyOjtNm + " 전체 응답"}
                        qst={Qst}
                        Gdata={GraphStandardData}
                      />
                    </div>
                  </div>
                );
              }
            } else {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "column";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "AgeGrpMainDept") {
                vTitle = "연령대별 방문진료과 응답률";
              } else if (data.value === "AgeGrpSex") {
                vTitle = "연령대별 성별 응답률";
              } else if (data.value === "Area") {
                vTitle = "주거지역별 응답률";
              } else if (data.value === "AgeGrpArea") {
                vTitle = "연령별 주거지역 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphBasicData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphAssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vStack = "Y";
                vData = GraphOctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vStack = "Y";
                vWhere = "WRK_YY_GRP";
                vData = GraphWrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vStack = "Y";
                vWhere = "SEX";
                vData = GraphSexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vStack = "Y";
                vWhere = "AGE_GRP";
                vData = GraphAgeGrpData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vStack = "Y";
                vWhere = "MAIN_DEPT_CD";
                vType = "bar";
                vData = GraphMainDeptData;
              } else if (data.value === "AgeGrpMainDept") {
                vName = "ageMainDept";
                vStack = "Y";
                vWhere = "AGE_GRP";
                vType = "bar";
                vData = GraphAgeMainDeptData;
              } else if (data.value === "AgeGrpSex") {
                //
                vName = "ageSex";
                vStack = "Y";
                vWhere = "AGE_GRP";
                vType = "bar";
                vData = GraphAgeSexData;
              } else if (data.value === "Area") {
                vName = "area";
                vStack = "Y";
                vWhere = "AREA_CD";
                vType = "bar";
                vData = GraphAreaData;
              } else if (data.value === "AgeGrpArea") {
                vName = "ageArea";
                vStack = "Y";
                vWhere = "AGE_GRP";
                vType = "bar";
                vData = GraphAgeAreaData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vStack = "Y";
                vWhere = "ETC_CD1";
                vType = "bar";
                vData = GraphEtc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vStack = "Y";
                vWhere = "ETC_CD2";
                vType = "bar";
                vData = GraphEtc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vStack = "Y";
                vWhere = "ETC_CD3";
                vType = "bar";
                vData = GraphEtc3Data;
              } // 에러부분 (방문진료과별 응답률)
              return (
                <div
                  key={`normal_${i}`}
                  className="float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]"
                >
                  {vTitle}
                  <BarColumnChart
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "column";
            var vData;
            var vTableData;
            var cartegories;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "AgeGrpMainDept") {
              vTitle = "연령대별 방문진료과 응답률";
            } else if (data.value === "AgeGrpSex") {
              vTitle = "연령대별 성별 응답률";
            } else if (data.value === "Area") {
              vTitle = "주거지역별 응답률";
            } else if (data.value === "AgeGrpArea") {
              vTitle = "연령별 주거지역 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphBasicData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphAssignData;
              vTableData = TableAssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDocData;
              vTableData = TableDocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vStack = "Y";
              vData = GraphOctyData;
              vTableData = TableOctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vStack = "Y";
              vWhere = "WRK_YY_GRP";
              vData = GraphWrkYyGrpData;
              vTableData = TableWrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vStack = "Y";
              vWhere = "SEX";
              vData = GraphSexData;
              vTableData = TableSexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vStack = "Y";
              vWhere = "AGE_GRP";
              vData = GraphAgeGrpData;
              vTableData = TableAgeGrpData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vStack = "Y";
              vWhere = "MAIN_DEPT_CD";
              vType = "bar";
              vData = GraphMainDeptData;
            } else if (data.value === "AgeGrpMainDept") {
              vName = "ageMainDept";
              vStack = "Y";
              vWhere = "AGE_GRP";
              vType = "bar";
              vData = GraphAgeMainDeptData;
            } else if (data.value === "AgeGrpSex") {
              vName = "ageSex";
              vStack = "Y";
              vWhere = "AGE_GRP";
              vType = "bar";
              vData = GraphAgeSexData;
            } else if (data.value === "Area") {
              vName = "area";
              vStack = "Y";
              vWhere = "AREA_CD";
              vType = "bar";
              vData = GraphAreaData;
            } else if (data.value === "AgeGrpArea") {
              vName = "ageArea";
              vStack = "Y";
              vWhere = "AGE_GRP";
              vType = "bar";
              vData = GraphAgeAreaData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vStack = "Y";
              vWhere = "ETC_CD1";
              vType = "bar";
              vData = GraphEtc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vStack = "Y";
              vWhere = "ETC_CD2";
              vData = GraphEtc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vStack = "Y";
              vWhere = "ETC_CD3";
              vData = GraphEtc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" && (
                      <NormalTable
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        Tdata={vTableData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChart
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default NormalPage;
