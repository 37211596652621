import { useMutation } from "@tanstack/react-query";
import postGpt from "../api/postGpt";
import { useFinalGptResState, useGptState } from "../store/mobile/store";

const usePostFinalGpt = () => {
  const setGptFinalResArr = useFinalGptResState((state) => state.setFinalData);
  //   const gptResArr = useFinalGptResState((state) => state.data);
  // let tempArr = gptResArr ? [...gptResArr] : gptResArr;

  const setGptState = useGptState((state) => state.setGptState);

  return useMutation(postGpt, {
    onSuccess: (res) => {
      // console.log(res);
      // tempArr.push(res.data.choices[0].message.content);
      setGptFinalResArr([res.data.choices[0].message.content]);
    },
    onError: (err) => {
      console.log(err);
      setGptState(400);
    },
  });
};

export default usePostFinalGpt;
