const GptDataComp = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center font-SDGothic text-[14px] font-bold text-white">
      <button
        type="button"
        className="h-fit w-fit rounded-lg bg-FilterActiveColor px-16 py-7 outline-none"
      >
        GPT 요청
      </button>
    </div>
  );
};

export default GptDataComp;
