import { useQuery } from "@tanstack/react-query";
import getEssayChartRes from "../api/getEssayChartRes";

const useGetEssayChartRes = (prj_cd, qst_cd, svy_ojt, filter_opt, stDate, edDate) => {
  return useQuery(["essay/chart"], () => getEssayChartRes(prj_cd, qst_cd, svy_ojt, filter_opt, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetEssayChartRes;
