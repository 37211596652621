import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminUserWork from "../api/postAdminUserWork";

const usePostAdminUserWork = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminUserWork, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserWork"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminUserWork;
