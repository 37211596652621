import { useQuery } from "@tanstack/react-query";
import getDa08Res from "../api/getDa08Res";

const useGetDa08Res = (prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate) => {
  return useQuery(["da08"], () => getDa08Res(prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa08Res;
