import { useNavigate } from "react-router";
import {
  useMenuListModalControlState,
  useMenuState,
  useQstCdState,
  useQstListState,
  useQstState,
  useSelectQstState,
  useSelListModalState,
} from "../../../store/mobile/store";
import List_icon from "../../../svg_icon/List_icon";
import Next_icon from "../../../svg_icon/Next_icon";
import Prev_icon from "../../../svg_icon/Prev_icon";

const TabBar = () => {
  const navigate = useNavigate();

  const qstList = useQstListState((state) => state.qstList);

  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  const menuState = useMenuState((state) => state.menuVal);

  const qstRn = useQstState((state) => state.qstState);
  const setQstRn = useQstState((state) => state.setQstState);

  const setQstCd = useQstCdState((state) => state.setQstCdState);

  const setQstModalState = useMenuListModalControlState((state) => state.setMenuListModalControl);

  const setSelectQstState = useSelectQstState((state) => state.setSelectQstState);

  const handleClickPrev = () => {
    let data = {};
    let realData = menuState === "DEP" ? qstList : qstList.slice(1, qstList.length);

    if (realData[qstRn - 2].grp_DVS === "GRP") {
      data = realData[qstRn - 3];
    } else {
      data = realData[qstRn - 2];
    }

    setQstCd(data.qst_CD);
    setQstRn(data.rn);
    setSelectQstState(data);

    if (data.menu_CATE !== "DEP") {
      if (data.menu_CATE2 === "일반문항") {
        if (data.deep_ANA_CD === "") {
          navigate("/m/common/default");
        } else {
          navigate(`/m/common/${data.deep_ANA_CD}`);
        }
      } else if (data.menu_CATE2 === "교차문항") {
        navigate("/m/common/Crss");
      } else if (data.menu_CATE2 === "주관식") {
        navigate("/m/common/Essay");
      }
    } else {
      navigate("/m/common/Dep");
    }
  };

  const handleClickNext = () => {
    let data = {};
    let realData = menuState === "DEP" ? qstList : qstList.slice(1, qstList.length);

    if (realData[qstRn].grp_DVS === "GRP") {
      data = realData[qstRn + 1];
    } else {
      data = realData[qstRn];
    }

    setQstCd(data.qst_CD);
    setQstRn(data.rn);
    setSelectQstState(data);

    if (data.menu_CATE !== "DEP") {
      if (data.menu_CATE2 === "일반문항") {
        if (data.deep_ANA_CD === "") {
          navigate("/m/common/default");
        } else {
          navigate(`/m/common/${data.deep_ANA_CD}`);
        }
      } else if (data.menu_CATE2 === "교차문항") {
        navigate("/m/common/Crss");
      } else if (data.menu_CATE2 === "주관식") {
        navigate("/m/common/Essay");
      }
    } else {
      navigate("/m/common/Dep");
    }
  };

  const handleClickList = () => {
    // navigate("/m/common/menuList");
    setQstModalState(true);
    setSelListModalState(false);
  };

  return (
    <div className="fixed bottom-0 flex h-fit w-full flex-row items-center justify-center bg-tabBarColor p-5">
      {/* prev btn */}
      {qstRn === 1 ? (
        <div className="flex h-fit w-1/3 flex-row items-center justify-start text-white">
          <Prev_icon active={false} />
          <div className="ml-5  flex h-10 w-fit flex-row items-center justify-center text-center font-SDGothic text-[16px]  text-white/30">
            이전문항
          </div>
        </div>
      ) : (
        <div onClick={handleClickPrev} className="flex h-fit w-1/3 flex-row items-center justify-start">
          <Prev_icon active={true} />
          <div className="ml-5  flex h-10 w-fit flex-row items-center justify-center text-center font-SDGothic text-[16px] text-white">이전문항</div>
        </div>
      )}
      {/* list btn */}
      <div onClick={handleClickList} className="flex h-fit w-1/3 flex-row items-center justify-center">
        <List_icon />
        <div className="ml-5  flex h-10 w-fit flex-row items-center justify-center text-center font-SDGothic text-[16px]  text-white">설문목록</div>
      </div>
      {/* next btn */}
      {qstRn === (menuState === "DEP" ? qstList.length : qstList.length - 1) ? (
        <div className="flex h-fit w-1/3 flex-row items-center justify-end">
          <div className="mr-5  flex h-10 w-fit flex-row items-center justify-center text-center font-SDGothic text-[16px] text-white/30">
            다음문항
          </div>
          <Next_icon active={false} />
        </div>
      ) : (
        <div onClick={handleClickNext} className="flex h-fit w-1/3 flex-row items-center justify-end">
          <div className="mr-5  flex h-10 w-fit flex-row items-center justify-center text-center font-SDGothic text-[16px]  text-white">다음문항</div>
          <Next_icon active={true} />
        </div>
      )}
    </div>
  );
};

export default TabBar;
