import useStore from "../../../../store/pc/store";

const AdminQstStandardRadio = ({ guideType, handleClickRadio }) => {
  const { AnalysisType } = useStore();

  // ANCHOR csscan시작값은 004로 되어있으므로 scan옵션추가시 csscan밑에 추가하면됨, 표준문항에 대한 세팅은 DB에서 넣어줘야함
  const radioOptions = [
    { value: "001", label: "비전전략", visibleWhen: "scan" },
    { value: "002", label: "병원건립", visibleWhen: "scan" },
    { value: "003", label: "재무건전성", visibleWhen: "scan" },
    { value: "004", label: "고객만족", visibleWhen: "csscan" },
    { value: "005", label: "환자경험", visibleWhen: "csscan" },
    { value: "006", label: "고객만족 + 환자경험", visibleWhen: "csscan" },
    { value: "007", label: "구성원 + 고객만족 + 환자경험", visibleWhen: "csscan" },
  ];

  const renderRadio = (option) => (
    <div
      key={option.value}
      className={`${option.visibleWhen === AnalysisType ? "visible" : "hidden"} float-left mx-2 flex h-[50px] flex-row items-center justify-start`}
    >
      <input
        type="radio"
        checked={guideType === option.value}
        name="admin_qst_radio"
        value={option.value}
        id={option.value}
        className="border accent-orangeColor"
        onClick={() => handleClickRadio(option.value)}
        onChange={() => {}}
      />
      <label htmlFor={option.value} className="ml-2 font-bold">
        {option.label}
      </label>
    </div>
  );

  return (
    <div className="sticky top-[84px] z-20 mt-4  flex items-center bg-white">
      <div className="float-left flex h-[50px] w-auto flex-row items-center justify-center text-[14px]">{radioOptions.map(renderRadio)}</div>
    </div>
  );
};

export default AdminQstStandardRadio;
