import { useQuery } from "@tanstack/react-query";
import getAdminTargetSelNm from "../api/getAdminTargetSelNm";

const useGetAdminTargetSelNm = (prj_cd) => {
  return useQuery(["adminTargetSelNm"], () => getAdminTargetSelNm(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminTargetSelNm;
