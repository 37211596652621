import { useEffect, useState } from "react";
import { HiCheck, HiOutlineX, HiPlus } from "react-icons/hi";
const FilterModal = (props) => {
  const [alert, setAlert] = useState(false);

  const handleClickCancel = () => {
    props.setModalState(false);
  };

  const handleClickConfirmBtn = () => {
    if (props.tempFilterOpt.length === 0) {
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 1500);
    } else {
      props.setModalState(false);
      props.setFilterOpt(props.tempFilterOpt);
    }
  };

  const handleClickInActiveBtn = (name) => {
    let list = [...props?.tempFilterOpt];

    if (name === "전체") {
      list = ["ALL"];
      props?.setTempFilterOpt(["ALL"]);
    } else if (list.length === props?.selData.length - 1) {
      list = ["ALL"];
      props?.setTempFilterOpt(["ALL"]);
    } else {
      if (list.includes("ALL")) {
        list = [];
      }
      list.push(name);
      props?.setTempFilterOpt(list);
    }
  };

  const handleClickActiveBtn = (name) => {
    let list = [...props?.tempFilterOpt];

    if (name === "전체") {
      list = [];
      props?.setTempFilterOpt(list);
    } else {
      let idx = list.indexOf(name);

      list.splice(idx, 1);

      props?.setTempFilterOpt(list);
    }
  };

  useEffect(() => {
    props?.setTempFilterOpt(props?.filterOpt);
  }, [props?.modalState]);

  return (
    <div
      className={
        props.modalState
          ? `fixed -right-[0px] top-0 z-50 flex h-full w-[270px] flex-col items-center justify-start rounded-l-xl border border-tableBorderColor/50 bg-white/95 p-3 pl-7 drop-shadow-lg duration-500 ease-in-out`
          : `fixed -right-[320px] top-0 z-50 flex h-full w-[270px] flex-col items-center justify-start rounded-l-xl border border-tableBorderColor/50 bg-white/95 p-3 pl-7 drop-shadow-lg duration-500 ease-in-out`
      }
    >
      <div
        className={
          alert
            ? `absolute top-16 flex h-fit w-[80%] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/80 p-3 font-SDGothic text-[12px] font-bold text-white opacity-100  duration-300`
            : `absolute top-16 flex h-fit w-[80%] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/80 p-3 font-SDGothic text-[12px] font-bold text-transparent opacity-0  duration-500`
        }
      >
        최소 1개 이상 옵션이 필요합니다
      </div>

      <HiOutlineX onClick={handleClickCancel} className="absolute right-4 top-4 text-4xl text-textColor" />
      {/* title */}
      <div className="mt-28 flex h-fit w-full flex-col items-start justify-start">
        <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[20px] font-extrabold text-selectTextColor">
          검색옵션을 설정하세요
        </div>
        <div className="mt-8 flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] font-light text-selectTextColor">
          교차문항 답변별 조회가 가능합니다
        </div>
      </div>

      {/* btn area */}
      <div className="mt-16 flex h-fit w-full flex-col items-start justify-start">
        {props?.tempFilterOpt[0] === "ALL" || props?.tempFilterOpt?.length === props?.selData?.length ? (
          // Active Btn
          <div
            onClick={() => handleClickActiveBtn("전체")}
            className="mb-6 mr-5 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] border-[1.5px] border-FilterActiveColor/80 bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/90"
          >
            <HiCheck className="mr-2 text-[22px] text-orangeColor/80" />
            <div className="flex  h-fit w-fit flex-col items-center justify-center">{"전체"}</div>
          </div>
        ) : (
          // InActive Btn
          <div
            onClick={() => handleClickInActiveBtn("전체")}
            className="mb-6 mr-5 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] border-[1.5px] border-FilterInActiveColor bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/60"
          >
            <HiPlus className="mr-2 text-[18px] text-selectTextColor/60" />
            <div className="flex h-fit w-fit flex-col items-center justify-center">{"전체"}</div>
          </div>
        )}

        {props?.selData?.map((el, idx) => {
          if (props?.tempFilterOpt.includes(el.sel_CD)) {
            // Active Btn
            return (
              <div
                onClick={() => handleClickActiveBtn(el.sel_CD)}
                className="mb-6 mr-5 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] border-[1.5px] border-FilterActiveColor/80 bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/90"
              >
                <HiCheck className="mr-2 text-[22px] text-orangeColor/80" />
                <div className="flex  h-fit w-fit flex-col items-center justify-center">{el.sel_NM_M}</div>
              </div>
            );
          } else {
            // InActive Btn
            return (
              <div
                onClick={() => handleClickInActiveBtn(el.sel_CD)}
                className="mb-6 mr-5 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] border-[1.5px] border-FilterInActiveColor bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/60"
              >
                <HiPlus className="mr-2 text-[18px] text-selectTextColor/60" />
                <div className="flex h-fit w-fit flex-col items-center justify-center">{el.sel_NM_M}</div>
              </div>
            );
          }
        })}
      </div>

      {/* confime btn */}
      <div
        onClick={handleClickConfirmBtn}
        className="mr-5 mt-20 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] bg-FilterActiveColor/90 px-16 py-6 font-SDGothic text-[15px] font-semibold text-white"
      >
        적용
      </div>
    </div>
  );
};

export default FilterModal;
