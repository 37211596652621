import { useEffect, useState } from "react";
import useStore from "../../store/pc/store";

export const Radio = (props) => {
  const { data, changed, id, value } = props;
  const [Radio, setRadio] = useState([{}]);
  const Pick = useStore((state) => state.Pick);

  useEffect(() => {
    if (data !== null && data !== undefined) {
      if (id === "RadioType") {
        var vRadio = [{}];
        vRadio.push({ value: "All", label: "전체" });
        data.map((dd, i) => {
          if (dd.octy_CNT !== "0") {
            vRadio.push({ value: "Octy", label: "직종" });
            vRadio.push({ value: "Doc", label: "의사" });
          }
          if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
            vRadio.push({ value: "Assign", label: "보직" });
          }
          if (dd.age_GRP_CNT !== "0") {
            vRadio.push({ value: "AgeGrp", label: "연령" });
          }
          if (dd.wrk_YY_GRP_CNT !== "0") {
            vRadio.push({ value: "WrkYyGrp", label: "연차" });
          }
          if (dd.sex_CNT !== "0") {
            vRadio.push({ value: "Sex", label: "성별" });
          }
          if (dd.main_DEPT_CD_CNT !== "0") {
            vRadio.push({ value: "MainDept", label: "방문진료과" });
          }
          if (dd.area_CD_CNT !== "0") {
            vRadio.push({ value: "Area", label: "주거지역" });
          }
          if (dd.etc_CD1_CNT !== "0") {
            if (
              (props.etc1Check?.length === 2 && props.etc1Check[0] === "전체" && props.etc1Check[1] === "해당없음") ||
              props.etc1Check?.length === 0
            ) {
            } else {
              vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
            }
          }
          if (dd.etc_CD2_CNT !== "0") {
            vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
          }
          if (dd.etc_CD3_CNT !== "0") {
            vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
          }
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioTypeDA03") {
        var vRadio = [{}];
        vRadio.push({ value: "All", label: "전체" });
        data.map((dd, i) => {
          if (dd.octy_CNT !== "0") {
            vRadio.push({ value: "Octy", label: "직종" });
          }
          if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
            vRadio.push({ value: "Assign", label: "보직" });
          }
          if (dd.age_GRP_CNT !== "0") {
            vRadio.push({ value: "AgeGrp", label: "연령" });
          }
          if (dd.wrk_YY_GRP_CNT !== "0") {
            vRadio.push({ value: "WrkYyGrp", label: "연차" });
          }
          if (dd.sex_CNT !== "0") {
            vRadio.push({ value: "Sex", label: "성별" });
          }
          if (dd.main_DEPT_CD_CNT !== "0") {
            vRadio.push({ value: "MainDept", label: "방문진료과" });
          }
          if (dd.area_CD_CNT !== "0") {
            vRadio.push({ value: "Area", label: "주거지역" });
          }
          if (dd.etc_CD1_CNT !== "0") {
            vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
          }
          if (dd.etc_CD2_CNT !== "0") {
            vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
          }
          if (dd.etc_CD3_CNT !== "0") {
            vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
          }
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioNum") {
        var vRadio = [{}];
        data.map((dd, i) => {
          vRadio.push({ value: dd.value, label: dd.label });
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDA06") {
        var vRadio = [{}];
        vRadio.push({ value: "All", label: "전체" });
        data.map((dd, i) => {
          vRadio.push({ value: dd.value, label: dd.label });
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDA12uprGrp") {
        var vRadio = [{}];
        vRadio.push({ value: "All", label: "전체" });
        data.map((dd, i) => {
          vRadio.push({ value: dd.value, label: dd.label });
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDA08") {
        var vRadio = [{}];
        data.map((dd, i) => {
          vRadio.push({ value: dd.value, label: dd.label });
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDA07") {
        var vRadio = [{}];
        vRadio.push({ value: "1", label: "1순위" });
        vRadio.push({ value: "2", label: "2순위" });
        vRadio.push({ value: "+", label: "1+2순위" });
        vRadio.push({ value: "*", label: "1*2+2*1순위" });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDA11") {
        var vRadio = [{}];
        vRadio.push({ value: "1", label: "1순위" });
        vRadio.push({ value: "2", label: "2순위" });
        vRadio.push({ value: "3", label: "3순위" });
        vRadio.push({ value: "+", label: "1+2+3순위" });
        vRadio.push({ value: "*", label: "1*3+2*2+3*1순위" });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDA09") {
        var vRadio = [{}];
        vRadio.push({ value: "All", label: "전체" });
        data.map((dd, i) => {
          vRadio.push({ value: dd.value, label: dd.label });
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      } else if (id === "RadioDEP") {
        var vRadio = [{}];
        data.map((dd, i) => {
          vRadio.push({ value: dd.value, label: dd.label });
        });
        vRadio = vRadio.filter((item1, idx1) => {
          return (
            vRadio.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setRadio(vRadio);
      }
    }
  }, [data, props.etc1Check]);
  // console.log("Radio값?", Radio);
  return (
    <div key={`radio_${id}`} className="min-h-[1.4rem]">
      {Radio.map((data, i) => {
        return (
          <div key={`radio_${id}_${i}`} className="float-left h-full  pr-[0.8rem] leading-[1.4rem]">
            <div className="float-left">
              <input
                id={id + "_" + data.value}
                name={`radio_${id}`}
                onChange={changed}
                value={data.value}
                type="radio"
                className="h-[1.4rem] w-[1.4rem] pt-[0.2rem] accent-orange2Color"
                checked={value === data.value ? true : false}
              />
            </div>
            <div className="float-left leading-[1.4rem]">
              <label htmlFor={id + "_" + data.value} className="ml-[0.4rem] mt-[-0.4rem] h-[full] text-[1.4rem]">
                {data.label}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};
