import { useQuery } from "@tanstack/react-query";
import getAdminQstSelRes from "../api/getAdminQstSelRes";
import { useAdminQstSelState } from "../store/mobile/store";

const useGetAdminQstSelRes = (prj_cd) => {
  const setAdminQstSelState = useAdminQstSelState((state) => state.setAdminQstSelState);

  return useQuery(["adminQstSelRes", prj_cd], () => getAdminQstSelRes(prj_cd), {
    onSuccess: (res) => {
      setAdminQstSelState(res.data);
    },
    onError: (err) => {
      console.log(err);
    },
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminQstSelRes;
