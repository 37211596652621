import { useEffect, useState } from "react";
import Select from "react-select";
import useGetDetailPrj from "../../../hooks/useGetDetailPrj";
import usePostAdminDetailProj from "../../../hooks/usePostAdminDetailProj";
import useUpdateAdminProj from "../../../hooks/useUpdateAdminProj";
import useStore from "../../../store/pc/store";
const hosGrd = [
  {
    label: "종합병원",
    value: "S001",
  },
];

const hosList = [
  {
    label: "세명기독병원",
    value: "110",
  },
];

const YnList = [
  {
    label: "예",
    value: "Y",
  },
  {
    label: "아니오",
    value: "N",
  },
];

const AdminProjModalComp = ({ prj_cd, mode, setMode }) => {
  const { data } = useGetDetailPrj(prj_cd);
  const { AnalysisType } = useStore();

  const [prjCd, setPrjCd] = useState("");
  const [prjNm, setPrjNm] = useState("");
  const [dvs, setDvs] = useState("");
  const [hspGrd, setHspGrd] = useState("");
  const [hsp, setHsp] = useState("");
  const [progYn, setProgYn] = useState("");
  const [prjYy, setPrjYy] = useState("");
  const [strtDt, setStrtDt] = useState("");
  const [endDt, setEndDt] = useState("");

  const [status, setStatus] = useState(false);

  const { mutate, isSuccess } = useUpdateAdminProj();
  const { mutate: createMutate, isSuccess: createSuccess } = usePostAdminDetailProj();
  const handleChangePrjCd = (e) => {
    setPrjCd(e.target.value);
  };

  console.log("dadta", data, "분석타입", AnalysisType);

  const handleChangePrjNm = (e) => {
    setPrjNm(e.target.value);
  };
  const handleChangeDvs = (e) => {
    setDvs(e.value);
  };
  const handleChangeHspGrd = (e) => {
    setHspGrd(e.value);
  };
  const handleChangeHsp = (e) => {
    setHsp(e.value);
  };
  const handleChangeProgYn = (e) => {
    setProgYn(e.value);
  };
  const handleChangePrjYy = (e) => {
    setPrjYy(e.target.value);
  };
  const handleChangeStrtDt = (e) => {
    setStrtDt(e.target.value);
  };
  const handleChageEndDt = (e) => {
    setEndDt(e.target.value);
  };

  const handleClickSubmit = () => {
    let body = {
      prj_CD: prjCd,
      prj_NM: prjNm,
      dvs: dvs,
      hsp_GRD: hspGrd,
      hsp_CD: hsp,
      prog_YN: progYn,
      prd_YY: prjYy,
      strt_DT: strtDt,
      end_DT: endDt,
    };

    if (prjCd && prjNm && dvs && hspGrd && hsp && progYn && prjYy && strtDt && endDt) {
      if (mode) {
        createMutate(body);
      } else {
        mutate(body);
      }
    }
  };

  useEffect(() => {
    if (mode === false && data?.data.prj_INFO.length !== 0) {
      setPrjCd(data?.data.prj_INFO[0].prj_CD);
      setPrjNm(data?.data.prj_INFO[0].prj_NM);
      setDvs(data?.data.prj_INFO[0].dvs);
      setHspGrd(data?.data.prj_INFO[0].hsp_GRD);
      setHsp(data?.data.prj_INFO[0].hsp_CD);
      setProgYn(data?.data.prj_INFO[0].prog_YN);
      setPrjYy(data?.data.prj_INFO[0].prd_YY);
      setStrtDt(data?.data.prj_INFO[0].strt_DT);
      setEndDt(data?.data.prj_INFO[0].end_DT);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess || createSuccess) {
      setStatus(true);

      setTimeout(() => {
        setStatus(false);
      }, 1000);
    }
  }, [isSuccess, createSuccess]);

  useEffect(() => {
    if (mode) {
      setPrjCd("");
      setPrjNm("");
      setDvs("");
      setHspGrd("");
      setHsp("");
      setProgYn("");
      setPrjYy("");
      setStrtDt("");
      setEndDt("");
    } else {
    }
  }, [mode]);

  useEffect(() => {
    return () => {
      setMode(false);
    };
  }, []);

  return (
    <>
      <div className="relative flex h-full w-full flex-col items-center justify-start">
        {status ? (
          <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-100 duration-300 ease-out">
            저장되었습니다
          </div>
        ) : (
          <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-0 duration-300 ease-out">
            저장되었습니다
          </div>
        )}

        <div className="mt-10 flex h-fit w-full flex-row items-center justify-start py-5 font-SDGothic text-[24px] font-extrabold text-selectTextColor/80">
          프로젝트 상세정보
        </div>
        <div className="mt-10 flex h-fit w-full flex-col items-center justify-start rounded-xl bg-FilterInActiveColor/20 px-10 py-20 font-SDGothic font-bold">
          {/* 1 row */}
          <div className=" flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className=" flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">코드</div>
              <input
                type="text"
                spellCheck={false}
                value={prjCd}
                onChange={handleChangePrjCd}
                className="h-fit w-[110px] text-right  text-selectTextColor outline-none"
              />
            </div>

            {/* input items */}
            <div className=" ml-10 flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">프로젝트 명</div>
              <input
                type="text"
                spellCheck={false}
                value={prjNm}
                onChange={handleChangePrjNm}
                className="h-fit w-[150px] text-right  text-selectTextColor outline-none"
              />
            </div>
            {/* input items */}
            <div className=" ml-10 flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[5.5px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">구분</div>
              <Select
                options={
                  AnalysisType === "scan"
                    ? data?.data.prj_DVS.filter((item) => item.value !== "P")
                    : data?.data.prj_DVS.filter((item) => item.value === "P")
                }
                isSearchable={false}
                onChange={handleChangeDvs}
                value={data?.data.prj_DVS.filter((el) => el.value === dvs)}
                className="w-[140px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>

          {/* 1 row */}
          <div className="mt-10 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className=" flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">병원등급</div>
              <Select
                options={data?.data.hsp_GRD}
                isSearchable={false}
                onChange={handleChangeHspGrd}
                value={data?.data.hsp_GRD.filter((el) => el.value === hspGrd)}
                className="w-[120px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>

            {/* input items */}
            <div className=" ml-10 flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">병원</div>
              <Select
                options={data?.data.hsp_LIST}
                isSearchable={false}
                onChange={handleChangeHsp}
                value={data?.data.hsp_LIST.filter((el) => el.value === hsp)}
                className="w-[180px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
            {/* input items */}
            <div className=" ml-10 flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[5.5px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">진행여부</div>
              <Select
                options={YnList}
                isSearchable={false}
                onChange={handleChangeProgYn}
                value={YnList.filter((el) => el.value === progYn)}
                className="w-[120px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>

          {/* 1 row */}
          <div className="mt-10 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className=" flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">설문연도</div>
              <input
                type="text"
                spellCheck={false}
                onChange={handleChangePrjYy}
                value={prjYy}
                className="h-fit w-[110px] text-right  text-selectTextColor outline-none"
              />
            </div>

            {/* input items */}
            <div className=" ml-10 flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">시작일자</div>
              <input
                type="text"
                spellCheck={false}
                onChange={handleChangeStrtDt}
                value={strtDt}
                className="h-fit w-[150px] text-right  text-selectTextColor outline-none"
              />
            </div>
            {/* input items */}
            <div className=" ml-10 flex h-fit w-1/3 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6  text-[15px] font-bold text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">종료일자</div>
              <input
                type="text"
                spellCheck={false}
                onChange={handleChageEndDt}
                value={endDt}
                className="h-fit w-[150px] text-right  text-selectTextColor outline-none"
              />
            </div>
          </div>
          {/* save btn */}
        </div>
        <button
          type="button"
          onClick={handleClickSubmit}
          className="mb-16 mt-20 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
        >
          저장
        </button>
      </div>
    </>
  );
};

export default AdminProjModalComp;
