import axios from "axios";

const getEssayListRes = (prj_cd, qst_cd, svy_ojt, filter_opt, search_keyword, amount, page, stDate, edDate) => {
  return axios.get(
    `/resultM/essay/list?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}&filter_opt=${filter_opt}&search_keyword=${search_keyword}&amount=${amount}&page=${page}&stDate=${stDate}&edDate=${edDate}`,
    {
      baseURL: process.env.REACT_APP_SERVER_ADDRESS,
      withCredentials: true,
    }
  );
};

export default getEssayListRes;
