import axios from "axios";

const getAdminDetailQst = (prj_cd, qst_cd, svy_ojt) => {
  return axios.get(`/admin/selectList?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminDetailQst;
