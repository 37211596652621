import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TrendUsingExp } from "../api/CSTrendUsingExp";

export const useTrendUsingExp = (prj_cd, qst_cd, svy_ojt, startDate, endDate, p_type) => {
  const queryClient = useQueryClient();

  const {
    isLoading: isTrendUsingExpLoading,
    isError: isTrendUsingExpError,
    data: TrendUsingExpData,
    isSuccess: isTrendUsingExpSuccess,
    refetch: TrendUsingExpRefetch,
    isFetching: isTrendUsingExpFetching,
  } = useQuery(
    ["trend_using_exp", prj_cd, qst_cd, svy_ojt, startDate, endDate, p_type],
    () => TrendUsingExp(prj_cd, qst_cd, svy_ojt, startDate, endDate, p_type),
    {
      enabled: false, // 마운트 시 자동으로 쿼리 실행
      skip: true,
      onSuccess: (data) => {
        //console.log(data);
        queryClient.invalidateQueries({
          queryKey: ["trend_using_exp"],
          refetchActive: true,
        });
      },
      onError: (e) => {
        // 실패시 호출 (401, 404 같은 error가 아니라 정말 api 호출이 실패한 경우만 호출됩니다.)
        // 강제로 에러 발생시키려면 api단에서 throw Error 날립니다.
        // (참조: https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default)
        //console.log(e.message);
      },
      onSettled: (data, err) => {
        //console.log(data, err);
      },
      refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    isTrendUsingExpLoading,
    isTrendUsingExpError,
    TrendUsingExpData,
    isTrendUsingExpSuccess,
    TrendUsingExpRefetch,
    isTrendUsingExpFetching,
  };
};
