import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PcEssayChart = (props) => {
  const data = props?.data;

  const options = {
    chart: {
      width: 1000,
      height: 350,
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat: "<h5>{chartTitle}</h5>" + "<div>{chartSubtitle}</div>" + "<div>{chartLongdesc}</div>" + "<div>{viewTableButton}</div>",
      },
    },
    series: [
      {
        type: "wordcloud",
        data,
        name: "Occurrences",
        style: {
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
        },
      },
    ],
    title: {
      text: null,
      align: "left",
    },
    subtitle: {
      text: null,
      align: "left",
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        return `<b style="font-size : 9px;">${point.name} </br> </b> <span style="color: ${color};">&#9679;</span> <span style="font-size : 9px;">${series.name} : <b>${point.weight}</b></span>`;
      },
      //   headerFormat:
      //     '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
      style: {
        fontFamily: "AppleSDGothicNeoRegular",
        fontWeight: "bold",
        color: "#383838",
      },
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    exporting: {
      enabled: false,
    },
  };

  return <>{options ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}</>;
};

export default PcEssayChart;
