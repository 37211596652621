import axios from "axios";

const getDepQstList = (prj_cd) => {
  return axios.get(`QstList/dep_qst?prj_cd=${prj_cd}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getDepQstList;
