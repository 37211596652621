import Lottie from "react-lottie-player";
import notfoundAnimation from "../../../../svg_icon/notFound.json";

const AdminQstNotFoundAnimation = () => {
  return (
    <>
      <div className="mt-[20px] w-full p-4 text-center text-[24px] font-semibold text-blue1Color">
        해당 프로젝트에 관한 표준문항이 설정되지 않았습니다.
      </div>
      <Lottie loop animationData={notfoundAnimation} play style={{ width: "100%", height: 300, margin: "0 auto", padding: "10px" }} />
      <div className="w-full p-4 text-center text-[24px] font-semibold text-blue1Color">
        표준 문항 설정 또는 Excel 업로드 이후 문항을 관리하시기 바랍니다.
      </div>
    </>
  );
};

export default AdminQstNotFoundAnimation;
