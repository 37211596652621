const AdminQstRadio = ({ qstManageType, handleClickRadio }) => {
  return (
    <div className="mt-4 flex items-center">
      <div className="float-left flex h-[50px] w-auto flex-row items-center justify-center text-[14px]">
        <div className={` float-left mx-2 flex h-[50px]  flex-row items-center justify-start`}>
          <input
            type="radio"
            checked={qstManageType === "001" ? true : false}
            name="admin_qst_radio"
            id="001"
            value="001"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("001")}
            onChange={() => {}}
          />
          <label htmlFor="001" className="ml-2 font-bold">
            문항 첨삭
          </label>
        </div>
        <div className={` float-left mx-2 flex h-[50px]  flex-row items-center justify-start`}>
          <input
            type="radio"
            checked={qstManageType === "002" ? true : false}
            name="admin_qst_radio"
            id="002"
            value="002"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("002")}
            onChange={() => {}}
          />
          <label htmlFor="002" className="ml-2 font-bold">
            설문대상 관리
          </label>
        </div>
        <div className={` float-left mx-2 flex h-[50px]  flex-row items-center justify-start`}>
          <input
            type="radio"
            checked={qstManageType === "003" ? true : false}
            name="admin_qst_radio"
            id="003"
            value="003"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("003")}
            onChange={() => {}}
          />
          <label htmlFor="003" className="ml-2 font-bold">
            설문대상 부서관리
          </label>
        </div>
      </div>
    </div>
  );
};

export default AdminQstRadio;
