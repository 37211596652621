import { useMutation } from "@tanstack/react-query";
import postExtractKeyword from "../api/postExtractKeyword";
import {
  useAiExtractionDataState,
  useEssayAgeBtnState,
  useEssayBtnState,
  useMenuState,
  usePcEssayOptState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useUserIdState,
} from "../store/mobile/store";
import useStore from "../store/pc/store";

import usePostAIChartRes from "./usePostAIChartRes";

const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94"];

const usePostExtractKeyword = () => {
  const setAiExtractionData = useAiExtractionDataState((state) => state.setAiExtractionData);
  // mobile
  const prjVal = usePrjValState((state) => state.prjVal);
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const finalActiveBtnList = useEssayBtnState((state) => state.essayBtn);
  const finalActiveAgeBtnList = useEssayAgeBtnState((state) => state.essayAgeBtn);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  // pc
  const mobileUserId = useUserIdState((state) => state.userId);
  const opt = usePcEssayOptState((state) => state.opt);
  const Pick = useStore((state) => state.Pick);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);

  const { mutate } = usePostAIChartRes();

  return useMutation(postExtractKeyword, {
    onSuccess: (res) => {
      //   console.log(res.data.ibm.items);

      let data = res.data.ibm.items.map((el, idx) => {
        return {
          prj_CD: mobileUserId ? prjVal.value : PrjCd,
          svy_OJT: mobileUserId ? selectQstState.svy_OJT : SvyOjt,
          qst_CD: mobileUserId ? selectQstState.qst_CD : Pick.qst_cd,
          // csscan
          prd: startDate ? `${startDate}-${endDate?.substring(0, 10)}` : "-", // scan일 경우 "-"
          seq: idx,
          dvs:
            mobileUserId === ""
              ? opt
              : (finalActiveBtnList.length === 7 && menuState === "MEM") ||
                (finalActiveAgeBtnList.length === 8 && (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS"))
              ? ["-"]
              : menuState === "MEM"
              ? finalActiveBtnList
              : finalActiveAgeBtnList,
          name: el.keyword,
          weight: Math.ceil((el.importance * 10) ** 5),
          color: colors[idx % 8],
        };
      });

      let chartData = res.data.ibm.items.map((el, idx) => {
        return {
          name: el.keyword,
          weight: Math.ceil((el.importance * 10) ** 5),
          color: colors[idx % 8],
        };
      });

      mutate(data);
      setAiExtractionData(chartData);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostExtractKeyword;
