import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import getAdminAuth from "../api/getAdminAuth";
import useStore from "../store/pc/store";

const useGetAdminAuth = (user_id) => {
  const setMode = useStore((state) => state.setMode);
  const navigate = useNavigate();

  return useQuery(["adminAuth"], () => getAdminAuth(user_id), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
      if (res.data.auth) {
        setMode("admin");
        navigate("/Admin/project");
      } else {
        alert("접근권한이 없습니다");
      }
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminAuth;
