import { useEffect, useState } from "react";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useQstList } from "../../hooks/useQstList";
import useStore from "../../store/pc/store";

const ScanMenu = () => {
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const Pick = useStore((state) => state.Pick);
  const setPick = useStore((state) => state.setPick);
  const [PrjCdTemp, setPrjCdTemp] = useState(PrjCd);
  const [SvyOjtTemp, setSvyOjtTemp] = useState(SvyOjt);
  const [PickTemp, setPickTemp] = useState(Pick);

  const navigate = useNavigate();

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCdTemp, SvyOjtTemp);

  useEffect(() => {
    setPrjCdTemp(PrjCd);
    setSvyOjtTemp(SvyOjt);
  }, [PrjCd, SvyOjt]);

  useEffect(() => {
    if (PrjCdTemp !== null && PrjCdTemp !== undefined && PrjCdTemp !== "" && SvyOjtTemp !== null && SvyOjtTemp !== undefined && SvyOjtTemp !== "") {
      QstListRefetch();
    }
  }, [PrjCdTemp, SvyOjtTemp]);

  const moveMenu = (menu_cate, menu_cate2, keyword, qst_cd, deep_ana_cd, deep_qst_cd, crss_deep_ana_cd, crss_qst_cd, sort_type, grp_dvs, cnt_yn) => {
    if (grp_dvs !== "GRP") {
      setPick({
        menu_cate: menu_cate,
        menu_cate2: menu_cate2,
        qst_cd: qst_cd,
        deep_ana_cd: deep_ana_cd,
        deep_qst_cd: deep_qst_cd,
        crss_deep_ana_cd: crss_deep_ana_cd,
        crss_qst_cd: crss_qst_cd,
        sort_type: sort_type,
        cnt_yn: cnt_yn,
        grp_dvs: grp_dvs,
      });

      if (menu_cate === "요약") {
        navigate("/Summary");
      } else if (menu_cate === "원천데이터") {
        navigate("/Raw");
      } else if (menu_cate === "주관식") {
        navigate("/Short");
      } else if (menu_cate === "대상간분석") {
        navigate("/Dep");
      } else {
        if (menu_cate === "교차문항" && crss_qst_cd !== "" && crss_qst_cd !== undefined) {
          navigate("/Crss");
        } else if (menu_cate === "심화문항" && deep_ana_cd !== "" && deep_ana_cd !== undefined) {
          navigate("/" + deep_ana_cd);
        } else {
          navigate("/Normal");
        }
      }
    }
  };

  return (
    <div className="w-[calc(100%-0.4rem] float-left h-[calc(100%-0.4rem)] bg-loginBlueColor">
      {QstListData &&
        QstListData !== null &&
        QstListData.length > 0 &&
        QstListData.map((data, i) => {
          var menuActive = false;
          if (
            Pick.menu_cate !== "" &&
            Pick.menu_cate === data.menu_CATE &&
            Pick.menu_cate2 === data.menu_CATE2 &&
            Pick.qst_cd === data.qst_CD &&
            Pick.deep_ana_cd === data.deep_ANA_CD &&
            Pick.deep_qst_cd === data.deep_QST_CD &&
            Pick.crss_qst_cd === data.crss_QST_CD
          ) {
            menuActive = true;
          } else {
            menuActive = false;
          }

          if ((i !== 0 && i !== QstListData.length - 1 && i > 0 && QstListData[i - 1].menu_CATE2 !== data.menu_CATE2) || i === 0) {
            return (
              <div key={`menu_${data.menu_CATE}_${data.menu_CATE2}_${data.qst_CD}`} className="float-left min-h-[5.2rem] w-full">
                <div className="float-left min-h-[2.6rem] w-full bg-blue2Color">
                  <div className="float-left ml-[1.0rem] mt-[1.1rem] h-[0.4rem] w-[0.4rem] bg-menu_rectangle bg-[length:100%_100%] bg-center bg-no-repeat"></div>
                  <div
                    className={`float-left h-full w-[calc(100%-1.4rem)] ${
                      data.grp_DVS === "SUB" ? "pl-[1.6rem]" : "pl-[0.6rem]"
                    } text-[1.4rem] leading-[2.6rem] text-white`}
                  >
                    {data.menu_CATE2}
                  </div>
                </div>
                <div
                  className={`float-left min-h-[2.6rem] w-full cursor-pointer ${menuActive ? "bg-orange2Color" : "bg-loginBlueColor"}`}
                  onClick={() => {
                    if (data.qst_TYP === "Q06") {
                    } else {
                      moveMenu(
                        data.menu_CATE,
                        data.menu_CATE2,
                        data.keyword,
                        data.qst_CD,
                        data.deep_ANA_CD,
                        data.deep_QST_CD,
                        data.crss_DEEP_ANA_CD,
                        data.crss_QST_CD,
                        data.sort_TYPE,
                        data.grp_DVS
                      );
                    }
                  }}
                >
                  <div className="float-left ml-[1.0rem] mt-[1.1rem] h-[0.4rem] w-[0.4rem] bg-menu_triangle bg-[length:100%_100%] bg-center bg-no-repeat"></div>
                  {data?.qst_TYP === "Q02" || data?.qst_TYP === "Q14" ? (
                    <div className="float-left flex h-full w-[calc(100%-1.4rem)] flex-row items-center justify-start pl-[0.6rem] text-[1.4rem] leading-[2.6rem] text-white">
                      {data.keyword}
                      <BsFillChatSquareTextFill className="ml-3 text-FilterActiveColor" />
                    </div>
                  ) : (
                    <div className="float-left h-full w-[calc(100%-1.4rem)] pl-[0.6rem] text-[1.4rem] leading-[2.6rem] text-white">
                      {data.keyword}
                    </div>
                  )}
                </div>
                <div className="float-left h-[0.2rem] w-full bg-blue1Color"></div>
              </div>
            );
          } else {
            return (
              <div key={`menu_${data.menu_CATE}_${data.menu_CATE2}_${data.qst_CD}`} className="float-left min-h-[2.8rem] w-full">
                <div
                  className={`float-left min-h-[2.6rem] w-full cursor-pointer ${menuActive ? "bg-orange2Color" : "bg-loginBlueColor"} ${
                    data.grp_DVS === "SUB" ? "pl-[1.6rem]" : ""
                  }`}
                  onClick={() => {
                    if (data.qst_TYP === "Q06") {
                    } else {
                      moveMenu(
                        data.menu_CATE,
                        data.menu_CATE2,
                        data.keyword,
                        data.qst_CD,
                        data.deep_ANA_CD,
                        data.deep_QST_CD,
                        data.crss_DEEP_ANA_CD,
                        data.crss_QST_CD,
                        data.sort_TYPE,
                        data.grp_DVS
                      );
                    }
                  }}
                >
                  <div className="float-left ml-[1.0rem] mt-[1.1rem] h-[0.4rem] w-[0.4rem] bg-menu_triangle bg-[length:100%_100%] bg-center bg-no-repeat"></div>
                  {data?.qst_TYP === "Q02" || data?.qst_TYP === "Q14" ? (
                    <div className="float-left flex h-full w-[calc(100%-1.4rem)] flex-row items-center justify-start pl-[0.6rem] text-[1.4rem] leading-[2.6rem] text-white">
                      {data.keyword}
                      <BsFillChatSquareTextFill className="ml-3 text-FilterActiveColor" />
                    </div>
                  ) : (
                    <div className="float-left h-full w-[calc(100%-1.4rem)] pl-[0.6rem] text-[1.4rem] leading-[2.6rem] text-white">
                      {data.keyword}
                    </div>
                  )}
                </div>
                <div className="float-left h-[0.2rem] w-full bg-blue1Color"></div>
              </div>
            );
          }
        })}
    </div>
  );
};

export default ScanMenu;
