import { useEffect, useState } from "react";
import { CgDanger } from "react-icons/cg";
import { HiOutlineX } from "react-icons/hi";
import { PulseLoader } from "react-spinners";
import usePostFinalGpt from "../../../hooks/usePostFinalGpt";
import usePostGpt from "../../../hooks/usePostGpt";
import usePostGptRes from "../../../hooks/usePostGptRes";
import {
  useFinalGptResState,
  useGptResState,
  useGptState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
} from "../../../store/mobile/store";

const GptFetchModal = (props) => {
  const { mutate: initGptMutate, isLoading: initGptLoading, isSuccess: initGptSuccess, status, isError: initGptError } = usePostGpt();
  const menuState = useMenuState((state) => state.menuVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const { mutate: finalGptMutate, isLoading: finalGptLoading, isSuccess: finalGptSuccess, isError: finalGptError } = usePostFinalGpt();

  const { mutate: GptResMutate, isLoading: GptResLoading, isSuccess: GptResIsSuccess, isError: GptResError } = usePostGptRes();

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const prjVal = usePrjValState((state) => state.prjVal);

  const [gptProcessMsg, setGptProcessMsg] = useState("");
  // const [fetchStatus, setFetchStatus] = useState(true);

  const fetchStatus = useGptState((state) => state.gptState);
  const setFetchStatus = useGptState((state) => state.setGptState);

  const gptResArr = useGptResState((state) => state.data);
  const setGptResArr = useGptResState((state) => state.setData);

  const finalGptRes = useFinalGptResState((state) => state.finalData);
  const setFinalGptRes = useFinalGptResState((state) => state.setFinalData);

  const [gptDatas, setGptDatas] = useState([]);

  const [start, setStart] = useState(false);

  // console.log(fetchStatus);
  // console.log(props?.data.length);

  const loopTimes = Math.ceil(props?.data.length / 100000);

  // console.log(loopTimes);
  let gptDataArr = [];

  for (let i = 0; i < loopTimes; i++) {
    let stPoint = i * 100000;
    let edPoint = (i + 1) * 100000;

    gptDataArr.push(props?.data.slice(stPoint, edPoint));
  }

  const handleClickModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClickCancel = () => {
    props.setModal();
  };

  // console.log(fetchStatus);

  const handleClickActivate = () => {
    setStart(true);
    if (loopTimes > 1) {
      console.log("activated!");
      // 전체 str을 100000 길이로 자른 요소를 각각 gpt에 요청
      for (let i = 0; i < loopTimes; i++) {
        let body = {
          // model: "gpt-3.5-turbo-16k",
          model: "gpt-4o-2024-08-06", // gpt-4-1106-preview -> 업그레이드 (2024-07-31)
          messages: [
            {
              role: "system",
              content: gptDataArr[i],
            },
            {
              role: "assistant",
              content: `병원 운영 개선을 위한 설문조사를 실시했어
                        병원 컨설턴트 입장에서 설문 답변 중 가장 많이 제시된 내용을 중심으로 400자 이내의 존대말을 사용하여 요약해줘`,
            },
          ],
          temperature: 1,
          max_tokens: 4095,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        initGptMutate(JSON.stringify(body));
      }
    } else if (loopTimes === 1) {
      console.log("activated final process!");

      setGptProcessMsg("마지막 단계 진행중");

      // let str = "";

      // gptDatas.forEach((el) => {
      //   str = str + " " + el;
      // });

      let body = {
        // model: "gpt-3.5-turbo-16k",
        model: "gpt-4o-2024-08-06", // gpt-4-1106-preview -> 업그레이드 (2024-07-31)
        messages: [
          {
            role: "system",
            content: gptDataArr[0],
          },
          {
            role: "assistant",
            content: `병원 운영 개선을 위한 설문조사를 실시했어
                        병원 컨설턴트 입장에서 설문 답변 중 가장 많이 제시된 내용을 중심으로 400자 이내의 존대말을 사용하여 요약해줘`,
          },
        ],
        temperature: 1,
        max_tokens: 4095,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      };

      finalGptMutate(JSON.stringify(body));
    }
  };

  // console.log(gptDatas);

  useEffect(() => {
    setGptDatas([...gptDatas, ...gptResArr]);

    let processRate = Math.floor((gptDatas.length / loopTimes) * 100);
    setGptProcessMsg(`${processRate}% 진행중`);

    // if (initGptError) {
    //   setFetchStatus(false);
    // }
    // console.log(gptDatas.length, loopTimes);
  }, [gptResArr]);

  useEffect(() => {
    if (gptDatas.length === loopTimes) {
      console.log("activated final process!");

      setGptProcessMsg("마지막 단계 진행중");

      let str = "";

      gptDatas.forEach((el) => {
        str = str + " " + el;
      });

      let body = {
        // model: "gpt-3.5-turbo-16k",
        model: "gpt-4o-2024-08-06", // gpt-4-1106-preview -> 업그레이드 (2024-07-31)
        messages: [
          {
            role: "system",
            content: str,
          },
          {
            role: "assistant",
            content: `병원 운영 개선을 위한 설문조사를 실시했어
                        병원 컨설턴트 입장에서 설문 답변 중 가장 많이 제시된 내용을 중심으로 400자 이내의 존대말을 사용하여 요약해줘`,
          },
        ],
        temperature: 1,
        max_tokens: 4095,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      };

      finalGptMutate(JSON.stringify(body));
    }
  }, [gptDatas]);

  useEffect(() => {
    let sortedFilter = props.filterOpt.sort((a, b) => a - b);

    if (finalGptRes.length !== 0) {
      if (
        (menuState === "MEM" && sortedFilter.length === 7) ||
        ((menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") && sortedFilter.length === 8)
      ) {
        let body = {
          prj_CD: prjVal.value,
          svy_OJT: selectQstState.svy_OJT,
          qst_CD: selectQstState.qst_CD,
          prd: startDate ? `${startDate}-${endDate.substring(0, 10)}` : "-",
          dvs: ["-"],
          gpt_RSLT: finalGptRes[0],
        };

        GptResMutate(body);
      } else {
        let body = {
          prj_CD: prjVal.value,
          svy_OJT: selectQstState.svy_OJT,
          qst_CD: selectQstState.qst_CD,
          prd: startDate ? `${startDate}-${endDate.substring(0, 10)}` : "-",
          dvs: sortedFilter,
          gpt_RSLT: finalGptRes[0],
        };

        GptResMutate(body);
      }
    }
  }, [finalGptRes]);

  // useEffect(() => {
  //   if (start) {
  //     if (GptResError || finalGptError || initGptError) {
  //       setFetchStatus(false);
  //     }
  //   }
  // }, [GptResError, finalGptError, initGptError]);

  useEffect(() => {
    return () => {
      setFinalGptRes([]);
      setGptDatas([]);
      setStart(false);
      setGptResArr([]);
      setFetchStatus(200);
    };
  }, []);

  return (
    <div
      onClick={handleClickModal}
      className="relative flex h-[180px] w-[300px] flex-col items-center justify-center rounded-lg border border-modalBorderColor bg-white"
    >
      <HiOutlineX onClick={handleClickCancel} className="absolute right-4 top-4 text-4xl text-textColor" />
      <div className="mb-4 flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] font-bold text-selectTextColor/80">
        <CgDanger className="mb-6 text-[30px] text-FilterActiveColor" />
        GPT모델을 사용하여 요약정보를 받아옵니다
      </div>
      <div className="mb-6 flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[12px] font-semibold text-selectTextColor/50">
        {start && (initGptLoading || gptDatas.length !== loopTimes || finalGptLoading) ? `(절대 창을 닫지 마세요!)` : `(최대 5분정도 소요됩니다)`}
      </div>
      {fetchStatus === 400 ? (
        <div
          onClick={handleClickCancel}
          className="flex h-[50px] w-[250px] flex-col items-center justify-center rounded-lg bg-red-600/90 px-16 py-7 font-SDGothic text-[12px] font-bold text-white outline-none"
        >
          <div className="mb-4 h-fit w-fit text-[14px]">실패</div>
          <div className="h-fit w-fit text-white/80">(관리자에게 문의하세요)</div>
        </div>
      ) : (
        <button
          onClick={finalGptSuccess ? handleClickCancel : initGptLoading || finalGptLoading || GptResLoading ? null : handleClickActivate}
          type="button"
          className="flex h-[50px] w-[250px] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/90 px-16 py-7 font-SDGothic text-[16px] font-bold text-white outline-none"
        >
          {GptResIsSuccess ? (
            "DB 저장 완료"
          ) : start && (initGptLoading || gptDatas.length !== loopTimes || finalGptLoading) ? (
            <>
              <PulseLoader color="#ffffff" size={8} />
              <div className="mt-4 h-fit w-fit text-[12px]">{gptProcessMsg}</div>
            </>
          ) : (
            "ChatGPT 데이터 요청"
          )}
        </button>
      )}
    </div>
  );
};

export default GptFetchModal;
