import Commom from "./pages/mobile/Common";
import LoginPage from "./pages/mobile/LoginPage";

const routesMobile = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/m/common/*",
    element: <Commom />,
  },
];

export default routesMobile;
