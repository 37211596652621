import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA12 from "../../components/pc/BarColumnChartDA12";
import DA12Table from "../../components/pc/DA12Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGetDa12UprGrpSel } from "../../hooks/useGetDa12UprGrpSel";
import { useGraphDA12AgeGrp } from "../../hooks/useGraphDA12AgeGrp";
import { useGraphDA12Area } from "../../hooks/useGraphDA12Area";
import { useGraphDA12Assign } from "../../hooks/useGraphDA12Assign";
import { useGraphDA12Doc } from "../../hooks/useGraphDA12Doc";
import { useGraphDA12Etc1 } from "../../hooks/useGraphDA12Etc1";
import { useGraphDA12Etc2 } from "../../hooks/useGraphDA12Etc2";
import { useGraphDA12Etc3 } from "../../hooks/useGraphDA12Etc3";
import { useGraphDA12MainDept } from "../../hooks/useGraphDA12MainDept";
import { useGraphDA12Normal } from "../../hooks/useGraphDA12Normal";
import { useGraphDA12Octy } from "../../hooks/useGraphDA12Octy";
import { useGraphDA12Sex } from "../../hooks/useGraphDA12Sex";
import { useGraphDA12WrkYyGrp } from "../../hooks/useGraphDA12WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA12AgeGrp } from "../../hooks/useTableDA12AgeGrp";
import { useTableDA12Assign } from "../../hooks/useTableDA12Assign";
import { useTableDA12Doc } from "../../hooks/useTableDA12Doc";
import { useTableDA12Octy } from "../../hooks/useTableDA12Octy";
import { useTableDA12Sex } from "../../hooks/useTableDA12Sex";
import { useTableDA12WrkYyGrp } from "../../hooks/useTableDA12WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA12Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [RadioUprGrpTypeCheck, setRadioUprGrpTypeCheck] = useState("All");
  const [RadioDA07DA11Check, setRadioDA07DA11Check] = useState("1");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);
  useEffect(() => {}, [RadioUprGrpTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);
  console.log("여기");
  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeUprGrpType = (text) => {
    setRadioUprGrpTypeCheck(text);
  };

  const changeDA07DA11 = (text) => {
    setRadioDA07DA11Check(text);
  };
  const { GraphDA12GrpSelData, GraphDA12GrpSelRefetch } = useGetDa12UprGrpSel(PrjCd, SvyOjt, QstCd);
  const { isGraphDA12NormalLoading, isGraphDA12NormalError, GraphDA12NormalData, isGraphDA12NormalSuccess, GraphDA12NormalRefetch } =
    useGraphDA12Normal(PrjCd, SvyOjt, QstCd, RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck, "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA12AssignLoading, isGraphDA12AssignError, GraphDA12AssignData, isGraphDA12AssignSuccess, GraphDA12AssignRefetch } =
    useGraphDA12Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);
  console.log("여기 메뉴가잘들어오나", GraphDA12GrpSelData);

  const { isGraphDA12DocLoading, isGraphDA12DocError, GraphDA12DocData, isGraphDA12DocSuccess, GraphDA12DocRefetch } = useGraphDA12Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA12OctyLoading, isGraphDA12OctyError, GraphDA12OctyData, isGraphDA12OctySuccess, GraphDA12OctyRefetch } = useGraphDA12Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA12WrkYyGrpLoading, isGraphDA12WrkYyGrpError, GraphDA12WrkYyGrpData, isGraphDA12WrkYyGrpSuccess, GraphDA12WrkYyGrpRefetch } =
    useGraphDA12WrkYyGrp(
      PrjCd,
      SvyOjt,
      QstCd,
      RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
      "",
      "WRK_YY_GRP",
      SortType,
      startDate,
      endDate
    );

  const { isGraphDA12AgeGrpLoading, isGraphDA12AgeGrpError, GraphDA12AgeGrpData, isGraphDA12AgeGrpSuccess, GraphDA12AgeGrpRefetch } =
    useGraphDA12AgeGrp(PrjCd, SvyOjt, QstCd, RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck, "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA12SexLoading, isGraphDA12SexError, GraphDA12SexData, isGraphDA12SexSuccess, GraphDA12SexRefetch } = useGraphDA12Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA12AreaLoading, isGraphDA12AreaError, GraphDA12AreaData, isGraphDA12AreaSuccess, GraphDA12AreaRefetch } = useGraphDA12Area(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA12MainDeptLoading, isGraphDA12MainDeptError, GraphDA12MainDeptData, isGraphDA12MainDeptSuccess, GraphDA12MainDeptRefetch } =
    useGraphDA12MainDept(
      PrjCd,
      SvyOjt,
      QstCd,
      RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
      "",
      "MAIN_DEPT_CD",
      SortType,
      startDate,
      endDate
    );

  const { isGraphDA12Etc1Loading, isGraphDA12Etc1Error, GraphDA12Etc1Data, isGraphDA12Etc1Success, GraphDA12Etc1Refetch } = useGraphDA12Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA12Etc2Loading, isGraphDA12Etc2Error, GraphDA12Etc2Data, isGraphDA12Etc2Success, GraphDA12Etc2Refetch } = useGraphDA12Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA12Etc3Loading, isGraphDA12Etc3Error, GraphDA12Etc3Data, isGraphDA12Etc3Success, GraphDA12Etc3Refetch } = useGraphDA12Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA12AgeGrpLoading, isTableDA12AgeGrpError, TableDA12AgeGrpData, isTableDA12AgeGrpSuccess, TableDA12AgeGrpRefetch } =
    useTableDA12AgeGrp(PrjCd, SvyOjt, QstCd, RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck, "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA12AssignLoading, isTableDA12AssignError, TableDA12AssignData, isTableDA12AssignSuccess, TableDA12AssignRefetch } =
    useTableDA12Assign(
      PrjCd,
      SvyOjt,
      QstCd,
      RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
      "",
      "ASSIGN_YN",
      SortType,
      startDate,
      endDate
    );

  const { isTableDA12DocLoading, isTableDA12DocError, TableDA12DocData, isTableDA12DocSuccess, TableDA12DocRefetch } = useTableDA12Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA12OctyLoading, isTableDA12OctyError, TableDA12OctyData, isTableDA12OctySuccess, TableDA12OctyRefetch } = useTableDA12Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA12SexLoading, isTableDA12SexError, TableDA12SexData, isTableDA12SexSuccess, TableDA12SexRefetch } = useTableDA12Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA12WrkYyGrpLoading, isTableDA12WrkYyGrpError, TableDA12WrkYyGrpData, isTableDA12WrkYyGrpSuccess, TableDA12WrkYyGrpRefetch } =
    useTableDA12WrkYyGrp(
      PrjCd,
      SvyOjt,
      QstCd,
      RadioUprGrpTypeCheck === "All" ? "" : RadioUprGrpTypeCheck,
      "",
      "WRK_YY_GRP",
      SortType,
      startDate,
      endDate
    );

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioDA07DA11Check.length > 0 && RadioDA07DA11Check[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA12NormalRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA12AssignRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA12DocRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA12OctyRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA12WrkYyGrpRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA12AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA12SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA12AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA12MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA12Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA12Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA12Etc3Refetch();
          }
        }
      }

      if (RadioTypeCheck === "AgeGrp") {
        GraphDA12AgeGrpRefetch();
        TableDA12AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA12SexRefetch();
        TableDA12SexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA12AssignRefetch();
        TableDA12AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphDA12DocRefetch();
        TableDA12DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA12OctyRefetch();
        TableDA12OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        TableDA12WrkYyGrpRefetch();
        GraphDA12WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA12MainDeptRefetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, RadioUprGrpTypeCheck, RadioDA07DA11Check, startDate, endDate, GraphSet]);

  useEffect(() => {
    GraphDA12GrpSelRefetch();
  }, [GraphDA12GrpSelData]);
  // useEffect(() => {
  //   if (!isGraphDA12NormalSuccess || GraphDA12NormalData === null || GraphDA12NormalData === undefined || GraphDA12NormalData.length === 0) {
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12NormalData,
  //   isGraphDA12NormalSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12AssignSuccess || GraphDA12AssignData === null || GraphDA12AssignData === undefined || GraphDA12AssignData.length === 0) {
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();

  //   }
  // }, [
  //   GraphDA12AssignData,
  //   isGraphDA12AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12DocSuccess || GraphDA12DocData === null || GraphDA12DocData === undefined || GraphDA12DocData.length === 0) {
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12DocData,
  //   isGraphDA12DocSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12OctySuccess || GraphDA12OctyData === null || GraphDA12OctyData === undefined || GraphDA12OctyData.length === 0) {
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12OctyData,
  //   isGraphDA12OctySuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12WrkYyGrpSuccess || GraphDA12WrkYyGrpData === null || GraphDA12WrkYyGrpData === undefined || GraphDA12WrkYyGrpData.length === 0) {
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12WrkYyGrpData,
  //   isGraphDA12WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12AgeGrpSuccess || GraphDA12AgeGrpData === null || GraphDA12AgeGrpData === undefined || GraphDA12AgeGrpData.length === 0) {
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12AgeGrpData,
  //   isGraphDA12AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12SexSuccess || GraphDA12SexData === null || GraphDA12SexData === undefined || GraphDA12SexData.length === 0) {
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12SexData,
  //   isGraphDA12SexSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12AreaSuccess || GraphDA12AreaData === null || GraphDA12AreaData === undefined || GraphDA12AreaData.length === 0) {
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12AreaData,
  //   isGraphDA12AreaSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12MainDeptSuccess || GraphDA12MainDeptData === null || GraphDA12MainDeptData === undefined || GraphDA12MainDeptData.length === 0) {
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12MainDeptData,
  //   isGraphDA12MainDeptSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12Etc1Success || GraphDA12Etc1Data === null || GraphDA12Etc1Data === undefined || GraphDA12Etc1Data.length === 0) {
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12Etc1Data,
  //   isGraphDA12Etc1Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12Etc2Success || GraphDA12Etc2Data === null || GraphDA12Etc2Data === undefined || GraphDA12Etc2Data.length === 0) {
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12Etc2Data,
  //   isGraphDA12Etc2Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA12Etc3Success || GraphDA12Etc3Data === null || GraphDA12Etc3Data === undefined || GraphDA12Etc3Data.length === 0) {
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   GraphDA12Etc3Data,
  //   isGraphDA12Etc3Success,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA12AgeGrpSuccess || TableDA12AgeGrpData === null || TableDA12AgeGrpData === undefined || TableDA12AgeGrpData.length === 0) {
  //     TableDA12AgeGrpRefetch();
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   TableDA12AgeGrpData,
  //   isTableDA12AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA12AssignSuccess || TableDA12AssignData === null || TableDA12AssignData === undefined || TableDA12AssignData.length === 0) {
  //     TableDA12AssignRefetch();
  //     TableDA12AgeGrpRefetch();
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   TableDA12AssignData,
  //   isTableDA12AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA12DocSuccess || TableDA12DocData === null || TableDA12DocData === undefined || TableDA12DocData.length === 0) {
  //     TableDA12DocRefetch();
  //     TableDA12AssignRefetch();
  //     TableDA12AgeGrpRefetch();
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   TableDA12DocData,
  //   isTableDA12DocSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA12OctySuccess || TableDA12OctyData === null || TableDA12OctyData === undefined || TableDA12OctyData.length === 0) {
  //     TableDA12OctyRefetch();
  //     TableDA12DocRefetch();
  //     TableDA12AssignRefetch();
  //     TableDA12AgeGrpRefetch();
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   TableDA12OctyData,
  //   isTableDA12OctySuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA12SexSuccess || TableDA12SexData === null || TableDA12SexData === undefined || TableDA12SexData.length === 0) {
  //     TableDA12SexRefetch();
  //     TableDA12OctyRefetch();
  //     TableDA12DocRefetch();
  //     TableDA12AssignRefetch();
  //     TableDA12AgeGrpRefetch();
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   TableDA12SexData,
  //   isTableDA12SexSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA12WrkYyGrpSuccess || TableDA12WrkYyGrpData === null || TableDA12WrkYyGrpData === undefined || TableDA12WrkYyGrpData.length === 0) {
  //     TableDA12WrkYyGrpRefetch();
  //     TableDA12SexRefetch();
  //     TableDA12OctyRefetch();
  //     TableDA12DocRefetch();
  //     TableDA12AssignRefetch();
  //     TableDA12AgeGrpRefetch();
  //     GraphDA12Etc3Refetch();
  //     GraphDA12Etc2Refetch();
  //     GraphDA12Etc1Refetch();
  //     GraphDA12MainDeptRefetch();
  //     GraphDA12AreaRefetch();
  //     GraphDA12SexRefetch();
  //     GraphDA12AgeGrpRefetch();+
  //     GraphDA12WrkYyGrpRefetch();
  //     GraphDA12OctyRefetch();
  //     GraphDA12DocRefetch();
  //     GraphDA12AssignRefetch();
  //     GraphDA12NormalRefetch();
  //   }
  // }, [
  //   TableDA12WrkYyGrpData,
  //   isTableDA12WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA07DA11Check,
  //   startDate,
  //   endDate,
  // ]);
  console.log(Sel, Sel);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA12"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeUprGrpType={changeUprGrpType}
          changeDA06={() => {}}
          changeDA12UprGrp={() => {}}
          changeDA07DA11={changeDA07DA11}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={RadioDA07DA11Check}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          uprGrpSel={GraphDA12GrpSelData?.map((item) => {
            return { value: item.sel_CD, label: item.sel_NM_SVY };
          })}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA12NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA12AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA12DocData;
              } else if (data.value === "Octy") {
                vType = "column";
                vStack = "Y";
                vName = "octy";
                vData = GraphDA12OctyData;
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vStack = "Y";
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA12WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA12SexData;
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vStack = "Y";
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA12AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA12AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA12MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA12Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA12Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA12Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA12
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    selectedDA07DA11={RadioDA07DA11Check}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA12NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA12AssignData;
              vTData = TableDA12AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA12DocData;
              vTData = TableDA12DocData;
            } else if (data.value === "Octy") {
              vType = "column";
              vStack = "Y";
              vName = "octy";
              vData = GraphDA12OctyData;
              vTData = TableDA12OctyData;
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vStack = "Y";
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA12WrkYyGrpData;
              vTData = TableDA12WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA12SexData;
              vTData = TableDA12SexData;
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vStack = "Y";
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA12AgeGrpData;
              vTData = TableDA12AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA12AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA12MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA12Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA12Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA12Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA12Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        RadioDA07DA11Check={RadioDA07DA11Check}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA12
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    selectedDA07DA11={RadioDA07DA11Check}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA12Page;
