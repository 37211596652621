import { useMutation } from "@tanstack/react-query";
import postGpt from "../api/postGpt";
import { useGptResState, useGptState } from "../store/mobile/store";

const usePostGpt = () => {
  const setGptResArr = useGptResState((state) => state.setData);
  const gptResArr = useGptResState((state) => state.data);

  const gptState = useGptState((state) => state.gptState);
  const setGptState = useGptState((state) => state.setGptState);

  // let tempArr = gptResArr ? [...gptResArr] : gptResArr;

  return useMutation(postGpt, {
    onSuccess: (res) => {
      // console.log(res);
      // tempArr.push(res.data.choices[0].message.content);
      setGptResArr([res.data.choices[0].message.content]);
    },
    onError: (err) => {
      console.log(err);
      setGptState(400);
    },
  });
};

export default usePostGpt;
