import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA06 from "../../components/pc/BarColumnChartDA06";
import CSTrendChartPeriod from "../../components/pc/CSTrendChartPeriod";
import DA06Table from "../../components/pc/DA06Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA06AgeGrp } from "../../hooks/useGraphDA06AgeGrp";
import { useGraphDA06Area } from "../../hooks/useGraphDA06Area";
import { useGraphDA06Assign } from "../../hooks/useGraphDA06Assign";
import { useGraphDA06Doc } from "../../hooks/useGraphDA06Doc";
import { useGraphDA06Etc1 } from "../../hooks/useGraphDA06Etc1";
import { useGraphDA06Etc2 } from "../../hooks/useGraphDA06Etc2";
import { useGraphDA06Etc3 } from "../../hooks/useGraphDA06Etc3";
import { useGraphDA06MainDept } from "../../hooks/useGraphDA06MainDept";
import { useGraphDA06Normal } from "../../hooks/useGraphDA06Normal";
import { useGraphDA06Octy } from "../../hooks/useGraphDA06Octy";
import { useGraphDA06Sex } from "../../hooks/useGraphDA06Sex";
import { useGraphDA06WrkYyGrp } from "../../hooks/useGraphDA06WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA06AgeGrp } from "../../hooks/useTableDA06AgeGrp";
import { useTableDA06Assign } from "../../hooks/useTableDA06Assign";
import { useTableDA06Doc } from "../../hooks/useTableDA06Doc";
import { useTableDA06Octy } from "../../hooks/useTableDA06Octy";
import { useTableDA06Sex } from "../../hooks/useTableDA06Sex";
import { useTableDA06WrkYyGrp } from "../../hooks/useTableDA06WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA06Page = () => {
  const analysisType = useStore((state) => state.AnalysisType);
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [RadioDA06Check, setRadioDA06Check] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeDA06 = (text) => {
    setRadioDA06Check(text);
  };

  const { isGraphDA06NormalLoading, isGraphDA06NormalError, GraphDA06NormalData, isGraphDA06NormalSuccess, GraphDA06NormalRefetch } =
    useGraphDA06Normal(PrjCd, SvyOjt, QstCd, RadioDA06Check === "All" ? "" : RadioDA06Check, "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA06AssignLoading, isGraphDA06AssignError, GraphDA06AssignData, isGraphDA06AssignSuccess, GraphDA06AssignRefetch } =
    useGraphDA06Assign(PrjCd, SvyOjt, QstCd, RadioDA06Check === "All" ? "" : RadioDA06Check, "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA06DocLoading, isGraphDA06DocError, GraphDA06DocData, isGraphDA06DocSuccess, GraphDA06DocRefetch } = useGraphDA06Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA06OctyLoading, isGraphDA06OctyError, GraphDA06OctyData, isGraphDA06OctySuccess, GraphDA06OctyRefetch } = useGraphDA06Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA06WrkYyGrpLoading, isGraphDA06WrkYyGrpError, GraphDA06WrkYyGrpData, isGraphDA06WrkYyGrpSuccess, GraphDA06WrkYyGrpRefetch } =
    useGraphDA06WrkYyGrp(PrjCd, SvyOjt, QstCd, RadioDA06Check === "All" ? "" : RadioDA06Check, "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA06AgeGrpLoading, isGraphDA06AgeGrpError, GraphDA06AgeGrpData, isGraphDA06AgeGrpSuccess, GraphDA06AgeGrpRefetch } =
    useGraphDA06AgeGrp(PrjCd, SvyOjt, QstCd, RadioDA06Check === "All" ? "" : RadioDA06Check, "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA06SexLoading, isGraphDA06SexError, GraphDA06SexData, isGraphDA06SexSuccess, GraphDA06SexRefetch } = useGraphDA06Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA06AreaLoading, isGraphDA06AreaError, GraphDA06AreaData, isGraphDA06AreaSuccess, GraphDA06AreaRefetch } = useGraphDA06Area(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA06MainDeptLoading, isGraphDA06MainDeptError, GraphDA06MainDeptData, isGraphDA06MainDeptSuccess, GraphDA06MainDeptRefetch } =
    useGraphDA06MainDept(PrjCd, SvyOjt, QstCd, RadioDA06Check === "All" ? "" : RadioDA06Check, "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA06Etc1Loading, isGraphDA06Etc1Error, GraphDA06Etc1Data, isGraphDA06Etc1Success, GraphDA06Etc1Refetch } = useGraphDA06Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA06Etc2Loading, isGraphDA06Etc2Error, GraphDA06Etc2Data, isGraphDA06Etc2Success, GraphDA06Etc2Refetch } = useGraphDA06Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA06Etc3Loading, isGraphDA06Etc3Error, GraphDA06Etc3Data, isGraphDA06Etc3Success, GraphDA06Etc3Refetch } = useGraphDA06Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA06Check === "All" ? "" : RadioDA06Check,
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA06AgeGrpLoading, isTableDA06AgeGrpError, TableDA06AgeGrpData, isTableDA06AgeGrpSuccess, TableDA06AgeGrpRefetch } =
    useTableDA06AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA06AssignLoading, isTableDA06AssignError, TableDA06AssignData, isTableDA06AssignSuccess, TableDA06AssignRefetch } =
    useTableDA06Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA06DocLoading, isTableDA06DocError, TableDA06DocData, isTableDA06DocSuccess, TableDA06DocRefetch } = useTableDA06Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA06OctyLoading, isTableDA06OctyError, TableDA06OctyData, isTableDA06OctySuccess, TableDA06OctyRefetch } = useTableDA06Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA06SexLoading, isTableDA06SexError, TableDA06SexData, isTableDA06SexSuccess, TableDA06SexRefetch } = useTableDA06Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA06WrkYyGrpLoading, isTableDA06WrkYyGrpError, TableDA06WrkYyGrpData, isTableDA06WrkYyGrpSuccess, TableDA06WrkYyGrpRefetch } =
    useTableDA06WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioDA06Check.length > 0 && RadioDA06Check[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA06NormalRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA06WrkYyGrpRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA06OctyRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA06DocRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA06AssignRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA06AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA06SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA06AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA06MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA06Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA06Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA06Etc3Refetch();
          }
        }

        if (Object.keys(GraphSet[0]).length === 0) {
          PrjDispCntRefetch();
          SelListRefetch();
          QstListRefetch();
        }
      }

      if (RadioTypeCheck === "AgeGrp") {
        GraphDA06AgeGrpRefetch();
        TableDA06AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA06SexRefetch();
        TableDA06SexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA06AssignRefetch();
        TableDA06AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphDA06DocRefetch();
        TableDA06DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA06OctyRefetch();
        TableDA06OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphDA06WrkYyGrpRefetch();
        TableDA06WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA06MainDeptRefetch();
      } else if (RadioTypeCheck === "Area") {
        GraphDA06AreaRefetch();
      } else if (RadioTypeCheck === "Etc1") {
        GraphDA06Etc1Refetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate, GraphSet]);

  //   useEffect(() => {
  //     if (!isGraphDA06NormalSuccess || GraphDA06NormalData === null || GraphDA06NormalData === undefined || GraphDA06NormalData.length === 0) {
  //       GraphDA06NormalRefetch();
  //     }
  //   }, [
  //     GraphDA06NormalData,
  //     isGraphDA06NormalSuccess,

  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA06AssignSuccess || GraphDA06AssignData === null || GraphDA06AssignData === undefined || GraphDA06AssignData.length === 0) {
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();

  //     }
  //   }, [
  //     GraphDA06AssignData,
  //     isGraphDA06AssignSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA06DocSuccess || GraphDA06DocData === null || GraphDA06DocData === undefined || GraphDA06DocData.length === 0) {
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  //     }
  //   }, [GraphDA06DocData, isGraphDA06DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isGraphDA06OctySuccess || GraphDA06OctyData === null || GraphDA06OctyData === undefined || GraphDA06OctyData.length === 0) {
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  //     }
  //   }, [GraphDA06OctyData, isGraphDA06OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isGraphDA06WrkYyGrpSuccess || GraphDA06WrkYyGrpData === null || GraphDA06WrkYyGrpData === undefined || GraphDA06WrkYyGrpData.length === 0) {
  //             GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA06WrkYyGrpData,
  //     isGraphDA06WrkYyGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA06AgeGrpSuccess || GraphDA06AgeGrpData === null || GraphDA06AgeGrpData === undefined || GraphDA06AgeGrpData.length === 0) {
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA06AgeGrpData,
  //     isGraphDA06AgeGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA06SexSuccess || GraphDA06SexData === null || GraphDA06SexData === undefined || GraphDA06SexData.length === 0) {
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [GraphDA06SexData, isGraphDA06SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isGraphDA06AreaSuccess || GraphDA06AreaData === null || GraphDA06AreaData === undefined || GraphDA06AreaData.length === 0) {
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [GraphDA06AreaData, isGraphDA06AreaSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isGraphDA06MainDeptSuccess || GraphDA06MainDeptData === null || GraphDA06MainDeptData === undefined || GraphDA06MainDeptData.length === 0) {
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();

  //     }
  //   }, [
  //     GraphDA06MainDeptData,
  //     isGraphDA06MainDeptSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA06Etc1Success || GraphDA06Etc1Data === null || GraphDA06Etc1Data === undefined || GraphDA06Etc1Data.length === 0) {
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [GraphDA06Etc1Data, isGraphDA06Etc1Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isGraphDA06Etc2Success || GraphDA06Etc2Data === null || GraphDA06Etc2Data === undefined || GraphDA06Etc2Data.length === 0) {
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [GraphDA06Etc2Data, isGraphDA06Etc2Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isGraphDA06Etc3Success || GraphDA06Etc3Data === null || GraphDA06Etc3Data === undefined || GraphDA06Etc3Data.length === 0) {
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [GraphDA06Etc3Data, isGraphDA06Etc3Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isTableDA06AgeGrpSuccess || TableDA06AgeGrpData === null || TableDA06AgeGrpData === undefined || TableDA06AgeGrpData.length === 0) {
  //       TableDA06AgeGrpRefetch();
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA06AgeGrpData,
  //     isTableDA06AgeGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA06AssignSuccess || TableDA06AssignData === null || TableDA06AssignData === undefined || TableDA06AssignData.length === 0) {
  //       TableDA06AssignRefetch();
  //       TableDA06AgeGrpRefetch();
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA06AssignData,
  //     isTableDA06AssignSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA06DocSuccess || TableDA06DocData === null || TableDA06DocData === undefined || TableDA06DocData.length === 0) {
  //       TableDA06DocRefetch();
  //       TableDA06AssignRefetch();
  //       TableDA06AgeGrpRefetch();
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [TableDA06DocData, isTableDA06DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isTableDA06OctySuccess || TableDA06OctyData === null || TableDA06OctyData === undefined || TableDA06OctyData.length === 0) {
  //       TableDA06OctyRefetch();
  //       TableDA06DocRefetch();
  //       TableDA06AssignRefetch();
  //       TableDA06AgeGrpRefetch();
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [TableDA06OctyData, isTableDA06OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isTableDA06SexSuccess || TableDA06SexData === null || TableDA06SexData === undefined || TableDA06SexData.length === 0) {
  //       TableDA06SexRefetch();
  //       TableDA06OctyRefetch();
  //       TableDA06DocRefetch();
  //       TableDA06AssignRefetch();
  //       TableDA06AgeGrpRefetch();
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [TableDA06SexData, isTableDA06SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA06Check, startDate, endDate]);

  //   useEffect(() => {
  //     if (!isTableDA06WrkYyGrpSuccess || TableDA06WrkYyGrpData === null || TableDA06WrkYyGrpData === undefined || TableDA06WrkYyGrpData.length === 0) {
  //       TableDA06WrkYyGrpRefetch();
  //       TableDA06SexRefetch();
  //       TableDA06OctyRefetch();
  //       TableDA06DocRefetch();
  //       TableDA06AssignRefetch();
  //       TableDA06AgeGrpRefetch();
  //       GraphDA06Etc3Refetch();
  //       GraphDA06Etc2Refetch();
  //       GraphDA06Etc1Refetch();
  //       GraphDA06MainDeptRefetch();
  //       GraphDA06AreaRefetch();
  //       GraphDA06SexRefetch();
  //       GraphDA06AgeGrpRefetch();
  //       GraphDA06AssignRefetch();
  //       GraphDA06NormalRefetch();
  // GraphDA06DocRefetch();
  // GraphDA06OctyRefetch();
  // GraphDA06WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA06WrkYyGrpData,
  //     isTableDA06WrkYyGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA06Check,
  //     startDate,
  //     endDate,
  //   ]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA06"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={changeDA06}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={RadioDA06Check}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {analysisType === "csscan" ? (
            <CSTrendChartPeriod
              hsp_nm={HspNm}
              prj_cd={PrjCd}
              svy_ojt={SvyOjt}
              qst_cd={Pick.qst_cd}
              sel_cd={""}
              string_value={""}
              sort_type={SortType}
              data_num={RadioNumCheck}
              data_type={RadioTypeCheck}
              qst={Qst}
              endDate={endDate}
              deep_ana_cd={"DA06"}
            />
          ) : (
            <></>
          )}
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA06NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA06AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA06DocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA06OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA06WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA06SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA06AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA06AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA06MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA06Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA06Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA06Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA06
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={RadioDA06Check}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA06NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA06AssignData;
              vTData = TableDA06AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA06DocData;
              vTData = TableDA06DocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA06OctyData;
              vTData = TableDA06OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA06WrkYyGrpData;
              vTData = TableDA06WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA06SexData;
              vTData = TableDA06SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA06AgeGrpData;
              vTData = TableDA06AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA06AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA06MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA06Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA06Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA06Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA06Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA06
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={RadioDA06Check}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA06Page;
