import { useMutation, useQueryClient } from "@tanstack/react-query";
import postQstOrders from "../api/postQstOrders";

const usePostQstOrders = () => {
  const queryClient = useQueryClient();

  return useMutation(postQstOrders, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminQstSelRes"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostQstOrders;
