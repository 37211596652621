import { HiCheck } from "react-icons/hi";

const ActiveFilterBtn = (props) => {
  const handleClickBtn = () => {
    if (props?.opt !== "0") {
      if (props.name === "전체") {
        props.setList([]);
      } else {
        let list = [...props.list];

        let idx = list.indexOf(props.code);

        list.splice(idx, 1);

        props.setList(list);
      }
    } else {
      props?.setAlert(true);

      setTimeout(() => {
        props?.setAlert(false);
      }, 1500);
    }
  };

  return (
    <div
      onClick={handleClickBtn}
      className="mr-5 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] border-[1.5px] border-FilterActiveColor/80 bg-white px-7 py-3 font-SDGothic text-[14px] font-bold text-selectTextColor/90"
    >
      <HiCheck className="mr-2 text-[22px] text-orangeColor/80" />
      <div className="flex h-fit w-fit flex-col items-center justify-center">
        {props.name}
      </div>
    </div>
  );
};

export default ActiveFilterBtn;
