import { useEffect } from "react";

const DA11Table = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  data_type,
  stack,
  type,
  title,
  qst,
  name,
  keyword,
  keywordDeepAnaCd,
  RadioDA07DA11Check,
  Tdata,
}) => {
  useEffect(() => {}, [Tdata, prj_cd, svy_ojt, qst_cd, name, data_num, data_type, RadioDA07DA11Check]);

  return (
    <div key={`table_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}`} className="float-left w-full">
      {name === "age" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-66.6rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              10대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              20대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              30대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              40대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              50대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              60대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              70대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              80대
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                  <div className="float-left flex h-[3.0rem] w-[calc(100%-66.6rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                    {data.sel_NM}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.tot_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_1)).toString().toLocaleString()
                            : data.tot_1.toLocaleString()
                          : 0
                        : data.tot_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_1_CNT)).toString().toLocaleString()
                          : data.tot_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.tot_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_3)).toString().toLocaleString()
                            : data.tot_3.toLocaleString()
                          : 0
                        : data.tot_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_3_CNT)).toString().toLocaleString()
                          : data.tot_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.tot_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_123)).toString().toLocaleString()
                            : data.tot_123.toLocaleString()
                          : 0
                        : data.tot_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_123_CNT)).toString().toLocaleString()
                          : data.tot_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a10_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10_1)).toString().toLocaleString()
                            : data.a10_1.toLocaleString()
                          : 0
                        : data.a10_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_1_CNT)).toString().toLocaleString()
                          : data.a10_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a10_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10_2)).toString().toLocaleString()
                            : data.a10_2.toLocaleString()
                          : 0
                        : data.a10_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_2_CNT)).toString().toLocaleString()
                          : data.a10_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a10_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10_3)).toString().toLocaleString()
                            : data.a10_3.toLocaleString()
                          : 0
                        : data.a10_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_3_CNT)).toString().toLocaleString()
                          : data.a10_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a10
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10)).toString().toLocaleString()
                            : data.a10.toLocaleString()
                          : 0
                        : data.a10_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_CNT)).toString().toLocaleString()
                          : data.a10_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a10_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10_123)).toString().toLocaleString()
                            : data.a10_123.toLocaleString()
                          : 0
                        : data.a10_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_123_CNT)).toString().toLocaleString()
                          : data.a10_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a20_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20_1)).toString().toLocaleString()
                            : data.a20_1.toLocaleString()
                          : 0
                        : data.a20_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_1_CNT)).toString().toLocaleString()
                          : data.a20_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a20_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20_2)).toString().toLocaleString()
                            : data.a20_2.toLocaleString()
                          : 0
                        : data.a20_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_2_CNT)).toString().toLocaleString()
                          : data.a20_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a20_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20_3)).toString().toLocaleString()
                            : data.a20_3.toLocaleString()
                          : 0
                        : data.a20_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_3_CNT)).toString().toLocaleString()
                          : data.a20_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a20
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20)).toString().toLocaleString()
                            : data.a20.toLocaleString()
                          : 0
                        : data.a20_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_CNT)).toString().toLocaleString()
                          : data.a20_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a20_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20_123)).toString().toLocaleString()
                            : data.a20_123.toLocaleString()
                          : 0
                        : data.a20_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_123_CNT)).toString().toLocaleString()
                          : data.a20_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a30_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30_1)).toString().toLocaleString()
                            : data.a30_1.toLocaleString()
                          : 0
                        : data.a30_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_1_CNT)).toString().toLocaleString()
                          : data.a30_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a30_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30_2)).toString().toLocaleString()
                            : data.a30_2.toLocaleString()
                          : 0
                        : data.a30_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_2_CNT)).toString().toLocaleString()
                          : data.a30_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a30_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30_3)).toString().toLocaleString()
                            : data.a30_3.toLocaleString()
                          : 0
                        : data.a30_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_3_CNT)).toString().toLocaleString()
                          : data.a30_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a30
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30)).toString().toLocaleString()
                            : data.a30.toLocaleString()
                          : 0
                        : data.a30_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_CNT)).toString().toLocaleString()
                          : data.a30_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a30_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30_123)).toString().toLocaleString()
                            : data.a30_123.toLocaleString()
                          : 0
                        : data.a30_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_123_CNT)).toString().toLocaleString()
                          : data.a30_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a40_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40_1)).toString().toLocaleString()
                            : data.a40_1.toLocaleString()
                          : 0
                        : data.a40_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_1_CNT)).toString().toLocaleString()
                          : data.a40_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a40_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40_2)).toString().toLocaleString()
                            : data.a40_2.toLocaleString()
                          : 0
                        : data.a40_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_2_CNT)).toString().toLocaleString()
                          : data.a40_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a40_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40_3)).toString().toLocaleString()
                            : data.a40_3.toLocaleString()
                          : 0
                        : data.a40_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_3_CNT)).toString().toLocaleString()
                          : data.a40_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a40
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40)).toString().toLocaleString()
                            : data.a40.toLocaleString()
                          : 0
                        : data.a40_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_CNT)).toString().toLocaleString()
                          : data.a40_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a40_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40_123)).toString().toLocaleString()
                            : data.a40_123.toLocaleString()
                          : 0
                        : data.a40_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_123_CNT)).toString().toLocaleString()
                          : data.a40_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a50_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50_1)).toString().toLocaleString()
                            : data.a50_1.toLocaleString()
                          : 0
                        : data.a50_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_1_CNT)).toString().toLocaleString()
                          : data.a50_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a50_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50_2)).toString().toLocaleString()
                            : data.a50_2.toLocaleString()
                          : 0
                        : data.a50_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_2_CNT)).toString().toLocaleString()
                          : data.a50_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a50_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50_3)).toString().toLocaleString()
                            : data.a50_3.toLocaleString()
                          : 0
                        : data.a50_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_3_CNT)).toString().toLocaleString()
                          : data.a50_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a50
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50)).toString().toLocaleString()
                            : data.a50.toLocaleString()
                          : 0
                        : data.a50_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_CNT)).toString().toLocaleString()
                          : data.a50_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a50_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50_123)).toString().toLocaleString()
                            : data.a50_123.toLocaleString()
                          : 0
                        : data.a50_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_123_CNT)).toString().toLocaleString()
                          : data.a50_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a60_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60_1)).toString().toLocaleString()
                            : data.a60_1.toLocaleString()
                          : 0
                        : data.a60_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_1_CNT)).toString().toLocaleString()
                          : data.a60_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a60_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60_2)).toString().toLocaleString()
                            : data.a60_2.toLocaleString()
                          : 0
                        : data.a60_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_2_CNT)).toString().toLocaleString()
                          : data.a60_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a60_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60_3)).toString().toLocaleString()
                            : data.a60_3.toLocaleString()
                          : 0
                        : data.a60_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_3_CNT)).toString().toLocaleString()
                          : data.a60_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a60
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60)).toString().toLocaleString()
                            : data.a60.toLocaleString()
                          : 0
                        : data.a60_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_CNT)).toString().toLocaleString()
                          : data.a60_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a60_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60_123)).toString().toLocaleString()
                            : data.a60_123.toLocaleString()
                          : 0
                        : data.a60_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_123_CNT)).toString().toLocaleString()
                          : data.a60_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a70_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70_1)).toString().toLocaleString()
                            : data.a70_1.toLocaleString()
                          : 0
                        : data.a70_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_1_CNT)).toString().toLocaleString()
                          : data.a70_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a70_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70_2)).toString().toLocaleString()
                            : data.a70_2.toLocaleString()
                          : 0
                        : data.a70_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_2_CNT)).toString().toLocaleString()
                          : data.a70_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a70_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70_3)).toString().toLocaleString()
                            : data.a70_3.toLocaleString()
                          : 0
                        : data.a70_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_3_CNT)).toString().toLocaleString()
                          : data.a70_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a70
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70)).toString().toLocaleString()
                            : data.a70.toLocaleString()
                          : 0
                        : data.a70_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_CNT)).toString().toLocaleString()
                          : data.a70_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a70_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70_123)).toString().toLocaleString()
                            : data.a70_123.toLocaleString()
                          : 0
                        : data.a70_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_123_CNT)).toString().toLocaleString()
                          : data.a70_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.a80_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80_1)).toString().toLocaleString()
                            : data.a80_1.toLocaleString()
                          : 0
                        : data.a80_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_1_CNT)).toString().toLocaleString()
                          : data.a80_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.a80_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80_2)).toString().toLocaleString()
                            : data.a80_2.toLocaleString()
                          : 0
                        : data.a80_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_2_CNT)).toString().toLocaleString()
                          : data.a80_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.a80_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80_3)).toString().toLocaleString()
                            : data.a80_3.toLocaleString()
                          : 0
                        : data.a80_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_3_CNT)).toString().toLocaleString()
                          : data.a80_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.a80
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80)).toString().toLocaleString()
                            : data.a80.toLocaleString()
                          : 0
                        : data.a80_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_CNT)).toString().toLocaleString()
                          : data.a80_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.a80_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80_123)).toString().toLocaleString()
                            : data.a80_123.toLocaleString()
                          : 0
                        : data.a80_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_123_CNT)).toString().toLocaleString()
                          : data.a80_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {name === "assign" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
              <div className="float-left flex h-full w-[34%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                구분
              </div>
              <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                전체
              </div>
              <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                보직자
              </div>
              <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                구성원
              </div>
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`Assign_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-full">
                  <div key={`Assign_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[34%] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.sel_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.tot_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_1)).toString().toLocaleString()
                              : data.tot_1.toLocaleString()
                            : 0
                          : data.tot_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_1_CNT)).toString().toLocaleString()
                            : data.tot_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.tot_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                              : data.tot_2.toLocaleString()
                            : 0
                          : data.tot_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                            : data.tot_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.tot_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_3)).toString().toLocaleString()
                              : data.tot_3.toLocaleString()
                            : 0
                          : data.tot_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_3_CNT)).toString().toLocaleString()
                            : data.tot_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.tot
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                              : data.tot.toLocaleString()
                            : 0
                          : data.tot_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                            : data.tot_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.tot_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_123)).toString().toLocaleString()
                              : data.tot_123.toLocaleString()
                            : 0
                          : data.tot_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_123_CNT)).toString().toLocaleString()
                            : data.tot_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.assign_Y_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_1)).toString().toLocaleString()
                              : data.assign_Y_1.toLocaleString()
                            : 0
                          : data.assign_Y_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_1_CNT)).toString().toLocaleString()
                            : data.assign_Y_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.assign_Y_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_2)).toString().toLocaleString()
                              : data.assign_Y_2.toLocaleString()
                            : 0
                          : data.assign_Y_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_2_CNT)).toString().toLocaleString()
                            : data.assign_Y_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.assign_Y_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_3)).toString().toLocaleString()
                              : data.assign_Y_3.toLocaleString()
                            : 0
                          : data.assign_Y_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_3_CNT)).toString().toLocaleString()
                            : data.assign_Y_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.assign_Y
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y)).toString().toLocaleString()
                              : data.assign_Y.toLocaleString()
                            : 0
                          : data.assign_Y_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_CNT)).toString().toLocaleString()
                            : data.assign_Y_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.assign_Y_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_123)).toString().toLocaleString()
                              : data.assign_Y_123.toLocaleString()
                            : 0
                          : data.assign_Y_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_123_CNT)).toString().toLocaleString()
                            : data.assign_Y_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.assign_N_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_1)).toString().toLocaleString()
                              : data.assign_N_1.toLocaleString()
                            : 0
                          : data.assign_N_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_1_CNT)).toString().toLocaleString()
                            : data.assign_N_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.assign_N_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_2)).toString().toLocaleString()
                              : data.assign_N_2.toLocaleString()
                            : 0
                          : data.assign_N_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_2_CNT)).toString().toLocaleString()
                            : data.assign_N_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.assign_N_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_3)).toString().toLocaleString()
                              : data.assign_N_3.toLocaleString()
                            : 0
                          : data.assign_N_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_3_CNT)).toString().toLocaleString()
                            : data.assign_N_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.assign_N
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N)).toString().toLocaleString()
                              : data.assign_N.toLocaleString()
                            : 0
                          : data.assign_N_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_CNT)).toString().toLocaleString()
                            : data.assign_N_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.assign_N_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_123)).toString().toLocaleString()
                              : data.assign_N_123.toLocaleString()
                            : 0
                          : data.assign_N_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_123_CNT)).toString().toLocaleString()
                            : data.assign_N_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {name === "doc" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
              <div className="float-left flex h-full w-[34%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                구분
              </div>
              <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                전체
              </div>
              <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                의사직
              </div>
              <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                구성원
              </div>
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`Doc_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-full">
                  <div key={`Doc_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[34%] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.sel_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.tot_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_1)).toString().toLocaleString()
                              : data.tot_1.toLocaleString()
                            : 0
                          : data.tot_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_1_CNT)).toString().toLocaleString()
                            : data.tot_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.tot_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                              : data.tot_2.toLocaleString()
                            : 0
                          : data.tot_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                            : data.tot_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.tot_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_3)).toString().toLocaleString()
                              : data.tot_3.toLocaleString()
                            : 0
                          : data.tot_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_3_CNT)).toString().toLocaleString()
                            : data.tot_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.tot
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                              : data.tot.toLocaleString()
                            : 0
                          : data.tot_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                            : data.tot_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.tot_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_123)).toString().toLocaleString()
                              : data.tot_123.toLocaleString()
                            : 0
                          : data.tot_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_123_CNT)).toString().toLocaleString()
                            : data.tot_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.assign_Y_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_1)).toString().toLocaleString()
                              : data.assign_Y_1.toLocaleString()
                            : 0
                          : data.assign_Y_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_1_CNT)).toString().toLocaleString()
                            : data.assign_Y_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.assign_Y_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_2)).toString().toLocaleString()
                              : data.assign_Y_2.toLocaleString()
                            : 0
                          : data.assign_Y_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_2_CNT)).toString().toLocaleString()
                            : data.assign_Y_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.assign_Y_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_3)).toString().toLocaleString()
                              : data.assign_Y_3.toLocaleString()
                            : 0
                          : data.assign_Y_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_3_CNT)).toString().toLocaleString()
                            : data.assign_Y_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.assign_Y
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y)).toString().toLocaleString()
                              : data.assign_Y.toLocaleString()
                            : 0
                          : data.assign_Y_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_CNT)).toString().toLocaleString()
                            : data.assign_Y_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.assign_Y_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_Y_123)).toString().toLocaleString()
                              : data.assign_Y_123.toLocaleString()
                            : 0
                          : data.assign_Y_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_Y_123_CNT)).toString().toLocaleString()
                            : data.assign_Y_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.assign_N_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_1)).toString().toLocaleString()
                              : data.assign_N_1.toLocaleString()
                            : 0
                          : data.assign_N_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_1_CNT)).toString().toLocaleString()
                            : data.assign_N_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.assign_N_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_2)).toString().toLocaleString()
                              : data.assign_N_2.toLocaleString()
                            : 0
                          : data.assign_N_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_2_CNT)).toString().toLocaleString()
                            : data.assign_N_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.assign_N_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_3)).toString().toLocaleString()
                              : data.assign_N_3.toLocaleString()
                            : 0
                          : data.assign_N_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_3_CNT)).toString().toLocaleString()
                            : data.assign_N_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.assign_N
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N)).toString().toLocaleString()
                              : data.assign_N.toLocaleString()
                            : 0
                          : data.assign_N_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_CNT)).toString().toLocaleString()
                            : data.assign_N_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.assign_N_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.assign_N_123)).toString().toLocaleString()
                              : data.assign_N_123.toLocaleString()
                            : 0
                          : data.assign_N_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.assign_N_123_CNT)).toString().toLocaleString()
                            : data.assign_N_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {name === "octy" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-65.6rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              의사직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              간호직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              보건직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              약무직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              사무직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              기술직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              기타
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                  <div className="float-left flex h-[3.0rem] w-[calc(100%-65.6rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                    {data.sel_NM}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.tot_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_1)).toString().toLocaleString()
                            : data.tot_1.toLocaleString()
                          : 0
                        : data.tot_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_1_CNT)).toString().toLocaleString()
                          : data.tot_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.tot_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_3)).toString().toLocaleString()
                            : data.tot_3.toLocaleString()
                          : 0
                        : data.tot_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_3_CNT)).toString().toLocaleString()
                          : data.tot_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.tot_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_123)).toString().toLocaleString()
                            : data.tot_123.toLocaleString()
                          : 0
                        : data.tot_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_123_CNT)).toString().toLocaleString()
                          : data.tot_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.doc_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc_1)).toString().toLocaleString()
                            : data.doc_1.toLocaleString()
                          : 0
                        : data.doc_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_1_CNT)).toString().toLocaleString()
                          : data.doc_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.doc_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc_2)).toString().toLocaleString()
                            : data.doc_2.toLocaleString()
                          : 0
                        : data.doc_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_2_CNT)).toString().toLocaleString()
                          : data.doc_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.doc_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc_3)).toString().toLocaleString()
                            : data.doc_3.toLocaleString()
                          : 0
                        : data.doc_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_3_CNT)).toString().toLocaleString()
                          : data.doc_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.doc
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc)).toString().toLocaleString()
                            : data.doc.toLocaleString()
                          : 0
                        : data.doc_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_CNT)).toString().toLocaleString()
                          : data.doc_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.doc_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc_123)).toString().toLocaleString()
                            : data.doc_123.toLocaleString()
                          : 0
                        : data.doc_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_123_CNT)).toString().toLocaleString()
                          : data.doc_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.nr_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr_1)).toString().toLocaleString()
                            : data.nr_1.toLocaleString()
                          : 0
                        : data.nr_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_1_CNT)).toString().toLocaleString()
                          : data.nr_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.nr_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr_2)).toString().toLocaleString()
                            : data.nr_2.toLocaleString()
                          : 0
                        : data.nr_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_2_CNT)).toString().toLocaleString()
                          : data.nr_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.nr_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr_3)).toString().toLocaleString()
                            : data.nr_3.toLocaleString()
                          : 0
                        : data.nr_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_3_CNT)).toString().toLocaleString()
                          : data.nr_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.nr
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr)).toString().toLocaleString()
                            : data.nr.toLocaleString()
                          : 0
                        : data.nr_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_CNT)).toString().toLocaleString()
                          : data.nr_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.nr_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr_123)).toString().toLocaleString()
                            : data.nr_123.toLocaleString()
                          : 0
                        : data.nr_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_123_CNT)).toString().toLocaleString()
                          : data.nr_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.hlth_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth_1)).toString().toLocaleString()
                            : data.hlth_1.toLocaleString()
                          : 0
                        : data.hlth_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_1_CNT)).toString().toLocaleString()
                          : data.hlth_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.hlth_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth_2)).toString().toLocaleString()
                            : data.hlth_2.toLocaleString()
                          : 0
                        : data.hlth_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_2_CNT)).toString().toLocaleString()
                          : data.hlth_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.hlth_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth_3)).toString().toLocaleString()
                            : data.hlth_3.toLocaleString()
                          : 0
                        : data.hlth_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_3_CNT)).toString().toLocaleString()
                          : data.hlth_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.hlth
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth)).toString().toLocaleString()
                            : data.hlth.toLocaleString()
                          : 0
                        : data.hlth_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_CNT)).toString().toLocaleString()
                          : data.hlth_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.hlth_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth_123)).toString().toLocaleString()
                            : data.hlth_123.toLocaleString()
                          : 0
                        : data.hlth_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_123_CNT)).toString().toLocaleString()
                          : data.hlth_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.pham_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham_1)).toString().toLocaleString()
                            : data.pham_1.toLocaleString()
                          : 0
                        : data.pham_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_1_CNT)).toString().toLocaleString()
                          : data.pham_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.pham_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham_2)).toString().toLocaleString()
                            : data.pham_2.toLocaleString()
                          : 0
                        : data.pham_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_2_CNT)).toString().toLocaleString()
                          : data.pham_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.pham_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham_3)).toString().toLocaleString()
                            : data.pham_3.toLocaleString()
                          : 0
                        : data.pham_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_3_CNT)).toString().toLocaleString()
                          : data.pham_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.pham
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham)).toString().toLocaleString()
                            : data.pham.toLocaleString()
                          : 0
                        : data.pham_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_CNT)).toString().toLocaleString()
                          : data.pham_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.pham_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham_123)).toString().toLocaleString()
                            : data.pham_123.toLocaleString()
                          : 0
                        : data.pham_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_123_CNT)).toString().toLocaleString()
                          : data.pham_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.office_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office_1)).toString().toLocaleString()
                            : data.office_1.toLocaleString()
                          : 0
                        : data.office_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_1_CNT)).toString().toLocaleString()
                          : data.office_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.office_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office_2)).toString().toLocaleString()
                            : data.office_2.toLocaleString()
                          : 0
                        : data.office_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_2_CNT)).toString().toLocaleString()
                          : data.office_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.office_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office_3)).toString().toLocaleString()
                            : data.office_3.toLocaleString()
                          : 0
                        : data.office_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_3_CNT)).toString().toLocaleString()
                          : data.office_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.office
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office)).toString().toLocaleString()
                            : data.office.toLocaleString()
                          : 0
                        : data.office_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_CNT)).toString().toLocaleString()
                          : data.office_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.office_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office_123)).toString().toLocaleString()
                            : data.office_123.toLocaleString()
                          : 0
                        : data.office_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_123_CNT)).toString().toLocaleString()
                          : data.office_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.tech_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech_1)).toString().toLocaleString()
                            : data.tech_1.toLocaleString()
                          : 0
                        : data.tech_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_1_CNT)).toString().toLocaleString()
                          : data.tech_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.tech_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech_2)).toString().toLocaleString()
                            : data.tech_2.toLocaleString()
                          : 0
                        : data.tech_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_2_CNT)).toString().toLocaleString()
                          : data.tech_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.tech_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech_3)).toString().toLocaleString()
                            : data.tech_3.toLocaleString()
                          : 0
                        : data.tech_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_3_CNT)).toString().toLocaleString()
                          : data.tech_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.tech
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech)).toString().toLocaleString()
                            : data.tech.toLocaleString()
                          : 0
                        : data.tech_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_CNT)).toString().toLocaleString()
                          : data.tech_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.tech_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech_123)).toString().toLocaleString()
                            : data.tech_123.toLocaleString()
                          : 0
                        : data.tech_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_123_CNT)).toString().toLocaleString()
                          : data.tech_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.etc_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc_1)).toString().toLocaleString()
                            : data.etc_1.toLocaleString()
                          : 0
                        : data.etc_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_1_CNT)).toString().toLocaleString()
                          : data.etc_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.etc_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc_2)).toString().toLocaleString()
                            : data.etc_2.toLocaleString()
                          : 0
                        : data.etc_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_2_CNT)).toString().toLocaleString()
                          : data.etc_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.etc_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc_3)).toString().toLocaleString()
                            : data.etc_3.toLocaleString()
                          : 0
                        : data.etc_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_3_CNT)).toString().toLocaleString()
                          : data.etc_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.etc
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc)).toString().toLocaleString()
                            : data.etc.toLocaleString()
                          : 0
                        : data.etc_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_CNT)).toString().toLocaleString()
                          : data.etc_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.etc_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc_123)).toString().toLocaleString()
                            : data.etc_123.toLocaleString()
                          : 0
                        : data.etc_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_123_CNT)).toString().toLocaleString()
                          : data.etc_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {name === "sex" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex h-[3.0rem] w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="float-left flex h-full w-[34%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              남성
            </div>
            <div className="float-left flex h-full w-[22%] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              여성
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`Sex_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-full">
                  <div key={`Sex_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[34%] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.sel_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.tot_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_1)).toString().toLocaleString()
                              : data.tot_1.toLocaleString()
                            : 0
                          : data.tot_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_1_CNT)).toString().toLocaleString()
                            : data.tot_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.tot_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                              : data.tot_2.toLocaleString()
                            : 0
                          : data.tot_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                            : data.tot_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.tot_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_3)).toString().toLocaleString()
                              : data.tot_3.toLocaleString()
                            : 0
                          : data.tot_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_3_CNT)).toString().toLocaleString()
                            : data.tot_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.tot
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                              : data.tot.toLocaleString()
                            : 0
                          : data.tot_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                            : data.tot_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.tot_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.tot_123)).toString().toLocaleString()
                              : data.tot_123.toLocaleString()
                            : 0
                          : data.tot_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_123_CNT)).toString().toLocaleString()
                            : data.tot_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.s_M_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_M_1)).toString().toLocaleString()
                              : data.s_M_1.toLocaleString()
                            : 0
                          : data.s_M_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M_1_CNT)).toString().toLocaleString()
                            : data.s_M_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.s_M_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_M_2)).toString().toLocaleString()
                              : data.s_M_2.toLocaleString()
                            : 0
                          : data.s_M_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M_2_CNT)).toString().toLocaleString()
                            : data.s_M_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.s_M_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_M_3)).toString().toLocaleString()
                              : data.s_M_3.toLocaleString()
                            : 0
                          : data.s_M_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M_3_CNT)).toString().toLocaleString()
                            : data.s_M_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.s_M
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_M)).toString().toLocaleString()
                              : data.s_M.toLocaleString()
                            : 0
                          : data.s_M_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M_CNT)).toString().toLocaleString()
                            : data.s_M_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.s_M_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_M_123)).toString().toLocaleString()
                              : data.s_M_123.toLocaleString()
                            : 0
                          : data.s_M_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M_123_CNT)).toString().toLocaleString()
                            : data.s_M_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[22%] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {RadioDA07DA11Check === "1" &&
                        (data_num === "1"
                          ? data.s_F_1
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_F_1)).toString().toLocaleString()
                              : data.s_F_1.toLocaleString()
                            : 0
                          : data.s_F_1_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F_1_CNT)).toString().toLocaleString()
                            : data.s_F_1_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "2" &&
                        (data_num === "1"
                          ? data.s_F_2
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_F_2)).toString().toLocaleString()
                              : data.s_F_2.toLocaleString()
                            : 0
                          : data.s_F_2_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F_2_CNT)).toString().toLocaleString()
                            : data.s_F_2_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "3" &&
                        (data_num === "1"
                          ? data.s_F_3
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_F_3)).toString().toLocaleString()
                              : data.s_F_3.toLocaleString()
                            : 0
                          : data.s_F_3_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F_3_CNT)).toString().toLocaleString()
                            : data.s_F_3_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "+" &&
                        (data_num === "1"
                          ? data.s_F
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_F)).toString().toLocaleString()
                              : data.s_F.toLocaleString()
                            : 0
                          : data.s_F_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F_CNT)).toString().toLocaleString()
                            : data.s_F_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                      {RadioDA07DA11Check === "*" &&
                        (data_num === "1"
                          ? data.s_F_123
                            ? data.sel_NM === "사례수"
                              ? Math.floor(parseFloat(data.s_F_123)).toString().toLocaleString()
                              : data.s_F_123.toLocaleString()
                            : 0
                          : data.s_F_123_CNT
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F_123_CNT)).toString().toLocaleString()
                            : data.s_F_123_CNT.toLocaleString()
                          : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {name === "wrk" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              3년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              7년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              10년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              15년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              15년 이상
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              return (
                <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                  <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                    {data.sel_NM}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.tot_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_1)).toString().toLocaleString()
                            : data.tot_1.toLocaleString()
                          : 0
                        : data.tot_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_1_CNT)).toString().toLocaleString()
                          : data.tot_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.tot_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_3)).toString().toLocaleString()
                            : data.tot_3.toLocaleString()
                          : 0
                        : data.tot_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_3_CNT)).toString().toLocaleString()
                          : data.tot_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.tot_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_123)).toString().toLocaleString()
                            : data.tot_123.toLocaleString()
                          : 0
                        : data.tot_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_123_CNT)).toString().toLocaleString()
                          : data.tot_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.u3_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3_1)).toString().toLocaleString()
                            : data.u3_1.toLocaleString()
                          : 0
                        : data.u3_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_1_CNT)).toString().toLocaleString()
                          : data.u3_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.u3_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3_2)).toString().toLocaleString()
                            : data.u3_2.toLocaleString()
                          : 0
                        : data.u3_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_2_CNT)).toString().toLocaleString()
                          : data.u3_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.u3_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3_3)).toString().toLocaleString()
                            : data.u3_3.toLocaleString()
                          : 0
                        : data.u3_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_3_CNT)).toString().toLocaleString()
                          : data.u3_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.u3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3)).toString().toLocaleString()
                            : data.u3.toLocaleString()
                          : 0
                        : data.u3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_CNT)).toString().toLocaleString()
                          : data.u3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.u3_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3_123)).toString().toLocaleString()
                            : data.u3_123.toLocaleString()
                          : 0
                        : data.u3_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_123_CNT)).toString().toLocaleString()
                          : data.u3_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.b3_7_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7_1)).toString().toLocaleString()
                            : data.b3_7_1.toLocaleString()
                          : 0
                        : data.b3_7_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_1_CNT)).toString().toLocaleString()
                          : data.b3_7_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.b3_7_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7_2)).toString().toLocaleString()
                            : data.b3_7_2.toLocaleString()
                          : 0
                        : data.b3_7_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_2_CNT)).toString().toLocaleString()
                          : data.b3_7_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.b3_7_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7_3)).toString().toLocaleString()
                            : data.b3_7_3.toLocaleString()
                          : 0
                        : data.b3_7_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_3_CNT)).toString().toLocaleString()
                          : data.b3_7_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.b3_7
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7)).toString().toLocaleString()
                            : data.b3_7.toLocaleString()
                          : 0
                        : data.b3_7_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_CNT)).toString().toLocaleString()
                          : data.b3_7_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.b3_7_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7_123)).toString().toLocaleString()
                            : data.b3_7_123.toLocaleString()
                          : 0
                        : data.b3_7_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_123_CNT)).toString().toLocaleString()
                          : data.b3_7_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.b7_10_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10_1)).toString().toLocaleString()
                            : data.b7_10_1.toLocaleString()
                          : 0
                        : data.b7_10_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_1_CNT)).toString().toLocaleString()
                          : data.b7_10_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.b7_10_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10_2)).toString().toLocaleString()
                            : data.b7_10_2.toLocaleString()
                          : 0
                        : data.b7_10_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_2_CNT)).toString().toLocaleString()
                          : data.b7_10_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.b7_10_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10_3)).toString().toLocaleString()
                            : data.b7_10_3.toLocaleString()
                          : 0
                        : data.b7_10_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_3_CNT)).toString().toLocaleString()
                          : data.b7_10_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.b7_10
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10)).toString().toLocaleString()
                            : data.b7_10.toLocaleString()
                          : 0
                        : data.b7_10_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_CNT)).toString().toLocaleString()
                          : data.b7_10_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.b7_10_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10_123)).toString().toLocaleString()
                            : data.b7_10_123.toLocaleString()
                          : 0
                        : data.b7_10_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_123_CNT)).toString().toLocaleString()
                          : data.b7_10_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.b10_15_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15_1)).toString().toLocaleString()
                            : data.b10_15_1.toLocaleString()
                          : 0
                        : data.b10_15_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_1_CNT)).toString().toLocaleString()
                          : data.b10_15_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.b10_15_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15_2)).toString().toLocaleString()
                            : data.b10_15_2.toLocaleString()
                          : 0
                        : data.b10_15_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_2_CNT)).toString().toLocaleString()
                          : data.b10_15_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.b10_15_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15_3)).toString().toLocaleString()
                            : data.b10_15_3.toLocaleString()
                          : 0
                        : data.b10_15_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_3_CNT)).toString().toLocaleString()
                          : data.b10_15_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.b10_15
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15)).toString().toLocaleString()
                            : data.b10_15.toLocaleString()
                          : 0
                        : data.b10_15_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_CNT)).toString().toLocaleString()
                          : data.b10_15_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.b10_15_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15_123)).toString().toLocaleString()
                            : data.b10_15_123.toLocaleString()
                          : 0
                        : data.b10_15_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_123_CNT)).toString().toLocaleString()
                          : data.b10_15_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                  <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                    {RadioDA07DA11Check === "1" &&
                      (data_num === "1"
                        ? data.o15_1
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15_1)).toString().toLocaleString()
                            : data.o15_1.toLocaleString()
                          : 0
                        : data.o15_1_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_1_CNT)).toString().toLocaleString()
                          : data.o15_1_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "2" &&
                      (data_num === "1"
                        ? data.o15_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15_2)).toString().toLocaleString()
                            : data.o15_2.toLocaleString()
                          : 0
                        : data.o15_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_2_CNT)).toString().toLocaleString()
                          : data.o15_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "3" &&
                      (data_num === "1"
                        ? data.o15_3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15_3)).toString().toLocaleString()
                            : data.o15_3.toLocaleString()
                          : 0
                        : data.o15_3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_3_CNT)).toString().toLocaleString()
                          : data.o15_3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "+" &&
                      (data_num === "1"
                        ? data.o15
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15)).toString().toLocaleString()
                            : data.o15.toLocaleString()
                          : 0
                        : data.o15_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_CNT)).toString().toLocaleString()
                          : data.o15_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    {RadioDA07DA11Check === "*" &&
                      (data_num === "1"
                        ? data.o15_123
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15_123)).toString().toLocaleString()
                            : data.o15_123.toLocaleString()
                          : 0
                        : data.o15_123_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_123_CNT)).toString().toLocaleString()
                          : data.o15_123_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default DA11Table;
