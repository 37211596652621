import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SyncLoader } from "react-spinners";
import Da06Chart from "../../components/mobile/chart/Da06Chart";
import TabBar from "../../components/mobile/common/TabBar";
import SelListModal from "../../components/mobile/SelListModal";
import Da06Table from "../../components/mobile/table/Da06Table";
import useGetDa06Res from "../../hooks/useGetDa06Res";
import {
  useMenuListModalControlState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useSelListModalState,
  useSelListNm,
  useSelListState,
  useUnitState,
} from "../../store/mobile/store";

const DA06 = () => {
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const unitState = useUnitState((state) => state.unitState);
  const menuState = useMenuState((state) => state.menuVal);
  const prjVal = usePrjValState((state) => state.prjVal);
  const selList = useSelListState((state) => state.selListState);
  const selNm = useSelListNm((state) => state.selNmState);
  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const selListModalState = useSelListModalState((state) => state.selListModalState);
  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  const [rendering, setRendering] = useState(false);

  const { refetch, data } = useGetDa06Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, selList, startDate, endDate);

  let selListDb = data?.data[0];

  const handleClickSelList = () => {
    setSelListModalState(!selListModalState);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 300);
  }, [selectQstState, selList, startDate, endDate]);

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="relative flex h-fit w-full flex-row items-center justify-between border-b border-qstTitleBorder px-2 py-3 text-[16px] font-bold text-selectTextColor">
          <BsDot className="absolute left-2 text-orangeColor" />
          <div className="hide-scrollbar relative  ml-7 flex  h-fit w-[60%] flex-row items-center justify-start overflow-auto whitespace-pre py-4 ">
            {selectQstState.keyword}
          </div>
          {selListModalState ? (
            <>
              <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                  {selNm.length > 5 ? `${selNm.slice(0, 5)}...` : selNm}
                </div>
                <IoIosArrowUp className="mr-2 text-[20px] text-orangeColor" />
              </div>
            </>
          ) : (
            <>
              <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                  {selNm.length > 5 ? `${selNm.slice(0, 5)}...` : selNm}
                </div>
                <IoIosArrowDown className="mr-2 text-[20px] text-orangeColor" />
              </div>
            </>
          )}

          <div
            className={
              qstListModalState
                ? `absolute right-3 top-[46px] -z-50 h-[500px] w-[80%] overflow-hidden font-semibold`
                : `absolute right-3 top-[46px]  h-[500px] w-[80%] overflow-hidden font-semibold`
            }
          >
            <SelListModal selList={selListDb} />
          </div>
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답률`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center py-2">
                <label className="ml-2 flex h-fit w-fit flex-col items-center justify-center">(단위: %)</label>
              </div>
            </div>
          </div>
          {rendering && !qstListModalState ? (
            <>
              <Da06Chart data={null} selList={selListDb} />
              <Da06Table data={null} />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default DA06;
