import axios from "axios";

const postAdminStandardQst = async (postData, prj_cd) => {
  console.log("api통신되는곳 postData", postData, prj_cd);
  const response = await axios.post(`/admin/qststand?prj_cd=${prj_cd}`, postData, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });

  return response.data;
};

export default postAdminStandardQst;
