import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA09 from "../../components/pc/BarColumnChartDA09";
import DA09Table from "../../components/pc/DA09Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA09AgeGrp } from "../../hooks/useGraphDA09AgeGrp";
import { useGraphDA09Area } from "../../hooks/useGraphDA09Area";
import { useGraphDA09Assign } from "../../hooks/useGraphDA09Assign";
import { useGraphDA09Doc } from "../../hooks/useGraphDA09Doc";
import { useGraphDA09Etc1 } from "../../hooks/useGraphDA09Etc1";
import { useGraphDA09Etc2 } from "../../hooks/useGraphDA09Etc2";
import { useGraphDA09Etc3 } from "../../hooks/useGraphDA09Etc3";
import { useGraphDA09MainDept } from "../../hooks/useGraphDA09MainDept";
import { useGraphDA09Normal } from "../../hooks/useGraphDA09Normal";
import { useGraphDA09Octy } from "../../hooks/useGraphDA09Octy";
import { useGraphDA09Sex } from "../../hooks/useGraphDA09Sex";
import { useGraphDA09WrkYyGrp } from "../../hooks/useGraphDA09WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA09AgeGrp } from "../../hooks/useTableDA09AgeGrp";
import { useTableDA09Assign } from "../../hooks/useTableDA09Assign";
import { useTableDA09Doc } from "../../hooks/useTableDA09Doc";
import { useTableDA09Octy } from "../../hooks/useTableDA09Octy";
import { useTableDA09Sex } from "../../hooks/useTableDA09Sex";
import { useTableDA09WrkYyGrp } from "../../hooks/useTableDA09WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA09Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [RadioDA09Check, setRadioDA09Check] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        if (!dd.grp_NM) {
          vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
        } else {
          vCheck.push({ value: dd.sel_CD, label: dd.grp_NM + "-" + dd.sel_NM });
        }
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeDA09 = (text) => {
    setRadioDA09Check(text);
  };

  const { isGraphDA09NormalLoading, isGraphDA09NormalError, GraphDA09NormalData, isGraphDA09NormalSuccess, GraphDA09NormalRefetch } =
    useGraphDA09Normal(PrjCd, SvyOjt, QstCd, RadioDA09Check === "All" ? "" : RadioDA09Check, "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA09AssignLoading, isGraphDA09AssignError, GraphDA09AssignData, isGraphDA09AssignSuccess, GraphDA09AssignRefetch } =
    useGraphDA09Assign(PrjCd, SvyOjt, QstCd, RadioDA09Check === "All" ? "" : RadioDA09Check, "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA09DocLoading, isGraphDA09DocError, GraphDA09DocData, isGraphDA09DocSuccess, GraphDA09DocRefetch } = useGraphDA09Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA09OctyLoading, isGraphDA09OctyError, GraphDA09OctyData, isGraphDA09OctySuccess, GraphDA09OctyRefetch } = useGraphDA09Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA09WrkYyGrpLoading, isGraphDA09WrkYyGrpError, GraphDA09WrkYyGrpData, isGraphDA09WrkYyGrpSuccess, GraphDA09WrkYyGrpRefetch } =
    useGraphDA09WrkYyGrp(PrjCd, SvyOjt, QstCd, RadioDA09Check === "All" ? "" : RadioDA09Check, "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA09AgeGrpLoading, isGraphDA09AgeGrpError, GraphDA09AgeGrpData, isGraphDA09AgeGrpSuccess, GraphDA09AgeGrpRefetch } =
    useGraphDA09AgeGrp(PrjCd, SvyOjt, QstCd, RadioDA09Check === "All" ? "" : RadioDA09Check, "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA09SexLoading, isGraphDA09SexError, GraphDA09SexData, isGraphDA09SexSuccess, GraphDA09SexRefetch } = useGraphDA09Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA09AreaLoading, isGraphDA09AreaError, GraphDA09AreaData, isGraphDA09AreaSuccess, GraphDA09AreaRefetch } = useGraphDA09Area(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA09MainDeptLoading, isGraphDA09MainDeptError, GraphDA09MainDeptData, isGraphDA09MainDeptSuccess, GraphDA09MainDeptRefetch } =
    useGraphDA09MainDept(PrjCd, SvyOjt, QstCd, RadioDA09Check === "All" ? "" : RadioDA09Check, "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA09Etc1Loading, isGraphDA09Etc1Error, GraphDA09Etc1Data, isGraphDA09Etc1Success, GraphDA09Etc1Refetch } = useGraphDA09Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA09Etc2Loading, isGraphDA09Etc2Error, GraphDA09Etc2Data, isGraphDA09Etc2Success, GraphDA09Etc2Refetch } = useGraphDA09Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA09Etc3Loading, isGraphDA09Etc3Error, GraphDA09Etc3Data, isGraphDA09Etc3Success, GraphDA09Etc3Refetch } = useGraphDA09Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA09Check === "All" ? "" : RadioDA09Check,
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA09AgeGrpLoading, isTableDA09AgeGrpError, TableDA09AgeGrpData, isTableDA09AgeGrpSuccess, TableDA09AgeGrpRefetch } =
    useTableDA09AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA09AssignLoading, isTableDA09AssignError, TableDA09AssignData, isTableDA09AssignSuccess, TableDA09AssignRefetch } =
    useTableDA09Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA09DocLoading, isTableDA09DocError, TableDA09DocData, isTableDA09DocSuccess, TableDA09DocRefetch } = useTableDA09Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA09OctyLoading, isTableDA09OctyError, TableDA09OctyData, isTableDA09OctySuccess, TableDA09OctyRefetch } = useTableDA09Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA09SexLoading, isTableDA09SexError, TableDA09SexData, isTableDA09SexSuccess, TableDA09SexRefetch } = useTableDA09Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA09WrkYyGrpLoading, isTableDA09WrkYyGrpError, TableDA09WrkYyGrpData, isTableDA09WrkYyGrpSuccess, TableDA09WrkYyGrpRefetch } =
    useTableDA09WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }
    if (RadioDA09Check.length > 0 && RadioDA09Check[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA09NormalRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA09AssignRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA09DocRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA09OctyRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA09WrkYyGrpRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA09AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA09SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA09AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA09MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA09Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA09Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA09Etc3Refetch();
          }
        }
      }

      if (RadioTypeCheck === "AgeGrp") {
        GraphDA09AgeGrpRefetch();
        TableDA09AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA09SexRefetch();
        TableDA09SexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA09AssignRefetch();
        TableDA09AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        TableDA09DocRefetch();
        GraphDA09DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA09OctyRefetch();
        TableDA09OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphDA09WrkYyGrpRefetch();
        TableDA09WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA09MainDeptRefetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA09NormalSuccess || GraphDA09NormalData === null || GraphDA09NormalData === undefined || GraphDA09NormalData.length === 0) {
  //     GraphDA09NormalRefetch();
  //   }
  // }, [
  //   GraphDA09NormalData,
  //   isGraphDA09NormalSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA09AssignSuccess || GraphDA09AssignData === null || GraphDA09AssignData === undefined || GraphDA09AssignData.length === 0) {
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   GraphDA09AssignData,
  //   isGraphDA09AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA09DocSuccess || GraphDA09DocData === null || GraphDA09DocData === undefined || GraphDA09DocData.length === 0) {
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09DocData, isGraphDA09DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA09OctySuccess || GraphDA09OctyData === null || GraphDA09OctyData === undefined || GraphDA09OctyData.length === 0) {
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09OctyData, isGraphDA09OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA09WrkYyGrpSuccess || GraphDA09WrkYyGrpData === null || GraphDA09WrkYyGrpData === undefined || GraphDA09WrkYyGrpData.length === 0) {
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   GraphDA09WrkYyGrpData,
  //   isGraphDA09WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA09AgeGrpSuccess || GraphDA09AgeGrpData === null || GraphDA09AgeGrpData === undefined || GraphDA09AgeGrpData.length === 0) {
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   GraphDA09AgeGrpData,
  //   isGraphDA09AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA09SexSuccess || GraphDA09SexData === null || GraphDA09SexData === undefined || GraphDA09SexData.length === 0) {
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09SexData, isGraphDA09SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA09AreaSuccess || GraphDA09AreaData === null || GraphDA09AreaData === undefined || GraphDA09AreaData.length === 0) {
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09AreaData, isGraphDA09AreaSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA09MainDeptSuccess || GraphDA09MainDeptData === null || GraphDA09MainDeptData === undefined || GraphDA09MainDeptData.length === 0) {
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   GraphDA09MainDeptData,
  //   isGraphDA09MainDeptSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA09Etc1Success || GraphDA09Etc1Data === null || GraphDA09Etc1Data === undefined || GraphDA09Etc1Data.length === 0) {
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09Etc1Data, isGraphDA09Etc1Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA09Etc2Success || GraphDA09Etc2Data === null || GraphDA09Etc2Data === undefined || GraphDA09Etc2Data.length === 0) {
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09Etc2Data, isGraphDA09Etc2Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA09Etc3Success || GraphDA09Etc3Data === null || GraphDA09Etc3Data === undefined || GraphDA09Etc3Data.length === 0) {
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [GraphDA09Etc3Data, isGraphDA09Etc3Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA09AgeGrpSuccess || TableDA09AgeGrpData === null || TableDA09AgeGrpData === undefined || TableDA09AgeGrpData.length === 0) {
  //     TableDA09AgeGrpRefetch();
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   TableDA09AgeGrpData,
  //   isTableDA09AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA09AssignSuccess || TableDA09AssignData === null || TableDA09AssignData === undefined || TableDA09AssignData.length === 0) {
  //     TableDA09AssignRefetch();
  //     TableDA09AgeGrpRefetch();
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   TableDA09AssignData,
  //   isTableDA09AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA09DocSuccess || TableDA09DocData === null || TableDA09DocData === undefined || TableDA09DocData.length === 0) {
  //     TableDA09DocRefetch();
  //     TableDA09AssignRefetch();
  //     TableDA09AgeGrpRefetch();
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [TableDA09DocData, isTableDA09DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA09OctySuccess || TableDA09OctyData === null || TableDA09OctyData === undefined || TableDA09OctyData.length === 0) {
  //     TableDA09OctyRefetch();
  //     TableDA09DocRefetch();
  //     TableDA09AssignRefetch();
  //     TableDA09AgeGrpRefetch();
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [TableDA09OctyData, isTableDA09OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA09SexSuccess || TableDA09SexData === null || TableDA09SexData === undefined || TableDA09SexData.length === 0) {
  //     TableDA09SexRefetch();
  //     TableDA09OctyRefetch();
  //     TableDA09DocRefetch();
  //     TableDA09AssignRefetch();
  //     TableDA09AgeGrpRefetch();
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [TableDA09SexData, isTableDA09SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA09Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA09WrkYyGrpSuccess || TableDA09WrkYyGrpData === null || TableDA09WrkYyGrpData === undefined || TableDA09WrkYyGrpData.length === 0) {
  //     TableDA09WrkYyGrpRefetch();
  //     TableDA09SexRefetch();
  //     TableDA09OctyRefetch();
  //     TableDA09DocRefetch();
  //     TableDA09AssignRefetch();
  //     TableDA09AgeGrpRefetch();
  //     GraphDA09Etc3Refetch();
  //     GraphDA09Etc2Refetch();
  //     GraphDA09Etc1Refetch();
  //     GraphDA09MainDeptRefetch();
  //     GraphDA09AreaRefetch();
  //     GraphDA09SexRefetch();
  //     GraphDA09AgeGrpRefetch();
  //     GraphDA09WrkYyGrpRefetch();
  //     GraphDA09OctyRefetch();
  //     GraphDA09DocRefetch();
  //     GraphDA09NormalRefetch();
  //     GraphDA09AssignRefetch();
  //   }
  // }, [
  //   TableDA09WrkYyGrpData,
  //   isTableDA09WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA09Check,
  //   startDate,
  //   endDate,
  // ]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA09"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={changeDA09}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={RadioDA09Check}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA09NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA09AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA09DocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA09OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA09WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA09SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA09AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA09AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA09MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA09Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA09Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA09Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA09
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={RadioDA09Check}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA09NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA09AssignData;
              vTData = TableDA09AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA09DocData;
              vTData = TableDA09DocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA09OctyData;
              vTData = TableDA09OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA09WrkYyGrpData;
              vTData = TableDA09WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA09SexData;
              vTData = TableDA09SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA09AgeGrpData;
              vTData = TableDA09AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA09AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA09MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA09Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA09Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA09Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA09Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA09
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={RadioDA09Check}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA09Page;
