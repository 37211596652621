import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SyncLoader } from "react-spinners";
import Da08Chart from "../../components/mobile/chart/Da08Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da08SelListModal from "../../components/mobile/Da08SelListModal";
import Da08Table from "../../components/mobile/table/Da08Table";
// import { ageCntDict, ageDict, workCntDict, workDict } from "../../dict/mobile/dict";
import useGetDa08Res from "../../hooks/useGetDa08Res";
import {
  useComnDict,
  useDa08SelListNm,
  useDa08SelListState,
  useMenuListModalControlState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useSelListModalState,
  useUnitState,
} from "../../store/mobile/store";

const DA08 = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);
  const ageCntDict = useComnDict((state) => state.ageCntDict);
  const workCntDict = useComnDict((state) => state.workCntDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const unitState = useUnitState((state) => state.unitState);
  const menuState = useMenuState((state) => state.menuVal);
  const prjVal = usePrjValState((state) => state.prjVal);
  const selNm = useDa08SelListNm((state) => state.da08SelNmState);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const selList = useDa08SelListState((state) => state.da08SelListState);
  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);
  const setUnitState = useUnitState((state) => state.setUnitState);

  const selListModalState = useSelListModalState((state) => state.selListModalState);
  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  const [rendering, setRendering] = useState(false);

  const { refetch, data, isLoading } = useGetDa08Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, selList, startDate, endDate);

  const selData = data?.data[0];

  const initData = data?.data[1];

  /* chart data 추출 */
  let answCd = [];
  let chartData = [];

  // 사례수를 제외한 chart추출용 데이터 생성
  let chartBaseData = initData?.slice(1, initData?.length);

  chartBaseData?.forEach((el, idx) => {
    answCd.push(el.answ_CD); // data name 값 확보

    // 직종별 데이터
    let temp = [];
    if (menuState === "MEM") {
      // 비율
      if (unitState === "rate") {
        workDict.forEach((work) => {
          temp.push(el[work.sel_cd] === 0 ? null : el[work.sel_cd]);
        });
      }
      // 카운트
      else {
        workCntDict.forEach((work) => {
          temp.push(el[work.sel_cd] === 0 ? null : el[work.sel_cd]);
        });
      }
    }

    // 연령별 데이터
    else if (menuState === "VST" || menuState === "LRES" || selectQstState.svy_OJT === "CS" || selectQstState.svy_OJT === "RS") {
      // 비율
      if (unitState === "rate") {
        ageDict.forEach((age) => {
          temp.push(el[age.sel_cd] === 0 ? null : el[age.sel_cd]);
        });
      }
      // 카운트
      else {
        ageCntDict.forEach((age) => {
          temp.push(el[age.sel_cd] === 0 ? null : el[age.sel_cd]);
        });
      }
    }

    chartData.push(temp);
  });

  /**
   * table data 추출
   */
  let tableData = [];

  initData?.forEach((el, idx) => {
    let temp = [el.answ_CD];

    // 직종별 데이터
    if (menuState === "MEM") {
      // 비율
      if (unitState === "rate") {
        workDict.forEach((work) => {
          temp.push(el[work.sel_cd]);
        });
      }
      // 카운트
      else {
        workCntDict.forEach((work) => {
          temp.push(el[work.sel_cd]);
        });
      }
    }
    // 연령별 데이터
    else if (menuState === "VST" || menuState === "LRES" || selectQstState.svy_OJT === "CS" || selectQstState.svy_OJT === "RS") {
      // 비율
      if (unitState === "rate") {
        ageDict.forEach((age) => {
          temp.push(el[age.sel_cd]);
        });
      }
      // 카운트
      else {
        ageDict.forEach((age) => {
          temp.push(el[age.sel_cd]);
        });
      }
    }

    tableData.push(temp);
  });

  const handleClickSelList = () => {
    setSelListModalState(!selListModalState);
  };

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 300);
  }, [selectQstState, selList, startDate, endDate]);

  return (
    <>
      {isLoading || !rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="relative flex h-fit w-full flex-row items-center justify-between border-b border-qstTitleBorder px-2 py-3 text-[16px] font-bold text-selectTextColor">
          <BsDot className="absolute left-2 text-orangeColor" />
          <div className="hide-scrollbar relative  ml-7 flex  h-fit w-[60%] flex-row items-center justify-start overflow-auto whitespace-pre py-4 ">
            {selectQstState.keyword}
          </div>
          {selListModalState ? (
            <>
              <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                  {selNm.length > 7 ? `${selNm.slice(0, 7)}...` : selNm}
                </div>
                <IoIosArrowUp className="mr-2 text-[20px] text-orangeColor" />
              </div>
            </>
          ) : (
            <>
              <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                  {selNm.length > 7 ? `${selNm.slice(0, 7)}...` : selNm}
                </div>
                <IoIosArrowDown className="mr-2 text-[20px] text-orangeColor" />
              </div>
            </>
          )}

          <div
            className={
              selListModalState || qstListModalState
                ? `absolute right-3 top-[46px] z-10 h-[500px] w-[80%] overflow-hidden font-semibold transition-z-index ease-out`
                : `absolute right-3 top-[46px] -z-30 h-[500px] w-[80%] overflow-hidden font-semibold transition-z-index duration-1000 ease-out`
            }
          >
            <Da08SelListModal selList={selData} />
          </div>
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>
          {rendering && !qstListModalState && !isLoading ? (
            <>
              <Da08Chart data={chartData} answCd={answCd} />
              <Da08Table data={tableData} />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default DA08;
