import { useEffect } from "react";
import CheckedMenuSub from "../../components/mobile/menuList/CheckedMenuSub";
import MenuGrp from "../../components/mobile/menuList/MenuGrp";
import MenuSub from "../../components/mobile/menuList/MenuSub";
import useGetDepQstList from "../../hooks/useGetDepQstList";
import useGetMobileQstList from "../../hooks/useGetMobileQstList";
import { useMenuState, usePrjValState, useQstCdState, useQstListState, useQstState, useQstSvyOjtState } from "../../store/mobile/store";

const MenuList = () => {
  const menuState = useMenuState((state) => state.menuVal);
  const setMenuState = useMenuState((state) => state.setMenuVal);
  const prjState = usePrjValState((state) => state.prjVal);
  const qstNumState = useQstState((state) => state.qstState);
  const qstCdState = useQstCdState((state) => state.qstCdState);
  const qstSvyOjtState = useQstSvyOjtState((state) => state.qstSvyOjtState);
  const qstListState = useQstListState((state) => state.qstList);
  // const { QstListData, QstListRefetch } = useQstList(prjState.value, menuState);
  const { refetch: qstListRefetch } = useGetMobileQstList(menuState, prjState.value);
  const { refetch: depQstListRefetch } = useGetDepQstList(prjState.value);

  // 메뉴 이동 시
  useEffect(() => {
    // setQstState(0);
    if (menuState && prjState.value && menuState !== "DEP") {
      qstListRefetch();
    } else if (menuState && prjState.value) {
      depQstListRefetch();
    }
  }, [menuState, prjState]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-start bg-loginBlueColor">
      <div className="flex h-fit w-full flex-col items-center justify-start bg-loginBlueColor p-1">
        {/* 일반문항 */}
        {/* 일반문항 타이틀 */}
        {qstListState?.filter((el) => el.menu_CATE2 === "일반문항").length ? <MenuGrp value={"일반문항"} /> : null}
        {/* 일반문항 리스트 */}
        {qstListState?.map((el, idx) => {
          if (el.menu_CATE2 === "일반문항") {
            // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
            if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
              return <CheckedMenuSub key={idx} props={el} />;
            } else {
              return <MenuSub key={idx} props={el} />;
            }
          }
        })}
        {/* 교차문항 */}
        {/* 교차문항 타이틀 */}
        {qstListState?.filter((el) => el.menu_CATE2 === "교차문항").length ? <MenuGrp value={"교차문항"} /> : null}
        {/* 교차문항 리스트 */}
        {qstListState?.map((el, idx) => {
          if (el.menu_CATE2 === "교차문항") {
            // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
            if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
              return <CheckedMenuSub key={idx} props={el} />;
            } else {
              return <MenuSub key={idx} props={el} />;
            }
          }
        })}
        {/* 대상간 문항 */}
        {/* 대상간 문항 타이틀 */}
        {qstListState?.filter((el) => el.menu_CATE2 === "대상간분석").length ? <MenuGrp value={"대상간분석"} /> : null}
        {/* 대상간 문항 리스트 */}
        {qstListState?.map((el, idx) => {
          if (el.menu_CATE2 === "대상간분석") {
            // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
            if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
              return <CheckedMenuSub key={idx} props={el} />;
            } else {
              return <MenuSub key={idx} props={el} />;
            }
          }
        })}
        {/* 주관식 */}
        {/* 주관식 타이틀 */}
        {qstListState?.filter((el) => el.menu_CATE2 === "주관식").length ? <MenuGrp value={"주관식"} /> : null}
        {/* 주관식 문항 리스트 */}
        {qstListState?.map((el, idx) => {
          if (el.menu_CATE2 === "주관식") {
            // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
            if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
              return <CheckedMenuSub key={idx} props={el} />;
            } else {
              return <MenuSub key={idx} props={el} />;
            }
          }
          // 원천데이터는 익명게시판이다
          else if (el.menu_CATE2 === "원천데이터") {
            // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
            if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
              return <CheckedMenuSub key={idx} props={el} />;
            } else {
              return <MenuSub key={idx} props={el} />;
            }
          }
        })}
        {/* <CheckedMenuSub value={"선택된 문항리스트"} /> */}
      </div>
    </div>
  );
};

export default MenuList;
