import { useMutation, useQueryClient } from "@tanstack/react-query";
import postTotalMake from "../api/postTotalMake";

const usePostTotalMake = () => {
  const queryClient = useQueryClient();

  return useMutation(postTotalMake, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["word_total"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostTotalMake;
