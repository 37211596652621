import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useWindowSize from "../../../hooks/useWindowSize";
import { useUnitState } from "../../../store/mobile/store";

const CrssChart = (props) => {
  const width = useWindowSize();
  const unitState = useUnitState((state) => state.unitState);

  const options = {
    chart: {
      type: "bubble",
      zoomType: "xy",
      width: width !== 0 ? width * 0.95 : null,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      //   reversed: true,
      title: {
        text: props?.xKeyword,
        style: {
          fontSize: 10,
          fontFamily: "AppleSDGothicNeoRegular",
          color: "#888888",
          lineWidthPlus: 0,
        },
      },
      labels: {
        style: {
          fontSize: 10,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        formatter: function () {
          return props?.xCategories[this.value];
        },
      },
      gridLineWidth: 1,
      tickInterval: 1,
    },
    yAxis: {
      reversed: true,
      title: {
        text: props?.yKeyword,
        style: {
          fontSize: 10,
          fontFamily: "AppleSDGothicNeoRegular",
          color: "#888888",
          lineWidthPlus: 0,
        },
      },
      startOnTick: false,
      endOnTick: false,
      labels: {
        style: {
          fontSize: 10,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        formatter: function () {
          return props?.yCategories[this.value];
        },
      },
      tickInterval: 1,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    plotOptions: {
      bubble: {
        pointWidth: 10,
        dataLabels: {
          enabled: true,
          format: "{point.z}",
          style: {
            fontSize: 12,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
        minSize: 10,
        maxSize: 50,
      },
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<table style="width:100px">',
      pointFormat:
        "" +
        '<tr><td colspan="2" style="fontSize:13px">{point.yName} / {point.xName}</td></tr>' +
        '<tr><th>value:</th><td style="fontSize:13px">{point.z}%</td></tr>',
      footerFormat: "</table>",
      followPointer: true,
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: false,
      reversed: false,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Data",
        data: props?.data,
      },
    ],
  };

  return <>{width && props.data ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}</>;
};

export default CrssChart;
