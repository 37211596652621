import { useEffect, useState } from "react";
import { CgDanger } from "react-icons/cg";
import { HiOutlineX } from "react-icons/hi";
import { PulseLoader } from "react-spinners";
import usePostFinalGpt from "../../../hooks/usePostFinalGpt";
import usePostGpt from "../../../hooks/usePostGpt";
import usePostGptRes from "../../../hooks/usePostGptRes";
import { useFinalGptResState, useGptResState, useGptState, usePeriodState } from "../../../store/mobile/store";
import useStore from "../../../store/pc/store";

const PcGptFetchModal = (props) => {
  const { mutate: initGptMutate, isLoading: initGptLoading, isSuccess: initGptSuccess, status, isError: initGptError } = usePostGpt();
  const { mutate: finalGptMutate, isLoading: finalGptLoading, isSuccess: finalGptSuccess, isError: finalGptError } = usePostFinalGpt();
  const { mutate: GptResMutate, isLoading: GptResLoading, isSuccess: GptResIsSuccess, isError: GptResError } = usePostGptRes();

  const Pick = useStore((state) => state.Pick);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);

  const [gptProcessMsg, setGptProcessMsg] = useState("");
  // const [fetchStatus, setFetchStatus] = useState(true);

  const gptResArr = useGptResState((state) => state.data);
  const setGptResArr = useGptResState((state) => state.setData);

  const finalGptRes = useFinalGptResState((state) => state.finalData);
  const setFinalGptRes = useFinalGptResState((state) => state.setFinalData);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const fetchStatus = useGptState((state) => state.gptState);
  const setFetchStatus = useGptState((state) => state.setGptState);

  const [gptDatas, setGptDatas] = useState([]);

  const [start, setStart] = useState(false);

  const loopTimes = Math.ceil(props?.data.length / 100000);

  let gptDataArr = [];

  // console.log(initGptError, finalGptError, GptResError);

  const handleClickModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClickCancel = () => {
    props.setModal();
  };

  for (let i = 0; i < loopTimes; i++) {
    let stPoint = i * 100000;
    let edPoint = (i + 1) * 100000;

    gptDataArr.push(props?.data.slice(stPoint, edPoint));
  }

  const handleClickActivate = () => {
    console.log("sss");
    console.log(props.qstInfo);
    setStart(true);
    if (loopTimes > 1) {
      console.log("activated!");
      // 전체 str을 100000개의 길이로 자른 요소를 각각 gpt에 요청
      for (let i = 0; i < loopTimes; i++) {
        let body = {
          // model: "gpt-3.5-turbo-16k",
          model: "gpt-4o-2024-08-06", // gpt-4-1106-preview -> 업그레이드 (2024-07-31)
          messages: [
            {
              role: "system",
              content: gptDataArr[i],
            },
            {
              role: "assistant",
              // content: `컨설팅목적으로 설문조사를 실시했어
              //     설문데이터를 활용해서 병원 운영에 관련된 의미있는 데이터를 뽑으려고 해
              //     다음 주어진 의견에 대해 많이 등장하는 내용에 가중치를 두어서 500자 이내의 존대말을 사용한 한문장으로 요약해줘`,
              // content: `병원 운영 개선을 위한 설문조사를 실시했어
              //           병원 컨설턴트 입장에서 설문 답변 중 가장 많이 제시된 내용을 중심으로 400자 이내의 존대말을 사용하여 요약해줘`,
              content: `병원 운영 개선을 위한 설문조사에 다음과 같은 질문을 했습니다
                        "${props.qstInfo}"
                        해당 질문에 대한 답변내용을 잘 파악할 수 있도록 400자 이내의 존대말을 사용하여 요약해주십시오. 말을 지어내지말고 있는 그대로 요약만 해줘`,
            },
          ],
          temperature: 1,
          max_tokens: 4095,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        initGptMutate(JSON.stringify(body));
      }
    } else if (loopTimes === 1) {
      console.log("activated final process!");

      setGptProcessMsg("마지막 단계 진행중");

      // let str = "";

      // gptDatas.forEach((el) => {
      //   str = str + " " + el;
      // });

      let body = {
        // model: "gpt-3.5-turbo-16k",
        model: "gpt-4o-2024-08-06", // gpt-4-1106-preview -> 업그레이드 (2024-07-31)
        messages: [
          {
            role: "system",
            content: gptDataArr[0],
          },
          {
            role: "assistant",
            // content: `컨설팅목적으로 설문조사를 실시했어
            //     설문데이터를 활용해서 병원 운영에 관련된 의미있는 데이터를 뽑으려고 해
            //     다음 주어진 의견에 대해 많이 등장하는 내용에 가중치를 두어서 모든 의견을 400자 이내의 한국 존댓말을 사용한 친절한 어투의 한문장으로 요약해줘`,
            // content: `병원 운영 개선을 위한 설문조사를 실시했어
            //             병원 컨설턴트 입장에서 설문 답변 중 가장 많이 제시된 내용을 중심으로 400자 이내의 존대말을 사용하여 요약해줘`,
            content: `병원 운영 개선을 위한 설문조사에 다음과 같은 질문을 했습니다
                        "${props.qstInfo}"
                        해당 질문에 대한 답변내용을 잘 파악할 수 있도록 400자 이내의 존대말을 사용하여 요약해주십시오. 말을 지어내지말고 있는 그대로 요약만 해줘`,
          },
        ],
        temperature: 1,
        max_tokens: 4095,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      };

      finalGptMutate(JSON.stringify(body));
    }
  };

  useEffect(() => {
    setGptDatas([...gptDatas, ...gptResArr]);

    let processRate = Math.floor((gptDatas.length / loopTimes) * 100);
    setGptProcessMsg(`${processRate}% 진행중`);

    // console.log(gptDatas.length, loopTimes);
  }, [gptResArr]);

  useEffect(() => {
    if (gptDatas.length === loopTimes) {
      console.log("activated final process!");

      setGptProcessMsg("마지막 단계 진행중");

      let str = "";

      gptDatas.forEach((el) => {
        str = str + " " + el;
      });

      let body = {
        // model: "gpt-3.5-turbo-16k",
        model: "gpt-4o-2024-08-06", // gpt-4-1106-preview -> 업그레이드 (2024-07-31)
        messages: [
          {
            role: "system",
            content: str,
          },
          {
            role: "assistant",
            // content: `컨설팅목적으로 설문조사를 실시했어
            //     설문데이터를 활용해서 병원 운영에 관련된 의미있는 데이터를 뽑으려고 해
            //     다음 주어진 문장을 많이 등장하는 내용에 가중치를 두어서 모든 의견을 400자 이내의 한국 존댓말을 사용한 친절한 어투의 한문장으로 요약해줘`,
            // content: `병원 운영 개선을 위한 설문조사를 실시했어
            //             병원 컨설턴트 입장에서 설문 답변 중 가장 많이 제시된 내용을 중심으로 400자 이내의 존대말을 사용하여 요약해줘`,
            content: `병원 운영 개선을 위한 설문조사에 다음과 같은 질문을 했습니다
                        "${props.qstInfo}"
                        해당 질문에 대한 답변내용을 잘 파악할 수 있도록 400자 이내의 존대말을 사용하여 요약해주십시오. 말을 지어내지말고 있는 그대로 요약만 해줘`,
          },
        ],
        temperature: 1,
        max_tokens: 4095,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      };

      finalGptMutate(JSON.stringify(body));
    }
  }, [gptDatas]);

  useEffect(() => {
    let sortedFilter = props.filterOpt.sort((a, b) => a - b);

    if (finalGptRes.length !== 0) {
      if (props.filterOpt.length === props.optLength) {
        let body = {
          prj_CD: PrjCd,
          svy_OJT: SvyOjt,
          qst_CD: Pick.qst_cd,
          prd: window.location.href.toLowerCase().indexOf("csscan") > -1 ? `${startDate}-${endDate.substring(0, 10)}` : "-",
          dvs: ["-"],
          gpt_RSLT: finalGptRes[0],
        };

        GptResMutate(body);
      } else {
        let body = {
          prj_CD: PrjCd,
          svy_OJT: SvyOjt,
          qst_CD: Pick.qst_cd,
          prd: window.location.href.toLowerCase().indexOf("csscan") > -1 ? `${startDate}-${endDate.substring(0, 10)}` : "-",
          dvs: sortedFilter,
          gpt_RSLT: finalGptRes[0],
        };

        GptResMutate(body);
      }
    }
  }, [finalGptRes]);

  useEffect(() => {
    if (start) {
      if (GptResError || finalGptError || initGptError) {
        setFetchStatus(false);
      }
    }
  }, [GptResError, finalGptError, initGptError]);

  useEffect(() => {
    return () => {
      setFinalGptRes([]);
      setGptDatas([]);
      setStart(false);
      setGptResArr([]);
      setFetchStatus(200);
    };
  }, []);

  return (
    <div
      onClick={handleClickModal}
      className="relative flex h-[250px] w-[430px] flex-col items-center justify-center rounded-lg border border-modalBorderColor/60 bg-white drop-shadow-2xl"
    >
      <HiOutlineX onClick={handleClickCancel} className="absolute right-4 top-4 text-4xl text-textColor" />
      <div className="mb-6 flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[20px] font-bold text-selectTextColor/80">
        <CgDanger className="mb-10 text-[40px] text-FilterActiveColor" />
        GPT모델을 사용하여 요약정보를 받아옵니다
      </div>

      <div className="mb-6 flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] font-semibold text-selectTextColor/50">
        {start && (initGptLoading || gptDatas.length !== loopTimes || finalGptLoading) ? `(절대 창을 닫지 마세요!)` : `(최대 5분정도 소요됩니다)`}
      </div>
      {!fetchStatus ? (
        <div
          onClick={handleClickCancel}
          className="flex h-[70px] w-[280px] flex-col items-center justify-center rounded-lg bg-red-600/90 px-16 py-7 font-SDGothic text-[16px] font-bold text-white outline-none"
        >
          <div className="mb-6 h-fit w-fit text-[20px]">실패</div>
          <div className="h-fit w-fit text-white/80">(관리자에게 문의하세요)</div>
        </div>
      ) : (
        <button
          onClick={finalGptSuccess ? handleClickCancel : initGptLoading || finalGptLoading || GptResLoading ? null : handleClickActivate}
          type="button"
          className="flex h-[70px] w-[280px] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/90 px-16 py-7 font-SDGothic text-[20px] font-bold text-white outline-none"
        >
          {GptResIsSuccess ? (
            "DB 저장 완료"
          ) : start && (initGptLoading || gptDatas.length !== loopTimes || finalGptLoading) ? (
            <>
              <PulseLoader color="#ffffff" size={8} />
              <div className="mt-6 h-fit w-fit text-[14px]">{gptProcessMsg}</div>
            </>
          ) : (
            "ChatGPT 데이터 요청"
          )}
        </button>
      )}
    </div>
  );
};

export default PcGptFetchModal;
