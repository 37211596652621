import { useMutation, useQueryClient } from "@tanstack/react-query";
import patchAdminUserEtcOrder from "../api/patchAdminUserEtcOrder";

const usePatchAdminUserEtcOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(patchAdminUserEtcOrder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserEtc"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePatchAdminUserEtcOrder;
