import axios from "axios";

const getAdminAuth = (user_id) => {
  return axios.get(`/User/adminAuthorization?user_id=${user_id}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminAuth;
