import { BsDot, BsFillChatSquareTextFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useMenuListModalControlState, useQstCdState, useQstState, useQstSvyOjtState, useSelectQstState } from "../../../store/mobile/store";

const MenuSub = (props) => {
  const navigate = useNavigate();

  const setQstState = useQstState((state) => state.setQstState);
  const setQstCdState = useQstCdState((state) => state.setQstCdState);
  const setQstSvyOjtState = useQstSvyOjtState((state) => state.setQstSvyOjtState);
  const setSelectQstState = useSelectQstState((state) => state.setSelectQstState);

  const setQstModalState = useMenuListModalControlState((state) => state.setMenuListModalControl);

  const handleClickQst = () => {
    setQstState(props.props.rn);
    setQstCdState(props.props.qst_CD);
    setQstSvyOjtState(props.props.svy_OJT);

    setSelectQstState(props.props);
    setQstModalState(false);

    if (props.props.menu_CATE !== "DEP") {
      if (props.props.menu_CATE2 === "일반문항") {
        if (props.props.deep_ANA_CD === "") {
          // todo! 주관식 분기
          navigate("/m/common/default");
        } else if (props.props.deep_ANA_CD === "DA01") {
          navigate("/m/common/DA01");
        } else if (props.props.deep_ANA_CD === "DA02") {
          navigate("/m/common/DA02");
        } else if (props.props.deep_ANA_CD === "DA03") {
          navigate("/m/common/DA03");
        } else if (props.props.deep_ANA_CD === "DA04") {
          navigate("/m/common/DA04");
        } else if (props.props.deep_ANA_CD === "DA05") {
          navigate("/m/common/DA05");
        } else if (props.props.deep_ANA_CD === "DA06") {
          navigate("/m/common/DA06");
        } else if (props.props.deep_ANA_CD === "DA07") {
          navigate("/m/common/DA07");
        } else if (props.props.deep_ANA_CD === "DA08") {
          navigate("/m/common/DA08");
        } else if (props.props.deep_ANA_CD === "DA09") {
          navigate("/m/common/DA09");
        } else if (props.props.deep_ANA_CD === "DA10") {
          navigate("/m/common/DA10");
        } else if (props.props.deep_ANA_CD === "DA11") {
          navigate("/m/common/DA11");
        } else if (props.props.deep_ANA_CD === "DA12") {
          navigate("/m/common/DA12");
        }
      } else if (props.props.menu_CATE2 === "교차문항") {
        navigate("/m/common/Crss");
      } else if (props.props.menu_CATE2 === "주관식") {
        navigate("/m/common/Essay");
      }
    } else {
      navigate("/m/common/Dep");
    }
  };

  return (
    <>
      {props.props.grp_DVS === "GRP" ? (
        <div className="flex h-fit w-full flex-row items-center justify-start border-b-2 border-bdGradientStColor px-5 py-5 font-SDGothic text-[16px]   text-selectTextColor">
          <BsDot className="mr-2 text-selectTextColor" />
          <div className="h-fit w-fit">{props.props.keyword}</div>
        </div>
      ) : props.props.grp_DVS === "SUB" ? (
        <div
          onClick={handleClickQst}
          className="flex h-fit w-full flex-row items-center justify-start border-b-2 border-bdGradientStColor px-5 py-5 pl-10 font-SDGothic text-[16px]  text-selectTextColor"
        >
          <BsDot className="mr-2 text-selectTextColor" />
          <div className="flex h-fit w-fit flex-row items-center justify-center">
            {props.props.keyword}
            {props?.props.qst_TYP === "Q02" || props?.props.qst_TYP === "Q14" ? (
              <BsFillChatSquareTextFill className="ml-3 text-FilterActiveColor/50" />
            ) : null}
          </div>
        </div>
      ) : (
        <div
          onClick={handleClickQst}
          className="flex h-fit w-full flex-row items-center justify-start border-b-2 border-bdGradientStColor px-5 py-5 font-SDGothic text-[16px]  text-selectTextColor"
        >
          <BsDot className="mr-2 text-selectTextColor" />
          <div className="flex h-fit w-fit flex-row items-center justify-center">
            {props.props.keyword}
            {props?.props.qst_TYP === "Q02" || props?.props.qst_TYP === "Q14" ? (
              <BsFillChatSquareTextFill className="ml-3 text-FilterActiveColor/50" />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuSub;
