import { useEffect, useRef, useState } from "react";

const useScroll = (scrollwidth, distance) => {
  const titleDiv = useRef();
  const checkDiv = useRef([]);
  const [xPos, setXpos] = useState(0);
  const [lastTrigger, setLastTrigger] = useState(false);
  const [caresel, setCaresel] = useState(false);

  useEffect(() => {
    if (titleDiv.current) {
      titleDiv.current.style.transform = `translateX(${xPos * distance}px)`;
    }

    checkDiv.current.forEach((el) => {
      if (el) {
        el.style.transform = `translateX(${xPos * distance}px)`;
      }
    });
  }, [xPos, lastTrigger]);

  const handleClickNextBtn = () => {
    if (xPos * distance > -titleDiv.current.clientWidth + scrollwidth) {
      setXpos(xPos - 1);
    }

    if (!lastTrigger && (xPos - 1) * distance <= -titleDiv.current.clientWidth + scrollwidth) {
      setLastTrigger(true);
    }
  };

  const handleClickPrevBtn = () => {
    setLastTrigger(false);

    if (xPos < 0) {
      setXpos(xPos + 1);
    }
  };

  const handleEnterMouse = () => {
    setCaresel(true);
  };

  const handleLeaveMouse = () => {
    setCaresel(false);
  };

  return {
    titleDiv,
    checkDiv,
    xPos,
    caresel,
    handleClickNextBtn,
    handleClickPrevBtn,
    handleEnterMouse,
    handleLeaveMouse,
  };
};

export default useScroll;
