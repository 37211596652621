import { useQuery } from "@tanstack/react-query";
import getAdminExcp from "../api/getAdminExcp";

const useGetAdminExcp = (prj_cd) => {
  return useQuery(["adminExcp"], () => getAdminExcp(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminExcp;
