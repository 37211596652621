export const getSvyOjtList = (data) => {
  const result = [];

  // 플래그 변수를 사용하여 중복 방지
  let hasRS = false;
  let hasCS = false;
  let hasMEM = false;
  let hasLRES = false;
  let hasVST = false;

  data.forEach((obj) => {
    if (obj.member_YN === "Y" && !hasMEM) {
      result.push(["MEM", "구성원", "custom_member_YN", "member_YN"]);
      hasMEM = true;
    }
    if (obj.cs_YN === "Y" && !hasCS) {
      result.push(["CS", "고객만족", "custom_cs_YN", "cs_YN"]);
      hasCS = true;
    }
    if (obj.rs_YN === "Y" && !hasRS) {
      result.push(["RS", "환자경험", "custom_rs_YN", "rs_YN"]);
      hasRS = true;
    }

    if (obj.vist_YN === "Y" && !hasVST) {
      result.push(["VST", "내원고객", "custom_vist_YN", "vist_YN"]);
      hasVST = true;
    }
    if (obj.loc_RES_YN === "Y" && !hasLRES) {
      result.push(["LRES", "지역주민", "custom_loc_RES_YN", "loc_RES_YN"]);
      hasLRES = true;
    }
  });
  return result;
};
