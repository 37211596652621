import { useEffect, useState } from "react";
import { BsArrowReturnRight, BsDot } from "react-icons/bs";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { SyncLoader } from "react-spinners";
import CrssChart from "../../components/mobile/chart/CrssChart";
import Da04Chart from "../../components/mobile/chart/Da04Chart";
import Da06Chart from "../../components/mobile/chart/Da06Chart";
import DefaultChart from "../../components/mobile/chart/DefaultChart";
import TabBar from "../../components/mobile/common/TabBar";
import FilterModal from "../../components/mobile/FilterModal";
import CrssTable from "../../components/mobile/table/CrssTable";
import Da04Table from "../../components/mobile/table/Da04Table";
import Da06Table from "../../components/mobile/table/Da06Table";
import DefaultTable from "../../components/mobile/table/DefaultTable";
// import { ageDict, workDict } from "../../dict/mobile/dict";
import useGetCrssDa04Res from "../../hooks/useGetCrssDa04Res";
import useGetCrssRes from "../../hooks/useGetCrssRes";
import {
  useComnDict,
  useMenuListModalControlState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useUnitState,
} from "../../store/mobile/store";

const Crss = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const prjVal = usePrjValState((state) => state.prjVal);
  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);

  const [filterModalState, setFilterModalState] = useState(false);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const [rendering, setRendering] = useState(false);

  const [filterOpt, setFilterOpt] = useState(["ALL"]);

  const [tempFilterOpt, setTempFilterOpt] = useState(["ALL"]);

  const { refetch, data, isLoading } = useGetCrssRes(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.svy_OJT,
    selectQstState.sort_TYPE,
    startDate,
    endDate
  );

  const {
    refetch: npsRefetch,
    data: npsData,
    isLoading: npsLoading,
  } = useGetCrssDa04Res(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.svy_OJT,
    selectQstState.crss_QST_CD,
    filterOpt,
    startDate,
    endDate,
    selectQstState.deep_ANA_CD,
    selectQstState.crss_DEEP_ANA_CD,
    selectQstState.sort_TYPE
  );

  const handleClickFilter = () => {
    setFilterModalState(true);
  };

  const handleClickBg = () => {
    setFilterModalState(false);
  };

  /* ---------------------------------------------------- 일반 - 일반 데이터 추출 알고리즘------------------------------------------------------ */

  const chartInitData = data?.data[0].chart;

  const tableInitData = data?.data[0].table;

  const chartYKeyword = chartInitData ? chartInitData[0]?.keyword : "";

  const chartXKeyword = chartInitData ? chartInitData[0]?.keyword2 : "";

  /* chart series 생성 알고리즘 */

  let chartProps = chartInitData?.map((el, idx) => {
    if (unitState === "rate") {
      return {
        x: el.x - 1,
        y: el.y - 1,
        z: el.rate > 0 ? el.rate : null,
        xName: el.sel_NM,
        yName: el.sel_NM2,
        color: "#DF895F",
      };
    } else {
      return {
        x: el.x - 1,
        y: el.y - 1,
        z: el.cnt > 0 ? el.cnt : null,
        xName: el.sel_NM,
        yName: el.sel_NM2,
        color: "#DF895F",
      };
    }
  });

  /* chart categories 생성 알고리즘 */
  // x
  let chartXCategories = chartInitData?.map((el) => {
    return el.sel_NM;
  });

  let xCategoriesSet = new Set(chartXCategories);
  let uniqueXCategories = [...xCategoriesSet];

  // y
  let chartYCategories = chartInitData?.map((el) => {
    return { value: el.sel_NM2, pos: el.y };
  });

  let sortedChartY = chartYCategories ? chartYCategories.sort((a, b) => a.pos - b.pos).map((el) => el.value) : [];

  let yCategoriesSet = new Set(sortedChartY);
  let uniqueYCategories = [...yCategoriesSet];

  /* table props 생성 알고리즘 */
  // x
  let tableXCategories = tableInitData?.map((el) => {
    return el.sel_NM;
  });

  let xTableSet = new Set(tableXCategories);
  let uniqueTableX = [...xTableSet];

  // y
  let tableYCategories = tableInitData?.map((el) => {
    return el.sel_NM2;
  });

  let yTableSet = new Set(tableYCategories);
  let uniqueTableY = [...yTableSet];

  let tableProps = [];

  uniqueTableX.forEach((el, idx) => {
    let temp = [el];
    tableInitData?.forEach((data, idx2) => {
      if (data.sel_NM === el) {
        temp.push(unitState === "rate" ? data.rate : data.cnt);
      }
    });

    tableProps.push(temp);
  });

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  /* ---------------------------------------------------- NPS(일반), NPS(NPS) 데이터 추출 알고리즘------------------------------------------------------ */

  const initData = npsData?.data;

  const chartData = initData?.chart;
  const tableData = selectQstState.svy_OJT === "MEM" ? initData?.tablem : initData?.tablevl;

  // chart
  let selNm = [];
  let chartCntDb = [];
  let chartRateDb = [];

  let npsChartProps = [selNm, chartCntDb, chartRateDb];
  let npsTableProps = [];

  let tableSelNm = tableData?.map((el) => {
    if (el.answ_GRP === "A") {
      return "비추천";
    } else if (el.answ_GRP === "B") {
      return "중립";
    } else if (el.answ_GRP === "C") {
      return "추천";
    } else {
      return el.answ_GRP;
    }
  });

  if (
    (selectQstState.deep_ANA_CD === "DA04" && selectQstState.crss_DEEP_ANA_CD === "-") ||
    (selectQstState.deep_ANA_CD === "DA04" && selectQstState.crss_DEEP_ANA_CD === "DA04") ||
    (selectQstState.deep_ANA_CD === "DA04" && selectQstState.crss_DEEP_ANA_CD === "DA06")
  ) {
    chartData?.forEach((el, idx) => {
      if (idx === 0) {
        chartCntDb.push({ y: el.cnt, color: "#ECB758" });
        chartRateDb.push({ y: el.rate, color: "#ECB758" });
      } else {
        chartCntDb.push({ y: el.cnt, color: "#DF895F" });
        chartRateDb.push({ y: el.rate, color: "#DF895F" });
      }

      selNm.push(el.assign);
    });

    // table
    // 기존 A,B,C 명칭에서 비추천, 중립, 추천으로 변경

    // 구성원 관련 설문일 경우 직종별 dictionary (workDict) 를 사용하여 데이터 수집
    if (selectQstState.svy_OJT === "MEM") {
      // 직종별 Dict을 순회하며 데이터에서 직종에 해당하는 데이터를 모아서 temp에 push
      // 마지막으로 temp를 tableProps에 넣음
      workDict.forEach((el, idx) => {
        let temp = [];
        if (selNm) {
          temp.push(selNm[idx]);
        }
        if (selNm[idx]) {
          tableData?.forEach((data, idx2) => {
            // if (data[el] > 0) {
            if (idx2 === 0) {
              temp.push(data[`${el.sel_cd}_CNT`] ? data[`${el.sel_cd}_CNT`] : 0);
            } else {
              temp.push(data[el.sel_cd] ? data[el.sel_cd] : 0);
            }
            // }
          });

          npsTableProps.push(temp);
        }
      });
    }
    // 고객, 지역주민 관련 설문일 경우 연령별 dictionary (ageDict) 사용
    else if (
      selectQstState.svy_OJT === "VST" ||
      selectQstState.svy_OJT === "LRES" ||
      selectQstState.svy_OJT === "CS" ||
      selectQstState.svy_OJT === "RS"
    ) {
      ageDict.forEach((el, idx) => {
        let temp = [];
        if (selNm) {
          temp.push(selNm[idx]);
        }
        if (selNm[idx]) {
          tableData?.forEach((data) => {
            // if (data[el] > 0) {
            temp.push(data[el.sel_cd] ? data[el.sel_cd] : 0);
            // }
          });

          npsTableProps.push(temp);
        }
      });
    }
  }

  /* ---------------------------------------------------- 일반(NPS) 데이터 추출 알고리즘------------------------------------------------------ */

  // chart 컴포넌트로 보낼 props 데이터 (객체)
  let propsData = {};

  // table 컴포넌트로 보낼 props 데이터 (배열)
  let defaultTableData = [];

  // assignData
  // 보기, 문항...
  let assignData = initData?.data?.map((el) => el.assign);
  const assignSet = new Set(assignData);
  const uniqueAssignData = [...assignSet];

  // 차트에 props로 전달될 categories
  const categories = [];

  if (
    (selectQstState.deep_ANA_CD === "" && selectQstState.crss_DEEP_ANA_CD === "DA04") ||
    (selectQstState.deep_ANA_CD === "" && selectQstState.crss_DEEP_ANA_CD === "DA06")
  ) {
    // xaxis.categories
    // 카테고리 (전체, 의사직, 간호직 ...)
    let selNmData = initData?.data?.map((el) => el.sel_NM);
    const selNmSet = new Set(selNmData);
    const uniqueSelNmData = [...selNmSet]; // props : categories

    uniqueAssignData.forEach((el) => (propsData[el] = []));

    // chart props를 생성하는 알고리즘
    uniqueSelNmData?.forEach((el, idx) => {
      // 각 카테고리별 필터링한 데이터 추출
      let filteredData = initData?.data?.filter((data) => data.sel_NM === el);

      // console.log(filteredData);

      // 연령대 별, 직종 별 데이터의 값이 모두 null 값인지 판별 (차트에서 걸러주기 위한 작업)
      let tempRes = filteredData.filter((el) => +el.cnt > 0);

      // tempRes에 값이 있을 경우 (데이터가 모두 null이 아닌 경우)
      if (tempRes.length > 0) {
        // categories에 sel_NM 삽입
        categories.push(tempRes[0].sel_NM);
      }

      // 각 카테고리별 테이블 배열 생성
      let tempArr = [];
      // 카테고리 이름 배열 삽입
      tempArr.push(filteredData[0].sel_NM);

      let sum = filteredData.map((el) => +el.cnt).reduce((a, b) => a + b);
      tempArr.push(sum);

      // 보기, 문항을 돌면서 해당 값이 없으면 null, 있으면 rate 값 propsData에 push
      uniqueAssignData.forEach((el) => {
        let res = filteredData.filter((data) => data.assign === el);

        if (+res[0].rate > 0) {
          // chart data
          if (tempRes.length > 0) {
            {
              unitState === "rate" ? propsData[el].push(parseFloat(res[0].rate)) : propsData[el].push(parseFloat(res[0].cnt));
            }
          }

          // table data
          {
            unitState === "rate" ? tempArr.push(parseFloat(res[0].rate)) : tempArr.push(parseFloat(res[0].cnt));
          }
        } else {
          // chart data
          if (tempRes.length > 0) {
            propsData[el].push(null);
          }
          // table data
          tempArr.push(0);
        }
      });
      defaultTableData.push(tempArr);
    });
  }

  /* ---------------------------------------------------- 다중NPS(일반) 데이터 추출 알고리즘------------------------------------------------------ */
  // null
  /* --------------------------------------------------------------------------------------------------------------------------------- */

  /* ---------------------------------------------------- 일반(다중NPS) 데이터 추출 알고리즘------------------------------------------------------ */

  /* --------------------------------------------------------------------------------------------------------------------------------- */

  useEffect(() => {
    // nps문항일 경우
    if (
      selectQstState.deep_ANA_CD === "DA04" ||
      selectQstState.crss_DEEP_ANA_CD === "DA04" ||
      selectQstState.deep_ANA_CD === "DA06" ||
      selectQstState.crss_DEEP_ANA_CD === "DA06"
    ) {
      npsRefetch();
    } else {
      refetch();
    }

    setTimeout(() => {
      setRendering(true);
    }, 700);

    return () => {
      setRendering(false);
    };
  }, [selectQstState, startDate, endDate, filterOpt]);

  useEffect(() => {
    if (filterOpt[0] !== "ALL") {
      setFilterOpt(["ALL"]);
    }
  }, [selectQstState]);

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}

      {filterModalState ? (
        <div onClick={handleClickBg} className="fixed top-0 z-50 flex h-full w-screen flex-col items-center justify-start bg-spinnerBackColor"></div>
      ) : null}

      <FilterModal
        modalState={filterModalState}
        selData={npsData?.data.sel}
        tempFilterOpt={tempFilterOpt}
        setModalState={setFilterModalState}
        setTempFilterOpt={setTempFilterOpt}
        setFilterOpt={setFilterOpt}
        filterOpt={filterOpt}
      />

      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="relative flex h-fit w-full flex-row items-center justify-between  border-b border-qstTitleBorder px-2 py-2 text-[16px] font-bold text-selectTextColor">
          <BsDot className="absolute left-2 text-orangeColor" />
          <div className="hide-scrollbar relative  ml-7 flex  h-fit w-[60%] flex-row items-center justify-start overflow-auto whitespace-pre py-4 ">
            {selectQstState.deep_ANA_CD === "DA04" ||
            selectQstState.crss_DEEP_ANA_CD === "DA04" ||
            selectQstState.deep_ANA_CD === "DA06" ||
            selectQstState.crss_DEEP_ANA_CD === "DA06"
              ? npsData?.data && npsData?.data?.info[1]?.keyword
              : selectQstState.keyword}
          </div>

          {/* header */}
          {selectQstState.deep_ANA_CD === "DA04" ||
          selectQstState.crss_DEEP_ANA_CD === "DA04" ||
          selectQstState.deep_ANA_CD === "DA06" ||
          selectQstState.crss_DEEP_ANA_CD === "DA06" ? (
            <div className="flex h-fit w-fit flex-row items-center justify-center">
              <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                {/* {(selectOptArr.length === 7 && menuState === "MEM") || (selectOptArr.length === 8 && (menuState === "VST" || menuState === "LRES"))
                ? "전체"
                : selectOptArr.length > 1
                ? `${selectOptArr[0]}+`
                : `${selectOptArr[0]}`} */}
                답변선택
              </div>
              <HiChevronDoubleLeft onClick={handleClickFilter} className="mr-2 text-[20px] text-orangeColor" />
            </div>
          ) : null}
        </div>
        {selectQstState.deep_ANA_CD === "DA04" ||
        selectQstState.crss_DEEP_ANA_CD === "DA04" ||
        selectQstState.deep_ANA_CD === "DA06" ||
        selectQstState.crss_DEEP_ANA_CD === "DA06" ? (
          <div className="relative flex h-fit w-full flex-row items-center justify-between  border-b border-qstTitleBorder px-2 py-2 text-[16px] font-bold text-selectTextColor">
            <BsArrowReturnRight className="absolute left-4 font-bold text-orangeColor" />
            <div className="hide-scrollbar relative  ml-12 flex  h-fit w-[60%] flex-row items-center justify-start overflow-auto whitespace-pre py-4 ">
              {npsData?.data?.info[0]?.keyword}
            </div>
          </div>
        ) : null}

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              {selectQstState.deep_ANA_CD === "DA04" || selectQstState.deep_ANA_CD === "DA06" ? null : (
                <div className="flex h-fit w-fit flex-row items-center justify-center">
                  <input
                    id="rate"
                    type="radio"
                    name="radio_unit"
                    value="rate"
                    className="accent-orangeColor"
                    checked={unitState === "rate" ? true : false}
                    onChange={handleChangeRadio}
                  />
                  <label for="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                    비중(%)
                  </label>
                  <input
                    id="count"
                    type="radio"
                    name="radio_unit"
                    value="count"
                    className="ml-3 border accent-orangeColor"
                    checked={unitState === "count" ? true : false}
                    onChange={handleChangeRadio}
                  />
                  <label for="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                    인원(명)
                  </label>
                </div>
              )}
            </div>
          </div>
          {rendering &&
          !qstListModalState &&
          !isLoading &&
          selectQstState.deep_ANA_CD !== "DA04" &&
          selectQstState.deep_ANA_CD !== "DA06" &&
          selectQstState.crss_DEEP_ANA_CD !== "DA04" &&
          selectQstState.crss_DEEP_ANA_CD !== "DA06" ? (
            <>
              <CrssChart
                data={chartProps}
                xCategories={uniqueXCategories}
                yCategories={uniqueYCategories}
                xKeyword={chartXKeyword}
                yKeyword={chartYKeyword}
              />
              <CrssTable yCategories={uniqueTableY} data={tableProps} />
            </>
          ) : null}

          {(rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "DA04" && selectQstState.crss_DEEP_ANA_CD === "-") ||
          (selectQstState.deep_ANA_CD === "DA04" && selectQstState.crss_DEEP_ANA_CD === "DA04") ? (
            <>
              <Da04Chart data={npsChartProps} />
              <Da04Table data={npsTableProps} selNm={tableSelNm} />
            </>
          ) : null}

          {rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "" && selectQstState.crss_DEEP_ANA_CD === "DA04" ? (
            <>
              <DefaultChart data={propsData} assignData={uniqueAssignData} categories={categories} />
              <DefaultTable assignData={uniqueAssignData} data={defaultTableData} />
            </>
          ) : null}

          {rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "DA06" && selectQstState.crss_DEEP_ANA_CD === "-" ? (
            <>
              <Da06Chart data={selectQstState.svy_OJT === "MEM" ? npsData?.data?.rsltm : npsData?.data?.rsltvl} selList={npsData?.data?.da06Sel} />
              <Da06Table data={selectQstState.svy_OJT === "MEM" ? npsData?.data?.rsltm : npsData?.data?.rsltvl} />
            </>
          ) : null}

          {rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "" && selectQstState.crss_DEEP_ANA_CD === "DA06" ? (
            <>
              <DefaultChart data={propsData} assignData={uniqueAssignData} categories={categories} />
              <DefaultTable assignData={uniqueAssignData} data={defaultTableData} />
            </>
          ) : null}

          {rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "DA06" && selectQstState.crss_DEEP_ANA_CD === "DA06" ? (
            <>
              <Da06Chart data={selectQstState.svy_OJT === "MEM" ? npsData?.data?.rsltm : npsData?.data?.rsltvl} selList={npsData?.data?.da06Sel} />
              <Da06Table data={selectQstState.svy_OJT === "MEM" ? npsData?.data?.rsltm : npsData?.data?.rsltvl} />
            </>
          ) : null}

          {rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "DA04" && selectQstState.crss_DEEP_ANA_CD === "DA06" ? (
            <>
              <Da04Chart data={npsChartProps} />
              <Da04Table data={npsTableProps} selNm={tableSelNm} />
            </>
          ) : null}

          {rendering && !qstListModalState && !npsLoading && selectQstState.deep_ANA_CD === "DA06" && selectQstState.crss_DEEP_ANA_CD === "DA04" ? (
            <>
              <Da06Chart data={selectQstState.svy_OJT === "MEM" ? npsData?.data?.rsltm : npsData?.data?.rsltvl} selList={npsData?.data?.da06Sel} />
              <Da06Table data={selectQstState.svy_OJT === "MEM" ? npsData?.data?.rsltm : npsData?.data?.rsltvl} />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default Crss;
