import { useQuery } from "@tanstack/react-query";
import getAdminQstSvyOjt from "../api/getAdminQstSvyOjt";

const useGetAdminQstSvyOjt = (prj_cd) => {
  return useQuery(["adminSvyOjt"], () => getAdminQstSvyOjt(prj_cd), {
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminQstSvyOjt;
