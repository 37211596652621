import { useAiModeState } from "../../../store/mobile/store";

const ToggleComp = (props) => {
  const setAiMode = useAiModeState((state) => state.setAiMode);
  const aiMode = useAiModeState((state) => state.aiMode);

  const handleClickToggle = () => {
    setAiMode(!aiMode);
  };

  // console.log(aiMode);
  return (
    <div
      onClick={handleClickToggle}
      className={
        aiMode
          ? `relative flex h-[20px] w-[40px] flex-col items-center justify-center rounded-full bg-blue2Color/90 duration-300 ease-in-out`
          : `relative flex h-[20px] w-[40px] flex-col items-center justify-center rounded-full bg-FilterInActiveColor duration-300 ease-in-out`
      }
    >
      <div
        className={
          aiMode
            ? `absolute left-9 h-[15px] w-[15px] rounded-full bg-white duration-500 ease-in-out`
            : `absolute left-1 h-[15px] w-[15px] rounded-full bg-white duration-500 ease-in-out`
        }
      ></div>
    </div>
  );
};

export default ToggleComp;
