import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/pc/Loading";
import TotalSvyOjt from "../../components/pc/TotalSvyOjt";
import { menuDict } from "../../dict/pc/dict";
import { useWordTotalEtc } from "../../hooks/useWordTotalEtc";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const TotalSvyOjtPage = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const HspCd = useStore((state) => state.HspCd);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const QstHspSelCd = useStore((state) => state.QstHspSelCd);
  const [loading, setLoading] = useState(false);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isWordTotalEtcLoading, isWordTotalEtcError, WordTotalEtcData, isWordTotalEtcSuccess, WordTotalEtcRefetch } = useWordTotalEtc(
    PrjCd,
    SvyOjt,
    startDate,
    endDate
  );

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (WordTotalEtcData === undefined || WordTotalEtcData === null) {
      WordTotalEtcRefetch();
    }
  }, [WordTotalEtcData, startDate, endDate]);

  useEffect(() => {
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  const changeStat = (text) => {
    if (document.getElementById(text + "_2") !== null && document.getElementById(text + "_2") !== undefined) {
      if (document.getElementById(text + "_1").classList.contains("hidden")) {
        document.getElementById(text + "_1").classList.remove("hidden");
        document.getElementById(text + "_2").classList.remove("hidden");
        document.getElementById(text + "_0").classList.remove("bg-icon_open_png");
        document.getElementById(text + "_0").classList.add("bg-icon_close_png");
      } else {
        document.getElementById(text + "_1").classList.add("hidden");
        document.getElementById(text + "_2").classList.add("hidden");
        document.getElementById(text + "_0").classList.remove("bg-icon_close_png");
        document.getElementById(text + "_0").classList.add("bg-icon_open_png");
      }
    } else {
      if (document.getElementById(text + "_1").classList.contains("hidden")) {
        document.getElementById(text + "_1").classList.remove("hidden");
        document.getElementById(text + "_0").classList.remove("bg-icon_open_png");
        document.getElementById(text + "_0").classList.add("bg-icon_close_png");
      } else {
        document.getElementById(text + "_1").classList.add("hidden");
        document.getElementById(text + "_0").classList.remove("bg-icon_close_png");
        document.getElementById(text + "_0").classList.add("bg-icon_open_png");
      }
    }
  };

  return (
    <div className="h-full bg-white p-[1.0rem]">
      {loading ? <Loading /> : null}
      {WordTotalEtcData &&
        WordTotalEtcData !== null &&
        WordTotalEtcData !== undefined &&
        WordTotalEtcData.length > 0 &&
        WordTotalEtcData.map((data, i) => {
          if (data.head_YN === "Y") {
            return (
              <div
                key={`TotalSvyOjtDiv_${i}_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`}
                className={`float-left w-full text-[1.8rem] font-bold leading-[3.0rem] ${i !== 0 ? "mt-[2.0rem]" : ""}`}
              >
                <div className="float-left min-h-[3.0rem] w-full border-b-[0.1rem] border-orangeColor">
                  <div className="float-left w-[10.0rem]">{data.qst_CLS_NM}</div>
                  <div className="float-left w-[calc(100%-10.0rem)]" dangerouslySetInnerHTML={{ __html: data.cnte.replace(/▒/gi, "<br>") }}></div>
                </div>
              </div>
            );
          } else {
            if (data.qst_CD1 && !data.qst_CD2) {
              return (
                <div
                  key={`TotalSvyOjtDiv_${i}_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`}
                  className="float-left mt-[1.0rem] w-full text-[1.8rem] leading-[3.0rem]"
                >
                  <div className="float-left min-h-[3.0rem] w-full">
                    <div className="float-left w-[10.0rem] pr-[1.0rem] text-right">-</div>
                    <div className="float-left w-[calc(100%-11.5rem)]" dangerouslySetInnerHTML={{ __html: data.cnte.replace(/▒/gi, "<br>") }}></div>
                    <div
                      id={`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}_0`}
                      className="float-left mt-[0.7rem] h-[1.5rem] w-[1.5rem] cursor-pointer bg-icon_open_png bg-[length:100%_100%] bg-center bg-no-repeat"
                      onClick={(e) => {
                        changeStat(`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`);
                      }}
                    ></div>
                  </div>
                  <div id={`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}_1`} className="float-left hidden w-full">
                    <div className="float-left w-[10.0rem]"></div>
                    <div className="float-right w-[calc(100%-10.0rem)]">
                      <TotalSvyOjt
                        key={`TotalSvyOjt_${i}_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`}
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={data.svy_OJT1}
                        qst_cd={data.qst_CD1}
                        deep_ana_cd={data.deep_ANA_CD1}
                        where={data.type1}
                        type={data.deep_ANA_CD1 === "DA04" || data.deep_ANA_CD1 === "DA06" || data.deep_ANA_CD1 === "DA09" ? "bar" : "column"}
                        data_num={"1"}
                        title={data.title1}
                        qst_hsp_sel_cd={QstHspSelCd}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              );
            } else if (data.qst_CD1 && data.qst_CD2) {
              return (
                <div
                  key={`TotalSvyOjtDiv_${i}_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`}
                  className="float-left mt-[1.0rem] w-full text-[1.8rem] leading-[3.0rem]"
                >
                  <div className="float-left min-h-[3.0rem] w-full">
                    <div className="float-left w-[10.0rem] pr-[1.0rem] text-right">-</div>
                    <div className="float-left w-[calc(100%-11.5rem)]" dangerouslySetInnerHTML={{ __html: data.cnte.replace(/▒/gi, "<br>") }}></div>
                    <div
                      id={`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}_0`}
                      className="float-left mt-[0.7rem] h-[1.5rem] w-[1.5rem] cursor-pointer bg-icon_open_png bg-[length:100%_100%] bg-center bg-no-repeat"
                      onClick={(e) => {
                        changeStat(`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`);
                      }}
                    ></div>
                  </div>
                  <div id={`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}_1`} className="float-left hidden w-full">
                    <div className="float-left w-[10.0rem]"></div>
                    <div className="float-right w-[calc(100%-10.0rem)]">
                      <TotalSvyOjt
                        key={`TotalSvyOjt_${i}_1_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}`}
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={data.svy_OJT1}
                        qst_cd={data.qst_CD1}
                        deep_ana_cd={data.deep_ANA_CD1}
                        where={data.type1}
                        type={data.deep_ANA_CD1 === "DA04" || data.deep_ANA_CD1 === "DA06" || data.deep_ANA_CD1 === "DA09" ? "bar" : "column"}
                        data_num={"1"}
                        title={data.title1}
                        qst_hsp_sel_cd={QstHspSelCd}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                  <div
                    id={`div_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}_${data.seq}_2`}
                    className="float-left mt-[0.5rem] hidden w-full"
                  >
                    <div className="float-left w-[10.0rem]"></div>
                    <div className="float-right w-[calc(100%-10.0rem)]">
                      <TotalSvyOjt
                        key={`TotalSvyOjt_${i}_2_${PrjCd}_${data.svy_OJT2}_${data.qst_CD2}_${data.deep_ANA_CD2}_${data.seq}`}
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={data.svy_OJT2}
                        qst_cd={data.qst_CD2}
                        deep_ana_cd={data.deep_ANA_CD2}
                        where={data.type2}
                        type={data.deep_ANA_CD2 === "DA04" || data.deep_ANA_CD2 === "DA06" || data.deep_ANA_CD2 === "DA09" ? "bar" : "column"}
                        data_num={"1"}
                        title={data.title2}
                        qst_hsp_sel_cd={QstHspSelCd}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={`TotalSvyOjtDiv_${i}_${PrjCd}_${data.svy_OJT1}_${data.qst_CD1}_${data.deep_ANA_CD1}`}
                  className="float-left mt-[1.0rem] w-full text-[1.8rem] leading-[3.0rem]"
                >
                  <div className="float-left min-h-[3.0rem] w-full">
                    <div className="float-left w-[10.0rem] pr-[1.0rem] text-right">-</div>
                    <div className="float-left w-[calc(100%-11.5rem)]" dangerouslySetInnerHTML={{ __html: data.cnte.replace(/▒/gi, "<br>") }}></div>
                    <div className="float-left mt-[0.7rem] h-[1.5rem] w-[1.5rem] cursor-pointer"></div>
                  </div>
                </div>
              );
            }
          }
        })}
      <div className="float-left h-[2.0rem] w-full"></div>
    </div>
  );
};

export default TotalSvyOjtPage;
