import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useWindowSize from "../../../hooks/useWindowSize";
import { useUnitState } from "../../../store/mobile/store";

const colors = [
  "#DF895F",
  "#ECB758",
  "#98C8D7",
  "#72BAB4",
  "#63ABCB",
  "#D977A0",
  "#E19DBA",
  "#EBBA94",
  "#FFDC83",
  "#FF82FF",
];

const Da12Chart = (props) => {
  const width = useWindowSize();
  const unitState = useUnitState((state) => state.unitState);

  let exceptionArr = [];

  let seriesData = props?.series ? props?.series : [];

  for (let j = 0; j < seriesData[0]?.length; j++) {
    let sum = 0;
    for (let i = 0; i < seriesData.length; i++) {
      let val = seriesData[i][j] === null ? 0 : seriesData[i][j];
      sum += val;
    }
    if (sum === 0) {
      exceptionArr.push(j);
    }
  }

  let series = seriesData.map((el, idx) => {
    let finalDb = el.filter((data, idx2) => {
      if (!exceptionArr.includes(idx2)) {
        if (data === null) {
          return "null";
        } else {
          return data;
        }
      }
    });

    return {
      name: props?.sel_nm[idx],
      data: finalDb,
      color: colors[idx % 10],
    };
  });

  const options = {
    chart: {
      type: "bar",
      style: {},
      width: width !== 0 ? width * 0.95 : null,
      height:
        props.categories.length > 7
          ? 550
          : props.categories.length > 5
          ? 450
          : props.categories.length > 3
          ? 300
          : props.categories.length > 1
          ? 220
          : 200,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
      style: {
        fontSize: 16,
        color: "#383838",
      },
    },
    xAxis: {
      marginTop: 80,
      tickLength: 0,
      lineWidth: 0.2,
      lineColor: "#383838",

      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
        },
        y: 5,
      },
      gridLineWidth: 0,

      categories: props?.categories,
    },
    yAxis: {
      min: 0,
      max: unitState === "rate" ? 100 : null,
      tickInterval: 10,

      title: {
        text: null,
      },
      visible: false,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    series: series.reverse(),
    plotOptions: {
      series: {
        stacking: "normal",
        pointWidth: 25,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        const legendValue = series.name;

        return `<b style="font-size : 9px;">${this.x} </br> </b> <span style="color: ${color};">&#9679;</span> ${legendValue} : <b>${this.y}</b>`;
      },
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: true,
      reversed: true,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
  };

  return (
    <>
      {width && series ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : null}
    </>
  );
};

export default Da12Chart;
