import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA08 from "../../components/pc/BarColumnChartDA08";
import DA08Table from "../../components/pc/DA08Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA08AgeGrp } from "../../hooks/useGraphDA08AgeGrp";
import { useGraphDA08Area } from "../../hooks/useGraphDA08Area";
import { useGraphDA08Assign } from "../../hooks/useGraphDA08Assign";
import { useGraphDA08Doc } from "../../hooks/useGraphDA08Doc";
import { useGraphDA08Etc1 } from "../../hooks/useGraphDA08Etc1";
import { useGraphDA08Etc2 } from "../../hooks/useGraphDA08Etc2";
import { useGraphDA08Etc3 } from "../../hooks/useGraphDA08Etc3";
import { useGraphDA08MainDept } from "../../hooks/useGraphDA08MainDept";
import { useGraphDA08Normal } from "../../hooks/useGraphDA08Normal";
import { useGraphDA08Octy } from "../../hooks/useGraphDA08Octy";
import { useGraphDA08Sex } from "../../hooks/useGraphDA08Sex";
import { useGraphDA08WrkYyGrp } from "../../hooks/useGraphDA08WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA08AgeGrp } from "../../hooks/useTableDA08AgeGrp";
import { useTableDA08Assign } from "../../hooks/useTableDA08Assign";
import { useTableDA08Doc } from "../../hooks/useTableDA08Doc";
import { useTableDA08Octy } from "../../hooks/useTableDA08Octy";
import { useTableDA08Sex } from "../../hooks/useTableDA08Sex";
import { useTableDA08WrkYyGrp } from "../../hooks/useTableDA08WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA08Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [RadioDA08Check, setRadioDA08Check] = useState("");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    if (Sel !== undefined || Sel !== null || Sel.length !== 0) {
      var vFirstSel = "";
      Sel.map((dd, i) => {
        if (i === 0 && dd.value) {
          vFirstSel = dd.value;
          setRadioDA08Check(vFirstSel);
        }
      });
    }
  }, [Sel]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {}, [RadioDA08Check]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeDA08 = (text) => {
    setRadioDA08Check(text);
  };

  const { isGraphDA08NormalLoading, isGraphDA08NormalError, GraphDA08NormalData, isGraphDA08NormalSuccess, GraphDA08NormalRefetch } =
    useGraphDA08Normal(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA08AssignLoading, isGraphDA08AssignError, GraphDA08AssignData, isGraphDA08AssignSuccess, GraphDA08AssignRefetch } =
    useGraphDA08Assign(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA08DocLoading, isGraphDA08DocError, GraphDA08DocData, isGraphDA08DocSuccess, GraphDA08DocRefetch } = useGraphDA08Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA08OctyLoading, isGraphDA08OctyError, GraphDA08OctyData, isGraphDA08OctySuccess, GraphDA08OctyRefetch } = useGraphDA08Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA08WrkYyGrpLoading, isGraphDA08WrkYyGrpError, GraphDA08WrkYyGrpData, isGraphDA08WrkYyGrpSuccess, GraphDA08WrkYyGrpRefetch } =
    useGraphDA08WrkYyGrp(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA08AgeGrpLoading, isGraphDA08AgeGrpError, GraphDA08AgeGrpData, isGraphDA08AgeGrpSuccess, GraphDA08AgeGrpRefetch } =
    useGraphDA08AgeGrp(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA08SexLoading, isGraphDA08SexError, GraphDA08SexData, isGraphDA08SexSuccess, GraphDA08SexRefetch } = useGraphDA08Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA08AreaLoading, isGraphDA08AreaError, GraphDA08AreaData, isGraphDA08AreaSuccess, GraphDA08AreaRefetch } = useGraphDA08Area(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA08MainDeptLoading, isGraphDA08MainDeptError, GraphDA08MainDeptData, isGraphDA08MainDeptSuccess, GraphDA08MainDeptRefetch } =
    useGraphDA08MainDept(PrjCd, SvyOjt, QstCd, "", "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA08Etc1Loading, isGraphDA08Etc1Error, GraphDA08Etc1Data, isGraphDA08Etc1Success, GraphDA08Etc1Refetch } = useGraphDA08Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA08Etc2Loading, isGraphDA08Etc2Error, GraphDA08Etc2Data, isGraphDA08Etc2Success, GraphDA08Etc2Refetch } = useGraphDA08Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA08Etc3Loading, isGraphDA08Etc3Error, GraphDA08Etc3Data, isGraphDA08Etc3Success, GraphDA08Etc3Refetch } = useGraphDA08Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA08AgeGrpLoading, isTableDA08AgeGrpError, TableDA08AgeGrpData, isTableDA08AgeGrpSuccess, TableDA08AgeGrpRefetch } =
    useTableDA08AgeGrp(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA08AssignLoading, isTableDA08AssignError, TableDA08AssignData, isTableDA08AssignSuccess, TableDA08AssignRefetch } =
    useTableDA08Assign(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA08DocLoading, isTableDA08DocError, TableDA08DocData, isTableDA08DocSuccess, TableDA08DocRefetch } = useTableDA08Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA08OctyLoading, isTableDA08OctyError, TableDA08OctyData, isTableDA08OctySuccess, TableDA08OctyRefetch } = useTableDA08Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA08SexLoading, isTableDA08SexError, TableDA08SexData, isTableDA08SexSuccess, TableDA08SexRefetch } = useTableDA08Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    RadioDA08Check === "All" ? "" : RadioDA08Check,
    "",
    "AREA",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA08WrkYyGrpLoading, isTableDA08WrkYyGrpError, TableDA08WrkYyGrpData, isTableDA08WrkYyGrpSuccess, TableDA08WrkYyGrpRefetch } =
    useTableDA08WrkYyGrp(PrjCd, SvyOjt, QstCd, RadioDA08Check === "All" ? "" : RadioDA08Check, "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioDA08Check.length > 0 && RadioDA08Check[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA08NormalRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA08AssignRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA08DocRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA08OctyRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA08WrkYyGrpRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA08AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA08SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA08AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA08MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA08Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA08Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA08Etc3Refetch();
          }
        }
      }

      if (RadioTypeCheck === "AgeGrp") {
        console.log(RadioTypeCheck);
        GraphDA08AgeGrpRefetch();
        TableDA08AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA08SexRefetch();
        TableDA08SexRefetch();
      } else if (RadioTypeCheck === "Area") {
        GraphDA08AreaRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA08AssignRefetch();
        TableDA08AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphDA08DocRefetch();
        TableDA08DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA08OctyRefetch();
        TableDA08OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphDA08WrkYyGrpRefetch();
        TableDA08WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA08MainDeptRefetch();
      } else if (RadioTypeCheck === "Etc1") {
        GraphDA08Etc1Refetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA08NormalSuccess || GraphDA08NormalData === null || GraphDA08NormalData === undefined || GraphDA08NormalData.length === 0) {
  //     GraphDA08NormalRefetch();
  //   }
  // }, [
  //   GraphDA08NormalData,
  //   isGraphDA08NormalSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA08AssignSuccess || GraphDA08AssignData === null || GraphDA08AssignData === undefined || GraphDA08AssignData.length === 0) {
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   GraphDA08AssignData,
  //   isGraphDA08AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA08DocSuccess || GraphDA08DocData === null || GraphDA08DocData === undefined || GraphDA08DocData.length === 0) {
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08DocData, isGraphDA08DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA08OctySuccess || GraphDA08OctyData === null || GraphDA08OctyData === undefined || GraphDA08OctyData.length === 0) {
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08OctyData, isGraphDA08OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA08WrkYyGrpSuccess || GraphDA08WrkYyGrpData === null || GraphDA08WrkYyGrpData === undefined || GraphDA08WrkYyGrpData.length === 0) {
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   GraphDA08WrkYyGrpData,
  //   isGraphDA08WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA08AgeGrpSuccess || GraphDA08AgeGrpData === null || GraphDA08AgeGrpData === undefined || GraphDA08AgeGrpData.length === 0) {
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   GraphDA08AgeGrpData,
  //   isGraphDA08AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA08SexSuccess || GraphDA08SexData === null || GraphDA08SexData === undefined || GraphDA08SexData.length === 0) {
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08SexData, isGraphDA08SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA08AreaSuccess || GraphDA08AreaData === null || GraphDA08AreaData === undefined || GraphDA08AreaData.length === 0) {
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08AreaData, isGraphDA08AreaSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA08MainDeptSuccess || GraphDA08MainDeptData === null || GraphDA08MainDeptData === undefined || GraphDA08MainDeptData.length === 0) {
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   GraphDA08MainDeptData,
  //   isGraphDA08MainDeptSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isGraphDA08Etc1Success || GraphDA08Etc1Data === null || GraphDA08Etc1Data === undefined || GraphDA08Etc1Data.length === 0) {
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08Etc1Data, isGraphDA08Etc1Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA08Etc2Success || GraphDA08Etc2Data === null || GraphDA08Etc2Data === undefined || GraphDA08Etc2Data.length === 0) {
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08Etc2Data, isGraphDA08Etc2Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA08Etc3Success || GraphDA08Etc3Data === null || GraphDA08Etc3Data === undefined || GraphDA08Etc3Data.length === 0) {
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [GraphDA08Etc3Data, isGraphDA08Etc3Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA08AgeGrpSuccess || TableDA08AgeGrpData === null || TableDA08AgeGrpData === undefined || TableDA08AgeGrpData.length === 0) {
  //     TableDA08AgeGrpRefetch();
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   TableDA08AgeGrpData,
  //   isTableDA08AgeGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA08AssignSuccess || TableDA08AssignData === null || TableDA08AssignData === undefined || TableDA08AssignData.length === 0) {
  //     TableDA08AssignRefetch();
  //     TableDA08AgeGrpRefetch();
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   TableDA08AssignData,
  //   isTableDA08AssignSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   if (!isTableDA08DocSuccess || TableDA08DocData === null || TableDA08DocData === undefined || TableDA08DocData.length === 0) {
  //     TableDA08DocRefetch();
  //     TableDA08AssignRefetch();
  //     TableDA08AgeGrpRefetch();
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [TableDA08DocData, isTableDA08DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA08OctySuccess || TableDA08OctyData === null || TableDA08OctyData === undefined || TableDA08OctyData.length === 0) {
  //     TableDA08OctyRefetch();
  //     TableDA08DocRefetch();
  //     TableDA08AssignRefetch();
  //     TableDA08AgeGrpRefetch();
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [TableDA08OctyData, isTableDA08OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA08SexSuccess || TableDA08SexData === null || TableDA08SexData === undefined || TableDA08SexData.length === 0) {
  //     TableDA08SexRefetch();
  //     TableDA08OctyRefetch();
  //     TableDA08DocRefetch();
  //     TableDA08AssignRefetch();
  //     TableDA08AgeGrpRefetch();
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [TableDA08SexData, isTableDA08SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, RadioDA08Check, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA08WrkYyGrpSuccess || TableDA08WrkYyGrpData === null || TableDA08WrkYyGrpData === undefined || TableDA08WrkYyGrpData.length === 0) {
  //     TableDA08WrkYyGrpRefetch();
  //     TableDA08SexRefetch();
  //     TableDA08OctyRefetch();
  //     TableDA08DocRefetch();
  //     TableDA08AssignRefetch();
  //     TableDA08AgeGrpRefetch();
  //     GraphDA08Etc3Refetch();
  //     GraphDA08Etc2Refetch();
  //     GraphDA08Etc1Refetch();
  //     GraphDA08MainDeptRefetch();
  //     GraphDA08AreaRefetch();
  //     GraphDA08SexRefetch();
  //     GraphDA08AgeGrpRefetch();
  //     GraphDA08WrkYyGrpRefetch();
  //     GraphDA08OctyRefetch();
  //     GraphDA08DocRefetch();
  //     GraphDA08NormalRefetch();
  //     GraphDA08AssignRefetch();
  //   }
  // }, [
  //   TableDA08WrkYyGrpData,
  //   isTableDA08WrkYyGrpSuccess,
  //   PrjCd,
  //   SvyOjt,
  //   QstCd,
  //   SortType,
  //   RadioNumCheck,
  //   RadioTypeCheck,
  //   RadioDA08Check,
  //   startDate,
  //   endDate,
  // ]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA08"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={changeDA08}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={RadioDA08Check}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA08NormalData;
              } else if (data.value === "Assign") {
                vStack = "Y";
                vName = "assign";
                vData = GraphDA08AssignData;
              } else if (data.value === "Doc") {
                vStack = "Y";
                vName = "doc";
                vData = GraphDA08DocData;
              } else if (data.value === "Octy") {
                vStack = "Y";
                vName = "octy";
                vData = GraphDA08OctyData;
              } else if (data.value === "WrkYyGrp") {
                vStack = "Y";
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA08WrkYyGrpData;
              } else if (data.value === "Sex") {
                vStack = "Y";
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA08SexData;
              } else if (data.value === "AgeGrp") {
                vStack = "Y";
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA08AgeGrpData;
              } else if (data.value === "Area") {
                vStack = "Y";
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA08AreaData;
              } else if (data.value === "MainDept") {
                vStack = "Y";
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA08MainDeptData;
              } else if (data.value === "Etc1") {
                vStack = "Y";
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA08Etc1Data;
              } else if (data.value === "Etc2") {
                vStack = "Y";
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA08Etc2Data;
              } else if (data.value === "Etc3") {
                vStack = "Y";
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA08Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA08
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={RadioDA08Check}
                    sel_nm={Sel.filter((x) => x.value === RadioDA08Check).length > 0 ? Sel.filter((x) => x.value === RadioDA08Check)[0].label : ""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA08NormalData;
            } else if (data.value === "Assign") {
              vStack = "Y";
              vName = "assign";
              vData = GraphDA08AssignData;
              vTData = TableDA08AssignData;
            } else if (data.value === "Doc") {
              vStack = "Y";
              vName = "doc";
              vData = GraphDA08DocData;
              vTData = TableDA08DocData;
            } else if (data.value === "Octy") {
              vStack = "Y";
              vName = "octy";
              vData = GraphDA08OctyData;
              vTData = TableDA08OctyData;
            } else if (data.value === "WrkYyGrp") {
              vStack = "Y";
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA08WrkYyGrpData;
              vTData = TableDA08WrkYyGrpData;
            } else if (data.value === "Sex") {
              vStack = "Y";
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA08SexData;
              vTData = TableDA08SexData;
            } else if (data.value === "AgeGrp") {
              vStack = "Y";
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA08AgeGrpData;
              vTData = TableDA08AgeGrpData;
            } else if (data.value === "Area") {
              vStack = "Y";
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA08AreaData;
            } else if (data.value === "MainDept") {
              vStack = "Y";
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA08MainDeptData;
            } else if (data.value === "Etc1") {
              vStack = "Y";
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA08Etc1Data;
            } else if (data.value === "Etc2") {
              vStack = "Y";
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA08Etc2Data;
            } else if (data.value === "Etc3") {
              vStack = "Y";
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA08Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA08Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={RadioDA08Check}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA08
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={RadioDA08Check}
                    sel_nm={Sel.filter((x) => x.value === RadioDA08Check).length > 0 ? Sel.filter((x) => x.value === RadioDA08Check)[0].label : ""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA08Page;
