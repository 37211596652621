import { useQuery } from "@tanstack/react-query";
import getStatisticRes from "../api/getStatisticRes";

const useGetStatistic = (prj_cd, stDate, edDate) => {
  return useQuery(["Statistic"], () => getStatisticRes(prj_cd, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetStatistic;
