const ToggleBtn = (props) => {
  const handleClickToggle = () => {
    props.setToggle(!props.toggle);
  };

  return (
    <div
      onClick={handleClickToggle}
      className={
        props.toggle
          ? `relative mb-1 flex h-[25px] w-[50px] flex-col rounded-full bg-blue2Color/90 duration-300 ease-in-out`
          : `relative mb-1 flex h-[25px] w-[50px] flex-col rounded-full bg-FilterInActiveColor duration-300 ease-in-out`
      }
    >
      <div
        className={
          props.toggle
            ? `absolute left-[28px] top-[3px] h-[18px] w-[18px] rounded-full bg-white duration-500 ease-in-out`
            : `absolute left-[4px] top-[3px] h-[18px] w-[18px] rounded-full bg-white duration-500 ease-in-out`
        }
      ></div>
    </div>
  );
};

export default ToggleBtn;
