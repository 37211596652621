import { useEffect, useRef, useState } from "react";
import { ImMinus, ImPlus } from "react-icons/im";
import Select from "react-select";
import useDeleteAdminUserDept from "../../hooks/useDeleteAdminUserDept";
import useGetAdminUserDept from "../../hooks/useGetAdminUserDept";
import usePostAdminDept from "../../hooks/usePostAdminDept";
import usePostAdminUserDeptOrder from "../../hooks/usePostAdminUserDeptOrder";
import usePostInsertAdminDept from "../../hooks/usePostInsertAdminDept";
import { useAdminUserTabState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const dummy = [
  {
    value: "IM",
    label: "일반내과",
  },
  {
    value: "IME",
    label: "내분비내과",
  },
  {
    value: "IMN",
    label: "신장내과",
  },
  {
    value: "NR",
    label: "신경과",
  },
  {
    value: "PSY",
    label: "정신건강의학과",
  },
  {
    value: "GS",
    label: "외과",
  },
];

const dvsDict = [
  {
    value: "I",
    label: "내과계",
  },
  {
    value: "O",
    label: "외과계",
  },
  {
    value: "S",
    label: "진료지원계",
  },
  {
    value: "X",
    label: "기타",
  },
];

const AdminUserDept = () => {
  const PrjCd = useStore((state) => state.PrjCd);

  const [hoverEl, setHoverEl] = useState(0);

  const { data, refetch } = useGetAdminUserDept(PrjCd);

  const [inputMode, setInputMode] = useState(false);

  const { mutate } = usePostAdminUserDeptOrder();
  const { mutate: updateMutate } = usePostAdminDept();
  const { mutate: insertMutate } = usePostInsertAdminDept();
  const { mutate: deleteMutate } = useDeleteAdminUserDept();
  const orderRef = useRef(null);

  const setAdminUserTab = useAdminUserTabState((state) => state.setAdminUserTab);

  const handleHoverMouse = (num) => {
    setHoverEl(num);
  };

  const handleLeaveMouse = () => {
    setHoverEl(0);
  };

  const handleBlurText = (e, seq, dvs) => {
    const body = {
      prj_CD: PrjCd,
      comn_CD: dvs,
      value: e.target.value,
      seq: seq,
    };

    updateMutate(body);
    setInputMode(false);
  };

  const handleChangeSelectBar = (e, seq, dvs) => {
    const body = {
      prj_CD: PrjCd,
      comn_CD: dvs,
      value: e.value,
      seq: seq,
    };

    updateMutate(body);
    setInputMode(false);
  };

  const handleClickPlus = (seq) => {
    const body = {
      prj_CD: PrjCd,
      seq: seq,
    };

    insertMutate(body);
  };

  const handleClickMinus = (seq) => {
    const body = {
      prj_CD: PrjCd,
      seq: seq,
    };

    deleteMutate(body);
  };

  const handleBlur = (e, seq) => {
    const body = {
      prj_CD: PrjCd,
      value: e.target.value,
      origin_VALUE: seq,
    };

    if (e.target.value) {
      mutate(body);
    }

    setInputMode(false);
  };

  useEffect(() => {
    refetch();
  }, [PrjCd]);

  useEffect(() => {
    // return () => {
    //   setAdminUserTab(0);
    // };
  }, []);
  return (
    <div className="h-full w-full flex-col items-center justify-start font-SDGothic text-[16px] font-bold text-selectTextColor/80">
      {/* subTitle */}
      <div className="flex h-fit w-full flex-col items-start justify-start p-3">
        <div className="mt-10 h-fit w-fit text-[18px] font-bold">부서(방문진료과)</div>
        <div className="mt-6 h-fit w-fit text-[14px] font-bold text-selectTextColor/50">설문에 사용될 부서(방문진료과)를 설정해주세요</div>
      </div>

      {/* table header */}
      <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
        <div className="flex h-full w-[80px] flex-col items-center justify-center border-l border-white">순서</div>
        <div className="flex h-full w-[100px] flex-col items-center justify-center border-l border-white">부서코드</div>
        <div className="flex h-full w-[267px] flex-col items-center justify-center border-l border-white">부서명</div>
        <div className="flex h-full w-[120px] flex-col items-center justify-center border-l border-white">부서명(약어)</div>
        <div className="flex h-full w-[180px] flex-col items-center justify-center border-l border-white">상위부서</div>
        <div className="flex h-full w-[160px] flex-col items-center justify-center border-l border-white">진료구분</div>
        <div className="flex h-full w-[80px] flex-col items-center justify-center border-l border-white">부서레벨</div>
      </div>

      {data?.data.dept.length === 0 ? (
        <div className="flex h-fit w-full flex-col items-center justify-center">
          <div className="flex h-fit w-full flex-col items-center justify-center p-10 text-[16px] font-bold text-selectTextColor/80">
            데이터가 존재하지 않습니다
          </div>
          <button
            type="button"
            onClick={() => handleClickPlus(0)}
            className=" flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
          >
            생성
          </button>
        </div>
      ) : null}

      {/* table rows */}
      <div className="mb-10 flex h-fit w-full flex-col items-start justify-start">
        {/* row */}
        {data?.data.dept.map((el, idx) => {
          return (
            <div
              key={idx}
              onMouseEnter={() => handleHoverMouse(el.dept_SEQ)}
              onMouseLeave={() => handleLeaveMouse()}
              className="relative flex h-fit w-full flex-row items-start justify-start"
            >
              {hoverEl === el.dept_SEQ ? (
                <div className="absolute -bottom-10 right-0 z-10 flex h-fit w-fit flex-row items-center justify-end py-5">
                  <button
                    type="button"
                    onClick={() => handleClickPlus(el.dept_SEQ)}
                    className="mr-3 flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                  >
                    <ImPlus className="text-[14px] text-white" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleClickMinus(el.dept_SEQ)}
                    className="flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                  >
                    <ImMinus className="text-[14px] text-white" />
                  </button>
                </div>
              ) : null}

              {/* 순서 */}
              <input
                type="number"
                key={el.dept_SEQ}
                value={inputMode ? null : el.dept_SEQ}
                onClick={() => setInputMode(true)}
                onChange={() => {}}
                onBlur={(e) => handleBlur(e, el.dept_SEQ)}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[80px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-right leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>

              {/* 부서코드 */}
              <input
                spellCheck="false"
                // 리랜더링 이슈
                type="text"
                value={inputMode ? null : el.dept_CD.includes("Tr") ? "입력" : el.dept_CD}
                onClick={() => setInputMode(true)}
                key={el.qst + idx}
                onChange={() => {}}
                onBlur={(e) => handleBlurText(e, el.dept_SEQ, "DEPT_CD")}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[100px] resize-none flex-col items-start justify-start border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
              {/* 부서명 */}
              <input
                spellCheck="false"
                type="text"
                onClick={() => setInputMode(true)}
                value={inputMode ? null : el.dept_NM.includes("Tr") ? "입력하세요" : el.dept_NM}
                onChange={() => {}}
                onBlur={(e) => handleBlurText(e, el.dept_SEQ, "DEPT_NM")}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[267px] resize-none flex-col items-start justify-start border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
              {/* 부서명 (약어) */}
              <input
                type="text"
                spellCheck="false"
                onClick={() => setInputMode(true)}
                value={inputMode ? null : el.dept_ABBR_NM.includes("Tr") ? "입력" : el.dept_ABBR_NM}
                key={el.dept_ABBR_NM + idx}
                onBlur={(e) => handleBlurText(e, el.dept_SEQ, "DEPT_ABBR_NM")}
                onChange={() => {}}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-start justify-start border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
              {/* 상위부서코드 */}
              <div className="flex h-[60px] w-[180px] flex-row items-start justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-4 leading-snug">
                <Select
                  options={data?.data.select_LIST}
                  isSearchable={false}
                  value={data?.data.select_LIST.filter((ele) => ele.value === el.upr_DEPT_CD)}
                  onChange={(e) => handleChangeSelectBar(e, el.dept_SEQ, "UPR_DEPT_CD")}
                  className="w-[160px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#FFFFFF",
                      border: "0px",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      color: "#383838",
                    }),
                    indicatorSeparator: (styles) => ({
                      display: "none",
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: "#1686FF",
                    }),
                  }}
                />
              </div>
              {/* 진료구분 */}
              <div className="flex h-[60px] w-[160px] flex-row items-start justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-4 leading-snug">
                <Select
                  options={dvsDict}
                  isSearchable={false}
                  value={dvsDict.filter((ele) => ele.value === el.med_DVS)}
                  onChange={(e) => handleChangeSelectBar(e, el.dept_SEQ, "MED_DVS")}
                  className="w-[160px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#FFFFFF",
                      border: "0px",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      color: "#383838",
                    }),
                    indicatorSeparator: (styles) => ({
                      display: "none",
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: "#1686FF",
                    }),
                  }}
                />
              </div>
              <input
                type="number"
                spellCheck="false"
                onClick={() => setInputMode(true)}
                value={inputMode ? null : el.dept_LVL}
                onBlur={(e) => handleBlurText(e, el.dept_SEQ, "DEPT_LVL")}
                // 리랜더링 이슈
                // key={el.qst + idx}
                onChange={() => {}}
                // onBlur={(e) => handleBlurText(e, "QST")}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[80px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-right leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminUserDept;
