import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/pc/Loading";
import useStore from "../../store/pc/store";

const RawPage = () => {
  const queryClient = useQueryClient();
  const setMode = useStore((state) => state.setMode);
  const [loading, setLoading] = useState(false);
  const LoginId = useStore((state) => state.LoginId);

  const navigate = useNavigate();

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  return (
    <div className="h-full bg-[#E3F5FF]">
      {loading ? <Loading /> : null}
      Raw
    </div>
  );
};

export default RawPage;
