import { useEffect } from "react";
import { BsClipboardCheckFill, BsClipboardDataFill } from "react-icons/bs";
import { FaFolderOpen, FaPenNib, FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAdminMenuState } from "../../../store/mobile/store";

const AdminMenu = () => {
  const adminMenu = useAdminMenuState((state) => state.adminMenu);
  const setAdminMenu = useAdminMenuState((state) => state.setAdminMenu);

  const navigate = useNavigate();

  const handleClickMenu = (menu) => {
    setAdminMenu(menu);
    if (menu === 0) {
      navigate("/Admin/project");
    } else if (menu === 1) {
      navigate("/Admin/user/disp");
    } else if (menu === 2) {
      navigate("/Admin/qst/standard");
    } else if (menu === 3) {
      navigate("/Admin/qst");
    } else if (menu === 4) {
      navigate("/Admin/sentense");
    } else if (menu === 5) {
      navigate("/Admin/qst/standard/test");
    }
  };

  useEffect(() => {
    return () => setAdminMenu(0);
  }, []);

  return (
    <div className="flex h-fit w-full flex-col items-center justify-start py-10">
      {/* title */}
      <div className="mt-10 flex h-fit w-full flex-col items-center justify-center font-SDGothic text-[18px] font-bold text-white">관리자 설정</div>
      {/* nav list */}
      {/* 프로젝트 */}
      <div
        onClick={() => handleClickMenu(0)}
        className={
          adminMenu === 0
            ? `mt-24 flex h-fit w-full cursor-default flex-row items-center justify-start bg-navBlueColor px-10 font-SDGothic text-[16px] font-normal text-white`
            : `mt-24 flex h-fit w-full cursor-pointer flex-row items-center justify-start bg-loginBlueColor px-10 font-SDGothic text-[16px] font-normal text-white hover:bg-navBlueColor/40`
        }
      >
        <FaFolderOpen className="mb-2 mr-7 text-[20px]" />
        <div className="flex h-fit w-fit flex-row items-center justify-center  py-10">프로젝트</div>
      </div>
      {/* 유저 */}
      <div
        onClick={() => handleClickMenu(1)}
        className={
          adminMenu === 1
            ? `flex h-fit w-full cursor-default flex-row items-center justify-start bg-navBlueColor px-10 font-SDGothic text-[16px] font-normal text-white`
            : `flex h-fit w-full cursor-pointer flex-row items-center justify-start bg-loginBlueColor px-10 font-SDGothic text-[16px] font-normal text-white hover:bg-navBlueColor/40`
        }
      >
        <FaUser className="mb-2 mr-7 text-[20px]" />
        <div className="flex h-fit w-fit flex-row items-center justify-center  py-10">대상자 정보</div>
      </div>
      {/* 표준문항 */}
      <div
        onClick={() => handleClickMenu(2)}
        className={
          adminMenu === 2
            ? `flex h-fit w-full cursor-default flex-row items-center justify-start bg-navBlueColor px-10 font-SDGothic text-[16px] font-normal text-white`
            : `flex h-fit w-full cursor-pointer flex-row items-center justify-start bg-loginBlueColor px-10 font-SDGothic text-[16px] font-normal text-white hover:bg-navBlueColor/40`
        }
      >
        <BsClipboardDataFill className="mb-2 mr-7 text-[20px]" />
        <div className="flex h-fit w-fit flex-row items-center justify-center  py-10">표준 문항 설정</div>
      </div>
      {/* 문항 관리 */}
      <div
        onClick={() => handleClickMenu(3)}
        className={
          adminMenu === 3
            ? `flex h-fit w-full cursor-default flex-row items-center justify-start bg-navBlueColor px-10 font-SDGothic text-[16px] font-normal text-white`
            : `flex h-fit w-full cursor-pointer flex-row items-center justify-start bg-loginBlueColor px-10 font-SDGothic text-[16px] font-normal text-white hover:bg-navBlueColor/40`
        }
      >
        <BsClipboardCheckFill className="mb-2 mr-7 text-[20px]" />
        <div className="flex h-fit w-fit flex-row items-center justify-center  py-10">문항 관리</div>
      </div>
      {/* 인사말 / 마침말 */}
      <div
        onClick={() => handleClickMenu(4)}
        className={
          adminMenu === 4
            ? `flex h-fit w-full cursor-default flex-row items-center justify-start bg-navBlueColor px-10 font-SDGothic text-[16px] font-normal text-white`
            : `flex h-fit w-full cursor-pointer flex-row items-center justify-start bg-loginBlueColor px-10 font-SDGothic text-[16px] font-normal text-white hover:bg-navBlueColor/40`
        }
      >
        <FaPenNib className="mb-2 mr-7 text-[20px]" />
        <div className="flex h-fit w-fit flex-row items-center justify-center  py-10">인사말 / 마침말</div>
      </div>
    </div>
  );
};

export default AdminMenu;
