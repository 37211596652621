import { useQuery } from "@tanstack/react-query";
import getMobileQstList from "../api/getMobileQstList";
import { useQstListState } from "../store/mobile/store";

const useGetMobileQstList = (svy_ojt, prj_cd) => {
  const setQstList = useQstListState((state) => state.setQstList);

  return useQuery(["mobileQst"], () => getMobileQstList(svy_ojt, prj_cd), {
    enabled: false,
    onSuccess: (res) => {
      setQstList(res.data);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetMobileQstList;
