import axios from "axios";

const getCrssDa04Res = (prj_cd, qst_cd, svy_ojt, crss_qst_cd, filter_opt, stDate, edDate, deep_ana_cd, crss_deep_ana_cd, sort_type) => {
  return axios.get(
    `/resultM/crss/da04/rslt?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}&crss_qst_cd=${crss_qst_cd}&filter_opt=${filter_opt}&stDate=${stDate}&edDate=${edDate}&deep_ana_cd=${deep_ana_cd}&crss_deep_ana_cd=${crss_deep_ana_cd}&sort_type=${sort_type}`,
    {
      baseURL: process.env.REACT_APP_SERVER_ADDRESS,
      withCredentials: true,
    }
  );
};

export default getCrssDa04Res;
