import { useEffect } from "react";
import { BsDot } from "react-icons/bs";
import Da04Chart from "../../components/mobile/chart/Da04Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da04Table from "../../components/mobile/table/Da04Table";
// import { ageDict, workDict } from "../../dict/mobile/dict";
import useGetDa04Res from "../../hooks/useGetDa04Res";
import { useComnDict, useMenuState, usePeriodState, usePrjValState, useSelectQstState, useUnitState } from "../../store/mobile/store";

const DA04 = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const unitState = useUnitState((state) => state.unitState);
  const menuState = useMenuState((state) => state.menuVal);
  const prjVal = usePrjValState((state) => state.prjVal);
  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const { refetch, data } = useGetDa04Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, startDate, endDate);

  // console.log(data);

  const initData = data?.data[0];

  const chartData = initData?.chart;
  const tableData = initData?.table;

  // chart
  let selNm = [];
  let chartCntDb = [];
  let chartRateDb = [];

  let chartProps = [selNm, chartCntDb, chartRateDb];

  chartData?.forEach((el, idx) => {
    if (idx === 0) {
      chartCntDb.push({ y: el.cnt, color: "#ECB758" });
      chartRateDb.push({ y: el.rate, color: "#ECB758" });
    } else {
      chartCntDb.push({ y: el.cnt, color: "#DF895F" });
      chartRateDb.push({ y: el.rate, color: "#DF895F" });
    }

    selNm.push(el.assign);
  });

  // table
  // 기존 A,B,C 명칭에서 비추천, 중립, 추천으로 변경
  let tableSelNm = tableData?.map((el) => {
    if (el.answ_GRP === "A") {
      return "비추천";
    } else if (el.answ_GRP === "B") {
      return "중립";
    } else if (el.answ_GRP === "C") {
      return "추천";
    } else {
      return el.answ_GRP;
    }
  });

  let tableProps = [];

  // 구성원 관련 설문일 경우 직종별 dictionary (workDict) 를 사용하여 데이터 수집
  if (selectQstState.svy_OJT === "MEM") {
    // 직종별 Dict을 순회하며 데이터에서 직종에 해당하는 데이터를 모아서 temp에 push
    // 마지막으로 temp를 tableProps에 넣음
    workDict.forEach((el, idx) => {
      let temp = [];
      if (selNm) {
        temp.push(selNm[idx]);
      }
      if (selNm[idx]) {
        tableData?.forEach((data, idx2) => {
          // if (data[el] > 0) {
          if (idx2 === 0) {
            temp.push(data[`${el.sel_cd}_CNT`] ? data[`${el.sel_cd}_CNT`] : 0);
          } else {
            temp.push(data[el.sel_cd] ? data[el.sel_cd] : 0);
          }
          // }
        });

        tableProps.push(temp);
      }
    });
  }
  // 고객, 지역주민 관련 설문일 경우 연령별 dictionary (ageDict) 사용
  else if (
    selectQstState.svy_OJT === "VST" ||
    selectQstState.svy_OJT === "LRES" ||
    selectQstState.svy_OJT === "CS" ||
    selectQstState.svy_OJT === "RS"
  ) {
    // console.log(tableData);
    // console.log(ageDict);
    // console.log(selNm);
    ageDict.forEach((el, idx) => {
      let temp = [];
      if (selNm.includes(el.sel_nm)) {
        if (temp.length === 0) {
          temp.push(el.sel_nm);
        }
        tableData?.forEach((data) => {
          temp.push(data[el.sel_cd] ? data[el.sel_cd] : 0);
        });

        tableProps.push(temp);
      }
    });
  }

  // const handleChangeRadio = (e) => {
  //   setUnitState(e.target.value);
  // };

  useEffect(() => {
    refetch();
  }, [selectQstState, startDate, endDate]);

  return (
    <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
      <div className="relative flex h-fit w-full flex-row items-center justify-between border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
        <div className="flex h-fit w-fit flex-row items-center justify-center">
          <BsDot className="text-orangeColor" />
          {selectQstState.keyword}
        </div>
        {/* <IoIosArrowDown className="mr-2 text-[20px] text-orangeColor" /> */}
        {/* <SelListModal /> */}
      </div>

      <div className="flex h-fit w-full flex-col items-center justify-center p-3">
        {/* chart title */}
        <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
          {/* chart info */}
          <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
            {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
            {unitState === "rate" ? `응답률` : `응답률`}
          </div>
          {/* unit */}
          <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
            {/* (단위: %) */}
            <div className="flex h-fit w-fit flex-row items-center justify-center py-2">
              <label className="ml-2 flex h-fit w-fit flex-col items-center justify-center">(단위: %)</label>
            </div>
          </div>
        </div>

        <Da04Chart data={chartProps} />
        <Da04Table data={tableProps} selNm={tableSelNm} />
      </div>
      <TabBar />
    </div>
  );
};

export default DA04;
