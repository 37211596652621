import { useQuery } from "@tanstack/react-query";
import getMenuList from "../api/getMenuList";
import { useMenuList } from "../store/mobile/store";

const useGetMenuList = (prjVal) => {
  const setMenuList = useMenuList((state) => state.setMenuList);

  return useQuery(["menuList"], () => getMenuList(prjVal), {
    enabled: false,
    onSuccess: (res) => {
      // 전역상태로 저장
      let menuList = res.data.map((el) => el.svy_OJT);
      setMenuList(menuList);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useGetMenuList;
