import { useEffect, useState } from "react";

const useScrollPos = () => {
  const containerElement = document.getElementById("con");
  // console.log(containerElement);

  const [ScrollY, setScrollY] = useState(0); // 스크롤값을 저장하기 위한 상태

  const handleFollow = () => {
    setScrollY(containerElement.scrollTop); // window 스크롤 값을 ScrollY에 저장
  };

  useEffect(() => {
    containerElement?.addEventListener("scroll", handleFollow);

    return () => {
      containerElement?.removeEventListener("scroll", handleFollow); // addEventListener 함수를 삭제
    };
  }, [containerElement]);

  return ScrollY;
};

export default useScrollPos;
