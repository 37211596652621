import { useQuery } from "@tanstack/react-query";
import getAdminStandardQstSelRes from "../../../api/Admin/Standard/getAdminStandardQstSelRes";

const useGetAdminStandardQstSelRes = (AnalysisType) => {
  const fallback = [];

  const { data = fallback, isLoading } = useQuery({
    queryKey: ["adminQstStandardSelRes", AnalysisType],
    queryFn: () => getAdminStandardQstSelRes(AnalysisType),
    onSuccess: () => {
      console.log("성공함");
    },
    staleTime: 0,
    gcTime: 0,
  });

  return { data, isLoading };
};

export default useGetAdminStandardQstSelRes;
