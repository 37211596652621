import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminUserAreaOrder from "../api/postAdminUserAreaOrder";

const usePostAdminUserAreaOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminUserAreaOrder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserArea"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminUserAreaOrder;
