import axios from "axios";

const getGptRes = (prj_cd, qst_cd, svy_ojt, filterOpt, date) => {
  return axios.get(`/gpt/summerize?prj_cd=${prj_cd}&svy_ojt=${svy_ojt}&qst_cd=${qst_cd}&dvs=${filterOpt}&date=${date}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getGptRes;
