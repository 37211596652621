import axios from "axios";

const deleteQst = (data) => {
  return axios.delete("/admin/deleteQst", {
    data: data,
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
  });
};

export default deleteQst;
