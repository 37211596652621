import { useMutation, useQueryClient } from "@tanstack/react-query";
import patchAdminWork from "../api/patchAdminWork";
const usePatchAdminWork = () => {
  const queryClient = useQueryClient();

  return useMutation(patchAdminWork, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserWork"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePatchAdminWork;
