import { useEffect, useState } from "react";
import Select from "react-select";
import useDeleteAdminUserDetail from "../../../hooks/useDeleteAdminUserDetail";
import useGetAdminUserDetail from "../../../hooks/useGetAdminUserDetail";
import usePatchAdminUserDetail from "../../../hooks/usePatchAdminUserDetail";
import usePostAdminUserUser from "../../../hooks/usePostAdminUserUser";
import useStore from "../../../store/pc/store";
const assignDict = [
  {
    value: "",
    label: "-",
  },
  {
    value: "Y",
    label: "예",
  },
  {
    value: "N",
    label: "아니오",
  },
];

const AdminUserModalComp = ({ prj_cd, hsp_cd, mode, setMode, excpMode, setExcpMode, user_id, selList, vstCnt, lresCnt }) => {
  const [userId, setUserId] = useState("");
  const [svyOjt, setSvyOjt] = useState("");
  const [dept, setDept] = useState("");
  const [octy, setOcty] = useState("");
  const [octyDtl, setOctyDtl] = useState("");
  const [wrkYy, setWrkYy] = useState("");
  const [assignYn, setAssignYn] = useState("");
  const [ageGrp, setAgeGrp] = useState("");
  const [sex, setSex] = useState("");
  const [areaCd, setAreaCd] = useState("");
  const [mainDeptCd, setMainDeptCd] = useState("");
  const [io, setIo] = useState("");
  const [etc1, setEtc1] = useState("");
  const [etc2, setEtc2] = useState("");
  const [etc3, setEtc3] = useState("");
  const [status, setStatus] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [activeSvyOjt, setActiveSvyOjt] = useState(true);
  const { AnalysisType } = useStore();

  const { mutate: insertMutate, isSuccess: insertSuccess } = usePostAdminUserUser();
  const { data: userDetailData, refetch: userDetailRefetch } = useGetAdminUserDetail(prj_cd, user_id);
  const { mutate: updateMutate, isSuccess: updateSuccess } = usePatchAdminUserDetail();
  const { mutate: deleteMutate, isSuccess: deleteSuccess } = useDeleteAdminUserDetail();

  const handleClickUpdate = () => {
    const body = {
      prj_CD: prj_cd,
      user_ID: userId,
      svy_OJT: svyOjt,
      dept_CD: dept,
      octy: octy,
      octy_DTL: octyDtl,
      wrk_YY_GRP: wrkYy,
      assign_YN: assignYn,
      age_GRP: ageGrp,
      sex: sex,
      area_CD: areaCd,
      main_DEPT_CD: mainDeptCd,
      io_DVS: io,
      etc_CD1: etc1,
      etc_CD2: etc2,
      etc_CD3: etc3,
    };

    console.log("수정눌림", body);

    updateMutate(body);
  };

  console.log("userDetailData??:", userDetailData?.data, lresCnt, vstCnt, user_id);

  const handleClickSubmit = () => {
    if (svyOjt === "VST" || svyOjt === "LRES") {
      const body = {
        prj_CD: prj_cd,
        user_ID: userId,
        svy_OJT: svyOjt,
        dept_CD: dept,
        octy: octy,
        octy_DTL: octyDtl,
        wrk_YY_GRP: wrkYy,
        assign_YN: assignYn,
        age_GRP: ageGrp,
        sex: sex,
        area_CD: areaCd,
        main_DEPT_CD: mainDeptCd,
        io_DVS: io,
        etc_CD1: etc1,
        etc_CD2: etc2,
        etc_CD3: etc3,
      };

      console.log("이거출력안됨", userId, svyOjt);

      if (userId && svyOjt) {
        console.log("LRES,VST저장눌림", body);
        insertMutate(body);
      }
    } else {
      const body = {
        prj_CD: prj_cd,
        user_ID:
          svyOjt === "MEM"
            ? `${hsp_cd}${userId}`
            : svyOjt === "VST"
            ? `${hsp_cd}V${userId}`
            : svyOjt === "LRES"
            ? `${hsp_cd}L${userId}`
            : svyOjt === "CS"
            ? `${hsp_cd}C${userId}`
            : svyOjt === "RS"
            ? `${hsp_cd}R${userId}`
            : `${hsp_cd}${userId}`,
        svy_OJT: svyOjt,
        dept_CD: dept,
        octy: octy,
        octy_DTL: octyDtl,
        wrk_YY_GRP: wrkYy,
        assign_YN: assignYn,
        age_GRP: ageGrp,
        sex: sex,
        area_CD: areaCd,
        main_DEPT_CD: mainDeptCd,
        io_DVS: io,
        etc_CD1: etc1,
        etc_CD2: etc2,
        etc_CD3: etc3,
      };

      if (userId && svyOjt) {
        insertMutate(body);
      }
    }
  };

  const handleClickDelete = () => {
    if (excpMode === "VST" && vstCnt > 1) {
      alert("내원고객에 대한 설문데이터가 존재하기 때문에 삭제가 불가능합니다");
    } else if (excpMode === "LRES" && lresCnt > 1) {
      alert("지역주민에 대한 설문데이터가 존재하기 때문에 삭제가 불가능합니다");
    } else {
      const body = {
        prj_CD: prj_cd,
        user_ID: userId,
      };

      let res = window.confirm("정말로 삭제하시겠습니까?");
      if (res) {
        deleteMutate(body);
      }
    }
  };

  const handleChangeUserId = (e) => {
    setUserId(e.target.value);
  };

  const handleChangeSvyOjt = (e) => {
    setSvyOjt(e.value);
  };
  const handleChangeDept = (e) => {
    setDept(e.value);
  };
  const handleChangeOcty = (e) => {
    setOcty(e.value);
  };
  const handleChangeOctyDtl = (e) => {
    setOctyDtl(e.value);
  };
  const handleChangeWrkYy = (e) => {
    setWrkYy(e.value);
  };
  const handleChangeAssignYn = (e) => {
    setAssignYn(e.value);
  };
  const handleChangeAgeGrp = (e) => {
    setAgeGrp(e.value);
  };
  const handleChangeSex = (e) => {
    setSex(e.value);
  };
  const handleChangeAreaCd = (e) => {
    setAreaCd(e.value);
  };
  const handleChangeMainDeptCd = (e) => {
    setMainDeptCd(e.value);
  };
  const handleChangeIo = (e) => {
    setIo(e.value);
  };
  const handleChangeEtc1 = (e) => {
    setEtc1(e.value);
  };

  const handleChangeEtc2 = (e) => {
    setEtc2(e.value);
  };

  const handleChangeEtc3 = (e) => {
    setEtc3(e.value);
  };

  useEffect(() => {
    if (insertSuccess) {
      setStatus(true);

      setTimeout(() => {
        setStatus(false);
      }, 1000);
    }
  }, [insertSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      setUpdateState(true);

      setTimeout(() => {
        setUpdateState(false);
      }, 1000);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteState(true);

      setTimeout(() => {
        setDeleteState(false);
      }, 1000);
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (userDetailData?.data) {
      setUserId(userDetailData?.data[0].user_ID);
      setSvyOjt(selList?.svy_OJT.filter((el) => el.value === userDetailData?.data[0].svy_OJT)[0].label);
      setDept(userDetailData?.data[0].dept_CD);
      setOcty(userDetailData?.data[0].octy);
      setOctyDtl(userDetailData?.data[0].octy_DTL);
      setWrkYy(userDetailData?.data[0].wrk_YY_GRP);
      setAssignYn(userDetailData?.data[0].assign_YN);
      setAgeGrp(userDetailData?.data[0].age_GRP);
      setSex(userDetailData?.data[0].sex);
      setAreaCd(userDetailData?.data[0].area_CD);
      setMainDeptCd(userDetailData?.data[0].main_DEPT_CD);
      setIo(userDetailData?.data[0].io__DVS);
      setEtc1(userDetailData?.data[0].etc_CD1);
      setEtc2(userDetailData?.data[0].etc_CD2);
      setEtc3(userDetailData?.data[0].etc_CD3);
    }
  }, [userDetailData]);

  useEffect(() => {
    if (excpMode === "VST") {
      setUserId(`${hsp_cd}V`);
      setSvyOjt("내원고객");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
    } else if (excpMode === "CS") {
      setUserId(`${hsp_cd}C`);
      setSvyOjt("고객만족");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
    } else if (excpMode === "RS") {
      setUserId(`${hsp_cd}R`);
      setSvyOjt("환자경험");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
    } else if (selList?.svy_OJT.filter((el) => el.value === "LRES")[0].label) {
      setUserId(`${hsp_cd}L`);
      setSvyOjt("지역주민");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
    }
  }, [excpMode]);

  useEffect(() => {
    if (svyOjt === "VST") {
      setUserId(`${hsp_cd}V`);
      setSvyOjt("VST");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
      setActiveSvyOjt(false);
    } else if (svyOjt === "LRES") {
      setUserId(`${hsp_cd}L`);
      setSvyOjt("LRES");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
      setActiveSvyOjt(false);
    } else if (svyOjt === "CS") {
      setUserId(`${hsp_cd}C`);
      setSvyOjt("CS");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
      setActiveSvyOjt(false);
    } else if (svyOjt === "RS") {
      setUserId(`${hsp_cd}R`);
      setSvyOjt("RS");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
      setActiveSvyOjt(false);
    } else if (svyOjt === "MEM") {
      setUserId("");
      setSvyOjt("MEM");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
      setActiveSvyOjt(true);
    }
  }, [svyOjt]);

  useEffect(() => {
    if (excpMode === "" && !mode) {
      userDetailRefetch();
    } else if (excpMode === "" && mode) {
      setUserId("");
      setSvyOjt("");
      setDept("");
      setOcty("");
      setOctyDtl("");
      setWrkYy("");
      setAssignYn("");
      setAgeGrp("");
      setSex("");
      setAreaCd("");
      setMainDeptCd("");
      setIo("");
      setEtc1("");
      setEtc2("");
      setEtc3("");
    }
  }, [mode]);

  useEffect(() => {
    return () => {
      setExcpMode("");
    };
  }, []);
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-start">
      {status ? (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-100 duration-300 ease-out">
          저장되었습니다
        </div>
      ) : (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-0 duration-300 ease-out">
          저장되었습니다
        </div>
      )}

      {updateState ? (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-100 duration-300 ease-out">
          수정되었습니다
        </div>
      ) : (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-0 duration-300 ease-out">
          수정되었습니다
        </div>
      )}

      {deleteState ? (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-100 duration-300 ease-out">
          삭제되었습니다
        </div>
      ) : (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-0 duration-300 ease-out">
          삭제되었습니다
        </div>
      )}
      <div
        className={
          excpMode === ""
            ? `mt-32  flex h-fit w-full flex-row items-center justify-between`
            : `mt-10  flex h-fit w-full flex-row items-center justify-between`
        }
      >
        <div className="flex h-fit w-full flex-row items-center justify-start py-5 font-SDGothic text-[24px] font-extrabold text-selectTextColor/80">
          대상자 상세정보
        </div>
        {mode ? null : (
          <button
            type="button"
            onClick={handleClickDelete}
            className="flex h-fit w-[100px] flex-col items-center justify-center rounded-xl bg-orangeColor py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-orangeColor/70"
          >
            삭제
          </button>
        )}
      </div>
      <div className="mt-10 flex h-fit w-full flex-col items-center justify-start rounded-xl bg-FilterInActiveColor/20 px-10 py-20 font-SDGothic font-bold">
        {/* 1 row */}
        <div className=" flex h-fit w-full flex-row items-center justify-between">
          {/* input items */}
          <div className=" flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">직번(사번)</div>
            <input
              readOnly={mode && excpMode === "" && activeSvyOjt ? false : true}
              type="text"
              spellCheck={false}
              value={userId}
              onChange={handleChangeUserId}
              className="h-fit w-3/5 text-right  text-selectTextColor outline-none"
            />
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">설문대상</div>
            {mode && excpMode === "" ? (
              <Select
                options={
                  AnalysisType === "csscan"
                    ? [
                        {
                          value: "MEM",
                          label: "구성원",
                        },
                        {
                          value: "CS",
                          label: "고객만족",
                        },
                        {
                          value: "RS",
                          label: "환자경험",
                        },
                      ]
                    : [
                        {
                          value: "MEM",
                          label: "구성원",
                        },
                        {
                          value: "LRES",
                          label: "지역주민",
                        },
                        {
                          value: "VST",
                          label: "내원고객",
                        },
                      ]
                }
                isSearchable={false}
                onChange={handleChangeSvyOjt}
                value={selList?.svy_OJT.filter((el2) => el2.value === svyOjt)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={svyOjt}
                onChange={handleChangeSvyOjt}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">부서코드</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.dept}
                isSearchable={false}
                onChange={handleChangeDept}
                value={selList?.dept.filter((el2) => el2.value === dept)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={dept} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">직종</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.octy}
                isSearchable={false}
                onChange={handleChangeOcty}
                value={selList?.octy.filter((el2) => el2.value === octy)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={octy} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>
        </div>
        {/* 2 row */}
        <div className="mt-10 flex h-fit w-full flex-row items-center justify-between">
          {/* input items */}
          <div className="flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">직종상세</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.octydtl}
                isSearchable={false}
                onChange={handleChangeOctyDtl}
                value={selList?.octydtl.filter((el2) => el2.value === octyDtl)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={octyDtl}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>

          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">연차그룹</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.wrkyy}
                isSearchable={false}
                onChange={handleChangeWrkYy}
                value={selList?.wrkyy.filter((el2) => el2.value === wrkYy)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={wrkYy}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">보직여부</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={assignDict}
                isSearchable={false}
                onChange={handleChangeAssignYn}
                value={assignDict.filter((el2) => el2.value === assignYn)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={assignYn}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">연령대</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.agegrp}
                isSearchable={false}
                onChange={handleChangeAgeGrp}
                value={selList?.agegrp.filter((el2) => el2.value === ageGrp)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={ageGrp}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>
        </div>
        {/* 3 row */}
        <div className="mt-10 flex h-fit w-full flex-row items-center justify-between">
          {/* input items */}
          <div className="flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">성별</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.sex}
                isSearchable={false}
                onChange={handleChangeSex}
                value={selList?.sex.filter((el2) => el2.value === sex)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={sex} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>

          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">지역</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.area}
                isSearchable={false}
                onChange={handleChangeAreaCd}
                value={selList?.area.filter((el2) => el2.value === areaCd)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={areaCd}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">방문진료과</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.dept}
                isSearchable={false}
                onChange={handleChangeMainDeptCd}
                value={selList?.dept.filter((el2) => el2.value === mainDeptCd)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input
                readOnly
                type="text"
                spellCheck={false}
                value={mainDeptCd}
                className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none"
              />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">입원외래</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.io}
                isSearchable={false}
                onChange={handleChangeIo}
                value={selList?.io.filter((el2) => el2.value === io)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={io} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>
        </div>
        {/* 4 row */}
        <div className="mt-10 flex h-fit w-full flex-row items-center justify-start">
          {/* input items */}
          <div className="flex h-fit w-[268px] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">기타1</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.etc1}
                isSearchable={false}
                onChange={handleChangeEtc1}
                value={selList?.etc1.filter((el2) => el2.value === etc1)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={etc1} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>
          {/* input items */}
          <div className="ml-10 flex h-fit w-[268px] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">기타2</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.etc2}
                isSearchable={false}
                onChange={handleChangeEtc2}
                value={selList?.etc2.filter((el2) => el2.value === etc2)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={etc2} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>

          {/* input items */}
          <div className="ml-10 flex h-fit w-[268px] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[7px] pl-5 pr-1  text-[15px] font-bold text-selectTextColor/80">
            <div className="h-fit w-fit leading-5">기타3</div>
            {excpMode === "" && activeSvyOjt ? (
              <Select
                options={selList?.etc3}
                isSearchable={false}
                onChange={handleChangeEtc3}
                value={selList?.etc3.filter((el2) => el2.value === etc3)[0]}
                className="w-3/5 bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            ) : (
              <input readOnly type="text" spellCheck={false} value={etc3} className="h-fit w-3/5 p-3 text-right  text-selectTextColor outline-none" />
            )}
          </div>
        </div>
      </div>
      {mode ? (
        <button
          type="button"
          onClick={handleClickSubmit}
          className="mb-32 mt-32 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
        >
          저장
        </button>
      ) : excpMode === "" ? (
        <button
          type="button"
          onClick={handleClickUpdate}
          className="mb-32 mt-32 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
        >
          수정
        </button>
      ) : null}
    </div>
  );
};

export default AdminUserModalComp;
