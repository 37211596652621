import { useEffect, useRef, useState } from "react";
import { SlOptions } from "react-icons/sl";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea";
import useDeleteAdminChat from "../../../hooks/useDeleteAdminChat";
import usePatchAdminChat from "../../../hooks/usePatchAdminChat";

const AdminProChatMyComp = ({ prj_cd, content, user_id, inpt_dtm, status, seq, select, setSelect, updateChat, setUpdateChat }) => {
  const textAreaRef = useRef(null);

  const [mode, setMode] = useState(false);

  const [text, setText] = useState(content);
  useAutosizeTextArea(textAreaRef, content);

  // window.addEventListener("click", () => {
  //   setMode(false);
  // });

  const { mutate: deleteMutate } = useDeleteAdminChat();
  const { mutate: updateMutate } = usePatchAdminChat();

  const handleClickOpt = (e) => {
    e.stopPropagation();
    setSelect(seq);
  };

  const handleClickModal = (e) => {
    e.stopPropagation();
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleClickDelete = () => {
    let body = {
      prj_CD: prj_cd,
      user_ID: user_id,
      content: "",
      seq: seq,
    };

    setSelect(0);
    deleteMutate(body);
  };

  const handleClickUpdate = () => {
    setUpdateChat(select);
    setSelect(0);
    setMode(true);
  };

  const handleClickApply = () => {
    setUpdateChat(0);

    let body = {
      prj_CD: prj_cd,
      user_ID: user_id,
      content: text,
      seq: seq,
    };

    updateMutate(body);
  };

  useEffect(() => {
    return () => {
      setUpdateChat(0);
    };
  }, []);

  return (
    <div className="flex h-fit w-full flex-col items-end justify-end">
      <div
        className={
          status === "D"
            ? `relative mr-10 mt-5 flex h-fit w-[80%] flex-col  items-start justify-start rounded-b-xl rounded-l-xl bg-FilterInActiveColor/80 p-5 text-[16px] font-bold text-white`
            : `relative mr-10 mt-5 flex h-fit w-[80%] flex-col  items-start justify-start rounded-b-xl rounded-l-xl bg-[#4B9AF9] p-5 text-[16px] font-bold text-white`
        }
      >
        {status != "D" ? <SlOptions onClick={handleClickOpt} className="absolute right-7 top-5 text-[20px] text-white" /> : null}

        {select === seq && status != "D" ? (
          <div
            onClick={handleClickModal}
            className="absolute right-5 top-12 flex h-fit w-[250px] flex-row items-center justify-center rounded-md border border-FilterInActiveColor bg-white/90 p-7"
          >
            <button
              onClick={handleClickUpdate}
              type="button"
              className="flex h-fit w-fit flex-col items-center justify-center rounded-md bg-selectTextColor/50 px-8 py-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
            >
              수정
            </button>
            <button
              type="button"
              onClick={handleClickDelete}
              className="ml-6 flex h-fit w-fit flex-col items-center justify-center rounded-md bg-selectTextColor/50 px-8 py-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
            >
              삭제
            </button>
          </div>
        ) : null}

        <div className="flex h-fit w-fit flex-col items-center justify-center p-3">{prj_cd}</div>

        <div className="flex h-fit w-full flex-row items-center justify-between">
          {mode && seq === updateChat ? (
            <>
              <textarea
                spellCheck="false"
                ref={textAreaRef}
                value={text}
                onChange={handleChangeText}
                className={`hide-scrollbar my-3 flex h-fit w-[90%] resize-none flex-col items-start justify-start rounded-md bg-white/50 p-3 leading-snug text-selectTextColor/80 outline-none`}
              ></textarea>
              <button
                type="button"
                onClick={handleClickApply}
                className="ml-6 flex h-fit w-[10%] flex-col items-center justify-center rounded-md bg-selectTextColor/50 px-8 py-6 text-[16px] font-bold text-white hover:bg-selectTextColor/80"
              >
                수정
              </button>
            </>
          ) : (
            <textarea
              readOnly
              spellCheck="false"
              ref={textAreaRef}
              value={content}
              className={`hide-scrollbar flex h-fit w-[90%] resize-none flex-col items-start justify-start bg-transparent p-3 leading-snug outline-none`}
            ></textarea>
          )}
        </div>

        <div className="flex h-fit w-full flex-col items-end justify-end p-3 pb-0">{`${user_id} / ${inpt_dtm}`}</div>
      </div>
    </div>
  );
};

export default AdminProChatMyComp;
