import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      AnalysisType: "",
      setAnalysisType: (input) => set({ AnalysisType: input }),
      HspCd: "",
      setHspCd: (input) => set({ HspCd: input }),
      HspNm: "",
      setHspNm: (input) => set({ HspNm: input }),
      PrjCd: "",
      setPrjCd: (input) => set({ PrjCd: input }),
      QstHspSelCd: "",
      setQstHspSelCd: (input) => set({ QstHspSelCd: input }),
      SvyOjt: "",
      setSvyOjt: (input) => set({ SvyOjt: input }),
      LoginId: "",
      setLoginId: (input) => set({ LoginId: input }),
      Mode: "",
      setMode: (input) => set({ Mode: input }),
      Year: "",
      setYear: (input) => set({ Year: input }),
      Pick: {
        menu_cate: "",
        menu_cate2: "",
        qst_cd: "",
        deep_ana_cd: "",
        deep_qst_cd: "",
        crss_deep_ana_cd: "",
        crss_qst_cd: "",
        sort_type: "",
        grp_dvs: "", // 추세 그래프(DA06중 만족도, 진료절차를 구분하기 위해 추가 2024-07-30 유지민)
      },
      setPick: (input) => set({ Pick: input }),
      // prj List
      PrjCdArr: [{}],
      setPrjCdArr: (input) =>
        set({
          PrjCdArr: input,
        }),
    }),
    {
      name: "user",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useStore;
