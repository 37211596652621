import { useEffect, useState } from "react";
import { BsClipboardCheckFill } from "react-icons/bs";
import { ImDownload, ImMinus, ImPlus, ImUpload } from "react-icons/im";
import { Link } from "react-router-dom";
import AdminModalContainer from "../../components/pc/admin/AdminModalContainer";
import AdminOptDetailModalComp from "../../components/pc/admin/AdminOptDetailModalComp";
import AdminQstNotFoundAnimation from "../../components/pc/admin/AdminQst/AdminQstNotFoundAnimation";
import AdminQstRadio from "../../components/pc/admin/AdminQst/AdminQstRadio";
import AdminQstResultPreviewRadio from "../../components/pc/admin/AdminQst/AdminQstResultPreviewRadio";
import AdminQstTargetComp from "../../components/pc/admin/AdminQst/AdminQstTargetComp";
import AdminQstTargetDeptComp from "../../components/pc/admin/AdminQst/AdminQstTargetDeptComp";
import AdminQstDetailModalComp from "../../components/pc/admin/AdminQstDetailModalComp";
import AdminQstUploadModalComp from "../../components/pc/admin/AdminQstUploadModalComp";
import Loading from "../../components/pc/Loading";
import useGetAdminProjectList from "../../hooks/Admin/Common/useGetAdminProjectList";
import useDeleteQst from "../../hooks/useDeleteQst";
import useDeleteSel from "../../hooks/useDeleteSel";
import useGetAdminQstSelRes from "../../hooks/useGetAdminQstSelRes";
import usePatchAdminQst from "../../hooks/usePatchAdminQst";
import usePathAdminSel from "../../hooks/usePatchAdminSel";
import usePostQstOrders from "../../hooks/usePostQstOrders";
import usePostSelOrders from "../../hooks/usePostSelOrders";
import { useAdminQstSelState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const AdminQst = () => {
  const [qstTarget, setQstTarget] = useState("");
  const [optTarget, setOptTarget] = useState("");
  const PrjCd = useStore((state) => state.PrjCd);
  const [confirming, setConfirming] = useState(false); // 상태 추가: onblur 중첩 에러로인한 현재 확인 중인지 여부 토글값
  const [tempText, setTempText] = useState("");

  const [qstManageType, setQstManageType] = useState("001");

  const [hoverEl, setHoverEl] = useState("");
  const [hoverOpt, setHoverOpt] = useState([]);

  const [qstCd, setQstCd] = useState("");
  const [selCd, setSelCd] = useState("");

  // file 업로드 모달 상태
  const [uploadModal, setUploadModal] = useState(false);
  // 문항 상세보기 모달 상태
  const [detailQstModal, setDetailQstModal] = useState(false);
  // 보기 상세보기 모달 상태
  const [detailOptModal, setDetailOptModal] = useState(false);

  const adminQstSelState = useAdminQstSelState((state) => state.adminQstSelState);
  const { data: adminProjectList, isLoading: adminProjectListLoading } = useGetAdminProjectList(PrjCd);
  const sortOrder = ["MEM", "VST", "LRES", "CS", "RS"];
  const sortedAdminProjectList = adminProjectList ? [...adminProjectList].sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)) : [];

  const [projectType, setProjectType] = useState(sortedAdminProjectList[0]);
  const { refetch } = useGetAdminQstSelRes(PrjCd);

  const handleClickSubRadio = (value) => {
    setProjectType(value);
  };

  const { mutate } = usePatchAdminQst();
  const { mutate: updateSelMutate } = usePathAdminSel();
  const { mutate: postQstOrdersMutate } = usePostQstOrders();
  const { mutate: postSelOrdersMutate } = usePostSelOrders();
  const { mutate: deleteSelMutate } = useDeleteSel();

  const { mutate: deleteQstMutate } = useDeleteQst();

  console.log("추적", qstManageType, PrjCd, adminQstSelState);
  // div 영역 제외한 영역을 선택했을 때
  window.addEventListener("click", (e) => {
    if (qstTarget) {
      qstTarget.className = `relative flex h-full w-[450px] flex-row items-start justify-start border-b border-l border-FilterInActiveColor/40`;
      setQstTarget("");
      setHoverEl("a");
      setHoverOpt("a");
    } else if (optTarget) {
      optTarget.className = `relative flex h-fit w-full flex-row items-center justify-start border-b border-FilterInActiveColor/40`;
      setOptTarget("");
      setHoverEl("a");
      setHoverOpt("a");
    }
  });

  useEffect(() => {
    setQstManageType("001");
  }, []);

  const handleClickRadio = (value) => {
    setQstManageType(value);
  };

  /**문항 선택에 대한 함수 */
  const handleClickDiv = (e, qst_cd) => {
    e.stopPropagation();

    setQstCd(qst_cd);

    // 옵션 선택 시 선택된 옵션 비활성화
    if (optTarget) {
      optTarget.className = `relative flex h-fit w-full flex-row items-center justify-start border-b border-FilterInActiveColor/40`;
    }

    // 문항에 선택되어있을 때, 기존 선택된 문항 비활성화 후 지금 선택한 문항 활성화
    if (qstTarget && qstTarget !== e.currentTarget) {
      qstTarget.className = `relative flex h-full w-[450px] flex-row items-start justify-start border-b border-l border-FilterInActiveColor/40`;
      e.currentTarget.className = `relative flex h-full w-[450px] flex-row items-start justify-start border-2 border-orangeColor`;
    }
    // 선택된 문항이 없을 때
    else if (qstTarget !== e.currentTarget) {
      // 지금 선택한 문항 활성화
      e.currentTarget.className = `relative flex h-full w-[450px] flex-row items-start justify-start border-2 border-orangeColor`;
    }

    // 옵션 상태는 초기화
    setOptTarget("");
    // 문항 상태는 현재 선택한 element 할당
    setQstTarget(e.currentTarget);
    setHoverEl(qst_cd);
  };

  const handleHoverMouse = (qst_cd) => {
    setHoverEl(qst_cd);
  };

  const handleLeaveMouse = () => {
    setHoverEl("");
  };

  /**옵션 선택에 대한 함수 */
  const handleClickOpt = (e, sel_cd, qst_cd) => {
    e.stopPropagation();

    setQstCd(qst_cd);
    setSelCd(sel_cd);

    if (qstTarget) {
      qstTarget.className = `relative flex h-full w-[450px] flex-row items-start justify-start border-b border-l border-FilterInActiveColor/40`;
    }

    if (optTarget && optTarget !== e.currentTarget) {
      optTarget.className = `relative flex h-fit w-full flex-row items-center justify-start border-b border-FilterInActiveColor/40`;
      e.currentTarget.className = `relative flex h-fit w-full flex-row items-center justify-start border-2 border-orangeColor`;
    } else if (optTarget !== e.currentTarget) {
      e.currentTarget.className = `relative flex h-fit w-full flex-row items-center justify-start border-2 border-orangeColor`;
    }
    setQstTarget("");
    setOptTarget(e.currentTarget);
    setHoverOpt([sel_cd, qst_cd]);
  };

  const handleHoverOpt = (sel_cd, qst_cd) => {
    setHoverOpt([sel_cd, qst_cd]);
  };

  const handleLeaveOpt = () => {
    setHoverOpt([]);
  };

  const handleBlurText = async (e, nm) => {
    let prj_cd = PrjCd;
    let qst_cd = qstCd;
    let sel_nm = nm;
    let content = e.target.value;

    if (confirming) return; // 이미 확인 중이면 무시
    setConfirming(true); // 확인 중임을 설정

    // 값이 변경되었을때만 수정 문구 띄우기
    if (tempText !== content) {
      const check = window.confirm("해당 부분을 " + content + "로 수정하시겠습니까?");
      if (check) {
        alert("수정되었습니다.");
        mutate({ prj_CD: prj_cd, qst_CD: qst_cd, sel_NM: sel_nm, content: content, svy_OJT: projectType });
      } else {
        e.target.value = tempText;
      }
    }

    setTimeout(() => {
      setConfirming(false); // 확인 완료 후 다시 설정 해제
    }, 300); // 약간의 딜레이 후 상태를 초기화
  };

  const handleBlurSel = async (e, nm) => {
    let prj_cd = PrjCd;
    let qst_cd = qstCd;
    let sel_cd = selCd;
    let sel_nm = nm;
    let content = e.target.value;

    if (confirming) return; // 이미 확인 중이면 무시
    setConfirming(true); // 확인 중임을 설정

    // 값이 변경되었을때만 수정 문구 띄우기
    if (tempText !== content) {
      const check = window.confirm("해당 부분을 " + content + "로 수정하시겠습니까?");
      if (check) {
        alert("수정되었습니다.");
        updateSelMutate({ prj_CD: prj_cd, qst_CD: qst_cd, sel_NM: sel_nm, sel_CD: sel_cd, content: content, svy_OJT: projectType });
      } else {
        e.target.value = tempText;
      }
    }

    setTimeout(() => {
      setConfirming(false); // 확인 완료 후 다시 설정 해제
    }, 300); // 약간의 딜레이 후 상태를 초기화
  };

  const handleClickPlus = (qstNo, svyOJT) => {
    let temp = JSON.parse(JSON.stringify(adminQstSelState));

    let max = "Q001";

    temp.forEach((el) => {
      if (max < el.qst_CD) {
        max = el.qst_CD;
      }
    });

    let maxNum = +max.slice(1, max.length);

    let qst_CD = maxNum + 1 > 99 ? "Q" + (maxNum + 1) : maxNum + 1 > 9 ? "Q0" + (maxNum + 1) : "Q00" + (maxNum + 1);

    let body = {
      prj_CD: PrjCd,
      qst_CD: qst_CD,
      qst_NO: Number(qstNo) + 1,
      svy_OJT: svyOJT,
    };

    postQstOrdersMutate(body);
  };

  const handleClickOptPlus = (qst_cd, opt, sel_cd) => {
    let max = "S001";

    opt.forEach((ele) => {
      if (max < ele.sel_CD) {
        max = ele.sel_CD;
      }
    });

    let maxNum = +max.slice(1, max.length);

    let sel_CD = maxNum + 1 > 99 ? "S" + (maxNum + 1) : maxNum + 1 > 9 ? "S0" + (maxNum + 1) : "S00" + (maxNum + 1);

    let body = {
      prj_CD: PrjCd,
      qst_CD: qst_cd,
      sel_CD: sel_CD,
      sel_NO: Number(sel_cd) + 1,
      svy_OJT: projectType,
    };
    postSelOrdersMutate(body);
  };

  const handleClickMinus = (qstNo, qst_cd) => {
    let body = {
      prj_CD: PrjCd,
      qst_CD: qst_cd,
      qst_NO: Number(qstNo),
      svy_OJT: projectType,
    };

    deleteQstMutate(body);
  };

  const handleClickOptMinus = (qst_cd, sel_cd, sel_no) => {
    let body = {
      prj_CD: PrjCd,
      qst_CD: qst_cd,
      sel_CD: sel_cd,
      sel_NO: Number(sel_no),
      svy_OJT: projectType,
    };
    deleteSelMutate(body);
  };

  const handleClickFileName = () => {
    const s3BucketUrl = "https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/file/";
    const tempFileNm = "문항 및 보기 업로드 양식.xlsx";

    window.open(s3BucketUrl + tempFileNm);
  };

  useEffect(() => {
    setQstManageType("001");
    setProjectType(sortedAdminProjectList[0]);
    refetch();
  }, [PrjCd, adminProjectList]);

  if (adminProjectListLoading) return <Loading />;

  console.log("adminQstSelState 값", adminQstSelState);
  console.log("adminProjectList 값", adminProjectList);
  if (adminProjectListLoading)
    return (
      <div className="relative flex h-full min-h-[1200px] w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic text-[16px] font-normal text-selectTextColor/90">
        <Loading />
      </div>
    );

  console.log(projectType, "projectType");
  return (
    <>
      <div className="relative flex h-full min-h-[1200px] w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic text-[16px] font-normal text-selectTextColor/90">
        {/* */}

        {qstManageType === "001" && (
          <AdminQstResultPreviewRadio projectType={projectType} adminProjectList={sortedAdminProjectList} handleClickRadio={handleClickSubRadio} />
        )}
        {/* 파일 업로드 모달 */}
        {uploadModal ? (
          <AdminModalContainer setModal={setUploadModal} size="T">
            <AdminQstUploadModalComp setModal={setUploadModal} fileDvs={"Q"} />
          </AdminModalContainer>
        ) : null}
        {/* 문항 상세보기 모달 */}
        {detailQstModal ? (
          <AdminModalContainer setModal={setDetailQstModal} size="T">
            <AdminQstDetailModalComp qstCd={qstCd} projectType={projectType} />
          </AdminModalContainer>
        ) : null}
        {/* 보기 상세보기 모달 */}
        {detailOptModal ? (
          <AdminModalContainer setModal={setDetailOptModal} size="T">
            <AdminOptDetailModalComp qstCd={qstCd} selCd={selCd} projectType={projectType} />
          </AdminModalContainer>
        ) : null}
        {/* file btns */}
        <div className="absolute right-10 top-10 hidden h-fit w-fit items-center justify-center">
          {/* ec2서버 가동 시 수정 */}
          <Link to="#" onClick={handleClickFileName}>
            <button className="mr-5 flex h-fit w-[220px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80">
              <ImDownload className="mb-2 mr-3 text-[20px]" />
              Excel 양식 다운로드
            </button>
          </Link>
          <button
            onClick={() => {
              document.body.style.overflow = "hidden";
              setUploadModal(true);
            }}
            className="flex h-fit w-[220px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
          >
            <ImUpload className="mb-2 mr-3 text-[20px]" />
            Excel 파일 업로드
          </button>
        </div>
        {/* title */}
        <div className="mt-2 flex h-fit w-full flex-row items-center justify-start text-[20px] font-bold">
          <BsClipboardCheckFill className="mb-2 mr-7 text-[24px] text-orangeColor" />
          문항 관리
        </div>
        <div className=" flex h-fit w-full flex-row items-center justify-between">
          {/* 라디오 버튼 */}
          <AdminQstRadio qstManageType={qstManageType} handleClickRadio={handleClickRadio} />
        </div>
        {/* switch point */}
        {qstManageType === "001" && (
          <>
            {/* table header */}
            <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
              <div className="flex h-full w-[70px] flex-col items-center justify-center border-l border-white">순서</div>
              <div className="flex h-full w-[210px] flex-col items-center justify-center border-l border-white">문항</div>
              <div className="flex h-full w-[240px] flex-col items-center justify-center border-l border-white">키워드</div>
              <div className="flex h-full w-[130px] flex-col items-center justify-center border-l border-white">PC분석 보기</div>
              <div className="flex h-full w-[130px] flex-col items-center justify-center border-l border-white">모바일분석 보기</div>
              <div className="flex h-full w-[210px] flex-col items-center justify-center border-l border-white">설문 보기</div>
            </div>
            {/* table rows */}
            <div className="mb-10 flex h-fit w-full flex-col items-start justify-start">
              {adminQstSelState?.length === 0 && <AdminQstNotFoundAnimation />}
              {/* rows */}
              {adminQstSelState
                ?.filter((item) => item.svy_OJT === projectType)
                .map((el, idx) => {
                  return (
                    <div key={idx} className="flex h-fit w-full flex-row items-start justify-start">
                      {/* 순서 */}
                      <div className="flex h-full w-[70px] flex-row items-start justify-end border-b border-FilterInActiveColor/40 p-7 leading-snug">
                        {idx + 1}
                      </div>
                      {/* 문항 섹션 */}
                      <div
                        onClick={(event) => handleClickDiv(event, el.qst_CD)}
                        onMouseEnter={(e) => (e.currentTarget === qstTarget ? handleHoverMouse(el.qst_CD) : null)}
                        onMouseLeave={(e) => (e.currentTarget === qstTarget ? handleLeaveMouse(el.qst_CD) : null)}
                        className="relative flex h-full w-[450px] flex-row items-start justify-start border-b border-l border-FilterInActiveColor/40"
                      >
                        {/* 옵션 모달 */}
                        {hoverEl === el.qst_CD ? (
                          <div className="absolute -bottom-20 right-0 z-10 flex h-fit w-fit flex-row items-center justify-end py-5">
                            <button
                              type="button"
                              onClick={() => handleClickPlus(el.qst_NO, el.svy_OJT)}
                              className="mr-3 flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                            >
                              <ImPlus className="text-[14px] text-white" />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleClickMinus(el.qst_NO, el.qst_CD)}
                              className="flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                            >
                              <ImMinus className="text-[14px] text-white" />
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                document.body.style.overflow = "hidden";
                                setDetailQstModal(true);
                              }}
                              className="ml-3 flex h-[25px] w-fit flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 px-4 pt-3 text-[14px] font-bold text-white hover:bg-orangeColor/80"
                            >
                              상세정보
                            </button>
                          </div>
                        ) : null}

                        <textarea
                          spellCheck="false"
                          // 리랜더링 이슈
                          defaultValue={el.qst}
                          key={el.qst + idx}
                          onFocus={(e) => setTempText(e.target.value)}
                          onBlur={(e) => handleBlurText(e, "QST")}
                          className="hide-scrollbar focus:bg-orangeColor/15 flex h-full min-h-[60px] w-[210px] resize-none flex-col items-start justify-start border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                        ></textarea>
                        <textarea
                          spellCheck="false"
                          // 리랜더링 이슈
                          defaultValue={el.keyword}
                          key={idx + el.keyword}
                          onFocus={(e) => setTempText(e.target.value)}
                          onBlur={(e) => handleBlurText(e, "KEYWORD")}
                          className="hide-scrollbar flex h-full min-h-[60px] w-[240px] resize-none flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                        ></textarea>
                      </div>
                      {/* 보기 섹션 */}
                      <div className="flex h-fit w-[470px] flex-col items-start justify-start ">
                        {/* 보기 row */}
                        {el.opt.map((optEl, optIdx) => {
                          return (
                            <div
                              key={optIdx}
                              onMouseEnter={(e) => (e.currentTarget === optTarget ? handleHoverOpt(optEl.sel_CD, el.qst_CD) : null)}
                              onMouseLeave={(e) => (e.currentTarget === optTarget ? handleLeaveOpt(optEl.sel_CD, el.qst_CD) : null)}
                              onClick={(event) => handleClickOpt(event, optEl.sel_CD, el.qst_CD)}
                              className="relative flex h-fit w-full flex-row items-center justify-start border-b border-FilterInActiveColor/40"
                            >
                              {/* 옵션 모달 */}
                              {hoverOpt[0] === optEl.sel_CD && hoverOpt[1] === el.qst_CD ? (
                                <div className="absolute -bottom-20 right-0 z-10 flex h-fit w-fit flex-row items-center justify-end py-5">
                                  <button
                                    type="button"
                                    onClick={() => handleClickOptPlus(el.qst_CD, el.opt, optEl.seq)}
                                    className="mr-3 flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                                  >
                                    <ImPlus className="text-[14px] text-white" />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleClickOptMinus(el.qst_CD, optEl.sel_CD, optEl.seq)}
                                    className="flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                                  >
                                    <ImMinus className="text-[14px] text-white" />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      document.body.style.overflow = "hidden";
                                      setDetailOptModal(true);
                                    }}
                                    className="ml-3 flex h-[25px] w-fit flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 px-4 pt-3 text-[14px] font-bold text-white hover:bg-orangeColor/80"
                                  >
                                    상세정보
                                  </button>
                                </div>
                              ) : null}

                              <textarea
                                spellCheck="false"
                                defaultValue={optEl.sel_NM}
                                key={optEl.sel_CD + optEl.sel_NM}
                                onFocus={(e) => setTempText(e.target.value)}
                                onBlur={(e) => handleBlurSel(e, "sel_NM")}
                                className="hide-scrollbar flex h-full min-h-[60px] w-[130px] resize-none flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                              ></textarea>
                              <textarea
                                spellCheck="false"
                                defaultValue={optEl.sel_NM_M}
                                key={optEl.sel_NM_M + optEl.sel_CD}
                                onFocus={(e) => setTempText(e.target.value)}
                                onBlur={(e) => handleBlurSel(e, "sel_NM_M")}
                                className="hide-scrollbar flex h-full min-h-[60px] w-[128px] resize-none flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                              ></textarea>
                              <textarea
                                spellCheck="false"
                                defaultValue={optEl.sel_NM_SVY}
                                key={optEl.sel_NM_SVY + el.seq}
                                onFocus={(e) => setTempText(e.target.value)}
                                onBlur={(e) => handleBlurSel(e, "sel_NM_SVY")}
                                className="hide-scrollbar flex h-full min-h-[60px] w-[208px] resize-none flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                              ></textarea>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {qstManageType === "002" && <AdminQstTargetComp />}
        {qstManageType === "003" && <AdminQstTargetDeptComp />}
      </div>
    </>
  );
};

export default AdminQst;
