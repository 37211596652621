const Prev_icon = (props) => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57227 15.7139L1.85798 8.64244L9.57226 1.57101"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
        opacity={props.active ? `1` : `0.3`}
      />
    </svg>
  );
};

export default Prev_icon;
