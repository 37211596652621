import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminGuide from "../api/postAdminGuide";

const usePostAdminGuide = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminGuide, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminGuide"]);
      alert("생성되었습니다");
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminGuide;
