import { BsCheckLg } from "react-icons/bs";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import useScroll from "../../../../hooks/Admin/AdminQst/useScroll";
import useGetAdminProjectList from "../../../../hooks/Admin/Common/useGetAdminProjectList";
import useDeleteTarget from "../../../../hooks/useDeleteTarget";
import useGetAdminExcp from "../../../../hooks/useGetAdminExcp";
import useGetAdminTargetSelNm from "../../../../hooks/useGetAdminTargetSelNm";
import usePostTarget from "../../../../hooks/usePostTarget";
import useStore from "../../../../store/pc/store";
import { makeDeptTableData } from "../../../../util/pc/makeTableData";
import AdminQstNotFoundAnimation from "./AdminQstNotFoundAnimation";

const AdminQstTargetDeptComp = () => {
  const { PrjCd, AnalysisType } = useStore();
  const { titleDiv, checkDiv, caresel, handleClickNextBtn, handleClickPrevBtn, handleEnterMouse, handleLeaveMouse } = useScroll(640, 80);

  checkDiv.current = [];

  //ANCHOR - API 요청 영역
  const { data: selNmAndQst, isLoading: isSelNmLoading } = useGetAdminTargetSelNm(PrjCd);
  const { data: excp, isLoading: isExcpLoading } = useGetAdminExcp(PrjCd);
  const { data: adminProjectList, isLoading: adminProjectListLoading } = useGetAdminProjectList(PrjCd);
  const { mutate: postMutate } = usePostTarget();
  const { mutate: deleteMutate } = useDeleteTarget();

  //ANCHOR - API DATA 가공 영역
  if (isSelNmLoading || isExcpLoading || adminProjectListLoading) return <div>loading...</div>;
  const { qst, sel_NM } = selNmAndQst.data;
  const vst_dept_sel_nm = sel_NM.filter((item) => item.dvs_CD === "VST" && item.comn_CD !== "I" && item.comn_CD !== "O");
  let excpData;
  if (AnalysisType === "csscan") {
    excpData = excp.data.filter((item) => item.svy_OJT === "CS" || item.svy_OJT === "RS");
  } else {
    excpData = excp.data.filter((item) => item.svy_OJT === "VST" && item.io_DVS !== "I" && item.io_DVS !== "O");
  }
  const tableData = makeDeptTableData(qst, vst_dept_sel_nm, excpData, AnalysisType, adminProjectList);
  // console.log("tableData", tableData);
  console.log("adminProjectList", adminProjectList);

  const handleClickCheck = (checked, comn_CD, qst_CD, AnalysisType, svy_OJT) => {
    let body;
    if (AnalysisType === "csscan") {
      body = {
        prj_CD: PrjCd,
        qst_CD: qst_CD,
        svy_OJT: svy_OJT, // 여기 CSSCAN 모드면 RS,CS로 들어가야함
        sel_NM: svy_OJT,
        value: comn_CD,
      };
    } else {
      body = {
        prj_CD: PrjCd,
        qst_CD: qst_CD,
        svy_OJT: "VST", // 여기 CSSCAN 모드면 RS,CS로 들어가야함
        sel_NM: "MAIN_DEPT_CD",
        value: comn_CD,
      };
    }

    console.log("body", body);
    if (checked) {
      postMutate(body);
    } else {
      deleteMutate(body);
    }
  };
  // ANCHOR 체크갯수가 0개인경우 해당없음체크로직
  // const handleClickListCheck = (listChecked, qst_CD) => {
  //   let body = {
  //     prj_CD: PrjCd,
  //     qst_CD: qst_CD,
  //     svy_OJT: "VST", // 여기 CSSCAN 모드면 RS,CS로 들어가야함
  //     sel_NM: "All_Main_Dept_Cd",
  //     value: "",
  //   };
  //   console.log(listChecked, qst_CD, "??");

  //   if (listChecked) {
  //     // 아무일도 x
  //     return;
  //   } else {
  //     deleteMutate(body);
  //   }
  // };

  // ANCHOR 체크갯수가 가득찼을 경우 해당없음 체크로직
  const handleClickListCheck = (listChecked, qst_CD) => {
    let body = {
      prj_CD: PrjCd,
      qst_CD: qst_CD,
      svy_OJT: "VST", // 여기 CSSCAN 모드면 RS,CS로 들어가야함
      sel_NM: "All_Main_Dept_Cd",
      value: "",
    };
    console.log(listChecked, qst_CD, "??");

    deleteMutate(body);
  };

  return (
    <>
      <div
        className={`mt-5 flex  ${
          AnalysisType === "csscan" ? "h-[90px]" : "h-[60px]"
        }  w-fit flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor`}
      >
        {/* 헤더 영역 (순서,문항,해당없음)*/}
        <div className="box-border flex h-full w-[50px] flex-col items-center justify-center border-l border-white">순서</div>
        <div className="box-border flex h-full w-[290px] flex-col items-center justify-center border-l border-white">문항</div>
        {/* <div className="box-border flex h-full w-[80px] flex-col items-center justify-center  border-white">
          <div
            className={`box-border flex ${
              AnalysisType === "csscan" ? "h-[90px]" : "h-[60px]"
            }  w-full flex-col items-center justify-center whitespace-pre-line border-l  border-white leading-8 `}
          >
            {`해당\n없음`}
          </div>
        </div> */}
        {/* 헤더 영역 (내원고객 세부과)*/}
        <div
          onMouseEnter={handleEnterMouse}
          onMouseLeave={handleLeaveMouse}
          className="hide-scrollbar relative flex h-full w-[640px] flex-row items-center justify-start overflow-hidden border-l border-white"
        >
          {caresel ? (
            <>
              <button
                type="button"
                onClick={handleClickPrevBtn}
                className="absolute left-3 z-50 flex h-[40px] w-[40px] flex-col items-center justify-center rounded-md bg-selectTextColor/50 p-3 text-white hover:bg-selectTextColor/30"
              >
                <MdNavigateBefore className="text-[30px]" />
              </button>
              <button
                type="button"
                onClick={handleClickNextBtn}
                className="absolute right-3 z-50 flex h-[40px] w-[40px] flex-col items-center justify-center rounded-md  bg-selectTextColor/50 p-3 text-white hover:bg-selectTextColor/30"
              >
                <MdNavigateNext className="text-[30px]" />
              </button>
            </>
          ) : null}
          <div ref={titleDiv} className="flex h-full w-fit translate-x-0 flex-row items-center justify-start duration-300 ease-in-out">
            <div className="box-border flex h-full w-fit flex-col items-start justify-start">
              {tableData[0]?.listData.length > 0 && (
                <>
                  <div className="box-border flex h-full w-full flex-col items-center justify-center border-b border-r border-white"> 내원고객</div>
                  <div
                    className={`box-border flex ${AnalysisType === "csscan" ? "h-[60px]" : "h-[30px]"}  w-full flex-row items-center justify-start`}
                  >
                    {vst_dept_sel_nm.map((item, index) => (
                      <div key={`${item.comn_NM}-${index}`} className="box-border flex h-full w-full flex-col items-center justify-start ">
                        <div className="box-border flex h-[30px] min-w-[160px] flex-col items-center justify-center border-r border-white ">
                          {item.comn_NM}
                        </div>
                        {AnalysisType === "csscan" && (
                          <div className="flex h-[30px] w-fit flex-row items-center justify-start border-t border-white">
                            {adminProjectList
                              .filter((item) => item !== "MEM")
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className={` flex h-full w-fit  flex-col items-center justify-center whitespace-nowrap border-r border-white `}
                                  style={{ width: `${(3 - adminProjectList.filter((item) => item !== "MEM").length) * 80}px` }}
                                >
                                  {item === "CS" ? "고객만족" : "환자경험"}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {!tableData[0]?.listData.length > 0 && (
                <div className="box-border flex h-full w-[640px] flex-col items-center justify-center border-white">부서가 설정되지 않았습니다.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 데이터 영역*/}
      {tableData?.length === 0 && <AdminQstNotFoundAnimation />}
      {tableData.map((item, index) => (
        <div
          key={index}
          className="flex h-[80px] w-fit flex-row items-center justify-start border-b border-FilterInActiveColor/40 text-[16px] font-normal text-selectTextColor"
        >
          <div className="flex h-full w-[50px] flex-col items-end justify-start p-7 leading-snug ">{index + 1}</div>
          <textarea
            spellCheck="false"
            readOnly
            value={item.qst}
            className="hide-scrollbar flex h-full w-[290px] cursor-default resize-none flex-col items-start justify-start border-l border-FilterInActiveColor/40 p-7 leading-snug outline-none"
          ></textarea>
          {/* ANCHOR 체크갯수가 0개인경우 해당없음체크로직
          <div
            onClick={() => handleClickListCheck(item.listChecked, item.qst_CD)}
            className="flex h-full min-w-[80px] flex-col items-center justify-center border-x border-FilterInActiveColor/40 hover:bg-FilterInActiveColor/20"
          >
            {item.listChecked ? (
              <BsCheckLg className="text-[24px] font-bold text-orangeColor" />
            ) : (
              <BsCheckLg className="text-[24px] font-bold text-gray-200" />
            )}
          </div> */}
          {/*ANCHOR 체크갯수가 가득찼을 경우 해당없음 체크로직 */}
          {/* <div
            onClick={() => handleClickListCheck(item.listChecked, item.qst_CD)}
            className="flex h-full min-w-[80px] flex-col items-center justify-center border-l border-FilterInActiveColor/40 hover:bg-FilterInActiveColor/20"
          >
            {item.listChecked ? (
              <BsCheckLg className="text-[24px] font-bold text-orangeColor" />
            ) : (
              <BsCheckLg className="text-[24px] font-bold text-gray-200" />
            )}
          </div> */}
          <div className="hide-scrollbar flex h-full w-[640px] flex-col items-start justify-start overflow-hidden">
            <div
              ref={(element) => (checkDiv.current[index] = element)}
              className="flex h-full w-fit translate-x-0 flex-row items-start justify-start border-r border-FilterInActiveColor/40  duration-300 ease-in-out "
            >
              {item.listData.map((item2, index2) => (
                <div
                  onClick={() => handleClickCheck(item2.checked, item2.comn_CD, item.qst_CD, AnalysisType, item2.svy_OJT)}
                  key={index2}
                  className={`flex h-full ${
                    AnalysisType === "csscan"
                      ? adminProjectList.filter((item) => item !== "MEM").length === 2
                        ? "min-w-[80px]"
                        : "min-w-[160px]"
                      : "min-w-[160px] "
                  } flex-col items-center justify-center ${
                    index2 === 0 ? "border-l" : " border-l "
                  } border-FilterInActiveColor/40 hover:bg-FilterInActiveColor/20`}
                >
                  {item2.checked ? (
                    <BsCheckLg className="text-[24px] font-bold text-orangeColor" />
                  ) : (
                    <BsCheckLg className="text-[24px] font-bold text-gray-200" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AdminQstTargetDeptComp;
