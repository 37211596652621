import { useQuery } from "@tanstack/react-query";
import getDa02Res from "../api/getDa02Res";

const useGetDa02Res = (prj_cd, qst_cd, svy_ojt, stDate, edDate) => {
  return useQuery(["da02"], () => getDa02Res(prj_cd, qst_cd, svy_ojt, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa02Res;
