import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA05 from "../../components/pc/BarColumnChartDA05";
import DA05Table from "../../components/pc/DA05Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA05AgeGrp } from "../../hooks/useGraphDA05AgeGrp";
import { useGraphDA05Assign } from "../../hooks/useGraphDA05Assign";
import { useGraphDA05Doc } from "../../hooks/useGraphDA05Doc";
import { useGraphDA05Etc1 } from "../../hooks/useGraphDA05Etc1";
import { useGraphDA05Etc2 } from "../../hooks/useGraphDA05Etc2";
import { useGraphDA05Etc3 } from "../../hooks/useGraphDA05Etc3";
import { useGraphDA05Normal } from "../../hooks/useGraphDA05Normal";
import { useGraphDA05Octy } from "../../hooks/useGraphDA05Octy";
import { useGraphDA05Sex } from "../../hooks/useGraphDA05Sex";
import { useGraphDA05WrkYyGrp } from "../../hooks/useGraphDA05WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA05AgeGrp } from "../../hooks/useTableDA05AgeGrp";
import { useTableDA05Assign } from "../../hooks/useTableDA05Assign";
import { useTableDA05Doc } from "../../hooks/useTableDA05Doc";
import { useTableDA05Octy } from "../../hooks/useTableDA05Octy";
import { useTableDA05Sex } from "../../hooks/useTableDA05Sex";
import { useTableDA05WrkYyGrp } from "../../hooks/useTableDA05WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA05Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const { isGraphDA05NormalLoading, isGraphDA05NormalError, GraphDA05NormalData, isGraphDA05NormalSuccess, GraphDA05NormalRefetch } =
    useGraphDA05Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA05AssignLoading, isGraphDA05AssignError, GraphDA05AssignData, isGraphDA05AssignSuccess, GraphDA05AssignRefetch } =
    useGraphDA05Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA05DocLoading, isGraphDA05DocError, GraphDA05DocData, isGraphDA05DocSuccess, GraphDA05DocRefetch } = useGraphDA05Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA05OctyLoading, isGraphDA05OctyError, GraphDA05OctyData, isGraphDA05OctySuccess, GraphDA05OctyRefetch } = useGraphDA05Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA05WrkYyGrpLoading, isGraphDA05WrkYyGrpError, GraphDA05WrkYyGrpData, isGraphDA05WrkYyGrpSuccess, GraphDA05WrkYyGrpRefetch } =
    useGraphDA05WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA05AgeGrpLoading, isGraphDA05AgeGrpError, GraphDA05AgeGrpData, isGraphDA05AgeGrpSuccess, GraphDA05AgeGrpRefetch } =
    useGraphDA05AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA05SexLoading, isGraphDA05SexError, GraphDA05SexData, isGraphDA05SexSuccess, GraphDA05SexRefetch } = useGraphDA05Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA05Etc1Loading, isGraphDA05Etc1Error, GraphDA05Etc1Data, isGraphDA05Etc1Success, GraphDA05Etc1Refetch } = useGraphDA05Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA05Etc2Loading, isGraphDA05Etc2Error, GraphDA05Etc2Data, isGraphDA05Etc2Success, GraphDA05Etc2Refetch } = useGraphDA05Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA05Etc3Loading, isGraphDA05Etc3Error, GraphDA05Etc3Data, isGraphDA05Etc3Success, GraphDA05Etc3Refetch } = useGraphDA05Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA05AgeGrpLoading, isTableDA05AgeGrpError, TableDA05AgeGrpData, isTableDA05AgeGrpSuccess, TableDA05AgeGrpRefetch } =
    useTableDA05AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA05AssignLoading, isTableDA05AssignError, TableDA05AssignData, isTableDA05AssignSuccess, TableDA05AssignRefetch } =
    useTableDA05Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA05DocLoading, isTableDA05DocError, TableDA05DocData, isTableDA05DocSuccess, TableDA05DocRefetch } = useTableDA05Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA05OctyLoading, isTableDA05OctyError, TableDA05OctyData, isTableDA05OctySuccess, TableDA05OctyRefetch } = useTableDA05Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA05SexLoading, isTableDA05SexError, TableDA05SexData, isTableDA05SexSuccess, TableDA05SexRefetch } = useTableDA05Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA05WrkYyGrpLoading, isTableDA05WrkYyGrpError, TableDA05WrkYyGrpData, isTableDA05WrkYyGrpSuccess, TableDA05WrkYyGrpRefetch } =
    useTableDA05WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length > 0) {
        let graphList = GraphSet.map((el) => el.value);

        if (graphList.includes("Normal")) {
          GraphDA05NormalRefetch();
        }

        if (graphList.includes("Assign")) {
          GraphDA05AssignRefetch();
        }

        if (graphList.includes("Doc")) {
          GraphDA05DocRefetch();
        }

        if (graphList.includes("Octy")) {
          GraphDA05OctyRefetch();
        }

        if (graphList.includes("WrkYyGrp")) {
          GraphDA05WrkYyGrpRefetch();
        }

        if (graphList.includes("AgeGrp")) {
          GraphDA05AgeGrpRefetch();
        }

        if (graphList.includes("Sex")) {
          GraphDA05SexRefetch();
        }

        if (graphList.includes("Etc1")) {
          GraphDA05Etc1Refetch();
        }

        if (graphList.includes("Etc2")) {
          GraphDA05Etc2Refetch();
        }

        if (graphList.includes("Etc3")) {
          GraphDA05Etc3Refetch();
        }
      }

      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioTypeCheck === "AgeGrp") {
      GraphDA05AgeGrpRefetch();
      TableDA05AgeGrpRefetch();
    } else if (RadioTypeCheck === "Sex") {
      GraphDA05SexRefetch();
      TableDA05SexRefetch();
    } else if (RadioTypeCheck === "Assign") {
      GraphDA05AssignRefetch();
      TableDA05AssignRefetch();
    } else if (RadioTypeCheck === "Doc") {
      GraphDA05DocRefetch();
      TableDA05DocRefetch();
    } else if (RadioTypeCheck === "Octy") {
      GraphDA05OctyRefetch();
      TableDA05OctyRefetch();
    } else if (RadioTypeCheck === "WrkYyGrp") {
      GraphDA05WrkYyGrpRefetch();
      TableDA05WrkYyGrpRefetch();
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA05NormalSuccess || GraphDA05NormalData === null || GraphDA05NormalData === undefined || GraphDA05NormalData.length === 0) {
  //     GraphDA05NormalRefetch();
  //   }
  // }, [GraphDA05NormalData, isGraphDA05NormalSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05AssignSuccess || GraphDA05AssignData === null || GraphDA05AssignData === undefined || GraphDA05AssignData.length === 0) {
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [GraphDA05AssignData, isGraphDA05AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05DocSuccess || GraphDA05DocData === null || GraphDA05DocData === undefined || GraphDA05DocData.length === 0) {
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();
  //   }
  // }, [GraphDA05DocData, isGraphDA05DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05OctySuccess || GraphDA05OctyData === null || GraphDA05OctyData === undefined || GraphDA05OctyData.length === 0) {
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();
  //   }
  // }, [GraphDA05OctyData, isGraphDA05OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05WrkYyGrpSuccess || GraphDA05WrkYyGrpData === null || GraphDA05WrkYyGrpData === undefined || GraphDA05WrkYyGrpData.length === 0) {
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();
  //   }
  // }, [GraphDA05WrkYyGrpData, isGraphDA05WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05AgeGrpSuccess || GraphDA05AgeGrpData === null || GraphDA05AgeGrpData === undefined || GraphDA05AgeGrpData.length === 0) {
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [GraphDA05AgeGrpData, isGraphDA05AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05SexSuccess || GraphDA05SexData === null || GraphDA05SexData === undefined || GraphDA05SexData.length === 0) {
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [GraphDA05SexData, isGraphDA05SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05Etc1Success || GraphDA05Etc1Data === null || GraphDA05Etc1Data === undefined || GraphDA05Etc1Data.length === 0) {
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [GraphDA05Etc1Data, isGraphDA05Etc1Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05Etc2Success || GraphDA05Etc2Data === null || GraphDA05Etc2Data === undefined || GraphDA05Etc2Data.length === 0) {
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [GraphDA05Etc2Data, isGraphDA05Etc2Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA05Etc3Success || GraphDA05Etc3Data === null || GraphDA05Etc3Data === undefined || GraphDA05Etc3Data.length === 0) {
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [GraphDA05Etc3Data, isGraphDA05Etc3Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA05AgeGrpSuccess || TableDA05AgeGrpData === null || TableDA05AgeGrpData === undefined || TableDA05AgeGrpData.length === 0) {
  //     TableDA05AgeGrpRefetch();
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [TableDA05AgeGrpData, isTableDA05AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA05AssignSuccess || TableDA05AssignData === null || TableDA05AssignData === undefined || TableDA05AssignData.length === 0) {
  //     TableDA05AssignRefetch();
  //     TableDA05AgeGrpRefetch();
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [TableDA05AssignData, isTableDA05AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA05DocSuccess || TableDA05DocData === null || TableDA05DocData === undefined || TableDA05DocData.length === 0) {
  //     TableDA05DocRefetch();
  //     TableDA05AssignRefetch();
  //     TableDA05AgeGrpRefetch();
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [TableDA05DocData, isTableDA05DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA05OctySuccess || TableDA05OctyData === null || TableDA05OctyData === undefined || TableDA05OctyData.length === 0) {
  //     TableDA05OctyRefetch();
  //     TableDA05DocRefetch();
  //     TableDA05AssignRefetch();
  //     TableDA05AgeGrpRefetch();
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [TableDA05OctyData, isTableDA05OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA05SexSuccess || TableDA05SexData === null || TableDA05SexData === undefined || TableDA05SexData.length === 0) {
  //     TableDA05SexRefetch();
  //     TableDA05OctyRefetch();
  //     TableDA05DocRefetch();
  //     TableDA05AssignRefetch();
  //     TableDA05AgeGrpRefetch();
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [TableDA05SexData, isTableDA05SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA05WrkYyGrpSuccess || TableDA05WrkYyGrpData === null || TableDA05WrkYyGrpData === undefined || TableDA05WrkYyGrpData.length === 0) {
  //     TableDA05WrkYyGrpRefetch();
  //     TableDA05SexRefetch();
  //     TableDA05OctyRefetch();
  //     TableDA05DocRefetch();
  //     TableDA05AssignRefetch();
  //     TableDA05AgeGrpRefetch();
  //     GraphDA05Etc3Refetch();
  //     GraphDA05Etc2Refetch();
  //     GraphDA05Etc1Refetch();
  //     GraphDA05SexRefetch();
  //     GraphDA05AgeGrpRefetch();
  //     GraphDA05WrkYyGrpRefetch();
  //     GraphDA05OctyRefetch();
  //     GraphDA05DocRefetch();
  //     GraphDA05AssignRefetch();
  //     GraphDA05NormalRefetch();

  //   }
  // }, [TableDA05WrkYyGrpData, isTableDA05WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA05"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vType = "column";
                vTitle = "보직여부 응답률";
                vStack = "Y";
              } else if (data.value === "Doc") {
                vType = "column";
                vTitle = "의사직, 구성원 응답률";
                vStack = "Y";
              } else if (data.value === "Octy") {
                vType = "column";
                vTitle = "직종별 응답률";
                vStack = "Y";
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vTitle = "연차별 응답률";
                vStack = "Y";
              } else if (data.value === "Sex") {
                vType = "column";
                vTitle = "성별 응답률";
                vStack = "Y";
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vTitle = "연령대별 응답률";
                vStack = "Y";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
                vStack = "Y";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
                vStack = "Y";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
                vStack = "Y";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA05NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA05AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA05DocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA05OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA05WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA05SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA05AgeGrpData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA05Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA05Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA05Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA05
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vType = "column";
              vTitle = "보직여부 응답률";
              vStack = "Y";
            } else if (data.value === "Doc") {
              vType = "column";
              vTitle = "의사직, 구성원 응답률";
              vStack = "Y";
            } else if (data.value === "Octy") {
              vType = "column";
              vTitle = "직종별 응답률";
              vStack = "Y";
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vTitle = "연차별 응답률";
              vStack = "Y";
            } else if (data.value === "Sex") {
              vType = "column";
              vTitle = "성별 응답률";
              vStack = "Y";
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vTitle = "연령대별 응답률";
              vStack = "Y";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
              vStack = "Y";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
              vStack = "Y";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
              vStack = "Y";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA05NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA05AssignData;
              vTData = TableDA05AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA05DocData;
              vTData = TableDA05DocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA05OctyData;
              vTData = TableDA05OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA05WrkYyGrpData;
              vTData = TableDA05WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA05SexData;
              vTData = TableDA05SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA05AgeGrpData;
              vTData = TableDA05AgeGrpData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA05Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA05Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA05Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA05Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA05
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA05Page;
