import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/pc/Loading";
import Summary from "../../components/pc/Summary";
import TrendSummary from "../../components/pc/TrendSummary";
import { menuDict } from "../../dict/pc/dict";
import { useQstList } from "../../hooks/useQstList";
import { usePeriodState, useTrendState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";
import { getStartDateForTrendSummarySql } from "../../utils/TrendSummaryFunc";

const SummaryPage = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const HspCd = useStore((state) => state.HspCd);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const QstHspSelCd = useStore((state) => state.QstHspSelCd);
  const [loading, setLoading] = useState(false);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  // 추세 그래프를 위한 현재 년도, 월 정보

  // Trends ENUM -> 추후 utils로 분리(TODO)
  const Trends = Object.freeze({
    MONTH: "month",
    YEAR: "year",
  });
  const trendPeriodYear = useTrendState((state) => state.trendYearState);
  const trendPeriodMonth = useTrendState((state) => state.trendMonthState);
  const analysisType = useStore((state) => state.AnalysisType);

  const [trendPeriod, setTrendPeriod] = useState(Trends.MONTH);

  const handleClickTrendRadio = (value) => {
    setTrendPeriod(value);

    // if (serverGuideType !== value) {
    //   setIntroMode(true);
    // } else {
    //   setIntroMode(false);
    // }
  };

  // const { isTrendSelListLoading, isTrendSelListError, TrendSelListData, isTrendSelListSuccess, TrendSelListRefetch } = useTrendSellist(
  //   PrjCd,
  //   formatDateToyyyyMMdd(endDate),
  //   trendPeriod
  // );

  // ~ 추세 그래프

  const navigate = useNavigate();

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   TrendSelListRefetch();
  // }, [endDate, trendPeriod]);

  useEffect(() => {
    if (QstListData === undefined || QstListData === null) {
      QstListRefetch();
    }
    // }, [QstListData]);
  }, [PrjCd]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
      setQst(vQst);
      setKeyword(vKeyword);
      setCntYn(vCntYn);
      setQstCd(Pick.qst_cd);
    }
  }, [Pick]);

  // console.log(
  //   QstListData?.filter(
  //     (x) =>
  //       x.menu_CATE === "필수문항" || x.menu_CATE === "선택문항" || (x.menu_CATE === "심화문항" && x.deep_ANA_CD !== "DA02" && x.grp_DVS !== "GRP")
  //   )
  // );

  return (
    <div className="h-full bg-white">
      {analysisType === "csscan" ? (
        <div className="flex w-full flex-row items-end justify-end gap-5 pr-7 pt-5 text-xl">
          <div className="flex w-fit flex-row items-center justify-center">
            <input
              type="radio"
              checked={trendPeriod === Trends.MONTH ? true : false}
              name="trend"
              id={Trends.MONTH}
              value={Trends.MONTH}
              className="border accent-orangeColor"
              onClick={() => handleClickTrendRadio(Trends.MONTH)}
              onChange={() => {}}
            />
            <label htmlFor="none" className="ml-1 font-bold">
              월
            </label>
          </div>
          <div className="flex h-fit w-fit flex-row items-center justify-center">
            <input
              type="radio"
              checked={trendPeriod === Trends.YEAR ? true : false}
              name="trend"
              id={Trends.YEAR}
              value={Trends.YEAR}
              className="border accent-orangeColor"
              onClick={() => handleClickTrendRadio(Trends.YEAR)}
              onChange={() => {}}
            />
            <label htmlFor="small" className="ml-1 font-bold">
              년 / 분기
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}

      {loading ? <Loading /> : null}
      {QstListData &&
        QstListData !== null &&
        QstListData !== undefined &&
        QstListData.length > 0 &&
        QstListData.filter(
          (x) =>
            x.menu_CATE === "필수문항" ||
            x.menu_CATE === "선택문항" ||
            (x.menu_CATE === "심화문항" && x.deep_ANA_CD !== "DA02" && x.grp_DVS !== "GRP")
        ).map((data, i) => {
          return analysisType === "csscan" ? (
            trendPeriod === Trends.MONTH ? (
              <TrendSummary
                key={`Summary_${i}_${PrjCd}_${SvyOjt}_${data.qst_CD}_${data.deep_ANA_CD}_month`}
                hsp_nm={HspNm}
                prj_cd={PrjCd}
                svy_ojt={SvyOjt}
                qst_cd={data.qst_CD}
                deep_ana_cd={data.deep_ANA_CD}
                sel_cd={""}
                string_value={""}
                where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                sort_type={data.sort_TYPE ? "M" : data.sort_TYPE}
                data_num={"1"}
                title={data.keyword}
                qst={data.qst}
                menu_cate={data.menu_CATE}
                qst_hsp_sel_cd={QstHspSelCd}
                grp_dvs={data.grp_DVS}
                startDate={getStartDateForTrendSummarySql(trendPeriod, endDate)}
                endDate={endDate}
                // trends_period_x={TrendSelListData}
                // trends_period_tmp={TrendSelListData}
                trendPeriod={trendPeriod}
              />
            ) : (
              <>
                {" "}
                <TrendSummary
                  key={`Summary_${i}_${PrjCd}_${SvyOjt}_${data.qst_CD}_${data.deep_ANA_CD}_year`}
                  hsp_nm={HspNm}
                  prj_cd={PrjCd}
                  svy_ojt={SvyOjt}
                  qst_cd={data.qst_CD}
                  deep_ana_cd={data.deep_ANA_CD}
                  sel_cd={""}
                  string_value={""}
                  where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                  sort_type={data.sort_TYPE ? "M" : data.sort_TYPE}
                  data_num={"1"}
                  title={data.keyword}
                  qst={data.qst}
                  menu_cate={data.menu_CATE}
                  qst_hsp_sel_cd={QstHspSelCd}
                  grp_dvs={data.grp_DVS}
                  startDate={getStartDateForTrendSummarySql(trendPeriod, endDate)}
                  endDate={endDate}
                  trendPeriod={"year"}
                />
                <TrendSummary
                  key={`Summary_${i}_${PrjCd}_${SvyOjt}_${data.qst_CD}_${data.deep_ANA_CD}_quarter`}
                  hsp_nm={HspNm}
                  prj_cd={PrjCd}
                  svy_ojt={SvyOjt}
                  qst_cd={data.qst_CD}
                  deep_ana_cd={data.deep_ANA_CD}
                  sel_cd={""}
                  string_value={""}
                  where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
                  sort_type={data.sort_TYPE ? "M" : data.sort_TYPE}
                  data_num={"1"}
                  title={data.keyword}
                  qst={data.qst}
                  menu_cate={data.menu_CATE}
                  qst_hsp_sel_cd={QstHspSelCd}
                  grp_dvs={data.grp_DVS}
                  startDate={getStartDateForTrendSummarySql(trendPeriod, endDate)}
                  endDate={endDate}
                  trendPeriod={"quarter"}
                />
              </>
            )
          ) : (
            <Summary
              key={`Summary_${i}_${PrjCd}_${SvyOjt}_${data.qst_CD}_${data.deep_ANA_CD}`}
              hsp_nm={HspNm}
              prj_cd={PrjCd}
              svy_ojt={SvyOjt}
              qst_cd={data.qst_CD}
              deep_ana_cd={data.deep_ANA_CD}
              sel_cd={""}
              string_value={""}
              where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
              sort_type={data.sort_TYPE ? "M" : data.sort_TYPE}
              data_num={"1"}
              title={data.keyword}
              qst={data.qst}
              menu_cate={data.menu_CATE}
              qst_hsp_sel_cd={QstHspSelCd}
              startDate={startDate}
              endDate={endDate}
            />
          );
        })}
    </div>
  );
};

export default SummaryPage;
