import { useQuery } from "@tanstack/react-query";
import getAdminUserArea from "../api/getAdminUserArea";
const useGetAdminUserArea = (prj_cd) => {
  return useQuery(["adminUserArea"], () => getAdminUserArea(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserArea;
