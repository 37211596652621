import { useEffect } from "react";
import { HiOutlineX } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import useGetDepQstList from "../../hooks/useGetDepQstList";
import useGetMobileQstList from "../../hooks/useGetMobileQstList";
import {
  useMenuListModalControlState,
  useMenuState,
  usePrjValState,
  useQstCdState,
  useQstListState,
  useQstState,
  useQstSvyOjtState,
} from "../../store/mobile/store";
import CheckedMenuSub from "./menuList/CheckedMenuSub";
import MenuGrp from "./menuList/MenuGrp";
import MenuSub from "./menuList/MenuSub";

const QstListModal = () => {
  const params = useParams();
  const paramsVal = Object.values(params)[0];

  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);
  const setQstModalState = useMenuListModalControlState((state) => state.setMenuListModalControl);

  const menuState = useMenuState((state) => state.menuVal);

  const setMenuState = useMenuState((state) => state.setMenuVal);

  const prjState = usePrjValState((state) => state.prjVal);

  const qstListState = useQstListState((state) => state.qstList);

  const qstNumState = useQstState((state) => state.qstState);

  const qstCdState = useQstCdState((state) => state.qstCdState);

  const qstSvyOjtState = useQstSvyOjtState((state) => state.qstSvyOjtState);

  const handleClickCancel = () => {
    setQstModalState(false);
    if (paramsVal === "analysis") {
      setMenuState("");
    } else {
      setMenuState(qstSvyOjtState);
    }
  };

  const { refetch: qstListRefetch, isRefetching: QstRefetching } = useGetMobileQstList(menuState, prjState.value);
  const { refetch: depQstListRefetch, isRefetching: DepQstRefetching } = useGetDepQstList(prjState.value);

  useEffect(() => {
    if (menuState) {
      if (menuState && prjState.value && menuState !== "DEP" && menuState !== "OBJ_ANA") {
        qstListRefetch();
      } else if (menuState && prjState.value && (menuState === "DEP" || menuState === "OBJ_ANA")) {
        depQstListRefetch();
      }
    }
  }, [menuState, prjState]);
  return (
    <>
      <div
        className={
          qstListModalState
            ? `fixed bottom-0 z-50 flex h-[95%] w-full flex-col items-center justify-start overflow-auto rounded-t-2xl bg-white font-SDGothic duration-500 ease-in-out`
            : `fixed -bottom-[900px] z-50 flex h-[95%] w-full flex-col items-center justify-start overflow-auto rounded-t-2xl bg-white font-SDGothic duration-500 ease-in-out`
        }
      >
        <div className="sticky top-0 flex h-fit w-full flex-col items-start justify-center border-b border-orangeColor bg-white p-5 py-7 text-[16px] font-bold text-selectTextColor">
          {menuState === "MEM"
            ? `구성원 설문목록`
            : menuState === "VST"
            ? `내원고객 설문목록`
            : menuState === "LRES"
            ? `지역주민 설문목록`
            : menuState === "CS"
            ? `고객만족 설문목록`
            : menuState === "RS"
            ? `환자경험평가 설문목록`
            : menuState === "DEP"
            ? `대상간 설문목록`
            : null}
          <HiOutlineX onClick={handleClickCancel} className="absolute right-4 top-4 text-4xl text-textColor" />
        </div>

        {QstRefetching || DepQstRefetching ? (
          <div className="fixed top-0 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
            <SyncLoader color="#1686FF" />
          </div>
        ) : (
          <div className="flex h-fit w-full flex-col items-center justify-start p-1">
            {/* 일반문항 */}
            {/* 일반문항 타이틀 */}
            {qstListState?.filter((el) => el.menu_CATE2 === "일반문항").length ? <MenuGrp value={"일반문항"} /> : null}
            {/* 일반문항 리스트 */}
            {qstListState?.map((el, idx) => {
              if (el.menu_CATE2 === "일반문항") {
                // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
                if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
                  return <CheckedMenuSub key={idx} props={el} />;
                } else {
                  return <MenuSub key={idx} props={el} />;
                }
              }
            })}

            {/* 교차문항 */}
            {/* 교차문항 타이틀 */}
            {qstListState?.filter((el) => el.menu_CATE2 === "교차문항").length ? <MenuGrp value={"교차문항"} /> : null}
            {/* 교차문항 리스트 */}
            {qstListState?.map((el, idx) => {
              if (el.menu_CATE2 === "교차문항") {
                // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
                if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
                  return <CheckedMenuSub key={idx} props={el} />;
                } else {
                  return <MenuSub key={idx} props={el} />;
                }
              }
            })}

            {/* 대상간 문항 */}
            {/* 대상간 문항 타이틀 */}
            {qstListState?.filter((el) => el.menu_CATE2 === "대상간분석").length ? <MenuGrp value={"대상간분석"} /> : null}
            {/* 대상간 문항 리스트 */}
            {qstListState?.map((el, idx) => {
              if (el.menu_CATE2 === "대상간분석") {
                // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
                if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
                  return <CheckedMenuSub key={idx} props={el} />;
                } else {
                  return <MenuSub key={idx} props={el} />;
                }
              }
            })}

            {/* 주관식 */}
            {/* 주관식 타이틀 */}
            {qstListState?.filter((el) => el.menu_CATE2 === "주관식").length ? <MenuGrp value={"주관식"} /> : null}
            {/* 주관식 문항 리스트 */}
            {qstListState?.map((el, idx) => {
              if (el.menu_CATE2 === "주관식") {
                // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
                if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
                  return <CheckedMenuSub key={idx} props={el} />;
                } else {
                  return <MenuSub key={idx} props={el} />;
                }
              }
              // 원천데이터는 익명게시판이다
              else if (el.menu_CATE2 === "원천데이터") {
                // 마지막 조회한 문항 식별을 위해 rn, qst_cd, svy_ojt가 일치한 문항만 active
                if (qstNumState === el.rn && qstCdState === el.qst_CD && qstSvyOjtState === el.svy_OJT) {
                  return <CheckedMenuSub key={idx} props={el} />;
                } else {
                  return <MenuSub key={idx} props={el} />;
                }
              }
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default QstListModal;
