import { useEffect, useState } from "react";
import { BsCheckLg, BsClipboardDataFill } from "react-icons/bs";
import Lottie from "react-lottie-player";
import AdminModalContainer from "../../components/pc/admin/AdminModalContainer";
import AdminQstAlert from "../../components/pc/admin/AdminQstStandard/AdminQstAlert";
import AdminQstStandardRadio from "../../components/pc/admin/AdminQstStandard/AdminQstStandardRadio";
import Loading from "../../components/pc/Loading";
import useGetAdminStandardExist from "../../hooks/Admin/AdminQstStandard/useGetAdminStandardExist";
import useGetAdminStandardQstSelRes from "../../hooks/Admin/AdminQstStandard/useGetAdminStandardQstSelRes";
import usePostAdminStandardQst from "../../hooks/Admin/AdminQstStandard/usePostAdminStandardQst";
import useStore from "../../store/pc/store";
import notfoundAnimation from "../../svg_icon/notFound.json";
import { getSvyOjtList } from "../../util/pc/getSvyOjtList";
import { makeStandardQstDynamicData } from "../../util/pc/makeStandardQstDynamicData";

const AdminQstStandardTest = () => {
  const { PrjCd, HspNm, AnalysisType } = useStore();
  const [guideType, setGuideType] = useState(AnalysisType === "scan" ? "001" : "004");
  //ANCHOR - 선택문항인것들에 대해서 체크 판단, 필수문항인것들은 고정값 업로드 버튼에서 합쳐주면 됨.

  const [modal, setModal] = useState(false);

  //ANCHOR - API 영역
  const { data: getStandardQstData, isLoading: StandardQstDataLoading } = useGetAdminStandardQstSelRes(guideType);
  const { data: isStandardExist, isLoading: isStandardExistLoading } = useGetAdminStandardExist(PrjCd);
  const { mutate: postAdminStandardQst, isLoading: isPending } = usePostAdminStandardQst(PrjCd);

  const [abc, setAbc] = useState([]);

  useEffect(() => {
    const standardQstDynamicData = makeStandardQstDynamicData(getStandardQstData);
    setAbc(standardQstDynamicData);
  }, [StandardQstDataLoading, isStandardExistLoading, guideType]);

  if (StandardQstDataLoading || isStandardExistLoading)
    return (
      <div className="relative flex h-fit min-h-[2000px] w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic text-[16px] font-normal text-selectTextColor/90">
        <Loading />
      </div>
    );
  console.log(abc, "abc");
  //SECTION - 필수인지 선택문항인지 문항 구분 필터링 설정
  const filteredEssentialAdminQstData = abc.filter((item) => item.ess_YN === "Y" && item.dvs_CD === guideType);
  const filteredNotEssentialAdminQstData = abc.filter((item) => item.ess_YN === "N" && item.dvs_CD === guideType);
  //!SECTION - 필수인지 선택문항인지 설정
  const filteredDataLength = filteredEssentialAdminQstData.length + filteredNotEssentialAdminQstData.length;
  const svyOjtList = getSvyOjtList(getStandardQstData);
  const SvyOjtThirdIndexList = svyOjtList.map((item) => item[2]);
  const svyOjtListLength = `calc(100% - 40px - 240px - ${svyOjtList.length * 70}px)`;

  const toggleCustomList = (qst_CD, yn, custemYn) => {
    console.log("클릭더;ㅁ", qst_CD, yn, custemYn);
    setAbc((prevAbc) =>
      prevAbc.map((item) => {
        if (item.qst_CD === qst_CD) {
          return {
            ...item,
            [custemYn]: yn === "Y" ? "N" : "Y",
          };
        }
        return item;
      })
    );

    console.log(abc, "abc");
  };

  const postAdminSelState = () => {
    const postData = filteredEssentialAdminQstData.concat(filteredNotEssentialAdminQstData);
    postAdminStandardQst(postData);
  };

  return (
    <div className="relative flex h-fit min-h-[2000px] w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic text-[16px] font-normal text-selectTextColor/90">
      {/* title */}
      <div className="sticky top-[56px] z-20 flex h-fit w-full flex-row items-center justify-start bg-white py-2 text-[20px] font-bold">
        <div className="flex items-center justify-start">
          <BsClipboardDataFill className="mb-2 mr-7 text-[24px] text-orangeColor" />
          표준 문항
        </div>
        <div className="ml-[10px] text-[12px] text-orangeColor">필수문항은 해제가 불가능 합니다.</div>
      </div>

      {/* ANCHOR 라디오버튼 */}
      <AdminQstStandardRadio guideType={guideType} handleClickRadio={(e) => setGuideType(e)} />

      {/* ANCHOR 메뉴 헤더 */}
      <div className="sticky top-[132px] z-20  mt-5 flex h-[45px] w-full flex-row items-center justify-start bg-[#E3E3E3] text-[16px] font-bold text-selectTextColor">
        <div className="flex h-full w-[40px] flex-col items-center justify-center border-l border-white">순서</div>
        <div className={`flex h-full flex-col items-center justify-center border-l border-white`} style={{ width: svyOjtListLength }}>
          문항
        </div>
        <div className="flex h-full w-[240px] flex-col items-center justify-center border-l border-white">키워드</div>
        {svyOjtList.map((item, index) => (
          <div key={index} className="flex h-full w-[70px] flex-col items-center justify-center border-l border-white">
            {item[1]}
          </div>
        ))}
      </div>

      {/* ANCHOR 필수문항 + 선택문항 0개 일때 */}
      {filteredDataLength === 0 && (
        <>
          <div className="mt-[20px] w-full p-4 text-center text-[24px] font-semibold text-blue1Color">
            선택하신 체크박스에 관한 표준문항이 존재 하지 않습니다.
          </div>
          <Lottie loop animationData={notfoundAnimation} play style={{ width: "100%", height: 300, margin: "0 auto", padding: "10px" }} />
          <div className="mb-[20px] w-full p-4 text-center text-[24px] font-semibold text-blue1Color">
            체크박스를 통한 표준 문항 추가를 원할 경우 IT본부에 문의 바랍니다.
          </div>
        </>
      )}

      {/* ANCHOR 필수문항 */}
      <div className={`flex h-fit w-full flex-col items-start justify-start ${filteredDataLength !== 0 ? "border-b-2 border-orangeColor" : ""}`}>
        {filteredEssentialAdminQstData
          ?.filter((item) => item.dvs_CD === guideType)
          .map((item, index) => (
            <div key={index} className="relative flex h-fit w-full flex-row items-stretch justify-start border-b ">
              {/* 순서 */}
              <div className="peer flex h-[45px] w-[40px] flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug">
                {index + 1}
              </div>
              {/* 문항 */}
              <textarea
                spellCheck="false"
                readOnly={true}
                // 리랜더링 이슈
                defaultValue={item.qst}
                key={item.qst + index + "qst"}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[45px] resize-none  items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                style={{ width: svyOjtListLength }}
              />
              {/* 키워드 */}
              <textarea
                spellCheck="false"
                // 리랜더링 이슈
                readOnly={true}
                defaultValue={item.keyword}
                key={item.keyword + index + "keyword"}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[45px] w-[240px] resize-none flex-col items-start justify-start border-FilterInActiveColor/40 p-2 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              />
              {/*필수문항 svy_OJT 체크박스 */}

              {SvyOjtThirdIndexList.map((yn, index) => {
                // 처음부터 Y인 불변값
                if (item[yn] === "Y" && item[yn.replace("custom_", "")] === "Y") {
                  return (
                    <div
                      key={index}
                      className={`flex h-[45px] w-[70px] cursor-not-allowed flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug`}
                      onClick={() => (item[yn] === "Y" ? null : toggleCustomList(item.qst_CD, item[yn], yn))}
                    >
                      <BsCheckLg className="text-[24px] font-bold text-orangeColor" />
                    </div>
                  );
                } else if (item[yn] === "Y" && item[yn.replace("custom_", "")] === "N") {
                  return (
                    <div
                      key={index}
                      className={`flex h-[45px] w-[70px] cursor-pointer flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug`}
                      onClick={() => toggleCustomList(item.qst_CD, item[yn], yn)}
                    >
                      <BsCheckLg className="text-[24px] font-bold text-loginBlueColor" />
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={`flex h-[45px] w-[70px] cursor-pointer flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug`}
                      onClick={() => toggleCustomList(item.qst_CD, item[yn], yn)}
                    >
                      <BsCheckLg className="text-[24px] font-bold text-gray-200" />
                    </div>
                  );
                }
              })}

              {/* 호버박스 */}
              <div className="invisible absolute left-0 top-[45px] z-50 h-fit w-[25%] border-2 border-orangeColor bg-white peer-hover:visible">
                <div className="flex h-[45px] w-full flex-row items-start justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
                  <div className="flex h-[45px] w-[100%] flex-col items-center justify-center border-l border-white">설문 보기</div>
                </div>

                {item.opt.map((optItem, index) =>
                  optItem.length === 0 ? (
                    <div key={"optItem" + index} className="flex h-fit w-full flex-row items-stretch justify-start border-b">
                      <div>주관식 문항</div>
                    </div>
                  ) : (
                    <div key={"optItem" + index} className="flex h-fit w-full flex-row items-start justify-start border-b">
                      <div className="flex h-full w-[100%] flex-row items-center  border-FilterInActiveColor/40 px-4 py-2 leading-snug">
                        {optItem.sel_NM_SVY}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
      </div>
      {/* ANCHOR 선택문항 */}
      <div className="mb-5 flex h-fit w-full flex-col items-start justify-start border-b">
        {filteredNotEssentialAdminQstData
          ?.filter((item) => item.dvs_CD === guideType)
          .map((item, index) => (
            <div key={index} className="relative flex h-fit w-full flex-row items-stretch justify-start border-b ">
              {/* 순서 */}
              <div className="peer flex h-[45px] w-[40px] flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug">
                {index + filteredEssentialAdminQstData.length + 1}
              </div>
              {/* 문항 */}
              <textarea
                spellCheck="false"
                readOnly={true}
                // 리랜더링 이슈
                defaultValue={item.qst}
                key={item.qst + index + "qst"}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[45px] resize-none  items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                style={{ width: svyOjtListLength }}
              />
              {/* 키워드 */}
              <textarea
                spellCheck="false"
                // 리랜더링 이슈
                readOnly={true}
                defaultValue={item.keyword}
                key={item.keyword + index + "keyword"}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[45px] w-[240px] resize-none flex-col items-start justify-start border-FilterInActiveColor/40 p-2 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              />
              {/*선택문항 svy_ojt 체크박스 */}
              {SvyOjtThirdIndexList.map((yn) => {
                // 처음부터 Y인 불변값
                if (item[yn] === "Y" && item[yn.replace("custom_", "")] === "N") {
                  return (
                    <div
                      className={`flex h-[45px] w-[70px] cursor-pointer flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug`}
                      onClick={() => toggleCustomList(item.qst_CD, item[yn], yn)}
                    >
                      <BsCheckLg className="text-[24px] font-bold text-loginBlueColor" />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`flex h-[45px] w-[70px] cursor-pointer flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug`}
                      onClick={() => toggleCustomList(item.qst_CD, item[yn], yn)}
                    >
                      <BsCheckLg className="text-[24px] font-bold text-gray-200" />
                    </div>
                  );
                }
              })}

              {/* 호버박스 */}
              <div className="invisible absolute left-0 top-[45px] z-50 h-fit w-[25%] border-2 border-orangeColor bg-white peer-hover:visible">
                <div className="flex h-[45px] w-full flex-row items-start justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
                  {/* <div className="flex h-[45px] w-[33%] flex-col items-center justify-center">PC분석 보기</div> */}
                  {/* <div className="flex h-[45px] w-[100%] flex-col items-start justify-center border-l border-white pl-6">모바일분석 보기</div> */}
                  <div className="flex h-[45px] w-[100%] flex-col items-center justify-center border-l border-white">설문 보기</div>
                </div>

                {item.opt.map((optItem, index) =>
                  optItem.length === 0 ? (
                    <div key={"optItem" + index} className="flex h-fit w-full flex-row items-stretch justify-start border-b">
                      <div>주관식 문항</div>
                    </div>
                  ) : (
                    <div key={"optItem" + index} className="flex h-fit w-full flex-row items-start justify-start border-b">
                      {/* 모바일만 보여주라고 하셔서 주석처리 (나중에 다시 3개 보여주라 할 수도 있을것 같아서) */}
                      {/* <div className="flex h-full w-[33%] flex-row items-center justify-center border-FilterInActiveColor/40 p-2 leading-snug">
                      {optItem.sel_NM}
                    </div> */}
                      {/* <div className="flex h-full w-[100%]  flex-col items-start justify-center border-FilterInActiveColor/40 p-2 pl-6 leading-snug">
                        {optItem.sel_NM_M}
                      </div> */}
                      <div className="flex h-full w-[100%] flex-row items-center border-FilterInActiveColor/40 px-4 py-2 leading-snug">
                        {optItem.sel_NM_SVY}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
      </div>

      {/* 만든적 있는 표준문항인지 판단 */}
      {!isStandardExist
        ? filteredDataLength !== 0 && (
            <div
              onClick={() => setModal(true)}
              className="mx-auto flex h-[40px] w-[250px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
            >
              {HspNm} 표준문항 업로드
            </div>
          )
        : filteredDataLength !== 0 && (
            <>
              <div className="flex h-[30px] w-full flex-row items-center justify-center p-6 text-[16px] font-bold text-orangeColor">
                {HspNm}의 해당 프로젝트에 이미 생성된 표준문항이 있습니다.
              </div>
              <div className="flex h-[30px] w-full flex-row items-center justify-center p-6 text-[16px] font-bold text-orangeColor">
                수정작업은 문항 관리 메뉴에서 작업해주시기 바랍니다.
              </div>
              {/* <div className="flex h-[40px] w-full flex-row items-center justify-center p-6 text-[16px] font-bold text-orangeColor">
            (없을땐 버튼 안눌리게바꾸자)
          </div> */}
              <div
                onClick={() => setModal(true)}
                className="mx-auto mt-[10px] flex h-[40px] w-[400px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
              >
                {HspNm} 표준문항 삭제 후 덮어쓰기
              </div>
            </>
          )}

      {modal ? (
        <AdminModalContainer setModal={setModal} size={"SMALL"}>
          <AdminQstAlert
            setModal={setModal}
            apiMethod={postAdminSelState}
            text={!isStandardExist ? `${HspNm} 표준문항 업로드` : `${HspNm} 표준문항 덮어쓰기`}
            isPending={isPending}
            init={!isStandardExist ? true : false}
          />
        </AdminModalContainer>
      ) : null}
    </div>
  );
};

export default AdminQstStandardTest;
