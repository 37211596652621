import axios from "axios";

const getEssayChartRes = (prj_cd, qst_cd, svy_ojt, filter_opt, stDate, edDate) => {
  return axios.get(
    `/resultM/essay/graph?prj_cd=${prj_cd}&qst_cd=${qst_cd}&svy_ojt=${svy_ojt}&filter_opt=${filter_opt}&stDate=${stDate}&edDate=${edDate}`,
    {
      baseURL: process.env.REACT_APP_SERVER_ADDRESS,
      withCredentials: true,
    }
  );
};

export default getEssayChartRes;
