import Lottie from "react-lottie-player";
import alertAnimation from "../../../../svg_icon/alert.json";
import surveyMakeLoading from "../../../../svg_icon/surveyMakeLoading.json";

const AdminQstAlert = ({ setModal, apiMethod, text, isPending, init }) => {
  const handleClickCancel = () => {
    setModal(false);
  };

  return (
    <div className="mx-auto my-[20px]">
      {isPending ? (
        <>
          <Lottie loop animationData={surveyMakeLoading} play style={{ width: 450, height: "auto", margin: "0 auto", padding: "10px" }} />
          <div className="my-6 pl-[20px] text-left text-orangeColor">
            표중문항이 제작중입니다.(최대 2분정도 시간이 소요되오니 닫지말고 기다려주십시오.)
          </div>
        </>
      ) : (
        <>
          <Lottie loop animationData={alertAnimation} play style={{ width: 150, height: 150, margin: "0 auto", padding: "10px" }} />

          {init && (
            <>
              <div className="my-6 w-[600px] pl-[10px] text-left text-orangeColor">
                &#8251; 덮어쓰기 버튼을 클릭 시 기존 데이터가 삭제 되오니 주의가 필요합니다.
              </div>
              <div className="my-6 w-[600px] pl-[10px] text-left text-orangeColor">&#8251; 필수 문항은 팀장급 이상이~</div>
              <div className="my-6 w-[600px] pl-[10px] text-left text-orangeColor">
                &#8251; 문항의 구분선을 기준으로 상단부분은 필수문항 하단부분은 선택문항입니다.
              </div>
            </>
          )}
          {!init && (
            <>
              <div className="my-6 w-[600px] pl-[10px] text-left text-orangeColor">
                &#8251; 업로드 된 표준 문항은 문항 관리 메뉴에서 수정 할 수 있습니다.
              </div>
              <div className="my-6 w-[600px] pl-[10px] text-left text-orangeColor">
                &#8251; 하단의 업로드 클릭시 표준문항이 약 2분간 생성되오니 업로드후 기다려주시기 바랍니다.
              </div>
            </>
          )}

          <div className="mt-[30px] flex justify-between">
            <div
              onClick={apiMethod}
              className="flex h-fit w-[290px] flex-row items-center justify-center rounded-xl bg-orangeColor p-6 text-[16px] font-bold text-white hover:bg-orangeColor/80"
            >
              {text}
            </div>
            <div
              onClick={handleClickCancel}
              className="flex h-fit w-[290px] flex-row items-center justify-center rounded-xl bg-modalBorderColor p-6 text-[16px] font-bold text-white hover:bg-modalBorderColor/80"
            >
              {text} 취소
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminQstAlert;
