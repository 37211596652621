import { useQuery } from "@tanstack/react-query";
import getDa05Res from "../api/getDa05Res";

const useGetDa05Res = (prj_cd, svy_ojt, stDate, edDate) => {
  return useQuery(["da05"], () => getDa05Res(prj_cd, svy_ojt, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa05Res;
