import { useQuery } from "@tanstack/react-query";
import getDetailPrj from "../api/getDetailPrj";

const useGetDetailPrj = (prj_cd) => {
  return useQuery(["detailPrj"], () => getDetailPrj(prj_cd), {
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDetailPrj;
