import axios from "axios";

const postGptRes = (body) => {
  //   const body = {
  //     prj_CD: prj_cd,
  //     svy_OJT: svy_ojt,
  //     qst_CD: qst_cd,
  //     prd: prd,
  //     gpt_RSLT: gpt_rslt,
  //   };

  return axios.post("/gpt/summerize", body, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    headers: {
      "content-Type": `application/json`,
    },
  });
};

export default postGptRes;
