import axios from "axios";

const getMenuList = async (prjVal) => {
  return await axios.get(`/AnalysisMobile/menu_list?prj_cd=${prjVal}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getMenuList;
