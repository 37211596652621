import { HiOutlineX } from "react-icons/hi";

const AdminModalContainer = ({ children, setModal, size }) => {
  let modalClassName = "";

  switch (size) {
    case "T":
      modalClassName =
        "z-50 flex h-fit w-[1300px] flex-col items-center justify-start overflow-auto rounded-xl border-2 border-FilterInActiveColor/80 bg-white p-20 pb-20 font-SDGothic text-selectTextColor drop-shadow-2xl";
      break;
    case "SMALL":
      modalClassName =
        "z-50 flex h-fit w-[750px] flex-col items-center justify-start overflow-auto rounded-xl border-2 border-FilterInActiveColor/80 bg-white p-10 font-SDGothic text-selectTextColor drop-shadow-2xl";
      break;
    // You can add more cases for different sizes if needed
    default:
      modalClassName =
        "z-50 flex h-fit w-[1000px] flex-col items-center justify-start overflow-auto rounded-xl border-2 border-FilterInActiveColor/80 bg-white p-20 pb-20 font-SDGothic text-selectTextColor drop-shadow-2xl";
      break;
  }

  const handleClickCancel = () => {
    document.body.style.overflow = "unset";
    setModal(false);
  };

  const handleClickBg = (e) => {
    // document.body.style.overflow = "unset";
    e.stopPropagation();
    // setModal(false);
  };

  const handleClickModal = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={handleClickBg} className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-spinnerBackColor">
      <div onClick={handleClickModal} className={modalClassName}>
        <HiOutlineX onClick={handleClickCancel} className="absolute right-6 top-6 cursor-pointer text-[24px] text-textColor" />
        {children}
      </div>
    </div>
  );
};

export default AdminModalContainer;
