import { useQuery } from "@tanstack/react-query";
import getAdminUserDept from "../api/getAdminUserDept";

const useGetAdminUserDept = (prj_cd) => {
  return useQuery(["adminUserDept"], () => getAdminUserDept(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserDept;
