import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import postAdminStandardQst from "../../../api/Admin/Standard/postAdminStandardQst";
import { useAdminMenuState } from "../../../store/mobile/store";

const usePostAdminStandardQst = (prj_cd) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setAdminMenu = useAdminMenuState((state) => state.setAdminMenu);

  const { mutate, isLoading } = useMutation({
    mutationFn: (postData) => postAdminStandardQst(postData, prj_cd),
    onSuccess: () => {
      queryClient.invalidateQueries(["adminQstSelRes"]);
      alert("표준문항 업로드가 완료되었습니다.");

      setAdminMenu(3);
      navigate("/Admin/qst");
      window.scrollTo(0, 0);
    },
    onError: (err) => {
      alert("표준문항 업로드를 실패했습니다. err :" + err);
    },
    mutationKey: ["adminQstSelRes"],
  });

  return { mutate, isLoading };
};

export default usePostAdminStandardQst;
