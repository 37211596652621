import "moment/locale/ko";
import moment from "moment/moment";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// 로그인 성공 시 userId 값
const useUserIdState = create(
  persist(
    (set) => ({
      userId: "",
      setUserId: (id) => {
        set({ userId: id });
      },
      resetUserId: () => {
        set({ userId: "" });
      },
    }),
    {
      name: "userId",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 선택한 navbar 메뉴
const useMenuState = create(
  persist(
    (set) => ({
      menuVal: "",
      setMenuVal: (menu) => {
        set({ menuVal: menu });
      },
    }),
    {
      name: "menuState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 로그인 성공 시 해당 유저가 접근할 수 있는 프로젝트 정보
const useUserDataState = create(
  persist(
    (set) => ({
      userData: [],
      setUserData: (data) => {
        set({
          userData: data,
        });
      },
    }),
    {
      name: "userData",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 필터링 시 년도 리스트
const useYearOptState = create(
  persist(
    (set) => ({
      yearOpt: [],
      setYearOpt: (data) => {
        set({
          yearOpt: data,
        });
      },
    }),
    {
      name: "yearOpt",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 필터링 시 선택된 년도
const useYearValState = create(
  persist(
    (set) => ({
      yearVal: [],
      setYearVal: (data) => {
        set({
          yearVal: data,
        });
      },
    }),
    {
      name: "yearVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 필터링 시 프로젝트 리스트
const usePrjOptState = create(
  persist(
    (set) => ({
      prjOpt: [],
      setPrjOpt: (data) => {
        set({
          prjOpt: data,
        });
      },
    }),
    {
      name: "prjOpt",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 필터링 시 선택된 프로젝트
const usePrjValState = create(
  persist(
    (set) => ({
      prjVal: { value: "", label: "" },
      setPrjVal: (data) => {
        set({
          prjVal: data,
        });
      },
    }),
    {
      name: "prjVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 필터링 시 병원 리스트
const useHspOptState = create(
  persist(
    (set) => ({
      hspOpt: [],
      setHspOpt: (data) => {
        set({
          hspOpt: data,
        });
      },
    }),
    {
      name: "hspOpt",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 필터링 시 선택된 병원
const useHspValState = create(
  persist(
    (set) => ({
      hspVal: [],
      setHspVal: (data) => {
        set({
          hspVal: data,
        });
      },
    }),
    {
      name: "hspVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 활성화된 메뉴리스트
const useMenuList = create((set) => ({
  menuList: [],
  setMenuList: (data) => {
    set({
      menuList: data,
    });
  },
}));

// 필터 모달 활성화 상태
const useActivePrjModalState = create((set) => ({
  prjModalState: false,
  setPrjModal: (data) => {
    set({
      prjModalState: data,
    });
  },
}));

// default로 랜더링 되어있는 필터 컴포넌트에서 이중 api 요청 방지를 위한 상태
const usePreventRequestFilterState = create((set) => ({
  preventReqFilterState: false,
  setPreventReqFilterState: (data) => {
    set({
      preventReqFilterState: data,
    });
  },
}));

// 선택한 문항 rn
const useQstState = create(
  persist(
    (set) => ({
      qstState: 0,
      setQstState: (data) => {
        set({
          qstState: data,
        });
      },
    }),
    {
      name: "qstVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

//  선택한 문항 qst_cd
const useQstCdState = create(
  persist(
    (set) => ({
      qstCdState: "",
      setQstCdState: (data) => {
        set({
          qstCdState: data,
        });
      },
    }),
    {
      name: "qstCdVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 선택한 문항 svyojt
const useQstSvyOjtState = create(
  persist(
    (set) => ({
      qstSvyOjtState: "",
      setQstSvyOjtState: (data) => {
        set({
          qstSvyOjtState: data,
        });
      },
    }),
    {
      name: "qstSvyOjtVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 문항리스트
const useQstListState = create(
  persist(
    (set) => ({
      qstList: [],
      setQstList: (data) => {
        set({
          qstList: data,
        });
      },
    }),
    {
      name: "qstListVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 선택한 문항
const useSelectQstState = create(
  persist(
    (set) => ({
      selectQstState: [],
      setSelectQstState: (data) => {
        set({
          selectQstState: data,
        });
      },
    }),
    {
      name: "selectQstVal",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// menu modal 활성화
const useMenuListModalControlState = create((set) => ({
  menuListModalControl: false,
  setMenuListModalControl: (data) => {
    set({
      menuListModalControl: data,
    });
  },
}));

// 비중, 인원 옵션 상태
const useUnitState = create((set) => ({
  unitState: "rate",
  setUnitState: (data) => {
    set({
      unitState: data,
    });
  },
}));

// selList modal 활성화
const useSelListModalState = create((set) => ({
  selListModalState: false,
  setSelListModalState: (data) => {
    set({
      selListModalState: data,
    });
  },
}));

// selNm 상태
const useSelListNm = create(
  persist(
    (set) => ({
      selNmState: "전체",
      setSelNmState: (data) => {
        set({
          selNmState: data,
        });
      },
    }),
    {
      name: "selNmState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// rankSelNm 상태
const useRankSelListNm = create(
  persist(
    (set) => ({
      rankSelNmState: "1순위",
      setRankSelNmState: (data) => {
        set({
          rankSelNmState: data,
        });
      },
    }),
    {
      name: "rankSelNmState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// Da08SelNm 상태
const useDa08SelListNm = create(
  persist(
    (set) => ({
      da08SelNmState: "1순위",
      setDa08SelNmState: (data) => {
        set({
          da08SelNmState: data,
        });
      },
    }),
    {
      name: "da08SelNmState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// selList 옵션 상태
const useSelListState = create(
  persist(
    (set) => ({
      selListState: "전체",
      setSelListState: (data) => {
        set({
          selListState: data,
        });
      },
    }),
    {
      name: "selListState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// rankSelList 옵션 상태
const useRankSelListState = create(
  persist(
    (set) => ({
      rankSelListState: "A",
      setRankSelListState: (data) => {
        set({
          rankSelListState: data,
        });
      },
    }),
    {
      name: "rankSelListState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// DA08 selList
const useDa08SelListState = create(
  persist(
    (set) => ({
      da08SelListState: "S001",
      setDa08SelListState: (data) => {
        set({
          da08SelListState: data,
        });
      },
    }),
    {
      name: "da08SelListState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// DA06 chart data
const useChartDataState = create((set) => ({
  chartData: [],
  setChartData: (data) => {
    set({
      chartData: data,
    });
  },
}));

// DA06 table data
const useTableDataState = create(
  persist(
    (set) => ({
      tableData: [],
      setTableData: (data) => {
        set({
          tableData: data,
        });
      },
    }),
    {
      name: "tableData",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 주관식 옵션리스트
const useEssayBtnState = create(
  persist(
    (set) => ({
      essayBtn: ["01", "02", "03", "04", "05", "06", "07"],
      setEssayBtn: (data) => {
        set({
          essayBtn: data,
        });
      },
    }),
    {
      name: "essayBtn",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const useEssayAgeBtnState = create(
  persist(
    (set) => ({
      essayAgeBtn: ["10", "20", "30", "40", "50", "60", "70", "80"],
      setEssayAgeBtn: (data) => {
        set({
          essayAgeBtn: data,
        });
      },
    }),
    {
      name: "essayAgeBtn",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const useEssayListState = create((set) => ({
  listData: [],
  setListData: (data) => {
    set({
      listData: data,
    });
  },
}));

const useGptResState = create((set) => ({
  data: [],
  setData: (data) => {
    set({
      data: data,
    });
  },
}));

const useFinalGptResState = create((set) => ({
  finalData: [],
  setFinalData: (data) => {
    set({
      finalData: data,
    });
  },
}));

const useSummerizeRes = create((set) => ({
  summerizedData: [],
  setSummerizedData: (data) => {
    set({
      summerizedData: data,
    });
  },
}));

// aiChart mode
const useAiModeState = create(
  persist(
    (set) => ({
      aiMode: false,
      setAiMode: (data) => {
        set({
          aiMode: data,
        });
      },
    }),
    {
      name: "aiMode",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const useAiExtractionDataState = create(
  persist(
    (set) => ({
      aiExtractionData: false,
      setAiExtractionData: (data) => {
        set({
          aiExtractionData: data,
        });
      },
    }),
    {
      name: "aiExtractionData",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const useMergedStrState = create((set) => ({
  mergedStr: "",
  setMergedStr: (data) => {
    set({
      mergedStr: data,
    });
  },
}));

const useGptKeywordJsonState = create((set) => ({
  gptKeywordJsonState: [
    {
      name: "sample",
      importance: 0,
    },
  ],
  setGptKeywordJsonState: (data) => {
    set({
      gptKeywordJsonState: data,
    });
  },
}));

// admin store
const useAdminMenuState = create(
  persist(
    (set) => ({
      adminMenu: 0,
      setAdminMenu: (data) => {
        set({
          adminMenu: data,
        });
      },
    }),
    {
      name: "adminMenu",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// adminQstSel
const useAdminQstSelState = create((set) => ({
  adminQstSelState: [],
  setAdminQstSelState: (data) => {
    set({
      adminQstSelState: data,
    });
  },
}));

// admin user tab state
const useAdminUserTabState = create(
  persist(
    (set) => ({
      adminUserTab: 0,
      setAdminUserTab: (data) => {
        set({
          adminUserTab: data,
        });
      },
    }),
    {
      name: "adminUserTab",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const usePcEssayOptState = create((set) => ({
  opt: [],
  setOpt: (data) => {
    set({
      opt: data,
    });
  },
}));

// 기간 전역상태
const usePeriodState = create(
  persist(
    (set) => ({
      /** csscan의 경우 활성화 */
      stPeriod:
        /* true ? null : */ window.location.href.toLowerCase().indexOf("csscan") > -1
          ? moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD")
          : null,
      edPeriod: /* true ? null : */ window.location.href.toLowerCase().indexOf("csscan") > -1 ? moment(new Date()).format("YYYY-MM-DD") : null,
      setStPeriod: (data) => {
        set({
          stPeriod: data,
        });
      },
      setEdPeriod: (data) => {
        set({
          edPeriod: data,
        });
      },
    }),
    {
      name: "periodState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// cs, scan 분기 임시 상태
const useServiceModeState = create(
  persist(
    (set) => ({
      /** csscan의 경우 활성화 */
      serviceMode: window.location.href.toLowerCase().indexOf("csscan") > -1 ? "csscan" : "scan",
      // serviceMode: "csscan",
      setServiceMode: (data) => {
        set({
          serviceMode: data,
        });
      },
    }),
    {
      name: "serviceModeState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// dict
const useComnDict = create(
  persist(
    (set) => ({
      // menuDict
      menuDict: [],
      setMenuDict: (data) => {
        set({
          menuDict: data,
        });
      },

      // workDict
      workDict: [],
      setWorkDict: (data) => {
        set({
          workDict: data,
        });
      },

      // workRateDict
      workRateDict: [],
      setWorkRateDict: (data) => {
        set({
          workRateDict: data,
        });
      },

      // ageDict
      ageDict: [],
      setAgeDict: (data) => {
        set({
          ageDict: data,
        });
      },

      // ageCntDict
      ageCntDict: [],
      setAgeCntDict: (data) => {
        set({
          ageCntDict: data,
        });
      },

      // ageRateDict
      ageRateDict: [],
      setAgeRateDict: (data) => {
        set({
          ageRateDict: data,
        });
      },

      // workCntDict
      workCntDict: [],
      setWorkCntDict: (data) => {
        set({
          workCntDict: data,
        });
      },

      // workCodeDict
      workCodeDict: [],
      setWorkCodeDict: (data) => {
        set({
          workCodeDict: data,
        });
      },

      // ageCodeDict
      ageCodeDict: [],
      setAgeCodeDict: (data) => {
        set({
          ageCodeDict: data,
        });
      },
    }),
    {
      name: "comnState",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const useGptState = create((set) => ({
  gptState: 200,
  setGptState: (data) => {
    set({
      gptState: data,
    });
  },
}));

/**
 * Trend (for only CSSCAN)
 * 월, 분기, 년 에따른 추세 분석을 위한 상태
 * 일단 현재 년도와 월을 저장 (추후에 과거 추세 분석을 위해 생성)
 */
const useTrendState = create((set) => ({
  trendYearState: new Date().getFullYear(),
  setTrendYearState: (tmp) => {
    set({
      trendYearState: tmp,
    });
  },
  trendMonthState: new Date().getMonth() + 1,
  setTrendMonthState: (tmp) => {
    set({
      trendMonthState: tmp,
    });
  },

  // 분기는 따로 저장안해도 될듯? -> 보여줄떄 분기 표현 함수로 변환?
  // trendQuarterState: "",
  // setTrendQuarterState: (tmp) => {
  //   set({
  //     trendQuarterState: tmp,
  //   });
  // },
}));

export {
  useUserIdState,
  useMenuState,
  useUserDataState,
  usePrjValState,
  useMenuList,
  useHspValState,
  useActivePrjModalState,
  usePreventRequestFilterState,
  useYearOptState,
  useYearValState,
  usePrjOptState,
  useHspOptState,
  useQstState,
  useQstCdState,
  useQstSvyOjtState,
  useQstListState,
  useSelectQstState,
  useMenuListModalControlState,
  useUnitState,
  useSelListModalState,
  useSelListState,
  useChartDataState,
  useTableDataState,
  useRankSelListState,
  useDa08SelListState,
  useSelListNm,
  useRankSelListNm,
  useDa08SelListNm,
  useEssayBtnState,
  useEssayAgeBtnState,
  useEssayListState,
  useGptResState,
  useFinalGptResState,
  useSummerizeRes,
  useAiModeState,
  useAiExtractionDataState,
  useMergedStrState,
  useGptKeywordJsonState,
  useAdminMenuState,
  useAdminQstSelState,
  useAdminUserTabState,
  usePcEssayOptState,
  usePeriodState,
  useComnDict,
  useServiceModeState,
  useGptState,
  useTrendState,
};
