const TableBody = (props) => {
  return (
    <div
      className={
        props.idx % 2
          ? `flex h-fit w-full flex-row items-center justify-center border border-r-0 border-t-0 border-tableBorderColor bg-tableBodyGray`
          : `flex h-fit w-full flex-row items-center justify-center border border-r-0 border-t-0 border-tableBorderColor bg-white`
      }
    >
      <div className="flex h-fit w-1/3 flex-col items-start justify-center border-r border-r-tableBorderColor px-4 py-4 font-SDGothic text-[14px] text-selectTextColor">
        {props.props[0]}
      </div>
      <div className="flex h-fit w-1/3 flex-col items-end justify-center border-r border-r-tableBorderColor px-4 py-4 font-SDGothic text-[14px] text-selectTextColor">
        {props.props[1]}명
      </div>
      <div className="flex h-fit w-1/3 flex-col items-end justify-center border-r border-r-tableBorderColor px-4 py-4 font-SDGothic text-[14px] text-selectTextColor">
        {props.props[2]}%
      </div>
    </div>
  );
};

export default TableBody;
