import { useQuery } from "@tanstack/react-query";
import getDa06Res from "../api/getDa06Res";
import { useChartDataState, useSelListState, useTableDataState } from "../store/mobile/store";

const useGetDa06Res = (prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate) => {
  const selList = useSelListState((state) => state.selListState);

  const setDa06ChartData = useChartDataState((state) => state.setChartData);
  const setDa06TableData = useTableDataState((state) => state.setTableData);

  return useQuery(["da06"], () => getDa06Res(prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      if (selList !== "전체") {
        setDa06ChartData(res.data[1]);
      } else {
        setDa06ChartData(res.data[1]);
        setDa06TableData(res.data[1]);
      }
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa06Res;
