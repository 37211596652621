// import { ageDict, workDict } from "../../../dict/mobile/dict";
import { useComnDict, useMenuState, useTableDataState } from "../../../store/mobile/store";

const Da06Table = (props) => {
  const workDict = useComnDict((state) => state.workDict);
  const ageDict = useComnDict((state) => state.ageDict);

  const da06TableDataState = useTableDataState((state) => state.tableData);

  let da06TableData = props?.data === null ? da06TableDataState : props?.data;

  const menuState = useMenuState((state) => state.menuVal);

  return (
    <div className="flex h-fit w-full flex-row items-start justify-start overflow-auto border-l border-qstTitleBorder">
      {/* 구분 */}
      <div className="sticky left-0 flex h-fit w-full   flex-col items-center justify-start">
        <div className="flex h-fit w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-14 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
          {/* {menuState === "MEM" ? `직종` : `연령대`} */}
          구분
        </div>
        {menuState === "MEM"
          ? workDict.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    idx % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {el.sel_nm}
                </div>
              );
            })
          : menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS"
          ? ageDict.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    idx % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {el.sel_nm}
                </div>
              );
            })
          : null}
      </div>

      {da06TableData?.slice(1, da06TableData?.length).map((el, idx) => {
        let value = [];
        if (menuState === "MEM") {
          workDict.forEach((work, idx3) => {
            value.push(el[work.sel_cd]);
          });
        } else if (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") {
          ageDict.forEach((work, idx3) => {
            value.push(el[work.sel_cd]);
          });
        }

        return (
          <div key={idx} className=" h-fit w-fit items-center justify-start">
            <div className="flex h-fit w-fit  min-w-full  flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
              {el.sel_NM}
            </div>
            {value.map((data, idx2) => {
              return (
                <div
                  key={idx2}
                  className={
                    idx2 % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4  font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {idx === 0
                    ? `${data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`
                    : `${data?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Da06Table;
