import { useMutation } from "@tanstack/react-query";
import postAIChartRes from "../api/postAIChartRes";

const usePostAIChartRes = () => {
  return useMutation(postAIChartRes, {
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAIChartRes;
