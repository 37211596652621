import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import Da03Chart from "../../components/mobile/chart/Da03Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da03Table from "../../components/mobile/table/Da03Table";
import useGetDa03Res from "../../hooks/useGetDa03Res";
import { useMenuState, usePeriodState, usePrjValState, useSelectQstState, useUnitState } from "../../store/mobile/store";

const DA03 = () => {
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const prjVal = usePrjValState((state) => state.prjVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const [rendering, setRendering] = useState(false);

  const { refetch, data } = useGetDa03Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, startDate, endDate);

  const chartInitData = data?.data.chart;

  const tableInitData = data?.data.table;

  // chartdata
  let chartCategories = [];

  let selfRateDb = [];
  let nonSelfRateDb = [];
  let chartRateData = [selfRateDb, nonSelfRateDb];

  let selfCntDb = [];
  let nonSelfCntDb = [];
  let chartCntData = [selfCntDb, nonSelfCntDb];

  chartInitData?.forEach((el) => {
    chartCategories.push(el.assign);

    selfRateDb.push(+el.self > 0 ? +el.self : 0);
    nonSelfRateDb.push(+el.nonself > 0 ? +el.nonself : 0);

    selfCntDb.push(+el.self_CNT > 0 ? +el.self_CNT : 0);
    nonSelfCntDb.push(+el.nonself_CNT > 0 ? +el.nonself_CNT : 0);
  });

  // tabledata
  let selNmData = [];
  let totData = [];
  let docData = [];
  let nrData = [];
  let hlthData = [];
  let phamData = [];
  let officeData = [];
  let techData = [];
  let etcData = [];

  let tableProps = [totData, docData, nrData, hlthData, phamData, officeData, techData, etcData];

  let tableColNm = ["전체", "의사직", "간호직", "보건직", "약무직", "사무직", "기술직", "기타"];

  tableInitData?.forEach((el, idx) => {
    selNmData.push(el.sel_NM);

    if (idx === 0 && tableProps) {
      tableColNm?.forEach((item, idx2) => {
        tableProps[idx2].push(item);
      });
    }
    totData.push(unitState === "rate" ? (el.tot ? el.tot : 0) : el.tot_CNT ? el.tot_CNT : 0);
    docData.push(unitState === "rate" ? (el.doc ? el.doc : 0) : el.doc_CNT ? el.doc_CNT : 0);
    nrData.push(unitState === "rate" ? (el.nr ? el.nr : 0) : el.nr_CNT ? el.nr_CNT : 0);
    hlthData.push(unitState === "rate" ? (el.hlth ? el.hlth : 0) : el.hlth_CNT ? el.hlth_CNT : 0);
    phamData.push(unitState === "rate" ? (el.pham ? el.pham : 0) : el.pham_CNT ? el.pham_CNT : 0);
    officeData.push(unitState === "rate" ? (el.office ? el.office : 0) : el.office_CNT ? el.office_CNT : 0);
    techData.push(unitState === "rate" ? (el.tech ? el.tech : 0) : el.tech_CNT ? el.tech_CNT : 0);
    etcData.push(unitState === "rate" ? (el.etc ? el.etc : 0) : el.etc_CNT ? el.etc_CNT : 0);
  });

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 300);
  }, [selectQstState, startDate, endDate]);
  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {selectQstState.keyword}
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>
          {rendering ? (
            <>
              <Da03Chart categories={chartCategories} rateDb={chartRateData} cntDb={chartCntData} />
              <Da03Table data={tableProps} selNm={selNmData} />
            </>
          ) : null}
        </div>

        <TabBar />
      </div>
    </>
  );
};

export default DA03;
