import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminUserUser from "../api/postAdminUserUser";

const usePostAdminUserUser = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminUserUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserUser"]);
    },
    onError: (err) => {
      console.log(err);
      alert("이미 등록된 직번입니다");
    },
  });
};

export default usePostAdminUserUser;
