import Highcharts from "highcharts";
import HCWordcloud from "highcharts/modules/wordcloud";
import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { SyncLoader } from "react-spinners";
import EssayChart from "../../components/mobile/chart/EssayChart";
import TabBar from "../../components/mobile/common/TabBar";
import ChatGptComp from "../../components/mobile/essay/ChatGptComp";
import EssayAnswerComp from "../../components/mobile/essay/EssayAnswerComp";
import EssayFilterModal from "../../components/mobile/essay/EssayFilterModal";
import TopBtn from "../../components/mobile/TopBtn";
// import { ageCodeDict, workCodeDict } from "../../dict/mobile/dict";
import ToggleComp from "../../components/mobile/essay/ToggleComp";
import useGetAIChartRes from "../../hooks/useGetAIChartRes";
import useGetEssayChartRes from "../../hooks/useGetEssayChartRes";
import useGetEssayListRes from "../../hooks/useGetEssayListRes";
import usePostExtractKeyword from "../../hooks/usePostExtractKeyword";
import useScrollPos from "../../hooks/useScrollPos";
import {
  useAiExtractionDataState,
  useAiModeState,
  useComnDict,
  useEssayAgeBtnState,
  useEssayBtnState,
  useEssayListState,
  useMenuState,
  useMergedStrState,
  usePeriodState,
  usePrjValState,
  useSelectQstState,
  useUserIdState,
} from "../../store/mobile/store";

const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94"];

HCWordcloud(Highcharts);

const Essay = () => {
  const ageCodeDict = useComnDict((state) => state.ageCodeDict);
  const workCodeDict = useComnDict((state) => state.workCodeDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const prjVal = usePrjValState((state) => state.prjVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  let scrollYPos = useScrollPos();
  // 유저정보
  const userInfo = useUserIdState((state) => state.userId);

  // 답변리스트 데이터
  const setListDb = useEssayListState((state) => state.setListData);

  // 화면 스크롤 감지용 요소
  const containerElement = document.getElementById("con");

  const [rendering, setRendering] = useState(false);

  // 검색조건 모달 제어 상태
  const [filterModalState, setFilterModalState] = useState(false);

  // 무한스크롤 용 spinner 제어
  const [fetching, setFetching] = useState(false);

  // 무한스크롤 현재 페이지
  const [page, setPage] = useState(0);

  // 검색 키워드
  const [searchWord, setSearchWord] = useState("A");

  // chart aimode
  const aiMode = useAiModeState((state) => state.aiMode);
  const setAiMode = useAiModeState((state) => state.setAiMode);
  const [aiSwitchRender, setAiSwitchRender] = useState(true);
  const [aiRender, setAiRender] = useState(false); // 첫번째 랜더링이 되었다면 true, 아니면 false

  // chart Ai Extraction Data
  const setAiExtractionData = useAiExtractionDataState((state) => state.setAiExtractionData);
  const aiExtractionData = useAiExtractionDataState((state) => state.aiExtractionData);

  // 검색조건 모달 - 최종 선택 조건(구성원)
  const finalActiveBtnList = useEssayBtnState((state) => state.essayBtn);
  const setFinalActiveBtnList = useEssayBtnState((state) => state.setEssayBtn);

  // 검색조건 모달 - 최종 선택 조건(내원고객, 지역주민)
  const finalActiveAgeBtnList = useEssayAgeBtnState((state) => state.essayAgeBtn);
  const setFinalActiveAgeBtnList = useEssayAgeBtnState((state) => state.setEssayAgeBtn);

  // chartData refetch
  const {
    refetch: chartRefetch,
    data: chartData,
    isRefetching: chartRefetching,
  } = useGetEssayChartRes(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.svy_OJT,
    menuState === "MEM" ? finalActiveBtnList : finalActiveAgeBtnList,
    startDate,
    endDate
  );

  // chartList refetch
  const {
    refetch: listRefetch,
    data: listData,
    isRefetching,
  } = useGetEssayListRes(
    prjVal.value,
    selectQstState.qst_CD,
    selectQstState.svy_OJT,
    menuState === "MEM" ? finalActiveBtnList : finalActiveAgeBtnList,
    searchWord === "" ? "A" : searchWord,
    15,
    page * 15,
    startDate,
    endDate
  );

  const { isLoading: extractKeywordLoading } = usePostExtractKeyword();

  const { refetch: aiChartRefetch } = useGetAIChartRes(
    prjVal.value,
    selectQstState.svy_OJT,
    selectQstState.qst_CD,
    (menuState === "MEM" && finalActiveBtnList.length === 7) ||
      ((menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") && finalActiveAgeBtnList.length === 8)
      ? ["-"]
      : menuState === "MEM"
      ? finalActiveBtnList
      : finalActiveAgeBtnList,
    startDate ? `${startDate}-${endDate?.substring(0, 10)}` : "-"
  );

  // console.log(
  //   extractKeywordLoading,
  //   extractKeywordSuccess,
  //   extractKeywordError
  // );
  let initData = chartData?.data;

  let initListData = listData?.data;

  // 전체 페이지 수
  let totalPage = initListData ? initListData[0]?.tot_PAGE : 0;

  let selectOptArr = [];

  // 검색조건 타이틀 추출 알고리즘
  // 구성원
  if (menuState === "MEM") {
    workCodeDict.forEach((el, idx) => {
      if (finalActiveBtnList.includes(el.sel_code)) {
        selectOptArr.push(el.sel_nm);
      }
    });
  }

  // 내원고객, 지역주민
  else {
    ageCodeDict.forEach((el, idx) => {
      if (finalActiveAgeBtnList.includes(el.sel_code)) {
        selectOptArr.push(el.sel_nm);
      }
    });
  }

  // data str 합치기
  // const [mergedStr, setMergedStr] = useState("");
  const mergedStr = useMergedStrState((state) => state.mergedStr);
  const setMergedStr = useMergedStrState((state) => state.setMergedStr);
  const [wordCountData, setWordCountData] = useState([]);

  // 단어 빈도수 추출 함수
  const extractWordCount = (text) => {
    const lines = text ? text?.replace(/[():'?0-9]+/g, "").split(/[,\. \n]+/g) : [""];

    // console.log(lines);

    const data = lines
      .reduce((arr, word, idx) => {
        let obj = Highcharts.find(arr, (obj) => obj.name === word);

        // console.log(obj);

        if (obj) {
          obj.weight += 1;
        } else {
          obj = {
            name: word,
            weight: 1,
            color: colors[idx % 8],
          };
          arr.push(obj);
        }
        return arr;
      }, [])
      .sort((a, b) => b.weight - a.weight)
      .slice(0, 100);

    return data;
  };

  const handleClickBg = () => {
    setFilterModalState(false);
  };

  const handleClickFilter = () => {
    setFilterModalState(true);
  };

  // chart data (단어별 빈도수)
  useEffect(() => {
    let str = "";
    let chartAiStr = "";

    chartData?.data?.forEach((el, idx) => {
      str = str + "\n" + el.sbjt_ANSW;

      if (el.sbjt_AI_ANSW !== null && el.sbjt_AI_ANSW !== "" && el.sbjt_AI_ANSW !== " ") {
        chartAiStr = chartAiStr + "\n" + el.sbjt_AI_ANSW;
      }
    });

    if (chartAiStr.length === 0) {
      setAiMode(false);
      setAiSwitchRender(false);
    } else {
      setAiMode(true);
      setAiSwitchRender(true);
    }
    setAiRender(true);

    setMergedStr(str);

    // if (chartAiStr.length === 0) {
    // }

    if (selectQstState?.qst_TYP === "Q02" || selectQstState?.qst_TYP === "Q14") {
      setWordCountData(extractWordCount(aiMode && chartAiStr.length > 0 ? chartAiStr : str));
    }
  }, [chartData?.data]);

  useEffect(() => {
    if (aiRender) {
      let str = "";
      let chartAiStr = "";
      chartData?.data?.forEach((el, idx) => {
        str = str + "\n" + el.sbjt_ANSW;
      });

      chartData?.data?.forEach((el, idx) => {
        if (el.sbjt_AI_ANSW !== null && el.sbjt_AI_ANSW !== "" && el.sbjt_AI_ANSW !== " ") {
          chartAiStr = chartAiStr + "\n" + el.sbjt_AI_ANSW;
        }
      });

      if (chartAiStr.length === 0) {
        setAiMode(false);
      }
      setMergedStr(str);
      // // if (chartAiStr.length === 0) {
      // // }

      if (selectQstState?.qst_TYP === "Q02" || selectQstState?.qst_TYP === "Q14") {
        if (aiMode && chartAiStr.length > 0) {
          setWordCountData(extractWordCount(chartAiStr) === null ? {} : extractWordCount(chartAiStr));
          // console.log(extractWordCount(chartAiStr));
        } else {
          setWordCountData(extractWordCount(str));
        }
        // setWordCountData(extractWordCount(aiMode && chartAiStr.length > 0 ? chartAiStr : str));
      }
    }
  }, [aiMode]);

  // console.log(mergedStr);

  // 스크롤 이벤트 발생시 페이지 변경
  useEffect(() => {
    if (scrollYPos + window.innerHeight > containerElement?.scrollHeight - 400) {
      if (page < totalPage && !isRefetching) {
        setFetching(true);
        setTimeout(() => {
          setFetching(false);
        }, 300);
        setPage(page + 1);
      }
    }
  }, [scrollYPos]);

  // unmount시 상태 초기화
  useEffect(() => {
    setTimeout(() => {
      setRendering(true);
    }, 800);

    setTimeout(() => {
      setPage(0);
    }, 500);

    return () => {
      // setAiMode(true);
      setAiExtractionData([]);
      setWordCountData([]);
      setListDb([]);
      setSearchWord("A");
      setRendering(false);
      setFinalActiveBtnList(["01", "02", "03", "04", "05", "06", "07"]);
      setFinalActiveAgeBtnList(["10", "20", "30", "40", "50", "60", "70", "80"]);
    };
  }, [selectQstState]);

  // 기간 추가
  // 검색조건 변경 시 실행
  useEffect(() => {
    // 차트데이터 FETCH
    chartRefetch();
    listRefetch();
    setPage(0);

    if (aiExtractionData.length !== 0) {
      // setAiMode(true);
      setAiExtractionData([]);
    }
  }, [finalActiveBtnList, finalActiveAgeBtnList, startDate, endDate]);

  // 페이지 변경 시 답변리스트 refetching
  useEffect(() => {
    listRefetch();
  }, [page]);

  // 키워드 검색 시 답변리스트 refetching
  useEffect(() => {
    if (searchWord !== "A") {
      listRefetch();
    }
  }, [searchWord]);

  return (
    <>
      {!rendering || !chartData || chartRefetching ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}

      {filterModalState ? (
        <div onClick={handleClickBg} className="fixed top-0 z-50 flex h-full w-screen flex-col items-center justify-start bg-spinnerBackColor"></div>
      ) : null}

      {scrollYPos > 550 ? <TopBtn /> : null}

      <EssayFilterModal
        btnList={finalActiveBtnList}
        setBtnList={setFinalActiveBtnList}
        ageBtnList={finalActiveAgeBtnList}
        setAgeBtnList={setFinalActiveAgeBtnList}
        setPage={setPage}
        menuState={menuState}
        modalState={filterModalState}
        setModalState={setFilterModalState}
        opt={initData?.opt}
      />
      <div className="mb-24 flex h-fit w-full flex-col  items-center justify-start font-SDGothic">
        <div className="relative flex h-fit w-full flex-row items-center justify-between  border-b border-qstTitleBorder px-2 py-3 text-[16px] font-bold text-selectTextColor">
          <BsDot className="absolute left-2 text-orangeColor" />
          <div className="hide-scrollbar relative  ml-7 flex  h-fit w-[70%]  flex-row items-center justify-start overflow-auto whitespace-pre py-4 ">
            {selectQstState.keyword}
          </div>

          {/* header */}
          <div className="flex h-fit w-fit flex-row items-center justify-center">
            <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
              {(selectOptArr.length === 7 && menuState === "MEM") ||
              (selectOptArr.length === 8 && (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS"))
                ? "전체"
                : selectOptArr.length > 1
                ? `${selectOptArr[0]}+`
                : `${selectOptArr[0]}`}
            </div>
            <HiChevronDoubleLeft onClick={handleClickFilter} className="mr-2 text-[20px] text-orangeColor" />
          </div>
        </div>

        {/* chart */}
        {rendering && chartData ? (
          <div className="flex h-fit w-full flex-col items-center justify-start">
            {selectQstState?.qst_TYP === "Q02" || selectQstState?.qst_TYP === "Q14" ? (
              <>
                {/* ai mode comp */}
                {aiSwitchRender ? (
                  <div className="relative mt-4 flex h-fit w-full flex-row items-center justify-between px-4 font-SDGothic text-[14px] font-bold text-selectTextColor/80">
                    <div className="ml-2 flex h-fit w-fit flex-col items-center justify-center">주요 키워드</div>
                    <div className=" flex h-fit w-fit flex-row items-center justify-center">
                      <div className="mr-2 flex h-full w-fit flex-col items-center justify-center">AI모드</div>
                      <ToggleComp mode={aiMode} />
                    </div>
                    {/* <div className="absolute -bottom-10 right-10 z-50 flex h-fit w-fit flex-col items-center justify-center rounded-md border border-blue1Color/50 bg-white p-2 text-[10px] text-selectTextColor/80">
                    IBM AI Extraction Keyword 기술 사용
                  </div> */}
                  </div>
                ) : null}

                <div className="flex h-[350px] w-full flex-col items-center justify-center">
                  {/* wordGraph */}
                  {!aiMode && wordCountData.length === 0 ? (
                    <SyncLoader color="#1686FF" />
                  ) : (
                    <>{wordCountData && !chartRefetching ? <EssayChart data={wordCountData} /> : null}</>
                  )}
                </div>

                {/* chatGPT Area */}
                <ChatGptComp
                  data={mergedStr}
                  selectOptArr={selectOptArr}
                  filterOpt={menuState === "MEM" ? finalActiveBtnList : finalActiveAgeBtnList}
                  qst={selectQstState.qst}
                />
              </>
            ) : null}
            {/* Search Area */}
            <EssayAnswerComp setPage={setPage} setSearchWord={setSearchWord} />
            {fetching ? (
              <div className="mb-32 mt-10 flex h-fit w-full flex-col items-center justify-center p-3">
                <SyncLoader color="#1686FF" />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <TabBar />
    </>
  );
};

export default Essay;

// {aiMode ? (
//   <div className="flex h-[350px] w-full flex-col items-center justify-center">
//     {aiExtractionData.length === 0 && aiMode ? (
//       <SyncLoader color="#1686FF" />
//     ) : (
//       <>
//         {/* wordGraph */}
//         {aiExtractionData.length ? <EssayAiChart data={aiExtractionData} /> : null}
//       </>
//     )}
//   </div>
// ) : (
//   <div className="flex h-[350px] w-full flex-col items-center justify-center">
//     {/* wordGraph */}
//     {wordCountData && !chartRefetching ? <EssayChart data={wordCountData} /> : null}
//   </div>
// )}
