import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminUserEtc from "../api/postAdminUserEtc";

const usePostAdminUserEtc = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminUserEtc, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserEtc"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminUserEtc;
