import { useQuery } from "@tanstack/react-query";
import getDepQstList from "../api/getDepQstList";
import { useQstListState } from "../store/mobile/store";

const useGetDepQstList = (prj_cd) => {
  const setQstList = useQstListState((state) => state.setQstList);

  return useQuery(["depQst"], () => getDepQstList(prj_cd), {
    enabled: false,
    onSuccess: (res) => {
      setQstList(res.data);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDepQstList;
