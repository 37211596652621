import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA07 from "../../components/pc/BarColumnChartDA07";
import DA07Table from "../../components/pc/DA07Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA07AgeGrp } from "../../hooks/useGraphDA07AgeGrp";
import { useGraphDA07Area } from "../../hooks/useGraphDA07Area";
import { useGraphDA07Assign } from "../../hooks/useGraphDA07Assign";
import { useGraphDA07Doc } from "../../hooks/useGraphDA07Doc";
import { useGraphDA07Etc1 } from "../../hooks/useGraphDA07Etc1";
import { useGraphDA07Etc2 } from "../../hooks/useGraphDA07Etc2";
import { useGraphDA07Etc3 } from "../../hooks/useGraphDA07Etc3";
import { useGraphDA07MainDept } from "../../hooks/useGraphDA07MainDept";
import { useGraphDA07Normal } from "../../hooks/useGraphDA07Normal";
import { useGraphDA07Octy } from "../../hooks/useGraphDA07Octy";
import { useGraphDA07Sex } from "../../hooks/useGraphDA07Sex";
import { useGraphDA07WrkYyGrp } from "../../hooks/useGraphDA07WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA07AgeGrp } from "../../hooks/useTableDA07AgeGrp";
import { useTableDA07Assign } from "../../hooks/useTableDA07Assign";
import { useTableDA07Doc } from "../../hooks/useTableDA07Doc";
import { useTableDA07Octy } from "../../hooks/useTableDA07Octy";
import { useTableDA07Sex } from "../../hooks/useTableDA07Sex";
import { useTableDA07WrkYyGrp } from "../../hooks/useTableDA07WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA07Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [RadioDA07DA11Check, setRadioDA07DA11Check] = useState("1");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const changeDA07DA11 = (text) => {
    setRadioDA07DA11Check(text);
  };

  const { isGraphDA07NormalLoading, isGraphDA07NormalError, GraphDA07NormalData, isGraphDA07NormalSuccess, GraphDA07NormalRefetch } =
    useGraphDA07Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA07AssignLoading, isGraphDA07AssignError, GraphDA07AssignData, isGraphDA07AssignSuccess, GraphDA07AssignRefetch } =
    useGraphDA07Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA07DocLoading, isGraphDA07DocError, GraphDA07DocData, isGraphDA07DocSuccess, GraphDA07DocRefetch } = useGraphDA07Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA07OctyLoading, isGraphDA07OctyError, GraphDA07OctyData, isGraphDA07OctySuccess, GraphDA07OctyRefetch } = useGraphDA07Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA07WrkYyGrpLoading, isGraphDA07WrkYyGrpError, GraphDA07WrkYyGrpData, isGraphDA07WrkYyGrpSuccess, GraphDA07WrkYyGrpRefetch } =
    useGraphDA07WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA07AgeGrpLoading, isGraphDA07AgeGrpError, GraphDA07AgeGrpData, isGraphDA07AgeGrpSuccess, GraphDA07AgeGrpRefetch } =
    useGraphDA07AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA07SexLoading, isGraphDA07SexError, GraphDA07SexData, isGraphDA07SexSuccess, GraphDA07SexRefetch } = useGraphDA07Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA07AreaLoading, isGraphDA07AreaError, GraphDA07AreaData, isGraphDA07AreaSuccess, GraphDA07AreaRefetch } = useGraphDA07Area(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA07MainDeptLoading, isGraphDA07MainDeptError, GraphDA07MainDeptData, isGraphDA07MainDeptSuccess, GraphDA07MainDeptRefetch } =
    useGraphDA07MainDept(PrjCd, SvyOjt, QstCd, "", "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA07Etc1Loading, isGraphDA07Etc1Error, GraphDA07Etc1Data, isGraphDA07Etc1Success, GraphDA07Etc1Refetch } = useGraphDA07Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA07Etc2Loading, isGraphDA07Etc2Error, GraphDA07Etc2Data, isGraphDA07Etc2Success, GraphDA07Etc2Refetch } = useGraphDA07Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA07Etc3Loading, isGraphDA07Etc3Error, GraphDA07Etc3Data, isGraphDA07Etc3Success, GraphDA07Etc3Refetch } = useGraphDA07Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA07AgeGrpLoading, isTableDA07AgeGrpError, TableDA07AgeGrpData, isTableDA07AgeGrpSuccess, TableDA07AgeGrpRefetch } =
    useTableDA07AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA07AssignLoading, isTableDA07AssignError, TableDA07AssignData, isTableDA07AssignSuccess, TableDA07AssignRefetch } =
    useTableDA07Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA07DocLoading, isTableDA07DocError, TableDA07DocData, isTableDA07DocSuccess, TableDA07DocRefetch } = useTableDA07Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA07OctyLoading, isTableDA07OctyError, TableDA07OctyData, isTableDA07OctySuccess, TableDA07OctyRefetch } = useTableDA07Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA07SexLoading, isTableDA07SexError, TableDA07SexData, isTableDA07SexSuccess, TableDA07SexRefetch } = useTableDA07Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA07WrkYyGrpLoading, isTableDA07WrkYyGrpError, TableDA07WrkYyGrpData, isTableDA07WrkYyGrpSuccess, TableDA07WrkYyGrpRefetch } =
    useTableDA07WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioDA07DA11Check.length > 0 && RadioDA07DA11Check[0] !== undefined) {
      if (RadioTypeCheck === "All") {
        if (Object.keys(GraphSet[0]).length > 0) {
          let graphList = GraphSet.map((el) => el.value);

          if (graphList.includes("Normal")) {
            GraphDA07NormalRefetch();
          }

          if (graphList.includes("Assign")) {
            GraphDA07AssignRefetch();
          }

          if (graphList.includes("WrkYyGrp")) {
            GraphDA07WrkYyGrpRefetch();
          }

          if (graphList.includes("Doc")) {
            GraphDA07DocRefetch();
          }

          if (graphList.includes("Octy")) {
            GraphDA07OctyRefetch();
          }

          if (graphList.includes("AgeGrp")) {
            GraphDA07AgeGrpRefetch();
          }

          if (graphList.includes("Sex")) {
            GraphDA07SexRefetch();
          }

          if (graphList.includes("Area")) {
            GraphDA07AreaRefetch();
          }

          if (graphList.includes("MainDept")) {
            GraphDA07MainDeptRefetch();
          }

          if (graphList.includes("Etc1")) {
            GraphDA07Etc1Refetch();
          }

          if (graphList.includes("Etc2")) {
            GraphDA07Etc2Refetch();
          }

          if (graphList.includes("Etc3")) {
            GraphDA07Etc3Refetch();
          }
        }
      }
      if (RadioTypeCheck === "AgeGrp") {
        GraphDA07AgeGrpRefetch();
        TableDA07AgeGrpRefetch();
      } else if (RadioTypeCheck === "Sex") {
        GraphDA07SexRefetch();
        TableDA07SexRefetch();
      } else if (RadioTypeCheck === "Assign") {
        GraphDA07AssignRefetch();
        TableDA07AssignRefetch();
      } else if (RadioTypeCheck === "Doc") {
        GraphDA07DocRefetch();
        TableDA07DocRefetch();
      } else if (RadioTypeCheck === "Octy") {
        GraphDA07OctyRefetch();
        TableDA07OctyRefetch();
      } else if (RadioTypeCheck === "WrkYyGrp") {
        GraphDA07WrkYyGrpRefetch();
        TableDA07WrkYyGrpRefetch();
      } else if (RadioTypeCheck === "MainDept") {
        GraphDA07MainDeptRefetch();
      }
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, RadioDA07DA11Check, startDate, endDate, GraphSet]);

  //   useEffect(() => {
  //     if (!isGraphDA07NormalSuccess || GraphDA07NormalData === null || GraphDA07NormalData === undefined || GraphDA07NormalData.length === 0) {
  //       GraphDA07NormalRefetch();
  //     }
  //   }, [
  //     GraphDA07NormalData,
  //     isGraphDA07NormalSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07AssignSuccess || GraphDA07AssignData === null || GraphDA07AssignData === undefined || GraphDA07AssignData.length === 0) {
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  //     }
  //   }, [
  //     GraphDA07AssignData,
  //     isGraphDA07AssignSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07DocSuccess || GraphDA07DocData === null || GraphDA07DocData === undefined || GraphDA07DocData.length === 0) {
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  //     }
  //   }, [
  //     GraphDA07DocData,
  //     isGraphDA07DocSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07OctySuccess || GraphDA07OctyData === null || GraphDA07OctyData === undefined || GraphDA07OctyData.length === 0) {
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  //     }
  //   }, [
  //     GraphDA07OctyData,
  //     isGraphDA07OctySuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07WrkYyGrpSuccess || GraphDA07WrkYyGrpData === null || GraphDA07WrkYyGrpData === undefined || GraphDA07WrkYyGrpData.length === 0) {
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07WrkYyGrpData,
  //     isGraphDA07WrkYyGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07AgeGrpSuccess || GraphDA07AgeGrpData === null || GraphDA07AgeGrpData === undefined || GraphDA07AgeGrpData.length === 0) {
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07AgeGrpData,
  //     isGraphDA07AgeGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07SexSuccess || GraphDA07SexData === null || GraphDA07SexData === undefined || GraphDA07SexData.length === 0) {
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07SexData,
  //     isGraphDA07SexSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07AreaSuccess || GraphDA07AreaData === null || GraphDA07AreaData === undefined || GraphDA07AreaData.length === 0) {
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07AreaData,
  //     isGraphDA07AreaSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07MainDeptSuccess || GraphDA07MainDeptData === null || GraphDA07MainDeptData === undefined || GraphDA07MainDeptData.length === 0) {
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07MainDeptData,
  //     isGraphDA07MainDeptSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07Etc1Success || GraphDA07Etc1Data === null || GraphDA07Etc1Data === undefined || GraphDA07Etc1Data.length === 0) {
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07Etc1Data,
  //     isGraphDA07Etc1Success,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07Etc2Success || GraphDA07Etc2Data === null || GraphDA07Etc2Data === undefined || GraphDA07Etc2Data.length === 0) {
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07Etc2Data,
  //     isGraphDA07Etc2Success,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isGraphDA07Etc3Success || GraphDA07Etc3Data === null || GraphDA07Etc3Data === undefined || GraphDA07Etc3Data.length === 0) {
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     GraphDA07Etc3Data,
  //     isGraphDA07Etc3Success,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA07AgeGrpSuccess || TableDA07AgeGrpData === null || TableDA07AgeGrpData === undefined || TableDA07AgeGrpData.length === 0) {
  //       TableDA07AgeGrpRefetch();
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA07AgeGrpData,
  //     isTableDA07AgeGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA07AssignSuccess || TableDA07AssignData === null || TableDA07AssignData === undefined || TableDA07AssignData.length === 0) {
  //       TableDA07AssignRefetch();
  //       TableDA07AgeGrpRefetch();
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA07AssignData,
  //     isTableDA07AssignSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA07DocSuccess || TableDA07DocData === null || TableDA07DocData === undefined || TableDA07DocData.length === 0) {
  //       TableDA07DocRefetch();
  //       TableDA07AssignRefetch();
  //       TableDA07AgeGrpRefetch();
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA07DocData,
  //     isTableDA07DocSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA07OctySuccess || TableDA07OctyData === null || TableDA07OctyData === undefined || TableDA07OctyData.length === 0) {
  //       TableDA07OctyRefetch();
  //       TableDA07DocRefetch();
  //       TableDA07AssignRefetch();
  //       TableDA07AgeGrpRefetch();
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA07OctyData,
  //     isTableDA07OctySuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA07SexSuccess || TableDA07SexData === null || TableDA07SexData === undefined || TableDA07SexData.length === 0) {
  //       TableDA07SexRefetch();
  //       TableDA07OctyRefetch();
  //       TableDA07DocRefetch();
  //       TableDA07AssignRefetch();
  //       TableDA07AgeGrpRefetch();
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA07SexData,
  //     isTableDA07SexSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  //   useEffect(() => {
  //     if (!isTableDA07WrkYyGrpSuccess || TableDA07WrkYyGrpData === null || TableDA07WrkYyGrpData === undefined || TableDA07WrkYyGrpData.length === 0) {
  //       TableDA07WrkYyGrpRefetch();
  //       TableDA07SexRefetch();
  //       TableDA07OctyRefetch();
  //       TableDA07DocRefetch();
  //       TableDA07AssignRefetch();
  //       TableDA07AgeGrpRefetch();
  //       GraphDA07Etc3Refetch();
  //       GraphDA07Etc2Refetch();
  //       GraphDA07Etc1Refetch();
  //       GraphDA07MainDeptRefetch();
  //       GraphDA07AreaRefetch();
  //       GraphDA07SexRefetch();
  //       GraphDA07AgeGrpRefetch();
  //       GraphDA07DocRefetch();
  //       GraphDA07NormalRefetch();
  //       GraphDA07AssignRefetch();
  // GraphDA07OctyRefetch();
  // GraphDA07WrkYyGrpRefetch();
  //     }
  //   }, [
  //     TableDA07WrkYyGrpData,
  //     isTableDA07WrkYyGrpSuccess,
  //     PrjCd,
  //     SvyOjt,
  //     QstCd,
  //     SortType,
  //     RadioNumCheck,
  //     RadioTypeCheck,
  //     RadioDA07DA11Check,
  //     startDate,
  //     endDate,
  //   ]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA07"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={changeDA07DA11}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={RadioDA07DA11Check}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA07NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA07AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA07DocData;
              } else if (data.value === "Octy") {
                vType = "column";
                vStack = "Y";
                vName = "octy";
                vData = GraphDA07OctyData;
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vStack = "Y";
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA07WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA07SexData;
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vStack = "Y";
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA07AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA07AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA07MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA07Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA07Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA07Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA07
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    selectedDA07DA11={RadioDA07DA11Check}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA07NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA07AssignData;
              vTData = TableDA07AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA07DocData;
              vTData = TableDA07DocData;
            } else if (data.value === "Octy") {
              vType = "column";
              vStack = "Y";
              vName = "octy";
              vData = GraphDA07OctyData;
              vTData = TableDA07OctyData;
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vStack = "Y";
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA07WrkYyGrpData;
              vTData = TableDA07WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA07SexData;
              vTData = TableDA07SexData;
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vStack = "Y";
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA07AgeGrpData;
              vTData = TableDA07AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA07AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA07MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA07Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA07Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA07Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA07Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        RadioDA07DA11Check={RadioDA07DA11Check}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA07
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioNumCheck}
                    selectedDA07DA11={RadioDA07DA11Check}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA07Page;
