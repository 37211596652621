const AdminIntroSentenseComp = ({
  mode,
  titleValue,
  setTitleValue,
  contentValue,
  setContentValue,
  guideType,
  setGuideType,
  tailValue,
  setTailValue,
  setIntroMode,
  handleClickIntroFetch,
  serverTitleData,
  serverContentData,
  serverGuideType,
  serverTailData,
}) => {
  const handleChangeTitle = (e) => {
    setTitleValue(e.target.value);

    if (serverTitleData !== null && serverTitleData !== e.target.value && e.target.value !== "") {
      setIntroMode(true);
    } else if (serverTitleData === null && e.target.value !== "인사말 타이틀을 입력하세요" && e.target.value !== "") {
      setIntroMode(true);
    } else {
      setIntroMode(false);
    }
  };

  const handleChangeContent = (e) => {
    setContentValue(e.target.value);

    if (serverContentData !== null && serverContentData !== e.target.value && e.target.value !== "") {
      setIntroMode(true);
    } else if (serverContentData === null && e.target.value !== "인사말 본문을 입력하세요" && e.target.value !== "") {
      setIntroMode(true);
    } else {
      setIntroMode(false);
    }
  };

  const handleClickRadio = (value) => {
    setGuideType(value);

    if (serverGuideType !== value) {
      setIntroMode(true);
    } else {
      setIntroMode(false);
    }
  };

  const handleChangeTail = (e) => {
    setTailValue(e.target.value);

    if (serverTailData !== null && serverTailData !== e.target.value && e.target.value !== "") {
      setIntroMode(true);
    } else if (e.target.value !== "꼬리말을 입력하세요") {
      setIntroMode(true);
    } else {
      setIntroMode(false);
    }
  };

  return (
    <div className="relative flex h-[800px] w-[460px] flex-col items-center justify-start rounded-2xl border border-selectTextColor/20 font-SDGothic">
      {/* title header */}
      {/* title */}
      <div className="flex h-[60px] w-full flex-row items-center justify-start border-b-2 border-orangeColor px-3">
        <input
          type="text"
          spellCheck={false}
          value={titleValue}
          onChange={handleChangeTitle}
          className="w-[75%] rounded-xl border border-orangeColor/40 p-3 text-[18px] font-bold outline-none"
        />
        <img src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/scan_logo.png" alt="scan_logo" className="h-[25px] w-auto pl-7" />
      </div>
      {/* img */}
      {/* radio btns */}
      <div className="felx-row flex h-fit w-full items-center justify-between p-8">
        <div className="flex h-fit w-fit flex-row items-center justify-center">
          <input
            type="radio"
            checked={guideType === "0" ? true : false}
            name="guide_img"
            id="none"
            value="0"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("0")}
            onChange={() => {}}
          />
          <label htmlFor="none" className="ml-3 font-bold">
            배경 없음
          </label>
        </div>
        <div className="flex h-fit w-fit flex-row items-center justify-center">
          <input
            type="radio"
            checked={guideType === "1" ? true : false}
            name="guide_img"
            id="small"
            value="1"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("1")}
            onChange={() => {}}
          />
          <label htmlFor="small" className="ml-3 font-bold">
            짧은 이미지
          </label>
        </div>
        <div className="flex h-fit w-fit flex-row items-center justify-center">
          <input
            type="radio"
            checked={guideType === "2" ? true : false}
            name="guide_img"
            id="medium"
            value="2"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("2")}
            onChange={() => {}}
          />
          <label htmlFor="medium" className="ml-3 font-bold">
            중간 이미지
          </label>
        </div>
        <div className="flex h-fit w-fit flex-row items-center justify-center">
          <input
            type="radio"
            checked={guideType === "3" ? true : false}
            name="guide_img"
            id="large"
            value="3"
            className="border accent-orangeColor"
            onClick={() => handleClickRadio("3")}
            onChange={() => {}}
          />
          <label htmlFor="large" className="ml-3 font-bold">
            긴 이미지
          </label>
        </div>
      </div>
      {/* image */}
      <div
        className={
          guideType === "0"
            ? `flex h-[0%] w-full flex-col items-center justify-center`
            : guideType === "1"
            ? `mb-5 flex h-[30%] w-full flex-col items-center justify-center`
            : guideType === "2"
            ? `mb-5 flex h-[45%] w-full flex-col items-center justify-center`
            : `flex h-[86%] w-full flex-col items-center justify-center`
        }
      >
        <img
          src="https://survey-file-bucket.s3.ap-northeast-2.amazonaws.com/intro/intro_basic.png"
          alt="guide_image"
          className={
            guideType === "3"
              ? `flex h-full w-full flex-col items-center justify-center rounded-b-xl`
              : `flex h-full w-full flex-col items-center justify-center`
          }
        />
      </div>
      <div
        className={
          guideType === "0"
            ? `flex h-[70%] w-full flex-col items-stretch justify-start px-8`
            : guideType === "1"
            ? `flex h-[40%] w-full flex-col items-stretch justify-start px-8`
            : guideType === "2"
            ? `flex h-[25%] w-full flex-col items-stretch justify-start px-8`
            : `flex h-[0%] w-full flex-col items-stretch justify-start px-8`
        }
      >
        {/* content */}
        <textarea
          value={contentValue}
          onChange={handleChangeContent}
          spellCheck={false}
          className={
            guideType === "0"
              ? `h-[400px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 leading-snug outline-none`
              : guideType === "1"
              ? `h-[200px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 leading-snug outline-none`
              : guideType === "2"
              ? `h-[100px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 leading-snug outline-none`
              : `hidden h-[0px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 leading-snug outline-none`
          }
        />
        {/* tail */}
        <textarea
          value={tailValue}
          onChange={handleChangeTail}
          spellCheck={false}
          className={
            guideType === "3"
              ? `mt-5 hidden h-[50px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 text-right leading-snug outline-none`
              : `mt-5 h-[50px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 text-right leading-snug outline-none`
          }
        />
      </div>
      {/* mockup btn */}
      {mode ? (
        <div
          onClick={handleClickIntroFetch}
          className="absolute bottom-0 mb-10 flex h-fit w-[80%] cursor-pointer flex-col items-center justify-center rounded-xl bg-orangeColor p-10 text-[20px] text-white hover:bg-orangeColor/80"
        >
          수정하기
        </div>
      ) : (
        <div className="absolute bottom-0 mb-10 flex h-fit w-[80%] flex-col items-center justify-center rounded-xl bg-orangeColor/20 p-10 text-[20px] text-white">
          수정하기
        </div>
      )}
    </div>
  );
};

export default AdminIntroSentenseComp;
