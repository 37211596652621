import { useEffect, useState } from "react";
import { ImMinus, ImPlus } from "react-icons/im";
import useDeleteAdminUserEtc from "../../hooks/useDeleteAdminUserEtc";
import useGetAdminUserEtc from "../../hooks/useGetAdminUserEtc";
import useGetAdminUserEtcTitle from "../../hooks/useGetAdminUserEtcTitle";
import usePatchAdminUserEtc from "../../hooks/usePatchAdminUserEtc";
import usePatchAdminUserEtcOrder from "../../hooks/usePatchAdminUserEtcOrder";
import usePatchAdminUserEtcTitle from "../../hooks/usePatchAdminUserEtcTitle";
import usePostAdminUserEtc from "../../hooks/usePostAdminUserEtc";
import useStore from "../../store/pc/store";

const AdminUserEtc2 = () => {
  const PrjCd = useStore((state) => state.PrjCd);
  const { data: etcTitle, refetch: etcTitleRefetch } = useGetAdminUserEtcTitle(PrjCd, 2);
  const { data: etcData, refetch: etcRefetch } = useGetAdminUserEtc(PrjCd, 2);

  const { mutate: updateEtcTitleMutate } = usePatchAdminUserEtcTitle();
  const { mutate: orderMutate } = usePatchAdminUserEtcOrder();
  const { mutate: updateEtcMutate } = usePatchAdminUserEtc();
  const { mutate: insertEtcMutate } = usePostAdminUserEtc();
  const { mutate: deleteEtcMutate } = useDeleteAdminUserEtc();

  const [inputMode, setInputMode] = useState(false);
  const [hoverEl, setHoverEl] = useState(0);

  const handleHoverMouse = (num) => {
    setHoverEl(num);
  };

  const handleLeaveMouse = () => {
    setHoverEl(0);
  };

  const handleBlurTitle = (e) => {
    const body = {
      prj_CD: PrjCd,
      value: e.target.value,
      etc_NUM: 2,
    };

    updateEtcTitleMutate(body);
    setInputMode(false);
  };

  const handleBlur = (e, seq) => {
    const body = {
      prj_CD: PrjCd,
      value: e.target.value,
      origin_VALUE: seq,
      etc_NUM: 2,
    };

    orderMutate(body);
    setInputMode(false);
  };

  const handleBlurText = (e, seq, dvs) => {
    const body = {
      prj_CD: PrjCd,
      comn_CD: dvs,
      value: e.target.value,
      seq: seq,
      etc_NUM: 2,
    };

    updateEtcMutate(body);
    setInputMode(false);
  };

  const handleClickPlus = (seq) => {
    const body = {
      prj_CD: PrjCd,
      seq: seq,
      etc_NUM: 2,
    };

    insertEtcMutate(body);
  };

  const handleClickMinus = (seq) => {
    const body = {
      prj_CD: PrjCd,
      seq: seq,
      etc_NUM: 2,
    };

    deleteEtcMutate(body);
  };

  useEffect(() => {
    etcTitleRefetch();
    etcRefetch();
  }, [PrjCd]);

  return (
    <>
      <div className="h-full w-full flex-col items-center justify-start font-SDGothic text-[16px] font-bold text-selectTextColor/80">
        {/* subTitle */}
        <div className="flex h-fit w-full flex-col items-start justify-start p-3">
          <div className="mt-10 h-fit w-fit text-[18px] font-bold">기타2</div>
          <div className="mt-6 h-fit w-fit text-[14px] font-bold text-selectTextColor/50">설문에 사용될 기타2을 설정해주세요</div>
        </div>
        <div className="mt-5 flex h-[40px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
          <div className="flex h-full w-[160px] flex-col items-center justify-center border-l border-white">기타2 주제</div>
          <div className="flex h-full w-[850px] flex-col items-start justify-start border-l border-white bg-white">
            <input
              spellCheck="false"
              type="text"
              value={inputMode ? null : etcTitle?.data}
              onClick={() => setInputMode(true)}
              // key={el.qst + idx}
              onChange={() => {}}
              // onChange={handleChangeText}
              onBlur={handleBlurTitle}
              className="hide-scrollbar focus:bg-orangeColor/15 flex h-full w-full resize-none flex-col items-start justify-start bg-FilterInActiveColor/10 p-7 leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
            ></input>
          </div>
        </div>
      </div>
      <div className="h-full w-full flex-col items-center justify-start font-SDGothic text-[16px] font-bold text-selectTextColor/80">
        {/* table header */}
        <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
          <div className="flex h-full w-[120px] flex-col items-center justify-center border-l border-white">순서</div>
          <div className="flex h-full w-[200px] flex-col items-center justify-center border-l border-white">기타2 코드</div>
          <div className="flex h-full w-[667px] flex-col items-center justify-center border-l border-white">기타2 명</div>
        </div>

        {etcData?.data.length === 0 ? (
          <div className="flex h-fit w-full flex-col items-center justify-center">
            <div className="flex h-fit w-full flex-col items-center justify-center p-10 text-[16px] font-bold text-selectTextColor/80">
              데이터가 존재하지 않습니다
            </div>
            <button
              type="button"
              onClick={etcTitle?.data ? () => handleClickPlus(0) : () => alert("주제를 먼저 입력하세요")}
              className=" flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
            >
              생성
            </button>
          </div>
        ) : null}

        {/* table rows */}
        <div className="mb-10 flex h-fit w-full flex-col items-start justify-start">
          {etcData?.data.map((el, idx) => {
            return (
              <div
                key={idx}
                onMouseEnter={() => handleHoverMouse(el.seq)}
                onMouseLeave={() => handleLeaveMouse()}
                className="relative flex h-fit w-full flex-row items-start justify-start"
              >
                {hoverEl === el.seq ? (
                  <div className="absolute -bottom-10 right-0 z-10 flex h-fit w-fit flex-row items-center justify-end py-5">
                    <button
                      type="button"
                      onClick={() => handleClickPlus(el.seq)}
                      className="mr-3 flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                    >
                      <ImPlus className="text-[14px] text-white" />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClickMinus(el.seq)}
                      className="flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                    >
                      <ImMinus className="text-[14px] text-white" />
                    </button>
                  </div>
                ) : null}
                {/* 순서 */}
                <input
                  type="number"
                  onChange={() => {}}
                  value={inputMode ? null : el.seq}
                  onClick={() => setInputMode(true)}
                  onBlur={(e) => handleBlur(e, el.seq)}
                  className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-right leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                ></input>
                {/* 기타1 코드 */}
                <input
                  spellCheck="false"
                  // 리랜더링 이슈
                  onChange={() => {}}
                  type="text"
                  value={inputMode ? null : el.comn_CD.includes("Tr") ? "입력하세요" : el.comn_CD}
                  onClick={() => setInputMode(true)}
                  onBlur={(e) => handleBlurText(e, el.seq, "COMN_CD")}
                  className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[200px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-left leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                ></input>
                {/* 기타1 명 */}
                <input
                  spellCheck="false"
                  onChange={() => {}}
                  // 리랜더링 이슈
                  type="text"
                  value={inputMode ? null : el.comn_NM.includes("Tr") ? "입력하세요" : el.comn_NM}
                  onClick={() => setInputMode(true)}
                  onBlur={(e) => handleBlurText(e, el.seq, "COMN_NM")}
                  className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[667px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-left leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
                ></input>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AdminUserEtc2;
