import { useQuery } from "@tanstack/react-query";
import getDa09Res from "../api/getDa09Res";
import { useChartDataState, useSelListState, useTableDataState } from "../store/mobile/store";

const useGetDa09Res = (prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate) => {
  const setChartData = useChartDataState((state) => state.setChartData);
  const setTableData = useTableDataState((state) => state.setTableData);
  const selList = useSelListState((state) => state.selListState);

  return useQuery(["da09"], () => getDa09Res(prj_cd, qst_cd, svy_ojt, sel_cd, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
      if (selList !== "전체") {
        setChartData(res.data[1]);
      } else {
        setChartData(res.data[1]);
        setTableData(res.data[1]);
      }
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa09Res;
