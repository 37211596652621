import axios from "axios";

const getAdminProj = () => {
  return axios.get("/admin/proj", {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminProj;
