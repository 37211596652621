import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA10 from "../../components/pc/BarColumnChartDA10";
import CSTrendChartPeriod from "../../components/pc/CSTrendChartPeriod";
import DA10Table from "../../components/pc/DA10Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA10AgeGrp } from "../../hooks/useGraphDA10AgeGrp";
import { useGraphDA10Area } from "../../hooks/useGraphDA10Area";
import { useGraphDA10Assign } from "../../hooks/useGraphDA10Assign";
import { useGraphDA10Doc } from "../../hooks/useGraphDA10Doc";
import { useGraphDA10Etc1 } from "../../hooks/useGraphDA10Etc1";
import { useGraphDA10Etc2 } from "../../hooks/useGraphDA10Etc2";
import { useGraphDA10Etc3 } from "../../hooks/useGraphDA10Etc3";
import { useGraphDA10MainDept } from "../../hooks/useGraphDA10MainDept";
import { useGraphDA10Normal } from "../../hooks/useGraphDA10Normal";
import { useGraphDA10Octy } from "../../hooks/useGraphDA10Octy";
import { useGraphDA10Sex } from "../../hooks/useGraphDA10Sex";
import { useGraphDA10WrkYyGrp } from "../../hooks/useGraphDA10WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA10AgeGrp } from "../../hooks/useTableDA10AgeGrp";
import { useTableDA10Assign } from "../../hooks/useTableDA10Assign";
import { useTableDA10Doc } from "../../hooks/useTableDA10Doc";
import { useTableDA10Octy } from "../../hooks/useTableDA10Octy";
import { useTableDA10Sex } from "../../hooks/useTableDA10Sex";
import { useTableDA10WrkYyGrp } from "../../hooks/useTableDA10WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA10Page = () => {
  const analysisType = useStore((state) => state.AnalysisType);
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        if (!dd.grp_NM) {
          vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
        } else {
          vCheck.push({ value: dd.sel_CD, label: dd.grp_NM + "-" + dd.sel_NM });
        }
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  useEffect(() => {
    //console.log(Sel);
  }, [Sel]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const { isGraphDA10NormalLoading, isGraphDA10NormalError, GraphDA10NormalData, isGraphDA10NormalSuccess, GraphDA10NormalRefetch } =
    useGraphDA10Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA10AssignLoading, isGraphDA10AssignError, GraphDA10AssignData, isGraphDA10AssignSuccess, GraphDA10AssignRefetch } =
    useGraphDA10Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA10DocLoading, isGraphDA10DocError, GraphDA10DocData, isGraphDA10DocSuccess, GraphDA10DocRefetch } = useGraphDA10Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA10OctyLoading, isGraphDA10OctyError, GraphDA10OctyData, isGraphDA10OctySuccess, GraphDA10OctyRefetch } = useGraphDA10Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA10WrkYyGrpLoading, isGraphDA10WrkYyGrpError, GraphDA10WrkYyGrpData, isGraphDA10WrkYyGrpSuccess, GraphDA10WrkYyGrpRefetch } =
    useGraphDA10WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA10AgeGrpLoading, isGraphDA10AgeGrpError, GraphDA10AgeGrpData, isGraphDA10AgeGrpSuccess, GraphDA10AgeGrpRefetch } =
    useGraphDA10AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA10SexLoading, isGraphDA10SexError, GraphDA10SexData, isGraphDA10SexSuccess, GraphDA10SexRefetch } = useGraphDA10Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA10AreaLoading, isGraphDA10AreaError, GraphDA10AreaData, isGraphDA10AreaSuccess, GraphDA10AreaRefetch } = useGraphDA10Area(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA10MainDeptLoading, isGraphDA10MainDeptError, GraphDA10MainDeptData, isGraphDA10MainDeptSuccess, GraphDA10MainDeptRefetch } =
    useGraphDA10MainDept(PrjCd, SvyOjt, QstCd, "", "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA10Etc1Loading, isGraphDA10Etc1Error, GraphDA10Etc1Data, isGraphDA10Etc1Success, GraphDA10Etc1Refetch } = useGraphDA10Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA10Etc2Loading, isGraphDA10Etc2Error, GraphDA10Etc2Data, isGraphDA10Etc2Success, GraphDA10Etc2Refetch } = useGraphDA10Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA10Etc3Loading, isGraphDA10Etc3Error, GraphDA10Etc3Data, isGraphDA10Etc3Success, GraphDA10Etc3Refetch } = useGraphDA10Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA10AgeGrpLoading, isTableDA10AgeGrpError, TableDA10AgeGrpData, isTableDA10AgeGrpSuccess, TableDA10AgeGrpRefetch } =
    useTableDA10AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA10AssignLoading, isTableDA10AssignError, TableDA10AssignData, isTableDA10AssignSuccess, TableDA10AssignRefetch } =
    useTableDA10Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA10DocLoading, isTableDA10DocError, TableDA10DocData, isTableDA10DocSuccess, TableDA10DocRefetch } = useTableDA10Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA10OctyLoading, isTableDA10OctyError, TableDA10OctyData, isTableDA10OctySuccess, TableDA10OctyRefetch } = useTableDA10Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA10SexLoading, isTableDA10SexError, TableDA10SexData, isTableDA10SexSuccess, TableDA10SexRefetch } = useTableDA10Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA10WrkYyGrpLoading, isTableDA10WrkYyGrpError, TableDA10WrkYyGrpData, isTableDA10WrkYyGrpSuccess, TableDA10WrkYyGrpRefetch } =
    useTableDA10WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length > 0) {
        let graphList = GraphSet.map((el) => el.value);

        if (graphList.includes("Normal")) {
          GraphDA10NormalRefetch();
        }

        if (graphList.includes("Assign")) {
          GraphDA10AssignRefetch();
        }

        if (graphList.includes("Doc")) {
          GraphDA10DocRefetch();
        }

        if (graphList.includes("Octy")) {
          GraphDA10OctyRefetch();
        }

        if (graphList.includes("WrkYyGrp")) {
          GraphDA10WrkYyGrpRefetch();
        }

        if (graphList.includes("AgeGrp")) {
          GraphDA10AgeGrpRefetch();
        }

        if (graphList.includes("Sex")) {
          GraphDA10SexRefetch();
        }

        if (graphList.includes("Area")) {
          GraphDA10AreaRefetch();
        }

        if (graphList.includes("MainDept")) {
          GraphDA10MainDeptRefetch();
        }

        if (graphList.includes("Etc1")) {
          GraphDA10Etc1Refetch();
        }

        if (graphList.includes("Etc2")) {
          GraphDA10Etc2Refetch();
        }

        if (graphList.includes("Etc3")) {
          GraphDA10Etc3Refetch();
        }
      }

      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioTypeCheck === "AgeGrp") {
      GraphDA10AgeGrpRefetch();
      TableDA10AgeGrpRefetch();
    } else if (RadioTypeCheck === "Sex") {
      GraphDA10SexRefetch();
      TableDA10SexRefetch();
    } else if (RadioTypeCheck === "Assign") {
      TableDA10AssignRefetch();
      GraphDA10AssignRefetch();
    } else if (RadioTypeCheck === "Doc") {
      TableDA10DocRefetch();
      GraphDA10DocRefetch();
    } else if (RadioTypeCheck === "Octy") {
      TableDA10OctyRefetch();
      GraphDA10OctyRefetch();
    } else if (RadioTypeCheck === "WrkYyGrp") {
      TableDA10WrkYyGrpRefetch();
      GraphDA10WrkYyGrpRefetch();
    } else if (RadioTypeCheck === "MainDept") {
      GraphDA10MainDeptRefetch();
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA10NormalSuccess || GraphDA10NormalData === null || GraphDA10NormalData === undefined || GraphDA10NormalData.length === 0) {
  //     GraphDA10NormalRefetch();
  //   }
  // }, [GraphDA10NormalData, isGraphDA10NormalSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10AssignSuccess || GraphDA10AssignData === null || GraphDA10AssignData === undefined || GraphDA10AssignData.length === 0) {
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10AssignData, isGraphDA10AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10DocSuccess || GraphDA10DocData === null || GraphDA10DocData === undefined || GraphDA10DocData.length === 0) {
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10DocData, isGraphDA10DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10OctySuccess || GraphDA10OctyData === null || GraphDA10OctyData === undefined || GraphDA10OctyData.length === 0) {
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10OctyData, isGraphDA10OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10WrkYyGrpSuccess || GraphDA10WrkYyGrpData === null || GraphDA10WrkYyGrpData === undefined || GraphDA10WrkYyGrpData.length === 0) {
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10WrkYyGrpData, isGraphDA10WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10AgeGrpSuccess || GraphDA10AgeGrpData === null || GraphDA10AgeGrpData === undefined || GraphDA10AgeGrpData.length === 0) {
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10AgeGrpData, isGraphDA10AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10SexSuccess || GraphDA10SexData === null || GraphDA10SexData === undefined || GraphDA10SexData.length === 0) {
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10SexData, isGraphDA10SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10AreaSuccess || GraphDA10AreaData === null || GraphDA10AreaData === undefined || GraphDA10AreaData.length === 0) {
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10AreaData, isGraphDA10AreaSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10MainDeptSuccess || GraphDA10MainDeptData === null || GraphDA10MainDeptData === undefined || GraphDA10MainDeptData.length === 0) {
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10MainDeptData, isGraphDA10MainDeptSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10Etc1Success || GraphDA10Etc1Data === null || GraphDA10Etc1Data === undefined || GraphDA10Etc1Data.length === 0) {
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10Etc1Data, isGraphDA10Etc1Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10Etc2Success || GraphDA10Etc2Data === null || GraphDA10Etc2Data === undefined || GraphDA10Etc2Data.length === 0) {
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10Etc2Data, isGraphDA10Etc2Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA10Etc3Success || GraphDA10Etc3Data === null || GraphDA10Etc3Data === undefined || GraphDA10Etc3Data.length === 0) {
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [GraphDA10Etc3Data, isGraphDA10Etc3Success, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA10AgeGrpSuccess || TableDA10AgeGrpData === null || TableDA10AgeGrpData === undefined || TableDA10AgeGrpData.length === 0) {
  //     TableDA10AgeGrpRefetch();
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [TableDA10AgeGrpData, isTableDA10AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA10AssignSuccess || TableDA10AssignData === null || TableDA10AssignData === undefined || TableDA10AssignData.length === 0) {
  //     TableDA10AssignRefetch();
  //     TableDA10AgeGrpRefetch();
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [TableDA10AssignData, isTableDA10AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA10DocSuccess || TableDA10DocData === null || TableDA10DocData === undefined || TableDA10DocData.length === 0) {
  //     TableDA10DocRefetch();
  //     TableDA10AssignRefetch();
  //     TableDA10AgeGrpRefetch();
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [TableDA10DocData, isTableDA10DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA10OctySuccess || TableDA10OctyData === null || TableDA10OctyData === undefined || TableDA10OctyData.length === 0) {
  //     TableDA10OctyRefetch();
  //     TableDA10DocRefetch();
  //     TableDA10AssignRefetch();
  //     TableDA10AgeGrpRefetch();
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [TableDA10OctyData, isTableDA10OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA10SexSuccess || TableDA10SexData === null || TableDA10SexData === undefined || TableDA10SexData.length === 0) {
  //     TableDA10SexRefetch();
  //     TableDA10OctyRefetch();
  //     TableDA10DocRefetch();
  //     TableDA10AssignRefetch();
  //     TableDA10AgeGrpRefetch();
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [TableDA10SexData, isTableDA10SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA10WrkYyGrpSuccess || TableDA10WrkYyGrpData === null || TableDA10WrkYyGrpData === undefined || TableDA10WrkYyGrpData.length === 0) {
  //     TableDA10WrkYyGrpRefetch();
  //     TableDA10SexRefetch();
  //     TableDA10OctyRefetch();
  //     TableDA10DocRefetch();
  //     TableDA10AssignRefetch();
  //     TableDA10AgeGrpRefetch();
  //     GraphDA10Etc3Refetch();
  //     GraphDA10Etc2Refetch();
  //     GraphDA10Etc1Refetch();
  //     GraphDA10MainDeptRefetch();
  //     GraphDA10AreaRefetch();
  //     GraphDA10SexRefetch();
  //     GraphDA10AgeGrpRefetch();
  //     GraphDA10WrkYyGrpRefetch();
  //     GraphDA10OctyRefetch();
  //     GraphDA10DocRefetch();
  //     GraphDA10NormalRefetch();
  //     GraphDA10AssignRefetch();
  //   }
  // }, [TableDA10WrkYyGrpData, isTableDA10WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA10"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {analysisType === "csscan" ? (
            <CSTrendChartPeriod
              hsp_nm={HspNm}
              prj_cd={PrjCd}
              svy_ojt={SvyOjt}
              qst_cd={Pick.qst_cd}
              sel_cd={""}
              string_value={""}
              sort_type={SortType}
              data_num={RadioNumCheck}
              data_type={RadioTypeCheck}
              qst={Qst}
              endDate={endDate}
              deep_ana_cd={"DA10"}
            />
          ) : (
            <></>
          )}
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA10NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA10AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA10DocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA10OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA10WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA10SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA10AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA10AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA10MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA10Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA10Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA10Etc3Data;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA10
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA10NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA10AssignData;
              vTData = TableDA10AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA10DocData;
              vTData = TableDA10DocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA10OctyData;
              vTData = TableDA10OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA10WrkYyGrpData;
              vTData = TableDA10WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA10SexData;
              vTData = TableDA10SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA10AgeGrpData;
              vTData = TableDA10AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA10AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA10MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA10Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA10Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA10Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA10Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA10
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA10Page;
