import { useQuery } from "@tanstack/react-query";
import getGptRes from "../api/getGptRes";
import { useSummerizeRes } from "../store/mobile/store";

const useGetGptRes = (prj_cd, qst_cd, svy_ojt, filterOpt, date) => {
  const setSummerizedData = useSummerizeRes((state) => state.setSummerizedData);

  return useQuery(["gpt"], () => getGptRes(prj_cd, qst_cd, svy_ojt, filterOpt, date), {
    onSuccess: (res) => {
      setSummerizedData(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetGptRes;
