const SpeechBubble = (props) => {
  const extractTime = (time) => {
    let currDate = new Date(time);

    let utc = currDate.getTime() + currDate.getTimezoneOffset() * 60 * 1000;

    // const KR_TIME_DIFF = 18 * 60 * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    const kr_curr = new Date(utc + KR_TIME_DIFF);

    let year = kr_curr.getFullYear();
    let month = Number(kr_curr.getMonth()) + 1 > 9 ? Number(kr_curr.getMonth()) + 1 : `0${Number(kr_curr.getMonth()) + 1}`;
    let date = kr_curr.getDate() > 9 ? kr_curr.getDate() : `0${kr_curr.getDate()}`;

    let hour = kr_curr.getHours() > 9 ? kr_curr.getHours() : `0${kr_curr.getHours()}`;
    let minute = kr_curr.getMinutes() > 9 ? kr_curr.getMinutes() : `0${kr_curr.getMinutes()}`;
    let sec = kr_curr.getSeconds() > 9 ? kr_curr.getSeconds() : `0${kr_curr.getSeconds()}`;

    return `${year}.${month}.${date} ${hour}:${minute}:${sec}`;
  };

  return (
    <div className="mt-5 flex h-fit w-full flex-col items-center justify-center">
      <div className="flex h-fit w-full flex-col items-start justify-start rounded-r-3xl rounded-bl-3xl border border-FilterInActiveColor bg-white p-5 text-left  font-SDGothic text-[14px] leading-snug text-selectTextColor/80 ">
        {`${props?.data.seq}. ${props?.data.sbjt_ANSW}`}
      </div>
      {window.location.href.toLowerCase().indexOf("csscan") > -1 ? (
        <div className="mt-3 flex h-full w-full flex-col items-end justify-end whitespace-nowrap pr-3 text-[12px] font-semibold  text-selectTextColor/40">
          {extractTime(props?.data.inpt_DTM)}
        </div>
      ) : null}
    </div>
  );
};

export default SpeechBubble;
