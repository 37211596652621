import { useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { useEssayListState, useMenuState } from "../../../store/mobile/store";
import ActiveFilterBtn from "../filterBtn/ActiveFilterBtn";
import InactiveFilterBtn from "../filterBtn/InactiveFilterBtn";

const EssayFilterModal = (props) => {
  const menuState = useMenuState((state) => state.menuVal);

  const handleClickCancel = () => {
    props.setModalState(false);
  };

  const [alert, setAlert] = useState(false);

  const [activeBtnList, setActiveBtnList] = useState(props.btnList);

  const [activeAgeBtnList, setActiveAgeBtnList] = useState(props.ageBtnList);

  const [dataAlert, setDataAlert] = useState(false);

  const setListDb = useEssayListState((state) => state.setListData);

  const handleClickConfirmBtn = () => {
    if (activeBtnList.length === 0 || activeAgeBtnList.length === 0) {
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 1500);
    } else {
      if (menuState === "MEM") {
        props.setBtnList(activeBtnList);
      } else {
        props.setAgeBtnList(activeAgeBtnList);
      }
      props.setModalState(false);
      setListDb([]);
      props.setPage(0);
    }
  };

  useEffect(() => {
    setActiveBtnList(props.btnList);
    setActiveAgeBtnList(props.ageBtnList);
  }, [props.modalState]);

  return (
    <div
      className={
        props.modalState
          ? `fixed -right-[0px] top-0 z-50 flex h-full w-[270px] flex-col items-center justify-start rounded-l-xl border border-tableBorderColor/50 bg-white/95 p-3 pl-7 drop-shadow-lg duration-500 ease-in-out`
          : `fixed -right-[320px] top-0 z-50 flex h-full w-[270px] flex-col items-center justify-start rounded-l-xl border border-tableBorderColor/50 bg-white/95 p-3 pl-7 drop-shadow-lg duration-500 ease-in-out`
      }
    >
      <div
        className={
          alert
            ? `absolute top-16 flex h-fit w-[80%] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/80 p-3 font-SDGothic text-[12px] font-bold text-white opacity-100  duration-300`
            : `absolute top-16 flex h-fit w-[80%] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/80 p-3 font-SDGothic text-[12px] font-bold text-transparent opacity-0  duration-500`
        }
      >
        최소 1개 이상 옵션이 필요합니다
      </div>

      <div
        className={
          dataAlert
            ? `absolute top-16 flex h-fit w-[80%] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/80 p-3 font-SDGothic text-[12px] font-bold text-white opacity-100  duration-300`
            : `absolute top-16 flex h-fit w-[80%] flex-col items-center justify-center rounded-lg bg-FilterActiveColor/80 p-3 font-SDGothic text-[12px] font-bold text-transparent opacity-0  duration-500`
        }
      >
        {props.menuState === "MEM" ? `직종별 ` : `연령별 `}데이터가 존재하지 않습니다
      </div>
      <HiOutlineX onClick={handleClickCancel} className="absolute right-4 top-4 text-4xl text-textColor" />
      {/* title */}
      <div className="mt-28 flex h-fit w-full flex-col items-start justify-start">
        <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[20px] font-extrabold text-selectTextColor">
          검색옵션을 설정하세요
        </div>
        <div className="mt-8 flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] font-light text-selectTextColor">
          {props.menuState === "MEM" ? `직종별 ` : `연령별 `}조회가 가능합니다
        </div>
      </div>
      {/* btn area */}
      <div className="mt-16 flex h-fit w-full flex-col items-start justify-start">
        {menuState === "MEM" && activeBtnList.length === 7 ? (
          <ActiveFilterBtn list={activeBtnList} setList={setActiveBtnList} name={"전체"} opt={props?.opt} setAlert={setDataAlert} />
        ) : menuState === "MEM" && activeBtnList.length !== 7 ? (
          <InactiveFilterBtn list={activeBtnList} setList={setActiveBtnList} name={"전체"} opt={props?.opt} setAlert={setDataAlert} />
        ) : (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") && activeAgeBtnList.length === 8 ? (
          <ActiveFilterBtn list={activeAgeBtnList} setList={setActiveAgeBtnList} name={"전체"} opt={props?.opt} setAlert={setDataAlert} />
        ) : (
          <InactiveFilterBtn list={activeAgeBtnList} setList={setActiveAgeBtnList} name={"전체"} opt={props?.opt} setAlert={setDataAlert} />
        )}
        {menuState === "MEM" ? (
          <>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 의사직 */}
              {activeBtnList.includes("01") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"01"}
                  name={"의사직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"01"}
                  name={"의사직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}

              {/* 간호직 */}
              {activeBtnList.includes("02") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"02"}
                  name={"간호직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"02"}
                  name={"간호직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 보건직 */}
              {activeBtnList.includes("03") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"03"}
                  name={"보건직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"03"}
                  name={"보건직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
              {/* 약무직 */}
              {activeBtnList.includes("04") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"04"}
                  name={"약무직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"04"}
                  name={"약무직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 사무직 */}
              {activeBtnList.includes("05") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"05"}
                  name={"사무직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"05"}
                  name={"사무직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
              {/* 기술직 */}
              {activeBtnList.includes("06") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"06"}
                  name={"기술직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"06"}
                  name={"기술직"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 기타 */}
              {activeBtnList.includes("07") && activeBtnList.length !== 7 ? (
                <ActiveFilterBtn list={activeBtnList} setList={setActiveBtnList} code={"07"} name={"기타"} opt={props?.opt} setAlert={setDataAlert} />
              ) : (
                <InactiveFilterBtn
                  list={activeBtnList}
                  setList={setActiveBtnList}
                  code={"07"}
                  name={"기타"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 10대 이하 */}
              {activeAgeBtnList.includes("10") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"10"}
                  name={"10대 이하"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"10"}
                  name={"10대 이하"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}

              {/* 20대 */}
              {activeAgeBtnList.includes("20") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"20"}
                  name={"20대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"20"}
                  name={"20대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 30대 */}
              {activeAgeBtnList.includes("30") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"30"}
                  name={"30대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"30"}
                  name={"30대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
              {/* 40대 */}
              {activeAgeBtnList.includes("40") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"40"}
                  name={"40대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"40"}
                  name={"40대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 50대 */}
              {activeAgeBtnList.includes("50") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"50"}
                  name={"50대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"50"}
                  name={"50대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
              {/* 60대 */}
              {activeAgeBtnList.includes("60") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"60"}
                  name={"60대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"60"}
                  name={"60대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
            <div className="mt-5 flex h-fit w-full flex-row items-center justify-start">
              {/* 70대 */}
              {activeAgeBtnList.includes("70") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"70"}
                  name={"70대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"70"}
                  name={"70대"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
              {/* 80대 이상 */}
              {activeAgeBtnList.includes("80") && activeAgeBtnList.length !== 8 ? (
                <ActiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"80"}
                  name={"80대 이상"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              ) : (
                <InactiveFilterBtn
                  list={activeAgeBtnList}
                  setList={setActiveAgeBtnList}
                  code={"80"}
                  name={"80대 이상"}
                  opt={props?.opt}
                  setAlert={setDataAlert}
                />
              )}
            </div>
          </>
        )}
      </div>
      {/* confime btn */}
      <div
        onClick={handleClickConfirmBtn}
        className="mr-5 mt-20 flex h-fit w-fit flex-row items-center justify-center rounded-[25px] bg-FilterActiveColor/90 px-16 py-6 font-SDGothic text-[15px] font-semibold text-white"
      >
        적용
      </div>
    </div>
  );
};

export default EssayFilterModal;
