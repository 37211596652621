import { useEffect, useState } from "react";
import { ImMinus, ImPlus } from "react-icons/im";
import useDeleteAdminUserArea from "../../hooks/useDeleteAdminUserArea";
import useGetAdminUserArea from "../../hooks/useGetAdminUserArea";
import usePatchAdminUserArea from "../../hooks/usePatchAdminUserArea";
import usePostAdminUserArea from "../../hooks/usePostAdminUserArea";
import usePostAdminUserAreaOrder from "../../hooks/usePostAdminUserAreaOrder";
import useStore from "../../store/pc/store";

const AdminUserArea = () => {
  const PrjCd = useStore((state) => state.PrjCd);
  const [inputMode, setInputMode] = useState(false);

  const { data, refetch } = useGetAdminUserArea(PrjCd);
  const { mutate: orderMutate } = usePostAdminUserAreaOrder();
  const { mutate: updateMutate } = usePatchAdminUserArea();
  const { mutate: insertMutate } = usePostAdminUserArea();
  const { mutate: deleteMutate } = useDeleteAdminUserArea();
  const [hoverEl, setHoverEl] = useState(0);

  const handleHoverMouse = (num) => {
    setHoverEl(num);
  };

  const handleLeaveMouse = () => {
    setHoverEl(0);
  };

  const handleClickPlus = (seq) => {
    const body = {
      prj_CD: PrjCd,
      seq: seq,
    };

    insertMutate(body);
  };

  const handleClickMinus = (seq) => {
    const body = {
      prj_CD: PrjCd,
      seq: seq,
    };

    deleteMutate(body);
  };

  const handleBlur = (e, seq) => {
    const body = {
      prj_CD: PrjCd,
      value: e.target.value,
      origin_VALUE: seq,
    };

    orderMutate(body);
    setInputMode(false);
  };

  const handleBlurText = (e, seq, dvs) => {
    const body = {
      prj_CD: PrjCd,
      comn_CD: dvs,
      value: e.target.value,
      seq: seq,
    };

    updateMutate(body);
    setInputMode(false);
  };

  useEffect(() => {
    refetch();
  }, [PrjCd]);

  return (
    <div className="h-full w-full flex-col items-center justify-start font-SDGothic text-[16px] font-bold text-selectTextColor/80">
      {/* subTitle */}
      <div className="flex h-fit w-full flex-col items-start justify-start p-3">
        <div className="mt-10 h-fit w-fit text-[18px] font-bold">지역</div>
        <div className="mt-6 h-fit w-fit text-[14px] font-bold text-selectTextColor/50">설문에 사용될 지역을 설정해주세요</div>
      </div>

      {/* table header */}
      <div className="mt-5 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
        <div className="flex h-full w-[120px] flex-col items-center justify-center border-l border-white">순서</div>
        <div className="flex h-full w-[200px] flex-col items-center justify-center border-l border-white">지역코드</div>
        <div className="flex h-full w-[667px] flex-col items-center justify-center border-l border-white">지역명</div>
      </div>

      {data?.data.length === 0 ? (
        <div className="flex h-fit w-full flex-col items-center justify-center">
          <div className="flex h-fit w-full flex-col items-center justify-center p-10 text-[16px] font-bold text-selectTextColor/80">
            데이터가 존재하지 않습니다
          </div>
          <button
            type="button"
            onClick={() => handleClickPlus(0)}
            className=" flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
          >
            생성
          </button>
        </div>
      ) : null}

      {/* table rows */}
      <div className="mb-10 flex h-fit w-full flex-col items-start justify-start">
        {data?.data.map((el, idx) => {
          return (
            <div
              onMouseEnter={() => handleHoverMouse(el.seq)}
              onMouseLeave={() => handleLeaveMouse()}
              className="relative flex h-fit w-full flex-row items-start justify-start"
            >
              {hoverEl === el.seq ? (
                <div className="absolute -bottom-10 right-0 z-10 flex h-fit w-fit flex-row items-center justify-end py-5">
                  <button
                    type="button"
                    onClick={() => handleClickPlus(el.seq)}
                    className="mr-3 flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                  >
                    <ImPlus className="text-[14px] text-white" />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleClickMinus(el.seq)}
                    className="flex h-[25px] w-[25px] flex-col items-center justify-center rounded-md bg-orangeColor/50 p-2 hover:bg-orangeColor/80"
                  >
                    <ImMinus className="text-[14px] text-white" />
                  </button>
                </div>
              ) : null}
              {/* 순서 */}
              <input
                type="number"
                value={inputMode ? null : el.seq}
                onChange={() => {}}
                onClick={() => setInputMode(true)}
                onBlur={(e) => handleBlur(e, el.seq)}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[120px] resize-none flex-col items-end justify-end border border-l-0 border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-right leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
              {/* 직종코드 */}
              <input
                spellCheck="false"
                // 리랜더링 이슈
                type="text"
                value={inputMode ? null : el.area_CD.includes("Tr") ? "입력하세요" : el.area_CD}
                onChange={() => {}}
                onClick={() => setInputMode(true)}
                onBlur={(e) => handleBlurText(e, el.seq, "AREA_CD")}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[200px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-right leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
              {/* 직종코드 */}
              <input
                spellCheck="false"
                // 리랜더링 이슈
                onChange={() => {}}
                type="text"
                value={inputMode ? null : el.area_NM.includes("Tr") ? "입력하세요" : el.area_NM}
                onClick={() => setInputMode(true)}
                onBlur={(e) => handleBlurText(e, el.seq, "AREA_NM")}
                className="hide-scrollbar focus:bg-orangeColor/15 flex h-[60px] w-[667px] resize-none flex-col items-end justify-end border border-r-0 border-t-0 border-FilterInActiveColor/40 p-7 text-left leading-snug outline-none hover:bg-FilterInActiveColor/20 focus:bg-orangeColor/5"
              ></input>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminUserArea;
