import { useEffect, useState } from "react";
import { Radio } from "../../components/pc/Radio";
import useStore from "../../store/pc/store";
import { Check } from "./Check";

const AnalysisHeader = (props) => {
  const {
    id,
    type,
    num,
    qst,
    deepAnaCd,
    keyword,
    keywordDeepAnaCd,
    changeNum,
    changeType,
    changeUprGrpType,
    changeDA06,
    changeDA12UprGrp,
    changeDA07DA11,
    changeDA08,
    changeDA09,
    changeDEP,
    selectedNum,
    selectedType,
    selectedDA06,
    selectedDA07DA11,
    selectedDA08,
    selectedDA09,
    selectedDEP,
    sel,
    uprGrpSel,
    changeFirstCheck,
    changeSecondCheck,
    selectedFirstCheck,
    selectedSecondCheck,
    etc1Check,
  } = props;

  const HspCd = useStore((state) => state.HspCd);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const Pick = useStore((state) => state.Pick);
  const [RadioNumCheck, setRadioNumCheck] = useState(selectedNum);
  const [RadioTypeCheck, setRadioTypeCheck] = useState(selectedType);
  const [RadioDA06Check, setRadioDA06Check] = useState(selectedDA06);
  const [radioChangeDA12UprGr, setRadioChangeDA12UprGrpCheck] = useState("All");
  const [RadioDA07DA11Check, setRadioDA07DA11Check] = useState(selectedDA07DA11);
  const [RadioDA08Check, setRadioDA08Check] = useState(selectedDA08);
  const [RadioDA09Check, setRadioDA09Check] = useState(selectedDA09);
  const [RadioDEPCheck, setRadioDEPCheck] = useState(selectedDEP);
  const [FirstCheck, setFirstCheck] = useState(selectedFirstCheck);
  const [SecondCheck, setSecondCheck] = useState(selectedSecondCheck);
  const [Qst, setQst] = useState("");

  const radioChangeNum = (e) => {
    setRadioNumCheck(e.target.value);
  };

  const radioChangeType = (e) => {
    setRadioTypeCheck(e.target.value);
  };

  const radioChangeDA06 = (e) => {
    setRadioDA06Check(e.target.value);
  };
  const radioChangeDA12UprGrp = (e) => {
    setRadioChangeDA12UprGrpCheck(e.target.value);
  };

  const radioChangeDA07DA11 = (e) => {
    setRadioDA07DA11Check(e.target.value);
  };

  const radioChangeDA08 = (e) => {
    setRadioDA08Check(e.target.value);
  };

  const radioChangeDA09 = (e) => {
    setRadioDA09Check(e.target.value);
  };

  const radioChangeDEP = (e) => {
    setRadioDEPCheck(e.target.value);
  };

  const checkChangeFirst = (e) => {
    var vFirst = FirstCheck;
    if (vFirst.indexOf(e.target.value) !== -1) {
      vFirst.map((data, i) => {
        if (e.target.value === data || data === undefined) {
          vFirst.splice(i, 1);
        }
      });
      vFirst = vFirst.sort((a, b) => parseFloat(a.substr(1, a.length)) - parseFloat(b.substr(1, b.length)));
    } else {
      vFirst.push(e.target.value);
      vFirst = vFirst.filter((item1, idx1) => {
        return (
          vFirst.findIndex((item2, idx2) => {
            return item1 === item2;
          }) === idx1
        );
      });
      vFirst = vFirst.sort((a, b) => parseFloat(a.substr(1, a.length)) - parseFloat(b.substr(1, b.length)));
    }
    changeFirstCheck([...vFirst]);
  };

  const checkChangeSecond = (e) => {
    var vSecond = SecondCheck;
    if (vSecond.indexOf(e.target.value) !== -1) {
      vSecond.map((data, i) => {
        if (e.target.value === data || data === undefined) {
          vSecond.splice(i, 1);
        }
      });
      vSecond = vSecond.sort((a, b) => parseFloat(a.substr(1, a.length)) - parseFloat(b.substr(1, b.length)));
    } else {
      vSecond.push(e.target.value);
      vSecond = vSecond.filter((item1, idx1) => {
        return (
          vSecond.findIndex((item2, idx2) => {
            return item1 === item2;
          }) === idx1
        );
      });
      vSecond = vSecond.sort((a, b) => parseFloat(a.substr(1, a.length)) - parseFloat(b.substr(1, b.length)));
    }
    changeSecondCheck([...vSecond]);
  };

  useEffect(() => {
    changeNum(RadioNumCheck);
  }, [RadioNumCheck]);

  useEffect(() => {
    changeType(RadioTypeCheck);
  }, [RadioTypeCheck]);

  useEffect(() => {
    if (changeUprGrpType) {
      changeUprGrpType(radioChangeDA12UprGr);
    }
  }, [radioChangeDA12UprGr]);

  useEffect(() => {
    changeDA06(RadioDA06Check);
  }, [RadioDA06Check]);

  useEffect(() => {
    changeDA07DA11(RadioDA07DA11Check);
  }, [RadioDA07DA11Check]);

  useEffect(() => {
    changeDA08(RadioDA08Check);
  }, [RadioDA08Check]);

  useEffect(() => {
    changeDA09(RadioDA09Check);
  }, [RadioDA09Check]);

  useEffect(() => {
    changeDEP(RadioDEPCheck);
  }, [RadioDEPCheck]);

  useEffect(() => {
    //changeFirstCheck(FirstCheck);
  }, [FirstCheck]);

  useEffect(() => {
    //changeSecondCheck(SecondCheck);
  }, [SecondCheck]);

  useEffect(() => {
    var vTemp = selectedFirstCheck.slice();
    setFirstCheck([...vTemp]);
  }, [selectedFirstCheck]);

  useEffect(() => {
    var vTemp = selectedSecondCheck.slice();
    setSecondCheck([...vTemp]);
  }, [selectedSecondCheck]);

  useEffect(() => {
    var vFirst = "";
    vFirst = selectedDA08;
    setRadioDA08Check(vFirst);
  }, [selectedDA08]);

  useEffect(() => {
    var vFirst = "";
    vFirst = selectedDEP;
    setRadioDEPCheck(vFirst);
  }, [selectedDEP]);

  return (
    <div className="float-left h-full w-full">
      <div className="float-left min-h-[3.2rem] w-full pb-[1.0rem] text-[2.0rem] font-bold leading-[3.2rem]">
        {qst.replace(/<br>/gi, " ").replace(/\r\n/gi, " ").replace(/\r/gi, " ").replace(/\n/gi, " ")}
      </div>
      <div className="float-left h-[0.2rem] w-full bg-orange2Color"></div>
      <div
        className={`float-left ${
          sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && Pick.deep_ana_cd === "DA02"
            ? "min-h-[11.4rem]"
            : sel !== null &&
              sel !== undefined &&
              ((Pick.menu_cate === "심화문항" &&
                (Pick.deep_ana_cd === "DA06" ||
                  Pick.deep_ana_cd === "DA09" ||
                  Pick.deep_ana_cd === "DA07" ||
                  Pick.deep_ana_cd === "DA08" ||
                  Pick.deep_ana_cd === "DA11")) ||
                deepAnaCd === "CRSS" ||
                (deepAnaCd === "DEP" && (Pick.deep_ana_cd === "DA06" || Pick.deep_ana_cd === "DA08")))
            ? "min-h-[7.6rem]"
            : "min-h-[3.8rem]"
        } w-full bg-pcCondition`}
      >
        <div
          className={`float-left pb-[1.1rem] pt-[1.1rem] ${
            Pick.deep_ana_cd === "DA04" || Pick.deep_ana_cd === "DA06" || Pick.deep_ana_cd === "DA09" ? "w-full" : "w-[calc(75%-0.1rem)]"
          } ${deepAnaCd === "DEP" ? "hidden" : ""} border-r-[0.2rem] border-pcConditionLine pl-[1.6rem] pr-[1.6rem]`}
        >
          <Radio
            key={"RadioType"}
            id={Pick.deep_ana_cd === "DA03" ? "RadioTypeDA03" : "RadioType"}
            data={type}
            changed={radioChangeType}
            value={RadioTypeCheck}
            onClick={(e) => {
              changeType(RadioTypeCheck);
            }}
            etc1Check={etc1Check}
          />
        </div>
        <div
          className={`float-left mt-[1.1rem] ${deepAnaCd == "DEP" ? "w-[100%]" : "w-[calc(25%-0.1rem)]"} pl-[1.6rem] pr-[1.6rem] ${
            (Pick.deep_ana_cd === "DA04" && deepAnaCd !== "DEP") ||
            (Pick.deep_ana_cd === "DA06" && deepAnaCd !== "DEP") ||
            Pick.deep_ana_cd === "DA09"
              ? "hidden"
              : ""
          }`}
        >
          <Radio
            key={"RadioNum"}
            id={"RadioNum"}
            data={num}
            changed={radioChangeNum}
            value={RadioNumCheck}
            onClick={(e) => {
              changeNum(RadioNumCheck);
            }}
          />
        </div>

        {sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && Pick.deep_ana_cd === "DA02" && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition pl-[1.6rem] pr-[1.6rem]">
            <div className="float-left h-full w-[calc(25%-0.1rem)] text-[1.4rem] leading-[3.8rem]">{keyword}</div>
            <div className="float-left h-full w-[0.2rem] bg-pcConditionLine"></div>
            <div className="float-left w-[calc(75%-0.1rem)] pl-[1.6rem] pt-[1.1rem]">
              <Check key={`CheckFirst_${deepAnaCd}`} id={`CheckFirst_${deepAnaCd}`} data={sel} changed={checkChangeFirst} value={FirstCheck} />
            </div>
          </div>
        )}
        {/* 여기서부터 둘째줄 */}
        {sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && Pick.deep_ana_cd === "DA02" && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition pl-[1.6rem] pr-[1.6rem]">
            <div className="float-left h-full w-[calc(25%-0.1rem)] text-[1.4rem] leading-[3.8rem]">{keywordDeepAnaCd}</div>
            <div className="float-left h-full w-[0.2rem] bg-pcConditionLine"></div>
            <div className="float-left w-[calc(75%-0.1rem)] pl-[1.6rem] pt-[1.1rem]">
              <Check key={`CheckSecond_${deepAnaCd}`} id={`CheckSecond_${deepAnaCd}`} data={sel} changed={checkChangeSecond} value={SecondCheck} />
            </div>
          </div>
        )}
        {sel !== null && sel !== undefined && deepAnaCd === "CRSS" && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition pl-[1.6rem] pr-[1.6rem]">
            <div className="float-left w-full pt-[1.1rem]">
              <Check key={`CheckSecond_${deepAnaCd}`} id={`CheckSecond_${deepAnaCd}`} data={sel} changed={checkChangeSecond} value={SecondCheck} />
            </div>
          </div>
        )}
        {sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && Pick.deep_ana_cd === "DA06" && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioSel"}
                id={"RadioDA06"}
                data={sel}
                changed={radioChangeDA06}
                value={RadioDA06Check}
                onClick={(e) => {
                  changeDA06(RadioDA06Check);
                }}
              />
            </div>
          </div>
        )}
        {sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && Pick.deep_ana_cd === "DA09" && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioSel"}
                id={"RadioDA09"}
                data={sel}
                changed={radioChangeDA09}
                value={RadioDA09Check}
                onClick={(e) => {
                  changeDA06(RadioDA09Check);
                }}
              />
            </div>
          </div>
        )}
        {sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && (Pick.deep_ana_cd === "DA07" || Pick.deep_ana_cd === "DA11") && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioSel"}
                id={`Radio${Pick.deep_ana_cd}`}
                data={sel}
                changed={radioChangeDA07DA11}
                value={RadioDA07DA11Check}
                onClick={(e) => {
                  changeDA07DA11(RadioDA07DA11Check);
                }}
              />
            </div>
          </div>
        )}
        {sel !== null && sel !== undefined && Pick.menu_cate === "심화문항" && Pick.deep_ana_cd === "DA08" && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioDA08"}
                id={"RadioDA08"}
                data={sel}
                changed={radioChangeDA08}
                value={RadioDA08Check}
                onClick={(e) => {
                  changeDA08(RadioDA08Check);
                }}
              />
            </div>
          </div>
        )}
        {sel !== null && sel !== undefined && deepAnaCd === "DEP" && (Pick.deep_ana_cd === "DA06" || Pick.deep_ana_cd === "DA08") && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioDEP"}
                id={"RadioDEP"}
                data={sel}
                changed={radioChangeDEP}
                value={RadioDEPCheck}
                onClick={(e) => {
                  changeDEP(RadioDEPCheck);
                }}
              />
            </div>
          </div>
        )}
        {/* q13이랑 겹침으로인한 length 1부여 */}
        {uprGrpSel !== null && uprGrpSel !== undefined && uprGrpSel.length !== 1 && (
          <div className="float-left min-h-[3.8rem] w-full border-t-[0.2rem] border-pcConditionLine bg-pcCondition">
            <div className="float-left w-full pl-[1.6rem] pt-[1.1rem]">
              <Radio
                key={"RadioSel"}
                id={"RadioDA12uprGrp"}
                data={uprGrpSel}
                changed={radioChangeDA12UprGrp}
                value={radioChangeDA12UprGr}
                onClick={(e) => {
                  changeDA12UprGrp(radioChangeDA12UprGr);
                }}
              />
            </div>
          </div>
        )}

        <div className="float-left h-[0.2rem] w-full bg-tableBorderColor"></div>
      </div>
      <div className={`float-left  h-[2.0rem] w-full`}></div>
    </div>
  );
};

export default AnalysisHeader;
