import exceljs from "exceljs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { getPointWith } from "../../utils/Func";
const html2canvas = require("html2canvas");

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const BarColumnChart = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  data_type,
  stack,
  type,
  title,
  qst,
  name,
  Gdata,
}) => {
  Highcharts.setOptions({
    colors: ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"],
  });

  const chart = useRef();

  const convertToCSV = () => {
    const currChart = chart.current.chart;
    const csvData = currChart.getCSV();

    return csvData;
  };

  const downloadChart = (type) => {
    if (chart && chart.current && chart.current.chart) {
      if (type === "jpeg") {
        chart.current.chart.exportChart({ type: "image/jpeg" });
      } else if (type === "png") {
        chart.current.chart.exportChart({ type: "image/png" });
      } else if (type === "pdf") {
        chart.current.chart.exportChart({
          type: "application/pdf",
        });
      } else {
        // 엑셀 워크북 생성
        let workbook = new exceljs.Workbook();
        // 시트 생성
        const imageSheet = workbook.addWorksheet("DataSheet");

        // 텍스트 데이터 csv로 변환
        const csvData = convertToCSV();
        // 불필요한 특수문자 제거 (")
        let filteredCsvData = csvData.replace(/\"/gi, "");

        // excelJs에 맞는 형식으로 전환
        let excelData = [];

        // 개행태그를 기준으로 split
        let dataArr = filteredCsvData.split("\n");

        // ,단위로 split하여 배열로 만든 후, 덩어리를 excelData에 push
        for (let i = 0; i < dataArr.length; i++) {
          let temp = dataArr[i].split(",");

          excelData.push(temp);
        }

        // 시트에 추가
        imageSheet.addRows(excelData);

        // 차트 이미지 작업을 위한 ref 속성 가져오기
        const chartContainer = chart.current.container.current;

        let promise = [];

        // 차트이미지를 스크린샷 찍어 이미지를 시트에 추가
        promise.push(
          html2canvas(chartContainer).then((c) => {
            let image = c.toDataURL();
            const imageId2 = workbook.addImage({
              base64: image,
              extension: "png",
            });

            imageSheet.addImage(imageId2, {
              tl: { col: 0, row: excelData.length + 3 },
              br: { col: chartContainer.offsetWidth / 100, row: excelData.length + 12 + chartContainer.offsetHeight / 100 },
            });
          })
        );

        // 파일 다운로드
        Promise.all(promise).then(() => {
          workbook.xlsx.writeBuffer().then((b) => {
            let a = new Blob([b]);
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement("a");
            elem.href = url;
            elem.download = `${qst}.xlsx`;
            document.body.appendChild(elem);
            elem.style = "display: none";
            elem.click();
            elem.remove();
          });
        });

        return workbook;
      }
    }
  };

  const initialOptions = {
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
      filename: qst + "_" + title,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    credits: { enabled: false },
    tooltip: {
      style: {
        fontSize: "1.4rem",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
    xAxis: {},
    yAxis: {},
    legend: {
      enabled: true,
      itemStyle: {
        fontSize: "1.4rem",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
    series: [], // 데이터가 처음엔 비어았다.
  };

  const [options, setOptions] = useState({});

  useEffect(() => {
    if (name === "standard_accu") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];
          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              // pointWidth: stack === "Y" ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
              pointWidth: stack === "Y" ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              // pointWidth: stack === "Y" ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
              pointWidth: stack === "Y" ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
          },
        });
      }
    } else if (name === "basic") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];

        Gdata.filter((x) => x.sel_NM !== null).map((data, i) => {
          tempSeriesData.push({
            name: data.sel_NM,
            y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
            dataLabels: {
              shadow: false,
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
            color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
          });
          tempCategoriesData.push(data.sel_NM);
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        if (
          Gdata.filter(
            (x) =>
              x.b_RATE !== "" &&
              x.b_RATE !== null &&
              x.b_RATE !== "0.0" &&
              x.b_RATE !== "0" &&
              x.w_RATE !== "" &&
              x.w_RATE !== null &&
              x.w_RATE !== "0.0" &&
              x.w_RATE !== "0"
          ).length > 0
        ) {
          Gdata.map((data, i) => {
            tempSeriesDataBest.push({
              name: data.sel_NM,
              y: data_num === "1" ? (data.b_RATE ? parseFloat(data.b_RATE) : 0) : data.b_RATE_CNT ? parseFloat(data.b_RATE_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
              },
            });
          });

          tempSeries.push({
            name: "Best",
            data: tempSeriesDataBest,
          });

          Gdata.map((data, i) => {
            tempSeriesDataWorst.push({
              name: data.sel_NM,
              y: data_num === "1" ? (data.w_RATE ? parseFloat(data.w_RATE) : 0) : data.w_RATE_CNT ? parseFloat(data.w_RATE_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
              },
            });
          });

          tempSeries.push({
            name: "Worst",
            data: tempSeriesDataWorst,
          });
        }

        const pointWidth = getPointWith(tempCategoriesData.length, tempSeries.length, stack, type);

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  // pointWidth: stack === "Y" ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
                  pointWidth: 500 / (tempCategoriesData.length * tempSeries.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: (tempSeriesData.length + tempSeriesDataBest.length + tempSeriesDataWorst.length) / tempCategoriesData.length > 1 ? true : false,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: pointWidth, //500 / (tempCategoriesData.length * tempSeries.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
              // pointWidth: stack === "Y" ? 50 : 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 500 / (tempCategoriesData.length * tempSeries.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
              // pointWidth: stack === "Y" ? 50 : 30,
            },
          },
        });
      }
    } else if (name === "assign") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];
          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth:
                    500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth:
                500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 30, // 500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
            },
          },
        });
      }
    } else if (name === "doc") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];
          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "8px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "8px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "8px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "7px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth:
                    500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth:
                500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 30, // 500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
            },
          },
        });
      }
    } else if (name === "octy" || name === "wrk" || name === "sex" || name === "age") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        const pointWidth = getPointWith(tempCategoriesData.length, tempSeries.length, stack, type);

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 300 / tempCategoriesData.length,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: pointWidth, //500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2) > 50,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2) > 50,
            },
          },
        });
      }
    } else if (name === "mainDept") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd && x !== null)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              if (tempSeriesData.filter((x) => x.name === data.sel_NM && x !== null).length === 0) {
                tempSeriesData.push({
                  name: data.sel_NM,
                  y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                  dataLabels: {
                    shadow: false,
                    fontSize: "1.4rem",
                    fontFamily: "AppleSDGothicNeoRegular",
                  },
                  color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
                });
              }
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 40,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (name === "ageMainDept") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 40,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (name === "ageSex") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 40,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData === null ? [] : tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (name === "area") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 40,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (name === "ageArea") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 40,
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40,
            },
          },
        });
      }
    } else if (name === "etc1") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40, //500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
            },
          },
        });
      }
    } else if (name === "etc2") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40, // 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
            },
          },
        });
      }
    } else if (name === "etc3") {
      if (Gdata !== null && Gdata !== undefined && Gdata.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        Gdata.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          Gdata.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                color: stack !== "Y" && data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempCategoriesData.length + 1) * 55,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: qst + "_" + title,
            chartOptions: {
              chart: {
                spacing: [30, 30, 30, 30],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
                column: {
                  dataLabels: {
                    enabled: true,
                    inside: true,
                    style: {
                      textShadow: 0,
                      textOutline: 0,
                      color: "#000000",
                    },
                  },
                  stacking: stack === "Y" ? "normal" : "",
                  pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData || [],
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: stack === "Y" ? "normal" : "",
              pointWidth: 40, // 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length + 2),
            },
          },
        });
      }
    }
  }, [Gdata, sort_type, data_num, data_type, sel_cd]);

  return (
    <div className="float-left w-full">
      <div
        className={`float-left h-[4.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor ${
          title !== null && title !== undefined && title !== "" && qst !== null && qst !== undefined && qst !== "" ? "" : "hidden"
        }`}
      >
        <div className="float-left h-full w-[calc(100%-15.0rem)] text-[2.0rem] font-bold leading-[3.0rem]">{title}</div>
        <div className="float-left h-full w-[15.0rem] leading-[6.0rem]">
          <div
            className="float-right ml-[0.5rem] h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_png bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("png");
            }}
          ></div>
          <div
            className="float-right ml-[0.5rem] h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_pdf bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("pdf");
            }}
          ></div>
          <div
            className="float-right ml-[0.5rem] h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_jpeg bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("jpeg");
            }}
          ></div>
          <div
            className="float-right h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_excel bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("excel");
            }}
          ></div>
        </div>
      </div>
      <div className="float-left w-full">
        {Object.keys(options).length > 0 &&
        Gdata?.length > 0 &&
        (options?.xAxis === undefined || options?.xAxis === null
          ? false
          : options?.xAxis.categories === undefined || options?.xAxis.categories === null
          ? false
          : true) ? (
          <HighchartsReact
            id={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + sort_type + "_" + data_num + "_" + data_type}`}
            ref={chart}
            highcharts={Highcharts}
            options={options}
            allowChartUpdate={true}
          />
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-center p-20">
            <ClipLoader color="#1686FF" />
          </div>
        )}
      </div>
    </div>
  );
};

export default BarColumnChart;
