import { useEffect, useState } from "react";
import Select from "react-select";
import useGetAdminDetailQst from "../../../hooks/useGetAdminDetailQst";
import usePatchDetailQst from "../../../hooks/usePatchDetailQst";
import { useAdminQstSelState } from "../../../store/mobile/store";
import useStore from "../../../store/pc/store";

const AdminQstDetailModalComp = (props) => {
  const [order, setOrder] = useState(0);
  const [grpData, setGrpData] = useState("");
  const [qstType, setQstType] = useState("");
  const [deepAnaCd, setDeepAnaCd] = useState("");
  const [uprQst, setUprQst] = useState("");
  const [crssQstCd, setCrssQstCd] = useState("");
  const [deepQstCd, setDeepQstCd] = useState("");
  const [qst, setQst] = useState("");
  const [sort, setSort] = useState("");
  const [keyword, setKeyword] = useState("");
  const [npsStWd, setNpsStWd] = useState("");
  const [npsEdWd, setNpsEdWd] = useState("");
  const [multiCount, setMultiCount] = useState(0);
  const [status, setStatus] = useState(false);
  const PrjCd = useStore((state) => state.PrjCd);

  const adminQstSelState = useAdminQstSelState((state) => state.adminQstSelState);

  const { data } = useGetAdminDetailQst(PrjCd, props.qstCd, props.projectType);
  const { mutate, isSuccess } = usePatchDetailQst();

  const handleChangeOrder = (e) => {
    setOrder(e.target.value);
  };

  const handleChangeGrp = (e) => {
    setGrpData(e);
  };

  const handleChangeQstTyp = (e) => {
    // qstTyp가 q03일 때
    if (e.value === "Q03") {
      if (adminQstSelState.filter((el) => el.qst_CD === props.qstCd)[0]?.opt.length === 1) {
        setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA04"));
      } else if (adminQstSelState.filter((el) => el.qst_CD === props.qstCd)[0]?.opt.length > 1) {
        setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA06"));
      }
      setMultiCount(0);
    }
    // qstTyp가 q04일 때
    else if (e.value === "Q04") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA08"));
      setMultiCount(0);
    }
    // qstTyp가 q06일 때
    else if (e.value === "Q06") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA10"));
      setMultiCount(0);
    }
    // qstTyp가 q07일 때
    else if (e.value === "Q07") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA10"));
      setMultiCount(0);
    }
    // qstTyp가 q08일 때
    else if (e.value === "Q08") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA10"));
      setMultiCount(0);
    }
    // qstTyp가 q09일 때
    else if (e.value === "Q09") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA07"));
      setMultiCount(2);
    }
    // qstTyp가 q12일 때
    else if (e.value === "Q12") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA11"));
      setMultiCount(3);
    }
    // qstTyp가 q13일 때
    else if (e.value === "Q13") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA12"));
      setMultiCount(data?.data.qst_INFO[0].multi_COUNT);
    }
    // qstTyp가 q14일 때
    else if (e.value === "Q14") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA11"));
      setMultiCount(0);
    }
    // qstTyp가 q16일 때
    else if (e.value === "Q16") {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === "DA10"));
      setMultiCount(0);
    }
    // 그 외
    else {
      setDeepAnaCd(data?.data.deep_ANA_CD.filter((el) => el.value === ""));
      setMultiCount(0);
    }

    setQstType(e);
  };

  const handleChangeDeepAnaCd = (e) => {
    setDeepAnaCd(e);
  };

  const handleChangeUprQst = (e) => {
    setUprQst(e);
  };

  const handleChangeCrssQst = (e) => {
    setCrssQstCd(e);
  };

  const handleChangeDeepQst = (e) => {
    setDeepQstCd(e);
  };

  const handleChangeQst = (e) => {
    setQst(e.target.value);
  };

  const handleChangeSort = (e) => {
    setSort(e);
  };

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleChangeNpsStWd = (e) => {
    setNpsStWd(e.target.value);
  };

  const handleChangeNpsEdWd = (e) => {
    setNpsEdWd(e.target.value);
  };

  const handleChangeMultiCount = (e) => {
    setMultiCount(e.target.value);
  };

  const handleClickSubmit = () => {
    let body = {
      prj_CD: PrjCd,
      qst_CD: props.qstCd,
      order: Number(order),
      origin_ORDER: data?.data.qst_INFO[0].seq,
      grp_DVS: grpData.value,
      qst_TYP: qstType.value,
      deep_ANA_CD: deepAnaCd.value,
      upr_GRP: uprQst.value,
      crss_QST_CD: crssQstCd.value,
      deep_QST_CD: deepQstCd.value,
      qst: qst,
      sort_TYPE: sort.value,
      keyword: keyword,
      nps_ST_WD: npsStWd,
      nps_ED_WD: npsEdWd,
      multi_COUNT: multiCount,
      svy_OJT: props.projectType,
    };

    console.log(body, "body좀보자");
    mutate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      setStatus(true);

      setTimeout(() => {
        setStatus(false);
      }, 1000);
    }
  }, [isSuccess]);

  useEffect(() => {
    setOrder(data?.data.qst_INFO[0].seq);
    setGrpData(data?.data.grp_DVS.filter((el) => el.value === data?.data.qst_INFO[0].grp_DVS)[0]);
    setQstType(data?.data.qst_TYP.filter((el) => el.value === data?.data.qst_INFO[0].qst_TYP)[0]);
    setDeepAnaCd(
      data?.data.deep_ANA_CD.filter((el) => el.value === (data?.data.qst_INFO[0].deep_ANA_CD === null ? "" : data?.data.qst_INFO[0].deep_ANA_CD))[0]
    );
    setUprQst(data?.data.upr_QST.filter((el) => el.value === (data?.data.qst_INFO[0].upr_GRP === null ? "" : data?.data.qst_INFO[0].upr_GRP))[0]);
    setCrssQstCd(
      data?.data.qst.filter((el) => el.value === (data?.data.qst_INFO[0].crss_QST_CD === null ? "" : data?.data.qst_INFO[0].crss_QST_CD))[0]
    );
    setDeepQstCd(
      data?.data.qst.filter((el) => el.value === (data?.data.qst_INFO[0].deep_QST_CD === null ? "" : data?.data.qst_INFO[0].deep_QST_CD))[0]
    );
    setQst(data?.data.qst_INFO[0].qst);
    setSort(data?.data.sort.filter((el) => el.value === data?.data.qst_INFO[0].sort_TYPE)[0]);
    setKeyword(data?.data.qst_INFO[0].keyword);
    setNpsStWd(data?.data.qst_INFO[0].nps_ST_WD);
    setNpsEdWd(data?.data.qst_INFO[0].nps_ED_WD);
    setMultiCount(data?.data.qst_INFO[0].multi_COUNT);
  }, [data]);

  console.log(data, "data좀보자");

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-start">
      {status ? (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-100 duration-300 ease-out">
          저장되었습니다
        </div>
      ) : (
        <div className="absolute top-0 flex h-fit w-[300px] flex-col items-center justify-center rounded-lg bg-orangeColor p-6 text-[16px] font-bold text-white opacity-0 duration-300 ease-out">
          저장되었습니다
        </div>
      )}

      {/* title */}
      <div className="mt-10 flex h-fit w-full flex-row items-center justify-start py-5 font-SDGothic text-[24px] font-extrabold text-selectTextColor/80">
        문항 상세정보
      </div>
      {data && (
        <div className="mt-10 flex h-fit w-full flex-col items-center justify-start rounded-xl bg-FilterInActiveColor/20 px-10 py-20 font-SDGothic font-bold">
          {/* 첫번째 row */}
          <div className="flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">순서</div>
              <input type="text" className="h-fit w-[80px] text-right text-selectTextColor outline-none" value={order} onChange={handleChangeOrder} />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">그룹구분</div>
              {grpData && (
                <Select
                  options={data?.data.grp_DVS}
                  isSearchable={false}
                  value={grpData}
                  onChange={handleChangeGrp}
                  className="w-[160px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#FFFFFF",
                      border: "0px",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      color: "#383838",
                    }),
                    indicatorSeparator: (styles) => ({
                      display: "none",
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      color: "#1686FF",
                    }),
                  }}
                />
              )}
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">문항타입</div>
              <Select
                options={data?.data.qst_TYP}
                isSearchable={false}
                value={qstType}
                onChange={handleChangeQstTyp}
                className="w-[150px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">심화분석</div>
              <Select
                options={data?.data.deep_ANA_CD}
                isSearchable={false}
                value={deepAnaCd}
                onChange={handleChangeDeepAnaCd}
                className="w-[160px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>
          {/* 두번째 row */}
          <div className="mt-5 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-full flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">상위문항</div>
              <Select
                options={data?.data.upr_QST}
                isSearchable={false}
                value={uprQst}
                onChange={handleChangeUprQst}
                className="w-[90%] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>
          {/* 세번째 row */}
          <div className="mt-5 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-full flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">교차문항</div>
              <Select
                options={data?.data.qst}
                isSearchable={false}
                value={crssQstCd}
                onChange={handleChangeCrssQst}
                className="w-[90%] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>
          {/* 세번째 row */}
          <div className="mt-5 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-full flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">심화문항</div>
              <Select
                options={data?.data.qst}
                isSearchable={false}
                value={deepQstCd}
                onChange={handleChangeDeepQst}
                className="w-[90%] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
          </div>
          {/* 네번째 row */}
          <div className="mt-5 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2  flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6  text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">멀티선택 개수</div>
              <input
                type="text"
                readOnly={qstType?.value === "Q13" ? false : true}
                className="h-fit w-[80px] text-right text-selectTextColor outline-none"
                value={multiCount}
                onChange={handleChangeMultiCount}
              />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-[77%] flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">문항</div>
              <input
                type="text"
                spellCheck={false}
                className="h-fit w-[90%] text-right text-selectTextColor outline-none"
                value={qst}
                onChange={handleChangeQst}
              />
            </div>
          </div>

          {/* 마지막 row */}
          <div className="mt-5 flex h-fit w-full flex-row items-center justify-between">
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  py-[5.5px] pl-5 pr-1 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">정렬</div>
              <Select
                options={data?.data.sort}
                isSearchable={false}
                value={sort}
                onChange={handleChangeSort}
                className="w-[130px] bg-white font-SDGothic text-2xl text-selectTextColor/80"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "#FFFFFF",
                    border: "0px",
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    color: "#383838",
                  }),
                  indicatorSeparator: (styles) => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: "#1686FF",
                  }),
                }}
              />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit">키워드</div>
              <input
                type="text"
                spellCheck={false}
                className="h-fit w-[150px] text-right text-selectTextColor outline-none"
                value={keyword}
                onChange={handleChangeKeyword}
              />
            </div>
            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">NPS 부정워딩</div>
              <input
                type="text"
                spellCheck={false}
                className="h-fit w-[110px] text-right text-selectTextColor outline-none"
                value={npsStWd}
                onChange={handleChangeNpsStWd}
              />
            </div>

            {/* input items */}
            <div className="mx-2 flex h-fit w-1/4 flex-row items-center justify-between rounded-lg border border-FilterInActiveColor bg-white  p-6 text-[15px] text-selectTextColor/80">
              <div className="h-fit w-fit leading-5">NPS 긍정워딩</div>
              <input
                type="text"
                spellCheck={false}
                className="h-fit w-[110px] text-right text-selectTextColor outline-none"
                value={npsEdWd}
                onChange={handleChangeNpsEdWd}
              />
            </div>
          </div>
        </div>
      )}

      {/* save btn */}
      <button
        type="button"
        onClick={handleClickSubmit}
        className="mt-20 flex h-fit w-fit flex-col items-center justify-center rounded-xl bg-loginBlueColor px-14 py-6 font-SDGothic text-[16px]  font-semibold text-white hover:bg-loginBlueColor/70"
      >
        저장
      </button>
    </div>
  );
};

export default AdminQstDetailModalComp;
