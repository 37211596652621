import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BarColumnChartTotal from "../../components/pc/BarColumnChartTotal";
import Loading from "../../components/pc/Loading";
import { menuDict } from "../../dict/pc/dict";
import { useGraphTotal } from "../../hooks/useGraphTotal";
import usePostTotalMake from "../../hooks/usePostTotalMake";
import { useWordTotal } from "../../hooks/useWordTotal";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const TotalPage = () => {
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const HspCd = useStore((state) => state.HspCd);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [Wording, setWording] = useState([]);
  const [InptDtm, setInptDtm] = useState("");

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const { mutate: insertMutate } = usePostTotalMake();

  const navigate = useNavigate();

  const makeES = () => {
    if (PrjCd && window.confirm("기존 워딩을 삭제하고 새로 생성하시겠습니까?")) {
      const body = {
        prj_CD: PrjCd,
        seq: 0,
      };
      setLoading(true);
      insertMutate(body);
    }
  };

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  const { isGraphTotalLoading, isGraphTotalError, GraphTotalData, isGraphTotalSuccess, GraphTotalRefetch } = useGraphTotal(
    PrjCd,
    "",
    "",
    "",
    "",
    "",
    "",
    startDate,
    endDate
  );

  const { isWordTotalLoading, isWordTotalError, WordTotalData, isWordTotalSuccess, WordTotalRefetch } = useWordTotal(
    PrjCd,
    "",
    "",
    "",
    "",
    "",
    "",
    startDate,
    endDate
  );

  useEffect(() => {
    GraphTotalRefetch();
    WordTotalRefetch();
  }, [PrjCd, startDate, endDate]);

  useEffect(() => {
    if (!isWordTotalSuccess || WordTotalData === null || WordTotalData === undefined || WordTotalData.length === 0) {
      WordTotalRefetch();
    } else {
      if (WordTotalData?.length > 0) {
        var vArray = [];
        var vCnte = WordTotalData ? WordTotalData[0].cnte : "";
        vArray = vCnte.split("▒▒");

        setWording([...vArray]);
        setLoading(false);
      }
    }
  }, [WordTotalData, startDate, endDate]);

  useEffect(() => {
    if (Wording?.length > 0) {
      var vString = "";
      var vDate = new Date(WordTotalData[0].inpt_DTM);
      vString =
        "생성일시: " +
        vDate.getFullYear() +
        "년 " +
        (vDate.getMonth() + 1) +
        "월" +
        vDate.getDate() +
        "일 " +
        vDate.getHours() +
        "시 " +
        vDate.getMinutes() +
        "분 " +
        vDate.getSeconds() +
        "초";
      setInptDtm(vString);
    }
  }, [Wording]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <div
          className={`float-left w-full text-[1.6rem] leading-[3.0rem]`}
          onClick={() => {
            makeES();
          }}
        >
          <div className="float-right h-[3.0rem] w-[8.0rem] cursor-pointer rounded-xl bg-orangeColor text-center font-bold text-white hover:bg-orangeColor/80">
            생성
          </div>
          {InptDtm && <div className={`float-left mr-[2.0rem] w-[calc(100%-10.0rem)] text-right`}>{InptDtm}</div>}
        </div>
        {WordTotalData?.length > 0 &&
          Wording?.map((data, i) => {
            return (
              <div key={`Wording_${i}`} className={`float-left w-full text-[1.8rem] ${InptDtm ? "mt-[2.0rem]" : ""} leading-[3.0rem]`}>
                <div className={`float-left w-full ${i === 0 ? "" : "mt-[2.0rem]"}`}>
                  <div className="float-left w-[3.0rem] text-orangeColor">◈</div>
                  <div className="float-left w-[calc(100%-3.0rem)]">{data}</div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="float-left mt-[2.0rem] w-full">
        <BarColumnChartTotal
          hsp_nm={HspNm}
          prj_cd={PrjCd}
          svy_ojt={SvyOjt}
          qst_cd={Pick.qst_cd}
          sel_cd={""}
          string_value={""}
          where={SvyOjt === "MEM" ? "OCTY" : "AGE_GRP"}
          sort_type={"M"}
          data_num={""}
          data_type={""}
          stack={"N"}
          type={"column"}
          title={"실시간 설문 참여 현황"}
          qst={"실시간 설문 참여 현황"}
          name={"total"}
          data={GraphTotalData}
        />
      </div>
    </div>
  );
};

export default TotalPage;
