import { useMutation, useQueryClient } from "@tanstack/react-query";
import postInsertAdminDept from "../api/postInsertAdminDept";

const usePostInsertAdminDept = () => {
  const queryClient = useQueryClient();

  return useMutation(postInsertAdminDept, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserDept"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostInsertAdminDept;
