import axios from "axios";

const postAIChartRes = (body) => {
    return axios.post("/gpt/aiChart", body, {
        baseURL : process.env.REACT_APP_SERVER_ADDRESS,
        headers: {
            "content-Type": `application/json`,
          },
    })
}

export default postAIChartRes;