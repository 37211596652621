import axios from "axios";

const getComnDict = () => {
  return axios.get(`/AnalysisMobile/comnData`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getComnDict;
