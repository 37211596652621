const TableHeader = (props) => {
  return (
    <div className="flex h-fit w-full flex-row items-center justify-center border border-r-0 border-t border-tableBorderColor border-t-orangeColor bg-tableHeaderColor font-bold">
      <div className="flex h-fit w-1/3 flex-col items-center justify-center border-r border-r-tableBorderColor py-5 font-SDGothic text-[14px] text-selectTextColor">
        {props.props[0]}
      </div>
      <div className="flex h-fit w-1/3 flex-col items-center justify-center border-r border-r-tableBorderColor py-5 font-SDGothic text-[14px] text-selectTextColor">
        {props.props[1]}
      </div>
      <div className="flex h-fit w-1/3 flex-col items-center justify-center border-r border-r-tableBorderColor py-5 font-SDGothic text-[14px] text-selectTextColor">
        {props.props[2]}
      </div>
    </div>
  );
};

export default TableHeader;
