import axios from "axios";

const postUploadUserFile = (data) => {
  return axios.post("/adminUser/adminUserFile", data, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default postUploadUserFile;
