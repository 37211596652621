import axios from "axios";

const deleteAdminUserDept = (data) => {
  return axios.delete("/adminUser/deleteAdminUserDept", {
    data: data,
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
  });
};

export default deleteAdminUserDept;
