import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QstListApi } from "../api/QstListApi";

export const useQstList = (prj_cd, svy_ojt) => {
  const queryClient = useQueryClient();
  //queryClient.invalidateQueries(["login", user_id]);
  const {
    isLoading: isQstListLoading,
    isError: isQstListError,
    data: QstListData,
    isSuccess: isQstListSuccess,
    refetch: QstListRefetch,
  } = useQuery(
    ["qst_list", prj_cd + svy_ojt],
    () => QstListApi(prj_cd, svy_ojt),
    {
      enabled: false, // 마운트 시 자동으로 쿼리 실행
      skip: true,
      onSuccess: (data) => {
        //console.log(data);
        queryClient.invalidateQueries({
          queryKey: ["qst_list"],
          refetchActive: true,
        });
      },
      onError: (e) => {
        // 실패시 호출 (401, 404 같은 error가 아니라 정말 api 호출이 실패한 경우만 호출됩니다.)
        // 강제로 에러 발생시키려면 api단에서 throw Error 날립니다.
        // (참조: https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default)
        //console.log(e.message);
      },
      onSettled: (data, err) => {
        //console.log(data, err);
      },
      refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    isQstListLoading,
    isQstListError,
    QstListData,
    isQstListSuccess,
    QstListRefetch,
  };
};
