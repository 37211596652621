import { useEffect } from "react";

const DA01Table = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  data_type,
  stack,
  type,
  title,
  qst,
  name,
  Tdata,
}) => {
  useEffect(() => {}, [Tdata, prj_cd, svy_ojt, qst_cd, name, data_num, data_type]);

  return (
    <div key={`table_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}`} className="float-left w-full">
      {name === "age" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-66.6rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              10대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              20대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              30대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              40대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              50대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              60대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              70대
            </div>
            <div className="flex h-[3.0rem] w-[7.4rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              80대
            </div>
          </div>
          {Tdata && Tdata !== null && (
            <div
              key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_0`}
              className={`float-left flex w-[calc(100%-93.1rem)] items-center justify-center border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem]`}
              style={{
                height: (Tdata.length * 3).toString() + "rem",
              }}
            >
              위상↑
            </div>
          )}
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_1`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[26.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a10_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_CNT)).toString().toLocaleString()
                          : data.a10_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a20_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_CNT)).toString().toLocaleString()
                          : data.a20_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a30_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_CNT)).toString().toLocaleString()
                          : data.a30_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a40_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_CNT)).toString().toLocaleString()
                          : data.a40_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a50_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_CNT)).toString().toLocaleString()
                          : data.a50_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a60_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_CNT)).toString().toLocaleString()
                          : data.a60_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a70_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_CNT)).toString().toLocaleString()
                          : data.a70_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a80_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_CNT)).toString().toLocaleString()
                          : data.a80_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[26.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a10
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10)).toString().toLocaleString()
                            : data.a10.toLocaleString()
                          : 0
                        : data.a10_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_CNT)).toString().toLocaleString()
                          : data.a10_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a20
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20)).toString().toLocaleString()
                            : data.a20.toLocaleString()
                          : 0
                        : data.a20_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_CNT)).toString().toLocaleString()
                          : data.a20_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a30
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30)).toString().toLocaleString()
                            : data.a30.toLocaleString()
                          : 0
                        : data.a30_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_CNT)).toString().toLocaleString()
                          : data.a30_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a40
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40)).toString().toLocaleString()
                            : data.a40.toLocaleString()
                          : 0
                        : data.a40_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_CNT)).toString().toLocaleString()
                          : data.a40_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a50
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50)).toString().toLocaleString()
                            : data.a50.toLocaleString()
                          : 0
                        : data.a50_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_CNT)).toString().toLocaleString()
                          : data.a50_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a60
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60)).toString().toLocaleString()
                            : data.a60.toLocaleString()
                          : 0
                        : data.a60_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_CNT)).toString().toLocaleString()
                          : data.a60_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a70
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70)).toString().toLocaleString()
                            : data.a70.toLocaleString()
                          : 0
                        : data.a70_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_CNT)).toString().toLocaleString()
                          : data.a70_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a80
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80)).toString().toLocaleString()
                            : data.a80.toLocaleString()
                          : 0
                        : data.a80_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_CNT)).toString().toLocaleString()
                          : data.a80_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-[93.1rem]">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2_${ii}`}
                          className="float-left flex w-[83.1rem]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[16.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot)).toString().toLocaleString()
                                  : dd.tot.toLocaleString()
                                : 0
                              : dd.tot_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_CNT)).toString().toLocaleString()
                                : dd.tot_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a10
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a10)).toString().toLocaleString()
                                  : dd.a10.toLocaleString()
                                : 0
                              : dd.a10_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a10_CNT)).toString().toLocaleString()
                                : dd.a10_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a20
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a20)).toString().toLocaleString()
                                  : dd.a20.toLocaleString()
                                : 0
                              : dd.a20_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a20_CNT)).toString().toLocaleString()
                                : dd.a20_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a30
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a30)).toString().toLocaleString()
                                  : dd.a30.toLocaleString()
                                : 0
                              : dd.a30_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a30_CNT)).toString().toLocaleString()
                                : dd.a30_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a40
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a40)).toString().toLocaleString()
                                  : dd.a40.toLocaleString()
                                : 0
                              : dd.a40_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a40_CNT)).toString().toLocaleString()
                                : dd.a40_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a50
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a50)).toString().toLocaleString()
                                  : dd.a50.toLocaleString()
                                : 0
                              : dd.a50_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a50_CNT)).toString().toLocaleString()
                                : dd.a50_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a60
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a60)).toString().toLocaleString()
                                  : dd.a60.toLocaleString()
                                : 0
                              : dd.a60_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a60_CNT)).toString().toLocaleString()
                                : dd.a60_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a70
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a70)).toString().toLocaleString()
                                  : dd.a70.toLocaleString()
                                : 0
                              : dd.a70_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a70_CNT)).toString().toLocaleString()
                                : dd.a70_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a80
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a80)).toString().toLocaleString()
                                  : dd.a80.toLocaleString()
                                : 0
                              : dd.a80_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a80_CNT)).toString().toLocaleString()
                                : dd.a80_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          {Tdata && Tdata !== null && (
            <div
              key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_4`}
              className={`float-left flex w-[calc(100%-93.1rem)] items-center justify-center border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem]`}
              style={{
                height: (Tdata.length * 3).toString() + "rem",
              }}
            >
              위상↓
            </div>
          )}
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_5`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[26.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a10_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_2_CNT)).toString().toLocaleString()
                          : data.a10_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a20_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_2_CNT)).toString().toLocaleString()
                          : data.a20_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a30_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_2_CNT)).toString().toLocaleString()
                          : data.a30_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a40_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_2_CNT)).toString().toLocaleString()
                          : data.a40_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a50_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_2_CNT)).toString().toLocaleString()
                          : data.a50_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a60_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_2_CNT)).toString().toLocaleString()
                          : data.a60_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a70_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_2_CNT)).toString().toLocaleString()
                          : data.a70_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.a80_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_2_CNT)).toString().toLocaleString()
                          : data.a80_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_6`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[26.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a10_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a10_2)).toString().toLocaleString()
                            : data.a10_2.toLocaleString()
                          : 0
                        : data.a10_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a10_2_CNT)).toString().toLocaleString()
                          : data.a10_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a20_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a20_2)).toString().toLocaleString()
                            : data.a20_2.toLocaleString()
                          : 0
                        : data.a20_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a20_2_CNT)).toString().toLocaleString()
                          : data.a20_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a30_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a30_2)).toString().toLocaleString()
                            : data.a30_2.toLocaleString()
                          : 0
                        : data.a30_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a30_2_CNT)).toString().toLocaleString()
                          : data.a30_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a40_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a40_2)).toString().toLocaleString()
                            : data.a40_2.toLocaleString()
                          : 0
                        : data.a40_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a40_2_CNT)).toString().toLocaleString()
                          : data.a40_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a50_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a50_2)).toString().toLocaleString()
                            : data.a50_2.toLocaleString()
                          : 0
                        : data.a50_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a50_2_CNT)).toString().toLocaleString()
                          : data.a50_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a60_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a60_2)).toString().toLocaleString()
                            : data.a60_2.toLocaleString()
                          : 0
                        : data.a60_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a60_2_CNT)).toString().toLocaleString()
                          : data.a60_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a70_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a70_2)).toString().toLocaleString()
                            : data.a70_2.toLocaleString()
                          : 0
                        : data.a70_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a70_2_CNT)).toString().toLocaleString()
                          : data.a70_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.a80_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.a80_2)).toString().toLocaleString()
                            : data.a80_2.toLocaleString()
                          : 0
                        : data.a80_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.a80_2_CNT)).toString().toLocaleString()
                          : data.a80_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_7_1`} className="float-left w-[93.1rem]">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Age_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_7_2_${ii}`}
                          className="float-left flex w-[83.1rem]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[16.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot_2)).toString().toLocaleString()
                                  : dd.tot_2.toLocaleString()
                                : 0
                              : dd.tot_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_2_CNT)).toString().toLocaleString()
                                : dd.tot_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a10_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a10_2)).toString().toLocaleString()
                                  : dd.a10_2.toLocaleString()
                                : 0
                              : dd.a10_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a10_2_CNT)).toString().toLocaleString()
                                : dd.a10_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a20_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a20_2)).toString().toLocaleString()
                                  : dd.a20_2.toLocaleString()
                                : 0
                              : dd.a20_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a20_2_CNT)).toString().toLocaleString()
                                : dd.a20_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a30_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a30_2)).toString().toLocaleString()
                                  : dd.a30_2.toLocaleString()
                                : 0
                              : dd.a30_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a30_2_CNT)).toString().toLocaleString()
                                : dd.a30_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a40_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a40_2)).toString().toLocaleString()
                                  : dd.a40_2.toLocaleString()
                                : 0
                              : dd.a40_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a40_2_CNT)).toString().toLocaleString()
                                : dd.a40_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a50_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a50_2)).toString().toLocaleString()
                                  : dd.a50_2.toLocaleString()
                                : 0
                              : dd.a50_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a50_2_CNT)).toString().toLocaleString()
                                : dd.a50_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a60_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a60_2)).toString().toLocaleString()
                                  : dd.a60_2.toLocaleString()
                                : 0
                              : dd.a60_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a60_2_CNT)).toString().toLocaleString()
                                : dd.a60_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a70_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a70_2)).toString().toLocaleString()
                                  : dd.a70_2.toLocaleString()
                                : 0
                              : dd.a70_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a70_2_CNT)).toString().toLocaleString()
                                : dd.a70_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[7.4rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.a80_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.a80_2)).toString().toLocaleString()
                                  : dd.a80_2.toLocaleString()
                                : 0
                              : dd.a80_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.a80_2_CNT)).toString().toLocaleString()
                                : dd.a80_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      )}
      {name === "assign" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="float-left flex h-[6.0rem] w-[calc(100%-65.64rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="float-left flex h-[6.0rem] w-[65.64rem] flex-col">
              <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
                <div className="float-left flex h-full w-[32.82rem] items-center justify-center border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  위상 상승 이유
                </div>
                <div className="float-left flex h-full w-[32.82rem] items-center justify-center border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  위상 하락 이유
                </div>
              </div>
              <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  전체
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  보직자
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  구성원
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  전체
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  보직자
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  구성원
                </div>
              </div>
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Assign_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_1`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.h_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_T_CNT)).toString().toLocaleString()
                          : data.h_T_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.h_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_Y_CNT)).toString().toLocaleString()
                          : data.h_Y_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.h_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_N_CNT)).toString().toLocaleString()
                          : data.h_N_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.l_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_T_CNT)).toString().toLocaleString()
                          : data.l_T_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.l_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_Y_CNT)).toString().toLocaleString()
                          : data.l_Y_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.l_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_N_CNT)).toString().toLocaleString()
                          : data.l_N_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Assign_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.h_T
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.h_T)).toString().toLocaleString()
                            : data.h_T.toLocaleString()
                          : 0
                        : data.h_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_T_CNT)).toString().toLocaleString()
                          : data.h_T_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.h_Y
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.h_Y)).toString().toLocaleString()
                            : data.h_Y.toLocaleString()
                          : 0
                        : data.h_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_Y_CNT)).toString().toLocaleString()
                          : data.h_Y_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.h_N
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.h_N)).toString().toLocaleString()
                            : data.h_N.toLocaleString()
                          : 0
                        : data.h_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_N_CNT)).toString().toLocaleString()
                          : data.h_N_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.l_T
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.l_T)).toString().toLocaleString()
                            : data.l_T.toLocaleString()
                          : 0
                        : data.l_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_T_CNT)).toString().toLocaleString()
                          : data.l_T_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.l_Y
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.l_Y)).toString().toLocaleString()
                            : data.l_Y.toLocaleString()
                          : 0
                        : data.l_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_Y_CNT)).toString().toLocaleString()
                          : data.l_Y_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.l_N
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.l_N)).toString().toLocaleString()
                            : data.l_N.toLocaleString()
                          : 0
                        : data.l_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_N_CNT)).toString().toLocaleString()
                          : data.l_N_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Assign_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-full">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Assign_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2_${ii}`}
                          className="float-left flex w-[calc(100%-10.0rem)]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.h_T
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.h_T)).toString().toLocaleString()
                                  : dd.h_T.toLocaleString()
                                : 0
                              : dd.h_T_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.h_T_CNT)).toString().toLocaleString()
                                : dd.h_T_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.h_Y
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.h_Y)).toString().toLocaleString()
                                  : dd.h_Y.toLocaleString()
                                : 0
                              : dd.h_Y_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.h_Y_CNT)).toString().toLocaleString()
                                : dd.h_Y_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.h_N
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.h_N)).toString().toLocaleString()
                                  : dd.h_N.toLocaleString()
                                : 0
                              : dd.h_N_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.h_N_CNT)).toString().toLocaleString()
                                : dd.h_N_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.l_T
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.l_T)).toString().toLocaleString()
                                  : dd.l_T.toLocaleString()
                                : 0
                              : dd.l_T_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.l_T_CNT)).toString().toLocaleString()
                                : dd.l_T_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.l_Y
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.l_Y)).toString().toLocaleString()
                                  : dd.l_Y.toLocaleString()
                                : 0
                              : dd.l_Y_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.l_Y_CNT)).toString().toLocaleString()
                                : dd.l_Y_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.l_N
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.l_N)).toString().toLocaleString()
                                  : dd.l_N.toLocaleString()
                                : 0
                              : dd.l_N_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.l_N_CNT)).toString().toLocaleString()
                                : dd.l_N_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      )}
      {name === "doc" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="float-left flex h-[6.0rem] w-[calc(100%-65.64rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="float-left flex h-[6.0rem] w-[65.64rem] flex-col">
              <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
                <div className="float-left flex h-full w-[32.82rem] items-center justify-center border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  위상 상승 이유
                </div>
                <div className="float-left flex h-full w-[32.82rem] items-center justify-center border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  위상 하락 이유
                </div>
              </div>
              <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  전체
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  의사직
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  구성원
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  전체
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  의사직
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  구성원
                </div>
              </div>
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Doc_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_1`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.h_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_T_CNT)).toString().toLocaleString()
                          : data.h_T_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.h_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_Y_CNT)).toString().toLocaleString()
                          : data.h_Y_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.h_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_N_CNT)).toString().toLocaleString()
                          : data.h_N_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.l_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_T_CNT)).toString().toLocaleString()
                          : data.l_T_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.l_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_Y_CNT)).toString().toLocaleString()
                          : data.l_Y_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.l_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_N_CNT)).toString().toLocaleString()
                          : data.l_N_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Doc_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.h_T
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.h_T)).toString().toLocaleString()
                            : data.h_T.toLocaleString()
                          : 0
                        : data.h_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_T_CNT)).toString().toLocaleString()
                          : data.h_T_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.h_Y
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.h_Y)).toString().toLocaleString()
                            : data.h_Y.toLocaleString()
                          : 0
                        : data.h_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_Y_CNT)).toString().toLocaleString()
                          : data.h_Y_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.h_N
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.h_N)).toString().toLocaleString()
                            : data.h_N.toLocaleString()
                          : 0
                        : data.h_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.h_N_CNT)).toString().toLocaleString()
                          : data.h_N_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.l_T
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.l_T)).toString().toLocaleString()
                            : data.l_T.toLocaleString()
                          : 0
                        : data.l_T_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_T_CNT)).toString().toLocaleString()
                          : data.l_T_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.l_Y
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.l_Y)).toString().toLocaleString()
                            : data.l_Y.toLocaleString()
                          : 0
                        : data.l_Y_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_Y_CNT)).toString().toLocaleString()
                          : data.l_Y_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.l_N
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.l_N)).toString().toLocaleString()
                            : data.l_N.toLocaleString()
                          : 0
                        : data.l_N_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.l_N_CNT)).toString().toLocaleString()
                          : data.l_N_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Doc_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-full">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Doc_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2_${ii}`}
                          className="float-left flex w-[calc(100%-10.0rem)]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.h_T
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.h_T)).toString().toLocaleString()
                                  : dd.h_T.toLocaleString()
                                : 0
                              : dd.h_T_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.h_T_CNT)).toString().toLocaleString()
                                : dd.h_T_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.h_Y
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.h_Y)).toString().toLocaleString()
                                  : dd.h_Y.toLocaleString()
                                : 0
                              : dd.h_Y_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.h_Y_CNT)).toString().toLocaleString()
                                : dd.h_Y_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.h_N
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.h_N)).toString().toLocaleString()
                                  : dd.h_N.toLocaleString()
                                : 0
                              : dd.h_N_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.h_N_CNT)).toString().toLocaleString()
                                : dd.h_N_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.l_T
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.l_T)).toString().toLocaleString()
                                  : dd.l_T.toLocaleString()
                                : 0
                              : dd.l_T_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.l_T_CNT)).toString().toLocaleString()
                                : dd.l_T_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.l_Y
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.l_Y)).toString().toLocaleString()
                                  : dd.l_Y.toLocaleString()
                                : 0
                              : dd.l_Y_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.l_Y_CNT)).toString().toLocaleString()
                                : dd.l_Y_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.l_N
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.l_N)).toString().toLocaleString()
                                  : dd.l_N.toLocaleString()
                                : 0
                              : dd.l_N_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.l_N_CNT)).toString().toLocaleString()
                                : dd.l_N_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      )}
      {name === "octy" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-65.6rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              의사직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              간호직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              보건직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              약무직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              사무직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              기술직
            </div>
            <div className="flex h-[3.0rem] w-[8.2rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              기타
            </div>
          </div>
          {Tdata && Tdata !== null && (
            <div
              key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_0`}
              className={`float-left flex w-[calc(100%-93.1rem)] items-center justify-center border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem]`}
              style={{
                height: (Tdata.length * 3).toString() + "rem",
              }}
            >
              위상↑
            </div>
          )}
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_1`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.doc_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_CNT)).toString().toLocaleString()
                          : data.doc_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.nr_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_CNT)).toString().toLocaleString()
                          : data.nr_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.hlth_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_CNT)).toString().toLocaleString()
                          : data.hlth_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.pham_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_CNT)).toString().toLocaleString()
                          : data.pham_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.office_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_CNT)).toString().toLocaleString()
                          : data.office_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tech_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_CNT)).toString().toLocaleString()
                          : data.tech_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.etc_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_CNT)).toString().toLocaleString()
                          : data.etc_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.doc
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc)).toString().toLocaleString()
                            : data.doc.toLocaleString()
                          : 0
                        : data.doc_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_CNT)).toString().toLocaleString()
                          : data.doc_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.nr
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr)).toString().toLocaleString()
                            : data.nr.toLocaleString()
                          : 0
                        : data.nr_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_CNT)).toString().toLocaleString()
                          : data.nr_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.hlth
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth)).toString().toLocaleString()
                            : data.hlth.toLocaleString()
                          : 0
                        : data.hlth_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_CNT)).toString().toLocaleString()
                          : data.hlth_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.pham
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham)).toString().toLocaleString()
                            : data.pham.toLocaleString()
                          : 0
                        : data.pham_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_CNT)).toString().toLocaleString()
                          : data.pham_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.office
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office)).toString().toLocaleString()
                            : data.office.toLocaleString()
                          : 0
                        : data.office_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_CNT)).toString().toLocaleString()
                          : data.office_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tech
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech)).toString().toLocaleString()
                            : data.tech.toLocaleString()
                          : 0
                        : data.tech_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_CNT)).toString().toLocaleString()
                          : data.tech_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.etc
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc)).toString().toLocaleString()
                            : data.etc.toLocaleString()
                          : 0
                        : data.etc_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_CNT)).toString().toLocaleString()
                          : data.etc_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-[93.1rem]">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2_${ii}`}
                          className="float-left flex w-[83.1rem]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[17.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot)).toString().toLocaleString()
                                  : dd.tot.toLocaleString()
                                : 0
                              : dd.tot_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_CNT)).toString().toLocaleString()
                                : dd.tot_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.doc
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.doc)).toString().toLocaleString()
                                  : dd.doc.toLocaleString()
                                : 0
                              : dd.doc_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.doc_CNT)).toString().toLocaleString()
                                : dd.doc_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.nr
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.nr)).toString().toLocaleString()
                                  : dd.nr.toLocaleString()
                                : 0
                              : dd.nr_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.nr_CNT)).toString().toLocaleString()
                                : dd.nr_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.hlth
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.hlth)).toString().toLocaleString()
                                  : dd.hlth.toLocaleString()
                                : 0
                              : dd.hlth_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.hlth_CNT)).toString().toLocaleString()
                                : dd.hlth_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.pham
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.pham)).toString().toLocaleString()
                                  : dd.pham.toLocaleString()
                                : 0
                              : dd.pham_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.pham_CNT)).toString().toLocaleString()
                                : dd.pham_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.office
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.office)).toString().toLocaleString()
                                  : dd.office.toLocaleString()
                                : 0
                              : dd.office_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.office_CNT)).toString().toLocaleString()
                                : dd.office_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tech
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tech)).toString().toLocaleString()
                                  : dd.tech.toLocaleString()
                                : 0
                              : dd.tech_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tech_CNT)).toString().toLocaleString()
                                : dd.tech_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.etc
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.etc)).toString().toLocaleString()
                                  : dd.etc.toLocaleString()
                                : 0
                              : dd.etc_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.etc_CNT)).toString().toLocaleString()
                                : dd.etc_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          {Tdata && Tdata !== null && (
            <div
              key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_4`}
              className={`float-left flex w-[calc(100%-93.1rem)] items-center justify-center border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem]`}
              style={{
                height: (Tdata.length * 3).toString() + "rem",
              }}
            >
              위상↓
            </div>
          )}
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_5`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.doc_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_2_CNT)).toString().toLocaleString()
                          : data.doc_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.nr_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_2_CNT)).toString().toLocaleString()
                          : data.nr_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.hlth_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_2_CNT)).toString().toLocaleString()
                          : data.hlth_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.pham_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_2_CNT)).toString().toLocaleString()
                          : data.pham_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.office_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_2_CNT)).toString().toLocaleString()
                          : data.office_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tech_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_2_CNT)).toString().toLocaleString()
                          : data.tech_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.etc_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_2_CNT)).toString().toLocaleString()
                          : data.etc_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_6`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.doc_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.doc_2)).toString().toLocaleString()
                            : data.doc_2.toLocaleString()
                          : 0
                        : data.doc_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.doc_2_CNT)).toString().toLocaleString()
                          : data.doc_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.nr_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.nr_2)).toString().toLocaleString()
                            : data.nr_2.toLocaleString()
                          : 0
                        : data.nr_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.nr_2_CNT)).toString().toLocaleString()
                          : data.nr_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.hlth_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.hlth_2)).toString().toLocaleString()
                            : data.hlth_2.toLocaleString()
                          : 0
                        : data.hlth_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.hlth_2_CNT)).toString().toLocaleString()
                          : data.hlth_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.pham_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.pham_2)).toString().toLocaleString()
                            : data.pham_2.toLocaleString()
                          : 0
                        : data.pham_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.pham_2_CNT)).toString().toLocaleString()
                          : data.pham_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.office_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.office_2)).toString().toLocaleString()
                            : data.office_2.toLocaleString()
                          : 0
                        : data.office_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.office_2_CNT)).toString().toLocaleString()
                          : data.office_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tech_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tech_2)).toString().toLocaleString()
                            : data.tech_2.toLocaleString()
                          : 0
                        : data.tech_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tech_2_CNT)).toString().toLocaleString()
                          : data.tech_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.etc_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.etc_2)).toString().toLocaleString()
                            : data.etc_2.toLocaleString()
                          : 0
                        : data.etc_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.etc_2_CNT)).toString().toLocaleString()
                          : data.etc_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_7_1`} className="float-left w-[93.1rem]">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Octy_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_7_2_${ii}`}
                          className="float-left flex w-[83.1rem]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[17.5rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot_2)).toString().toLocaleString()
                                  : dd.tot_2.toLocaleString()
                                : 0
                              : dd.tot_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_2_CNT)).toString().toLocaleString()
                                : dd.tot_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.doc_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.doc_2)).toString().toLocaleString()
                                  : dd.doc_2.toLocaleString()
                                : 0
                              : dd.doc_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.doc_2_CNT)).toString().toLocaleString()
                                : dd.doc_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.nr_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.nr_2)).toString().toLocaleString()
                                  : dd.nr_2.toLocaleString()
                                : 0
                              : dd.nr_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.nr_2_CNT)).toString().toLocaleString()
                                : dd.nr_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.hlth_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.hlth_2)).toString().toLocaleString()
                                  : dd.hlth_2.toLocaleString()
                                : 0
                              : dd.hlth_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.hlth_2_CNT)).toString().toLocaleString()
                                : dd.hlth_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.pham_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.pham_2)).toString().toLocaleString()
                                  : dd.pham_2.toLocaleString()
                                : 0
                              : dd.pham_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.pham_2_CNT)).toString().toLocaleString()
                                : dd.pham_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.office_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.office_2)).toString().toLocaleString()
                                  : dd.office_2.toLocaleString()
                                : 0
                              : dd.office_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.office_2_CNT)).toString().toLocaleString()
                                : dd.office_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tech_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tech_2)).toString().toLocaleString()
                                  : dd.tech_2.toLocaleString()
                                : 0
                              : dd.tech_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tech_2_CNT)).toString().toLocaleString()
                                : dd.tech_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[8.2rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.etc_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.etc_2)).toString().toLocaleString()
                                  : dd.etc_2.toLocaleString()
                                : 0
                              : dd.etc_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.etc_2_CNT)).toString().toLocaleString()
                                : dd.etc_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      )}
      {name === "sex" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="float-left flex h-[6.0rem] w-[calc(100%-65.64rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="float-left flex h-[6.0rem] w-[65.64rem] flex-col">
              <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
                <div className="float-left flex h-full w-[32.82rem] items-center justify-center border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  위상 상승 이유
                </div>
                <div className="float-left flex h-full w-[32.82rem] items-center justify-center border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  위상 하락 이유
                </div>
              </div>
              <div className="float-left flex h-[3.0rem] w-full text-[1.4rem] ">
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  전체
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  남성
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  여성
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  전체
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  남성
                </div>
                <div className="float-left flex h-full w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
                  여성
                </div>
              </div>
            </div>
          </div>
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`Sex_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_1`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.s_M_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_M_CNT)).toString().toLocaleString()
                          : data.s_M_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.s_F_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_F_CNT)).toString().toLocaleString()
                          : data.s_F_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.s_M_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_M_2_CNT)).toString().toLocaleString()
                          : data.s_M_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.s_F_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_F_2_CNT)).toString().toLocaleString()
                          : data.s_F_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`Sex_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex w-full">
                    <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.s_M
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M)).toString().toLocaleString()
                            : data.s_M.toLocaleString()
                          : 0
                        : data.s_M_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_M_CNT)).toString().toLocaleString()
                          : data.s_M_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.s_F
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F)).toString().toLocaleString()
                            : data.s_F.toLocaleString()
                          : 0
                        : data.s_F_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_F_CNT)).toString().toLocaleString()
                          : data.s_F_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.s_M_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_M_2)).toString().toLocaleString()
                            : data.s_M_2.toLocaleString()
                          : 0
                        : data.s_M_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_M_2_CNT)).toString().toLocaleString()
                          : data.s_M_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.s_F_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.s_F_2)).toString().toLocaleString()
                            : data.s_F_2.toLocaleString()
                          : 0
                        : data.s_F_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.s_F_2_CNT)).toString().toLocaleString()
                          : data.s_F_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`Sex_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-full">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`Sex_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2_${ii}`}
                          className="float-left flex w-[calc(100%-10.0rem)]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot)).toString().toLocaleString()
                                  : dd.tot.toLocaleString()
                                : 0
                              : dd.tot_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_CNT)).toString().toLocaleString()
                                : dd.tot_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.s_M
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.s_M)).toString().toLocaleString()
                                  : dd.s_M.toLocaleString()
                                : 0
                              : dd.s_M_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.s_M_CNT)).toString().toLocaleString()
                                : dd.s_M_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.s_F
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.s_F)).toString().toLocaleString()
                                  : dd.s_F.toLocaleString()
                                : 0
                              : dd.s_F_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.s_F_CNT)).toString().toLocaleString()
                                : dd.s_F_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot_2)).toString().toLocaleString()
                                  : dd.tot_2.toLocaleString()
                                : 0
                              : dd.tot_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_2_CNT)).toString().toLocaleString()
                                : dd.tot_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.s_M_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.s_M_2)).toString().toLocaleString()
                                  : dd.s_M_2.toLocaleString()
                                : 0
                              : dd.s_M_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.s_M_2_CNT)).toString().toLocaleString()
                                : dd.s_M_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.s_F_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.s_F_2)).toString().toLocaleString()
                                  : dd.s_F_2.toLocaleString()
                                : 0
                              : dd.s_F_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.s_F_2_CNT)).toString().toLocaleString()
                                : dd.s_F_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      )}
      {name === "wrk" && (
        <div className="float-left w-full">
          <div className="float-left h-[0.1rem] w-full bg-orangeColor"></div>
          <div className="float-left flex w-full border-b-[0.1rem] border-solid border-tableBorderColor bg-tableHeaderColor ">
            <div className="flex h-[3.0rem] w-[calc(100%-65.64rem)] items-center justify-center border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              구분
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              전체
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              3년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              7년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              10년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              15년 미만
            </div>
            <div className="flex h-[3.0rem] w-[10.94rem] items-center justify-center border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem] ">
              15년 이상
            </div>
          </div>
          {Tdata && Tdata !== null && (
            <div
              key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_0`}
              className={`float-left flex w-[calc(100%-93.1rem)] items-center justify-center border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem]`}
              style={{
                height: (Tdata.length * 3).toString() + "rem",
              }}
            >
              위상↑
            </div>
          )}
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_1`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.46rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.u3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_CNT)).toString().toLocaleString()
                          : data.u3_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.b3_7_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_CNT)).toString().toLocaleString()
                          : data.b3_7_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.b7_10_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_CNT)).toString().toLocaleString()
                          : data.b7_10_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.b10_15_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_CNT)).toString().toLocaleString()
                          : data.b10_15_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.o15_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_CNT)).toString().toLocaleString()
                          : data.o15_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_2`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.46rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot)).toString().toLocaleString()
                            : data.tot.toLocaleString()
                          : 0
                        : data.tot_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_CNT)).toString().toLocaleString()
                          : data.tot_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.u3
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3)).toString().toLocaleString()
                            : data.u3.toLocaleString()
                          : 0
                        : data.u3_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_CNT)).toString().toLocaleString()
                          : data.u3_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.b3_7
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7)).toString().toLocaleString()
                            : data.b3_7.toLocaleString()
                          : 0
                        : data.b3_7_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_CNT)).toString().toLocaleString()
                          : data.b3_7_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.b7_10
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10)).toString().toLocaleString()
                            : data.b7_10.toLocaleString()
                          : 0
                        : data.b7_10_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_CNT)).toString().toLocaleString()
                          : data.b7_10_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.b10_15
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15)).toString().toLocaleString()
                            : data.b10_15.toLocaleString()
                          : 0
                        : data.b10_15_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_CNT)).toString().toLocaleString()
                          : data.b10_15_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.o15
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15)).toString().toLocaleString()
                            : data.o15.toLocaleString()
                          : 0
                        : data.o15_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_CNT)).toString().toLocaleString()
                          : data.o15_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_1`} className="float-left w-[93.1rem]">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_3_2_${ii}`}
                          className="float-left flex w-[83.1rem]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[17.46rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot)).toString().toLocaleString()
                                  : dd.tot.toLocaleString()
                                : 0
                              : dd.tot_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_CNT)).toString().toLocaleString()
                                : dd.tot_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.u3
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.u3)).toString().toLocaleString()
                                  : dd.u3.toLocaleString()
                                : 0
                              : dd.u3_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.u3_CNT)).toString().toLocaleString()
                                : dd.u3_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.b3_7
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.b3_7)).toString().toLocaleString()
                                  : dd.b3_7.toLocaleString()
                                : 0
                              : dd.b3_7_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.b3_7_CNT)).toString().toLocaleString()
                                : dd.b3_7_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.b7_10
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.b7_10)).toString().toLocaleString()
                                  : dd.b7_10.toLocaleString()
                                : 0
                              : dd.b7_10_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.b7_10_CNT)).toString().toLocaleString()
                                : dd.b7_10_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.b10_15
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.b10_15)).toString().toLocaleString()
                                  : dd.b10_15.toLocaleString()
                                : 0
                              : dd.b10_15_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.b10_15_CNT)).toString().toLocaleString()
                                : dd.b10_15_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.o15
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.o15)).toString().toLocaleString()
                                  : dd.o15.toLocaleString()
                                : 0
                              : dd.o15_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.o15_CNT)).toString().toLocaleString()
                                : dd.o15_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          {Tdata && Tdata !== null && (
            <div
              key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_4`}
              className={`float-left flex w-[calc(100%-93.1rem)] items-center justify-center border-b-[0.1rem] border-l-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor text-[1.4rem]`}
              style={{
                height: (Tdata.length * 3).toString() + "rem",
              }}
            >
              위상↓
            </div>
          )}
          {Tdata &&
            Tdata !== null &&
            Tdata.map((data, i) => {
              if (data.grp_NM === "사례수") {
                return (
                  <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_5`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.46rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.u3_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_2_CNT)).toString().toLocaleString()
                          : data.u3_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.b3_7_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_2_CNT)).toString().toLocaleString()
                          : data.b3_7_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.b7_10_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_2_CNT)).toString().toLocaleString()
                          : data.b7_10_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.b10_15_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_2_CNT)).toString().toLocaleString()
                          : data.b10_15_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data.o15_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_2_CNT)).toString().toLocaleString()
                          : data.o15_2_CNT.toLocaleString()
                        : 0) + "명"}
                    </div>
                  </div>
                );
              }

              if (data.grp_NM === "기타") {
                return (
                  <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_6`} className="float-left flex">
                    <div className="float-left flex h-[3.0rem] w-[27.46rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                      {data.grp_NM}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.tot_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.tot_2)).toString().toLocaleString()
                            : data.tot_2.toLocaleString()
                          : 0
                        : data.tot_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.tot_2_CNT)).toString().toLocaleString()
                          : data.tot_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.u3_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.u3_2)).toString().toLocaleString()
                            : data.u3_2.toLocaleString()
                          : 0
                        : data.u3_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.u3_2_CNT)).toString().toLocaleString()
                          : data.u3_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.b3_7_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b3_7_2)).toString().toLocaleString()
                            : data.b3_7_2.toLocaleString()
                          : 0
                        : data.b3_7_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b3_7_2_CNT)).toString().toLocaleString()
                          : data.b3_7_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.b7_10_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b7_10_2)).toString().toLocaleString()
                            : data.b7_10_2.toLocaleString()
                          : 0
                        : data.b7_10_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b7_10_2_CNT)).toString().toLocaleString()
                          : data.b7_10_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.b10_15_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.b10_15_2)).toString().toLocaleString()
                            : data.b10_15_2.toLocaleString()
                          : 0
                        : data.b10_15_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.b10_15_2_CNT)).toString().toLocaleString()
                          : data.b10_15_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                    <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                      {(data_num === "1"
                        ? data.o15_2
                          ? data.sel_NM === "사례수"
                            ? Math.floor(parseFloat(data.o15_2)).toString().toLocaleString()
                            : data.o15_2.toLocaleString()
                          : 0
                        : data.o15_2_CNT
                        ? data.sel_NM === "사례수"
                          ? Math.floor(parseFloat(data.o15_2_CNT)).toString().toLocaleString()
                          : data.o15_2_CNT.toLocaleString()
                        : 0) + (data.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                    </div>
                  </div>
                );
              }

              if (!(data.grp_NM === "사례수" || data.grp_NM === "기타") && data.grp_NM !== Tdata[i - 1].grp_NM) {
                return (
                  <div key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_7_1`} className="float-left w-[93.1rem]">
                    <div
                      className={`float-left flex w-[10.0rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]`}
                      style={{
                        height: (Tdata.filter((x) => x.grp_NM === data.grp_NM).length * 3.0).toString() + ".0rem",
                      }}
                    >
                      {data.grp_NM}
                    </div>
                    {Tdata.filter((x) => x.grp_NM === data.grp_NM).map((dd, ii) => {
                      return (
                        <div
                          key={`WrkYyGrp_${prj_cd}_${svy_ojt}_${qst_cd}_${name}_${data_num}_${data_type}_${i}_7_2_${ii}`}
                          className="float-left flex w-[83.1rem]"
                        >
                          <div className="float-left flex h-[3.0rem] w-[17.46rem] items-center justify-start overflow-hidden text-ellipsis whitespace-nowrap border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pl-[0.5rem] text-[1.4rem]">
                            {dd.sel_NM}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.tot_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.tot_2)).toString().toLocaleString()
                                  : dd.tot_2.toLocaleString()
                                : 0
                              : dd.tot_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.tot_2_CNT)).toString().toLocaleString()
                                : dd.tot_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.u3_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.u3_2)).toString().toLocaleString()
                                  : dd.u3_2.toLocaleString()
                                : 0
                              : dd.u3_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.u3_2_CNT)).toString().toLocaleString()
                                : dd.u3_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.b3_7_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.b3_7_2)).toString().toLocaleString()
                                  : dd.b3_7_2.toLocaleString()
                                : 0
                              : dd.b3_7_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.b3_7_2_CNT)).toString().toLocaleString()
                                : dd.b3_7_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.b7_10_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.b7_10_2)).toString().toLocaleString()
                                  : dd.b7_10_2.toLocaleString()
                                : 0
                              : dd.b7_10_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.b7_10_2_CNT)).toString().toLocaleString()
                                : dd.b7_10_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.b10_15_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.b10_15_2)).toString().toLocaleString()
                                  : dd.b10_15_2.toLocaleString()
                                : 0
                              : dd.b10_15_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.b10_15_2_CNT)).toString().toLocaleString()
                                : dd.b10_15_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                          <div className="float-left flex h-[3.0rem] w-[10.94rem] items-center justify-end border-b-[0.1rem] border-r-[0.1rem] border-solid border-tableBorderColor pr-[0.5rem] text-[1.4rem]">
                            {(data_num === "1"
                              ? dd.o15_2
                                ? dd.sel_NM === "사례수"
                                  ? Math.floor(parseFloat(dd.o15_2)).toString().toLocaleString()
                                  : dd.o15_2.toLocaleString()
                                : 0
                              : dd.o15_2_CNT
                              ? dd.sel_NM === "사례수"
                                ? Math.floor(parseFloat(dd.o15_2_CNT)).toString().toLocaleString()
                                : dd.o15_2_CNT.toLocaleString()
                              : 0) + (dd.sel_NM === "사례수" ? "명" : data_num === "1" ? "%" : "명")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
        </div>
      )}
    </div>
  );
};

export default DA01Table;
