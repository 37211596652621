// import { ageDict, workDict } from "../../../dict/mobile/dict";
import { useComnDict, useMenuState, useTableDataState } from "../../../store/mobile/store";

const Da09Table = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  const tableData = useTableDataState((state) => state.tableData);
  const menuState = useMenuState((state) => state.menuVal);

  let tableDb = [];
  let grpNmList = [];

  tableData.forEach((data, idx) => {
    let temp = [];
    // 직종별 데이터
    workDict.forEach((work, idx2) => {
      if (idx2 === 0) {
        if (data.grp_NM === "전체") {
          temp.push(data.sel_NM);
        } else {
          temp.push(data.grp_NM);
          grpNmList.push(data.grp_NM);
          temp.push(data.sel_NM);
        }
      }
      temp.push(data[work.sel_cd]);
    });

    tableDb.push(temp);
    // 연령별 데이터
  });

  // 중복제거한 grpNm
  let grpNmSet = new Set(grpNmList);
  let uniqueGrpNm = [...grpNmSet];

  let divisionData = [];
  // grpNm 별 데이터 그룹화
  uniqueGrpNm.forEach((el, idx) => {
    let temp = [];
    tableDb.forEach((data, idx2) => {
      if (data[0] === el) {
        temp.push(data.slice(1, data.length));
      }
    });
    divisionData.push(temp);
  });

  // 사례수, 전체 데이터
  let comnDb = tableDb.slice(0, 2);

  return (
    <div className="flex h-fit w-full flex-row items-start justify-start overflow-auto border-l border-qstTitleBorder">
      {/* 구분 */}
      <div className="sticky left-0 flex h-fit w-fit  flex-col items-center justify-start">
        <div className="flex h-[60px] w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-8 font-SDGothic  text-[14px] font-bold text-selectTextColor">
          {/* {menuState === "MEM" ? `직종` : `연령대`} */}
          구분
        </div>
        {menuState === "MEM"
          ? workDict.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    idx % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {el.sel_nm}
                </div>
              );
            })
          : menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS"
          ? ageDict.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    idx % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {el.sel_nm}
                </div>
              );
            })
          : null}
      </div>
      {/* 사례수, 전체 */}
      {comnDb?.map((data, idx) => {
        return (
          <div key={idx} className=" flex h-fit w-fit  flex-col items-center justify-start">
            <div className="flex h-[60px] w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-8 font-SDGothic  text-[14px] font-bold text-selectTextColor">
              {/* {menuState === "MEM" ? `직종` : `연령대`} */}
              {data[0]}
            </div>
            {data.slice(1, data.length).map((el, idx2) => {
              return (
                <div
                  key={idx2}
                  className={
                    idx2 % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {idx === 0
                    ? `${el?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                    : `${el?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}
                </div>
              );
            })}
          </div>
        );
      })}
      {/* 그룹데이터 */}
      {uniqueGrpNm.map((el, idx) => {
        return (
          <div key={idx} className="flex h-fit w-fit flex-col items-center justify-start">
            <div className="flex h-[30px] w-full   flex-col items-center justify-center whitespace-pre border border-b-0 border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-8 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
              {el}
            </div>
            <div className="flex h-fit w-fit flex-row items-center justify-start">
              {divisionData[idx].map((data, idx2) => {
                return (
                  <div key={idx2} className=" flex h-fit w-fit flex-col items-center justify-start">
                    {data.map((el2, idx3) => {
                      if (idx3 === 0) {
                        return (
                          <div
                            key={idx3}
                            className="flex h-[30px] w-fit  min-w-full  flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-qstTitleBorder bg-tableHeaderColor px-8 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor"
                          >
                            {el2}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={idx3}
                            className={
                              idx3 % 2 === 0
                                ? `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4  font-SDGothic text-[14px] text-selectTextColor`
                                : `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4  font-SDGothic text-[14px] text-selectTextColor`
                            }
                          >
                            {`${el2?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`}
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Da09Table;

// `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
