import { useMutation, useQueryClient } from "@tanstack/react-query";
import deleteSel from "../api/deleteSel";
const useDeleteSel = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSel, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminQstSelRes"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useDeleteSel;
