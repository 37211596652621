// ANCHOR 체크갯수가 0개인경우 해당없음체크로직
// export const makeTableData = (qst, sel_nm, excpData) => {
//   const resultData = qst.map((qstItem) => {
//     const listData = sel_nm.map((vst_sel_nm_item) => {
//       const targetQstCD = qstItem.qst_CD;
//       const targetSelNMComnCD = vst_sel_nm_item.comn_CD;
//       const existsInExcpData = excpData.filter((excpItem) => {
//         return excpItem.qst_CD === targetQstCD && excpItem.main_DEPT_CD === targetSelNMComnCD;
//       });
//       if (existsInExcpData.length > 0) {
//         return { ...vst_sel_nm_item, checked: false };
//       } else {
//         return { ...vst_sel_nm_item, checked: true };
//       }
//     });

//     const listChecked = listData.filter((item) => !item.checked).length === 0 ? true : false;

//     return { ...qstItem, listChecked: listChecked, listData };
//   });

//   //   console.log("resultData", resultData);
//   return resultData;
// };

// ANCHOR 체크갯수가 가득찼을 경우 해당없음 체크로직
export const makeDeptTableData = (qst, sel_nm, excpData, AnalysisType, adminProjectList) => {
  // csscan일때
  if (AnalysisType === "csscan") {
    // console.log("qst", qst);
    // console.log("sel_nm", sel_nm);
    // console.log("excpData", excpData);

    sel_nm = sel_nm
      .map((item) => [
        {
          ...item,
          svy_OJT: "CS",
        },
        {
          ...item,
          svy_OJT: "RS",
        },
      ])
      .flat();

    sel_nm = sel_nm.filter((item) => adminProjectList.includes(item.svy_OJT));

    console.log("sel_nm후", sel_nm);

    const SelfullLength = sel_nm.length;
    const resultData = qst.map((qstItem) => {
      const listData = sel_nm.map((vst_sel_nm_item) => {
        const targetQstCD = qstItem.qst_CD;
        const targetSelNMComnCD = vst_sel_nm_item.comn_CD;
        const targetSvyOJT = vst_sel_nm_item.svy_OJT;
        const existsInExcpData = excpData.filter((excpItem) => {
          return excpItem.qst_CD === targetQstCD && excpItem.main_DEPT_CD === targetSelNMComnCD && excpItem.svy_OJT === targetSvyOJT;
        });
        if (existsInExcpData.length > 0) {
          return { ...vst_sel_nm_item, checked: false };
        } else {
          return { ...vst_sel_nm_item, checked: true };
        }
      });

      const listChecked = listData.filter((item) => !item.checked).length === SelfullLength ? true : false;

      return { ...qstItem, listChecked: listChecked, listData };
    });
    return resultData;
  }
  // scan일때
  else {
    // console.log("qst", qst);
    // console.log("sel_nm", sel_nm);
    // console.log("excpData", excpData);

    const SelfullLength = sel_nm.length;
    const resultData = qst.map((qstItem) => {
      const listData = sel_nm.map((vst_sel_nm_item) => {
        const targetQstCD = qstItem.qst_CD;
        const targetSelNMComnCD = vst_sel_nm_item.comn_CD;
        const existsInExcpData = excpData.filter((excpItem) => {
          return excpItem.qst_CD === targetQstCD && excpItem.main_DEPT_CD === targetSelNMComnCD;
        });
        if (existsInExcpData.length > 0) {
          return { ...vst_sel_nm_item, checked: false };
        } else {
          return { ...vst_sel_nm_item, checked: true };
        }
      });

      const listChecked = listData.filter((item) => !item.checked).length === SelfullLength ? true : false;

      return { ...qstItem, listChecked: listChecked, listData };
    });
    return resultData;
  }

  //   console.log("resultData", resultData);
};

export const makeTableData = (qst, sel_nm, excpData, AnalysisType, adminProjectList) => {
  // console.log("qst", qst);
  // console.log("sel_nm", sel_nm);
  // console.log("excpData", excpData);

  // csscan일때
  if (AnalysisType === "csscan") {
    //cs,rs만들어줌
    sel_nm = sel_nm
      .map((item) => {
        if (item.dvs_NM === "내원고객(입원외래)") {
          return [
            {
              ...item,
              svy_OJT: "CS",
            },
            {
              ...item,
              svy_OJT: "RS",
            },
          ];
        } else {
          return { ...item };
        }
      })
      .flat();

    if (adminProjectList.includes("MEM")) {
      const sel_nm_mem = sel_nm.filter((item) => item.dvs_CD === "MEM");
      const sel_nm_csscan = sel_nm.filter((item) => adminProjectList.includes(item.svy_OJT));
      sel_nm = sel_nm_csscan.concat(sel_nm_mem);
    } else {
      sel_nm = sel_nm.filter((item) => adminProjectList.includes(item.svy_OJT));
    }

    // console.log("sen_nm후", sel_nm);
    const resultData = qst.map((qstItem) => {
      const listData = sel_nm.map((vst_sel_nm_item) => {
        const targetQstCD = qstItem.qst_CD;
        let existsInExcpData = [];

        //octy는있고 octy_dtl이 없는경우
        if (vst_sel_nm_item.dvs_NM === "구성원" && vst_sel_nm_item.dtl_COMN_CD === null) {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.octy === vst_sel_nm_item.comn_CD;
          });
        }
        //octy, octy_dtl있는경우
        else if (vst_sel_nm_item.dvs_NM === "구성원" && vst_sel_nm_item.dtl_COMN_CD !== null) {
          // console.log("octy, octy_dtl있는경우", vst_sel_nm_item.dtl_COMN_CD);
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.octy_DTL === vst_sel_nm_item.dtl_COMN_CD;
          });
        } else if (vst_sel_nm_item.dvs_NM === "내원고객(입원외래)" && vst_sel_nm_item.comn_CD === "I" && vst_sel_nm_item.svy_OJT === "CS") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.io_DVS === "I" && excpItem.svy_OJT === "CS";
          });
        } else if (vst_sel_nm_item.dvs_NM === "내원고객(입원외래)" && vst_sel_nm_item.comn_CD === "I" && vst_sel_nm_item.svy_OJT === "RS") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.io_DVS === "I" && excpItem.svy_OJT === "RS";
          });
        } else if (vst_sel_nm_item.dvs_NM === "내원고객(입원외래)" && vst_sel_nm_item.comn_CD === "O" && vst_sel_nm_item.svy_OJT === "CS") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.io_DVS === "O" && excpItem.svy_OJT === "CS";
          });
        } else if (vst_sel_nm_item.dvs_NM === "내원고객(입원외래)" && vst_sel_nm_item.comn_CD === "O" && vst_sel_nm_item.svy_OJT === "RS") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.io_DVS === "O" && excpItem.svy_OJT === "RS";
          });
        } else if (vst_sel_nm_item.dvs_NM === "지역주민") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.svy_OJT === "LRES";
          });
        }

        if (existsInExcpData.length > 0) {
          return { ...vst_sel_nm_item, checked: false };
        } else {
          return { ...vst_sel_nm_item, checked: true };
        }
      });

      const listChecked = listData.filter((item) => !item.checked).length === 0 ? true : false;

      return { ...qstItem, listChecked: listChecked, listData };
    });

    //   console.log("resultData", resultData);
    return resultData;
  }
  // scan일때
  else {
    const resultData = qst.map((qstItem) => {
      const listData = sel_nm.map((vst_sel_nm_item) => {
        const targetQstCD = qstItem.qst_CD;
        let existsInExcpData = [];

        //octy는있고 octy_dtl이 없는경우
        if (vst_sel_nm_item.dvs_NM === "구성원" && vst_sel_nm_item.dtl_COMN_CD === null) {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.octy === vst_sel_nm_item.comn_CD;
          });
        }
        //octy, octy_dtl있는경우
        else if (vst_sel_nm_item.dvs_NM === "구성원" && vst_sel_nm_item.dtl_COMN_CD !== null) {
          // console.log("octy, octy_dtl있는경우", vst_sel_nm_item.dtl_COMN_CD);
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.octy_DTL === vst_sel_nm_item.dtl_COMN_CD;
          });
        } else if (vst_sel_nm_item.dvs_NM === "내원고객(입원외래)" && vst_sel_nm_item.comn_CD === "I") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.io_DVS === "I";
          });
        } else if (vst_sel_nm_item.dvs_NM === "내원고객(입원외래)" && vst_sel_nm_item.comn_CD === "O") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.io_DVS === "O";
          });
        } else if (vst_sel_nm_item.dvs_NM === "지역주민") {
          existsInExcpData = excpData.filter((excpItem) => {
            return excpItem.qst_CD === targetQstCD && excpItem.svy_OJT === "LRES";
          });
        }

        if (existsInExcpData.length > 0) {
          return { ...vst_sel_nm_item, checked: false };
        } else {
          return { ...vst_sel_nm_item, checked: true };
        }
      });

      const listChecked = listData.filter((item) => !item.checked).length === 0 ? true : false;

      return { ...qstItem, listChecked: listChecked, listData };
    });

    //   console.log("resultData", resultData);
    return resultData;
  }
};
