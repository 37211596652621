import { useQuery } from "@tanstack/react-query";
import getAdminUserUser from "../api/getAdminUserUser";

const useGetAdminUserUser = (prj_cd) => {
  return useQuery(["adminUserUser", prj_cd], () => getAdminUserUser(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserUser;
