import axios from "axios";
//NOTE - Q50의 Q13타입은 그룹문항의 보기가 필요함
export const getDa12UprGrpSel = async (prj_cd, svy_ojt, qst_cd) => {
  let fetchData;

  await axios
    .get(
      process.env.REACT_APP_SERVER_ADDRESS + "/DA12/GrpSel",
      {
        params: {
          prj_cd: prj_cd,
          svy_ojt: svy_ojt,
          qst_cd: qst_cd,
        },
      },
      { withCredentials: true }
    )
    .catch(function (error) {
      if (error.response) {
      } else if (error.request) {
      } else {
        // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
        console.log("Error", error.message);
      }
      console.log(error.config);
    })
    .then((res) => (fetchData = res.data));
  return fetchData;
};
