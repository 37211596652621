import { useQuery } from "@tanstack/react-query";
import getDa10Res from "../api/getDa10Res";

const useGetDa10Res = (prj_cd, qst_cd, svy_ojt, stDate, edDate) => {
  return useQuery(["da10"], () => getDa10Res(prj_cd, qst_cd, svy_ojt, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa10Res;
