import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GraphDA06Etc1Api } from "../api/GraphDA06Etc1Api";

export const useGraphDA06Etc1 = (prj_cd, svy_ojt, qst_cd, sel_cd, string_value, where, sort_type, startDate, endDate) => {
  const queryClient = useQueryClient();
  //queryClient.invalidateQueries(["login", user_id]);
  const {
    isLoading: isGraphDA06Etc1Loading,
    isError: isGraphDA06Etc1Error,
    data: GraphDA06Etc1Data,
    isSuccess: isGraphDA06Etc1Success,
    refetch: GraphDA06Etc1Refetch,
    isFetching: isGraphDA06Etc1Fetching,
  } = useQuery(
    ["graph_da06_etc1", prj_cd, svy_ojt, qst_cd, sel_cd, string_value, where, sort_type, startDate, endDate],
    () => GraphDA06Etc1Api(prj_cd, svy_ojt, qst_cd, sel_cd, string_value, where, sort_type, startDate, endDate),
    {
      enabled: false, // 마운트 시 자동으로 쿼리 실행
      skip: true,
      onSuccess: (data) => {
        //console.log(data);
        queryClient.invalidateQueries({
          queryKey: ["graph_da06_etc1"],
          refetchActive: true,
        });
      },
      onError: (e) => {
        // 실패시 호출 (401, 406 같은 error가 아니라 정말 api 호출이 실패한 경우만 호출됩니다.)
        // 강제로 에러 발생시키려면 api단에서 throw Error 날립니다.
        // (참조: https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default)
        //console.log(e.message);
      },
      onSettled: (data, err) => {
        //console.log(data, err);
      },
      refetchOnWindowFocus: false, // react-query는 사용자가 사용하는 윈도우가 다른 곳을 갔다가 다시 화면으로 돌아오면 이 함수를 재실행합니다. 그 재실행 여부 옵션 입니다.
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    isGraphDA06Etc1Loading,
    isGraphDA06Etc1Error,
    GraphDA06Etc1Data,
    isGraphDA06Etc1Success,
    isGraphDA06Etc1Fetching,
    GraphDA06Etc1Refetch,
  };
};
