const AdminEndSentenseComp = ({ mode, endContentValue, setEndContentValue, setEndMode, handleClickEndFetch, serverData }) => {
  const handleChangeContent = (e) => {
    setEndContentValue(e.target.value);

    if (serverData !== null && serverData !== e.target.value && e.target.value !== "") {
      setEndMode(true);
    } else if (serverData === null && e.target.value !== "마침말 본문을 입력하세요" && e.target.value !== "") {
      setEndMode(true);
    } else {
      setEndMode(false);
    }
  };

  return (
    <div className="flex h-[800px] w-[460px] flex-col items-center justify-start rounded-2xl border border-selectTextColor/20 p-8 font-SDGothic">
      <div className="flex h-[700px] w-full flex-col items-center justify-start pt-60">
        <img
          src="http://survey-file-bucket.s3.ap-northeast-2.amazonaws.com/image/icon_complete.png"
          alt="complete_img"
          className="h-[120px] w-auto"
        />
        <div className="mt-20 flex h-fit w-full flex-col items-center justify-center text-[22px] font-bold text-selectTextColor">
          설문이 완료되었습니다.
        </div>
        <textarea
          value={endContentValue}
          onChange={handleChangeContent}
          spellCheck={false}
          className="mt-16 h-[100px] w-full resize-none rounded-xl border border-orangeColor/40 p-3 text-center font-normal leading-snug outline-none"
        />
      </div>

      {/* mockup btn */}
      {mode ? (
        <div
          onClick={handleClickEndFetch}
          className="mb-2 mt-10 flex h-fit w-[85%] cursor-pointer flex-col items-center justify-center rounded-xl bg-orangeColor p-10 text-[20px] text-white hover:bg-orangeColor/80"
        >
          수정하기
        </div>
      ) : (
        <div className="mb-2 mt-10 flex h-fit w-[85%] flex-col items-center justify-center rounded-xl bg-orangeColor/20 p-10 text-[20px] text-white">
          수정하기
        </div>
      )}
    </div>
  );
};

export default AdminEndSentenseComp;
