import { useMutation, useQueryClient } from "@tanstack/react-query";
import deleteAdminUserWork from "../api/deleteAdminUserWork";

const useDeleteAdminUserWork = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAdminUserWork, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserWork"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useDeleteAdminUserWork;
