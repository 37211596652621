import axios from "axios";

const getMobileQstList = (svy_ojt, prj_cd) => {
  return axios.get(`/QstList/menu_list?svy_ojt=${svy_ojt}&prj_cd=${prj_cd}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getMobileQstList;
