import { useQuery } from "@tanstack/react-query";
import getEssayListRes from "../api/getEssayListRes";
import { useEssayListState } from "../store/mobile/store";

const useGetEssayListRes = (prj_cd, qst_cd, svy_ojt, filter_opt, search_keyword, amount, page, stDate, edDate) => {
  const listData = useEssayListState((state) => state.listData);
  const setListData = useEssayListState((state) => state.setListData);

  return useQuery(["essay/list"], () => getEssayListRes(prj_cd, qst_cd, svy_ojt, filter_opt, search_keyword, amount, page, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      // page가 0일때?
      if (page === 0) {
        setListData(res.data);
        // console.log("0 check");
      } else {
        setListData([...listData, ...res.data]);
        // console.log("+ checkt");
      }
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetEssayListRes;
