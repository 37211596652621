import { useMutation, useQueryClient } from "@tanstack/react-query";
import patchDetailQst from "../api/patchDetailQst";

const usePatchDetailQst = () => {
  const queryClient = useQueryClient();

  return useMutation(patchDetailQst, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminQstSelRes"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePatchDetailQst;
