// import { ageDict, workDict } from "../../../dict/mobile/dict";
import { useComnDict, useMenuState, useUnitState } from "../../../store/mobile/store";

const Da10Table = (props) => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);

  const unitState = useUnitState((state) => state.unitState);
  const menuState = useMenuState((state) => state.menuVal);

  let colNm = menuState === "MEM" ? workDict.map((el) => el.sel_nm) : ageDict.map((el) => el.sel_nm);

  return (
    <div className="flex h-fit w-full flex-row items-start justify-start overflow-auto border-l border-qstTitleBorder">
      {/* 구분 */}
      <div className="sticky left-0 flex h-fit w-fit  flex-col items-center justify-start">
        <div className="flex h-fit w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-12 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
          구분
        </div>
        {props?.categories.map((el, idx) => {
          return (
            <div
              key={idx}
              className={
                idx % 2 === 0
                  ? `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  : `flex h-fit w-fit min-w-full flex-col items-start justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
              }
            >
              {el.length > 20 ? `${el.slice(0, 21)}...` : el}
            </div>
          );
        })}
      </div>
      {/* 사례수 */}
      {props?.qstInfo.qst_TYP === "Q07" ? (
        <div className="flex h-full w-fit flex-col items-center justify-start">
          <div className="flex h-fit w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-8 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
            사례수
          </div>
          <div className="flex h-full w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor">
            {props?.caseCnt[0] !== undefined ? `${props?.caseCnt[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명` : "0"}
          </div>
        </div>
      ) : (
        <div className="flex h-fit w-fit flex-col items-center justify-start">
          <div className="flex h-fit w-full flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-8 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
            사례수
          </div>
          {props?.caseCnt.map((el, idx) => {
            return (
              <div
                key={idx}
                className={
                  idx % 2 === 0
                    ? `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                    : `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                }
              >
                {el ? `${el.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명` : `0명`}
              </div>
            );
          })}
        </div>
      )}

      {/* 데이터 */}
      {props?.data.map((el, idx) => {
        return (
          <div key={idx} className=" h-fit w-fit items-center justify-start">
            <div className="flex h-fit w-fit  min-w-full  flex-col items-center justify-center whitespace-pre border border-l-0 border-qstTitleBorder border-t-orangeColor bg-tableHeaderColor px-8 py-4 font-SDGothic  text-[14px] font-bold text-selectTextColor">
              {colNm[idx]}
            </div>
            {el.map((data, idx2) => {
              return (
                <div
                  key={idx2}
                  className={
                    idx2 % 2 === 0
                      ? `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-white px-2 py-4  font-SDGothic text-[14px] text-selectTextColor`
                      : `flex h-fit w-fit min-w-full flex-col items-end justify-center whitespace-pre border border-l-0 border-t-0 border-qstTitleBorder bg-tableBodyGray px-2 py-4 font-SDGothic text-[14px] text-selectTextColor`
                  }
                >
                  {unitState === "rate" && data !== undefined
                    ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`
                    : unitState === "count" && data !== undefined
                    ? `${data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}명`
                    : "0"}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Da10Table;
