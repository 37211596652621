import { useMutation, useQueryClient } from "@tanstack/react-query";
import deleteAdminUserDetail from "../api/deleteAdminUserDetail";

const useDeleteAdminUserDetail = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAdminUserDetail, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserUser"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useDeleteAdminUserDetail;
