const Next_icon = (props) => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.42773 1.28613L9.14202 8.35756L1.42774 15.429"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
        opacity={props.active ? `1` : `0.3`}
      />
    </svg>
  );
};

export default Next_icon;
