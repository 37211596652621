import { useQuery } from "@tanstack/react-query";
import getAdminDetailSelList from "../api/getAdminDetailSelList";

const useGetAdminDetailSelList = (prj_cd, qst_cd, sel_cd) => {
  return useQuery(["adminDetailSel"], () => getAdminDetailSelList(prj_cd, qst_cd, sel_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminDetailSelList;
