// import Filter from "../../components/mobile/analysis/Filter";
import { useEffect } from "react";
import LresComp from "../../components/mobile/analysis/LresComp";
import MemComp from "../../components/mobile/analysis/MemComp";
import PrjPrd from "../../components/mobile/analysis/PrjPrd";
import TableBody from "../../components/mobile/analysis/table/TableBody";
import TableHeader from "../../components/mobile/analysis/table/TableHeader";
import VstComp from "../../components/mobile/analysis/VstComp";
import useGetComnDict from "../../hooks/useGetComnDict";
import useGetStatistic from "../../hooks/useGetStatistic";
import { useComnDict, useMenuList, useMenuState, usePeriodState, usePrjValState, useQstListState, useUserDataState } from "../../store/mobile/store";

const Analysis = () => {
  const setMenuState = useMenuState((state) => state.setMenuVal);
  const userData = useUserDataState((state) => state.userData);
  const menuList = useMenuList((state) => state.menuList);
  const setQstList = useQstListState((state) => state.setQstList);
  const prjVal = usePrjValState((state) => state.prjVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const workDict = useComnDict((state) => state.workDict);
  const workRateDict = useComnDict((state) => state.workRateDict);
  const ageDict = useComnDict((state) => state.ageDict);
  const ageRateDict = useComnDict((state) => state.ageRateDict);

  const { refetch, data } = useGetStatistic(prjVal.value, startDate, endDate);
  const { refetch: comnRefetch } = useGetComnDict();
  // dict data fetch

  const initDataM = data?.data[0];
  const initDataV = data?.data[1];
  const initDataL = data?.data[2];
  const initDataCS = data?.data[3];
  const initDataRS = data?.data[4];

  let cntM = [];
  let cntV = [];
  let cntL = [];
  let cntCS = [];
  let cntRS = [];

  let rateM = [];
  let rateV = [];
  let rateL = [];
  let rateCS = [];
  let rateRS = [];

  let divisionM = [];
  let divisionVL = [];

  workDict?.forEach((el, idx) => {
    divisionM.push(el.sel_nm);
    initDataM?.forEach((data, idx2) => {
      cntM.push(data[el.sel_cd]);
    });
  });

  workRateDict?.forEach((el, idx) => {
    initDataM?.forEach((data, idx2) => {
      rateM.push(data[el.sel_cd]);
    });
  });

  ageDict?.forEach((el, idx) => {
    divisionVL.push(el.sel_nm);
    initDataV?.forEach((data, idx2) => {
      cntV.push(data[el.sel_cd]);
    });

    initDataL?.forEach((data, idx2) => {
      cntL.push(data[el.sel_cd]);
    });

    initDataCS?.forEach((data, idx2) => {
      cntCS.push(data[el.sel_cd]);
    });

    initDataRS?.forEach((data, idx2) => {
      cntRS.push(data[el.sel_cd]);
    });
  });

  ageRateDict?.forEach((el, idx) => {
    initDataV?.forEach((data, idx2) => {
      rateV.push(data[el.sel_cd]);
    });

    initDataL?.forEach((data, idx2) => {
      rateL.push(data[el.sel_cd]);
    });

    initDataCS?.forEach((data, idx2) => {
      rateCS.push(data[el.sel_cd]);
    });

    initDataRS?.forEach((data, idx2) => {
      rateRS.push(data[el.sel_cd]);
    });
  });

  useEffect(() => {
    if (prjVal.value !== "") {
      refetch();
    }

    if (workDict.length === 0) {
      comnRefetch();
    }

    setMenuState("");
  }, [prjVal, startDate, endDate]);

  return (
    <>
      {userData ? (
        <div className="flex h-full w-full flex-col items-center justify-start overflow-auto bg-bdGradientStColor ">
          {/* Filter */}
          {/* 초기 데이터 받아오기 용 (화면에 보이지 않지만 랜더링은 되어있음)*/}
          {/* <div className="absolute -top-72 h-fit w-full ">
        <Filter />
      </div> */}
          {/* 설문기간 */}
          <div className=" flex h-fit w-full flex-col items-center justify-center  border-tableGrayColor bg-white p-3 drop-shadow">
            <PrjPrd />
          </div>

          <div className="flex h-fit w-full flex-col items-center justify-start">
            {/* 고객만족 */}
            {menuList.includes("CS") ? (
              <div className="mt-4 flex h-fit w-full flex-col items-center justify-center bg-white px-4 pb-5 drop-shadow">
                <>
                  {/* 지역주민 타이틀 */}
                  <MemComp name={"고객만족"} />
                  {/* 지역주민 테이블 */}
                  <TableHeader props={["연령대", "참여대상자수", "비중(%)"]} />
                  {divisionVL?.map((el, idx) => {
                    return <TableBody key={idx} idx={idx} props={[el, cntCS[idx], rateCS[idx]]} />;
                  })}
                </>
              </div>
            ) : null}

            {/* 환자경험평가 */}
            {menuList.includes("RS") ? (
              <div className="mt-4 flex h-fit w-full flex-col items-center justify-center bg-white px-4 pb-5 drop-shadow">
                <>
                  {/* 지역주민 타이틀 */}
                  <MemComp name={"환자경험평가"} />
                  {/* 지역주민 테이블 */}
                  <TableHeader props={["연령대", "참여대상자수", "비중(%)"]} />
                  {divisionVL?.map((el, idx) => {
                    return <TableBody key={idx} idx={idx} props={[el, cntRS[idx], rateRS[idx]]} />;
                  })}
                </>
              </div>
            ) : null}

            {/* 구성원 */}
            {menuList.includes("MEM") ? (
              <div className="mt-4 flex h-fit w-full flex-col items-center justify-center bg-white px-4 pb-5 drop-shadow">
                <>
                  {/* 구성원 타이틀 */}
                  <MemComp name={"내부구성원"} />
                  {/* 구성원테이블 */}
                  <TableHeader props={["직종", "참여대상자수", "참여율(%)"]} />
                  {divisionM?.map((el, idx) => {
                    return <TableBody key={idx} idx={idx} props={[el, cntM[idx], rateM[idx]]} />;
                  })}
                  {/* <TableBody idx={1} props={["의사직", "96", "35"]} />
                <TableBody idx={2} props={["간호직", "743", "31"]} />
                <TableBody idx={3} props={["보건직", "70", "27"]} />
                <TableBody idx={4} props={["약무직", "9", "0"]} />
                <TableBody idx={5} props={["사무직", "76", "29"]} /> */}
                </>
              </div>
            ) : null}

            {/* 내원고객 */}
            {menuList.includes("VST") ? (
              <div className="mt-4 flex h-fit w-full flex-col items-center justify-center bg-white px-4 pb-5 drop-shadow">
                <>
                  {/* 내원고객 타이틀 */}
                  <VstComp />
                  {/* 내원고객 테이블 */}
                  <TableHeader props={["연령대", "참여대상자수", "비중(%)"]} />
                  {divisionVL?.map((el, idx) => {
                    return <TableBody key={idx} idx={idx} props={[el, cntV[idx], rateV[idx]]} />;
                  })}
                </>
              </div>
            ) : null}

            {/* 지역주민 */}
            {menuList.includes("LRES") ? (
              <div className="mt-4 flex h-fit w-full flex-col items-center justify-center bg-white px-4 pb-5 drop-shadow">
                <>
                  {/* 지역주민 타이틀 */}
                  <LresComp />
                  {/* 지역주민 테이블 */}
                  <TableHeader props={["연령대", "참여대상자수", "비중(%)"]} />
                  {divisionVL?.map((el, idx) => {
                    return <TableBody key={idx} idx={idx} props={[el, cntL[idx], rateL[idx]]} />;
                  })}
                </>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Analysis;
