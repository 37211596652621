import axios from "axios";

const getStatisticRes = (prj_cd, stDate, edDate) => {
  return axios.get(`/AnalysisMobile/statistic?prj_cd=${prj_cd}&stDate=${stDate}&edDate=${edDate}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getStatisticRes;
