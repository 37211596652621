import { useQuery } from "@tanstack/react-query";
import getAdminProj from "../api/getAdminProj";

const useGetAdminProj = () => {
  return useQuery(["adminProj"], () => getAdminProj(), {
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminProj;
