import { useEffect, useState } from "react";
import useGetPrjPrd from "../../../hooks/useGetPrjPrd";
import { usePreventRequestFilterState, usePrjValState } from "../../../store/mobile/store";

const PrjPrd = () => {
  const prjVal = usePrjValState((state) => state.prjVal);

  // prjPrd api
  const { refetch, data } = useGetPrjPrd(prjVal.value);

  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);

  // preventReqFilterState
  const preventReqFilterState = usePreventRequestFilterState((state) => state.preventReqFilterState);

  useEffect(() => {
    if (prjVal.value && !preventReqFilterState) {
      refetch();
    }
  }, [prjVal]);

  useEffect(() => {
    if (data) {
      let strVal = data.data[0].strt_DT;
      let endVal = data.data[0].end_DT;

      let strYear = strVal.slice(0, 4);
      let strMonth = strVal.slice(4, 6);
      let strDay = strVal.slice(6, 8);

      let endYear = endVal.slice(0, 4);
      let endMonth = endVal.slice(4, 6);
      let endDay = endVal.slice(6, 8);

      setStartDate([strYear, strMonth, strDay]);
      setEndDate([endYear, endMonth, endDay]);
    }
  }, [data]);

  return (
    <div className="flex h-fit w-full flex-row items-center justify-center p-2 font-SDGothic text-selectTextColor/80">
      <div className="flex h-fit w-[25%] flex-col items-center justify-center border border-tableBorderColor bg-tableGrayColor px-4 py-4 text-[14px] font-bold">
        설문 기간
      </div>
      <div className="flex h-fit w-[75%] flex-col items-center justify-center border border-l-0 border-tableBorderColor px-2 py-4 text-[14px]">
        {startDate[0] === undefined
          ? "loading"
          : endDate[0] === ""
          ? `${startDate[0]}년 ${startDate[1]}월 ${startDate[2]}일 ~`
          : `${startDate[0]}년 ${startDate[1]}월 ${startDate[2]}일 ~ ${endDate[0]}년 ${endDate[1]}월 ${endDate[2]}일`}
      </div>
    </div>
  );
};

export default PrjPrd;
