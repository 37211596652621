import { useEffect, useState } from "react";
import { FaExclamation, FaPenNib } from "react-icons/fa";
import Select from "react-select";
import AdminEndSentenseComp from "../../components/pc/admin/AdminEndSentenseComp";
import AdminIntroSentenseComp from "../../components/pc/admin/AdminIntroSentenseComp";
import useGetAdminGuide from "../../hooks/useGetAdminGuide";
import useGetAdminQstSvyOjt from "../../hooks/useGetAdminQstSvyOjt";
import usePostAdminGuide from "../../hooks/usePostAdminGuide";
import useUpdateAdminEndGuide from "../../hooks/useUpdateAdminEndGuide";
import useUpdateAdminIntroGuide from "../../hooks/useUpdateAdminIntroGuide";
import useStore from "../../store/pc/store";

const AdminSentense = () => {
  const [introMode, setIntroMode] = useState(false);
  const [endMode, setEndMode] = useState(false);
  // title
  const [titleValue, setTitleValue] = useState("인사말 타이틀을 입력하세요");
  const [originTitleValue, setOriginTitleValue] = useState("");
  // note
  const [contentValue, setContentValue] = useState("인사말 본문을 입력하세요");
  const [originContentValue, setOriginContentValue] = useState("");
  // end_msg
  const [endContentValue, setEndContentValue] = useState("마침말 본문을 입력하세요");
  const [originEndContentValue, setOriginEndContentValue] = useState("");
  // guide type
  const [guideType, setGuideType] = useState(0);
  const [originGuideType, setOriginGuideType] = useState(0);
  // tail
  const [tailValue, setTailValue] = useState("꼬리말을 입력하세요");
  const [originTailValue, setOriginTailValue] = useState("");

  const [svyOjt, setSvyOjt] = useState("");

  const prjCd = useStore((state) => state.PrjCd);

  // 설문대상 데이터 api
  const { data: svyOjtData, refetch: svyOjtRefetch } = useGetAdminQstSvyOjt(prjCd);
  // guide 데이터 api
  const { data: guideData, refetch: guideRefetch } = useGetAdminGuide(prjCd, svyOjt);
  // 마침말 수정 api
  const { mutate: endGuideMutate } = useUpdateAdminEndGuide();
  // 인사말 수정 api
  const { mutate: introGuideMutate } = useUpdateAdminIntroGuide();
  // guide 생성 api
  const { mutate: createGuideMutate } = usePostAdminGuide();

  const svyOjtOpt = svyOjtData?.data.map((el) => {
    return {
      label: el.comn_CLS,
      value: el.svy_OJT,
    };
  });

  console.log("svyOjtData", svyOjtData);
  console.log("guideData", guideData);

  const handleClickIntroFetch = () => {
    const body = {
      prj_CD: prjCd,
      svy_OJT: svyOjt,
      note: contentValue === "인사말 본문을 입력하세요" ? "" : contentValue,
      title: titleValue === "인사말 타이틀을 입력하세요" ? "" : titleValue,
      tail: tailValue === "꼬리말을 입력하세요" ? "" : tailValue,
      guide_TYPE: guideType,
    };

    introGuideMutate(body);
    setIntroMode(false);
  };

  const handleClickEndFetch = () => {
    const body = {
      prj_CD: prjCd,
      svy_OJT: svyOjt,
      end_MST: endContentValue,
    };

    endGuideMutate(body);
    setEndMode(false);
  };

  const handleChangeSvyOjt = (e) => {
    setSvyOjt(e.value);
  };

  const handleClickCreate = () => {
    const body = {
      prj_CD: prjCd,
      svy_OJT: svyOjt,
    };

    createGuideMutate(body);
  };

  useEffect(() => {
    svyOjtRefetch();
  }, [prjCd]);

  useEffect(() => {
    if (svyOjtData) {
      setSvyOjt(svyOjtOpt[0].value);
      guideRefetch();
    }
  }, [svyOjtData]);

  useEffect(() => {
    if (svyOjt) {
      guideRefetch();
    }
  }, [svyOjt]);

  useEffect(() => {
    if (guideData?.data) {
      setTitleValue(guideData?.data[0]?.title ? guideData?.data[0]?.title : "인사말 타이틀을 입력하세요");
      setContentValue(guideData?.data[0]?.note ? guideData?.data[0]?.note : "인사말 본문을 입력하세요");
      setEndContentValue(guideData?.data[0]?.end_MSG ? guideData?.data[0]?.end_MSG : "마침말 본문을 입력하세요");
      setGuideType(guideData?.data[0]?.guide_TYPE ? guideData?.data[0]?.guide_TYPE : "0");
      setTailValue(guideData?.data[0]?.tail ? guideData?.data[0]?.tail : "꼬리말을 입력하세요");
      setOriginEndContentValue(guideData?.data[0]?.end_MSG);
      setOriginContentValue(guideData?.data[0]?.note);
      setOriginTitleValue(guideData?.data[0]?.title);
      setOriginGuideType(guideData?.data[0]?.guide_TYPE ? guideData?.data[0]?.guide_TYPE : "0");
      setOriginTailValue(guideData?.data[0]?.tail);
    }
  }, [guideData]);

  return (
    <div className="relative flex h-fit min-h-[calc(100vh-5.9rem)] w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic  text-[16px] font-normal text-selectTextColor/90">
      {/* title */}
      <div className="mt-6 flex h-fit w-full flex-row items-center justify-start text-[20px] font-bold">
        <FaPenNib className="mb-2 mr-7 text-[24px] text-orangeColor" />
        인사말 / 마침말
        <Select
          key={"svy_ojt"}
          className="ml-6"
          options={svyOjtOpt} //위에서 만든 배열을 select로 넣기
          onChange={handleChangeSvyOjt} //값이 바뀌면 setState되게
          value={svyOjtOpt?.filter((el) => el.value === svyOjt)[0]}
          isSearchable={false}
          styles={{
            control: (provided, state) => ({
              ...provided,
              backgroundColor: "#E3F5FF",
              fontSize: "1.6rem",
            }),
            menu: (provided, state) => ({
              ...provided,
              fontSize: "1.6rem",
            }),
            indicatorSeparator: (styles) => ({
              display: "none",
            }),
            valueContainer: (provided, state) => ({
              height: "1.6rem",
              lineHeight: "1.6rem",
              padding: "0 0.8rem",
            }),
          }}
        />
      </div>

      {/* content */}
      {guideData?.data.length > 0 ? (
        <div className="mt-16 flex h-full w-full flex-row items-center justify-between">
          {/* 인사말 컴포넌트 */}
          <div className="flex w-1/2 flex-col items-start justify-start">
            {/* title */}
            <div className="mb-6 flex h-fit w-fit flex-row items-center justify-start text-[18px] font-bold">
              <img
                src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
                alt="menu_dot"
                className="mb-1 mr-8 w-[8px]"
              />
              <div className="flex h-fit w-[120px] flex-row items-center justify-start text-[18px] font-bold">인사말</div>
            </div>
            <AdminIntroSentenseComp
              mode={introMode}
              titleValue={titleValue}
              setTitleValue={setTitleValue}
              contentValue={contentValue}
              setContentValue={setContentValue}
              guideType={guideType}
              setGuideType={setGuideType}
              tailValue={tailValue}
              setTailValue={setTailValue}
              setIntroMode={setIntroMode}
              handleClickIntroFetch={handleClickIntroFetch}
              serverTitleData={originTitleValue}
              serverContentData={originContentValue}
              serverGuideType={originGuideType}
              serverTailData={originTailValue}
            />
          </div>

          {/* 마침말 컴포넌트 */}
          <div className="flex w-1/2 flex-col items-start justify-start">
            {/* title */}
            <div className="mb-6 flex h-fit w-fit flex-row items-center justify-start text-[18px] font-bold">
              <img
                src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
                alt="menu_dot"
                className="mb-1 mr-8 w-[8px]"
              />
              <div className="flex h-fit w-[120px] flex-row items-center justify-start text-[18px] font-bold">마침말</div>
            </div>
            <AdminEndSentenseComp
              mode={endMode}
              endContentValue={endContentValue}
              setEndContentValue={setEndContentValue}
              setEndMode={setEndMode}
              handleClickEndFetch={handleClickEndFetch}
              serverData={originEndContentValue}
            />
          </div>
        </div>
      ) : (
        <div className="mt-10 flex h-fit w-full flex-col items-center justify-center p-20">
          <FaExclamation className="mb-14 text-[40px] text-orangeColor" />
          <div className="flex h-fit w-full flex-col items-center justify-center text-[18px] font-bold">데이터가 존재하지 않습니다</div>
          <button
            type="button"
            onClick={handleClickCreate}
            className="mt-10 flex h-fit w-[150px] flex-col items-center justify-center rounded-xl bg-orangeColor p-8 font-bold text-white hover:bg-orangeColor/80"
          >
            생성하기
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminSentense;
