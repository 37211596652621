import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA04 from "../../components/pc/BarColumnChartDA04";
import CSTrendChartPeriod from "../../components/pc/CSTrendChartPeriod";
import DA04Table from "../../components/pc/DA04Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA04AgeGrp } from "../../hooks/useGraphDA04AgeGrp";
import { useGraphDA04Area } from "../../hooks/useGraphDA04Area";
import { useGraphDA04Assign } from "../../hooks/useGraphDA04Assign";
import { useGraphDA04Doc } from "../../hooks/useGraphDA04Doc";
import { useGraphDA04Docter } from "../../hooks/useGraphDA04Docter";
import { useGraphDA04Etc1 } from "../../hooks/useGraphDA04Etc1";
import { useGraphDA04Etc2 } from "../../hooks/useGraphDA04Etc2";
import { useGraphDA04Etc3 } from "../../hooks/useGraphDA04Etc3";
import { useGraphDA04MainDept } from "../../hooks/useGraphDA04MainDept";
import { useGraphDA04Normal } from "../../hooks/useGraphDA04Normal";
import { useGraphDA04Octy } from "../../hooks/useGraphDA04Octy";
import { useGraphDA04Sex } from "../../hooks/useGraphDA04Sex";
import { useGraphDA04WrkYyGrp } from "../../hooks/useGraphDA04WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA04AgeGrp } from "../../hooks/useTableDA04AgeGrp";
import { useTableDA04Assign } from "../../hooks/useTableDA04Assign";
import { useTableDA04Doc } from "../../hooks/useTableDA04Doc";
import { useTableDA04Octy } from "../../hooks/useTableDA04Octy";
import { useTableDA04Sex } from "../../hooks/useTableDA04Sex";
import { useTableDA04WrkYyGrp } from "../../hooks/useTableDA04WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA04Page = () => {
  const analysisType = useStore((state) => state.AnalysisType);
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }

        if (QstListData?.filter((x) => x.qst_CD === Pick.qst_cd) && QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].note2 === "DOC") {
          vRadio.push({ value: "Docter", label: "의사별" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.area_CD_CNT !== "0") {
          vRadio.push({ value: "Area", label: "주거지역" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
        if (dd.main_DEPT_CD_CNT !== "0") {
          vRadio.push({ value: "MainDept", label: "방문진료과" });
        }

        if (dd.etc_CD1_CNT !== "0") {
          vRadio.push({ value: "Etc1", label: dd.etc_CD1_NM });
        }
        if (dd.etc_CD2_CNT !== "0") {
          vRadio.push({ value: "Etc2", label: dd.etc_CD2_NM });
        }
        if (dd.etc_CD3_CNT !== "0") {
          vRadio.push({ value: "Etc3", label: dd.etc_CD3_NM });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null || QstListData.length === 0) {
  //     QstListRefetch();
  //   } else {
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const { isGraphDA04NormalLoading, isGraphDA04NormalError, GraphDA04NormalData, isGraphDA04NormalSuccess, GraphDA04NormalRefetch } =
    useGraphDA04Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA04AssignLoading, isGraphDA04AssignError, GraphDA04AssignData, isGraphDA04AssignSuccess, GraphDA04AssignRefetch } =
    useGraphDA04Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA04DocLoading, isGraphDA04DocError, GraphDA04DocData, isGraphDA04DocSuccess, GraphDA04DocRefetch } = useGraphDA04Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA04DocterLoading, isGraphDA04DocterError, GraphDA04DocterData, isGraphDA04DocterSuccess, GraphDA04DocterRefetch } =
    useGraphDA04Docter(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA04OctyLoading, isGraphDA04OctyError, GraphDA04OctyData, isGraphDA04OctySuccess, GraphDA04OctyRefetch } = useGraphDA04Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA04WrkYyGrpLoading, isGraphDA04WrkYyGrpError, GraphDA04WrkYyGrpData, isGraphDA04WrkYyGrpSuccess, GraphDA04WrkYyGrpRefetch } =
    useGraphDA04WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA04AgeGrpLoading, isGraphDA04AgeGrpError, GraphDA04AgeGrpData, isGraphDA04AgeGrpSuccess, GraphDA04AgeGrpRefetch } =
    useGraphDA04AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA04SexLoading, isGraphDA04SexError, GraphDA04SexData, isGraphDA04SexSuccess, GraphDA04SexRefetch } = useGraphDA04Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA04AreaLoading, isGraphDA04AreaError, GraphDA04AreaData, isGraphDA04AreaSuccess, GraphDA04AreaRefetch } = useGraphDA04Area(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "AREA_CD",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA04MainDeptLoading, isGraphDA04MainDeptError, GraphDA04MainDeptData, isGraphDA04MainDeptSuccess, GraphDA04MainDeptRefetch } =
    useGraphDA04MainDept(PrjCd, SvyOjt, QstCd, "", "", "MAIN_DEPT_CD", SortType, startDate, endDate);

  const { isGraphDA04Etc1Loading, isGraphDA04Etc1Error, GraphDA04Etc1Data, isGraphDA04Etc1Success, GraphDA04Etc1Refetch } = useGraphDA04Etc1(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD1",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA04Etc2Loading, isGraphDA04Etc2Error, GraphDA04Etc2Data, isGraphDA04Etc2Success, GraphDA04Etc2Refetch } = useGraphDA04Etc2(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD2",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA04Etc3Loading, isGraphDA04Etc3Error, GraphDA04Etc3Data, isGraphDA04Etc3Success, GraphDA04Etc3Refetch } = useGraphDA04Etc3(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ETC_CD3",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA04AgeGrpLoading, isTableDA04AgeGrpError, TableDA04AgeGrpData, isTableDA04AgeGrpSuccess, TableDA04AgeGrpRefetch } =
    useTableDA04AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA04AssignLoading, isTableDA04AssignError, TableDA04AssignData, isTableDA04AssignSuccess, TableDA04AssignRefetch } =
    useTableDA04Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA04DocLoading, isTableDA04DocError, TableDA04DocData, isTableDA04DocSuccess, TableDA04DocRefetch } = useTableDA04Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA04OctyLoading, isTableDA04OctyError, TableDA04OctyData, isTableDA04OctySuccess, TableDA04OctyRefetch } = useTableDA04Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA04SexLoading, isTableDA04SexError, TableDA04SexData, isTableDA04SexSuccess, TableDA04SexRefetch } = useTableDA04Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA04WrkYyGrpLoading, isTableDA04WrkYyGrpError, TableDA04WrkYyGrpData, isTableDA04WrkYyGrpSuccess, TableDA04WrkYyGrpRefetch } =
    useTableDA04WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length > 0) {
        let graphList = GraphSet.map((el) => el.value);

        if (graphList.includes("Normal")) {
          GraphDA04NormalRefetch();

          // 여기 추가? (API refetch)
        }

        if (graphList.includes("Assign")) {
          GraphDA04AssignRefetch();
        }

        if (graphList.includes("Doc")) {
          GraphDA04DocRefetch();
        }

        if (graphList.includes("Docter")) {
          GraphDA04DocterRefetch();
        }

        if (graphList.includes("Octy")) {
          GraphDA04OctyRefetch();
        }

        if (graphList.includes("WrkYyGrp")) {
          GraphDA04WrkYyGrpRefetch();
        }

        if (graphList.includes("AgeGrp")) {
          GraphDA04AgeGrpRefetch();
        }

        if (graphList.includes("Sex")) {
          GraphDA04SexRefetch();
        }

        if (graphList.includes("Area")) {
          GraphDA04AreaRefetch();
        }

        if (graphList.includes("MainDept")) {
          GraphDA04MainDeptRefetch();
        }

        if (graphList.includes("Etc1")) {
          GraphDA04Etc1Refetch();
        }

        if (graphList.includes("Etc2")) {
          GraphDA04Etc2Refetch();
        }

        if (graphList.includes("Etc3")) {
          GraphDA04Etc3Refetch();
        }
      }

      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioTypeCheck === "AgeGrp") {
      GraphDA04AgeGrpRefetch();
      TableDA04AgeGrpRefetch();
    } else if (RadioTypeCheck === "Sex") {
      GraphDA04SexRefetch();
      TableDA04SexRefetch();
    } else if (RadioTypeCheck === "Assign") {
      GraphDA04AssignRefetch();
      TableDA04AssignRefetch();
    } else if (RadioTypeCheck === "Doc") {
      GraphDA04DocRefetch();
      TableDA04DocRefetch();
    } else if (RadioTypeCheck === "Octy") {
      GraphDA04OctyRefetch();
      TableDA04OctyRefetch();
    } else if (RadioTypeCheck === "WrkYyGrp") {
      GraphDA04WrkYyGrpRefetch();
      TableDA04WrkYyGrpRefetch();
    } else if (RadioTypeCheck === "MainDept") {
      GraphDA04MainDeptRefetch();
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, startDate, endDate, GraphSet]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA04"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
          etc1Check={GraphDA04Etc1Data?.filter((el) => el.rate !== "0.0").map((el) => el.assign)}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full ">
          {analysisType === "csscan" ? (
            <div className="">
              <CSTrendChartPeriod
                hsp_nm={HspNm}
                prj_cd={PrjCd}
                svy_ojt={SvyOjt}
                qst_cd={Pick.qst_cd}
                sel_cd={""}
                string_value={""}
                sort_type={SortType}
                data_num={RadioNumCheck}
                data_type={RadioTypeCheck}
                qst={Qst}
                endDate={endDate}
                deep_ana_cd={"DA04"}
              />
            </div>
          ) : (
            <></>
          )}
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData = [];

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Docter") {
                vTitle = "의사별 응답률";
              } else if (data.value === "Octy") {
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vTitle = "연령대별 응답률";
              } else if (data.value === "Area") {
                vTitle = "지역별 응답률";
              } else if (data.value === "MainDept") {
                vTitle = "방문진료과별 응답률";
              } else if (data.value === "Etc1") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc2") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              } else if (data.value === "Etc3") {
                vTitle =
                  (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") +
                  "별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA04NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA04AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA04DocData;
              } else if (data.value === "Docter") {
                vName = "docter";
                vData = GraphDA04DocterData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vData = GraphDA04OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA04WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA04SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vWhere = "AGE_GRP";
                vData = GraphDA04AgeGrpData;
              } else if (data.value === "Area") {
                vName = "area";
                vWhere = "AGE_GRP";
                vData = GraphDA04AreaData;
              } else if (data.value === "MainDept") {
                vName = "mainDept";
                vWhere = "AGE_GRP";
                vData = GraphDA04MainDeptData;
              } else if (data.value === "Etc1") {
                vName = "etc1";
                vWhere = "ETC_CD1";
                vData = GraphDA04Etc1Data;
              } else if (data.value === "Etc2") {
                vName = "etc2";
                vWhere = "ETC_CD2";
                vData = GraphDA04Etc2Data;
              } else if (data.value === "Etc3") {
                vName = "etc3";
                vWhere = "ETC_CD3";
                vData = GraphDA04Etc3Data;
              }

              if (data.value === "Etc1" && data.label === "병동") {
                if (GraphDA04Etc1Data) {
                  let arr = GraphDA04Etc1Data?.filter((el) => el.rate !== "0.0").map((el) => el.assign);

                  if ((arr.length === 2 && arr[0] === "전체" && arr[1] === "해당없음") || arr.length === 0) {
                    return null;
                  } else {
                    return (
                      <div
                        key={`normal_${i}`}
                        className={`float-left ${
                          i === 1 ? "" : "mt-[2.0rem]"
                        } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                      >
                        <BarColumnChartDA04
                          hsp_nm={HspNm}
                          prj_cd={PrjCd}
                          svy_ojt={SvyOjt}
                          qst_cd={Pick.qst_cd}
                          sel_cd={""}
                          string_value={""}
                          where={vWhere}
                          sort_type={SortType}
                          data_num={RadioNumCheck}
                          data_type={RadioTypeCheck}
                          stack={vStack}
                          type={vType}
                          title={vTitle}
                          qst={Qst}
                          name={vName}
                          Gdata={vData}
                        />
                      </div>
                    );
                  }
                }
              } else {
                return (
                  <div
                    key={`normal_${i}`}
                    className={`float-left ${
                      i === 1 ? "" : "mt-[2.0rem]"
                    } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                  >
                    <BarColumnChartDA04
                      hsp_nm={HspNm}
                      prj_cd={PrjCd}
                      svy_ojt={SvyOjt}
                      qst_cd={Pick.qst_cd}
                      sel_cd={""}
                      string_value={""}
                      where={vWhere}
                      sort_type={SortType}
                      data_num={RadioNumCheck}
                      data_type={RadioTypeCheck}
                      stack={vStack}
                      type={vType}
                      title={vTitle}
                      qst={Qst}
                      name={vName}
                      Gdata={vData}
                    />
                  </div>
                );
              }
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "bar";
            var vData;
            var vTData;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Docter") {
              vTitle = "의사별 응답률";
            } else if (data.value === "Octy") {
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vTitle = "연령대별 응답률";
            } else if (data.value === "Area") {
              vTitle = "지역별 응답률";
            } else if (data.value === "MainDept") {
              vTitle = "방문진료과별 응답률";
            } else if (data.value === "Etc1") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc1").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc2") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc2").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            } else if (data.value === "Etc3") {
              vTitle =
                (GraphSet.filter((x) => x.value === "Etc3").length > 0 ? GraphSet.filter((x) => x.value === data.value)[0].label : "") + "별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA04NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA04AssignData;
              vTData = TableDA04AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA04DocData;
              vTData = TableDA04DocData;
            } else if (data.value === "Docter") {
              vName = "docter";
              vData = GraphDA04DocterData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vData = GraphDA04OctyData;
              vTData = TableDA04OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA04WrkYyGrpData;
              vTData = TableDA04WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA04SexData;
              vTData = TableDA04SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vWhere = "AGE_GRP";
              vData = GraphDA04AgeGrpData;
              vTData = TableDA04AgeGrpData;
            } else if (data.value === "Area") {
              vName = "area";
              vWhere = "AGE_GRP";
              vData = GraphDA04AreaData;
            } else if (data.value === "MainDept") {
              vName = "mainDept";
              vWhere = "AGE_GRP";
              vData = GraphDA04MainDeptData;
            } else if (data.value === "Etc1") {
              vName = "etc1";
              vWhere = "ETC_CD1";
              vData = GraphDA04Etc1Data;
            } else if (data.value === "Etc2") {
              vName = "etc2";
              vWhere = "ETC_CD2";
              vData = GraphDA04Etc2Data;
            } else if (data.value === "Etc3") {
              vName = "etc3";
              vWhere = "ETC_CD3";
              vData = GraphDA04Etc3Data;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? ""
                      : "hidden"
                  } float-left w-full`}
                >
                  {RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Area" &&
                    RadioTypeCheck !== "MainDept" &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3" && (
                      <DA04Table
                        hsp_nm={HspNm}
                        prj_cd={PrjCd}
                        svy_ojt={SvyOjt}
                        qst_cd={Pick.qst_cd}
                        sel_cd={""}
                        string_value={""}
                        where={vWhere}
                        sort_type={SortType}
                        data_num={RadioNumCheck}
                        data_type={RadioTypeCheck}
                        stack={vStack}
                        type={vType}
                        title={vTitle}
                        qst={Qst}
                        name={vName}
                        keyword={Keyword}
                        Tdata={vTData}
                      />
                    )}
                </div>
                <div
                  className={`${
                    RadioTypeCheck !== "" &&
                    RadioTypeCheck !== undefined &&
                    RadioTypeCheck !== null &&
                    RadioTypeCheck !== "Etc1" &&
                    RadioTypeCheck !== "Etc2" &&
                    RadioTypeCheck !== "Etc3"
                      ? "mt-[2.0rem]"
                      : ""
                  } float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA04
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA04Page;
