import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useWindowSize from "../../../hooks/useWindowSize";
import { useUnitState } from "../../../store/mobile/store";

const Da05Chart = (props) => {
  const width = useWindowSize(props);
  const unitState = useUnitState((state) => state.unitState);

  const cateList = ["전체", "의사직", "간호직", "보건직", "약무직", "사무직", "기술직", "기타"];

  const color = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4"];

  let finalCategories = cateList.filter((el) => props?.data[1].includes(el));

  let initSeries = [...props?.data[2]];

  for (let i = 0; i < initSeries[0]?.length; i++) {
    if (initSeries[0][i] + initSeries[1][i] + initSeries[2][i] + initSeries[3][i] === 0) {
      initSeries[0][i] = false;
      initSeries[1][i] = false;
      initSeries[2][i] = false;
      initSeries[3][i] = false;
    }
  }

  let finalSeries = initSeries.map((el, idx) => {
    return {
      name: props?.data[0][idx],
      data: el.filter((el) => el !== false),
      color: color[idx],
    };
  });

  const options = {
    chart: {
      type: "bar",
      style: {},
      width: width !== 0 ? width * 0.95 : null,
      height:
        props?.data[1].length > 7 ? 400 : props?.data[1].length > 5 ? 350 : props?.data[1].length > 3 ? 250 : props?.data[1].length > 1 ? 200 : 150,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: "",
      style: {
        fontSize: 16,
        color: "#383838",
      },
    },
    xAxis: {
      marginTop: 10,
      tickLength: 0,
      lineWidth: 0.2,
      lineColor: "#383838",
      tickWidth: 0,
      title: {
        text: null,
      },
      labels: {
        // useHTML: true,
        // formatter: function () {
        //   return `<span style="border: none;">${this.value}</span>`;
        // },
        style: {
          fontSize: 14,
          fontFamily: "AppleSDGothicNeoRegular",
          fontWeight: "bold",
          color: "#383838",
          lineWidthPlus: 0,
        },
        y: 5,
      },
      gridLineWidth: 0,
      categories: finalCategories,
    },
    yAxis: {
      min: 0,
      max: unitState === "rate" ? 100 : null,
      tickInterval: 10,

      title: {
        text: null,
      },
      visible: false,
    },
    credits: {
      enabled: false,
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    series: finalSeries.reverse(),
    plotOptions: {
      series: {
        stacking: "normal",
        pointWidth: 25,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 14,
            fontFamily: "AppleSDGothicNeoRegular",
            color: "#383838",
            textOutline: "none",
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        const color = point.color || this.series.color;

        const series = this.series;
        const legendValue = series.name;

        return `<b style="font-size : 9px;">${this.x} </br> </b> <span style="color: ${color};">&#9679;</span> ${legendValue} : <b>${this.y}</b>`;
      },
      style: {
        fontSize: 10,
        fontFamily: "AppleSDGothicNeoRegular",
        color: "#383838",
      },
    },
    legend: {
      enabled: true,
      reversed: true,
      maxHeight: 100,
      layout: "horizontal",
      align: "center",
      itemMarginBottom: 3,
      itemStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
  };
  return <>{width ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}</>;
};

export default Da05Chart;
