import { useState } from "react";
import CSTrendColumnChart from "./CSTrendColumnChart";

const CSTrendChartPeriod = ({ hsp_nm, prj_cd, svy_ojt, qst_cd, sel_cd, string_value, sort_type, data_num, data_type, qst, endDate, deep_ana_cd }) => {
  const Trends = Object.freeze({
    MONTH: "month",
    YEAR: "year",
  });

  const [trendPeriod, setTrendPeriod] = useState(Trends.MONTH);

  const handleClickTrendRadio = (value) => {
    setTrendPeriod(value);
  };
  return (
    <div className="float-left mb-10 border-[0.1rem] border-tableBorderColor pb-[1.5rem]">
      <div className="my-3 flex w-full flex-row items-end justify-end gap-5 pr-7 pt-1 text-xl">
        <div className="flex w-fit flex-row items-center justify-center">
          <input
            type="radio"
            checked={trendPeriod === Trends.MONTH ? true : false}
            name="trend"
            id={Trends.MONTH}
            value={Trends.MONTH}
            className="border accent-orangeColor"
            onClick={() => handleClickTrendRadio(Trends.MONTH)}
            onChange={() => {}}
          />
          <label htmlFor="none" className="ml-1 font-bold">
            월
          </label>
        </div>
        <div className="flex h-fit w-fit flex-row items-center justify-center">
          <input
            type="radio"
            checked={trendPeriod === Trends.YEAR ? true : false}
            name="trend"
            id={Trends.YEAR}
            value={Trends.YEAR}
            className="border accent-orangeColor"
            onClick={() => handleClickTrendRadio(Trends.YEAR)}
            onChange={() => {}}
          />
          <label htmlFor="small" className="ml-1 font-bold">
            년 / 분기
          </label>
        </div>
      </div>
      {trendPeriod === "month" ? (
        <CSTrendColumnChart
          hsp_nm={hsp_nm}
          prj_cd={prj_cd}
          svy_ojt={svy_ojt}
          qst_cd={qst_cd}
          sel_cd={sel_cd}
          string_value={string_value}
          sort_type={sort_type}
          data_num={data_num}
          data_type={data_type}
          qst={qst}
          endDate={endDate}
          deep_ana_cd={deep_ana_cd}
          trendPeriod={trendPeriod}
        />
      ) : (
        <div className="flex gap-10">
          <CSTrendColumnChart
            hsp_nm={hsp_nm}
            prj_cd={prj_cd}
            svy_ojt={svy_ojt}
            qst_cd={qst_cd}
            sel_cd={sel_cd}
            string_value={string_value}
            sort_type={sort_type}
            data_num={data_num}
            data_type={data_type}
            qst={qst}
            endDate={endDate}
            deep_ana_cd={deep_ana_cd}
            trendPeriod={"year"}
          />
          <CSTrendColumnChart
            hsp_nm={hsp_nm}
            prj_cd={prj_cd}
            svy_ojt={svy_ojt}
            qst_cd={qst_cd}
            sel_cd={sel_cd}
            string_value={string_value}
            sort_type={sort_type}
            data_num={data_num}
            data_type={data_type}
            qst={qst}
            endDate={endDate}
            deep_ana_cd={deep_ana_cd}
            trendPeriod={"quarter"}
          />
        </div>
      )}
    </div>
  );
};

export default CSTrendChartPeriod;
