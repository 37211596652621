/**
 * Colum차트 그래프 두께 계산
 * @param {*} tempCategoriesDataLength
 * @param {*} tempSeriesLength
 * @param {*} stacking
 * @param {*} type
 * @returns
 */
export const getPointWith = (tempCategoriesDataLength, tempSeriesLength, stacking, type) => {
  if (type === "column" && stacking) {
    if (tempCategoriesDataLength <= 4) {
      return 100;
    } else return 500 / (tempCategoriesDataLength + 1);
  } else if (type === "column" && !stacking) {
    if (tempCategoriesDataLength <= 3 && tempSeriesLength <= 1) {
      return 100;
    }
  } else {
    if (500 / (tempCategoriesDataLength * tempSeriesLength) > 50) return 50;
  }

  return 500 / (tempCategoriesDataLength * tempSeriesLength) > 100 ? 100 : 500 / (tempCategoriesDataLength * tempSeriesLength);
};

/**
 * Normal Table 데이터 정규화
 * @param {*} data
 * @returns
 */
export const getNormalTable = (data) => {
  if (!data) return;

  console.log("data", data);
  const result = [];
  const category = new Set();
  // sel_cd를 기준으로 그룹화
  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.sel]) {
      acc[item.sel] = {};
    }
    category.add(item.assign);
    acc[item.sel][`cnt_${item.assign_cd}`] = item.cnt;
    return acc;
  }, {});

  // 결과 배열 생성
  for (const [sel, counts] of Object.entries(groupedData)) {
    result.push({ sel, ...counts });
  }
  const categoryArray = Array.from(category);
  console.log("result", result);
  console.log("categoryArray", categoryArray);
  return { result, categoryArray };
};
