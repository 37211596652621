import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SyncLoader } from "react-spinners";
import Da11Chart from "../../components/mobile/chart/Da11Chart";
import TabBar from "../../components/mobile/common/TabBar";
import RankSelListModal from "../../components/mobile/RankSelListModal";
import Da11Table from "../../components/mobile/table/Da11Table";
// import { ageCntDict, ageDict, workCntDict, workDict } from "../../dict/mobile/dict";
import useGetDa11Res from "../../hooks/useGetDa11Res";
import {
  useComnDict,
  useMenuListModalControlState,
  useMenuState,
  usePeriodState,
  usePrjValState,
  useRankSelListNm,
  useRankSelListState,
  useSelectQstState,
  useSelListModalState,
  useUnitState,
} from "../../store/mobile/store";

const DA11 = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);
  const ageCntDict = useComnDict((state) => state.ageCntDict);
  const workCntDict = useComnDict((state) => state.workCntDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const menuState = useMenuState((state) => state.menuVal);
  const prjVal = usePrjValState((state) => state.prjVal);
  const selNm = useRankSelListNm((state) => state.rankSelNmState);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const qstListModalState = useMenuListModalControlState((state) => state.menuListModalControl);

  const selListModalState = useSelListModalState((state) => state.selListModalState);
  const setSelListModalState = useSelListModalState((state) => state.setSelListModalState);

  const [rendering, setRendering] = useState(false);

  const rankSelList = useRankSelListState((state) => state.rankSelListState);

  const { refetch, data, isLoading } = useGetDa11Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, rankSelList, startDate, endDate);

  const selListOpt = [
    {
      selCd: "A",
      selNm: "1순위",
    },
    {
      selCd: "B",
      selNm: "2순위",
    },
    {
      selCd: "C",
      selNm: "3순위",
    },
    {
      selCd: "D",
      selNm: "1+2+3순위",
    },
    {
      selCd: "E",
      selNm: "1*3+2*2+3*1순위",
    },
  ];

  const initData = data?.data[0];
  // chartData
  let chartCategories = [];
  let chartSeries = []; // chartProps
  let chartSelNm = []; // chartProps
  let finalCagtegory = [];

  // tableData
  let tableCategory = [];
  let tableSeries = [];

  // 직종별 데이터
  if (menuState === "MEM") {
    // 사례수에 대한 처리 (table)
    initData?.slice(0, 1).forEach((el, idx) => {
      let temp = [];
      temp.push(el.sel_NM_M);
      workDict.forEach((data, idx2) => {
        temp.push(el[data.sel_cd]);
      });

      tableSeries.push(temp);
    });

    // 비율일 때
    if (unitState === "rate") {
      initData?.slice(1, initData?.length).forEach((el, idx) => {
        let temp = [];
        let tableTemp = [];

        // sel_nm 우선저장
        tableTemp.push(el.sel_NM_M);

        workDict.forEach((data, idx2) => {
          // workDict에 처음 접근할때만 initData의 selNm을 push
          if (idx2 === 0) {
            chartSelNm.push(el.sel_NM_M);
          }
          // table category 뽑기
          if (idx === 0) {
            tableCategory.push(data.sel_nm);
          }
          // 값이 있으면 값을 push, 없으면 null push
          if (el[data.sel_cd]) {
            temp.push(el[data.sel_cd]);
            chartCategories.push(data.sel_nm);
          } else {
            temp.push(null);
          }

          // table
          tableTemp.push(el[data.sel_cd]);
        });
        chartSeries.push(temp);
        tableSeries.push(tableTemp);
      });
    }
    // 카운트일 때
    else {
      initData?.slice(1, initData?.length).forEach((el, idx) => {
        let temp = [];
        let tableTemp = [];

        // sel_nm 우선저장
        tableTemp.push(el.sel_NM_M);

        workCntDict.forEach((data, idx2) => {
          // workDict에 처음 접근할때만 initData의 selNm을 push
          if (idx2 === 0) {
            chartSelNm.push(el.sel_NM_M);
          }
          // table category 뽑기
          if (idx === 0) {
            tableCategory.push(data.sel_nm);
          }
          // 값이 있으면 값을 push, 없으면 null push
          if (el[data.sel_cd]) {
            temp.push(el[data.sel_cd]);
            chartCategories.push(data.sel_nm);
          } else {
            temp.push(null);
          }

          // table
          tableTemp.push(el[data.sel_cd]);
        });
        chartSeries.push(temp);
        tableSeries.push(tableTemp);
      });
    }

    workDict.forEach((el) => {
      if (chartCategories.includes(el.sel_nm)) {
        finalCagtegory.push(el.sel_nm);
      }
    });
  }
  // 연령별 데이터
  if (menuState === "VST" || menuState === "LRES" || selectQstState.svy_OJT === "CS" || selectQstState.svy_OJT === "RS") {
    // 사례수에 대한 처리 (table)
    initData?.slice(0, 1).forEach((el, idx) => {
      let temp = [];
      temp.push(el.sel_NM_M);
      ageDict.forEach((data, idx2) => {
        temp.push(el[data.sel_cd]);
      });

      tableSeries.push(temp);
    });

    // 비율일 때
    if (unitState === "rate") {
      initData?.slice(1, initData?.length).forEach((el, idx) => {
        let temp = [];
        let tableTemp = [];

        // sel_nm 우선저장
        tableTemp.push(el.sel_NM_M);

        ageDict.forEach((data, idx2) => {
          // workDict에 처음 접근할때만 initData의 selNm을 push
          if (idx2 === 0) {
            chartSelNm.push(el.sel_NM_M);
          }
          // table category 뽑기
          if (idx === 0) {
            tableCategory.push(data.sel_nm);
          }
          // 값이 있으면 값을 push, 없으면 null push
          if (el[data.sel_cd]) {
            temp.push(el[data.sel_cd]);
            chartCategories.push(data.sel_nm);
          } else {
            temp.push(null);
          }

          // table
          tableTemp.push(el[data.sel_cd]);
        });
        chartSeries.push(temp);
        tableSeries.push(tableTemp);
      });
    }
    // 카운트일 때
    else {
      initData?.slice(1, initData?.length).forEach((el, idx) => {
        let temp = [];
        let tableTemp = [];

        // sel_nm 우선저장
        tableTemp.push(el.sel_NM_M);

        ageCntDict.forEach((data, idx2) => {
          // workDict에 처음 접근할때만 initData의 selNm을 push
          if (idx2 === 0) {
            chartSelNm.push(el.sel_NM_M);
          }
          // table category 뽑기
          if (idx === 0) {
            tableCategory.push(data.sel_nm);
          }
          // 값이 있으면 값을 push, 없으면 null push
          if (el[data.sel_cd]) {
            temp.push(el[data.sel_cd]);
            chartCategories.push(data.sel_nm);
          } else {
            temp.push(null);
          }

          // table
          tableTemp.push(el[data.sel_cd]);
        });
        chartSeries.push(temp);
        tableSeries.push(tableTemp);
      });
    }

    ageDict.forEach((el) => {
      if (chartCategories.includes(el.sel_nm)) {
        finalCagtegory.push(el.sel_nm);
      }
    });
  }

  const handleClickSelList = () => {
    setSelListModalState(!selListModalState);
  };

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 500);
  }, [selectQstState, rankSelList, startDate, endDate]);

  return (
    <>
      {isLoading || !rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="relative flex h-fit w-full flex-row items-center justify-between border-b border-qstTitleBorder px-2 py-3 text-[16px] font-bold text-selectTextColor">
          <BsDot className="absolute left-2 text-orangeColor" />
          <div className="hide-scrollbar relative  ml-7 flex  h-fit w-[60%] flex-row items-center justify-start overflow-auto whitespace-pre py-4 ">
            {selectQstState.keyword}
          </div>
          {selListModalState ? (
            <>
              <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                  {selNm.length > 7 ? `${selNm.slice(0, 7)}...` : selNm}
                </div>
                <IoIosArrowUp className="mr-2 text-[20px] text-orangeColor" />
              </div>
            </>
          ) : (
            <>
              <div className="flex h-fit w-fit flex-row items-center justify-center" onClick={handleClickSelList}>
                <div className="mr-2 flex h-fit w-fit flex-row items-center justify-center text-[14px]">
                  {selNm.length > 7 ? `${selNm.slice(0, 7)}...` : selNm}
                </div>
                <IoIosArrowDown className="mr-2 text-[20px] text-orangeColor" />
              </div>
            </>
          )}

          <div
            className={
              selListModalState || qstListModalState
                ? `absolute right-3 top-[46px] z-10 h-[500px] w-[80%] overflow-hidden font-semibold transition-z-index ease-out`
                : `absolute right-3 top-[46px] -z-30 h-[500px] w-[80%] overflow-hidden font-semibold transition-z-index duration-1000 ease-out`
            }
          >
            <RankSelListModal data={selListOpt} />
          </div>
        </div>
        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>
          {rendering && !qstListModalState && !isLoading ? (
            <>
              <Da11Chart categories={finalCagtegory} series={chartSeries} sel_nm={chartSelNm} />
              <Da11Table categories={tableCategory} series={tableSeries} />
            </>
          ) : null}
        </div>

        <TabBar />
      </div>
    </>
  );
};

export default DA11;
