import exceljs from "exceljs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
const html2canvas = require("html2canvas");

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const BarColumnChartTotal = ({
  hsp_nm,
  prj_cd,
  svy_ojt,
  qst_cd,
  sel_cd,
  string_value,
  where,
  sort_type,
  data_num,
  data_type,
  stack,
  type,
  title,
  qst,
  name,
  deepAnaCd,
  data,
}) => {
  Highcharts.setOptions({
    colors: ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"],
  });

  const chart = useRef();

  const convertToCSV = () => {
    const currChart = chart.current.chart;
    const csvData = currChart.getCSV();

    return csvData;
  };

  const downloadChart = async (type) => {
    if (chart && chart.current && chart.current.chart) {
      if (type === "jpeg") {
        chart.current.chart.exportChart({ type: "image/jpeg" });
      } else if (type === "png") {
        chart.current.chart.exportChart({ type: "image/png" });
      } else if (type === "pdf") {
        chart.current.chart.exportChart({
          type: "application/pdf",
        });
      } else {
        // 엑셀 워크북 생성
        let workbook = new exceljs.Workbook();
        // 시트 생성
        const imageSheet = workbook.addWorksheet("DataSheet");

        // 텍스트 데이터 csv로 변환
        const csvData = convertToCSV();
        // 불필요한 특수문자 제거 (")
        let filteredCsvData = csvData.replace(/\"/gi, "");

        // excelJs에 맞는 형식으로 전환
        let excelData = [];

        // 개행태그를 기준으로 split
        let dataArr = filteredCsvData.split("\n");

        // ,단위로 split하여 배열로 만든 후, 덩어리를 excelData에 push
        for (let i = 0; i < dataArr.length; i++) {
          let temp = dataArr[i].split(",");

          excelData.push(temp);
        }

        // 시트에 추가
        imageSheet.addRows(excelData);

        // 차트 이미지 작업을 위한 ref 속성 가져오기
        const chartContainer = chart.current.container.current;

        let promise = [];

        // 차트이미지를 스크린샷 찍어 이미지를 시트에 추가
        promise.push(
          html2canvas(chartContainer).then((c) => {
            let image = c.toDataURL();
            const imageId2 = workbook.addImage({
              base64: image,
              extension: "png",
            });

            imageSheet.addImage(imageId2, {
              tl: { col: 0, row: excelData.length + 3 },
              br: { col: chartContainer.offsetWidth / 100, row: excelData.length + 12 + chartContainer.offsetHeight / 100 },
            });
          })
        );

        // 파일 다운로드
        Promise.all(promise).then(() => {
          workbook.xlsx.writeBuffer().then((b) => {
            let a = new Blob([b]);
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement("a");
            elem.href = url;
            elem.download = `${qst}.xlsx`;
            document.body.appendChild(elem);
            elem.style = "display: none";
            elem.click();
            elem.remove();
          });
        });

        return workbook;
      }
    }
  };

  const initialOptions = {
    title: { text: "" },
    exporting: {
      enabled: false,
      filename: qst + "_" + title,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: { fontSize: "1.4rem", fontFamily: "AppleSDGothicNeoRegular", textShadow: false, textOutLine: false },
          format: "{y}",
          border: null,
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: stack === "Y" ? "normal" : "",
        pointWidth: 30,
      },
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: stack === "Y" ? "normal" : "",
        pointWidth: 30,
      },
    },
    credits: { enabled: false },
    tooltip: {
      style: {
        fontSize: "1.4rem",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
    xAxis: {},
    yAxis: {},
    legend: {
      enabled: false,
      itemStyle: {
        fontSize: "1.4rem",
        fontFamily: "AppleSDGothicNeoRegular",
      },
    },
    series: [], // 데이터가 처음엔 비어았다.
  };

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    if (data !== null && data !== undefined && data.length > 0) {
      let tempSeries = [];
      let tempSeriesData = [];
      let tempSeriesDataBest = [];
      let tempSeriesDataWorst = [];
      let tempCategoriesData = [];
      let tempAssignData = [];

      data.map((data, i) => {
        if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
          tempCategoriesData.push(data.sel_NM);
        }
        if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
          tempAssignData.push(data.assign);
        }
      });

      var vName = "";
      tempAssignData.map((dd, ii) => {
        tempSeriesData = [];

        data
          .filter((x) => x.assign === dd.replace("위", ""))
          //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
          .map((data, i) => {
            tempSeriesData.push({
              name: data.sel_NM,
              y: data.rate ? parseFloat(data.rate) : 0,
              dataLabels: {
                shadow: false,
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
              },
              color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
            });
          });
        tempSeries.push({
          name: dd,
          data: tempSeriesData,
        });
      });

      setOptions({
        ...initialOptions,
        chart: {
          type: type,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          animation: false,
          height: 400,
        },
        exporting: {
          scale: 2,
          enabled: false,
          filename: qst + "_" + title,
          chartOptions: {
            chart: {
              spacing: [30, 15, 30, 15],
            },
            xAxis: {
              labels: {
                style: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
            },
            legend: {
              itemStyle: {
                fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                fontSize: "12px",
              },
            },
            plotOptions: {
              series: {
                dataLabels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                  inside: true,
                  style: {
                    textShadow: 0,
                    textOutline: 0,
                    color: "#000000",
                  },
                },
                stacking: stack === "Y" ? "normal" : "",
                pointWidth:
                  500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
              },
            },
          },
        },
        series: tempSeries,
        xAxis: {
          title: { text: null },
          labels: {
            style: {
              fontSize: "1.4rem",
              fontFamily: "AppleSDGothicNeoRegular",
              fontWeight: "bold",
            },
          },
          tickWidth: 0,
          tickLength: 0,
          categories: tempCategoriesData,
        },
        yAxis: {
          gridLineWidth: 0,
          labels: { enabled: false },
          title: { text: null },
        },
        legend: {
          enabled: true,
          itemStyle: {
            fontSize: "1.4rem",
            fontFamily: "AppleSDGothicNeoRegular",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                textShadow: false,
                textOutLine: false,
              },
              format: "{y}",
              border: null,
            },
          },
          column: {
            dataLabels: {
              enabled: true,
              inside: true,
              style: {
                textShadow: 0,
                textOutline: 0,
                color: "#000000",
              },
            },
            stacking: stack === "Y" ? "normal" : "",
            // pointWidth: stack === "Y" ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            pointWidth:
              500 / (tempCategoriesData.length * tempAssignData.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempAssignData.length),
            // pointWidth: 50,
          },
          bar: {
            dataLabels: {
              enabled: true,
              inside: true,
              style: {
                textShadow: 0,
                textOutline: 0,
                color: "#000000",
              },
            },
            stacking: stack === "Y" ? "normal" : "",
            pointWidth: 35,
          },
        },
      });
    }
  }, [data, sort_type, data_num, data_type, sel_cd]);

  return (
    <div className="float-left w-full">
      <div
        className={`float-left h-[4.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor ${
          title !== null && title !== undefined && title !== "" && qst !== null && qst !== undefined && qst !== "" ? "" : "hidden"
        }`}
      >
        <div className="float-left h-full w-[calc(100%-15.0rem)] text-[2.0rem] font-bold leading-[3.0rem]">{title}</div>
        <div className="float-left h-full w-[15.0rem] leading-[6.0rem]">
          <div
            className="float-right ml-[0.5rem] h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_png bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("png");
            }}
          ></div>
          <div
            className="float-right ml-[0.5rem] h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_pdf bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("pdf");
            }}
          ></div>
          <div
            className="float-right ml-[0.5rem] h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_jpeg bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("jpeg");
            }}
          ></div>
          <div
            className="float-right h-[3.0rem] w-[3.0rem] cursor-pointer bg-download_excel bg-[length:100%_100%] bg-center bg-no-repeat"
            onClick={() => {
              downloadChart("excel");
            }}
          ></div>
        </div>
      </div>
      <div className="float-left w-full">
        <HighchartsReact
          id={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + sort_type + "_" + data_num + "_" + data_type}`}
          className="plot-container"
          ref={chart}
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
        />
      </div>
    </div>
  );
};

export default BarColumnChartTotal;
