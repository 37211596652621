import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalysisHeader from "../../components/pc/AnalysisHeader";
import BarColumnChartDA01 from "../../components/pc/BarColumnChartDA01";
import DA01Table from "../../components/pc/DA01Table";
import Loading from "../../components/pc/Loading";
import { amtDict, menuDict } from "../../dict/pc/dict";
import { useGraphDA01AgeGrp } from "../../hooks/useGraphDA01AgeGrp";
import { useGraphDA01Assign } from "../../hooks/useGraphDA01Assign";
import { useGraphDA01Doc } from "../../hooks/useGraphDA01Doc";
import { useGraphDA01Normal } from "../../hooks/useGraphDA01Normal";
import { useGraphDA01Octy } from "../../hooks/useGraphDA01Octy";
import { useGraphDA01Sex } from "../../hooks/useGraphDA01Sex";
import { useGraphDA01WrkYyGrp } from "../../hooks/useGraphDA01WrkYyGrp";
import { usePrjDispCnt } from "../../hooks/usePrjDispCnt";
import { useQstList } from "../../hooks/useQstList";
import { useSelList } from "../../hooks/useSelList";
import { useTableDA01AgeGrp } from "../../hooks/useTableDA01AgeGrp";
import { useTableDA01Assign } from "../../hooks/useTableDA01Assign";
import { useTableDA01Doc } from "../../hooks/useTableDA01Doc";
import { useTableDA01Octy } from "../../hooks/useTableDA01Octy";
import { useTableDA01Sex } from "../../hooks/useTableDA01Sex";
import { useTableDA01WrkYyGrp } from "../../hooks/useTableDA01WrkYyGrp";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const DA01Page = () => {
  const queryClient = useQueryClient();
  const LoginId = useStore((state) => state.LoginId);
  const setMode = useStore((state) => state.setMode);
  const PrjCd = useStore((state) => state.PrjCd);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const [SvyOjtNm, setSvyOjtNm] = useState(
    SvyOjt !== "" && menuDict.filter((x) => x.value === SvyOjt)[0] !== null && menuDict.filter((x) => x.value === SvyOjt)[0] !== undefined
      ? menuDict.filter((x) => x.value === SvyOjt)[0].label
      : ""
  );
  const Pick = useStore((state) => state.Pick);
  const HspNm = useStore((state) => state.HspNm);
  const [loading, setLoading] = useState(false);
  const [RadioNumCheck, setRadioNumCheck] = useState("1");
  const [RadioTypeCheck, setRadioTypeCheck] = useState("All");
  const [Sel, setSel] = useState([{}]);
  const [Qst, setQst] = useState("");
  const [Keyword, setKeyword] = useState("");
  const [QstCd, setQstCd] = useState(Pick.qst_cd);
  const [CntYn, setCntYn] = useState("");
  const [DeepAnaCd, setDeepAnaCd] = useState("");
  const [SortType, setSortType] = useState(Pick.sort_type);
  const [GraphSet, setGraphSet] = useState([{}]);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const navigate = useNavigate();

  const { isPrjDispCntLoading, isPrjDispCntError, PrjDispCntData, isPrjDispCntSuccess, PrjDispCntRefetch } = usePrjDispCnt(PrjCd, SvyOjt);

  const { isQstListLoading, isQstListError, QstListData, isQstListSuccess, QstListRefetch } = useQstList(PrjCd, SvyOjt);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(PrjCd, SvyOjt, QstCd);

  useEffect(() => {
    if (LoginId === null || LoginId === undefined || LoginId === "") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (PrjDispCntData) {
      var vRadio = [{}];
      vRadio.push({ value: "All", label: "전체" });
      vRadio.push({ value: "Normal", label: "기본" });

      PrjDispCntData.map((dd, i) => {
        if (dd.octy_CNT !== "0") {
          vRadio.push({ value: "Octy", label: "직종" });
          vRadio.push({ value: "Doc", label: "의사" });
        }
        if (dd.assign_YN_CNT !== "0" && dd.assign_YN_CNT !== dd.member_CNT) {
          vRadio.push({ value: "Assign", label: "보직" });
        }
        if (dd.age_GRP_CNT !== "0") {
          vRadio.push({ value: "AgeGrp", label: "연령" });
        }
        if (dd.wrk_YY_GRP_CNT !== "0") {
          vRadio.push({ value: "WrkYyGrp", label: "연차" });
        }
        if (dd.sex_CNT !== "0") {
          vRadio.push({ value: "Sex", label: "성별" });
        }
      });
      vRadio = vRadio.filter((item1, idx1) => {
        return (
          vRadio.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });
      vRadio = vRadio.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
      setGraphSet(vRadio);
    }
  }, [QstCd, PrjDispCntData]);

  // useEffect(() => {
  //   if (QstListData === undefined || QstListData === null) {
  //     QstListRefetch();
  //   }
  // }, [QstListData]);

  useEffect(() => {
    if (SelListData) {
      var vCheck = [{}];

      SelListData.map((dd, i) => {
        vCheck.push({ value: dd.sel_CD, label: dd.sel_NM });
      });

      vCheck = vCheck.filter((item1, idx1) => {
        return (
          vCheck.findIndex((item2, idx2) => {
            return item1.value === item2.value;
          }) === idx1
        );
      });

      vCheck = vCheck.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSel([...vCheck]);
    }
  }, [SelListData]);

  useEffect(() => {
    var vQst = "";
    var vKeyword = "";
    var vCntYn = "";
    if (QstListData !== null && QstListData !== undefined && QstListData.filter((x) => x.qst_CD === Pick.qst_cd).length > 0) {
      vQst = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].qst;
      vKeyword = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].keyword;
      vCntYn = QstListData.filter((x) => x.qst_CD === Pick.qst_cd)[0].cnt_YN;
    }
    setQst(vQst);
    setKeyword(vKeyword);
    setCntYn(vCntYn);
    setQstCd(Pick.qst_cd);
  }, [Pick]);

  useEffect(() => {}, [RadioTypeCheck]);

  useEffect(() => {}, [RadioNumCheck]);

  const changeNum = (text) => {
    setRadioNumCheck(text);
  };

  const changeType = (text) => {
    setRadioTypeCheck(text);
  };

  const { isGraphDA01NormalLoading, isGraphDA01NormalError, GraphDA01NormalData, isGraphDA01NormalSuccess, GraphDA01NormalRefetch } =
    useGraphDA01Normal(PrjCd, SvyOjt, QstCd, "", "", "OCTY", SortType, startDate, endDate);

  const { isGraphDA01AssignLoading, isGraphDA01AssignError, GraphDA01AssignData, isGraphDA01AssignSuccess, GraphDA01AssignRefetch } =
    useGraphDA01Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isGraphDA01DocLoading, isGraphDA01DocError, GraphDA01DocData, isGraphDA01DocSuccess, GraphDA01DocRefetch } = useGraphDA01Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA01OctyLoading, isGraphDA01OctyError, GraphDA01OctyData, isGraphDA01OctySuccess, GraphDA01OctyRefetch } = useGraphDA01Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isGraphDA01WrkYyGrpLoading, isGraphDA01WrkYyGrpError, GraphDA01WrkYyGrpData, isGraphDA01WrkYyGrpSuccess, GraphDA01WrkYyGrpRefetch } =
    useGraphDA01WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  const { isGraphDA01AgeGrpLoading, isGraphDA01AgeGrpError, GraphDA01AgeGrpData, isGraphDA01AgeGrpSuccess, GraphDA01AgeGrpRefetch } =
    useGraphDA01AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isGraphDA01SexLoading, isGraphDA01SexError, GraphDA01SexData, isGraphDA01SexSuccess, GraphDA01SexRefetch } = useGraphDA01Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA01AgeGrpLoading, isTableDA01AgeGrpError, TableDA01AgeGrpData, isTableDA01AgeGrpSuccess, TableDA01AgeGrpRefetch } =
    useTableDA01AgeGrp(PrjCd, SvyOjt, QstCd, "", "", "AGE_GRP", SortType, startDate, endDate);

  const { isTableDA01AssignLoading, isTableDA01AssignError, TableDA01AssignData, isTableDA01AssignSuccess, TableDA01AssignRefetch } =
    useTableDA01Assign(PrjCd, SvyOjt, QstCd, "", "", "ASSIGN_YN", SortType, startDate, endDate);

  const { isTableDA01DocLoading, isTableDA01DocError, TableDA01DocData, isTableDA01DocSuccess, TableDA01DocRefetch } = useTableDA01Doc(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "ASSIGN_YN",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA01OctyLoading, isTableDA01OctyError, TableDA01OctyData, isTableDA01OctySuccess, TableDA01OctyRefetch } = useTableDA01Octy(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "OCTY",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA01SexLoading, isTableDA01SexError, TableDA01SexData, isTableDA01SexSuccess, TableDA01SexRefetch } = useTableDA01Sex(
    PrjCd,
    SvyOjt,
    QstCd,
    "",
    "",
    "SEX",
    SortType,
    startDate,
    endDate
  );

  const { isTableDA01WrkYyGrpLoading, isTableDA01WrkYyGrpError, TableDA01WrkYyGrpData, isTableDA01WrkYyGrpSuccess, TableDA01WrkYyGrpRefetch } =
    useTableDA01WrkYyGrp(PrjCd, SvyOjt, QstCd, "", "", "WRK_YY_GRP", SortType, startDate, endDate);

  useEffect(() => {
    if (RadioTypeCheck === "All") {
      if (Object.keys(GraphSet[0]).length > 0) {
        let graphList = GraphSet.map((el) => el.value);

        if (graphList.includes("Normal")) {
          GraphDA01NormalRefetch();
        }

        if (graphList.includes("Octy")) {
          GraphDA01OctyRefetch();
        }

        if (graphList.includes("Doc")) {
          GraphDA01DocRefetch();
        }

        if (graphList.includes("Assign")) {
          GraphDA01AssignRefetch();
        }

        if (graphList.includes("WrkYyGrp")) {
          GraphDA01WrkYyGrpRefetch();
        }

        if (graphList.includes("Sex")) {
          GraphDA01SexRefetch();
        }

        if (graphList.includes("AgeGrp")) {
          GraphDA01AgeGrpRefetch();
        }
      }

      if (Object.keys(GraphSet[0]).length === 0) {
        PrjDispCntRefetch();
        SelListRefetch();
        QstListRefetch();
      }
    }

    if (RadioTypeCheck === "AgeGrp") {
      GraphDA01AgeGrpRefetch();
      TableDA01AgeGrpRefetch();
    } else if (RadioTypeCheck === "Sex") {
      GraphDA01SexRefetch();
      TableDA01SexRefetch();
    } else if (RadioTypeCheck === "Assign") {
      GraphDA01AssignRefetch();
      TableDA01AssignRefetch();
    } else if (RadioTypeCheck === "Doc") {
      GraphDA01DocRefetch();
      TableDA01DocRefetch();
    } else if (RadioTypeCheck === "Octy") {
      GraphDA01OctyRefetch();
      TableDA01OctyRefetch();
    } else if (RadioTypeCheck === "WrkYyGrp") {
      GraphDA01WrkYyGrpRefetch();
      TableDA01WrkYyGrpRefetch();
    }
  }, [QstCd, RadioNumCheck, RadioTypeCheck, startDate, endDate, GraphSet]);

  // useEffect(() => {
  //   if (!isGraphDA01NormalSuccess || GraphDA01NormalData === null || GraphDA01NormalData === undefined || GraphDA01NormalData.length === 0) {
  //     GraphDA01NormalRefetch();
  //   }
  // }, [GraphDA01NormalData, isGraphDA01NormalSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA01AssignSuccess || GraphDA01AssignData === null || GraphDA01AssignData === undefined || GraphDA01AssignData.length === 0) {
  //     GraphDA01AssignRefetch();
  //   }
  // }, [GraphDA01AssignData, isGraphDA01AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA01DocSuccess || GraphDA01DocData === null || GraphDA01DocData === undefined || GraphDA01DocData.length === 0) {
  //     GraphDA01DocRefetch();
  //   }
  // }, [GraphDA01DocData, isGraphDA01DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA01OctySuccess || GraphDA01OctyData === null || GraphDA01OctyData === undefined || GraphDA01OctyData.length === 0) {
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [GraphDA01OctyData, isGraphDA01OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA01WrkYyGrpSuccess || GraphDA01WrkYyGrpData === null || GraphDA01WrkYyGrpData === undefined || GraphDA01WrkYyGrpData.length === 0) {
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [GraphDA01WrkYyGrpData, isGraphDA01WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA01AgeGrpSuccess || GraphDA01AgeGrpData === null || GraphDA01AgeGrpData === undefined || GraphDA01AgeGrpData.length === 0) {
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [GraphDA01AgeGrpData, isGraphDA01AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isGraphDA01SexSuccess || GraphDA01SexData === null || GraphDA01SexData === undefined || GraphDA01SexData.length === 0) {
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [GraphDA01SexData, isGraphDA01SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA01AgeGrpSuccess || TableDA01AgeGrpData === null || TableDA01AgeGrpData === undefined || TableDA01AgeGrpData.length === 0) {
  //     TableDA01AgeGrpRefetch();
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [TableDA01AgeGrpData, isTableDA01AgeGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA01AssignSuccess || TableDA01AssignData === null || TableDA01AssignData === undefined || TableDA01AssignData.length === 0) {
  //     TableDA01AssignRefetch();
  //     TableDA01AgeGrpRefetch();
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [TableDA01AssignData, isTableDA01AssignSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA01DocSuccess || TableDA01DocData === null || TableDA01DocData === undefined || TableDA01DocData.length === 0) {
  //     TableDA01DocRefetch();
  //     TableDA01AssignRefetch();
  //     TableDA01AgeGrpRefetch();
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [TableDA01DocData, isTableDA01DocSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA01OctySuccess || TableDA01OctyData === null || TableDA01OctyData === undefined || TableDA01OctyData.length === 0) {
  //     TableDA01OctyRefetch();
  //     TableDA01DocRefetch();
  //     TableDA01AssignRefetch();
  //     TableDA01AgeGrpRefetch();
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [TableDA01OctyData, isTableDA01OctySuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA01SexSuccess || TableDA01SexData === null || TableDA01SexData === undefined || TableDA01SexData.length === 0) {
  //     TableDA01SexRefetch();
  //     TableDA01OctyRefetch();
  //     TableDA01DocRefetch();
  //     TableDA01AssignRefetch();
  //     TableDA01AgeGrpRefetch();
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [TableDA01SexData, isTableDA01SexSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  // useEffect(() => {
  //   if (!isTableDA01WrkYyGrpSuccess || TableDA01WrkYyGrpData === null || TableDA01WrkYyGrpData === undefined || TableDA01WrkYyGrpData.length === 0) {
  //     TableDA01WrkYyGrpRefetch();
  //     TableDA01SexRefetch();
  //     TableDA01OctyRefetch();
  //     TableDA01DocRefetch();
  //     TableDA01AssignRefetch();
  //     TableDA01AgeGrpRefetch();
  //     GraphDA01SexRefetch();
  //     GraphDA01AgeGrpRefetch();
  //     GraphDA01WrkYyGrpRefetch();
  //     GraphDA01OctyRefetch();
  //     GraphDA01DocRefetch();
  //   }
  // }, [TableDA01WrkYyGrpData, isTableDA01WrkYyGrpSuccess, PrjCd, SvyOjt, QstCd, SortType, RadioNumCheck, RadioTypeCheck, startDate, endDate]);

  return (
    <div className="float-left w-full bg-white pb-[1.8rem] pl-[2.8rem] pr-[2.8rem] pt-[1.8rem]">
      {loading ? <Loading /> : null}
      <div className="float-left w-full">
        <AnalysisHeader
          key={"AnalysisHeader"}
          id={"AnalysisHeader"}
          type={PrjDispCntData}
          num={amtDict}
          qst={Qst}
          deepAnaCd={"DA01"}
          keyword={Keyword}
          keywordDeepAnaCd={""}
          changeNum={changeNum}
          changeType={changeType}
          changeDA06={() => {}}
          changeDA07DA11={() => {}}
          changeDA08={() => {}}
          changeDA09={() => {}}
          changeDEP={() => {}}
          selectedNum={RadioNumCheck}
          selectedType={RadioTypeCheck}
          selectedDA06={""}
          selectedDA07DA11={""}
          selectedDA08={""}
          selectedDA09={""}
          selectedDEP={""}
          sel={Sel}
          changeFirstCheck={() => {}}
          changeSecondCheck={() => {}}
          selectedFirstCheck={[]}
          selectedSecondCheck={[]}
        />
      </div>
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck === "All" && (
        <div className="float-left w-full">
          {GraphSet.map((data, i) => {
            if (data.value !== "All") {
              var vTitle = "";
              var vName = "";
              var vStack = "N";
              var vWhere = "";
              var vType = "bar";
              var vData;

              if (SvyOjt === "MEM") {
                vWhere = "OCTY";
              } else {
                vWhere = "AGE_GRP";
              }

              if (data.value === "Normal") {
                vTitle = SvyOjtNm + " 항목별 비교";
              } else if (data.value === "Assign") {
                vType = "column";
                vTitle = "보직여부 응답률";
              } else if (data.value === "Doc") {
                vType = "column";
                vTitle = "의사직, 구성원 응답률";
              } else if (data.value === "Octy") {
                vType = "column";
                vTitle = "직종별 응답률";
              } else if (data.value === "WrkYyGrp") {
                vType = "column";
                vTitle = "연차별 응답률";
              } else if (data.value === "Sex") {
                vType = "column";
                vTitle = "성별 응답률";
              } else if (data.value === "AgeGrp") {
                vType = "column";
                vTitle = "연령대별 응답률";
              }

              if (data.value === "Normal") {
                vName = "basic";
                vData = GraphDA01NormalData;
              } else if (data.value === "Assign") {
                vName = "assign";
                vData = GraphDA01AssignData;
              } else if (data.value === "Doc") {
                vName = "doc";
                vData = GraphDA01DocData;
              } else if (data.value === "Octy") {
                vName = "octy";
                vStack = "Y";
                vData = GraphDA01OctyData;
              } else if (data.value === "WrkYyGrp") {
                vName = "wrk";
                vStack = "Y";
                vWhere = "WRK_YY_GRP";
                vData = GraphDA01WrkYyGrpData;
              } else if (data.value === "Sex") {
                vName = "sex";
                vWhere = "SEX";
                vData = GraphDA01SexData;
              } else if (data.value === "AgeGrp") {
                vName = "age";
                vStack = "Y";
                vWhere = "AGE_GRP";
                vData = GraphDA01AgeGrpData;
              }

              return (
                <div
                  key={`normal_${i}`}
                  className={`float-left ${
                    i === 1 ? "" : "mt-[2.0rem]"
                  } w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]`}
                >
                  <BarColumnChartDA01
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
      {PrjDispCntData !== null && PrjDispCntData !== undefined && RadioTypeCheck !== "All" && (
        <div className="float-left w-full">
          {GraphSet.filter((x) => x.value === RadioTypeCheck).map((data, i) => {
            var vTitle = "";
            var vName = "";
            var vStack = "N";
            var vWhere = "";
            var vType = "column";
            var vData;
            var vTdata;

            if (SvyOjt === "MEM") {
              vWhere = "OCTY";
            } else {
              vWhere = "AGE_GRP";
            }

            if (data.value === "Normal") {
              vTitle = SvyOjtNm + " 항목별 비교";
            } else if (data.value === "Assign") {
              vType = "column";
              vTitle = "보직여부 응답률";
            } else if (data.value === "Doc") {
              vType = "column";
              vTitle = "의사직, 구성원 응답률";
            } else if (data.value === "Octy") {
              vType = "column";
              vTitle = "직종별 응답률";
            } else if (data.value === "WrkYyGrp") {
              vType = "column";
              vTitle = "연차별 응답률";
            } else if (data.value === "Sex") {
              vType = "column";
              vTitle = "성별 응답률";
            } else if (data.value === "AgeGrp") {
              vType = "column";
              vTitle = "연령대별 응답률";
            }

            if (data.value === "Normal") {
              vName = "basic";
              vData = GraphDA01NormalData;
            } else if (data.value === "Assign") {
              vName = "assign";
              vData = GraphDA01AssignData;
              vTdata = TableDA01AssignData;
            } else if (data.value === "Doc") {
              vName = "doc";
              vData = GraphDA01DocData;
              vTdata = TableDA01DocData;
            } else if (data.value === "Octy") {
              vName = "octy";
              vStack = "Y";
              vData = GraphDA01OctyData;
              vTdata = TableDA01OctyData;
            } else if (data.value === "WrkYyGrp") {
              vName = "wrk";
              vStack = "Y";
              vWhere = "WRK_YY_GRP";
              vData = GraphDA01WrkYyGrpData;
              vTdata = TableDA01WrkYyGrpData;
            } else if (data.value === "Sex") {
              vName = "sex";
              vWhere = "SEX";
              vData = GraphDA01SexData;
              vTdata = TableDA01SexData;
            } else if (data.value === "AgeGrp") {
              vName = "age";
              vStack = "Y";
              vWhere = "AGE_GRP";
              vData = GraphDA01AgeGrpData;
              vTdata = TableDA01AgeGrpData;
            }

            return (
              <div key={`normal_${PrjCd}_${SvyOjt}_${Pick.qst_CD}_${RadioTypeCheck}`} className="float-left w-full">
                <div className="float-left w-full">
                  <DA01Table
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Tdata={vTdata}
                  />
                </div>
                <div className="float-left mt-[2.0rem] w-full border-[0.1rem] border-tableBorderColor pb-[1.5rem] pl-[1.5rem] pr-[1.5rem] pt-[1.0rem]">
                  <BarColumnChartDA01
                    hsp_nm={HspNm}
                    prj_cd={PrjCd}
                    svy_ojt={SvyOjt}
                    qst_cd={Pick.qst_cd}
                    sel_cd={""}
                    string_value={""}
                    where={vWhere}
                    sort_type={SortType}
                    data_num={RadioNumCheck}
                    data_type={RadioTypeCheck}
                    stack={vStack}
                    type={vType}
                    title={vTitle}
                    qst={Qst}
                    name={vName}
                    Gdata={vData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="float-left w-full"></div>
    </div>
  );
};

export default DA01Page;
