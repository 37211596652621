import { useMutation, useQueryClient } from "@tanstack/react-query";
import postGptRes from "../api/postGptRes";
import { useGptState } from "../store/mobile/store";

const usePostGptRes = () => {
  const queryClient = useQueryClient();
  const setGptState = useGptState((state) => state.setGptState);

  return useMutation(postGptRes, {
    onSuccess: (res) => {
      // console.log(res);
      queryClient.invalidateQueries(["gpt"]);
    },
    onError: (err) => {
      setGptState(400);
      console.log(err);
    },
  });
};

export default usePostGptRes;
