const Checkbox = ({ children, disabled, checked, onChange }) => {
  return (
    <label className="mr-32 flex flex-row items-center justify-start">
      <input
        className="mb-1 mr-4 border border-blue1Color bg-blue1Color"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={({ target: { checked } }) => onChange(checked)}
      />
      <div className="h-fit w-[140px]">{children}</div>
    </label>
  );
};

export default Checkbox;
