const AdminQstResultPreviewRadio = ({ projectType, handleClickRadio, adminProjectList }) => {
  const radioOptions = adminProjectList.map((item) => {
    const data1 = ["구성원", "내원고객", "지역주민", "고객만족", "환자경험"];
    const data2 = ["MEM", "VST", "LRES", "CS", "RS"];

    const target = data2.indexOf(item);

    return {
      id: item,
      label: data1[target],
    };
  });

  console.log("radioOptions,radioOptions", radioOptions, adminProjectList);

  return (
    <div className="z-2 absolute right-10 top-[68px]">
      <div className="float-left flex h-[50px] w-auto flex-row items-center justify-center text-[14px]">
        {radioOptions.map((option) => (
          <div key={option.id} className="float-left mx-2 flex h-[50px] flex-row items-center justify-start">
            <input
              type="radio"
              checked={projectType === option.id}
              name="admin_qst_result_radio"
              id={option.id}
              value={option.id}
              className="border accent-orangeColor"
              onClick={() => handleClickRadio(option.id)}
              onChange={() => {}}
            />
            <label htmlFor={option.id} className="ml-2 font-bold">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminQstResultPreviewRadio;
