import { useQuery } from "@tanstack/react-query";
import getAdminDetailQst from "../api/getAdminDetailQst";

const useGetAdminDetailQst = (prj_cd, qst_cd, svy_ojt) => {
  return useQuery(["adminDetailQst"], () => getAdminDetailQst(prj_cd, qst_cd, svy_ojt), {
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminDetailQst;
