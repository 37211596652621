const VstComp = () => {
  return (
    <div className="my-4 flex h-fit w-full flex-row items-center justify-start">
      <img src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png" alt="menu_dot" className="mr-3 w-[8px]" />
      <div className=" flex h-fit w-fit flex-col items-center justify-center py-3 font-SDGothic text-[16px] font-bold text-selectTextColor/90">
        내원고객
      </div>
    </div>
  );
};

export default VstComp;
