import axios from "axios";
import "moment/locale/ko";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { AiOutlineCalendar } from "react-icons/ai";
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loading from "../../components/pc/Loading";
import { menuCSDict, menuDict } from "../../dict/pc/dict";
import { useCSUser } from "../../hooks/useCSUser";
import useGetAdminAuth from "../../hooks/useGetAdminAuth";
import { useSvyOjtActive } from "../../hooks/useSvyOjtActive";
import { useTotal } from "../../hooks/useTotal";
import { useUser } from "../../hooks/useUser";
import { usePeriodState } from "../../store/mobile/store";
import useStore from "../../store/pc/store";

const Header = () => {
  let params = window.location.pathname;

  // const stDateRef = useRef(null);
  // const edDateRef = useRef(null);

  const startDate = usePeriodState((state) => state.stPeriod);
  const setStartDate = usePeriodState((state) => state.setStPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);
  const setEndDate = usePeriodState((state) => state.setEdPeriod);

  const [stViewMode, setStViewMode] = useState("month");
  const [edViewMode, setEdViewMode] = useState("month");

  const [visiblePr, setVisiblepr] = useState(false);

  const [loading, setLoading] = useState(false);
  const SvyOjt = useStore((state) => state.SvyOjt);
  const LoginId = useStore((state) => state.LoginId);
  const Mode = useStore((state) => state.Mode);
  const Year = useStore((state) => state.Year);
  const HspCd = useStore((state) => state.HspCd);
  const HspNm = useStore((state) => state.HspNm);
  const PrjCd = useStore((state) => state.PrjCd);
  const AnalysisType = useStore((state) => state.AnalysisType);
  const QstHspSelCd = useStore((state) => state.QstHspSelCd);
  const Pick = useStore((state) => state.Pick);
  const setSvyOjt = useStore((state) => state.setSvyOjt);
  const setLoginId = useStore((state) => state.setLoginId);
  const setMode = useStore((state) => state.setMode);
  const setYear = useStore((state) => state.setYear);
  const setHspCd = useStore((state) => state.setHspCd);
  const setHspNm = useStore((state) => state.setHspNm);
  const setPrjCd = useStore((state) => state.setPrjCd);
  const setAnalysisType = useStore((state) => state.setAnalysisType);
  const setQstHspSelCd = useStore((state) => state.setQstHspSelCd);
  const setPick = useStore((state) => state.setPick);

  const [YearArr, setYearArr] = useState([{}]);
  const [HspCdArr, setHspCdArr] = useState([{}]);
  // 전역상태로 변경
  // const [PrjCdArr, setPrjCdArr] = useState([{}]);
  const PrjCdArr = useStore((state) => state.PrjCdArr);
  const setPrjCdArr = useStore((state) => state.setPrjCdArr);
  const [SvyOjtActiveArr, setSvyOjtActiveArr] = useState([{}]);
  const [Total, setTotal] = useState("");

  const navigate = useNavigate();

  const { isUserLoading, isUserError, UserData, isUserSuccess, UserRefetch } = useUser(LoginId);
  const { isCSUserLoading, isCSUserError, CSUserData, isCSUserSuccess, CSUserRefetch } = useCSUser(LoginId);
  const { data: AdminAuthData, refetch: AdminAuthRefetch } = useGetAdminAuth(LoginId);

  useEffect(() => {
    if (AnalysisType === "scan") {
      UserRefetch();
    } else if (AnalysisType === "csscan") {
      CSUserRefetch();
    }
  }, []);
  // useEffect(() => {
  //   if (!isUserSuccess || UserData === null || UserData === undefined || UserData.length === 0) {
  //     UserRefetch();
  //   }
  // }, [isUserSuccess]);

  // useEffect(() => {
  //   if (!isCSUserSuccess || CSUserData === null || CSUserData === undefined || CSUserData.length === 0) {
  //     CSUserRefetch();
  //   }
  // }, [isCSUserSuccess]);

  useEffect(() => {
    setLoading(true);
    if (AnalysisType === "scan") {
      if (UserData !== null && UserData !== undefined && UserData.length > 0) {
        setLoading(false);
        var vPrdYy = [];
        UserData.map((dd, i) => {
          vPrdYy.push(dd.prd_YY);
        });
        vPrdYy = vPrdYy.filter((v, i) => vPrdYy.indexOf(v) === i);
        vPrdYy = vPrdYy.filter((x) => x !== null && x !== undefined && x !== "");

        var vYearJson = [{}];
        vYearJson.push({ value: "ALL", label: "전체" });
        vPrdYy.map((data, i) => {
          vYearJson.push({ value: data, label: data + "년" });
        });
        vYearJson = vYearJson.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

        setYearArr([...vYearJson]);
      }
    } else if (AnalysisType === "csscan") {
      if (CSUserData !== null && CSUserData !== undefined && CSUserData.length > 0) {
        setLoading(false);
        var vPrdYy = [];
        CSUserData.map((dd, i) => {
          vPrdYy.push(dd.prd_YY);
        });
        vPrdYy = vPrdYy.filter((v, i) => vPrdYy.indexOf(v) === i);
        vPrdYy = vPrdYy.filter((x) => x !== null && x !== undefined && x !== "");

        var vYearJson = [{}];
        vYearJson.push({ value: "ALL", label: "전체" });
        vPrdYy.map((data, i) => {
          vYearJson.push({ value: data, label: data + "년" });
        });
        vYearJson = vYearJson.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

        setYearArr([...vYearJson]);
      }
    }
  }, [UserData, CSUserData]);

  useEffect(() => {
    var vYear = "";
    if (YearArr && YearArr.length > 0 && YearArr[0]) {
      if (Year === "" || Year === null || Year === undefined) {
        vYear = YearArr[0].value;
        if (vYear !== undefined && vYear !== null && vYear !== "") {
          setYear(vYear);
        }
      }
    }
  }, [YearArr]);

  useEffect(() => {
    if (AnalysisType === "csscan") {
      setStartDate(moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD"));
      setEndDate(`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`);
    } else {
      // setStartDate(moment(new Date(new Date().setFullYear(new Date().getFullYear() - 100))).format("YYYY-MM-DD"));
    }

    var vHspCd = [{}];

    if (AnalysisType === "scan") {
      if (UserData !== null && UserData !== undefined && UserData.length > 0) {
        if (Year === "ALL") {
          UserData.map((dd, i) => {
            vHspCd.push({ value: dd.hsp_CD, label: dd.hsp_NM });
          });
        } else {
          UserData.filter((x) => x.prd_YY === Year).map((dd, i) => {
            vHspCd.push({ value: dd.hsp_CD, label: dd.hsp_NM });
          });
        }

        vHspCd = vHspCd.filter((item1, idx1) => {
          return (
            vHspCd.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vHspCd = vHspCd.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setHspCdArr([...vHspCd]);
      }
    } else if (AnalysisType === "csscan") {
      if (CSUserData !== null && CSUserData !== undefined && CSUserData.length > 0) {
        if (Year === "ALL") {
          CSUserData.map((dd, i) => {
            vHspCd.push({ value: dd.hsp_CD, label: dd.hsp_NM });
          });
        } else {
          CSUserData.filter((x) => x.prd_YY === Year).map((dd, i) => {
            vHspCd.push({ value: dd.hsp_CD, label: dd.hsp_NM });
          });
        }

        vHspCd = vHspCd.filter((item1, idx1) => {
          return (
            vHspCd.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vHspCd = vHspCd.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setHspCdArr([...vHspCd]);
      }
    }
  }, [Year, isUserSuccess, isCSUserSuccess]);

  useEffect(() => {
    var vHspCd = "";
    if (HspCdArr !== null && HspCdArr !== undefined && HspCdArr.length > 0) {
      vHspCd = HspCdArr[0].value;
      if (vHspCd !== undefined && vHspCd !== null && vHspCd !== "") {
        setHspCd(vHspCd);
      }
    }
  }, [HspCdArr]);

  useEffect(() => {
    if (AnalysisType === "csscan") {
      setStartDate(moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD"));
      setEndDate(`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`);
    } else {
      // setStartDate(moment(new Date(new Date().setFullYear(new Date().getFullYear() - 100))).format("YYYY-MM-DD"));
    }

    var vHspNm = "";

    if (AnalysisType === "scan") {
      if (UserData !== null && UserData !== undefined && UserData.length > 0) {
        if (HspCd !== "" && HspCd !== null && HspCd !== undefined) {
          vHspNm = UserData.filter((x) => x.hsp_CD === HspCd)[0].hsp_NM;
          if (vHspNm !== undefined && vHspNm !== null && vHspNm !== "") {
            setHspNm(vHspNm);
          }
        }
      } else {
        UserRefetch();
      }
    } else if (AnalysisType === "csscan") {
      if (CSUserData !== null && CSUserData !== undefined && CSUserData.length > 0) {
        if (HspCd !== "" && HspCd !== null && HspCd !== undefined) {
          vHspNm = CSUserData.filter((x) => x.hsp_CD === HspCd)[0].hsp_NM;
          if (vHspNm !== undefined && vHspNm !== null && vHspNm !== "") {
            setHspNm(vHspNm);
          }
        }
      } else {
        CSUserRefetch();
      }
    }
  }, [HspCd]);

  useEffect(() => {
    var vPrjCd = [{}];

    if (AnalysisType === "scan") {
      if (UserData !== null && UserData !== undefined && UserData.length > 0) {
        if (Year === "ALL") {
          UserData.filter((x) => x.hsp_CD === HspCd).map((dd, i) => {
            vPrjCd.push({ value: dd.prj_CD, label: dd.prj_NM });
          });
        } else {
          UserData.filter((x) => x.hsp_CD === HspCd && x.prd_YY === Year).map((dd, i) => {
            vPrjCd.push({ value: dd.prj_CD, label: dd.prj_NM });
          });
        }

        vPrjCd = vPrjCd.filter((item1, idx1) => {
          return (
            vPrjCd.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vPrjCd = vPrjCd.filter((x) => x.value !== null && x.value !== undefined && x.value !== "").sort((a, b) => b.value - a.value);

        // console.log(vPrjCd);
        setPrjCdArr([...vPrjCd]);
      }
    } else if (AnalysisType === "csscan") {
      if (CSUserData !== null && CSUserData !== undefined && CSUserData.length > 0) {
        if (Year === "ALL") {
          CSUserData.filter((x) => x.hsp_CD === HspCd).map((dd, i) => {
            vPrjCd.push({ value: dd.prj_CD, label: dd.prj_NM });
          });
        } else {
          CSUserData.filter((x) => x.hsp_CD === HspCd && x.prd_YY === Year).map((dd, i) => {
            vPrjCd.push({ value: dd.prj_CD, label: dd.prj_NM });
          });
        }

        vPrjCd = vPrjCd.filter((item1, idx1) => {
          return (
            vPrjCd.findIndex((item2, idx2) => {
              return item1.value === item2.value;
            }) === idx1
          );
        });
        vPrjCd = vPrjCd.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");
        setPrjCdArr([...vPrjCd]);
      }
    }
  }, [HspNm, isUserSuccess, isCSUserSuccess]);

  useEffect(() => {
    var vPrjCd = "";
    var vPrjCd2 = PrjCd;
    if (PrjCdArr !== null && PrjCdArr !== undefined && PrjCdArr.length > 0) {
      vPrjCd = PrjCdArr[0].value;
      if (vPrjCd !== undefined && vPrjCd !== null && vPrjCd !== "") {
        setPrjCd(vPrjCd);
      }
    } else {
    }
  }, [PrjCdArr]);

  const { isSvyOjtActiveLoading, isSvyOjtActiveError, SvyOjtActiveData, isSvyOjtActiveSuccess, SvyOjtActiveRefetch } = useSvyOjtActive(PrjCd);

  useEffect(() => {
    if (AnalysisType === "scan") {
      if (PrjCd !== null && PrjCd !== undefined && PrjCd !== "" && UserData) {
        var vSelCd =
          UserData.filter((x) => x.prj_CD === PrjCd) && UserData.filter((x) => x.prj_CD === PrjCd).length > 0
            ? UserData.filter((x) => x.prj_CD === PrjCd)[0].qst_HSP_SEL_CD
            : "";
        if (vSelCd !== undefined && vSelCd !== null && vSelCd !== "") {
          setQstHspSelCd(vSelCd);
        }
      }
    } else if (AnalysisType === "csscan") {
      if (PrjCd !== null && PrjCd !== undefined && PrjCd !== "" && CSUserData) {
        var vSelCd =
          CSUserData.filter((x) => x.prj_CD === PrjCd) && CSUserData.filter((x) => x.prj_CD === PrjCd).length > 0
            ? CSUserData.filter((x) => x.prj_CD === PrjCd)[0].qst_HSP_SEL_CD
            : "";
        if (vSelCd !== undefined && vSelCd !== null && vSelCd !== "") {
          setQstHspSelCd(vSelCd);
        }
      }
    }

    setPick({
      menu_cate: "",
      menu_cate2: "",
      qst_cd: "",
      deep_ana_cd: "",
      crss_qst_cd: "",
      sort_type: "",
      cnt_yn: "",
      grp_dvs: "",
    });

    if (isSvyOjtActiveSuccess) {
      if (Mode !== "admin") {
        navigate(AnalysisType === "csscan" ? `/Summary` : `/Total`);
      }
    }
  }, [PrjCd, isSvyOjtActiveSuccess]);

  useEffect(() => {
    if (AnalysisType === "csscan") {
      setStartDate(moment(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`).format("YYYY-MM-DD"));
      setEndDate(`${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`);
    } else {
      // setStartDate(moment(new Date(new Date().setFullYear(new Date().getFullYear() - 100))).format("YYYY-MM-DD"));
    }

    var vSvyOjtActive = [{}];
    if (
      PrjCd !== null &&
      PrjCd !== undefined &&
      PrjCd !== "" &&
      isSvyOjtActiveSuccess &&
      SvyOjtActiveData !== null &&
      SvyOjtActiveData !== undefined &&
      SvyOjtActiveData.length > 0
    ) {
      SvyOjtActiveData.map((data, i) => {
        vSvyOjtActive.push({ value: data.comn_CD1, label: data.comn_NM1 });
      });
      vSvyOjtActive = vSvyOjtActive.filter((x) => x.value !== null && x.value !== undefined && x.value !== "");

      setSvyOjtActiveArr([...vSvyOjtActive]);
    } else {
      SvyOjtActiveRefetch();
    }
  }, [PrjCd, isSvyOjtActiveSuccess, SvyOjtActiveData]);

  useEffect(() => {
    var vSvyOjt = "";
    if (SvyOjtActiveArr !== null && SvyOjtActiveArr !== undefined && SvyOjtActiveArr.length > 0) {
      vSvyOjt = SvyOjtActiveArr[0].value;
      if (vSvyOjt !== undefined && vSvyOjt !== null && vSvyOjt !== "") {
        setSvyOjt(vSvyOjt);
      }
    }
  }, [SvyOjtActiveArr]);

  const { isTotalLoading, isTotalError, TotalData, isTotalSuccess, TotalRefetch } = useTotal(PrjCd, SvyOjt, startDate, endDate);

  useEffect(() => {
    if (SvyOjt !== "DEP") {
      TotalRefetch();
    }
  }, [PrjCd, SvyOjt, startDate, endDate]);

  useEffect(() => {
    var vTotal = "";
    if (isTotalSuccess && TotalData !== null && TotalData !== undefined && TotalData.length > 0) {
      vTotal = TotalData[0].comn_CD1;
      if (vTotal !== undefined && vTotal !== null && vTotal !== "") {
        setTotal(vTotal);
      }
    } else {
      setTotal("");
    }
  }, [isTotalSuccess, PrjCd, SvyOjt, startDate, endDate]);

  useEffect(() => {}, [QstHspSelCd]);

  const moveMode = (mode) => {
    setMode(mode);
  };

  const downloadData = () => {
    if (PrjCd && SvyOjt) {
      setLoading(true);
      axios({
        method: "POST",
        url: process.env.REACT_APP_SERVER_ADDRESS + "/Common/RawData",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          prj_cd: PrjCd,
          svy_ojt: SvyOjt,
          startDate: startDate,
          endDate: endDate,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "raw data.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  };

  const handleClickPeriod = () => {
    setVisiblepr(!visiblePr);
  };

  const handleChangeStDate = (e) => {
    setStartDate(moment(e).format("YYYY-MM-DD"));
  };

  const handleChageEdDate = (e) => {
    setEndDate(`${moment(e).format("YYYY-MM-DD")} 23:59:59`);
  };

  const handleClickPrClose = () => {
    setVisiblepr(false);
  };

  const handleChangeStView = ({ date, view }) => {
    if (view === "year") {
      setStViewMode("year");
    } else if (view === "month") {
      setStViewMode("month");
    }
  };

  const handleChangeEdView = ({ date, view }) => {
    if (view === "year") {
      setEdViewMode("year");
    } else if (view === "month") {
      setEdViewMode("month");
    }
  };

  const handleClickAdmin = () => {
    AdminAuthRefetch();
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="float-left h-[5.6rem] w-full">
        <div className="float-left h-full w-[0.2rem] bg-grey_gradation bg-[length:100%_100%] bg-center bg-no-repeat"></div>
        <div
          className="float-left h-full w-[23.4rem] cursor-pointer bg-gradient-to-b from-[#FFFFFF] to-[#F3F3F3]"
          onClick={() => {
            setPick({
              menu_cate: "",
              menu_cate2: "",
              qst_cd: "",
              deep_ana_cd: "",
              crss_qst_cd: "",
              sort_type: "",
              cnt_yn: "",
              grp_dvs: "",
            });

            if (Mode !== "admin") {
              navigate(AnalysisType === "csscan" ? `/Summary` : `/Total`);
              moveMode("scan");
            }
          }}
        >
          <div
            className={
              window.location.href.toLowerCase().indexOf("csscan") > -1
                ? `float-left ml-[1.2rem] mt-[1.0rem] h-[3.6rem] w-full bg-csscan bg-[length:auto_100%] bg-left bg-no-repeat`
                : `float-left ml-[1.2rem] mt-[1.0rem] h-[3.6rem] w-full bg-scan bg-[length:auto_100%] bg-left bg-no-repeat`
            }
          ></div>
        </div>
        <div className="float-left h-full w-[0.2rem] bg-grey_gradation bg-[length:100%_100%] bg-center bg-no-repeat"></div>
        <div className="float-left h-full w-[calc(100%-35.6rem)] bg-gradient-to-b from-[#FFFFFF] to-[#F3F3F3]">
          {params === "/Admin/project" ? null : (
            <>
              <div className="float-left ml-[1.0rem] mt-[1.0rem]">
                <Select
                  key={"year"}
                  options={YearArr} //위에서 만든 배열을 select로 넣기
                  onChange={(e) => setYear(e.value)} //값이 바뀌면 setState되게
                  value={YearArr.filter((option) => {
                    return option.value === Year;
                  })}
                  defaultValue={Year}
                  isSearchable={false}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#E3F5FF",
                      fontSize: "1.6rem",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      fontSize: "1.6rem",
                    }),
                    indicatorSeparator: (styles) => ({
                      display: "none",
                    }),
                    valueContainer: (provided, state) => ({
                      height: "1.6rem",
                      lineHeight: "1.6rem",
                      padding: "0 0.8rem",
                    }),
                  }}
                />
              </div>
              <div className="float-left ml-[1.8rem] mt-[1.0rem]">
                <Select
                  key={"HspCd"}
                  options={HspCdArr} //위에서 만든 배열을 select로 넣기
                  onChange={(e) => {
                    setHspCd(e.value);
                  }} //값이 바뀌면 setState되게
                  value={HspCdArr.filter((option) => {
                    return option.value === HspCd;
                  })}
                  defaultValue={HspCd}
                  isSearchable={false}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#E3F5FF",
                      fontSize: "1.6rem",
                      width: "26.0rem",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      fontSize: "1.6rem",
                    }),
                    indicatorSeparator: (styles) => ({
                      display: "none",
                    }),
                    valueContainer: (provided, state) => ({
                      height: "1.6rem",
                      lineHeight: "1.6rem",
                      padding: "0 0.8rem",
                    }),
                  }}
                />
              </div>

              <div className="float-left ml-[1.8rem] mt-[1.0rem]">
                <Select
                  key={"PrjCd"}
                  options={PrjCdArr} //위에서 만든 배열을 select로 넣기
                  onChange={(e) => setPrjCd(e.value)} //값이 바뀌면 setState되게
                  value={PrjCdArr.filter((option) => {
                    return option.value === PrjCd;
                  })}
                  defaultValue={PrjCd}
                  isSearchable={false}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#E3F5FF",
                      fontSize: "1.6rem",
                      width: "28.0rem",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      fontSize: "1.6rem",
                    }),
                    indicatorSeparator: (styles) => ({
                      display: "none",
                    }),
                    valueContainer: (provided, state) => ({
                      height: "1.6rem",
                      lineHeight: "1.6rem",
                      padding: "0 0.8rem",
                      width: "22.8rem",
                    }),
                  }}
                />
              </div>

              <div className={`${AnalysisType === "csscan" ? " " : "hidden"} float-left ml-[1.8rem] mt-[1.0rem] cursor-pointer`}>
                <div
                  onClick={handleClickPeriod}
                  className="relative flex h-full w-[200px] flex-row items-center justify-center rounded-md border border-FilterInActiveColor bg-[#E3F5FF] p-[13px] pb-[12px] pl-14 pt-[14px] text-[16px] text-selectTextColor"
                >
                  <AiOutlineCalendar className="absolute left-9 top-3 mr-3 text-selectTextColor/80" />
                  {`${moment(new Date(startDate)).format("YY.MM.DD")} ~ ${moment(new Date(endDate)).format("YY.MM.DD")}`}
                </div>
                {visiblePr && (
                  <>
                    <div className="absolute top-[55px] z-50 flex h-fit w-[400px] flex-col items-center justify-center">
                      <Calendar
                        className="react-calendar mb-4  w-[400px] rounded-lg border border-FilterInActiveColor p-3 font-SDGothic text-[16px] text-selectTextColor drop-shadow-lg duration-300 ease-in-out"
                        minDate={new Date("01-01-2000")}
                        maxDate={new Date(endDate)}
                        onChange={handleChangeStDate}
                        tileContent={handleChangeStView}
                        value={startDate}
                        prevLabel={<BiChevronLeft className="ml-5 text-[20px] text-selectTextColor/80" />}
                        prev2Label={<BiChevronsLeft className="ml-5 text-[20px] text-selectTextColor/80" />}
                        nextLabel={<BiChevronRight className="ml-5 text-[20px] text-selectTextColor/80" />}
                        next2Label={<BiChevronsRight className="ml-5 text-[20px] text-selectTextColor/80" />}
                      />
                      <Calendar
                        className="react-calendar mb-4 w-[400px] rounded-lg border border-FilterInActiveColor p-3 font-SDGothic text-[16px] text-selectTextColor drop-shadow-lg duration-300 ease-in-out"
                        minDate={new Date(startDate)}
                        maxDate={new Date()}
                        onChange={handleChageEdDate}
                        tileContent={handleChangeEdView}
                        value={endDate}
                        prevLabel={<BiChevronLeft className="ml-5 text-[20px] text-selectTextColor/80" />}
                        prev2Label={<BiChevronsLeft className="ml-5 text-[20px] text-selectTextColor/80" />}
                        nextLabel={<BiChevronRight className="ml-5 text-[20px] text-selectTextColor/80" />}
                        next2Label={<BiChevronsRight className="ml-5 text-[20px] text-selectTextColor/80" />}
                      />
                      <div className="flex h-fit w-[400px] flex-col items-end justify-end duration-300 ease-in-out">
                        <button
                          onClick={handleClickPrClose}
                          type="button"
                          className=" flex h-fit w-fit flex-col items-center justify-center rounded-md bg-blue1Color px-16 py-8  font-SDGothic text-[16px] text-white hover:bg-blue1Color/80"
                        >
                          닫기
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className="float-left h-full w-[0.2rem] bg-grey_gradation bg-[length:100%_100%] bg-center bg-no-repeat"></div>
        {Mode === "scan" && (
          <div
            className="float-left h-full w-[5.6rem] cursor-pointer bg-gradient-to-b from-[#FFFFFF] to-[#F3F3F3]"
            // onClick={() => {
            //   moveMode("admin");
            //   navigate("/Admin/project");
            // }}
            onClick={handleClickAdmin}
          >
            <div className="float-left ml-[2.1rem] mt-[1.2rem] h-[1.4rem] w-[1.4rem] bg-admin bg-[length:100%_100%] bg-center bg-no-repeat"></div>
            <div className="float-left mt-[0.5rem] h-[1.2rem] w-full text-center text-[1.2rem] leading-[1.2rem]">관리자</div>
          </div>
        )}
        {Mode === "admin" && (
          <div
            className="float-left h-full w-[5.6rem] cursor-pointer bg-gradient-to-b from-[#FFFFFF] to-[#F3F3F3]"
            onClick={() => {
              moveMode("scan");
              navigate(AnalysisType === "csscan" ? `/Summary` : `/Total`);
            }}
          >
            <div className="float-left ml-[2.1rem] mt-[1.2rem] h-[1.4rem] w-[1.4rem] bg-admin bg-[length:100%_100%] bg-center bg-no-repeat"></div>
            <div className="float-left mt-[0.5rem] h-[1.2rem] w-full text-center text-[1.2rem] leading-[1.2rem]">설문</div>
          </div>
        )}
        <div className="float-left h-full w-[0.2rem] bg-grey_gradation bg-[length:100%_100%] bg-center bg-no-repeat"></div>
        <div
          className="float-left h-full w-[5.6rem] cursor-pointer bg-gradient-to-b from-[#FFFFFF] to-[#F3F3F3]"
          onClick={() => {
            moveMode("");
            navigate("/");
          }}
        >
          <div className="float-left ml-[2.1rem] mt-[1.2rem] h-[1.4rem] w-[1.4rem] bg-logout bg-[length:100%_100%] bg-center bg-no-repeat"></div>
          <div className="float-left mt-[0.5rem] h-[1.2rem] w-full text-center text-[1.2rem] leading-[1.2rem]">로그아웃</div>
        </div>
        <div className="float-left h-full w-[0.2rem] bg-grey_gradation bg-[length:100%_100%] bg-center bg-no-repeat"></div>
      </div>
      {Mode === "scan" && (
        <div className="float-left h-[4.8rem] w-full">
          <div className="float-left h-full w-[23.8rem] bg-blue1Color">
            <div className="float-left h-full w-[5.9rem] bg-blue1Color text-center text-[1.4rem] font-extrabold leading-[4.8rem] text-white">
              집계
            </div>
            <div className="float-left mt-[1.0rem] h-[2.8rem] w-[16.6rem] bg-loginBlueColor text-center text-[1.4rem] leading-[2.8rem] text-white">
              {SvyOjt !== "DEP" && Total}
            </div>
          </div>
          <div className="float-left h-full w-[calc(100%-23.8rem)] bg-blue2Color">
            <div className="float-left ml-[2.0rem] mt-[1.8rem] h-[1.2rem] w-[0.24rem] bg-three_dot bg-[length:100%_100%] bg-center bg-no-repeat"></div>
            <div className="float-left ml-[0.86rem] h-full text-[1.4rem] font-extrabold leading-[4.8rem] text-white">조회옵션</div>
            <div className="float-left ml-[1.8rem] mt-[1.3rem] h-[2.2rem] w-[0.3rem] bg-condition_rectangle bg-[length:100%_100%] bg-center bg-no-repeat"></div>
            <div className="float-left h-full w-[8.0rem] text-center text-[1.4rem] font-extrabold leading-[4.8rem] text-white">대상별</div>
            <div className="float-left mt-[1.0rem] h-[2.8rem]">
              {/* {(SvyOjt === "MEM" || SvyOjt === "VST" || SvyOjt === "LRES" || SvyOjt === "DEP") && */}
              {AnalysisType === "scan" &&
                menuDict.map((data, i) => {
                  var vActive = SvyOjtActiveArr.filter((x) => x.value === data.value);
                  return (
                    <div
                      key={`SvyOjt_${data.value}`}
                      className={`float-left h-full w-[8.0rem]  rounded-[0.4rem] text-center text-[1.4rem] leading-[2.8rem] text-white
                    ${vActive.length > 0 ? " cursor-pointer" : ""}
                    ${SvyOjt === data.value ? " bg-orange2Color" : vActive.length > 0 ? " bg-blue1Color" : " bg-gray-500"}`}
                      onClick={(event) => {
                        if (vActive.length > 0) {
                          setPick({
                            menu_cate: "",
                            menu_cate2: "",
                            qst_cd: "",
                            deep_ana_cd: "",
                            crss_qst_cd: "",
                            sort_type: "",
                            cnt_yn: "",
                            grp_dvs: "",
                          });
                          navigate(AnalysisType === "csscan" ? `/Summary` : `/TotalSvyOjt`);
                          setSvyOjt(data.value);
                        }
                      }}
                    >
                      {data.label}
                    </div>
                  );
                })}
              {/* {(SvyOjt === "CS" || SvyOjt === "RS") && */}
              {AnalysisType === "csscan" &&
                menuCSDict.map((data, i) => {
                  var vActive = SvyOjtActiveArr.filter((x) => x.value === data.value);
                  return (
                    <div
                      key={`SvyOjt_${data.value}`}
                      className={`float-left h-full w-[8.0rem]  rounded-[0.4rem] text-center text-[1.4rem] leading-[2.8rem] text-white
                    ${vActive.length > 0 ? " cursor-pointer" : ""}
                    ${SvyOjt === data.value ? " bg-orange2Color" : vActive.length > 0 ? " bg-blue1Color" : " bg-gray-500"}`}
                      onClick={(event) => {
                        if (vActive.length > 0) {
                          setPick({
                            menu_cate: "",
                            menu_cate2: "",
                            qst_cd: "",
                            deep_ana_cd: "",
                            crss_qst_cd: "",
                            sort_type: "",
                            cnt_yn: "",
                            grp_dvs: "",
                          });
                          navigate(AnalysisType === "csscan" ? `/Summary` : `/TotalSvyOjt`);
                          setSvyOjt(data.value);
                        }
                      }}
                    >
                      {data.label}
                    </div>
                  );
                })}
            </div>
            <div className="float-right mr-[1.0rem] mt-[1.0rem] h-[2.8rem]">
              <div
                className={`float-left h-full w-[8.0rem]  cursor-pointer rounded-[0.4rem] bg-blue1Color text-center text-[1.4rem] leading-[2.8rem] text-white ${
                  SvyOjt && SvyOjt === "DEP" ? "hidden" : ""
                }`}
                onClick={(event) => {
                  downloadData();
                }}
              >
                원천데이터
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
