import exceljs from "exceljs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { useGraphAgeGrp } from "../../hooks/useGraphAgeGrp";
import { useGraphBasic } from "../../hooks/useGraphBasic";
import { useGraphDA03Octy } from "../../hooks/useGraphDA03Octy";
import { useGraphDA04Octy } from "../../hooks/useGraphDA04Octy";
import { useGraphDA05Normal } from "../../hooks/useGraphDA05Normal";
import { useGraphDA06AgeGrp } from "../../hooks/useGraphDA06AgeGrp";
import { useGraphDA06Normal } from "../../hooks/useGraphDA06Normal";
import { useGraphDA08Normal } from "../../hooks/useGraphDA08Normal";
import { useGraphDA08Octy } from "../../hooks/useGraphDA08Octy";
import { useGraphDA09Normal } from "../../hooks/useGraphDA09Normal";
import { useGraphDEPNormal } from "../../hooks/useGraphDEPNormal";
import { useGraphOcty } from "../../hooks/useGraphOcty";
import { useSelList } from "../../hooks/useSelList";
const html2canvas = require("html2canvas");

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const TotalSvyOjt = ({ hsp_nm, prj_cd, svy_ojt, qst_cd, deep_ana_cd, where, type, data_num, title, qst_hsp_sel_cd, startDate, endDate }) => {
  const [DA08Check, setDA08Check] = useState("");
  const [DA08CheckNm, setDA08CheckNm] = useState("");
  const [OptionCnt, setOptionCnt] = useState(0);
  Highcharts.setOptions({
    colors: ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"],
  });

  const chart = useRef();

  const convertToCSV = () => {
    const currChart = chart.current.chart;
    const csvData = currChart.getCSV();

    return csvData;
  };

  const downloadChart = (type) => {
    if (chart && chart.current && chart.current.chart) {
      if (type === "jpeg") {
        chart.current.chart.exportChart({ type: "image/jpeg" });
      } else if (type === "png") {
        chart.current.chart.exportChart({ type: "image/png" });
      } else if (type === "pdf") {
        chart.current.chart.exportChart({
          type: "application/pdf",
        });
      } else {
        // 엑셀 워크북 생성
        let workbook = new exceljs.Workbook();
        // 시트 생성
        const imageSheet = workbook.addWorksheet("DataSheet");

        // 텍스트 데이터 csv로 변환
        const csvData = convertToCSV();
        // 불필요한 특수문자 제거 (")
        let filteredCsvData = csvData.replace(/\"/gi, "");

        // excelJs에 맞는 형식으로 전환
        let excelData = [];

        // 개행태그를 기준으로 split
        let dataArr = filteredCsvData.split("\n");

        // ,단위로 split하여 배열로 만든 후, 덩어리를 excelData에 push
        for (let i = 0; i < dataArr.length; i++) {
          let temp = dataArr[i].split(",");

          excelData.push(temp);
        }

        // 시트에 추가
        imageSheet.addRows(excelData);

        // 차트 이미지 작업을 위한 ref 속성 가져오기
        const chartContainer = chart.current.container.current;

        let promise = [];

        // 차트이미지를 스크린샷 찍어 이미지를 시트에 추가
        promise.push(
          html2canvas(chartContainer).then((c) => {
            let image = c.toDataURL();
            const imageId2 = workbook.addImage({
              base64: image,
              extension: "png",
            });

            imageSheet.addImage(imageId2, {
              tl: { col: 0, row: excelData.length + 3 },
              br: { col: chartContainer.offsetWidth / 100, row: excelData.length + 12 + chartContainer.offsetHeight / 100 },
            });
          })
        );

        // 파일 다운로드
        Promise.all(promise).then(() => {
          workbook.xlsx.writeBuffer().then((b) => {
            let a = new Blob([b]);
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement("a");
            elem.href = url;
            elem.download = `${hsp_nm}.xlsx`;
            document.body.appendChild(elem);
            elem.style = "display: none";
            elem.click();
            elem.remove();
          });
        });

        return workbook;
      }
    }
  };

  const initialOptions = {
    title: { text: "" },
    exporting: {
      enabled: false,
      filename: title,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    boost: {
      enabled: true,
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: { fontSize: "1.4rem", textShadow: false, textOutLine: false },
          format: "{y}",
          border: null,
        },
      },
      column: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: "",
        pointWidth: 30,
      },
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          style: {
            textShadow: 0,
            textOutline: 0,
            color: "#000000",
          },
        },
        stacking: "",
        pointWidth: 30,
      },
    },
    credits: { enabled: false },
    tooltip: {
      style: {
        fontSize: "1.4rem",
      },
    },
    xAxis: {},
    yAxis: {},
    legend: {
      enabled: false,
      itemStyle: {
        fontSize: "1.4rem",
      },
    },
    series: [], // 데이터가 처음엔 비어았다.
  };

  const [options, setOptions] = useState(initialOptions);

  const { isSelListLoading, isSelListError, SelListData, isSelListSuccess, SelListRefetch } = useSelList(prj_cd, svy_ojt, qst_cd);

  useEffect(() => {
    if (!isSelListSuccess || SelListData === null || SelListData === undefined || SelListData.length === 0) {
      SelListRefetch();
    } else {
      var vTemp = SelListData.slice().map((data) => data.value);
      var vTemp2 = qst_hsp_sel_cd ? qst_hsp_sel_cd : "";
      var vTemp3 =
        vTemp2 && SelListData.filter((x) => x.sel_CD === vTemp2) && SelListData.filter((x) => x.sel_CD === vTemp2).length > 0
          ? SelListData.filter((x) => x.sel_CD === vTemp2)[0].sel_NM
          : "";
      setDA08Check(vTemp2);
      setDA08CheckNm(vTemp3);
    }
  }, [SelListData, isSelListSuccess, prj_cd, svy_ojt, qst_cd, deep_ana_cd, where, qst_hsp_sel_cd]);

  const { isGraphDEPNormalLoading, isGraphDEPNormalError, GraphDEPNormalData, isGraphDEPNormalSuccess, GraphDEPNormalRefetch } = useGraphDEPNormal(
    prj_cd,
    svy_ojt,
    qst_cd,
    deep_ana_cd === "DA08" ? qst_hsp_sel_cd : "",
    "",
    "",
    "",
    startDate,
    endDate
  );

  const { isGraphBasicLoading, isGraphBasicError, GraphBasicData, isGraphBasicSuccess, GraphBasicRefetch } = useGraphBasic(
    prj_cd,
    svy_ojt,
    qst_cd,
    "",
    "",
    svy_ojt === "MEM" ? "OCTY" : "AGE_GRP",
    "M",
    startDate,
    endDate
  );

  const { isGraphOctyLoading, isGraphOctyError, GraphOctyData, isGraphOctySuccess, GraphOctyRefetch } = useGraphOcty(
    prj_cd,
    svy_ojt,
    qst_cd,
    "",
    "",
    "OCTY",
    "M",
    startDate,
    endDate
  );

  const { isGraphAgeGrpLoading, isGraphAgeGrpError, GraphAgeGrpData, isGraphAgeGrpSuccess, GraphAgeGrpRefetch } = useGraphAgeGrp(
    prj_cd,
    svy_ojt,
    qst_cd,
    "",
    "",
    "AGE_GRP",
    "M",
    startDate,
    endDate
  );

  const { isGraphDA06NormalLoading, isGraphDA06NormalError, GraphDA06NormalData, isGraphDA06NormalSuccess, GraphDA06NormalRefetch } =
    useGraphDA06Normal(prj_cd, svy_ojt, qst_cd, "", "", svy_ojt === "MEM" ? "OCTY" : "AGE_GRP", "M", startDate, endDate);

  const { isGraphDA06AgeGrpLoading, isGraphDA06AgeGrpError, GraphDA06AgeGrpData, isGraphDA06AgeGrpSuccess, GraphDA06AgeGrpRefetch } =
    useGraphDA06AgeGrp(prj_cd, svy_ojt, qst_cd, "", "", svy_ojt === "MEM" ? "OCTY" : "AGE_GRP", "M", startDate, endDate);

  const { isGraphDA03OctyLoading, isGraphDA03OctyError, GraphDA03OctyData, isGraphDA03OctySuccess, GraphDA03OctyRefetch } = useGraphDA03Octy(
    prj_cd,
    svy_ojt,
    qst_cd,
    "",
    "",
    svy_ojt === "MEM" ? "OCTY" : "AGE_GRP",
    "M",
    startDate,
    endDate
  );

  const { isGraphDA05NormalLoading, isGraphDA05NormalError, GraphDA05NormalData, isGraphDA05NormalSuccess, GraphDA05NormalRefetch } =
    useGraphDA05Normal(prj_cd, svy_ojt, qst_cd, "", "", svy_ojt === "MEM" ? "OCTY" : "AGE_GRP", "M", startDate, endDate);

  const { isGraphDA04OctyLoading, isGraphDA04OctyError, GraphDA04OctyData, isGraphDA04OctySuccess, GraphDA04OctyRefetch } = useGraphDA04Octy(
    prj_cd,
    svy_ojt,
    qst_cd,
    "",
    "",
    svy_ojt === "MEM" ? "OCTY" : "AGE_GRP",
    "M",
    startDate,
    endDate
  );

  const { isGraphDA09NormalLoading, isGraphDA09NormalError, GraphDA09NormalData, isGraphDA09NormalSuccess, GraphDA09NormalRefetch } =
    useGraphDA09Normal(prj_cd, svy_ojt, qst_cd, "", "", svy_ojt === "MEM" ? "OCTY" : "AGE_GRP", "M", startDate, endDate);

  const { isGraphDA08NormalLoading, isGraphDA08NormalError, GraphDA08NormalData, isGraphDA08NormalSuccess, GraphDA08NormalRefetch } =
    useGraphDA08Normal(prj_cd, svy_ojt, qst_cd, DA08Check, "", svy_ojt === "MEM" ? "OCTY" : "AGE_GRP", "M", startDate, endDate);

  const { isGraphDA08OctyLoading, isGraphDA08OctyError, GraphDA08OctyData, isGraphDA08OctySuccess, GraphDA08OctyRefetch } = useGraphDA08Octy(
    prj_cd,
    svy_ojt,
    qst_cd,
    DA08Check,
    "",
    svy_ojt === "MEM" ? "OCTY" : "AGE_GRP",
    "M",
    startDate,
    endDate
  );

  useEffect(() => {
    if (!isGraphBasicSuccess || GraphBasicData === null || GraphBasicData === undefined || GraphBasicData.length === 0) {
      GraphBasicRefetch();
    }
  }, [GraphBasicData, isGraphBasicSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA03OctySuccess || GraphDA03OctyData === null || GraphDA03OctyData === undefined || GraphDA03OctyData.length === 0) {
      GraphDA03OctyRefetch();
    }
  }, [GraphDA03OctyData, isGraphDA03OctySuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA04OctySuccess || GraphDA04OctyData === null || GraphDA04OctyData === undefined || GraphDA04OctyData.length === 0) {
      GraphDA04OctyRefetch();
    }
  }, [GraphDA04OctyData, isGraphDA04OctySuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA05NormalSuccess || GraphDA05NormalData === null || GraphDA05NormalData === undefined || GraphDA05NormalData.length === 0) {
      GraphDA05NormalRefetch();
    }
  }, [GraphDA05NormalData, isGraphDA05NormalSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA06AgeGrpSuccess || GraphDA06AgeGrpData === null || GraphDA06AgeGrpData === undefined || GraphDA06AgeGrpData.length === 0) {
      GraphDA06AgeGrpRefetch();
    }
  }, [GraphDA06AgeGrpData, isGraphDA06AgeGrpSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA06NormalSuccess || GraphDA06NormalData === null || GraphDA06NormalData === undefined || GraphDA06NormalData.length === 0) {
      GraphDA06NormalRefetch();
    }
  }, [GraphDA06NormalData, isGraphDA06NormalSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA08NormalSuccess || GraphDA08NormalData === null || GraphDA08NormalData === undefined || GraphDA08NormalData.length === 0) {
      GraphDA08NormalRefetch();
    }
  }, [GraphDA08NormalData, isGraphDA08NormalSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA08OctySuccess || GraphDA08OctyData === null || GraphDA08OctyData === undefined || GraphDA08OctyData.length === 0) {
      GraphDA08OctyRefetch();
    }
  }, [GraphDA08OctyData, isGraphDA08OctySuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDA09NormalSuccess || GraphDA09NormalData === null || GraphDA09NormalData === undefined || GraphDA09NormalData.length === 0) {
      GraphDA09NormalRefetch();
    }
  }, [GraphDA09NormalData, isGraphDA09NormalSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphDEPNormalSuccess || GraphDEPNormalData === null || GraphDEPNormalData === undefined || GraphDEPNormalData.length === 0) {
      GraphDEPNormalRefetch();
    }
  }, [GraphDEPNormalData, isGraphDEPNormalSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (!isGraphOctySuccess || GraphOctyData === null || GraphOctyData === undefined || GraphOctyData.length === 0) {
      GraphOctyRefetch();
    }
  }, [GraphOctyData, isGraphOctySuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);
  useEffect(() => {
    if (!isGraphAgeGrpSuccess || GraphAgeGrpData === null || GraphAgeGrpData === undefined || GraphAgeGrpData.length === 0) {
      GraphAgeGrpRefetch();
    }
  }, [GraphAgeGrpData, isGraphAgeGrpSuccess, prj_cd, svy_ojt, qst_cd, where, deep_ana_cd, title, type, DA08Check, startDate, endDate]);

  useEffect(() => {
    if (svy_ojt !== "DEP" && !deep_ana_cd && where === "basic") {
      if (GraphBasicData !== null && GraphBasicData !== undefined && GraphBasicData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];

        GraphBasicData.map((data, i) => {
          tempSeriesData.push({
            name: data.sel_NM,
            y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
            dataLabels: {
              shadow: false,
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
            color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
          });
          tempCategoriesData.push(data.sel_NM);
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        if (
          GraphBasicData.filter(
            (x) =>
              x.b_RATE !== "" &&
              x.b_RATE !== null &&
              x.b_RATE !== "0.0" &&
              x.b_RATE !== "0" &&
              x.w_RATE !== "" &&
              x.w_RATE !== null &&
              x.w_RATE !== "0.0" &&
              x.w_RATE !== "0"
          ).length > 0
        ) {
          GraphBasicData.map((data, i) => {
            tempSeriesDataBest.push({
              name: data.sel_NM,
              y: data_num === "1" ? (data.b_RATE ? parseFloat(data.b_RATE) : 0) : data.b_RATE_CNT ? parseFloat(data.b_RATE_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
              },
            });
          });

          tempSeries.push({
            name: "Best",
            data: tempSeriesDataBest,
          });

          GraphBasicData.map((data, i) => {
            tempSeriesDataWorst.push({
              name: data.sel_NM,
              y: data_num === "1" ? (data.w_RATE ? parseFloat(data.w_RATE) : 0) : data.w_RATE_CNT ? parseFloat(data.w_RATE_CNT) : 0,
              dataLabels: {
                shadow: false,
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
              },
            });
          });

          tempSeries.push({
            name: "Worst",
            data: tempSeriesDataWorst,
          });
        }

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: (tempSeriesData.length + tempSeriesDataBest.length + tempSeriesDataWorst.length) / tempCategoriesData.length > 1 ? true : false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 500 / (tempCategoriesData.length * tempSeries.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 500 / (tempCategoriesData.length * tempSeries.length) > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && !deep_ana_cd && where === "octy") {
      if (GraphOctyData !== null && GraphOctyData !== undefined && GraphOctyData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphOctyData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          GraphOctyData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempCategoriesData.length > 5 ? "Y" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempCategoriesData.length > 5 ? "Y" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && !deep_ana_cd && where === "age") {
      if (GraphAgeGrpData !== null && GraphAgeGrpData !== undefined && GraphAgeGrpData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphAgeGrpData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          GraphAgeGrpData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  fontFamily: "AppleSDGothicNeoRegular",
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: data.sel_NM !== null && data.sel_NM.indexOf(hsp_nm) !== -1 ? "#98D7F8" : "",
              });
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempCategoriesData.length > 5 ? "Y" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempCategoriesData.length > 5 ? "Y" : "",
              pointWidth: 500 / tempCategoriesData.length > 50 ? 50 : 500 / (tempCategoriesData.length * tempSeries.length),
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA03") {
      if (GraphDA03OctyData !== null && GraphDA03OctyData !== undefined && GraphDA03OctyData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA03OctyData.map((dd, ii) => {
          tempCategoriesData.push(dd.assign);
        });
        GraphDA03OctyData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
          tempCategoriesData.map((dd, i) => {
            tempCategoriesAssignData.push({
              name: dd,
              categories: ["본인<br>직종", "다른<br>직종"],
            });
          });
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA03OctyData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: "본인<br>직종",
                y: data_num === "1" ? (data.self ? parseFloat(data.self) : 0) : data.self_CNT ? parseFloat(data.self_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                },
                color: "",
              });
              tempSeriesData.push({
                name: "다른<br>직종",
                y: data_num === "1" ? (data.nonself ? parseFloat(data.nonself) : 0) : data.nonself_CNT ? parseFloat(data.nonself_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                },
                color: "#ecb758",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA04") {
      if (GraphDA04OctyData !== null && GraphDA04OctyData !== undefined && GraphDA04OctyData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA04OctyData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA04OctyData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                dataLabels: {
                  fontFamily: "AppleSDGothicNeoRegular",
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA05") {
      if (GraphDA05NormalData !== null && GraphDA05NormalData !== undefined && GraphDA05NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA05NormalData.map((dd, ii) => {
          tempCategoriesData.push(dd.assign);
        });
        GraphDA05NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA05NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  fontFamily: "AppleSDGothicNeoRegular",
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontWeight: "bold",
                fontFamily: "AppleSDGothicNeoRegular",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA06" && where === "basic") {
      if (GraphDA06NormalData !== null && GraphDA06NormalData !== undefined && GraphDA06NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA06NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA06NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                dataLabels: {
                  shadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA06" && where === "age") {
      if (GraphDA06AgeGrpData !== null && GraphDA06AgeGrpData !== undefined && GraphDA06AgeGrpData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA06AgeGrpData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }

          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
        });

        tempCategoriesData.map((dd, ii) => {
          tempSeriesData = [];
          GraphDA06AgeGrpData.filter((x) => x.sel_NM === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              if (tempAssignData.length === 1) {
                if (data.assign === tempAssignData[0]) {
                  tempSeriesData.push({
                    name: data.assign,
                    y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                    dataLabels: {
                      shadow: false,
                      fontFamily: "AppleSDGothicNeoRegular",
                      fontSize: "1.4rem",
                    },
                    color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
                  });
                }
              } else {
                tempSeriesData.push({
                  name: data.assign,
                  y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                  dataLabels: {
                    shadow: false,
                    fontFamily: "AppleSDGothicNeoRegular",
                    fontSize: "1.4rem",
                  },
                  color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
                });
              }
            });

          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempAssignData.length + 1) * (tempCategoriesData.length * 50),
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 25,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 25,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA08" && where === "basic") {
      if (GraphDA08NormalData !== null && GraphDA08NormalData !== undefined && GraphDA08NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA08NormalData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.answ_CD + "위").length === 0) {
            tempCategoriesData.push(data.answ_CD + "위");
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.sel_NM);
          }
        });

        tempSeriesData = [];
        GraphDA08NormalData
          //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
          .map((data, i) => {
            tempSeriesData.push({
              name: data.answ_CD + "위",
              y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
              dataLabels: {
                fontFamily: "AppleSDGothicNeoRegular",
                shadow: false,
                fontSize: "1.4rem",
              },
            });
          });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempAssignData.length + 1) * (tempCategoriesData.length * 10),
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  textShadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA08" && where === "octy") {
      if (GraphDA08OctyData !== null && GraphDA08OctyData !== undefined && GraphDA08OctyData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];
        let tempCategoriesAssignData = [];

        GraphDA08OctyData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.answ_CD).length === 0) {
            tempCategoriesData.push(data.answ_CD);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempCategoriesData.map((dd, ii) => {
          tempSeriesData = [];
          GraphDA08OctyData.filter((x) => x.answ_CD === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.assign,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                },
              });
            });

          tempSeries.push({
            name: dd + "위",
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: (tempAssignData.length + 1) * (tempCategoriesData.length * 10),
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontSize: "1.4rem",
                fontFamily: "AppleSDGothicNeoRegular",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: tempAssignData.length > 5 ? "Y" : "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt !== "DEP" && deep_ana_cd && deep_ana_cd === "DA09") {
      if (GraphDA09NormalData !== null && GraphDA09NormalData !== undefined && GraphDA09NormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDA09NormalData.map((data, i) => {
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        tempSeriesData = [];
        tempAssignData.map((dd, ii) => {
          GraphDA09NormalData.filter((x) => x.assign === dd)
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: dd,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate ? parseFloat(data.rate) : 0,
                dataLabels: {
                  fontFamily: "AppleSDGothicNeoRegular",
                  shadow: false,
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" || dd === "전체-전체" ? "#98c8d7" : "",
              });
            });
        });

        tempSeries.push({
          name: hsp_nm,
          data: tempSeriesData,
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: tempAssignData.length * 50,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempAssignData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: false,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "",
              pointWidth: 30,
            },
          },
        });
      }
    } else if (svy_ojt === "DEP") {
      if (GraphDEPNormalData !== null && GraphDEPNormalData !== undefined && GraphDEPNormalData.length > 0) {
        let tempSeries = [];
        let tempSeriesData = [];
        let tempSeriesDataBest = [];
        let tempSeriesDataWorst = [];
        let tempCategoriesData = [];
        let tempAssignData = [];

        GraphDEPNormalData.map((data, i) => {
          if (tempCategoriesData.filter((x) => x === data.sel_NM && x !== null).length === 0) {
            tempCategoriesData.push(data.sel_NM);
          }
          if (tempAssignData.filter((x) => x === data.assign && x !== null).length === 0) {
            tempAssignData.push(data.assign);
          }
        });

        var vName = "";
        tempAssignData.map((dd, ii) => {
          tempSeriesData = [];

          GraphDEPNormalData.filter((x) => x.assign === dd.replace("위", ""))
            //.sort((a, b) => parseFloat(a.seq) - parseFloat(b.seq))
            .map((data, i) => {
              tempSeriesData.push({
                name: data.sel_NM,
                y: data_num === "1" ? (data.rate ? parseFloat(data.rate) : 0) : data.rate_CNT ? parseFloat(data.rate_CNT) : 0,
                dataLabels: {
                  shadow: false,
                  fontFamily: "AppleSDGothicNeoRegular",
                  fontSize: "1.4rem",
                },
                color: dd === "순추천고객지수" || dd === "전체" ? "#98c8d7" : "",
              });
            });
          tempSeries.push({
            name: dd,
            data: tempSeriesData,
          });
        });

        setOptions({
          ...initialOptions,
          chart: {
            type: type,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            animation: false,
            height: 400,
          },
          exporting: {
            scale: 2,
            enabled: false,
            filename: hsp_nm + "_" + title,
            chartOptions: {
              chart: {
                spacing: [10, 10, 10, 10],
              },
              xAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              yAxis: {
                labels: {
                  style: {
                    fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                    fontSize: "12px",
                  },
                },
              },
              legend: {
                itemStyle: {
                  fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                  fontSize: "12px",
                },
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    style: {
                      fontFamily: "AppleSDGothicNeoRegular, Arial, sans-serif",
                      fontSize: "12px",
                    },
                  },
                },
              },
            },
          },
          series: tempSeries,
          xAxis: {
            title: { text: null },
            labels: {
              style: {
                fontFamily: "AppleSDGothicNeoRegular",
                fontSize: "1.4rem",
                fontWeight: "bold",
              },
            },
            tickWidth: 0,
            tickLength: 0,
            categories: tempCategoriesData,
          },
          yAxis: {
            gridLineWidth: 0,
            labels: { enabled: false },
            title: { text: null },
          },
          legend: {
            enabled: true,
            itemStyle: {
              fontFamily: "AppleSDGothicNeoRegular",
              fontSize: "1.4rem",
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                style: {
                  fontSize: "1.4rem",
                  fontFamily: "AppleSDGothicNeoRegular",
                  textShadow: false,
                  textOutLine: false,
                },
                format: "{y}",
                border: null,
              },
            },
            column: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "Y",
              pointWidth: 50,
            },
            bar: {
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  textShadow: 0,
                  textOutline: 0,
                  color: "#000000",
                },
              },
              stacking: "Y",
              pointWidth: 30,
            },
          },
        });
      }
    }
  }, [
    GraphBasicData,
    GraphOctyData,
    GraphAgeGrpData,
    GraphDEPNormalData,
    GraphDA03OctyData,
    GraphDA05NormalData,
    GraphDA04OctyData,
    GraphDA06NormalData,
    GraphDA06AgeGrpData,
    GraphDA08NormalData,
    GraphDA08OctyData,
    GraphDA09NormalData,
    prj_cd,
    svy_ojt,
    qst_cd,
    deep_ana_cd,
    title,
    type,
    where,
    DA08Check,
  ]);

  useEffect(() => {
    if (options.xAxis.categories) {
      setOptionCnt(options.xAxis.categories.length);
    }
  }, [options]);

  return (
    <div className={`float-left w-full bg-white`}>
      <div className="float-left h-full w-full border-[0.1rem] border-tableBorderColor">
        <div
          className={`float-left h-[3.0rem] w-full border-b-[0.1rem] border-b-tableBorderColor bg-white pl-[1.0rem] pr-[1.0rem] ${
            title !== null && title !== undefined && title !== "" ? "" : "hidden"
          }`}
        >
          <div className="float-left mt-[1.0rem] h-[1.0rem] w-[1.0rem] bg-orangeColor"></div>
          <div className="float-left ml-[1.0rem] h-full w-[calc(100%-17.0rem)] overflow-hidden text-ellipsis break-all text-[1.8rem] font-bold leading-[3.0rem]">
            {title}
          </div>
          <div className="float-left h-full w-[15.0rem] leading-[6.0rem]">
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_png bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("png");
              }}
            ></div>
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_pdf bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("pdf");
              }}
            ></div>
            <div
              className="float-right ml-[0.5rem] mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_jpeg bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("jpeg");
              }}
            ></div>
            <div
              className="float-right mt-[0.15rem] h-[2.5rem] w-[2.5rem] cursor-pointer bg-download_excel bg-[length:100%_100%] bg-center bg-no-repeat"
              onClick={() => {
                downloadChart("excel");
              }}
            ></div>
          </div>
        </div>
        <div className="float-left w-full">
          <HighchartsReact
            key={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + deep_ana_cd + "_" + type}`}
            id={`${prj_cd + "_" + svy_ojt + "_" + qst_cd + "_" + deep_ana_cd + "_" + type}`}
            ref={chart}
            highcharts={Highcharts}
            options={options}
            allowChartUpdate={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalSvyOjt;
