import { useQuery } from "@tanstack/react-query";
import getCrssRes from "../api/getCrssRes";

const useGetCrssRes = (prj_cd, qst_cd, svy_ojt, sort_type, stDate, edDate) => {
  return useQuery(["Crss"], () => getCrssRes(prj_cd, qst_cd, svy_ojt, sort_type, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetCrssRes;
