import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { FaFolderOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import AdminModalContainer from "../../components/pc/admin/AdminModalContainer";
import AdminProChatComp from "../../components/pc/admin/AdminProjChatComp";
import AdminProChatMyComp from "../../components/pc/admin/AdminProjChatMyComp";
import AdminProjModalComp from "../../components/pc/admin/AdminProjModalComp";
import useGetAdminChat from "../../hooks/useGetAdminChat";
import useGetAdminFileHistory from "../../hooks/useGetAdminFileHistory";
import useGetAdminProj from "../../hooks/useGetAdminProj";
import usePatchAdminChat from "../../hooks/usePostAdminChat";
import useStore from "../../store/pc/store";

const AdminProject = () => {
  const [modal, setModal] = useState(false);
  const [prjCd, setPrjCd] = useState("");
  const [createMode, setCreateMode] = useState(false);
  const { data } = useGetAdminProj();
  const { data: historyDb, refetch } = useGetAdminFileHistory(prjCd);
  const { data: chatData, refetch: chatRefetch } = useGetAdminChat(prjCd);
  const [chat, setChat] = useState("");
  const { mutate } = usePatchAdminChat();
  const [selectChat, setSelectChat] = useState(0);
  const [updateChat, setUpdateChat] = useState(0);

  const { AnalysisType, userId } = useStore();

  const chatRef = useRef(null);

  window.addEventListener("click", () => {
    setSelectChat(0);
  });

  const handleClickPrj = (e, prj_cd) => {
    setPrjCd(prj_cd);
    e.stopPropagation();
  };

  const handleClickSelectPrj = (e, prj_cd) => {
    document.body.style.overflow = "hidden";
    setModal(true);
  };

  const handleClickCreateProj = () => {
    document.body.style.overflow = "hidden";
    setModal(true);
    setCreateMode(true);
  };

  const extractTime = (time) => {
    let currDate = new Date(time);

    let utc = currDate.getTime() + currDate.getTimezoneOffset() * 60 * 1000;

    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    const kr_curr = new Date(utc + KR_TIME_DIFF);

    let year = kr_curr.getFullYear();
    let month = Number(kr_curr.getMonth()) + 1 > 9 ? Number(kr_curr.getMonth()) + 1 : `0${Number(kr_curr.getMonth()) + 1}`;
    let date = kr_curr.getDate() > 9 ? kr_curr.getDate() : `0${kr_curr.getDate()}`;

    let hour = kr_curr.getHours() > 9 ? kr_curr.getHours() : `0${kr_curr.getHours()}`;
    let minute = kr_curr.getMinutes() > 9 ? kr_curr.getMinutes() : `0${kr_curr.getMinutes()}`;
    let sec = kr_curr.getSeconds() > 9 ? kr_curr.getSeconds() : `0${kr_curr.getSeconds()}`;

    return `${year}-${month}-${date} ${hour}:${minute}:${sec}`;
  };

  const handleClickSubmit = () => {
    if (chat.length > 0) {
      let body = {
        prj_CD: prjCd,
        user_ID: userId,
        seq: chatData?.data.length + 1,
        content: chat,
        status: "E",
      };

      mutate(body);
      setChat("");
    } else {
      alert("내용을 입력하세요!");
    }
  };

  const handleClickFileName = (file_PATH) => {
    // const s3BucketUrl = "https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/upload_file/";
    // window.open(s3BucketUrl + fileNm);
    window.open(file_PATH);
  };

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [chatData]);

  useEffect(() => {
    refetch();
    chatRefetch();
  }, [prjCd]);

  return (
    <div className="relative flex h-full w-full flex-col border-b-2 border-r-2 border-FilterInActiveColor bg-white p-10 font-SDGothic text-[16px] font-normal text-selectTextColor/90">
      {modal ? (
        <AdminModalContainer setModal={setModal} size={"S"}>
          <AdminProjModalComp prj_cd={prjCd} mode={createMode ? true : false} setMode={setCreateMode} />
        </AdminModalContainer>
      ) : null}

      {/* title */}
      <div className="mt-6 flex h-fit w-full flex-row items-center justify-start text-[20px] font-bold">
        <FaFolderOpen className="mb-2 mr-7 text-[24px] text-orangeColor" />
        프로젝트
      </div>
      {/* sub title */}
      <div className="mt-16 flex h-fit w-full flex-row items-center justify-between">
        <div className="flex h-fit w-1/2 flex-row items-center justify-start">
          <div className="flex h-fit w-fit flex-row items-center justify-start text-[18px] font-bold">
            <img
              src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
              alt="menu_dot"
              className="mb-1 mr-8 w-[8px]"
            />
            <div className="flex h-fit w-[120px] flex-row items-center justify-start text-[18px] font-bold">프로젝트 관리</div>
          </div>

          <div className="mt-1 flex h-fit w-full flex-row items-center justify-start text-[14px] font-bold text-orangeColor">
            프로젝트 삭제는 IT본부에 문의해주세요
          </div>
        </div>
        <button
          onClick={handleClickCreateProj}
          type="button"
          className="flex h-fit w-fit flex-col items-center justify-center rounded-md bg-orangeColor p-4 px-6 text-[14px] font-bold text-white hover:bg-orangeColor/80"
        >
          신규생성
        </button>
      </div>

      {/* 프로젝트 관리 테이블 */}
      {/* table header */}
      <div className="mt-6 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
        <div className="flex h-full w-[200px] flex-col items-center justify-center border-l border-white">코드</div>
        <div className="flex h-full w-[400px] flex-col items-center justify-center border-l border-white">프로젝트명</div>
        <div className="flex h-full w-[200px] flex-col items-center justify-center border-l border-white">진행여부</div>
        <div className="flex h-full w-[190px] flex-col items-center justify-center border-l border-white">구분</div>
      </div>
      {/* table body */}
      <div className="h-[300px] w-full items-start justify-start">
        <Scrollbars
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: "transparent",
                right: "2px",
                bottom: "2px",
                top: "2px",
                borderRadius: "3px",
                width: "7px",
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: "7px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                backgroundColor: "#6C6C6C",
                opacity: "60%",
              }}
            />
          )}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={300}
        >
          {data &&
            data.data
              .filter((item) => (AnalysisType === "scan" ? item.dvs !== "P" : item.dvs === "P"))
              .map((el, idx) => {
                if (el.prj_CD === prjCd) {
                  return (
                    <div
                      key={idx}
                      onClick={(e) => handleClickSelectPrj(e, el.prj_CD)}
                      className="flex h-[60px] w-full cursor-pointer flex-row items-center justify-start border-b border-FilterInActiveColor/40 bg-orangeColor/10 text-[16px] font-normal text-selectTextColor hover:bg-orangeColor/20"
                    >
                      <div className="flex h-full w-[200px] flex-col items-center justify-center">{el.prj_CD}</div>
                      <div className="flex h-full w-[400px] flex-col items-center justify-center">{el.prj_NM}</div>
                      <div className="flex h-full w-[200px] flex-col items-center justify-center">{el.prog_YN}</div>
                      <div className="flex h-full w-[190px] flex-col items-center justify-center">
                        {el.dvs === "C" ? "협력경영" : el.dvs === "S" ? "전략컨설팅" : el.dvs === "P" ? "고객만족" : null}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={idx}
                      onClick={(e) => handleClickPrj(e, el.prj_CD)}
                      className="flex h-[60px] w-full cursor-pointer flex-row items-center justify-start border-b border-FilterInActiveColor/40 bg-white text-[16px] font-normal text-selectTextColor hover:bg-FilterInActiveColor/20"
                    >
                      <div className="flex h-full w-[200px] flex-col items-center justify-center">{el.prj_CD}</div>
                      <div className="flex h-full w-[400px] flex-col items-center justify-center">{el.prj_NM}</div>
                      <div className="flex h-full w-[200px] flex-col items-center justify-center">{el.prog_YN}</div>
                      <div className="flex h-full w-[190px] flex-col items-center justify-center">
                        {el.dvs === "C" ? "협력경영" : el.dvs === "S" ? "전략컨설팅" : el.dvs === "P" ? "고객만족" : null}
                      </div>
                    </div>
                  );
                }
              })}
        </Scrollbars>
      </div>

      {/* sub title */}
      <div className="mt-20 flex h-fit w-full flex-row items-center justify-between">
        <div className="flex h-fit w-fit flex-row items-center justify-center text-[18px] font-bold">
          <img
            src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
            alt="menu_dot"
            className="mb-1 mr-8 w-[8px]"
          />
          파일 히스토리
        </div>
      </div>

      {/* 파일 히스토리 테이블 */}
      {/* table header */}
      <div className="mt-10 flex h-[60px] w-full flex-row items-center justify-start bg-FilterInActiveColor/40 text-[16px] font-bold text-selectTextColor">
        <div className="flex h-full w-[12%] flex-col items-center justify-center border-l border-white">프로젝트 코드</div>
        <div className="flex h-full w-[13%] flex-col items-center justify-center border-l border-white">파일구분</div>
        <div className="flex h-full w-[45%] flex-col items-center justify-center border-l border-white">파일 이름 (클릭하여 다운로드)</div>
        <div className="flex h-full w-[10%] flex-col items-center justify-center border-l border-white">상태</div>
        <div className="flex h-full w-[20%] flex-col items-center justify-center border-l border-white">업로드 일시</div>
      </div>
      <div
        className={
          prjCd === ""
            ? `scrollbar h-[100px] w-full items-start justify-start`
            : historyDb?.data.length === 0
            ? `scrollbar h-[100px] w-full items-start justify-start`
            : `scrollbar h-[300px] w-full items-start justify-start`
        }
      >
        <Scrollbars
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: "transparent",
                right: "2px",
                bottom: "2px",
                top: "2px",
                borderRadius: "3px",
                width: "7px",
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: "7px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                backgroundColor: "#6C6C6C",
                opacity: "60%",
              }}
            />
          )}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={300}
        >
          {/* null 일때 */}
          {prjCd === "" ? (
            <div className="mt-14 flex h-fit w-full flex-col items-center justify-center font-bold text-orangeColor/50">프로젝트를 선택해주세요</div>
          ) : historyDb?.data.length === 0 ? (
            <div className="mt-14 flex h-fit w-full flex-col items-center justify-center font-bold text-orangeColor/50">
              등록된 파일 히스토리가 없습니다
            </div>
          ) : null}

          {/* table body */}
          {historyDb &&
            historyDb?.data.map((el, idx) => {
              return (
                <div className="flex h-[60px] w-full flex-row items-center justify-start border-b border-FilterInActiveColor/40 text-[16px] font-normal text-selectTextColor hover:bg-FilterInActiveColor/20">
                  <div className="flex h-full w-[12%] flex-col items-center justify-center">{el.prj_CD}</div>
                  <div className="flex h-full w-[13%] flex-col items-center justify-center">
                    {el.dvs === "Q" ? "문항 및 보기" : el.dvs === "U" ? "유저" : null}
                  </div>
                  <Link
                    to={"#"}
                    onClick={() => handleClickFileName(el.file_PATH)}
                    // href="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/file/%EC%9C%A0%EC%A0%80+%EB%B0%8F+%EC%82%AC%EC%A0%84%EC%A0%95%EB%B3%B4+%EC%97%85%EB%A1%9C%EB%93%9C+%EC%96%91%EC%8B%9D.xlsx"
                    // target="_blank"
                    // download={"테스트"}
                    className="flex h-full w-[45%] cursor-pointer flex-col items-center justify-center p-3 text-center leading-snug underline-offset-2 hover:font-bold hover:text-orangeColor hover:underline"
                  >
                    {/* <div
                    // onClick={() => handleClickFileName(el.file_NAME)}
                    className="flex h-full w-[40%] cursor-pointer flex-col items-center justify-center underline-offset-2 hover:font-bold hover:text-orangeColor hover:underline"
                  > */}
                    {el.file_NAME}
                    {/* </div> */}
                  </Link>
                  <div className="flex h-full w-[10%] flex-col items-center justify-center">
                    {el.status === "S" ? "성공" : el.status === "F" ? "실패" : null}
                  </div>
                  <div className="flex h-full w-[20%] flex-col items-center justify-center">{extractTime(el.inpt_DTM)}</div>
                </div>
              );
            })}
        </Scrollbars>
      </div>

      {/* sub title */}
      <div className="mt-20 flex h-fit w-full flex-row items-center justify-between">
        <div className="flex h-fit w-fit flex-row items-center justify-center text-[18px] font-bold">
          <img
            src="https://scan-file-bucket.s3.ap-northeast-2.amazonaws.com/image/orange_menu_rectangle.png"
            alt="menu_dot"
            className="mb-1 mr-8 w-[8px]"
          />
          협업용 게시판
        </div>
      </div>

      <div className="mb-10 mt-10 flex h-fit w-full flex-col items-center justify-between rounded-xl border-2 border-FilterInActiveColor/40 p-10 pr-0">
        <div
          ref={chatRef}
          className={
            prjCd === ""
              ? `h-[300px] w-full items-center justify-start`
              : chatData?.data.length === 0
              ? `h-[300px] w-full items-center justify-start`
              : `mb-10 h-[550px] w-full items-center justify-start pb-10`
          }
        >
          <Scrollbars
            renderTrackVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  backgroundColor: "transparent",
                  right: "2px",
                  bottom: "2px",
                  top: "2px",
                  borderRadius: "3px",
                  width: "7px",
                }}
              />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  width: "7px",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                  backgroundColor: "#6C6C6C",
                  opacity: "60%",
                }}
              />
            )}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={300}
          >
            {prjCd === "" ? (
              <div className="mt-10 flex h-fit w-full flex-col items-center justify-center font-bold text-orangeColor/50">
                프로젝트를 선택해주세요
              </div>
            ) : chatData?.data.length === 0 ? (
              <div className="mt-10 flex h-fit w-full flex-col items-center justify-center font-bold text-orangeColor/50">
                등록된 게시글이 없습니다
              </div>
            ) : null}

            {chatData &&
              chatData?.data.map((el, idx) => {
                if (el.user_ID.toUpperCase() === userId.toUpperCase()) {
                  return (
                    <AdminProChatMyComp
                      prj_cd={el.prj_CD}
                      content={el.content}
                      user_id={el.user_ID}
                      inpt_dtm={extractTime(el.inpt_DTM)}
                      status={el.status}
                      seq={el.seq}
                      select={selectChat}
                      setSelect={setSelectChat}
                      updateChat={updateChat}
                      setUpdateChat={setUpdateChat}
                    />
                  );
                } else {
                  return (
                    <AdminProChatComp
                      prj_cd={el.prj_CD}
                      content={el.content}
                      user_id={el.user_ID}
                      inpt_dtm={extractTime(el.inpt_DTM)}
                      status={el.status}
                    />
                  );
                }
              })}
          </Scrollbars>
        </div>
        <div className="flex h-[80px] w-full flex-row items-center justify-between">
          <textarea
            placeholder={prjCd ? "내용을 입력하세요" : "프로젝트를 선택해주세요"}
            spellCheck="false"
            onChange={(e) => (prjCd ? setChat(e.target.value) : null)}
            value={chat}
            className="mr-10 h-full w-[88%] resize-none rounded-lg bg-FilterInActiveColor/20 p-7 leading-snug outline-none"
          ></textarea>
          {prjCd ? (
            <button
              onClick={handleClickSubmit}
              type="button"
              className="mr-10 flex h-full w-[10%] flex-col items-center justify-center rounded-lg bg-[#4B9AF9] text-[16px] font-bold text-white hover:bg-[#4B9AF9]/80"
            >
              등록
            </button>
          ) : (
            <button
              type="button"
              className="mr-10 flex h-full w-[10%] cursor-default flex-col items-center justify-center rounded-lg bg-FilterInActiveColor text-[16px] font-bold text-white"
            >
              등록
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminProject;
