import { useQuery } from "@tanstack/react-query";
import getDa11Res from "../api/getDa11Res";

const useGetDa11Res = (prj_cd, qst_cd, svy_ojt, opt, stDate, edDate) => {
  return useQuery(["da11"], () => getDa11Res(prj_cd, qst_cd, svy_ojt, opt, stDate, edDate), {
    enabled: false,
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDa11Res;
