import axios from "axios";

const getDetailPrj = (prj_cd) => {
  return axios.get(`/admin/detailProj?prj_cd=${prj_cd}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getDetailPrj;
