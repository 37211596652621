import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import Da02Chart from "../../components/mobile/chart/Da02Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da02Table from "../../components/mobile/table/Da02Table";
import useGetDa02Res from "../../hooks/useGetDa02Res";
import { useMenuState, usePeriodState, usePrjValState, useSelectQstState, useUnitState } from "../../store/mobile/store";

const DA02 = () => {
  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const unitState = useUnitState((state) => state.unitState);
  const menuState = useMenuState((state) => state.menuVal);
  const setUnitState = useUnitState((state) => state.setUnitState);
  const prjVal = usePrjValState((state) => state.prjVal);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const [rendering, setRendering] = useState(false);

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  const { refetch, data } = useGetDa02Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, startDate, endDate);

  const initData = data?.data;

  // console.log(initData);
  // chart series 생성 알고리즘
  let chartProps = initData?.map((el, idx) => {
    if (unitState === "rate") {
      return {
        x: el.x - 1,
        y: el.y - 1,
        z: el.rate > 0 ? el.rate : null,
        xName: el.sel_NM,
        yName: el.sel_NM2,
        color: "#DF895F",
      };
    } else {
      return {
        x: el.x - 1,
        y: el.y - 1,
        z: el.cnt > 0 ? el.cnt : null,
        xName: el.sel_NM,
        yName: el.sel_NM2,
        color: "#DF895F",
      };
    }
  });

  // chart categories 생성 알고리즘
  let chartXCategories = initData?.map((el) => {
    return el.sel_NM;
  });

  let chartYCategories = initData?.map((el) => {
    return el.sel_NM2;
  });

  let categoriesSet = new Set(chartXCategories?.concat(chartYCategories));

  let uniqueCategories = [...categoriesSet];

  // table props 생성 알고리즘
  let tableProps = [];

  uniqueCategories.forEach((el) => {
    let temp = initData
      .filter((data) => data.sel_NM === el)
      .map((el) => {
        if (unitState === "rate") {
          return el.rate > 0 ? el.rate : 0;
        } else {
          return el.cnt > 0 ? el.cnt : 0;
        }
      });
    temp.unshift(el);
    // console.log(temp);
    tableProps.push(temp);
  });

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 400);
  }, [selectQstState, startDate, endDate]);

  let keyword = initData ? initData[0]?.keyword : null;
  let keyword2 = initData ? initData[0]?.keyword2 : null;

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder/50 px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {keyword}
        </div>
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {keyword2}
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>

          {rendering ? (
            <>
              <Da02Chart data={chartProps} categories={uniqueCategories} keyword={keyword} keyword2={keyword2} />
              <Da02Table categories={uniqueCategories} data={tableProps} />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default DA02;
