import { useQuery } from "@tanstack/react-query";
import getAdminUserEtcTitle from "../api/getAdminUserEtcTitle";

const useGetAdminUserEtcTitle = (prj_cd, etc_num) => {
  return useQuery(["adminUserEtcTitle"], () => getAdminUserEtcTitle(prj_cd, etc_num), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserEtcTitle;
