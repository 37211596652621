import { useQuery } from "@tanstack/react-query";
import getPrjPrd from "../api/getPrjPrd";

const useGetPrjPrd = (prjVal) => {
  return useQuery(["prjPrd"], () => getPrjPrd(prjVal), {
    enabled: false,
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default useGetPrjPrd;
