import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminUserDeptOrder from "../api/postAdminUserDeptOrder";

const usePostAdminUserDeptOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminUserDeptOrder, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminUserDept"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminUserDeptOrder;
