import axios from "axios";

const getAdminFileHistory = (prjCd) => {
  return axios.get(`/admin/getFileHistory?prj_cd=${prjCd}`, {
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: true,
  });
};

export default getAdminFileHistory;
