import { useQuery } from "@tanstack/react-query";
import getAdminChat from "../api/getAdminChat";

const useGetAdminChat = (prj_cd) => {
  return useQuery(["adminChat"], () => getAdminChat(prj_cd), {
    onSuccess: (res) => {
      //   console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminChat;
