import axios from "axios";

const deleteSel = (data) => {
  return axios.delete("/admin/deleteSel", {
    data: data,
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
  });
};

export default deleteSel;
