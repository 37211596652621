import { useMutation, useQueryClient } from "@tanstack/react-query";
import postAdminFileUpload from "../api/postAdminFileUpload";

const usePostAdminFileUpload = () => {
  const queryClient = useQueryClient();

  return useMutation(postAdminFileUpload, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["adminQstSelRes"]);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export default usePostAdminFileUpload;
