import { useEffect } from "react";
import {
  useSelectQstState,
  useSelListModalState,
  useSelListNm,
  useSelListState,
} from "../../store/mobile/store";
const SelListModal = (props) => {
  let selListArr = props?.selList
    ? [{ sel_NM: "전체", sel_CD: "전체", sel_TYP: "S00" }, ...props?.selList]
    : [];

  const selList = useSelListState((state) => state.selListState);
  const setSelList = useSelListState((state) => state.setSelListState);
  const setSelNm = useSelListNm((state) => state.setSelNmState);
  const selectQstState = useSelectQstState((state) => state.selectQstState);

  const selListModalState = useSelListModalState(
    (state) => state.selListModalState
  );
  const setSelListModalState = useSelListModalState(
    (state) => state.setSelListModalState
  );

  const handleClickCheckedList = (sel_cd) => {
    setSelListModalState(false);
  };

  const handleClickList = (sel_cd, sel_nm) => {
    setSelNm(sel_nm);
    setSelList(sel_cd);
    setSelListModalState(false);
  };

  useEffect(() => {
    selListArr.forEach((el, idx) => {
      if (el.sel_CD === selList) {
        setSelNm(el.sel_NM);
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      setSelListModalState(false);
      setSelList("전체");
      setSelNm("전체");
    };
  }, [selectQstState]);

  return (
    <div
      className={
        selListModalState
          ? ` absolute -top-[0px] right-0 z-10 flex  h-fit w-fit flex-col  items-center justify-start rounded-sm border border-t-0  border-tableBorderColor/50 bg-white py-3 font-SDGothic text-[14px] text-selectTextColor/80 drop-shadow-lg duration-500 ease-in-out`
          : ` absolute -top-[500px] right-0 z-10 flex   h-fit w-fit flex-col  items-center justify-start rounded-sm border border-tableBorderColor/50 bg-white py-3 font-SDGothic text-[14px] text-selectTextColor/80 drop-shadow-lg duration-500 ease-in-out`
      }
    >
      {selListArr.map((el, idx) => {
        if (el.sel_CD === selList) {
          return (
            <div
              key={idx}
              onClick={() => handleClickCheckedList(el.sel_CD, el.sel_NM)}
              className="flex h-fit w-full flex-col items-start justify-center rounded-sm bg-tableHeaderColor px-7 py-4 text-orangeColor"
            >
              {el.sel_NM}
            </div>
          );
        } else {
          return (
            <div
              key={idx}
              onClick={() => handleClickList(el.sel_CD, el.sel_NM)}
              className="flex h-fit w-full flex-col items-start justify-center rounded-sm px-7 py-4"
            >
              {el.sel_NM}
            </div>
          );
        }
      })}
    </div>
  );
};

export default SelListModal;
