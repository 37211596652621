import { useQuery } from "@tanstack/react-query";
import getAdminUserWork from "../api/getAdminUserWork";

const useGetAdminUserWork = (prj_cd) => {
  return useQuery(["adminUserWork"], () => getAdminUserWork(prj_cd), {
    onSuccess: (res) => {},
    onError: (err) => {
      console.log(err);
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetAdminUserWork;
