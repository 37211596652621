import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import Da01Chart from "../../components/mobile/chart/Da01Chart";
import TabBar from "../../components/mobile/common/TabBar";
import Da01Table from "../../components/mobile/table/Da01Table";
// import { ageCntDict, ageDict, workCntDict, workDict } from "../../dict/mobile/dict";
import useGetDa01Res from "../../hooks/useGetDa01Res";
import { useComnDict, useMenuState, usePeriodState, usePrjValState, useSelectQstState, useUnitState } from "../../store/mobile/store";

const DA01 = () => {
  const ageDict = useComnDict((state) => state.ageDict);
  const workDict = useComnDict((state) => state.workDict);
  const ageCntDict = useComnDict((state) => state.ageCntDict);
  const workCntDict = useComnDict((state) => state.workCntDict);

  const selectQstState = useSelectQstState((state) => state.selectQstState);
  const menuState = useMenuState((state) => state.menuVal);
  const prjVal = usePrjValState((state) => state.prjVal);

  const [rendering, setRendering] = useState(false);

  const startDate = usePeriodState((state) => state.stPeriod);
  const endDate = usePeriodState((state) => state.edPeriod);

  const unitState = useUnitState((state) => state.unitState);
  const setUnitState = useUnitState((state) => state.setUnitState);

  const { refetch, data } = useGetDa01Res(prjVal.value, selectQstState.qst_CD, selectQstState.svy_OJT, startDate, endDate);

  let initData = data?.data;

  const incData = initData?.filter((el) => el.keyword === "위상이 높아지는 이유");
  const decData = initData?.filter((el) => el.keyword === "위상이 낮아지는 이유");

  /* grp_nm 추출 */
  // 위상 상승
  let initIncGrpNm = incData?.map((el) => el.grp_NM);
  let incGrpSet = new Set(initIncGrpNm);
  let uniqueIncGrpNm = [...incGrpSet]; // tableProps

  // 위상 하락
  let initDecGrpNm = decData?.map((el) => el.grp_NM);
  let decGrpSet = new Set(initDecGrpNm);
  let uniqueDecGrpNm = [...decGrpSet]; // tableProps

  /* grp_nm에 대한 sel_nm 추출 */
  // 위상 상승
  let incSelNmArr = []; // tableProps

  uniqueIncGrpNm.forEach((el, idx) => {
    // 임시 배열
    let temp = [];

    // 위상상승에 대한 데이터 loop
    incData.forEach((data, idx2) => {
      // grpNm에 해당하는 selNm 추출
      if (data.grp_NM === el) {
        temp.push(data.sel_NM);
      }
    });

    incSelNmArr.push(temp);
  });

  // 위상하락
  let decSelNmArr = []; // tableProps

  uniqueDecGrpNm.forEach((el, idx) => {
    // 임시 배열
    let temp = [];

    // 위상하락에 대한 데이터 loop
    decData.forEach((data, idx2) => {
      // grpNm에 해당하는 selNm 추출
      if (data.grp_NM === el) {
        temp.push(data.sel_NM);
      }
    });

    decSelNmArr.push(temp);
  });

  /* table data 추출 */
  let tableData = [];
  let tableDecData = [];

  if (menuState === "MEM") {
    // 비율모드
    if (unitState === "rate") {
      workDict.forEach((el, idx) => {
        // 위상 상승 데이터
        let sum = 0; // 사례수
        let temp = [];
        incData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          sum += +data[`${el.sel_cd}_CNT`];
          temp.push(data[el.sel_cd]);
        });
        temp = [sum, ...temp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableData.push(temp);

        // 위상 하락 데이터
        let decSum = 0; //
        let decTemp = [];
        decData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          decSum += +data[`${el.sel_cd}_CNT`];
          decTemp.push(data[el.sel_cd]);
        });
        decTemp = [decSum, ...decTemp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableDecData.push(decTemp);
      });
    }
    // 카운트모드
    else {
      workCntDict.forEach((el, idx) => {
        // 위상 상승 데이터
        let sum = 0; // 사례수
        let temp = [];
        incData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          sum += +data[`${el.sel_cd}`];
          temp.push(data[el.sel_cd]);
        });

        temp = [sum, ...temp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableData.push(temp);

        // 위상 하락 데이터
        let decSum = 0; //
        let decTemp = [];
        decData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          decSum += +data[el.sel_cd];
          decTemp.push(data[el.sel_cd]);
        });
        decTemp = [decSum, ...decTemp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableDecData.push(decTemp);
      });
    }
  } else if (menuState === "VST" || menuState === "LRES" || selectQstState.svy_OJT === "CS" || selectQstState.svy_OJT === "RS") {
    // 비율모드
    if (unitState === "rate") {
      ageDict.forEach((el, idx) => {
        // 위상 상승 데이터
        let sum = 0; // 사례수
        let temp = [];
        incData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          sum += +data[`${el.sel_cd}_CNT`];
          temp.push(data[el.sel_cd]);
        });

        temp = [sum, ...temp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableData.push(temp);

        // 위상 하락 데이터
        let decSum = 0; //
        let decTemp = [];
        decData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          decSum += +data[el.sel_cd];
          decTemp.push(data[el.sel_cd]);
        });
        decTemp = [decSum, ...decTemp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableDecData.push(decTemp);
      });
    }
    // 카운트모드
    else {
      ageCntDict.forEach((el, idx) => {
        // 위상 상승 데이터
        let sum = 0; // 사례수
        let temp = [];
        incData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          sum += +data[`${el.sel_cd}`];
          temp.push(data[el.sel_cd]);
        });

        temp = [sum, ...temp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableData.push(temp);

        // 위상 하락 데이터
        let decSum = 0; //
        let decTemp = [];
        decData?.forEach((data, idx2) => {
          // 사례수를 구하기위해 cnt값도 함께 sum
          decSum += +data[el.sel_cd];
          decTemp.push(data[el.sel_cd]);
        });
        decTemp = [decSum, ...decTemp]; // 사례수를 맨 앞으로 보내기위한 처리
        tableDecData.push(decTemp);
      });
    }
  }

  /* chart data 추출 */
  let chartData = [];

  uniqueIncGrpNm.forEach((el, idx) => {
    // 그룹별 데이터
    let grpTemp = []; // 위상상승 그룹별 최종데이터 배열
    let decGrpTemp = []; // 위상하락 그룹별 최종데이터 배열
    let finalTemp = []; // 차트에 뿌려질 최종 데이터 배열
    // 직종별 문항
    if (menuState === "MEM") {
      // 비율모드
      if (unitState === "rate") {
        workDict.forEach((work, idx2) => {
          let temp = []; // 그룹별 위상상승 데이터 배열
          let decTemp = []; // 그룹별 위상하락 데이터 배열

          // 위상상승
          incData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              temp.push(data[work.sel_cd]);
            }
          });

          // 위상하락
          decData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              decTemp.push(data[work.sel_cd]);
            }
          });

          // 위상 상승에 대한 그룹별 합계
          grpTemp.push(+temp.reduce((a, b) => +a + +b, 0).toFixed(1));
          // 위상 하락에 대한 그룹별 합계
          decGrpTemp.push(+decTemp.reduce((a, b) => +a + +b, 0).toFixed(1));
        });

        // 최종데이터에 위상상승, 하락 교차 삽입
        grpTemp.forEach((el2, comnIdx) => {
          finalTemp.push(el2);
          finalTemp.push(decGrpTemp[comnIdx]);
        });

        chartData.push(finalTemp);
      }
      // 카운트모드
      else {
        workCntDict.forEach((work, idx2) => {
          let temp = []; // 그룹별 위상상승 데이터 배열
          let decTemp = []; // 그룹별 위상하락 데이터 배열

          // 위상상승
          incData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              temp.push(data[work.sel_cd]);
            }
          });

          // 위상하락
          decData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              decTemp.push(data[work.sel_cd]);
            }
          });

          // 위상 상승에 대한 그룹별 합계
          grpTemp.push(+temp.reduce((a, b) => +a + +b, 0).toFixed(1));
          // 위상 하락에 대한 그룹별 합계
          decGrpTemp.push(+decTemp.reduce((a, b) => +a + +b, 0).toFixed(1));
        });

        // 최종데이터에 위상상승, 하락 교차 삽입
        grpTemp.forEach((el2, comnIdx) => {
          finalTemp.push(el2);
          finalTemp.push(decGrpTemp[comnIdx]);
        });

        chartData.push(finalTemp);
      }
    }
    // 연령별 데이터
    else if (menuState === "VST" || menuState === "LRES" || menuState === "CS" || menuState === "RS") {
      // 비율모드
      if (unitState === "rate") {
        ageDict.forEach((work, idx2) => {
          let temp = []; // 그룹별 위상상승 데이터 배열
          let decTemp = []; // 그룹별 위상하락 데이터 배열

          // 위상상승
          incData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              temp.push(data[work.sel_cd]);
            }
          });

          // 위상하락
          decData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              decTemp.push(data[work.sel_cd]);
            }
          });

          // 위상 상승에 대한 그룹별 합계
          grpTemp.push(+temp.reduce((a, b) => +a + +b, 0).toFixed(1));
          // 위상 하락에 대한 그룹별 합계
          decGrpTemp.push(+decTemp.reduce((a, b) => +a + +b, 0).toFixed(1));
        });

        // 최종데이터에 위상상승, 하락 교차 삽입
        grpTemp.forEach((el2, comnIdx) => {
          finalTemp.push(el2);
          finalTemp.push(decGrpTemp[comnIdx]);
        });

        chartData.push(finalTemp);
      }
      // 카운트모드
      else {
        ageCntDict.forEach((work, idx2) => {
          let temp = []; // 그룹별 위상상승 데이터 배열
          let decTemp = []; // 그룹별 위상하락 데이터 배열

          // 위상상승
          incData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              temp.push(data[work.sel_cd]);
            }
          });

          // 위상하락
          decData.forEach((data, idx3) => {
            if (data.grp_NM === el) {
              decTemp.push(data[work.sel_cd]);
            }
          });

          // 위상 상승에 대한 그룹별 합계
          grpTemp.push(+temp.reduce((a, b) => +a + +b, 0).toFixed(1));
          // 위상 하락에 대한 그룹별 합계
          decGrpTemp.push(+decTemp.reduce((a, b) => +a + +b, 0).toFixed(1));
        });

        // 최종데이터에 위상상승, 하락 교차 삽입
        grpTemp.forEach((el2, comnIdx) => {
          finalTemp.push(el2);
          finalTemp.push(decGrpTemp[comnIdx]);
        });

        chartData.push(finalTemp);
      }
    }
  });

  const colors = ["#DF895F", "#ECB758", "#98C8D7", "#72BAB4", "#63ABCB", "#D977A0", "#E19DBA", "#EBBA94", "#FFDC83", "#FF82FF"];

  let chartSeries = chartData.map((el, idx) => {
    return {
      name: uniqueIncGrpNm[idx],
      data: el,
      color: colors[idx % 10],
    };
  });

  const handleChangeRadio = (e) => {
    setUnitState(e.target.value);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setRendering(true);
    }, 900);
  }, [selectQstState, startDate, endDate]);

  return (
    <>
      {!rendering ? (
        <div className="fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white/80">
          <SyncLoader color="#1686FF" />
        </div>
      ) : null}
      <div className="mb-28 flex h-fit  w-full flex-col items-center justify-start font-SDGothic">
        <div className="flex h-fit w-full flex-row items-center justify-start border-b border-qstTitleBorder px-2 py-5 text-[16px] font-bold text-selectTextColor">
          <BsDot className="text-orangeColor" />
          {selectQstState.keyword}
        </div>

        <div className="flex h-fit w-full flex-col items-center justify-center p-3">
          {/* chart title */}
          <div className="mt-2 flex h-fit w-full flex-row items-center justify-between px-2">
            {/* chart info */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {menuState === "MEM" ? `직종별` : menuState === "DEP" ? `구성원 전체` : `연령별`}
              {unitState === "rate" ? `응답률` : `응답수`}
            </div>
            {/* unit */}
            <div className="flex h-fit w-fit flex-col items-center justify-center font-SDGothic text-[14px] text-selectTextColor">
              {/* (단위: %) */}
              <div className="flex h-fit w-fit flex-row items-center justify-center">
                <input
                  id="rate"
                  type="radio"
                  name="radio_unit"
                  value="rate"
                  className="accent-orangeColor"
                  checked={unitState === "rate" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="rate" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  비중(%)
                </label>
                <input
                  id="count"
                  type="radio"
                  name="radio_unit"
                  value="count"
                  className="ml-3 border accent-orangeColor"
                  checked={unitState === "count" ? true : false}
                  onChange={handleChangeRadio}
                />
                <label htmlFor="count" className="ml-2 flex h-fit w-fit flex-col items-center justify-center">
                  인원(명)
                </label>
              </div>
            </div>
          </div>

          {rendering ? (
            <>
              <Da01Chart data={chartSeries} />
              <Da01Table
                incGrp={uniqueIncGrpNm}
                incSel={incSelNmArr}
                decGrp={uniqueDecGrpNm}
                decSel={decSelNmArr}
                data={tableData}
                decData={tableDecData}
              />
            </>
          ) : null}
        </div>
        <TabBar />
      </div>
    </>
  );
};

export default DA01;
